import { PredictiveLabelsModel, PredictiveLabelsModelMode } from 'src/pages/predictive-labels/types.ts';
import { StaffPermission, useAuth } from 'src/auth';
import { useMemo } from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';

export function ModelSettingsCard({
  setModel,
  model,
  isNew
}: {
  setModel: (value: ((prevState: PredictiveLabelsModel) => PredictiveLabelsModel) | PredictiveLabelsModel) => void;
  model: PredictiveLabelsModel;
  isNew: boolean;
}) {
  const { userHasStaffPermission } = useAuth();

  const showIsGlobal = useMemo(() => {
    return userHasStaffPermission(StaffPermission.VIEW_ANY_ACCOUNT);
  }, [userHasStaffPermission]);

  return (
    <Card>
      <Card.Header>Model settings</Card.Header>
      <Card.Body>
        <div className="mb-3">
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text style={{ backgroundColor: '#f5f5f5', width: '6rem' }}>Model name</InputGroup.Text>
            <Form.Control
              name="name"
              size="sm"
              onChange={ev => setModel(prevState => ({ ...prevState, name: ev.target.value || '' }))}
              value={model?.name}
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text style={{ backgroundColor: '#f5f5f5', width: '6rem' }}>Description</InputGroup.Text>
            <Form.Control
              name="description"
              size="sm"
              onChange={ev => setModel(prevState => ({ ...prevState, description: ev.target.value || '' }))}
              value={model.description}
              as="textarea"
              rows={2}
            />
          </InputGroup>
          <div className={'my-2'}>Model type</div>
          <Form.Check
            checked={model.mode === PredictiveLabelsModelMode.MULTI_CLASS}
            label="Multi-class"
            name="mode"
            value="MULTI_CLASS"
            type="radio"
            id="mode-multiClass"
            disabled={!isNew}
            onChange={() =>
              setModel(prevState => ({
                ...prevState,
                mode: PredictiveLabelsModelMode.MULTI_CLASS
              }))
            }
          />
          <Form.Check
            checked={model.mode === PredictiveLabelsModelMode.BINARY}
            label="Binary"
            name="mode"
            value="BINARY"
            type="radio"
            id="mode-binary"
            disabled={!isNew}
            onChange={() =>
              setModel(prevState => ({
                ...prevState,
                mode: PredictiveLabelsModelMode.BINARY
              }))
            }
          />
          {showIsGlobal && (
            <>
              <div className={'my-2 '}>Is it a customer model?</div>
              <Form.Check
                checked={model.isGlobal}
                label="No, it's global"
                name="isGlobal"
                value="true"
                type="radio"
                id="isGlobal-true"
                disabled={!isNew}
                onChange={() => setModel(prevState => ({ ...prevState, isGlobal: true }))}
              />
              <Form.Check
                checked={!model.isGlobal}
                label="Yes"
                name="isGlobal"
                value="false"
                type="radio"
                id="isGlobal-true"
                disabled={!isNew}
                onChange={() => setModel(prevState => ({ ...prevState, isGlobal: false }))}
              />
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
