import { Alert, Button, Col, Dropdown, Form, Offcanvas, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import toast from 'react-hot-toast';

import EmptyMessage from 'src/components/EmptyMessage';
import { Segment, SegmentPersona, SegmentPersonaCriteria, SegmentPersonaSearchOptions } from 'src/pages/segments/types';
import { PersonaCriteriaRuleEditModal } from './PersonaCriteriaRuleEditModal';
import { showModal } from 'src/utils/modals';
import { DraggablePersonaCriteriaList } from 'src/pages/segments/components/view/personas/DraggablePersonaCriteriaList.tsx';
import { PersonaPreviewModal } from './preview/PersonaPreviewModal';
import set from 'lodash/set';
import clone from 'lodash/clone';
import { StartPersonaSearchModal } from './search/StartPersonaSearchModal';
import { useClient } from 'src/auth';

type SegmentPersonaEdit = Pick<SegmentPersona, 'name' | 'criteriaGroups' | 'searchStatus' | 'searchOptions'>;

const DEFAULT_PERSONA: SegmentPersonaEdit = {
  name: '',
  criteriaGroups: [],
  searchStatus: 'NEW',
  searchOptions: {
    numContactsRequiredPerCompany: 1,
    isEmailRequired: false
  }
};

export function PersonaEditOffcanvas({
  segmentId,
  segment,
  show,
  handleClose,
  editingPersona,
  handleUpdatePersona,
  handleCreatePersona,
  handleTriggerPersonaSearchProcess,
  handleDeletePersona
}: {
  segmentId: string;
  segment: Segment;
  show: boolean;
  handleClose: () => void;
  editingPersona: SegmentPersonaEdit | null;
  handleUpdatePersona: (persona: SegmentPersonaEdit) => Promise<string>;
  handleDeletePersona: () => Promise<void>;
  handleCreatePersona: (persona: SegmentPersonaEdit) => Promise<string>;
  handleTriggerPersonaSearchProcess: (personaId: string, searchOptions: SegmentPersonaSearchOptions) => Promise<void>;
}) {
  const [persona, setPersona] = useState<SegmentPersonaEdit>(DEFAULT_PERSONA);
  const { creditBalances, hasFeatureFlag } = useClient();

  const hasPhoneNumbersFeature = hasFeatureFlag('phoneNumbers');

  useEffect(() => {
    setPersona(editingPersona ?? DEFAULT_PERSONA);
  }, [editingPersona]);

  const setCriteriaGroups = useCallback(
    (criteriaGroups: SegmentPersonaCriteria[]) => {
      setPersona(prev => {
        return { ...prev, criteriaGroups };
      });
    },
    [setPersona]
  );

  const addCriteria = useCallback(
    (criteria: SegmentPersonaCriteria) => {
      setCriteriaGroups([...persona.criteriaGroups, criteria]);
    },
    [persona.criteriaGroups, setCriteriaGroups]
  );

  const editCriteria = useCallback(
    (idx: number, criteria: SegmentPersonaCriteria) => {
      const newGroups = [...persona.criteriaGroups];
      newGroups[idx] = criteria;
      setCriteriaGroups(newGroups);
    },
    [persona.criteriaGroups, setCriteriaGroups]
  );
  const moveCriteria = useCallback(
    (dragIndex: number, dropIndex: number) => {
      const newGroups = [...persona.criteriaGroups];
      const [movedItem] = newGroups.splice(dragIndex, 1);
      newGroups.splice(dragIndex < dropIndex ? dropIndex : dropIndex + 1, 0, movedItem);
      setCriteriaGroups(newGroups);
    },
    [persona, setCriteriaGroups]
  );
  const duplicateCriteria = useCallback(
    (idx: number) => {
      const oldGroups = persona.criteriaGroups;
      // Duplicate this array but with the idx'th item repeated
      const newGroups = [...oldGroups.slice(0, idx), oldGroups[idx], oldGroups[idx], ...oldGroups.slice(idx + 1)];
      setCriteriaGroups(newGroups);
    },
    [persona.criteriaGroups, setCriteriaGroups]
  );
  const deleteCriteria = useCallback(
    (idx: number) => {
      const newGroups = persona.criteriaGroups.filter((_, i) => i !== idx);
      setCriteriaGroups(newGroups);
    },
    [persona.criteriaGroups, setCriteriaGroups]
  );

  const showPreview = useCallback(() => {
    showModal(PersonaPreviewModal, { segmentId, criteriaGroups: persona.criteriaGroups });
  }, [persona.criteriaGroups, segmentId]);

  const triggerSearch = useCallback(
    async (personaId: string) => {
      await toast.promise(handleTriggerPersonaSearchProcess(personaId, {}), {
        loading: 'Starting search...',
        success: 'Search started',
        error: 'Error starting search'
      });
    },
    [handleTriggerPersonaSearchProcess]
  );

  const showStartSearchModal = useCallback(
    async (personaId: string) => {
      const result: any = await showModal(StartPersonaSearchModal, { persona, creditBalances, segment });
      if (result) {
        console.log('Search started');
        triggerSearch(personaId);
      } else {
        console.log('Search cancelled');
      }
    },
    [creditBalances, persona, segment, triggerSearch]
  );

  const isValid = !!persona?.name && !!persona?.criteriaGroups && persona?.criteriaGroups?.length > 0;
  const isNew = !editingPersona;

  if (!persona) return <></>;

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: 800 }}>
        <Offcanvas.Header closeButton>{isNew ? 'New Persona' : 'Edit Persona'}</Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col>
              {persona?.searchStatus === 'RUNNING' && (
                <Alert variant="warning">
                  A search is underway for this persona, editing the persona will cancel and restart the search process
                </Alert>
              )}
              <div className="pb-3 d-flex flex-row">
                <Form.Control
                  value={persona?.name}
                  placeholder="Persona name"
                  onChange={ev => setPersona({ ...persona, name: ev.target.value })}
                />
                <Dropdown align="end">
                  <Dropdown.Toggle variant="white" className="dropdown-ellipses mx-2" role="button">
                    <FiMoreVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        handleClose();
                        handleDeletePersona();
                      }}
                      disabled={isNew}
                    >
                      Delete persona
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <Form.Check
                type="checkbox"
                id="isEmailRequired"
                name="isEmailRequired"
                checked={persona.searchOptions.isEmailRequired}
                onChange={ev => setPersona(set(clone(persona), 'searchOptions.isEmailRequired', ev.target.checked))}
                label="Require email address for contacts"
              />

              {hasPhoneNumbersFeature && (
                <Form.Check
                  type="checkbox"
                  id="checkPhoneNumbers"
                  name="checkPhoneNumbers"
                  checked={persona.searchOptions.checkPhoneNumber}
                  onChange={ev => setPersona(set(clone(persona), 'searchOptions.checkPhoneNumber', ev.target.checked))}
                  label="Enrich with phone numbers"
                />
              )}

              <p className="mt-3">
                Find maximum{' '}
                <select
                  className="form-select form-select-sm d-inline-block w-auto"
                  name="numContactsRequiredPerCompany"
                  value={persona.searchOptions.numContactsRequiredPerCompany}
                  onChange={ev =>
                    setPersona(
                      set(clone(persona), 'searchOptions.numContactsRequiredPerCompany', parseInt(ev.target.value))
                    )
                  }
                >
                  <option value="1">1 contact</option>
                  <option value="2">2 contacts</option>
                  <option value="3">3 contacts</option>
                  <option value="4">4 contacts</option>
                  <option value="5">5 contacts</option>
                </select>{' '}
                per persona per company
              </p>
              <hr />

              {persona.criteriaGroups?.length === 0 && (
                <>
                  <div>
                    <EmptyMessage
                      style={{ height: 300 }}
                      message="Add one or more contact search criteria to perform a contact search"
                      actions={[
                        <Button
                          key={'add-criteria'}
                          size="sm"
                          onClick={async () => {
                            const criteria = await showModal(PersonaCriteriaRuleEditModal, { segmentId });
                            if (criteria) addCriteria(criteria as SegmentPersonaCriteria);
                          }}
                        >
                          Add search criteria
                        </Button>
                      ]}
                    />
                  </div>
                </>
              )}
              {persona.criteriaGroups?.length > 0 && (
                <div>
                  <div className="d-flex justify-content-between align-items-center" style={{ gap: 8 }}>
                    <div>Criteria:</div>
                    <div>
                      <Button
                        size="sm"
                        disabled={persona?.criteriaGroups?.length === 0}
                        variant="white"
                        onClick={showPreview}
                      >
                        Preview persona
                      </Button>
                      &nbsp;
                      <Button
                        size="sm"
                        disabled={!isValid}
                        variant="primary"
                        onClick={async () => {
                          let resultingPersonaId = '';
                          await toast.promise(
                            (async () => {
                              if (isNew) {
                                resultingPersonaId = await handleCreatePersona(persona);
                              } else {
                                resultingPersonaId = await handleUpdatePersona(persona);
                              }
                            })(),
                            {
                              loading: 'Saving persona...',
                              success: 'Persona saved',
                              error: 'Persona save failed'
                            }
                          );
                          handleClose();
                          showStartSearchModal(resultingPersonaId);
                        }}
                      >
                        Save and start search
                      </Button>
                    </div>
                  </div>
                  <DraggablePersonaCriteriaList
                    segmentId={segmentId}
                    criteriaGroups={persona.criteriaGroups}
                    editCriteria={editCriteria}
                    deleteCriteria={deleteCriteria}
                    moveCriteria={moveCriteria}
                    duplicateCriteria={duplicateCriteria}
                  />

                  <div>
                    <Button
                      size="sm"
                      variant="white"
                      onClick={async () => {
                        const criteria = await showModal(PersonaCriteriaRuleEditModal, { segmentId });
                        if (criteria) addCriteria(criteria as SegmentPersonaCriteria);
                      }}
                    >
                      Add search criteria
                    </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
