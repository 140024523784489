import { v4 as uuidv4 } from 'uuid';
import {
  Integration,
  IntegrationExternalFieldDescription,
  IntegrationFieldMapping,
  IntegrationFieldUpdateStrategy,
  IntegrationType,
  checkCreateExternalFieldProcess,
  loadIntegrationFieldNames,
  loadIntegrations,
  startCreateExternalFieldProcess,
  updateIntegration
} from 'src/apis/integrations';
import { DataDirectoryEntry, IntegrationFieldMappingInput, RecordType } from './types';
import { create } from 'zustand';
import API from 'src/utils/API';
import { CompanySchema, ContactSchema } from 'src/auth';
import toast from 'react-hot-toast';
import { METADATA_FIELDS } from './metadata';
import { getFieldType, getPicklistValues } from './utils/createFieldUtils';
import orderBy from 'lodash/orderBy';
import omit from 'lodash/omit';

export interface DataDirectoryStore {
  isDataDirectoryLoading: boolean;
  dataDirectoryCompanyFields: DataDirectoryEntry[];
  dataDirectoryContactFields: DataDirectoryEntry[];
  integrations: Integration[];
  sourcingCriteria: any;
  companiesSchema: CompanySchema | null;
  contactsSchema: ContactSchema | null;
  isContactsEnabled: boolean;
  integrationContactFieldDescriptions: Record<string, IntegrationExternalFieldDescription[]>;
  integrationCompanyFieldDescriptions: Record<string, IntegrationExternalFieldDescription[]>;
  loadDataDirectory: () => Promise<void>;
  addFieldMapping: (integrationId: string, recordType: RecordType, fieldName: string) => void;
  removeFieldMapping: (integrationId: string, recordType: RecordType, fieldMappingId: string) => void;
  modifyFieldMapping: (
    integrationId: string,
    recordType: RecordType,
    fieldMappingId: string,
    props: any,
    skipSave?: boolean
  ) => Promise<void>;
  createExternalField: (
    integration: Integration,
    recordType: RecordType,
    mapping: IntegrationFieldMappingInput,
    externalFieldName: string
  ) => Promise<{ success?: boolean; error?: string }>;
}

export function checkMapping(mapping: IntegrationFieldMappingInput) {
  if (!mapping.externalName) return 'Please select CRM field';
  return null;
}

function stateKeyName(recordType: string) {
  return recordType === 'contact' ? 'dataDirectoryContactFields' : 'dataDirectoryCompanyFields';
}

async function loadClientConfig(): Promise<{
  companiesSchema: CompanySchema;
  contactsSchema: ContactSchema;
  sourcingCriteria: any;
  isContactsEnabled: true;
}> {
  const data = await API.get<any>('/app/client', {});
  return data.client;
}

export const useDataDirectoryStore = create<DataDirectoryStore>()(set => ({
  dataDirectoryCompanyFields: [],
  dataDirectoryContactFields: [],
  isDataDirectoryLoading: true,
  isContactsEnabled: false,
  integrations: [],
  sourcingCriteria: null,
  companiesSchema: null,
  contactsSchema: null,
  integrationContactFieldDescriptions: {},
  integrationCompanyFieldDescriptions: {},
  addFieldMapping: async (integrationId: string, recordType: string, fieldName: string) =>
    set((state: DataDirectoryStore) => {
      const newState = {
        ...state,
        [stateKeyName(recordType)]: state[stateKeyName(recordType)].map(fd => {
          const newMapping = {
            id: uuidv4(),
            integrationId,
            internalName: fieldName,
            externalName: null,
            updateStrategy: IntegrationFieldUpdateStrategy.OVERWRITE_ALWAYS
          };
          if (fd.fieldName === fieldName) {
            return {
              ...fd,
              mappings: [...fd.mappings, newMapping]
            };
          }
          return fd;
        })
      };
      return newState;
    }),
  removeFieldMapping: async (integrationId: string, recordType: string, fieldMappingId: string) =>
    set((state: DataDirectoryStore) => {
      const newState = {
        ...state,
        [stateKeyName(recordType)]: state[stateKeyName(recordType)].map(fd => {
          return {
            ...fd,
            mappings: fd.mappings.filter(mapping => mapping.id !== fieldMappingId)
          };
        })
      };
      collectAndSaveMappings(newState, integrationId);
      return newState;
    }),
  modifyFieldMapping: async (integrationId: string, recordType: RecordType, fieldMappingId: string, updates: any) =>
    set((state: DataDirectoryStore) => {
      const newState = {
        ...state,
        [stateKeyName(recordType)]: state[stateKeyName(recordType)].map(fd => {
          return {
            ...fd,
            mappings: fd.mappings.map(mapping => {
              if (mapping.id === fieldMappingId) {
                return {
                  ...mapping,
                  ...updates
                };
              }
              return mapping;
            })
          };
        })
      };
      collectAndSaveMappings(newState, integrationId);
      return newState;
    }),
  loadDataDirectory: async () => {
    const results = await Promise.all([loadClientConfig(), loadIntegrations()]);
    // filter WEBHOOK integrations out as we dont map things for them
    const integrations = (results[1] as Integration[]).filter(i => i.integrationType !== IntegrationType.WEBHOOK);
    const config = results[0];

    // Now we need to map these two sets of entities into dataDirectoryCompanyFields and dataDirectoryContactFields

    // Group mappings by dataset field name
    const mappingLookupCompanies = {} as Record<string, any[]>;
    const mappingLookupContacts = {} as Record<string, any[]>;
    for (const integration of integrations) {
      for (const mapping of (integration.mappingSettings?.companies || []) as IntegrationFieldMapping[]) {
        const fieldName = mapping.internalName;
        if (!(fieldName in mappingLookupCompanies)) mappingLookupCompanies[fieldName] = [];
        mappingLookupCompanies[fieldName].push({
          ...mapping,
          integrationId: integration.id,
          id: mapping.id || uuidv4(), // Some mappings dont have ids yet
          updateStrategy: mapping.updateStrategy || IntegrationFieldUpdateStrategy.OVERWRITE_ALWAYS
        });
      }
      for (const mapping of (integration.mappingSettings?.contacts || []) as IntegrationFieldMapping[]) {
        const fieldName = mapping.internalName;
        if (!(fieldName in mappingLookupContacts)) mappingLookupContacts[fieldName] = [];
        mappingLookupContacts[fieldName].push({
          ...mapping,
          integrationId: integration.id,
          id: mapping.id || uuidv4(), // Some mappings dont have ids yet
          updateStrategy: mapping.updateStrategy || IntegrationFieldUpdateStrategy.OVERWRITE_ALWAYS
        });
      }
    }

    // Sort fields by datablock or source so similar fields are together
    const sortedCompanySchema = orderBy(
      config.companiesSchema,
      [f => f?.dataBlock?.blockName === 'firmographics', 'dataBlock.blockName', 'fieldSource'],
      ['desc', 'asc', 'asc']
    );

    // Map field mappings options for CCM companies and default contacts
    const dataDirectoryCompanyFields: DataDirectoryEntry[] = [
      ...sortedCompanySchema,
      ...(METADATA_FIELDS as unknown as CompanySchema)
    ]
      .filter(fd => !fd.isHiddenFromUser && fd.isMappableInIntegration && !fd.fieldSource.startsWith('crmSync'))
      .map(fd => ({
        ...fd,
        fieldName: fd.fieldName,
        ccmName: fd?.ccmName,
        presentInCCM: !!fd.ccmName,
        mappings: mappingLookupCompanies[fd.fieldName] || []
      }));
    const dataDirectoryContactFields: DataDirectoryEntry[] = [
      ...config.contactsSchema,
      ...(METADATA_FIELDS as ContactSchema)
    ]
      .filter(fd => !fd.isHiddenFromUser && fd.isMappableInIntegration && !fd.fieldSource.startsWith('crmSync'))
      .map(fd => ({
        ...fd,
        ccmName: fd.fieldName,
        presentInCCM: false,
        mappings: mappingLookupContacts[fd.fieldName] || []
      }));

    // Trigger loading the integration field descriptions
    for (const integration of integrations) {
      loadIntegrationFieldNames(integration.id).then((result: any) => {
        set((state: any) => ({
          ...state,
          integrationCompanyFieldDescriptions: {
            ...state.integrationCompanyFieldDescriptions,
            [integration.id]: result.accountFields
          },
          integrationContactFieldDescriptions: {
            ...state.integrationContactFieldDescriptions,
            [integration.id]: result.contactFields
          }
        }));
      });
    }

    set((state: any) => ({
      ...state,
      isDataDirectoryLoading: false,
      integrations,
      sourcingCriteria: config.sourcingCriteria,
      dataDirectoryCompanyFields,
      dataDirectoryContactFields,
      isContactsEnabled: config.isContactsEnabled,
      companiesSchema: config.companiesSchema,
      contactsSchema: config.contactsSchema
    }));
  },
  createExternalField: async (
    integration: Integration,
    recordType: RecordType,
    mapping: IntegrationFieldMappingInput,
    externalFieldName: string
  ) => {
    const state = useDataDirectoryStore.getState();
    const isSystemField = mapping.internalName.includes('system:');
    const externalFieldType = getFieldType(recordType, mapping.internalName, integration, state);
    const picklistValues = isSystemField ? [] : await getPicklistValues(mapping.internalName, recordType);

    let response;
    try {
      const { jobId } = await startCreateExternalFieldProcess(
        integration,
        recordType,
        mapping.internalName,
        externalFieldName,
        externalFieldType,
        picklistValues
      );

      do {
        await new Promise(resolve => setTimeout(resolve, 3000));
        response = await checkCreateExternalFieldProcess(integration, jobId);
      } while (response?.status !== 'SUCCEEDED' && response?.status !== 'FAILED');

      if (response?.status !== 'FAILED' && response.results[0]?.externalName) {
        state.modifyFieldMapping(
          integration.id,
          recordType,
          mapping.id,
          { externalName: response.results[0]?.externalName },
          false
        );

        loadIntegrationFieldNames(integration.id).then((result: any) => {
          set((state: any) => ({
            ...state,
            integrationCompanyFieldDescriptions: {
              ...state.integrationCompanyFieldDescriptions,
              [integration.id]: result.accountFields
            },
            integrationContactFieldDescriptions: {
              ...state.integrationContactFieldDescriptions,
              [integration.id]: result.contactFields
            }
          }));
        });
        return { success: true };
      }
    } catch (err) {
      console.error(err);
    }

    return { success: false, error: response?.results[0]?.errorMessage || 'Something went wrong' };
  }
}));

function collectMappings(entries: DataDirectoryEntry[], integrationId: string) {
  const filteredMappings: any[] = [];
  for (const entry of entries) {
    for (const mapping of entry.mappings) {
      if (mapping.integrationId === integrationId && mapping.externalName) {
        filteredMappings.push(omit(mapping, ['integrationId']));
      }
    }
  }
  return filteredMappings;
}

async function collectAndSaveMappings(state: DataDirectoryStore, integrationId: string) {
  // Collect all the VALID mappings for this integrationId from all entries
  const mappingSettings = {
    companies: collectMappings(state.dataDirectoryCompanyFields, integrationId),
    contacts: collectMappings(state.dataDirectoryContactFields, integrationId)
  };

  await toast.promise(updateIntegration(integrationId, { mappingSettings }), {
    loading: 'Saving mapping change...',
    success: 'Mapping change saved',
    error: 'Error saving mapping change'
  });
}
