import { Spinner } from 'react-bootstrap';

/**
 * Simple full viewport loader
 */
function PageLoader({ fullHeight, style, message }: { fullHeight: boolean; style: any; message?: string }) {
  const classNames = ['d-flex', 'justify-content-center', 'align-items-center'];
  if (fullHeight) classNames.push('min-vh-100');

  return (
    <div style={style} className={classNames.join(' ')}>
      <div className="text-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <div>{message}</div>
      </div>
    </div>
  );
}

PageLoader.defaultProps = {
  fullHeight: true,
  style: {}
};

export default PageLoader;
