import { Badge, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { titleCase } from 'src/utils/string.ts';
import EmptyMessage from 'src/components/EmptyMessage';
import API from 'src/utils/API';
import PageLoader from 'src/components/PageLoader';
import LoadingButton from 'src/components/LoadingButton';
import { navigateToCRMRecord } from '../../../pages/companies/utils';

export default function CRMSyncStatusTab({ company }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [integrations, setIntegrations] = useState<any[]>([]);
  const [syncStatuses, setSyncStatuses] = useState<any[]>([]);

  useEffect(() => {
    API.get('/app/companies/sync-status', { linkedInId: company.__linked_in_id }).then((result: any) => {
      setIntegrations(result.integrations);
      setSyncStatuses(result.syncStatuses);
      setIsLoading(false);
    });
  }, [company]);

  if (isLoading) {
    return <PageLoader fullHeight={false} style={{ height: 300 }} />;
  }

  return (
    <>
      {(integrations?.length > 0 &&
        integrations?.map(integration => {
          const syncStatus = syncStatuses.find(s => s.integrationId === integration.id);

          const showExternalButton =
            !!syncStatus?.externalId && (integration.type === 'HUBSPOT' || integration.type === 'SALESFORCE');

          return (
            <div key={integration.id}>
              <h2>
                {titleCase(integration.type)}
                {syncStatus?.isSyncDisabled ? (
                  <Badge className="ms-3" bg="secondary">
                    Sync disabled
                  </Badge>
                ) : (
                  ''
                )}
              </h2>
              {(syncStatus && (
                <Table size="sm" className="table-nowrap table-fixed" bordered hover responsive>
                  <tbody>
                    <tr>
                      <td style={{ width: '40%' }}>{`${titleCase(integration.type)} ID`}</td>
                      <td style={{ width: '60%' }}>
                        {showExternalButton && (
                          <LoadingButton
                            className="float-end m-0"
                            size="sm"
                            variant="light"
                            onClick={() => navigateToCRMRecord(integration.id, syncStatus.externalId)}
                          >
                            Open Record
                          </LoadingButton>
                        )}
                        <div className="pt-2">{syncStatus.externalId}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>Last synced successfully at</td>
                      <td>{syncStatus.lastSyncedAt}</td>
                    </tr>
                    <tr>
                      <td>Last synced attempted at</td>
                      <td>{syncStatus.lastSyncAttemptAt}</td>
                    </tr>
                    <tr>
                      <td>Sync Status</td>
                      <td>
                        <Badge bg={syncStatus.lastSyncStatus === 'success' ? 'success' : 'danger'}>
                          {titleCase(syncStatus.lastSyncStatus)}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td>Last sync error</td>
                      <td>
                        {syncStatus.lastSyncError && (
                          <>
                            <div>{syncStatus.lastSyncError?.shortDescription}</div>
                            <div className="text-muted">{syncStatus.lastSyncError?.longDescription}</div>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>First synced successfully at</td>
                      <td>{syncStatus.firstSyncedAt}</td>
                    </tr>
                    <tr>
                      <td>Was created by GoodFit?</td>
                      <td>{syncStatus.wasCreatedByGoodfit ? 'Yes' : 'No'}</td>
                    </tr>
                  </tbody>
                </Table>
              )) || (
                <div style={{ marginBottom: '16px' }}>
                  Record has not been synced yet<br></br>
                </div>
              )}
            </div>
          );
        })) || <EmptyMessage message="No CRM integrations set up, please connect your CRM" />}
    </>
  );
}
