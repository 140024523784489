import { Alert, Card, CloseButton, Table } from 'react-bootstrap';
import { BreakingChanges, ClientConfigErrors } from './types.ts';

import { FiExternalLink } from 'react-icons/fi';
import isEmpty from 'lodash/isEmpty';

export function ErrorCard({
  clientName,
  errorsOpen,
  errors,
  closeErrorsCard
}: {
  clientName?: string;
  errorsOpen: boolean;
  errors: ClientConfigErrors | null;
  closeErrorsCard: () => void;
}) {
  if (!errorsOpen || !errors) return <></>;

  return (
    <Alert variant={'danger'}>
      <Card.Header>
        <h2>Config failed following validations</h2>
        <div style={{ fontSize: '1rem' }}>
          <CloseButton variant={'white'} onClick={() => closeErrorsCard()} />
        </div>
      </Card.Header>
      {!isEmpty(errors.validationsFailed?.fieldExternalNameIsNull) && (
        <>
          <h4>Please provide externalName for</h4>
          <ul>
            {errors?.validationsFailed?.fieldExternalNameIsNull?.map((error: any) => (
              <li key={JSON.stringify(error)} color={'white'}>
                Field: {error.index + 1} {error.internalName} {error.configId ? `: ${error.configId}` : ''}
              </li>
            ))}
          </ul>
        </>
      )}
      {!isEmpty(errors.validationsFailed?.fieldExternalNameNotUnique) && (
        <>
          <h4>Please make sure following rows have unique externalNames</h4>
          <ul>
            {errors?.validationsFailed?.fieldExternalNameNotUnique?.map((error: any) => (
              <li key={JSON.stringify(error)} color={'white'}>
                Field: {error.index + 1} {error.internalName} {error.externalName ? `: ${error.externalName}` : ''}
              </li>
            ))}
          </ul>
        </>
      )}
      {!isEmpty(errors.validationsFailed?.dataBlocksMissing) && (
        <>
          <h4>Following config Ids cannot be removed, they are used by some of the fields</h4>
          <ul>
            {errors?.validationsFailed?.dataBlocksMissing?.map((error: any) => (
              <li key={JSON.stringify(error)} color={'white'}>
                Field: {error}
              </li>
            ))}
          </ul>
        </>
      )}
      {errors.validationsFailed?.emptySourcingCriteria && (
        <>
          <h4>Sourcing criteria cannot be empty</h4>
          <ul>
            <li>Please add sourcing criteria and try again</li>
          </ul>
        </>
      )}
      {!isEmpty(errors.validationsFailed?.contactCriteriaMissingProperties) && (
        <>
          <h4>Following contact criteria are missing properties. Please provide them and try again</h4>
          <ul>
            {errors?.validationsFailed?.contactCriteriaMissingProperties?.map((error: any) => (
              <li key={JSON.stringify(error)} color={'white'}>
                Entry {error.index} is missing properties: {error.missingProperties.join(', ')}
              </li>
            ))}
          </ul>
        </>
      )}
      {!isEmpty(errors.breakingChanges) && (
        <>
          <h3>Removing following fields will break client's settings</h3>
          <ul>
            {errors?.breakingChanges?.map((error: BreakingChanges) => (
              <li key={JSON.stringify(error.fieldExternalName)} color={'white'}>
                {error.fieldExternalName}
                {!isEmpty(error.breakingTalkTracks) && (
                  <ul>
                    {error.breakingTalkTracks.map((tt, ind) => (
                      <li key={`tt-${tt}-${ind}`}>
                        Tag: {tt} <FiExternalLink onClick={() => window.open(`/app/${clientName}/tags`, '_blank')} />
                      </li>
                    ))}
                  </ul>
                )}
                {!isEmpty(error.breakingScoringConfigs) && (
                  <ul>
                    {error.breakingScoringConfigs.map((sc, ind) => (
                      <li key={`sc-${sc}-${ind}`}>
                        Scoring config: {sc}{' '}
                        <FiExternalLink onClick={() => window.open(`/app/${clientName}/scoring`, '_blank')} />
                      </li>
                    ))}
                  </ul>
                )}
                {!isEmpty(error.breakingIntegrations) && (
                  <ul>
                    {error.breakingIntegrations.map((int, ind) => (
                      <li key={`integration-${int}-${ind}`}>
                        Connector's mapping for field {int}{' '}
                        <FiExternalLink onClick={() => window.open(`/app/${clientName}/data-directory`, '_blank')} />
                      </li>
                    ))}
                  </ul>
                )}
                {!isEmpty(error.breakingSegments) && (
                  <ul>
                    {error.breakingSegments.map(seg => (
                      <li key={seg}>
                        Segment {seg}{' '}
                        <FiExternalLink
                          onClick={() => window.open(`/app/${clientName}/segments/view/${seg}`, '_blank')}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          To fix the problem either add the field back, or make sure the field is not referenced in the above.
        </>
      )}
      {errors.schemaErrors && errors.schemaErrors.length > 0 && (
        <Table bordered size="sm" style={{ backgroundColor: 'white', margin: 0 }}>
          <thead>
            <tr>
              <th>Path</th>
              <th>Sub Path</th>
              <th>Message</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {errors?.schemaErrors?.map((error: any) => (
              <tr key={JSON.stringify(error)}>
                <td>{error.instancePath}</td>
                <td>{error.schemaPath}</td>
                <td>{error.message}</td>
                <td>{JSON.stringify(error.params)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Alert>
  );
}
