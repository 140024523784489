import { Button } from 'react-bootstrap';

import { Integration } from 'src/apis/integrations';
import { useSyncStatus } from '../../hooks/useSyncStatus';
import { TooltipWrapper } from 'src/components/TooltipWrapper';
import LoadingButton from 'src/components/LoadingButton';

interface SyncNowButtonProps {
  integration: Integration;
  isSaving: boolean;
}

export const SyncNowButton: React.FC<SyncNowButtonProps> = ({ integration, isSaving }) => {
  const { isSyncing, isConnectorFaulty } = useSyncStatus(integration);

  const syncButtonText = `${integration.syncSegmentRecordsOnly ? 'Save & Sync Now' : 'Sync Now'}`;

  if (isSyncing || isConnectorFaulty) {
    const tooltipText = isConnectorFaulty
      ? 'There is a problem with this connector configuration'
      : 'A sync is currently in progress';

    return (
      <TooltipWrapper
        tooltipText={tooltipText}
        customComponent={
          <span>
            <Button size="sm" type="submit" disabled>
              {syncButtonText}
            </Button>
          </span>
        }
      />
    );
  }

  return (
    <LoadingButton size="sm" type="submit" disabled={isSaving} loading={isSaving}>
      {syncButtonText}
    </LoadingButton>
  );
};
