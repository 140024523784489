import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useEffect, useState } from 'react';
import { CoverageAnalysisDTO } from 'src/pages/staff/coverageAnalysis/types.ts';
import { deleteCoverageAnalysis, getAllCoverageAnalysis } from 'src/pages/staff/coverageAnalysis/apis.ts';
import { showConfirm } from 'src/utils/modals.ts';

import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { formatDate } from 'src/utils/string.ts';
import { FiX } from 'react-icons/fi';
import TableGrid from 'src/components/Table/TableGrid.tsx';
import SimplePaginator from 'src/components/Table/SimplePaginator.tsx';
import NavButton from 'src/components/NavButton.tsx';
import debounce from 'lodash/debounce';

const PAGE_SIZE = 10;

export default function CoverageAnalysisListPage({ title }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [coverageAnalyses, setCoverageAnalyses] = useState<CoverageAnalysisDTO[]>([]);
  const [search, setSearch] = useState<string>('');
  const [numPages, setNumPages] = useState(10);
  const [page, setPage] = useState(0);

  usePageTitle(title);

  useEffect(() => {
    setIsLoading(true);
    getAllCoverageAnalysis(search, PAGE_SIZE, page * PAGE_SIZE).then(cc => {
      setCoverageAnalyses(cc.coverageAnalyses);
      setNumPages(Math.ceil(cc.coverageAnalysesCnt / PAGE_SIZE));
      setIsLoading(false);
    });
  }, [search, page]);

  const onDelete = (id: string) => {
    showConfirm(
      'Are you sure you want to delete the coverage analysis?',
      'Yes, delete',
      'No, cancel',
      'Confirm deletion'
    ).then(isSure => {
      if (isSure) {
        setIsLoading(true);
        deleteCoverageAnalysis(id).then(() => {
          getAllCoverageAnalysis(search, PAGE_SIZE, page * PAGE_SIZE).then(cc => {
            setCoverageAnalyses(cc.coverageAnalyses);
            setNumPages(Math.ceil(cc.coverageAnalysesCnt / PAGE_SIZE));
            setIsLoading(false);
          });
        });
      }
    });
  };

  const setSearchDebounced = debounce(search => setSearch(search), 500);
  const table = useReactTable<CoverageAnalysisDTO>({
    columns: [
      {
        header: 'Name',
        id: 'name',
        accessorFn: row => row.name,
        enableSorting: false
      },
      {
        header: 'Client Name',
        id: 'client_name',
        accessorFn: row => row.clientName,
        enableSorting: false
      },
      {
        header: 'Domains',
        accessorKey: 'domains',
        accessorFn: row => row.domains?.length,
        enableSorting: false
      },
      {
        header: 'Last Modifed At',
        accessorKey: 'updatedAt',
        accessorFn: row => row.updatedAt && formatDate(row.updatedAt),
        enableSorting: false
      },
      {
        header: '',
        id: 'actions',
        enableSorting: false,
        cell: props => (
          <div className={'d-flex gap-2'}>
            <NavButton size="sm" to={`/admin/coverage-analysis/${props.row.original.id}`}>
              Open
            </NavButton>
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                onDelete(props.row.original.id as string);
              }}
            >
              <FiX />
            </Button>
          </div>
        )
      }
    ],
    data: coverageAnalyses,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  return (
    <NarrowLayout title={'Coverage Analyses'} preheader="Staff admin">
      <Card>
        <Card.Header>
          <Row className="align-items-center ">
            <Col className="p-1">
              <Form.Control
                className={''}
                placeholder={'search...'}
                onChange={event => setSearchDebounced(event.target.value)}
              />
            </Col>
            <Col xs="auto" className="d-flex justify-content-end p-1">
              <NavButton size="sm" to={`/admin/coverage-analysis/new`}>
                Start new analysis
              </NavButton>
            </Col>
          </Row>
        </Card.Header>
        <TableGrid
          table={table}
          isLoading={isLoading}
          emptyMessage="No Coverage analysis"
          numLoadingRows={PAGE_SIZE}
          className="coverage-analysis-table table-centered table-compact table-nowrap"
        />
        <div className="d-flex justify-content-end px-5 pb-3">
          <SimplePaginator pageIndex={page} totalPages={numPages} setPage={setPage} />
        </div>
      </Card>
    </NarrowLayout>
  );
}
