import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { useShallow } from 'zustand/react/shallow';

import { useIntegrationStore } from 'src/pages/settings/connectors/integrationsStore';
import { Segment } from 'src/pages/segments/types';
import { IntegrationMenuItem } from './IntegrationMenuItem';
import { DropdownToggle } from './DropdownToggle';
import { usePeriodic } from 'src/utils/usePeriodic';

interface SegmentSyncActionsProps {
  isLoading: boolean;
  segment: Segment;
}

export const SegmentSyncActions: React.FC<SegmentSyncActionsProps> = ({ isLoading, segment }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { integrations, fetchIntegrations } = useIntegrationStore(
    useShallow(state => ({
      integrations: state.integrations,
      fetchIntegrations: state.fetchIntegrations
    }))
  );

  // We use a periodic fetch here to keep the UI in sync with the sync status
  usePeriodic(fetchIntegrations, 5000);

  return (
    <Dropdown onToggle={setIsOpen} show={isOpen}>
      <DropdownToggle integrations={integrations} />

      {/* HACK: Dropdown.Menu never unmounts, this forces it to do so */}
      {isOpen && (
        <Dropdown.Menu>
          {integrations.map((integration, ind) => {
            const includeDivider = integrations.length > 1 && ind !== integrations.length - 1;

            return (
              <IntegrationMenuItem
                segment={segment}
                key={integration.id}
                isLoading={isLoading}
                integration={integration}
                includeDivider={includeDivider}
              />
            );
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
