import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import { Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { ClientConfigList } from './components/ClientConfigList.tsx';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useEffect, useState } from 'react';
import { ClientConfigShort } from 'src/apis/clients/types.ts';
import { getClientConfigs } from 'src/apis/clients/apis.ts';
import { useNavigate } from 'react-router-dom';
import ClientConfigModal from 'src/pages/staff/clientConfigs/components/ClientConfigModal.tsx';
import { usePeriodic } from 'src/utils/usePeriodic.ts';

function matchesFilters(cc: ClientConfigShort, search: string, group: string) {
  const matchesSearch = search ? cc.clientName.includes(search?.toLowerCase()) : true;
  let matchesGroup = true;
  if (group === 'all_active')
    matchesGroup =
      cc.lifecycleStage === 'CALIBRATION' ||
      cc.lifecycleStage === 'CLIENT_ONBOARDING' ||
      cc.lifecycleStage === 'CLIENT_ACTIVE';
  if (group === 'calibration') matchesGroup = cc.lifecycleStage === 'CALIBRATION';
  if (group === 'onboarding') matchesGroup = cc.lifecycleStage === 'CLIENT_ONBOARDING';
  if (group === 'active') matchesGroup = cc.lifecycleStage === 'CLIENT_ACTIVE';
  if (group === 'churned') matchesGroup = cc.lifecycleStage === 'CLIENT_CHURNED';
  return matchesSearch && matchesGroup;
}

function ClientListPage({ title }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [clientConfigsShort, setClientConfigsShort] = useState<ClientConfigShort[]>([]);
  const [search, setSearch] = useState<string>('');
  const [group, setGroup] = useState<string>('all_active');
  const navigate = useNavigate();

  usePageTitle(title);

  useEffect(() => {
    getClientConfigs().then(cc => {
      setIsLoading(false);
      setClientConfigsShort(cc);
    });
  }, []);

  usePeriodic(() => {
    getClientConfigs().then(cc => {
      setClientConfigsShort(cc);
    });
  }, 5000);

  return (
    <NarrowLayout title={'Clients'} preheader="Staff admin" maxWidthCols={12}>
      <Card>
        <Card.Header>
          <Row className="align-items-center ">
            <Col className="p-1">
              <Form.Control
                placeholder={'search...'}
                onChange={event => setSearch(event.target.value)}
                type="search"
                className="search"
              />
            </Col>
            <Col xs="auto" className="d-flex justify-content-end p-1">
              <Button size="sm" onClick={() => setShowModal(true)}>
                + Add Client Config
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Header className="mt-2">
          <Tabs activeKey={group} onSelect={activeTab => setGroup(activeTab ?? 'all_active')}>
            <Tab eventKey="all_active" title="All live clients"></Tab>
            <Tab eventKey="calibration" title="Calibration clients"></Tab>
            <Tab eventKey="onboarding" title="Onboarding clients"></Tab>
            <Tab eventKey="active" title="Active paying clients"></Tab>
            <Tab eventKey="churned" title="Churned clients"></Tab>
            <Tab eventKey="all" title="All records (inc test)"></Tab>
          </Tabs>
        </Card.Header>
        <Card.Body>
          <ClientConfigList
            clientConfigs={clientConfigsShort?.filter(cc => matchesFilters(cc, search, group))}
            isLoading={isLoading}
          />
        </Card.Body>
      </Card>
      <ClientConfigModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSubmit={async (name: string) => navigate(`/admin/clients/${name}?isNew=true`)}
      />
    </NarrowLayout>
  );
}

export default ClientListPage;
