import { ValueEditorProps } from 'react-querybuilder';
import { FilterOperator } from './types';
import { SchemaDataType } from 'src/auth';
import {
  CurrencyInput,
  DateInput,
  InputPair,
  NumberInput,
  PercentageInput,
  RelativeDateInput,
  TextInput
} from 'src/components/Filters/Inputs/inputs.tsx';
import ClientCompaniesFieldValueMultiSelect from 'src/components/Filters/ClientCompaniesFilters/ClientCompaniesFieldValueMultiSelect.tsx';

export type CustomValueEditorComponentProps = {
  fieldName: string;
  type: SchemaDataType;
  operator: FilterOperator;
  value: any;
  handleOnChange: (value: any) => void;
  disabled?: boolean;
};

export const CustomValueEditorComponent = ({
  fieldName,
  type,
  operator,
  value,
  handleOnChange,
  disabled
}: CustomValueEditorComponentProps) => {
  if (fieldName === '') return <></>;

  if (operator === FilterOperator.exists || operator === FilterOperator.notExists) return <></>;

  // Text input with typeahead selection
  if (
    (type === SchemaDataType.String ||
      type === SchemaDataType.Picklist ||
      type === SchemaDataType.Multipicklist ||
      type === SchemaDataType.Array) &&
    (operator === FilterOperator.stringEqualOneOf ||
      operator === FilterOperator.stringNotEqualOneOf ||
      operator === FilterOperator.stringContainsOneOf ||
      operator === FilterOperator.stringNotContainsOneOf ||
      operator === FilterOperator.arrayContainsAnyOf ||
      operator === FilterOperator.arrayNotContainsAnyOf)
  ) {
    const allowFreeTextEntry =
      operator === FilterOperator.stringContainsOneOf || operator === FilterOperator.stringNotContainsOneOf;
    return (
      <ClientCompaniesFieldValueMultiSelect
        fieldName={fieldName}
        onChange={handleOnChange}
        value={value}
        allowNew={allowFreeTextEntry}
        disabled={disabled}
      />
    );
  }

  // Standard free text input
  if (
    (type === SchemaDataType.String || type === SchemaDataType.Picklist) &&
    (operator === FilterOperator.stringContains || operator === FilterOperator.stringNotContains)
  ) {
    return <TextInput handleOnChange={handleOnChange} value={value} disabled={disabled} />;
  }

  // Array length, number input
  if (
    (type === SchemaDataType.Multipicklist || type === SchemaDataType.Array) &&
    (operator === FilterOperator.arrayLengthGte ||
      operator === FilterOperator.arrayLengthLt ||
      operator === FilterOperator.arrayLengthGt ||
      operator === FilterOperator.arrayLengthLte)
  ) {
    return <NumberInput handleOnChange={handleOnChange} value={value} disabled={disabled} />;
  }

  // Numeric types, number input
  if (type === SchemaDataType.Number || type === SchemaDataType.Percentage || type === SchemaDataType.Currency) {
    let InputType = NumberInput;
    if (type === SchemaDataType.Percentage) InputType = PercentageInput;
    if (type === SchemaDataType.Currency) InputType = CurrencyInput;

    if (operator === FilterOperator.between) {
      return <InputPair handleOnChange={handleOnChange} value={value} inputType={InputType} disabled={disabled} />;
    } else {
      return <InputType handleOnChange={handleOnChange} value={value} disabled={disabled} />;
    }
  }

  // Date, date input
  if (type === SchemaDataType.Date) {
    if (operator === FilterOperator.dateSinceRelative || operator === FilterOperator.dateBeforeRelative) {
      return <RelativeDateInput handleOnChange={handleOnChange} value={value} disabled={disabled} />;
    } else if (operator === FilterOperator.dateBetween) {
      return <InputPair handleOnChange={handleOnChange} value={value} inputType={DateInput} disabled={disabled} />;
    } else {
      return <DateInput handleOnChange={handleOnChange} value={value} disabled={disabled} />;
    }
  }

  return <></>;
};

export const CustomValueEditor = (props: ValueEditorProps & { disabled?: boolean }) => {
  const type = props.fieldData.type as SchemaDataType;
  const operator = props.operator as FilterOperator;
  const disabled = props.disabled as boolean;
  return (
    <CustomValueEditorComponent
      fieldName={props.field}
      type={type}
      operator={operator}
      value={props.value}
      handleOnChange={props.handleOnChange}
      disabled={disabled}
    />
  );
};
