import { useCallback } from 'react';
import Button from 'src/components/LoadingButton';
import toast from 'react-hot-toast';
import { FiDownload } from 'react-icons/fi';
import { triggerCsvExport } from 'src/apis/csvExports/apis';
import { useClient } from 'src/auth';
import { showModal } from 'src/utils/modals';
import { ExportMeta, ExportOptionsModal } from '../modals/ExportOptionsModal';
import { Segment } from '../../types';

export interface ExportButtonProps {
  segment: Segment;
  isLoading: boolean;
  personaName?: string;
  exportConfig: {
    columns?: string[];
    personaId?: string;
    include_active?: boolean;
    include_removed?: boolean;
    include_checked?: boolean;
  };
}

export default function ExportButton({ segment, exportConfig, isLoading, personaName }: ExportButtonProps) {
  const { clientName } = useClient();

  const triggerExport = useCallback(
    ({ exportName, exportType }: ExportMeta) => {
      toast.promise(
        triggerCsvExport(exportType, { ...exportConfig, segmentId: segment.id }, exportName),
        {
          loading: 'Starting export...',
          success: (
            <span>
              CSV export has been started, you will receive an email with the link to download it shortly. You can also
              monitor progress on the <a href={`/app/${clientName}/exports`}>exports page</a>
            </span>
          ),
          error: 'Could not start CSV export'
        },
        { success: { duration: 5000 } }
      );
    },
    [exportConfig, segment, clientName]
  );

  const showExportOptionsModal = (): Promise<unknown> => {
    return showModal(ExportOptionsModal, { segmentName: segment.name, personaName }, (exportMeta: ExportMeta) =>
      triggerExport(exportMeta)
    );
  };

  return (
    <Button size="sm" variant="white" disabled={isLoading} icon={<FiDownload />} onClick={showExportOptionsModal}>
      Export
    </Button>
  );
}
