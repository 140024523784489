import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { flexRender, Header } from '@tanstack/react-table';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

import { CompanySchema } from 'src/auth';
import { CompanyRecord } from '../types.ts';

interface TableHeaderCellProps {
  header: Header<CompanyRecord, unknown>;
  toggleSortColumn: (columnId: string) => void;
  schema: CompanySchema;
}

export function TableHeaderCell({ header, toggleSortColumn, schema }: TableHeaderCellProps) {
  const isFixedCell = header.id === 'name';
  const renderSorter = header.column.getCanSort();
  const currentSortDirection = header.column.getIsSorted() as string;

  return (
    <th key={header.id} className={renderSorter ? 'sortable' : ''} onClick={() => toggleSortColumn(header.id)}>
      <div className={isFixedCell ? 'fixed-cell' : ''}>
        <OverlayTrigger
          placement="bottom"
          overlay={props => {
            const fieldInfo = schema.find(f => f.fieldName === header.id);
            return <Tooltip {...props}>{fieldInfo?.description}</Tooltip>;
          }}
        >
          <div>
            {renderSorter && (
              <div className="table-column-sorter">
                <FaSortUp className={currentSortDirection === 'asc' ? 'table-column-sorter-selected' : ''} />
                <FaSortDown className={currentSortDirection === 'desc' ? 'table-column-sorter-selected' : ''} />
              </div>
            )}
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </div>
        </OverlayTrigger>
      </div>
    </th>
  );
}
