import { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CompaniesAPIResponse } from '../types';
import API from 'src/utils/API';
import toast from 'react-hot-toast';

export default function OpenWithButton({ state }: { state: any }) {
  const handleLinkedInSalesNavClick = useCallback(async () => {
    const toastId = toast.loading('Preparing...');

    const data = await API.post<CompaniesAPIResponse>(
      '/app/companies/search',
      {},
      {
        filters: state.filters,
        offset: 0,
        limit: 100,
        search: state.search,
        sortby: state.sortby,
        sortdirection: state.sortdirection
      }
    );

    const numberCompaniesLoaded = data.companies.length;
    const wasTruncated = numberCompaniesLoaded === 100;
    const linkedInIds = data.companies.map(c => c.__linked_in_id);
    const linkedInSalesNavUrl = makeLinkedInSalesNavUrl(linkedInIds);

    let message = 'LinkedIn Sales Navigator URL copied to clipboard.';
    if (wasTruncated) {
      message += ' Note only the first 100 companies are included.';
    }

    toast.dismiss(toastId);
    toast.success(message);

    navigator.clipboard.writeText(linkedInSalesNavUrl);
    //window.open(linkedInSalesNavUrl, '_blank');
  }, [state]);

  return (
    <Dropdown className="ms-2">
      <Dropdown.Toggle size="sm" variant="white" id="dropdown-basic">
        Open with
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={handleLinkedInSalesNavClick}>LinkedIn Sales Navigator</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function makeLinkedInSalesNavUrl(linkedInIds: number[]) {
  const idParts = linkedInIds.map(
    //(id: number) => `(id:urn%3Ali%3Aorganization%3A${id},selectionType:INCLUDED,parent:(id:0))`
    (id: number) => `(id:urn%3Ali%3Aorganization%3A${id})`
  );
  const url = `https://www.linkedin.com/sales/search/people?query=(filters:List((type:CURRENT_COMPANY,values:List(${idParts.join(',')}))))`;
  return url;
}
