import API from 'src/utils/API.ts';
import { ClientConfig, ClientConfigShort } from 'src/apis/clients/types.ts';
import { BreakingChanges } from 'src/pages/staff/clientConfigs/validation/types.ts';

export async function getClientConfigs(): Promise<ClientConfigShort[]> {
  return (await API.get<{ clientConfigs: any }>(`/admin/client-configs`, {})).clientConfigs.map(
    (cc: any) =>
      ({
        clientName: cc.clientName,
        lastDataSyncCompletedAt: cc.lastDataSyncCompletedAt ? new Date(cc.lastDataSyncCompletedAt) : null,
        lastDataSyncStartedAt: cc.lastDataSyncStartedAt ? new Date(cc.lastDataSyncStartedAt) : null,
        lifecycleStage: cc.lifecycleStage,
        scheduledForDeletionAt: cc.scheduledForDeletionAt ? new Date(cc.scheduledForDeletionAt) : null
      }) as ClientConfigShort
  );
}

export async function getClientConfig(clientName: string) {
  const ret = await API.get<{ schema: any; config: ClientConfig }>(`/admin/client-configs/${clientName}`, {});
  if (ret.config.scheduledForDeletionAt)
    ret.config.scheduledForDeletionAt = new Date(ret.config.scheduledForDeletionAt);
  return ret;
}

export async function upsertClientConfig(
  clientName: string,
  create: Partial<ClientConfig>
): Promise<{
  isValid: boolean;
  clientName: string;
  created: boolean;
  errors?: any;
  breakingChanges?: BreakingChanges[];
}> {
  return await API.put<{
    isValid: boolean;
    clientName: string;
    created: boolean;
    errors?: any;
  }>(`/admin/client-configs/${clientName}`, {}, create);
}

export async function triggerCCM(clientName: string, resetDataset: boolean = false) {
  return await API.post<{ executionId: string }>(`/admin/client-configs/${clientName}/trigger`, {}, { resetDataset });
}

export async function getFeatureFlags() {
  return await API.get<{ featureFlags: string[] }>(`/admin/feature-flags`, {});
}

export type DataSyncStatus = {
  status: 'NOT_RUN_YET' | 'COMPLETE' | 'FAILED' | 'IN_PROGRESS';
  percentage?: number;
  executionArn?: string;
  lastDataSyncCompletedAt?: Date;
};

export async function getDataSyncStatus(clientName: string) {
  return await API.get<DataSyncStatus>(`/admin/client-configs/${clientName}/data-sync-status`, {});
}
