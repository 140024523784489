import Button from 'src/components/LoadingButton';
import { FiDownload } from 'react-icons/fi';
import { useCallback } from 'react';
import { Form, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { triggerCsvExport } from 'src/apis/csvExports/apis';
import { useClient } from 'src/auth';
import { showModal } from 'src/utils/modals';
import { combineFiltersWithSearch } from '../utils';
import { CsvExportType } from 'src/apis/csvExports/types';

interface ExportMeta {
  exportName?: string;
  exportType: Extract<CsvExportType, 'DATASET' | 'DATASET_LINKEDIN_ADS'>;
}

export default function ExportButton({ filters, search, columns }: any) {
  const { appSettings, clientName } = useClient();
  const defaultColumns = appSettings.companiesTableFields;

  const triggerExport = useCallback(
    ({ exportName, exportType }: ExportMeta) => {
      const combinedFilters = combineFiltersWithSearch(search, filters);
      toast.promise(
        triggerCsvExport(
          exportType,
          { filters: combinedFilters, columns: columns ? columns : defaultColumns },
          exportName
        ),
        {
          loading: 'Starting export...',
          success: (
            <span>
              CSV export has been started, you will receive an email with the link to download it shortly. You can also
              monitor progress on the <a href={`/app/${clientName}/exports`}>exports page</a>
            </span>
          ),
          error: 'Could not start CSV export'
        },
        { success: { duration: 5000 } }
      );
    },
    [search, filters, columns, defaultColumns, clientName]
  );

  const showExportModal = (): Promise<unknown> =>
    showModal(ExportModal, {}, (exportMeta: ExportMeta) => triggerExport(exportMeta));

  return (
    <Button size="sm" className="ms-2" icon={<FiDownload />} onClick={showExportModal}>
      Export
    </Button>
  );
}

interface ModalProps {
  show: boolean;
  onSubmit(exportMeta: ExportMeta): any;
  onHide: () => void;
}

const ExportModal: React.FC<ModalProps> = ({ show, onSubmit, onHide }) => {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const exportMeta: ExportMeta = {
      exportName: formData.get('exportName')?.toString().trim() || undefined,
      exportType: formData.get('exportType')! as ExportMeta['exportType']
    };

    onSubmit(exportMeta);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>Export Options</Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3" controlId="exportName">
            <Form.Label>Export Name (optional)</Form.Label>
            {/* NOTE: `exportName` is VARCHAR(255) */}
            <Form.Control type="text" maxLength={255} name="exportName" placeholder="Dataset" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exportType">
            <Form.Label>Export Format</Form.Label>
            <Form.Select aria-label="Export format" name="exportType" defaultValue="DATASET">
              <option value="DATASET">GoodFit Dataset (default)</option>
              <option value="DATASET_LINKEDIN_ADS">LinkedIn Ads Audience Match Template</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="white" className="me-2" onClick={onHide}>
            Cancel
          </Button>

          <Button size="sm" type="submit">
            Export
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
