import { Outlet, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import Sitenav from './components/Sidenav';
import ErrorMessage from 'src/components/ErrorMessage';
import { FloatingHelpButton } from './components/FloatingHelpButton';
import { AppAlertBar } from './components/AppAlertBar';
import { useClient } from 'src/auth';
import { generateAlertsForClient } from 'src/utils/appAlerts';

export default function Dashboard() {
  const location = useLocation();
  const client = useClient();

  return (
    <>
      <Sitenav />
      <div className="main-content" style={{ paddingBottom: 50 }}>
        <AppAlertBar messages={generateAlertsForClient(client)} />
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs={12}>
              <ErrorBoundary key={location.pathname} fallback={<ErrorMessage />}>
                <Outlet />
              </ErrorBoundary>
            </Col>
          </Row>
        </Container>
      </div>
      <FloatingHelpButton />
    </>
  );
}
