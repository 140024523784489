import { Card, Row } from 'react-bootstrap';
import { Collapsable } from 'src/pages/staff/clientConfigs/components/Collapsable.tsx';
import { FiBarChart2, FiFlag, FiList, FiMicOff, FiPlusCircle } from 'react-icons/fi';
import { Toggle } from 'src/pages/staff/clientConfigs/components/Toggle.tsx';
import JsonEditor from 'src/components/JsonEditor/JsonEditor.tsx';
import { useEffect, useState } from 'react';
import { getFeatureFlags } from 'src/apis/clients/apis.ts';
import { ExcludeCompanyIds } from 'src/pages/staff/clientConfigs/components/ExcludeCompanyIds.tsx';
import { CustomDataFieldsEditor } from '../components/CustomDataFieldsEditor';
import { CustomDataField } from 'src/apis/clients/types';
import { MdOutlineSettingsApplications } from 'react-icons/md';
import { GrTest } from 'react-icons/gr';

type AdvancedTabProps = {
  schema: any;
  clientConfig: any;
  isEditing: boolean;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
};

export function AdvancedTab({
  schema,
  clientConfig,
  isEditing,
  handleChange,
  handleCommitChanges,
  handleChangeAndCommit
}: AdvancedTabProps) {
  const [featureFlags, setFeatureFlags] = useState<string[] | null>(null);

  useEffect(() => {
    getFeatureFlags().then(ff => setFeatureFlags(ff.featureFlags));
  }, []);

  return (
    <Card>
      <Card.Body>
        <Row className={'m-1'}>
          <Collapsable
            title={'Exclude Company Ids'}
            icon={<FiMicOff className={'mx-1'} />}
            onCollapse={handleCommitChanges}
          >
            <ExcludeCompanyIds
              isEditing={isEditing}
              schema={schema}
              clientConfig={clientConfig}
              handleChange={handleChange}
            />
          </Collapsable>
        </Row>
        <Row className={'m-1'}>
          <Collapsable title={'Enrichment'} icon={<FiPlusCircle className={'mx-1'} />} onCollapse={handleCommitChanges}>
            <h3>Enrichment destinations</h3>
            {[
              'builtwith',
              'crawler',
              'domain-profiler',
              'embeddings',
              'facebook',
              'facebook-ads',
              'instagram',
              'lighthouse',
              'linkedin',
              'similartech',
              'similarweb',
              'spyfu',
              'trustpilot'
            ].map(destination => (
              <Row key={destination}>
                <Toggle
                  flag={clientConfig.enrichmentDestinations?.includes(destination) as boolean}
                  label={destination}
                  isEditing={isEditing}
                  onChange={(update: any) => {
                    let enrichmentDestinations = clientConfig.enrichmentDestinations || [];
                    if (update) enrichmentDestinations.push(destination);
                    else enrichmentDestinations = enrichmentDestinations.filter((d: any) => d !== destination);
                    handleChangeAndCommit(enrichmentDestinations, 'enrichmentDestinations');
                  }}
                />
              </Row>
            ))}
            <div className={'m-2'} />
            <h3>Other enrichment destinations</h3>
            <JsonEditor
              schema={{
                items: {},
                type: 'array'
              }}
              referenceSchema={schema}
              json={clientConfig.otherEnrichmentStages}
              isEditing={isEditing}
              onChange={(update: any) => {
                handleChangeAndCommit(update, 'otherEnrichmentStages');
              }}
            />
          </Collapsable>
        </Row>
        <Row className={'m-1'}></Row>
        <Row className={'m-1'}>
          <Collapsable
            title={'Custom data fields'}
            icon={<FiList className={'mx-1'} />}
            onCollapse={handleCommitChanges}
          >
            <CustomDataFieldsEditor
              schema={schema}
              isEditing={isEditing}
              fields={clientConfig.customDataFields}
              onChange={(fields: CustomDataField[]) => {
                handleChangeAndCommit(fields, 'customDataFields');
              }}
            />
          </Collapsable>
        </Row>
        <Row className={'m-1'}>
          <Collapsable title={'CRM data'} icon={<FiBarChart2 className={'mx-1'} />} onCollapse={handleCommitChanges}>
            <Row>
              <Toggle
                flag={clientConfig.crmDataPullEnabled as boolean}
                label={'Enabled'}
                isEditing={isEditing}
                onChange={(update: any) => {
                  handleChangeAndCommit(update, 'crmDataPullEnabled');
                }}
              />
            </Row>
            <h3>Config</h3>
            <JsonEditor
              schema={schema.definitions.CrmDataPullConfig}
              referenceSchema={schema}
              json={clientConfig.crmDataPullConfig}
              isEditing={isEditing}
              onChange={(update: any) => {
                handleChangeAndCommit(update, 'crmDataPullConfig');
              }}
            />
          </Collapsable>
        </Row>
        <Row className={'m-1'}>
          <Collapsable title={'Feature flags'} icon={<FiFlag className={'mx-1'} />} onCollapse={handleCommitChanges}>
            {featureFlags?.map(destination => (
              <Row key={destination}>
                <Toggle
                  flag={clientConfig.gfAppFeatureFlags[destination]}
                  label={destination}
                  isEditing={isEditing}
                  onChange={(update: any) => {
                    const gfAppFeatureFlags = clientConfig.gfAppFeatureFlags || {};
                    gfAppFeatureFlags[destination] = update;
                    handleChangeAndCommit(gfAppFeatureFlags, 'gfAppFeatureFlags');
                  }}
                />
              </Row>
            ))}
          </Collapsable>
        </Row>
        <Row className={'m-1'}>
          <Collapsable
            title={'App settings'}
            icon={<MdOutlineSettingsApplications className={'mx-1'} />}
            onCollapse={handleCommitChanges}
          >
            <JsonEditor
              schema={schema?.gfAppSettings}
              referenceSchema={schema}
              json={clientConfig?.gfAppSettings}
              isEditing={isEditing}
              onChange={(update: any) => {
                handleChangeAndCommit(update, 'gfAppSettings');
              }}
            />
          </Collapsable>
        </Row>
        <Row className={'m-1'}>
          <Collapsable title={'Calibration mode'} icon={<GrTest className={'mx-1'} />} onCollapse={handleCommitChanges}>
            <Toggle
              flag={clientConfig.isCalibrationModeEnabled}
              label="Calibration mode enabled?"
              isEditing={isEditing}
              onChange={isSet => {
                handleChangeAndCommit(isSet, 'isCalibrationModeEnabled');
              }}
            />
            <h4>Calibration domains / company IDs</h4>
            <textarea
              rows={10}
              cols={50}
              value={clientConfig.calibrationModeCompanies?.join('\n') || ''}
              disabled={!isEditing}
              onChange={ev => {
                handleChangeAndCommit(ev.target.value.split('\n'), 'calibrationModeCompanies');
              }}
            ></textarea>
            <div className="text-muted">
              One per line. Max 1000. Companies must also be in sourcing criteria or added via enrichment.
            </div>
          </Collapsable>
        </Row>
      </Card.Body>
    </Card>
  );
}
