import { Dropdown } from 'react-bootstrap';
import { FiRefreshCw } from 'react-icons/fi';

import { Integration } from 'src/apis/integrations';
import { TooltipWrapper } from 'src/components/TooltipWrapper';

interface DropdownToggleProps {
  integrations: Integration[];
}

export const DropdownToggle: React.FC<DropdownToggleProps> = ({ integrations }) => {
  if (!integrations || integrations.length === 0) {
    return (
      <TooltipWrapper
        placement="left"
        tooltipText="No integrations have been configured"
        customComponent={
          <Dropdown.Toggle size="sm" variant="primary" disabled>
            <FiRefreshCw style={{ marginRight: 8 }} /> Sync
          </Dropdown.Toggle>
        }
      />
    );
  }

  return (
    <Dropdown.Toggle size="sm" variant="primary">
      <FiRefreshCw style={{ marginRight: 8 }} /> Sync
    </Dropdown.Toggle>
  );
};
