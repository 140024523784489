import FieldValueSelect from 'src/components/Filters/Inputs/FieldValueSelect.tsx';

export type FieldValueMultiSelectProps = {
  onChange: (value: any[]) => void;
  value: any[] | null;
  fieldName: string;
  allowNew?: boolean;
  style?: any;
  placeholder?: string;
  disabled?: boolean;
  loadOptions: (fieldName: string, search: string) => Promise<{ value: string; label: string }[]>;
  isMulti: boolean;
};

export default function CCMFieldValueMultiSelect({
  onChange,
  value,
  fieldName,
  allowNew,
  placeholder,
  style,
  disabled,
  loadOptions,
  isMulti
}: FieldValueMultiSelectProps) {
  return (
    <FieldValueSelect
      onChange={onChange}
      value={value}
      fieldName={fieldName}
      allowNew={allowNew}
      placeholder={placeholder}
      style={style}
      disabled={disabled}
      loadOptions={loadOptions}
      isMulti={isMulti}
    />
  );
}
