import React, { useState } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { SegmentSettingsFormProps } from '.';
import { validateSegmentName } from 'src/pages/segments/apis';

enum NAME_VALIDATION_ERRORS {
  IS_IN_USE = 'This name is already in use',
  EMPTY_STRING = 'Please provide a name for the segment',
  NETWORK_ERROR = 'Failed to validate name (Network Error)'
}

export function SegmentNameInputField({
  segment,
  updateSegment,
  isSegmentNameValid,
  setIsSegmentNameValid
}: SegmentSettingsFormProps) {
  const [error, setError] = useState<string | null>(null);
  const [isValidating, setIsValidating] = useState(false);

  const validateName: React.FocusEventHandler<HTMLInputElement> = async evt => {
    if (isValidating) return;

    const segmentName = evt.target.value.trim();

    // Handle empty strings
    if (!segmentName) {
      setIsSegmentNameValid(false);
      setError(NAME_VALIDATION_ERRORS.EMPTY_STRING);
      return;
    }

    setError(null);
    setIsValidating(true);

    validateSegmentName({ segmentName, ...(segment.id ? { segmentId: segment.id } : {}) })
      .then(({ isValid }) => {
        if (!isValid) setError(isValid ? null : NAME_VALIDATION_ERRORS.IS_IN_USE);
        setIsSegmentNameValid(isValid);
      })
      .catch(e => {
        console.warn(e);
        setIsSegmentNameValid(false);
        setError(NAME_VALIDATION_ERRORS.NETWORK_ERROR);
      })
      .finally(() => setIsValidating(false));
  };

  return (
    <div className="mb-4">
      <InputGroup className="mb-3 flex-wrap">
        <InputGroup.Text style={{ backgroundColor: '#f5f5f5' }}>Segment name</InputGroup.Text>
        <Form.Control
          size="sm"
          name="name"
          value={segment.name}
          placeholder="Untitled segment"
          isInvalid={!isSegmentNameValid}
          onChange={ev => updateSegment('name', ev.target.value)}
          onBlur={validateName}
        />
        {error && (
          <Form.Control.Feedback style={{ width: '100%' }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </div>
  );
}
