import { ListGroup, Row, Col, Placeholder } from 'react-bootstrap';

export default function ConnectorPlaceholder() {
  return (
    <ListGroup.Item>
      <Row className="align-items-center">
        <Col xs={5} className="ms-n2">
          <Placeholder as="div" animation="glow">
            <Placeholder xs={10} />
          </Placeholder>
          <Placeholder as="div" animation="glow">
            <Placeholder xs={3} />
          </Placeholder>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
