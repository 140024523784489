import ErrorMessage from 'src/components/ErrorMessage';
import { useAuth } from './hooks';
import { StaffPermission } from './types';

interface RequirePermissionsProps {
  staffPermissions?: StaffPermission[];
  children?: React.ReactNode;
}

export default function RequireStaffPermissions({ staffPermissions, children }: RequirePermissionsProps) {
  const { user } = useAuth();
  if (staffPermissions && !staffPermissions.every(permission => user?.staffPermissions.includes(permission))) {
    return (
      <ErrorMessage message="You don't have permissions to view this page. Please contact your account administrator." />
    );
  }

  return children;
}
