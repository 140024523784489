import { useReactTable, getCoreRowModel, getPaginationRowModel, ColumnDef } from '@tanstack/react-table';
import { ScoringRule } from 'src/apis/scoring/types';
import { CompanySchema, SchemaDataType, useClient } from 'src/auth';
import Avatar from 'src/components/Avatar';
import TableGrid from 'src/components/Table/TableGrid';
import { getReactTableCellRendererForType } from 'src/components/Table/cellRenderers';
import { CompanyRecord } from 'src/pages/companies/types';
import { getLogoForCompany } from 'src/utils/logos';
import { formatNumber } from 'src/utils/number';

type ScoringPreviewTableProps = {
  companies: any[];
  isLoading: boolean;
  rules: ScoringRule[];
  onSelectCompany: (companyId: string) => void;
};

function buildColumns(schema: CompanySchema, rules: ScoringRule[], onSelectCompany: (companyId: string) => void) {
  const referencedColumns = rules.map(r => r.field).filter(col => !!col) as string[];
  const selectedColumns = ['domain', 'name'];
  for (const col of referencedColumns) {
    if (!selectedColumns.includes(col)) selectedColumns.push(col);
  }

  const columns: ColumnDef<CompanyRecord>[] = [
    {
      header: 'Preview Score',
      accessorKey: '__previewScore',
      enableSorting: true,
      cell: props => {
        const company = props.row.original;
        return formatNumber(company.__previewScore, 1);
      }
    },
    {
      header: 'Company',
      accessorKey: 'name',
      enableSorting: true,
      cell: props => {
        const company = props.row.original;
        const companyName = company.name;
        const logoUrl = getLogoForCompany(company);
        return (
          <div className="text-start d-flex flex-row align-items-center">
            <Avatar size="xs" className="me-2" style={{ minWidth: 24, width: 24, height: 24 }}>
              <Avatar.Image
                src={logoUrl}
                alt={companyName}
                className="rounded-circle border-light border"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onSelectCompany(company.__company_id);
                }}
              />
            </Avatar>
            <a
              href="#"
              onClick={ev => {
                ev.preventDefault();
                onSelectCompany(company.__company_id);
                return false;
              }}
            >
              {companyName}
            </a>
          </div>
        );
      }
    },
    {
      header: 'Domain',
      accessorKey: '__domain',
      enableSorting: true
    }
  ];

  for (const col of selectedColumns) {
    const schemaField = schema.find(f => f.fieldName === col);
    if (schemaField) {
      if (col !== 'name' && col !== 'domain') {
        let actualType = schemaField.type;
        if (col.includes('year')) actualType = SchemaDataType.String; // Dont format year fields
        columns.push({
          header: `${schemaField?.displayName}`,
          accessorKey: col,
          enableSorting: false,
          cell: getReactTableCellRendererForType(actualType)
        });
      }
    }
  }

  return columns;
}

export default function ScoringPreviewTable({
  companies,
  isLoading,
  rules,
  onSelectCompany
}: ScoringPreviewTableProps) {
  const { companiesSchema } = useClient();
  const columns = buildColumns(companiesSchema, rules, onSelectCompany);

  const table = useReactTable({
    columns,
    data: companies ?? [],
    manualPagination: true,
    manualSorting: true,
    pageCount: 1,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  return (
    <TableGrid
      className="scoring-preview-table table-bordered table-nowrap"
      responsive
      isLoading={isLoading}
      table={table}
    />
  );
}
