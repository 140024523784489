import * as amplifyApi from 'aws-amplify/api';

import { v4 as uuidv4 } from 'uuid';
import { getCurrentClientName } from 'src/auth/ClientContext';
import isString from 'lodash/isString';

const SESSION_ID = uuidv4();

function mergeAndEncodeQueryParams(params: any) {
  const ret = {
    client: getCurrentClientName()
  } as Record<string, any>;
  Object.keys(params).forEach(function (key) {
    let value = params[key];
    if (value === null || value === undefined) return;
    // Encode none strings as JSON
    // E.g. numbers to raw number string
    // E.g. boolean to true / false
    // E.g. array to json array []
    if (!isString(value)) {
      value = JSON.stringify(value);
    }
    ret[key] = value;
  });

  return ret;
}

function defaultExtraHeaders() {
  // Logged in the app-backend to tell which instance of the app this is and version ID
  return { 'GoodFit-Session-ID': SESSION_ID, 'GoodFit-App-Version': (window as any).gfCurrentVersion };
}

export async function get<T>(path: string, queryParams: Record<string, any> = {}, options?: Record<string, any>) {
  const res = await amplifyApi.get({
    apiName: options?.apiName ?? 'defaultApi',
    path,
    options: { ...(options || {}), queryParams: mergeAndEncodeQueryParams(queryParams), headers: defaultExtraHeaders() }
  }).response;
  const json = (await res.body.json()) as T;
  return json;
}

export async function del(path: string, queryParams: Record<string, string> = {}, options?: Record<string, any>) {
  return await amplifyApi.del({
    apiName: options?.apiName ?? 'defaultApi',
    path,
    options: { ...(options || {}), queryParams: mergeAndEncodeQueryParams(queryParams), headers: defaultExtraHeaders() }
  }).response;
}

export async function post<T>(
  path: string,
  queryParams: Record<string, any> = {},
  body: any = {},
  options?: Record<string, any>
) {
  const res = await amplifyApi.post({
    apiName: options?.apiName ?? 'defaultApi',
    path,
    options: {
      ...(options || {}),
      queryParams: mergeAndEncodeQueryParams(queryParams),
      body,
      headers: defaultExtraHeaders()
    }
  }).response;

  try {
    const json = (await res.body.json()) as T;
    return json;
  } catch (e) {
    // Response has no body; e.g. 204 status code
    return {} as T;
  }
}

export async function put<T>(
  path: string,
  queryParams: Record<string, any> = {},
  body: any = {},
  options?: Record<string, any>
) {
  const res = await amplifyApi.put({
    apiName: options?.apiName ?? 'defaultApi',
    path,
    options: {
      ...(options || {}),
      queryParams: mergeAndEncodeQueryParams(queryParams),
      body,
      headers: defaultExtraHeaders()
    }
  }).response;
  const json = (await res.body.json()) as T;
  return json;
}

export async function patch<T>(
  path: string,
  queryParams: Record<string, any> = {},
  body: any = {},
  options?: Record<string, any>
) {
  const res = await amplifyApi.patch({
    apiName: options?.apiName ?? 'defaultApi',
    path,
    options: {
      ...(options || {}),
      queryParams: mergeAndEncodeQueryParams(queryParams),
      body,
      headers: defaultExtraHeaders()
    }
  }).response;
  const json = (await res.body.json()) as T;
  return json;
}

export default {
  get,
  post,
  del,
  put,
  patch
};
