import { Offcanvas } from 'react-bootstrap';
import { RecordType } from '../../pages/data-directory/types';
import { BasicField } from 'src/auth';
import { useEffect, useState } from 'react';
import FieldStatsOffcanvasValuesTable from './components/FieldStatsOffcanvasValuesTable';
import FieldStatsOffcanvasMetrics from './components/FieldStatsOffcanvasMetrics';
import { fetchFieldStats } from 'src/apis/data/apis';

export type FieldInfoOffcanvasProps = {
  show: boolean;
  field: BasicField | null;
  recordType: RecordType | null;
  onHide: () => void;
};

export default function FieldInfoOffcanvas({ show, field, recordType, onHide }: FieldInfoOffcanvasProps) {
  const [fieldStats, setFieldStats] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const shouldLoadStats = field?.fieldSource !== 'meta';

  useEffect(() => {
    // Reset if field changes
    setFieldStats(null);
  }, [field?.fieldName, recordType]);

  useEffect(() => {
    if (field && recordType && shouldLoadStats) {
      setIsLoading(true);
      fetchFieldStats(recordType, field.fieldName, 1000).then(result => {
        setFieldStats(result);
        setIsLoading(false);
      });
    }
  }, [recordType, field, shouldLoadStats]);

  const dynamicConfig = fieldStats?.field?.dynamicConfig;
  const showDynamicConfig = !!dynamicConfig;

  return (
    <Offcanvas placement="end" onHide={onHide} style={{ width: 800 }} show={show}>
      <Offcanvas.Header closeButton>Field: {field?.displayName}</Offcanvas.Header>
      <Offcanvas.Body>
        <h3>Description</h3>
        <p className="mb-5">{field?.description}</p>

        {shouldLoadStats ? (
          <>
            <FieldStatsOffcanvasMetrics isLoading={isLoading} fieldStats={fieldStats} fieldType={field?.type} />

            <FieldStatsOffcanvasValuesTable fieldStats={fieldStats} isLoading={isLoading} />

            {showDynamicConfig && (
              <>
                <hr />
                <h4>Configuration</h4>
                <pre className="border border-grey" style={{ background: '#f5f5f5', fontSize: 10, padding: 8 }}>
                  {JSON.stringify(dynamicConfig, null, 2)}
                </pre>
              </>
            )}
          </>
        ) : (
          <p className="text-muted">Coverage statistics cannot be shown for system fields</p>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
