import { Button, Form, Modal } from 'react-bootstrap';
import { CsvExportType } from 'src/apis/csvExports/types';

export interface ExportMeta {
  exportName?: string;
  exportType: Extract<CsvExportType, 'SEGMENT' | 'SEGMENT_LINKEDIN_ADS'>;
}

interface ModalProps {
  show: boolean;
  onSubmit(exportMeta: ExportMeta): any;
  onHide: () => void;
  segmentName: string;
  personaName?: string;
}

export const ExportOptionsModal: React.FC<ModalProps> = ({ show, segmentName, personaName, onSubmit, onHide }) => {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const exportMeta: ExportMeta = {
      exportName: formData.get('exportName')?.toString().trim() || undefined,
      exportType: formData.get('exportType')! as ExportMeta['exportType']
    };

    onSubmit(exportMeta);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>Export Options</Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3" controlId="exportName">
            <Form.Label>Export Name (optional)</Form.Label>
            {/* NOTE: `exportName` is VARCHAR(255) */}
            <Form.Control
              type="text"
              maxLength={255}
              name="exportName"
              placeholder="Segment"
              defaultValue={personaName ?? segmentName}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exportType">
            <Form.Label>Export Format</Form.Label>
            <Form.Select aria-label="Export format" name="exportType" defaultValue="DATASET">
              <option value="SEGMENT">GoodFit Dataset (default)</option>
              <option value="SEGMENT_LINKEDIN_ADS">LinkedIn Ads Audience Match Template</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="white" className="me-2" onClick={onHide}>
            Cancel
          </Button>

          <Button size="sm" type="submit">
            Export
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
