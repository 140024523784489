import { Button, ListGroup } from 'react-bootstrap';
import { useClient } from 'src/auth';
import EmptyMessage from 'src/components/EmptyMessage';
import ScoringAnalysisReportPlaceholder from './ScoringAnalysisReportPlaceholder';
import ScoringAnalysisReportItem from './ScoringAnalysisReportItem';
import { FiSliders } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function ScoringAnalysisReportList({ scoringAnalysisReports, isLoading, handleDelete }: any) {
  const navigate = useNavigate();
  const { clientName } = useClient();
  if (isLoading) {
    return (
      <ListGroup className="list-group-flush my-n3">
        <ScoringAnalysisReportPlaceholder />
        <ScoringAnalysisReportPlaceholder />
        <ScoringAnalysisReportPlaceholder />
      </ListGroup>
    );
  }

  if (scoringAnalysisReports.length === 0) {
    return (
      <EmptyMessage
        message="You don't have any scoring analysis reports"
        subMessage={
          <>
            Find out about how scoring works:{' '}
            <a
              href="https://docs.goodfit.io/product-docs/guides/prioritisation/how-to-build-your-first-score"
              target="_blank"
            >
              Scoring documentation
            </a>
          </>
        }
        icon={<FiSliders />}
        actions={
          <Button size="sm" variant="primary" onClick={() => navigate(`/app/${clientName}/scoring/analysis/new`)}>
            Create your first scoring analysis
          </Button>
        }
      />
    );
  }

  return (
    <ListGroup className="list-group-flush my-n3">
      {scoringAnalysisReports.map((scoringAnalysisReport: any) => (
        <ScoringAnalysisReportItem
          key={scoringAnalysisReport.id}
          scoringAnalysisReport={scoringAnalysisReport}
          handleDelete={handleDelete}
        />
      ))}
    </ListGroup>
  );
}
