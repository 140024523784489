import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDef, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import TableGrid from 'src/components/Table/TableGrid.tsx';
import { ColumnFilter } from '@tanstack/table-core';
import { Form } from 'react-bootstrap';
import SimplePaginator from 'src/components/Table/SimplePaginator.tsx';
import { ListFunction } from 'src/components/Table/listTable/types.ts';
import { usePeriodic } from 'src/utils/usePeriodic.ts';
import { Debouncer } from 'src/utils/Debouncer.ts';
import './list-table.scss';

const debouncer = new Debouncer(1000);

export function ListTable({
  columns,
  list,
  addButton,
  pollInterval,
  shouldPollData = false,
  emptyMessage = 'No data found',
  pageSize = 10
}: {
  columns: ColumnDef<any>[];
  list: ListFunction;
  addButton?: JSX.Element;
  pollInterval?: number;
  shouldPollData?: boolean;
  emptyMessage?: string;
  pageSize?: number;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState('');
  const [uiSearch, setUiSearch] = useState('');
  const [columnFilter, setColumnFilter] = useState<ColumnFilter[]>([]);
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(0);
  const [data, setData] = useState<any[]>([]);

  const tableColumns = useMemo(() => {
    return columns;
  }, [columns]);

  const table = useReactTable({
    columns: tableColumns,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      // @ts-expect-error to provide a method to refreshTable into state object, so we can force refresh it from the cell
      refreshTable: () => fetchData(true)
    },
    manualSorting: true,
    manualFiltering: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilter
  });

  const fetchData = useCallback(
    (showRefresh = false) => {
      showRefresh && setIsLoading(showRefresh);
      const sortParam = sorting.length ? `${sorting[0].id}:${sorting[0].desc ? 'desc' : 'asc'}` : '';
      const filtering = [{ id: 'search', value: search }, ...columnFilter];
      list(filtering, sortParam, page * pageSize, pageSize)
        .then(response => {
          setData(response.items);
          setNumPages(Math.ceil(response.count / pageSize));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [sorting, search, columnFilter, list, page, pageSize]
  );

  usePeriodic(
    () => shouldPollData && debouncer.schedule(() => fetchData(false), 25),
    pollInterval || 5000,
    [fetchData, shouldPollData],
    false
  );

  useEffect(() => {
    // This will trigger if any of [sorting, search, columnFilter, list, page, pageSize] changes
    setIsLoading(true);
    // Using debouncer here to prevent double loading on mount in test mode
    debouncer.schedule(() => fetchData(true), 25);
  }, [fetchData]);

  useEffect(() => {
    setPage(0);
  }, [search, columnFilter]);

  return (
    <>
      <div className={'d-flex justify-content-between list-table-search-bar'}>
        <div>
          <Form.Control
            size={'sm'}
            type="search"
            className="search mb-2"
            placeholder="Search"
            value={uiSearch}
            onChange={e => {
              setUiSearch(e.target.value);
              debouncer.schedule(() => {
                setSearch(e.target.value);
              });
            }}
          />
        </div>
        <div> {addButton}</div>
      </div>
      <TableGrid
        table={table}
        isLoading={isLoading}
        numLoadingRows={pageSize}
        className="table-centered table-compact table-nowrap"
        showEmptyTable={true}
        emptyMessage={emptyMessage}
      />
      <div className="d-flex justify-content-end px-5 pb-3">
        <SimplePaginator pageIndex={page} totalPages={numPages} setPage={setPage} />
      </div>
    </>
  );
}
