import { DataDirectoryEntry } from './types';
import { Integration } from 'src/apis/integrations';

function downloadFile(blob: Blob, fileName: string) {
  const url = URL.createObjectURL(blob);
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', fileName);
  pom.click();
}

export function downloadFields(fields: DataDirectoryEntry[], integrations: Integration[], fileName: string) {
  // Create a blob
  const headers = ['Field Name', 'Description', 'Date Type', 'CRM Mappings', 'Field data name'];
  const rows = fields.map(f => {
    const row = [
      f.displayName,
      f.description ?? '',
      f.type,
      f.mappings
        .map(m => {
          const integration = integrations.find(i => i.id === m.integrationId);
          if (integration) {
            return `${integration.integrationType}: ${m.externalName}`;
          }
          return '';
        })
        .join('\n'),
      f.fieldName
    ];

    return row;
  });

  console.log(fields);
  console.log(integrations);
  const csvData = [headers, ...rows].map(r => r.map(c => `"${c?.replace(/"/g, '')}"`).join(',')).join('\n');
  const blob = new Blob([csvData], { type: 'text/csv' });
  downloadFile(blob, fileName);
}
