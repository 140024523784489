import { useCallback } from 'react';
import { fetchFieldOptions } from 'src/apis/data/apis.ts';
import FieldValueSelect from 'src/components/Filters/Inputs/FieldValueSelect.tsx';

export type FieldValueMultiSelectProps = {
  onChange: (value: any[]) => void;
  value: any[] | null;
  fieldName: string;
  allowNew?: boolean;
  style?: any;
  placeholder?: string;
  disabled?: boolean;
};

export default function ClientCompaniesFieldValueMultiSelect({
  onChange,
  value,
  fieldName,
  allowNew,
  placeholder,
  style,
  disabled
}: FieldValueMultiSelectProps) {
  const loadOptions = useCallback(async (fieldName: string, search: string) => {
    const res: any = await fetchFieldOptions(fieldName, search);
    return res.values.map((v: any) => ({
      value: v,
      label: v
    }));
  }, []);

  return (
    <FieldValueSelect
      onChange={onChange}
      value={value}
      fieldName={fieldName}
      allowNew={allowNew}
      placeholder={placeholder}
      style={style}
      disabled={disabled}
      loadOptions={loadOptions}
    />
  );
}
