import React from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi';

import { UnlockResourceButton } from '../../UnlockResourceButton';
import { useResourceLockStore } from '../../../resourceLockStore';
import { secureLockForResource } from 'src/apis/resourceLocks/apis';
import { ResourceLock } from 'src/apis/resourceLocks/types';

interface LockedResourceModalContentProps {
  resourceLock: ResourceLock;
  handleClose(): void;
}

export const LockedResourceModalContent: React.FC<LockedResourceModalContentProps> = ({
  resourceLock,
  handleClose
}) => {
  const { isResourceStale, isResourceLocked } = useResourceLockStore(
    resourceLock.resourceType,
    resourceLock.resourceId,
    state => ({
      isResourceStale: state.isResourceStale,
      isResourceLocked: state.isResourceLocked
    })
  );

  const overrideExistingLock: React.FormEventHandler<HTMLFormElement> = async evt => {
    evt.preventDefault();
    await secureLockForResource(resourceLock.resourceType, resourceLock.resourceId);
    handleClose();
  };

  // Only render the locked UI if the resource is locked and not stale
  if (!isResourceLocked || isResourceStale) return null;

  return (
    <Form onSubmit={overrideExistingLock}>
      <Modal.Header closeButton onHide={handleClose}>
        <FiAlertTriangle className="me-2" fontSize={20} color="red" />
        Resource Locked
      </Modal.Header>

      <Modal.Body>
        This resource is currently being edited by{' '}
        <a href={`mailto:${resourceLock.lockedBy}`}>{resourceLock.lockedBy}</a>.
        <br />
        <br />
        If you're sure this resource is no longer in use, unlock it using the button below.
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="white" className="me-2" onClick={handleClose}>
          Cancel
        </Button>

        <UnlockResourceButton resourceType={resourceLock.resourceType} resourceId={resourceLock.resourceId} />
      </Modal.Footer>
    </Form>
  );
};
