import { useCallback, useEffect, useState } from 'react';
import './../filters.scss';
import Filters, { FilterContext } from 'src/components/Filters/Filters.tsx';
import { CompanyCriteriaGroup, DataBlockFieldDataType } from 'src/apis/clients/types.ts';
import { getOperatorsForField } from 'src/components/Filters/CCMFilters/operators.ts';
import { CustomValueEditor } from './CustomValueEditor.tsx';
import { FieldMapping } from './services/types.ts';
import { fetchFieldOptions } from 'src/apis/fields/apis.ts';
import { findMapping, mapSourcingCriteria } from 'src/components/Filters/CCMFilters/services/service.ts';
import { ValueEditorProps } from 'react-querybuilder';

type AdapterFilterProps = {
  fieldMapping: FieldMapping[];
  setQuery: (sourcingCriteria: CompanyCriteriaGroup) => void;
  defaultQuery: any;
  disabled?: boolean;
  dataBlockConfigs: any[];
  ruleActionOrnament?: (f: any, disabled: boolean | undefined) => JSX.Element;
  groupActionOrnament?: (f: any, disabled: boolean | undefined) => JSX.Element;
  context?: FilterContext;
};

const cache: {
  [key: string]: {
    status: 'running' | 'done';
    result: any;
  };
} = {};

function cacheKey(fieldName: string, search: string, dataBlockConfig: any) {
  return `${fieldName}_${search}_${JSON.stringify(dataBlockConfig)}`;
}

function FiltersAdapter({
  fieldMapping,
  setQuery,
  defaultQuery,
  disabled = false,
  dataBlockConfigs,
  ruleActionOrnament,
  groupActionOrnament,
  context
}: AdapterFilterProps) {
  const [fields, setFields] = useState<{ name: string; label: string; type?: string; group?: string }[]>(() => {
    if (fieldMapping)
      return (
        [
          { name: 'Select', label: 'Select' },
          ...fieldMapping.map(f => ({
            name: f.name,
            label: f.displayName,
            type: f.type,
            group: f.group
          }))
        ] || []
      );
    return [];
  });

  useEffect(() => {
    if (fieldMapping)
      setFields([
        { name: 'Select', label: 'Select' },
        ...fieldMapping.map(f => ({
          name: f.name,
          label: f.displayName,
          type: f.type,
          group: f.group
        }))
      ]);
  }, [fieldMapping]);

  const getOperators = useCallback(
    (field: string) => {
      const fieldDefinition = fieldMapping.find(f => f.name === field) as { type: DataBlockFieldDataType } | undefined;

      if (!fieldDefinition || !fieldDefinition.type) return [];

      return getOperatorsForField(fieldDefinition.type);
    },
    [fieldMapping]
  );

  const loadOptions = useCallback(
    async (fieldName: string, search: string) => {
      // A hack:
      // there is a problem with how CCMFieldValueMultiSelect loads values in async mode. Sometimes when the load takes to long, it retries over and over again
      // to avoid it here is a cache that will prevent it from going in an infinite loop.
      // tried to fix it on the CCMFieldValueMultiSelect but couldn't find a solution in a short time and this is a quick fix
      // Ideally we would solve it long term
      const fieldMap = findMapping({ field: fieldName }, fieldMapping, 'to');
      let dataBlockConfig = null;
      if (fieldMap?.configId)
        dataBlockConfig = dataBlockConfigs.find((config: any) => config.configId === fieldMap?.configId);
      const key = cacheKey(fieldName, search, dataBlockConfig);

      const cacheEntry = cache[key];
      if (cacheEntry && cacheEntry.status === `done`) {
        return cacheEntry.result;
      } else if (cacheEntry?.status === 'running') {
        // return a promise that will resolve when the cache is done
        return new Promise(resolve => {
          const interval = setInterval(() => {
            const cacheEntry = cache[key];
            if (cacheEntry?.status === 'done') {
              clearInterval(interval);
              resolve(cacheEntry.result);
            }
          }, 2000);
        });
      }
      cache[key] = { status: 'running', result: [] };
      let result = [];
      try {
        const res: any = await fetchFieldOptions(
          { internalName: fieldMap?.dataBlockField, configId: fieldMap?.configId },
          dataBlockConfig,
          search
        );
        result = (res.values || ['']).map((v: any) => ({
          value: v,
          label: v
        }));
      } catch (e) {
        // fail siliently
      }
      cache[key] = { status: 'done', result };
      return result;
    },
    [dataBlockConfigs, fieldMapping]
  );

  return (
    <Filters
      fields={fields}
      getOperators={getOperators}
      setQuery={setQuery}
      defaultQuery={defaultQuery}
      disabled={disabled}
      valueEditor={(props: ValueEditorProps) => (
        <CustomValueEditor {...props} disabled={props.disabled} loadOptions={loadOptions} />
      )}
      ruleActionOrnament={ruleActionOrnament}
      groupActionOrnament={groupActionOrnament}
      context={context}
    />
  );
}

type CCMFiltersProps = {
  dataBlockConfigs: any[];
  sourcingCriteria: CompanyCriteriaGroup;
  fieldMapping: FieldMapping[];
  handleChange: (sourcingCriteria: CompanyCriteriaGroup) => void;
  disabled: boolean;
  ruleActionOrnament?: (f: any, disabled: boolean | undefined) => JSX.Element;
  groupActionOrnament?: (f: any, disabled: boolean | undefined) => JSX.Element;
  context?: FilterContext;
};

export default function CCMFilters({
  dataBlockConfigs,
  sourcingCriteria,
  fieldMapping,
  handleChange,
  disabled,
  ruleActionOrnament,
  groupActionOrnament,
  context
}: CCMFiltersProps) {
  const [mappedSourcingCriteria, setMappedSourcingCriteria] = useState<any>();
  useEffect(() => {
    if (sourcingCriteria && fieldMapping) {
      const mappedCriteria = mapSourcingCriteria(sourcingCriteria, fieldMapping, 'from');
      setMappedSourcingCriteria(mappedCriteria);
    }
  }, [sourcingCriteria, fieldMapping]);

  if (!fieldMapping || !mappedSourcingCriteria || !fieldMapping) return <>Loading</>;
  return (
    <FiltersAdapter
      fieldMapping={fieldMapping}
      setQuery={sourcingCriteria => {
        handleChange(mapSourcingCriteria(sourcingCriteria, fieldMapping, 'to'));
      }}
      defaultQuery={mappedSourcingCriteria}
      disabled={disabled}
      dataBlockConfigs={dataBlockConfigs}
      ruleActionOrnament={ruleActionOrnament}
      groupActionOrnament={groupActionOrnament}
      context={context}
    />
  );
}
