import { Form } from 'react-bootstrap';

import { integrationToast } from '../../utils';
import { useIntegrationStore } from '../../../../integrationsStore';
import { Integration } from 'src/apis/integrations';

import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { SegmentSyncSettings } from './SegmentSyncSettings';

interface AutoSyncSettingsFormProps {
  integration: Integration;
}

export function AutoSyncSettingsForm({ integration }: AutoSyncSettingsFormProps) {
  const updateConnector = useIntegrationStore(state => state.updateIntegration);

  const handleAutoSyncValue: React.ChangeEventHandler<HTMLInputElement> = async ev => {
    await integrationToast(
      async () =>
        await updateConnector(integration.id as string, {
          enableAutoSync: ev.target.checked
        })
    );
  };

  return (
    <>
      <Form.Group className="mb-3" controlId="enableAutoSync">
        <Form.Check
          type="switch"
          name="enableAutoSync"
          disabled={!integration?.id}
          onChange={handleAutoSyncValue}
          checked={integration?.enableAutoSync}
          label={
            <span className="d-flex align-items-center gap-2">
              <span>Auto-sync</span>
              <TooltipWrapper
                placement="bottom"
                tooltipText="Enabling auto sync will mean all records that are updated will automatically be synced to your CRM. You can expect updated records to be synced to your system every 24 hours."
              />
            </span>
          }
        />
      </Form.Group>

      <SegmentSyncSettings integration={integration} />
    </>
  );
}
