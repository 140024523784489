import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useAuth } from 'src/auth';

export default function Analytics() {
  const { user } = useAuth();
  const posthog = usePostHog();

  if (posthog) {
    // Store it later as need to use it in router callback
    (window as any)._posthog = posthog;
  }

  const path = window.location.pathname;
  useEffect(() => {
    const pathParts = path.split('/');
    const clientName = pathParts[1] === 'app' && pathParts[2] ? pathParts[2] : 'none';

    if (posthog && user?.id) {
      posthog.identify(user?.email, {
        email: user?.email,
        clientName
      });
      posthog.group('client', clientName);
      console.log('Posthog init', [user?.email, clientName, user?.id]);
      posthog.capture('appStart');
    }
  }, [posthog, user?.email, path, user?.id]);

  useEffect(() => {
    const w = window as any;
    if (user && w.HelpHero) {
      console.log('HelpHero init', [user?.id]);
      w.HelpHero.identify(user.id);
    }
  }, [user]);

  return <></>;
}
