import { ClientEnrichmentStatus, EnricherStatus } from 'src/pages/staff/sourcerEnrichment/types.ts';
import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { titleCase } from 'src/utils/string.ts';
import TableGrid from 'src/components/Table/TableGrid.tsx';
import { Button } from 'react-bootstrap';
import { FiActivity, FiCheck } from 'react-icons/fi';
import EmptyMessage from 'src/components/EmptyMessage.tsx';
import Tag from 'src/components/Tag.tsx';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

export function SourcerEnrichmentList({
  isLoading,
  isRunning,
  clientEnrichmentStatus,
  runCheck
}: {
  isLoading: boolean;
  isRunning: boolean;
  clientEnrichmentStatus: ClientEnrichmentStatus | undefined;
  runCheck: () => void;
}) {
  const table = useReactTable<EnricherStatus>({
    columns: [
      {
        header: 'Sourcer',
        id: 'sourcer',
        accessorFn: row => titleCase(row.enricher),
        enableSorting: false
      },
      {
        header: 'ETA',
        id: 'eta',
        enableSorting: false,
        cell: c => (
          <>
            {(c.row.original.eta.expected === 0 && <FiCheck color={'green'} />) ||
              `${Number(c.row.original.eta.expected).toFixed(1)} min`}
          </>
        )
      },
      {
        header: 'Total',
        id: 'Total',
        accessorFn: row => Number(row.clientEnrichment.Total),
        enableSorting: false
      },
      {
        header: 'Parsed',
        id: 'parsed',
        accessorFn: row => Number(row.clientEnrichment.Parsed),
        enableSorting: false
      },
      {
        header: 'Unavailable',
        id: 'Unavailable',
        accessorFn: row => Number(row.clientEnrichment.Unavailable),
        enableSorting: false
      },
      {
        header: 'Scheduled',
        id: 'Scheduled',
        enableSorting: false,
        cell: c => {
          const color =
            Number(c.row.original.clientEnrichment.Scheduled) +
              Number(c.row.original.clientEnrichment['Waiting to be Scheduled']) ===
            0
              ? 'green'
              : 'info';
          return (
            <Tag color={color}>
              {Number(c.row.original.clientEnrichment.Scheduled) +
                Number(c.row.original.clientEnrichment['Waiting to be Scheduled'])}
            </Tag>
          );
        }
      },
      {
        header: 'Stuck',
        id: 'Stuck',
        enableSorting: false,
        cell: c => {
          const color =
            Number(c.row.original.clientEnrichment['Stuck In Scheduled']) +
              Number(c.row.original.clientEnrichment['Stuck In Waiting to be Scheduled']) >
            0
              ? 'red'
              : 'green';
          return (
            <Tag color={color}>
              {Number(c.row.original.clientEnrichment['Stuck In Scheduled']) +
                Number(c.row.original.clientEnrichment['Stuck In Waiting to be Scheduled'])}
            </Tag>
          );
        }
      },
      {
        header: 'Retry',
        id: 'Retry',
        enableSorting: false,
        cell: c => {
          const color = Number(c.row.original.clientEnrichment.Retry) > 0 ? 'red' : 'green';
          return <Tag color={color}>{Number(c.row.original.clientEnrichment.Retry) || 0}</Tag>;
        }
      },
      {
        header: 'Error',
        id: 'Error',
        accessorFn: row => Number(row.clientEnrichment.Error),
        enableSorting: false
      }
    ],
    data: sortBy(clientEnrichmentStatus?.output?.enrichmentStatus || [], 'enricher'),
    manualPagination: true,
    manualSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  if (clientEnrichmentStatus === undefined) {
    return <EmptyMessage message="No client selected" icon={<FiActivity />} />;
  }

  if (clientEnrichmentStatus.status === 'RUNNING') {
    return (
      <TableGrid
        table={table}
        isLoading={true}
        emptyMessage={<EmptyMessage message="Check is running" icon={<FiActivity />} />}
        numLoadingRows={20}
        className="table-centered table-compact table-nowrap table-fixed"
      />
    );
  }
  if (clientEnrichmentStatus.status === 'FAILED') {
    return <EmptyMessage message="Check failed, contact engineering" icon={<FiActivity />} />;
  }
  if (clientEnrichmentStatus.status === 'SUCCEEDED') {
    return (
      <TableGrid
        table={table}
        isLoading={isLoading || isRunning}
        emptyMessage={
          <EmptyMessage
            message={
              (isEmpty(clientEnrichmentStatus.output.enrichmentStatus) && 'No sourcer selected for enrichment') ||
              `Check is running`
            }
            icon={<FiActivity />}
          />
        }
        numLoadingRows={20}
        className="table-centered table-compact table-nowrap table-fixed"
      />
    );
  }
  return (
    <EmptyMessage
      message="Check has not been run yet"
      icon={<FiActivity />}
      actions={
        <Button size="sm" variant="primary" disabled={isRunning || isLoading} onClick={runCheck}>
          Run check
        </Button>
      }
    />
  );
}
