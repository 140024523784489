import { PredictiveLabelsModel } from 'src/pages/predictive-labels/types.ts';
import { SourcingComponent } from './SourcingComponent.tsx';
import { Alert, Card } from 'react-bootstrap';

export function SourceCriteriaCard({
  model,
  errorsOpen,
  handleChange,
  handleCommitChanges,
  handleChangeAndCommit
}: {
  model: PredictiveLabelsModel;
  errorsOpen: boolean;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
}) {
  return (
    <Card>
      <Card.Header>Universe setup</Card.Header>
      <Card.Body>
        <Alert variant="danger" show={errorsOpen}>
          There is an error in the sourcing criteria
        </Alert>
        <SourcingComponent
          clientConfig={model.universeConfig}
          isEditing={true}
          handleChange={handleChange}
          handleCommitChanges={handleCommitChanges}
          handleChangeAndCommit={handleChangeAndCommit}
        />
      </Card.Body>
    </Card>
  );
}
