import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import ConnectorList from './components/ConnectorList';
import ConnectorSettingsOffcanvas from './components/ConnectorSettingsOffcanvas';
import { Integration, IntegrationType } from 'src/apis/integrations';
import { useIntegrationStore } from 'src/pages/settings/connectors/integrationsStore.ts';
import { usePageTitle } from 'src/utils/usePageTitle.ts';

export type PossibleIntegration = Omit<Integration, 'id'> & { id: string | null };

export default function ConnectorsPage({ title }: any) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [settingsConnectorType, setSettingsConnectorType] = useState<IntegrationType | null>(null);
  const fetchIntegrations = useIntegrationStore(state => state.fetchIntegrations);
  const integrations = useIntegrationStore(state => state.integrations);
  const [allIntegrations, setAllIntegrations] = useState<Integration[]>([]);

  usePageTitle(title);

  useEffect(() => {
    fetchIntegrations().then(() => setIsLoading(false));
    // We only want this to run upon boot
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSettingsFor = (integration: Integration) => {
    setSettingsConnectorType(integration.integrationType);
    setIsSettingsOpen(true);
  };

  useEffect(() => {
    const allIntegrations: PossibleIntegration[] = integrations;
    // no need to add pipedrive. If it's there it can be edited, but not by default
    const types = [IntegrationType.SALESFORCE, IntegrationType.HUBSPOT, IntegrationType.WEBHOOK];
    for (const type of types) {
      if (!allIntegrations.find(i => i.integrationType === type)) {
        allIntegrations.push({
          integrationType: type,
          credentials: null,
          id: null,
          enableAutoSync: false,
          hasCredentials: false,
          syncSegmentRecordsOnly: false,
          segmentIds: []
        });
      }
    }
    // just make sure the order is correct
    const order = {
      [IntegrationType.SALESFORCE]: 1,
      [IntegrationType.HUBSPOT]: 2,
      [IntegrationType.WEBHOOK]: 3,
      [IntegrationType.PIPEDRIVE]: 4
    };
    allIntegrations.sort((a, b) => order[a.integrationType] - order[b.integrationType]);
    setAllIntegrations(allIntegrations as Integration[]);
  }, [integrations]);

  return (
    <>
      <NarrowLayout title="Connectors" preheader="Settings">
        <Card>
          <Card.Body>
            <ConnectorList openSettingsFor={openSettingsFor} integrations={allIntegrations} isLoading={isLoading} />
          </Card.Body>
        </Card>
      </NarrowLayout>
      <ConnectorSettingsOffcanvas
        show={isSettingsOpen}
        integration={allIntegrations?.find(c => c.integrationType === settingsConnectorType) ?? null}
        onHide={() => setIsSettingsOpen(false)}
      />
    </>
  );
}
