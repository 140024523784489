import { useState } from 'react';
import { Button, Spinner, ButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface LoadingButtonProps extends ButtonProps {
  icon?: React.ReactNode;
  loading?: boolean;
  spinColor?: string;
  overlayText?: string;
}

/**
 * Extend the default button with loading state and icon
 */
export default function LoadingButton({
  children = null,
  disabled = false,
  icon = null,
  loading = false,
  onClick,
  overlayText,
  spinColor = '#fff',
  variant = 'primary',
  ...rest
}: LoadingButtonProps) {
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const handleClick = async (event: any) => {
    setIsLoadingInternal(true);
    try {
      if (onClick) await onClick(event);
      setIsLoadingInternal(false);
    } catch (e) {
      setIsLoadingInternal(false);
      throw e;
    }
  };
  const isLoadingResolved = loading || isLoadingInternal;

  function renderIcon(buttonSize?: any) {
    if (isLoadingResolved) {
      // spinAlignment
      const sx = buttonSize === 'sm' ? 12 : 18;
      return (
        <>
          <Spinner
            as="span"
            role="status"
            aria-hidden="true"
            size={buttonSize}
            style={{ height: sx, width: sx }}
            color={spinColor}
          />{' '}
          &nbsp;{' '}
        </>
      );
    }

    if (!icon) return <></>;
    return <>{icon}&nbsp; </>;
  }

  const buttonDisabled = disabled || isLoadingResolved;

  if (overlayText) {
    return (
      <OverlayTrigger overlay={(props: any) => <Tooltip {...props}>{overlayText}</Tooltip>}>
        <span>
          <Button {...rest} variant={variant} disabled={buttonDisabled} onClick={handleClick}>
            {renderIcon(rest.size)}
            {children}
          </Button>
        </span>
      </OverlayTrigger>
    );
  }

  return (
    <Button {...rest} variant={variant} disabled={buttonDisabled} onClick={handleClick}>
      {renderIcon(rest.size)}
      {children}
    </Button>
  );
}
