import { Button, Col, Dropdown, ListGroup, Row } from 'react-bootstrap';
import { FiMoreVertical } from 'react-icons/fi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import { titleCase } from 'src/utils/string';
import { useClient } from 'src/auth';
import { Integration } from 'src/apis/integrations';

const toConnectorLogs = (clientName: string, type: string) =>
  `/app/${clientName}/settings/connector-logs?connector=${type}`;
const toDataDictionary = (clientName: string) => `/app/${clientName}/data-directory`;

const LOGOS = {
  SALESFORCE: '/img/logos/salesforce_logo.svg',
  HUBSPOT: '/img/logos/hubspot_logo.svg',
  WEBHOOK: '/img/logos/webhooks_logo.svg',
  PIPEDRIVE: '/img/logos/pipedrive_logo.svg'
};

const salesforceRedirect = (type: 'sandbox' | 'production', client: string) => {
  let url =
    type === 'production'
      ? import.meta.env.VITE_SALESFORCE_AUTH_URL
      : import.meta.env.VITE_SALESFORCE_AUTH_URL.replace('login.', 'test.').replace(
          'connectors/salesforce',
          'connectors/salesforce-sandbox'
        );
  const state = {
    client,
    connectorType: 'SALESFORCE',
    sandbox: type === 'sandbox'
  };
  url += `&state=${encodeURIComponent(JSON.stringify(state))}`;
  return url;
};

const hubspotRedirect = (client: string) => {
  let url = `${import.meta.env.VITE_HUBSPOT_AUTH_URL}`;
  const state = {
    client,
    connectorType: 'HUBSPOT'
  };
  url += `&state=${encodeURIComponent(JSON.stringify(state))}`;
  return url;
};

export interface ConnectButtonProps {
  integrationType: string;
  onSettingsClicked: () => void;
}

function ConnectButton({ integrationType, onSettingsClicked }: ConnectButtonProps) {
  const { clientName } = useClient();
  switch (integrationType) {
    case 'SALESFORCE':
      return (
        <Dropdown>
          <Dropdown.Toggle variant="primary" size="sm">
            Connect
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={salesforceRedirect('sandbox', clientName)}>Sandbox</Dropdown.Item>
            <Dropdown.Item href={salesforceRedirect('production', clientName)}>Production</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    case 'HUBSPOT':
      return (
        <Button size="sm" href={hubspotRedirect(clientName)}>
          Connect
        </Button>
      );
    case 'WEBHOOK':
    case 'PIPEDRIVE':
      return (
        <Button size="sm" onClick={onSettingsClicked}>
          Connect
        </Button>
      );
  }
}

function ConnectSettings({ integration }: any) {
  const { clientName } = useClient();
  const navigate = useNavigate();
  return (
    <Dropdown align="end" style={{ marginLeft: 12 }}>
      <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
        <FiMoreVertical />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {(integration.integrationType === 'SALESFORCE' && (
          <>
            <Dropdown.Item href={salesforceRedirect('production', clientName)}>Re-connect (production)</Dropdown.Item>
            <Dropdown.Item href={salesforceRedirect('sandbox', clientName)}>Re-connect (sandbox)</Dropdown.Item>
          </>
        )) ||
          (integration.integrationType === 'HUBSPOT' && (
            <Dropdown.Item href={hubspotRedirect(clientName)}>Re-connect</Dropdown.Item>
          ))}
        <Dropdown.Item onClick={() => navigate(toConnectorLogs(clientName, integration.integrationType))}>
          View sync errors
        </Dropdown.Item>
        <Dropdown.Item onClick={() => navigate(toDataDictionary(clientName))}>Configure field mappings</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export interface ConnectorItemProps {
  integration: Integration;
  openSettingsFor: (integration: Integration) => void;
}

export default function ConnectorItem({ integration, openSettingsFor }: ConnectorItemProps) {
  let optionalConnectionLabel = '';
  if (integration.integrationType === 'SALESFORCE' && integration?.isSalesforceSandbox) {
    optionalConnectionLabel = `(sandbox)`;
  }

  return (
    <ListGroup.Item>
      <Row className="align-items-center">
        <Col xs="auto">
          <img
            style={{ maxWidth: 50, maxHeight: 50 }}
            src={LOGOS[integration.integrationType]}
            alt={integration.integrationType}
          />
        </Col>
        <Col xs={5} className="ms-n2">
          <h4 className="mb-1"> {titleCase(integration.integrationType)}</h4>
        </Col>
        <Col xs="auto" className="ms-auto">
          {integration.hasCredentials ? (
            <span className="text-success">
              <IoIosCheckmarkCircle /> Connected {optionalConnectionLabel}
            </span>
          ) : (
            <span className="text-muted">Not Connected</span>
          )}
        </Col>
        <Col xs="auto">
          {integration.hasCredentials ? (
            <div className="d-flex">
              <Button size="sm" variant="white" onClick={() => openSettingsFor(integration)}>
                Settings
              </Button>
              <ConnectSettings integration={integration} />
            </div>
          ) : (
            <ConnectButton
              integrationType={integration.integrationType}
              onSettingsClicked={() => openSettingsFor(integration)}
            />
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
