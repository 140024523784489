import { FiChevronDown, FiChevronUp, FiFilter } from 'react-icons/fi';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useState } from 'react';
import { OptionsFunction } from 'src/components/Table/listTable/types.ts';

export type ColumnProps = {
  column: any;
  headerName: string;
  allowSorting?: boolean;
  allowFiltering?: boolean;
  options?: { label: string; value: string }[];
  loadOptions?: OptionsFunction;
};

export function ColumnHeader({
  column,
  headerName,
  allowFiltering = false,
  allowSorting = false,
  options,
  loadOptions
}: ColumnProps) {
  const [filters, setFilters] = useState([]);

  const filterOptions = (inputValue: string) => {
    return options?.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())) as {
      label: string;
      value: string;
    }[];
  };

  const promiseOptions = async (inputValue: string) => {
    if (loadOptions) {
      const { options } = await loadOptions(column.id, inputValue);
      return options.map(i => ({
        label: i,
        value: i
      }));
    } else {
      return new Promise<any[]>(resolve => {
        resolve(filterOptions(inputValue));
      });
    }
  };

  return (
    <div>
      {(allowSorting && (
        <span onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>{headerName} </span>
      )) ||
        headerName}
      {allowFiltering && (
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          overlay={
            <Popover
              id="filter-popover"
              style={{
                minWidth: '250px'
              }}
            >
              <Popover.Body>
                <AsyncSelect
                  cacheOptions
                  loadOptions={promiseOptions}
                  value={filters}
                  defaultOptions
                  isMulti={true}
                  onChange={(selectedOption: any) => {
                    if (selectedOption) {
                      column.setFilterValue(selectedOption.map((option: any) => option.value));
                      setFilters(selectedOption);
                    } else {
                      column.setFilterValue([]);
                      setFilters([]);
                    }
                  }}
                />
              </Popover.Body>
            </Popover>
          }
        >
          <Button
            variant={filters.length > 0 ? 'secondary' : 'outline-secondary'}
            style={{ width: '18px', height: '18px', padding: '0', fontSize: '11px' }}
          >
            <FiFilter />
          </Button>
        </OverlayTrigger>
      )}
      {column.getIsSorted() ? (
        column.getIsSorted() === 'asc' ? (
          <FiChevronUp size={'0.8rem'} />
        ) : (
          <FiChevronDown size={'0.8rem'} />
        )
      ) : (
        ''
      )}
    </div>
  );
}
