import { FaSync } from 'react-icons/fa';

import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { SegmentSyncStatuses } from './hooks/useSegmentsSyncStatuses';
import startCase from 'lodash/startCase';

interface SyncStatusIconProps {
  segmentSyncStatus: SegmentSyncStatuses[number];
}

export const SyncStatusIcon: React.FC<SyncStatusIconProps> = ({ segmentSyncStatus }) => {
  // Don't render an icon if auto sync is disabled
  if (!segmentSyncStatus || !segmentSyncStatus.isAutoSync) return null;

  const normalisedIntegrationNames = segmentSyncStatus.autoSyncIntegrations
    .map(type => startCase(type.toLowerCase()))
    .join(' and ');

  return (
    <TooltipWrapper
      placement="bottom"
      tooltipText={`Auto sync is enabled for ${normalisedIntegrationNames}`}
      customComponent={<FaSync color="lightgreen" />}
    />
  );
};
