import { useRef, useCallback, useEffect } from 'react';

// Call to periodically call a function making sure its only called once at a time
// immediate = call right away then after intervalMs or just after wait
// returns function that can be called to stop iteration
export function usePeriodic(
  tick: CallableFunction,
  intervalMs: number,
  dependencies: any[] = [],
  immediate: boolean = true
) {
  const id = useRef<number>();

  const tickAndReschedule = useCallback(async () => {
    if (id.current) {
      clearTimeout(id.current);
    }
    await tick();
    id.current = setTimeout(tickAndReschedule, intervalMs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalMs, tick, ...dependencies]);

  useEffect(() => {
    if (immediate) {
      // Use setTimeout with 1ms to avoid blocking main thread
      id.current = setTimeout(tickAndReschedule, 1);
    } else {
      id.current = setTimeout(tickAndReschedule, intervalMs);
    }
    return () => {
      if (id.current) {
        clearTimeout(id.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalMs, immediate, ...dependencies]);

  return () => {
    if (id.current) {
      clearTimeout(id.current);
    }
  };
}
