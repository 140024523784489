import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { formatNumber } from 'src/utils/number';

export default function LabelScoresTable({ evaluation }: { evaluation: any }) {
  if (!evaluation.scores?.labelScores) return <></>;

  const labels = Object.keys(evaluation.scores?.labelScores);

  const universeScale = evaluation.totalUniverseSize / evaluation.evaluationUniverseSetSize;

  return (
    <Table bordered size="sm" className="table-centered">
      <thead>
        <tr>
          <th>Label</th>
          <th>
            Precision
            <OverlayTrigger
              overlay={props => (
                <Tooltip {...props}>
                  Precision is a metric that gives you the proportion of true positives to the amount of total positives
                  that the model predicts. It answers the question “Out of all the positive predictions we made, how
                  many were true?”
                </Tooltip>
              )}
            >
              <div className="d-inline-block mx-2">
                <IoIosInformationCircleOutline />
              </div>
            </OverlayTrigger>
          </th>
          <th>
            Recall
            <OverlayTrigger
              overlay={props => (
                <Tooltip {...props}>
                  Recall focuses on how good the model is at finding all the positives. Recall is also called true
                  positive rate and answers the question “Out of all the data points that should be predicted as true,
                  how many did we correctly predict as true?”
                </Tooltip>
              )}
            >
              <div className="d-inline-block mx-2">
                <IoIosInformationCircleOutline />
              </div>
            </OverlayTrigger>
          </th>
          <th>
            F-1 score
            <OverlayTrigger
              overlay={props => <Tooltip {...props}>F1 Score is a measure that combines recall and precision.</Tooltip>}
            >
              <div className="d-inline-block mx-2">
                <IoIosInformationCircleOutline />
              </div>
            </OverlayTrigger>
          </th>
          <th>
            Est occurrences
            <OverlayTrigger
              overlay={props => (
                <Tooltip {...props}>
                  Estimated number of times this label <i>may</i> occur in full dataset. Its formed by extrapolating the
                  detections in a sample over the entire universe of this label.
                </Tooltip>
              )}
            >
              <div className="d-inline-block mx-2">
                <IoIosInformationCircleOutline />
              </div>
            </OverlayTrigger>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>OVERALL</td>
          <td>{evaluation?.scores?.avgWeightedScores?.['precision']?.toFixed(2)}</td>
          <td>{evaluation?.scores?.avgWeightedScores?.['recall']?.toFixed(2)}</td>
          <td>{evaluation?.scores?.avgWeightedScores?.['f1-score']?.toFixed(2)}</td>
          <td>{formatNumber(evaluation?.totalUniverseSize)}</td>
        </tr>
        {labels.map(label => (
          <tr key={`${label}`}>
            <td>{label}</td>
            <td>{evaluation.scores.labelScores[label]['precision']?.toFixed(2)}</td>
            <td>{evaluation.scores.labelScores[label]['recall']?.toFixed(2)}</td>
            <td>{evaluation.scores.labelScores[label]['f1-score']?.toFixed(2)}</td>
            <td>{formatNumber(Math.round(evaluation.evaluationPerLabelCounts[label] * universeScale))}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
