import { useCallback, useState } from 'react';
import { Alert, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'src/components/LoadingButton';
import { useAuth } from 'src/auth';
import goodFitLogo from '/img/goodfit-logo-text.svg';
import { FiLock } from 'react-icons/fi';
import * as Auth from 'aws-amplify/auth';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { validateNewPassword } from 'src/pages/auth/validatePassword.ts';

interface SignInFormProps {
  error?: string;
  isSubmitting?: boolean;
  passwordResetRequired: boolean;
  onSubmit: (email: string, password: string, newPassword: string) => void;
}

function SignInForm({ onSubmit, error, passwordResetRequired, isSubmitting }: SignInFormProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState<string | undefined>(undefined);

  return (
    <div style={{ marginTop: -80 }}>
      <h1 className="display-4 text-center mb-3">
        <img className="navbar-brand-img" src={goodFitLogo} alt="GoodFit" style={{ height: 25 }} />
      </h1>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(email, password, newPassword as string);
        }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        {!passwordResetRequired && (
          <>
            <div className="form-group">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                tabIndex={1}
                placeholder="name@address.com"
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <Row>
                <Col>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col xs="auto">
                  <Form.Text as={Link} className="small text-muted" to="/reset-password">
                    Forgot password?
                  </Form.Text>
                </Col>
              </Row>
              <InputGroup className="input-group-merge">
                <Form.Control
                  type="password"
                  tabIndex={2}
                  placeholder="Enter your password"
                  onChange={e => setPassword(e.target.value)}
                />
                <InputGroup.Text>
                  <FiLock />
                </InputGroup.Text>
              </InputGroup>
            </div>
          </>
        )}
        {passwordResetRequired && (
          <div className="form-group">
            <Form.Label>New password</Form.Label>
            <InputGroup className="input-group-merge">
              <Form.Control
                type="password"
                tabIndex={2}
                required
                placeholder="Enter new password"
                onChange={e => setNewPassword(e.target.value)}
                isInvalid={newPassword !== undefined && validateNewPassword(newPassword)?.details?.length > 0}
              />
              <InputGroup.Text>
                <FiLock />
              </InputGroup.Text>
            </InputGroup>
            <div className={'invalid-feedback d-block'}>
              {newPassword !== undefined && validateNewPassword(newPassword)?.details[0]?.message}
            </div>
          </div>
        )}
        <Button size="lg" className="w-100 mb-3" type="submit" tabIndex={3} loading={isSubmitting}>
          {passwordResetRequired ? 'Set password' : 'Sign in'}
        </Button>
      </form>
    </div>
  );
}

export default function LoginPage({ title }: any) {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [signInWithNewPassword, setSignInWithNewPassword] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  usePageTitle(title);

  const nextPath = searchParams.get('next') ?? '/app';

  const handleLogin = useCallback(
    async (email: string, password: string, newPassword?: string) => {
      setIsSubmitting(true);
      setError('');
      try {
        await auth.logout();
        if (newPassword) {
          const r = await Auth.confirmSignIn({
            challengeResponse: newPassword as string,
            options: { username: email, password: newPassword }
          });
          if (r.isSignedIn) {
            navigate('/app');
          } else {
            setError(`Error during password reset`);
          }
        } else {
          const result = await auth.login(email, password);
          if (result.isSignedIn) navigate(nextPath);
          if (result.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
            setSignInWithNewPassword(true);
          }
          if (result.nextStep.signInStep === 'RESET_PASSWORD') {
            navigate('/reset-password');
          }
        }
      } catch (err: unknown) {
        console.error(err);
        setError('Error logging you in: ' + (err as Error).message);
      }
      setIsSubmitting(false);
    },
    [auth, navigate, nextPath]
  );

  return (
    <div className="d-flex align-items-center min-vh-100 bg-auth">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={5} xl={4} className="">
            <SignInForm
              onSubmit={handleLogin}
              error={error}
              isSubmitting={isSubmitting}
              passwordResetRequired={signInWithNewPassword}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
