import { Alert, Tab, Tabs } from 'react-bootstrap';
import { useCallback, useEffect, useRef, useState } from 'react';
import WideLayout from 'src/layouts/Wide';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useLocation, useParams } from 'react-router-dom';
import { ClientConfig } from 'src/apis/clients/types.ts';
import { getClientConfig, upsertClientConfig } from 'src/apis/clients/apis.ts';
import toast from 'react-hot-toast';
import PageLoader from 'src/components/PageLoader';
import { ClientConfigEditorToolbox } from 'src/pages/staff/clientConfigs/components/ClientConfigEditorToolbox.tsx';
import { SourcingTab } from './tabs/SourcingTab.tsx';
import { AdvancedTab } from './tabs/AdvancedTab.tsx';
import { ContactsTab } from './tabs/ContactsTab.tsx';
import { mandatoryFields } from 'src/pages/staff/clientConfigs/sourcing/fieldDefinitions/mandatoryFields.ts';
import { ClientConfigErrors } from './validation/types.ts';
import { ErrorCard } from './validation/ErrorCard.tsx';
import { getFieldMap } from 'src/components/Filters/CCMFilters/services/service.ts';
import { validate } from 'src/pages/staff/clientConfigs/validation/validate.ts';
import { PreSaveModal } from 'src/pages/staff/clientConfigs/presave/PreSaveModal.tsx';
import ClientConfigModal from 'src/pages/staff/clientConfigs/components/ClientConfigModal.tsx';
import { LifecycleTab } from './tabs/LifecycleTab.tsx';
import { useAdminContext } from 'src/adminContext/hooks.ts';
import { CreditsAndLimitsTab } from 'src/pages/staff/clientConfigs/tabs/creditsAndLimits/CreditsAndLimitsTab.tsx';
import { ResourceLockModal } from '../../../components/ResourceLock/components/ResourceLockModal/index.tsx';
import { LockableResource } from 'src/apis/resourceLocks/types.ts';

const emptyConfig = (id: string): ClientConfig => ({
  clientName: id,
  contactsEnabled: true, // Default on with segments release, however as contactCriteria=[] legacy contacts is not used
  syncWithoutContacts: false,
  syncWithoutEmails: false,
  crmDataPullEnabled: false,
  autoSyncEnabled: false,
  sourcingCriteria: { rules: [] },
  fieldDefinitions: mandatoryFields,
  enrichmentDestinations: [],
  otherEnrichmentStages: [],
  contactCriteria: [],
  dataBlockConfigs: [],
  excludeCompanyIds: [],
  customDataFields: [],
  gfAppFeatureFlags: {},
  lifecycleStage: 'OTHER',
  scheduledForDeletionAt: undefined
});

function ClientConfigEditorPage({ title }: any) {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isNew = queryParams.get('isNew') === 'true';

  const { schema, dataBlocksDefinitions } = useAdminContext();
  // the state is used to render the right components, Ref is used so components do not re-render to often
  // state is required only on the initial load. Ref for managing consecutive changes
  const [clientConfig, setClientConfig] = useState<ClientConfig>(emptyConfig(''));
  const [clientConfigBeforeChanges, setClientConfigBeforeChanges] = useState<ClientConfig | null>(null);
  const clientConfigRef = useRef<Partial<ClientConfig>>({});

  usePageTitle(title);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasInitialLoaded, setHasInitialLoaded] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [errors, setErrors] = useState<ClientConfigErrors | null>(null);
  const [errorsOpen, setErrorsOpen] = useState(false);
  const [preSaveOpen, setPreSaveOpen] = useState(false);
  const [cloneModalOpen, setCloneModalOpen] = useState(false);

  const prefillNames = useCallback(
    (config: ClientConfig): ClientConfig => {
      const fieldMapping = getFieldMap(config.dataBlockConfigs as any[], dataBlocksDefinitions);
      config.fieldDefinitions = config.fieldDefinitions.map(field => {
        if (!field.externalName || field.externalName === '') {
          field.externalName = fieldMapping.find(f => f.dataBlockField === field.internalName)?.displayName;
        }
        return field;
      });
      return config;
    },
    [dataBlocksDefinitions]
  );

  useEffect(() => {
    if (isNew) {
      const newConfig = emptyConfig(id as string);
      clientConfigRef.current = newConfig;
      setClientConfig(newConfig);
      setIsLoading(false);
      setHasInitialLoaded(true);
    } else if (id) {
      getClientConfig(id)
        .then(cc => {
          setClientConfigBeforeChanges(cc.config);
          const configWithPrefilledNames = prefillNames(cc.config);
          setClientConfig(configWithPrefilledNames);
          clientConfigRef.current = configWithPrefilledNames;
          setIsLoading(false);
          setHasInitialLoaded(true);
        })
        .catch(() => {
          //setClientConfig(emptyConfig(id));
          setHasErrored(true);
          setIsLoading(false);
        });
    } else {
      setClientConfig(emptyConfig(''));
      setIsLoading(false);
      setHasInitialLoaded(true);
    }
  }, [id, isNew, prefillNames]);

  const handleChange = useCallback((update: any, path: string) => {
    clientConfigRef.current = { ...clientConfigRef.current, [path]: update };
  }, []);

  const handleChangeAndCommit = useCallback((update: any, path: string) => {
    clientConfigRef.current = { ...clientConfigRef.current, [path]: update };
    setClientConfig(prevState => ({ ...prevState, [path]: update }));
  }, []);

  const handleCommitChanges = useCallback(() => {
    setClientConfig(prevState => ({ ...prevState, ...clientConfigRef.current }));
  }, []);

  const validateBeforeSave = useCallback(async () => {
    const toastId = toast.loading('Validating Changes...');
    handleCommitChanges();
    const fieldMapping = getFieldMap(clientConfigRef.current.dataBlockConfigs as any[], dataBlocksDefinitions);
    const validationsFailed = validate(clientConfigRef.current, fieldMapping);

    if (validationsFailed.validationFailed) {
      setErrors({ validationsFailed });
      setErrorsOpen(true);
      toast.dismiss(toastId);
      toast.error(`The config is incorrect, please fix the changes and try again`);
      return false;
    }
    toast.dismiss(toastId);
    setErrors(null);
    setErrorsOpen(false);
    setIsLoading(true);
    return true;
  }, [handleCommitChanges, dataBlocksDefinitions]);

  const openPreSaveModal = useCallback(async () => {
    const isValid = await validateBeforeSave();
    if (isValid) {
      setPreSaveOpen(true);
    }
  }, [validateBeforeSave]);

  const openCloneModal = useCallback(async () => {
    const isValid = await validateBeforeSave();
    if (isValid) {
      setCloneModalOpen(true);
    }
  }, [validateBeforeSave]);

  const cloneClientConfig = useCallback(
    async (name: string) => {
      const toastId = toast.loading('Saving...');
      // we effectively just copy sourcing criteria, field definitions and data block configs. No flags are, or advanced settings are copied by default
      const configToSave = {
        ...emptyConfig(name),
        sourcingCriteria: clientConfigRef.current.sourcingCriteria,
        fieldDefinitions: clientConfigRef.current.fieldDefinitions,
        dataBlockConfigs: clientConfigRef.current.dataBlockConfigs
      };
      handleCommitChanges();
      const result = await upsertClientConfig(name, configToSave);
      toast.dismiss(toastId);
      if (result.isValid) {
        toast.success(`Client config cloned with name ${name}`);
        setCloneModalOpen(false);
        setErrors(null);
        setErrorsOpen(false);
        setIsLoading(false);
        //   open in new tab
        window.open(`/admin/clients/${name}`);
      } else {
        toast.error('Cloning failed');
      }
    },
    [handleCommitChanges]
  );

  const updateConfig = useCallback(async () => {
    const toastId = toast.loading('Saving...');
    try {
      const result = await upsertClientConfig(id as string, clientConfigRef.current);
      toast.dismiss(toastId);
      if (result.isValid) {
        toast.success('Saved');
        setIsEditing(false);
        setErrors(null);
        setErrorsOpen(true);
      } else {
        setErrors({ schemaErrors: result.errors, breakingChanges: result.breakingChanges });
        setErrorsOpen(true);
        toast.error(`Failed the schema is incorrect. Please see error summary for details.`);
      }
    } catch (e) {
      toast.dismiss(toastId);
      toast.error(`Failed please contact eng team`);
    }

    setIsLoading(false);
  }, [id]);

  // Cant show form until config AND flags (and all other required data) is loaded otherwise it will be partial
  if (!hasInitialLoaded) return <PageLoader fullHeight />;

  if (hasErrored) return <>Sorry, something went wrong. Please reload page.</>;

  return (
    <>
      <WideLayout
        title={`Client: ${id}`}
        titleControls={
          <ClientConfigEditorToolbox
            id={id}
            isNew={isNew}
            clientConfig={clientConfig}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            openPreSaveModal={openPreSaveModal}
            openCloneModal={openCloneModal}
          />
        }
      >
        <>
          {isEditing && id && <ResourceLockModal resourceId={id} resourceType={LockableResource.ClientConfig} />}
          {clientConfig?.scheduledForDeletionAt && (
            <Alert variant="warning">
              This client is scheduled for deletion after {clientConfig.scheduledForDeletionAt.toUTCString()}. You can
              undo this in the lifecycle tab.
            </Alert>
          )}
          {/*possibly reuse */}
          <ErrorCard
            clientName={id || ''}
            errorsOpen={errorsOpen}
            errors={errors}
            closeErrorsCard={() => setErrorsOpen(false)}
          />
          <Tabs defaultActiveKey="sourcing" id="client-config-tabs">
            <Tab eventKey="sourcing" title="Sourcing">
              <SourcingTab
                schema={schema}
                clientConfig={clientConfig}
                isEditing={isEditing}
                handleChange={handleChange}
                handleCommitChanges={handleCommitChanges}
                handleChangeAndCommit={handleChangeAndCommit}
                errors={errors}
              />
            </Tab>
            <Tab eventKey="lifecycle" title="Lifecycle">
              <LifecycleTab
                schema={schema}
                clientConfig={clientConfig}
                isEditing={isEditing}
                handleChange={handleChange}
                handleCommitChanges={handleCommitChanges}
                handleChangeAndCommit={handleChangeAndCommit}
                errors={errors}
              />
            </Tab>
            <Tab eventKey="contacts" title="Contacts">
              <ContactsTab
                schema={schema}
                clientConfig={clientConfig}
                isEditing={isEditing}
                handleChange={handleChange}
                handleCommitChanges={handleCommitChanges}
                handleChangeAndCommit={handleChangeAndCommit}
              />
            </Tab>
            <Tab eventKey="advanced" title="Advanced">
              <AdvancedTab
                schema={schema}
                clientConfig={clientConfig}
                isEditing={isEditing}
                handleChange={handleChange}
                handleCommitChanges={handleCommitChanges}
                handleChangeAndCommit={handleChangeAndCommit}
              />
            </Tab>
            <Tab eventKey="creditsAndLimits" title="Credits & limits">
              <CreditsAndLimitsTab clientName={id as string} isNew={isNew} />
            </Tab>
          </Tabs>
        </>
      </WideLayout>
      <PreSaveModal
        show={preSaveOpen}
        onHide={() => {
          setIsLoading(false);
          setPreSaveOpen(false);
        }}
        clientName={id}
        save={updateConfig}
        newClientConfig={clientConfig}
        clientConfigBeforeChanges={clientConfigBeforeChanges}
      />

      <ClientConfigModal show={cloneModalOpen} onHide={() => setCloneModalOpen(false)} onSubmit={cloneClientConfig} />
    </>
  );
}

export default ClientConfigEditorPage;
