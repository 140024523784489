import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { DataSyncStatus, getDataSyncStatus, triggerCCM } from 'src/apis/clients/apis.ts';
import { LockableResource } from 'src/apis/resourceLocks/types';
import { useResourceLockStore } from 'src/components/ResourceLock/resourceLockStore';
import { UnlockResourceButton } from 'src/components/ResourceLock/components/UnlockResourceButton';
import { generateSql } from 'src/pages/staff/marketEstimations/apis.ts';
import { Button, Dropdown, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { FiDatabase, FiEdit, FiSave } from 'react-icons/fi';
import { formatDate } from 'src/utils/string.ts';
import { usePeriodic } from 'src/utils/usePeriodic';

export function ClientConfigEditorToolbox({
  id,
  clientConfig,
  isNew,
  isLoading,
  setIsLoading,
  isEditing,
  setIsEditing,
  openPreSaveModal,
  openCloneModal
}: {
  id: string | undefined;
  clientConfig: any;
  isNew: boolean;
  isLoading: boolean;
  setIsLoading: (a: boolean) => void;
  isEditing: boolean;
  setIsEditing: (a: boolean) => void;
  openPreSaveModal: () => Promise<void>;
  openCloneModal: () => Promise<void>;
}) {
  const { isResourceLocked, isResourceStale } = useResourceLockStore(LockableResource.ClientConfig, id, state => ({
    isResourceLocked: state.isResourceLocked,
    isResourceStale: state.isResourceStale
  }));

  const [dataSyncStatus, setDataSyncStatus] = useState<DataSyncStatus>();
  // to avoid flickering, straight after triggering new data sync.
  // otherwise there is a dataSyncStatus refresh before the CCM can set the status
  const [isDataSyncTriggered, setIsDataSyncTriggered] = useState(false);

  const triggerDataSync = useCallback(
    async (resetDataset: boolean) => {
      const toastId = toast.loading('Starting...');
      setIsLoading(true);
      const result = await triggerCCM(id as string, resetDataset);
      toast.dismiss(toastId);
      if (result.executionId) {
        toast.success(`Started sync with ID ${result.executionId}`);
        setIsEditing(false);
        setDataSyncStatus({ status: 'IN_PROGRESS', percentage: 0 });
        setIsDataSyncTriggered(true);
      } else {
        toast.error(`Failed to start sync`);
      }
      setIsLoading(false);
    },
    [id, setIsEditing, setIsLoading]
  );

  const generateAndCopySql = useCallback(() => {
    return toast.promise(
      generateSql(clientConfig).then(result => {
        navigator.clipboard.writeText(result?.formattedUnsafeSql?.toString());
      }),
      {
        loading: 'Generating SQL',
        success: 'Copied SQL to clipboard',
        error: 'Why you break SQL?'
      }
    );
  }, [clientConfig]);

  const shouldCheckStatus = useMemo(
    () => !isNew && id && dataSyncStatus?.status !== 'FAILED',
    [isNew, id, dataSyncStatus]
  );

  usePeriodic(
    async () => {
      if (shouldCheckStatus) {
        if (isDataSyncTriggered) {
          // If triggerDataSync has been executed, wait for 5 seconds before calling getDataSyncStatus
          // so CCM can actually invoke
          await new Promise(resolve => setTimeout(resolve, 5000));
          setIsDataSyncTriggered(false); // Reset the state variable
        }
        try {
          const res = await getDataSyncStatus(id as string);
          setDataSyncStatus(res);
        } catch (e) {
          setDataSyncStatus({ status: 'FAILED' });
          console.error(e);
        }
      }
    },
    10_000,
    [id, isDataSyncTriggered, shouldCheckStatus]
  );

  return (
    <div className="d-flex align-items-center" style={{ gap: 5 }}>
      <Dropdown>
        <Dropdown.Toggle variant="white" size="sm" id="dropdown-basic">
          Shortcuts
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item target="_blank" href={`/app/${id}`}>
            Open app
          </Dropdown.Item>
          <Dropdown.Item onClick={() => openCloneModal()}>Clone config</Dropdown.Item>

          <Dropdown.Item onClick={() => generateAndCopySql()}>Copy preview SQL</Dropdown.Item>

          <Dropdown.Item href={`/admin/market-estimations#client=${id}`}>Market estimations</Dropdown.Item>
          <Dropdown.Item href={`/admin/coverage-analysis#client=${id}`}>Coverage analysis</Dropdown.Item>
          <Dropdown.Item
            href={`https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/v2/executions/details/${dataSyncStatus?.executionArn}`}
            target="_blank"
          >
            Last CCM Run
          </Dropdown.Item>
          <Dropdown.Item onClick={() => triggerDataSync(true)} disabled={isLoading}>
            Rebuild dataset (and reset)
          </Dropdown.Item>
          <Dropdown.Header>Redash reports</Dropdown.Header>
          <Dropdown.Item href={`https://redash.goodfit.io/queries/16?p_client_name=${id}`} target="_blank">
            Audit logs
          </Dropdown.Item>
          <Dropdown.Item
            href={`https://redash.goodfit.io/queries/4?p_date_range=d_this_year&p_client_name=${id}`}
            target="_blank"
          >
            Enrichment usage
          </Dropdown.Item>
          <Dropdown.Item
            href={`https://redash.goodfit.io/queries/12?p_clientName=%5B%22${id}%22%5D&p_period=weekly&p_Accounts=All`}
            target="_blank"
          >
            Adoption metrics
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {isResourceLocked && id && <UnlockResourceButton resourceType={LockableResource.ClientConfig} resourceId={id} />}
      <Button
        size={'sm'}
        disabled={isLoading || isResourceLocked || isResourceStale}
        onClick={async () => {
          if (isEditing) {
            await openPreSaveModal();
          } else {
            setIsEditing(true);
          }
        }}
      >
        {isEditing ? (
          <>
            <FiSave /> Save
          </>
        ) : (
          <>
            <FiEdit /> Edit
          </>
        )}
      </Button>
      {(dataSyncStatus?.status == 'IN_PROGRESS' && (
        <ProgressBar
          now={dataSyncStatus.percentage}
          style={{ width: '7rem', height: '80%', fontSize: '0.8125rem' }}
          animated={true}
          label={'Building dataset'}
        />
      )) || (
        <>
          <Button
            size={'sm'}
            disabled={isLoading}
            onClick={() => {
              triggerDataSync(false);
            }}
          >
            Rebuild dataset
          </Button>
        </>
      )}
      <OverlayTrigger
        placement={'bottom'}
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            Last built finished at{' '}
            {dataSyncStatus?.lastDataSyncCompletedAt ? formatDate(dataSyncStatus?.lastDataSyncCompletedAt) : ''}
          </Tooltip>
        }
      >
        <div>
          <FiDatabase
            size={'1.5rem'}
            color={
              dataSyncStatus?.status === 'IN_PROGRESS'
                ? '#8c6cf5'
                : dataSyncStatus?.status === 'COMPLETE'
                  ? 'green'
                  : dataSyncStatus?.status === 'FAILED'
                    ? 'red'
                    : '#8c6cf5'
            }
          />
        </div>
      </OverlayTrigger>
    </div>
  );
}
