import { useState } from 'react';
import { Alert } from 'react-bootstrap';

export function AppAlertBar({ messages }: { messages: string[] }) {
  const [activeMessages, setActiveMessages] = useState<string[]>(messages);

  return (
    <div className={activeMessages.length > 0 ? 'app-alert-bar open' : 'app-alert-bar'}>
      {activeMessages.map((message, idx) => (
        <Alert
          variant="warning"
          key={`${message}-${idx}`}
          dismissible
          onClose={() => {
            setActiveMessages(activeMessages.slice(0, activeMessages.length - 1));
          }}
        >
          {message} <a href="mailto:cs@goodfit.io">Message support</a>.
        </Alert>
      ))}
    </div>
  );
}
