import { useState, useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';

import { IntegrationType } from 'src/apis/integrations';
import { useClient } from 'src/auth';
import { showModal } from 'src/utils/modals';
import { ConnectorSettingsOffcanvasProps } from '..';
import { useIntegrationStore } from '../../../integrationsStore';
import { WebhookExamplePayloadModal } from '../../WebhookExamplePayloadModal';
import { integrationToast } from '../utils';

function isValidUrl(url: string | undefined | null) {
  return !url || url.startsWith('https://') || url.startsWith('http://');
}

export function WebhookForm({ integration }: ConnectorSettingsOffcanvasProps) {
  const [credentials, setInnerCredentials] = useState(integration?.credentials);
  const [isChanged, setIsChanged] = useState(false);
  const { isContactsEnabled, companiesSchema, contactsSchema } = useClient();
  const updateConnector = useIntegrationStore(state => state.updateIntegration);
  const addConnector = useIntegrationStore(state => state.addIntegration);

  const setCredentials = useCallback((credentials: any) => {
    setInnerCredentials(credentials);
    setIsChanged(true);
  }, []);

  const headers = credentials?.headers ?? [];

  let isValid = true;
  if (!isValidUrl(credentials?.accountUrl)) isValid = false;
  if (credentials?.contactsUrl && !isValidUrl(credentials?.contactsUrl)) isValid = false;
  if (
    credentials?.webhookBatchSize !== undefined &&
    (credentials?.webhookBatchSize < 1 || credentials?.webhookBatchSize > 200)
  ) {
    isValid = false;
  }
  return (
    <Form
      onSubmit={ev => {
        ev.preventDefault();
        integrationToast(async () => {
          if (integration?.id) {
            return await updateConnector(integration?.id as string, {
              credentials: { ...credentials, webhookBatchSize: Number(credentials?.webhookBatchSize) }
            });
          }
          return await addConnector({
            integrationType: IntegrationType.WEBHOOK,
            credentials: { ...credentials, webhookBatchSize: Number(credentials?.webhookBatchSize) }
          });
        });
      }}
    >
      <Form.Group className="mb-3" controlId="webhookCredentialsCompanies">
        <div className="d-flex justify-content-between gap-2 my-2">
          <h4>Webhook Companies URL</h4>
          <Button
            variant="white"
            size="sm"
            onClick={() =>
              showModal(WebhookExamplePayloadModal, {
                recordType: 'company',
                isContactsEnabled,
                companiesSchema,
                contactsSchema,
                isBatch: credentials?.webhookBatchSize && credentials?.webhookBatchSize > 1
              })
            }
          >
            Show example payload
          </Button>
        </div>
        <Form.Control
          onSubmit={ev => ev.preventDefault()}
          type="url"
          required
          name="accountUrl"
          value={credentials?.accountUrl ?? ''}
          size="sm"
          onChange={event => {
            event.preventDefault();
            setCredentials({ ...credentials, accountUrl: event.target.value || undefined });
          }}
          isInvalid={!isValidUrl(credentials?.accountUrl)}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">URL must start with "https://" or "http://"</Form.Control.Feedback>
      </Form.Group>

      <hr />

      {isContactsEnabled && (
        <>
          <Form.Group className="mb-3" controlId="webhookCredentialsContacts">
            <div className="d-flex justify-content-between gap-2 my-2">
              <h4>Webhook Contacts URL</h4>
              <Button
                variant="white"
                size="sm"
                onClick={() =>
                  showModal(WebhookExamplePayloadModal, {
                    recordType: 'contact',
                    isContactsEnabled,
                    companiesSchema,
                    contactsSchema,
                    isBatch: credentials?.webhookBatchSize && credentials?.webhookBatchSize > 1
                  })
                }
              >
                Show example payload
              </Button>
            </div>
            <Form.Control
              type="url"
              name="contactsUrl"
              size="sm"
              value={credentials?.contactsUrl ?? ''}
              onChange={event => {
                event.preventDefault();
                setCredentials({ ...credentials, contactsUrl: event.target.value || undefined });
              }}
              isInvalid={!isValidUrl(credentials?.contactsUrl)}
              onSubmit={ev => ev.preventDefault()}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">URL must start with "https://" or "http://"</Form.Control.Feedback>
          </Form.Group>
          <hr />
        </>
      )}
      <Form.Group className="mb-3" controlId="webhookBatchSize">
        <div className="d-flex justify-content-between gap-2 my-2">
          <h4>Webhook Batch Size</h4>
        </div>
        <Form.Control
          onSubmit={ev => ev.preventDefault()}
          type="number"
          name="webhookBatchSize"
          value={credentials?.webhookBatchSize ?? ''}
          size="sm"
          onChange={event => {
            event.preventDefault();
            setCredentials({
              ...credentials,
              webhookBatchSize: event.target.value
            });
          }}
          isInvalid={
            !(
              credentials?.webhookBatchSize === undefined ||
              (Number(credentials?.webhookBatchSize) >= 1 && Number(credentials?.webhookBatchSize) <= 200)
            )
          }
        ></Form.Control>
        <Form.Control.Feedback type="invalid">Value must be between 1 and 200</Form.Control.Feedback>
      </Form.Group>
      <Form.Text>
        Optional. Allows you to specify how many entries can be sent in a single request. Please note that this is a
        maximum size of a batch, in practice there might be batches smaller than the specified value. Left blank, or set
        to 1, will send entries one by one.
      </Form.Text>
      <hr />

      <div className="d-flex justify-content-between gap-2 my-2">
        <h4>Webhook headers</h4>
        <Button
          size="sm"
          variant="white"
          onClick={() => {
            setCredentials({ ...credentials, headers: [...headers, { header: '', value: '' }] });
          }}
        >
          Add header
        </Button>
      </div>
      <Form.Text>
        Optional. Allows you to include extra HTTP headers in each Webhook request, e.g. for authentication. Please see{' '}
        <a href="https://docs.goodfit.io/goodfit-docs/product/integrations/webhooks" target="_blank">
          documentation
        </a>
        .
      </Form.Text>
      {!headers.length && <div>No headers defined</div>}
      {headers.map((headerPair, index) => (
        <div className="d-flex gap-2 my-2" key={`header-${index}`}>
          <Form.Control
            name="authHeaderName"
            size="sm"
            placeholder="Header, eg Authorization"
            value={headerPair.header}
            onChange={ev => {
              const newHeaders = [...headers];
              newHeaders[index].header = ev.target.value;
              setCredentials({ ...credentials, headers: newHeaders });
            }}
          />
          <Form.Control
            name="authHeaderValue"
            size="sm"
            placeholder="Value, eg token"
            value={headerPair.value}
            onChange={ev => {
              const newHeaders = [...headers];
              newHeaders[index].value = ev.target.value;
              setCredentials({ ...credentials, headers: newHeaders });
            }}
          />
          <Button
            size="sm"
            variant="white"
            onClick={() => {
              const newHeaders = [...headers];
              newHeaders.splice(index, 1);
              setCredentials({ ...credentials, headers: newHeaders });
            }}
          >
            ✕
          </Button>
        </div>
      ))}
      <hr />
      <h4>HMAC Authentication</h4>
      <Form.Text>
        Optional. Signing the webhook with a shared secret allows you to verify that the webhook message originates from
        our service, by verifying the value of the{' '}
        <pre style={{ display: 'inline', color: 'darkblue' }}>x-goodfit-hmac-sha256</pre>
        HTTP header. To enable this feature, input the shared secret below. Please see{' '}
        <a href="https://docs.goodfit.io/goodfit-docs/product/integrations/webhooks" target="_blank">
          documentation
        </a>
        .
      </Form.Text>
      <Form.Control
        name="hmacSecret"
        size="sm"
        placeholder="Optional, secret value"
        value={credentials?.hmacSecret ?? ''}
        onChange={ev => {
          setCredentials({ ...credentials, hmacSecret: ev.target.value });
        }}
      />
      <hr />
      <div className="mt-4">
        <Button disabled={!isChanged || !isValid} type="submit" variant="primary" size="sm">
          Save changes
        </Button>
      </div>
    </Form>
  );
}
