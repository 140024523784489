import { Button, Card } from 'react-bootstrap';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import './pageElementStyles.scss';
import BatchTaskList from 'src/components/staff/BatchTasksList.tsx';
import SourceCriteriaEditor from 'src/pages/staff/clientConfigs/components/SourcingCriteriaEditor.tsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getFieldMap } from 'src/components/Filters/CCMFilters/services/service.ts';
import { useAdminContext } from 'src/adminContext/hooks.ts';
import { ClientConfig, CompanyCriteriaFilterOperator } from 'src/apis/clients/types.ts';
import { FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';
import PageLoader from 'src/components/PageLoader.tsx';
import { showModal } from 'src/utils/modals.ts';
import toast from 'react-hot-toast';
import { triggerBatch } from './api.ts';
import { TriggerRefreshModal } from 'src/pages/staff/pageElements/components/TriggerRefreshModal.tsx';
import { DataBlockConfigs } from 'src/pages/staff/clientConfigs/sourcing/dynamicDataBlocks/DataBlockConfigs.tsx';
import LayoutTNG from 'src/layouts/LayoutTNG';
import ClientSelect from 'src/components/staff/ClientSelect.tsx';

const emptyConfig = (): Partial<ClientConfig> => ({
  sourcingCriteria: {
    rules: [
      {
        field: 'firmographics/employee_count',
        value: 3,
        operator: CompanyCriteriaFilterOperator.GTE
      }
    ]
  },
  dataBlockConfigs: []
});

export function PageElementsConfigRefreshPage() {
  usePageTitle('Page Element Refresh');
  const { schema, dataBlocksDefinitions, dynamicDataBlocksDefinitions } = useAdminContext();
  const [fieldMapping, setFieldMapping] = useState<FieldMapping[]>();
  const [hasRunningBatchTask, setHasRunningBatchTask] = useState<boolean>(false);
  const [clientDestination, setClientDestination] = useState<string | undefined>(undefined);

  // the state is used to render the right components, Ref is used so components do not re-render to often
  // state is required only on the initial load. Ref for managing consecutive changes
  const [clientConfig, setClientConfig] = useState<Partial<ClientConfig>>(emptyConfig());
  const clientConfigRef = useRef<Partial<ClientConfig>>(emptyConfig());

  const handleChange = useCallback((update: any, path: string) => {
    clientConfigRef.current = { ...clientConfigRef.current, [path]: update };
  }, []);

  const handleChangeAndCommit = useCallback((update: any, path: string) => {
    clientConfigRef.current = { ...clientConfigRef.current, [path]: update };
    setClientConfig(prevState => ({ ...prevState, [path]: update }));
  }, []);

  const handleCommitChanges = useCallback(() => {
    setClientConfig(prevState => ({ ...prevState, ...clientConfigRef.current }));
    return clientConfigRef.current;
  }, []);

  useEffect(() => {
    clientConfig.dataBlockConfigs && setFieldMapping(getFieldMap(clientConfig.dataBlockConfigs, dataBlocksDefinitions));
  }, [clientConfig.dataBlockConfigs, dataBlocksDefinitions]);

  if (!fieldMapping) return <PageLoader />;

  return (
    <LayoutTNG title={'Page Element Refresh'} preTitle="Page Elements">
      <Card>
        <Card.Header>Batch refreshes</Card.Header>
        <Card.Body>
          <BatchTaskList
            taskType="PAGE_ELEMENTS_BATCH"
            onHasRunningTask={(has: boolean) => setHasRunningBatchTask(has)}
            showClientName={false}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Start Refresh</Card.Header>
        <Card.Body>
          <div className="pb-4">
            <h3>(Optional) Select a single client destination </h3>
            <ClientSelect clientName={clientDestination} setClientName={setClientDestination} />
          </div>
          <SourceCriteriaEditor
            schema={schema}
            // @ts-expect-error - clientConfig is Partial
            clientConfig={clientConfig}
            fieldMapping={fieldMapping}
            isEditing={true}
            handleChange={handleChange}
            handleCommitChanges={handleCommitChanges}
          />
          <DataBlockConfigs
            schema={schema}
            dynamicDataBlocksDefinitions={dynamicDataBlocksDefinitions}
            clientConfig={clientConfig as unknown as ClientConfig}
            isEditing={true}
            handleChange={handleChange}
            handleCommitChanges={handleCommitChanges}
            handleChangeAndCommit={handleChangeAndCommit}
          />
          <Button
            size="sm"
            variant="secondary"
            disabled={hasRunningBatchTask}
            onClick={() => {
              const config = handleCommitChanges();
              return showModal(
                TriggerRefreshModal,
                {
                  companyCriteria: config?.sourcingCriteria,
                  dataBlockConfigs: config?.dataBlockConfigs,
                  clientDestination
                },
                () => {
                  triggerBatch(config?.sourcingCriteria, config?.dataBlockConfigs, clientDestination)
                    .then(() => {
                      setHasRunningBatchTask(true);
                      toast.success('Batch refresh triggered. You can track progress in the Batch Tasks list.');
                    })
                    .catch(() => toast.error('Failed to trigger batch refresh. Please contact engineering'));
                }
              );
            }}
          >
            Trigger Refresh
          </Button>
        </Card.Body>
      </Card>
    </LayoutTNG>
  );
}
