import { useCallback, useEffect, useState } from 'react';
import CompaniesPageHeader from './CompaniesPageHeader';
import CompaniesTable from './CompaniesTable';
import CompanyOffcanvas from '../../components/companiesList/CompanyOffcanvas';
import { CompanySchema, useClient } from 'src/auth';
import API from 'src/utils/API';
import { useErrorBoundary } from 'react-error-boundary';
import { CompaniesAPIResponse, CompanyRecord } from './types';
import './companies-table.scss';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { decodeState, encodeState } from 'src/utils/urls';

interface CompaniesPageState {
  filters: any;
  pageIndex: number;
  pageSize: number;
  search?: string;
  sortby?: string;
  sortdirection?: 'asc' | 'desc';
  currentTalkTrackId?: string;
}

const DEFAULT_STATE: CompaniesPageState = {
  filters: null,
  pageIndex: 0, // 0 indexed
  pageSize: 25,
  search: undefined,
  sortby: '__quality_score',
  sortdirection: 'desc',
  currentTalkTrackId: undefined
};

function parseInitialState(companiesSchema: CompanySchema): CompaniesPageState {
  try {
    if (window.location.hash.startsWith('#state=')) {
      const newState = decodeState(window.location.hash.substring(7));
      return { ...DEFAULT_STATE, ...newState } as CompaniesPageState;
    }
  } catch (err) {
    console.warn('Could not parse initial state');
  }

  // Get default sort column if one exists
  const defaultSortField = companiesSchema.find(f => f.isDefaultSortField);
  if (defaultSortField) {
    return { ...DEFAULT_STATE, sortby: defaultSortField.fieldName };
  }

  return DEFAULT_STATE;
}

function serializeState(state: CompaniesPageState) {
  return 'state=' + encodeState(state);
}

function CompaniesPage({ title }: any) {
  const { companiesSchema } = useClient();
  const { showBoundary } = useErrorBoundary();

  const [totalItems, setTotalItems] = useState(0);
  const [isOffcanvasShowing, setIsOffcanvasShowing] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyRecord | null>(null);
  const [companies, setCompanies] = useState<CompanyRecord[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<CompaniesPageState>(() => parseInitialState(companiesSchema));

  function openCompanyOffcanvas(company: CompanyRecord) {
    setSelectedCompany(company);
    setIsOffcanvasShowing(true);
  }

  function modifyState(updates: any) {
    const newState = { ...state, ...updates };
    setState(newState);
    window.location.hash = serializeState(newState);
  }

  const loadCompanies = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await API.post<CompaniesAPIResponse>(
        '/app/companies/search',
        {},
        {
          filters: state.filters,
          offset: `${state.pageIndex * state.pageSize}`,
          limit: `${state.pageSize}`,
          search: state.search,
          sortby: state.sortby,
          sortdirection: state.sortdirection
        }
      );
      setCompanies(data.companies);
      setTotalItems(data?.companiesCnt);
      setIsLoading(false);
    } catch (err) {
      showBoundary(err);
    }
  }, [showBoundary, state]);

  usePageTitle(title);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  return (
    <>
      <CompaniesPageHeader
        totalItems={totalItems}
        onTabChange={() => {
          modifyState({ pageIndex: 0 });
          //loadCustomers();
        }}
        state={state}
      />
      <CompaniesTable
        isLoading={isLoading}
        companies={companies}
        pageIndex={state.pageIndex}
        schema={companiesSchema}
        state={state}
        modifyState={modifyState}
        totalItems={totalItems}
        onCompanySelect={openCompanyOffcanvas}
      />
      <CompanyOffcanvas
        company={{ record: selectedCompany }}
        show={isOffcanvasShowing}
        onHide={() => setIsOffcanvasShowing(false)}
      />
    </>
  );
}

export default CompaniesPage;
