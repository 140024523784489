import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useCallback, useEffect, useState } from 'react';
import { getLatestResults, getResults, triggerCheck } from './apis.ts';
import API from 'src/utils/API.ts';
import { ClientEnrichmentStatus } from 'src/pages/staff/sourcerEnrichment/types.ts';
import { SourcerEnrichmentList } from 'src/pages/staff/sourcerEnrichment/components/SourcerEnrichmentList.tsx';
import { formatDateString } from 'src/utils/string.ts';
import Tag from 'src/components/Tag.tsx';
import { HiOutlineBeaker } from 'react-icons/hi';
import WideLayout from 'src/layouts/Wide';

import { FiCheck } from 'react-icons/fi';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';

interface UserApiClient {
  clientName: string;
  userId: string;
  userPermissions: string[];
}

interface UserApiUser {
  clients: UserApiClient[];
  userId: string;
  email: string;
}

interface UserApiResponse {
  user: UserApiUser;
}

function SourcerEnrichmentPage({ title }: any) {
  usePageTitle(title);
  const [isLoading, setIsLoading] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [clientEnrichmentStatus, setClientEnrichmentStatus] = useState<ClientEnrichmentStatus>();
  const [clientName, setClientName] = useState<string>();
  const [clients, setClients] = useState<UserApiClient[]>([]);
  const [executionId, setExecutionId] = useState<string | null>(null);
  const [eta, setEta] = useState<string | undefined>();

  const loadPossibleClients = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await API.get<UserApiResponse>('/auth/user', {});
      setClients(data.user.clients);
      setIsLoading(false);
    } catch (err) {
      // showBoundary(err);
    }
  }, []);

  useEffect(() => {
    loadPossibleClients();
  }, [loadPossibleClients]);

  useEffect(() => {
    if (!clientName) {
      setIsLoading(false);
    } else
      getLatestResults(clientName).then(cc => {
        setClientEnrichmentStatus(cc);
        setIsLoading(false);
      });
  }, [clientName]);

  useEffect(() => {
    let interval: any;

    const pollResults = async () => {
      if (executionId) {
        const result = await getResults(executionId);
        if (['SUCCEEDED', 'FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status)) {
          clearInterval(interval);
          setExecutionId(null);
          setIsRunning(false);
          setClientEnrichmentStatus(result);
          const didFail = ['FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status);
          if (didFail) alert(`Sorry, preview errored: ${result.status}`);
        }
      }
      interval = setTimeout(pollResults, 5000); // Re-Poll in 5 seconds
    };

    if (executionId) {
      interval = setTimeout(pollResults, 5000); // Poll in 5 seconds
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [executionId]);

  const runCheck = useCallback(() => {
    if (!clientName) return;
    setIsRunning(true);
    triggerCheck(clientName).then(cc => {
      setExecutionId(cc.executionId);
      getResults(cc.executionId).then(cc => {
        setClientEnrichmentStatus(cc);
        setIsRunning(false);
      });
    });
  }, [clientName]);

  useEffect(() => {
    if (
      !isLoading &&
      !isRunning &&
      clientEnrichmentStatus?.status === 'SUCCEEDED' &&
      !isEmpty(clientEnrichmentStatus?.output?.enrichmentStatus)
    ) {
      const expectedDate = new Date(clientEnrichmentStatus?.output.checkedAt);
      const maxMinutes = Number(max(clientEnrichmentStatus.output.enrichmentStatus.map(es => es.eta.expected)));
      expectedDate.setMinutes(expectedDate.getMinutes() + maxMinutes);
      setEta(expectedDate.toISOString());
    } else {
      setEta(undefined);
    }
  }, [clientEnrichmentStatus?.output, clientEnrichmentStatus?.status, isLoading, isRunning]);

  return (
    <WideLayout
      title={
        <>
          Sourcers Enrichment{' '}
          <Tag color="#aaa">
            <HiOutlineBeaker /> Beta
          </Tag>
        </>
      }
      preheader="Staff admin"
    >
      <Card>
        <Card.Header>
          <Row className="align-items-center justify-content-between">
            <Col xs="auto" className="p-1">
              <Form.Select
                aria-label="Default select example"
                onChange={e => {
                  setClientName(e.target.value);
                  setIsLoading(true);
                }}
              >
                <option key={'empty-client-name'} value={'empty-client-name'}></option>
                {clients?.map(item => (
                  <option value={item.clientName} key={item.clientName}>
                    {item.clientName}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs="auto" className="d-flex align-items-center p-1">
              {!isRunning && !isLoading && (
                <div className="p-2">
                  <div>
                    {clientEnrichmentStatus?.status === 'SUCCEEDED' && (
                      <>Last Run: {formatDateString(clientEnrichmentStatus?.output.checkedAt)}</>
                    )}
                  </div>
                  <div>
                    {clientEnrichmentStatus?.output?.enrichmentStatus === undefined && <></>}
                    {eta != undefined &&
                      Number(max(clientEnrichmentStatus?.output?.enrichmentStatus?.map(es => es.eta.expected))) ===
                        0 && (
                        <>
                          Enrichment finished <FiCheck color={'green'} />
                        </>
                      )}
                    {eta != undefined &&
                      Number(max(clientEnrichmentStatus?.output?.enrichmentStatus?.map(es => es.eta.expected))) > 0 && (
                        <>ETA: {formatDateString(eta)}</>
                      )}
                  </div>
                </div>
              )}
              <div>
                <Button size="sm" onClick={runCheck} disabled={isRunning || isLoading}>
                  Run Check
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <SourcerEnrichmentList
            isLoading={isLoading}
            isRunning={isRunning}
            clientEnrichmentStatus={clientEnrichmentStatus}
            runCheck={runCheck}
          />
        </Card.Body>
      </Card>
    </WideLayout>
  );
}

export default SourcerEnrichmentPage;
