import toast from 'react-hot-toast';

export async function integrationToast(fun: () => Promise<boolean>) {
  const toastId = toast.loading('Saving...');
  const result = await fun();
  toast.dismiss(toastId);
  if (result) {
    toast.success('Connector saved');
  } else {
    toast.error('Error saving integration');
  }
}
