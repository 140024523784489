import { useState, useCallback, useEffect, useMemo } from 'react';
import { Tab, Card, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useClient } from 'src/auth';
import WideLayout from 'src/layouts/Wide';
import { usePageTitle } from 'src/utils/usePageTitle';
import CompaniesTable from '../../components/companiesList/CompaniesTable';
import { CompanyRecord } from '../companies/types';
import { SegmentSettingsForm } from './components/edit/SegmentSettingsForm';
import PageLoader from 'src/components/PageLoader';
import { Segment } from './types';
import { createSegment, fetchCompaniesPreview, loadSegment, saveSegment, triggerSegmentUpdateProcess } from './apis';
import { pluralize } from 'src/utils/string';
import { formatNumber } from 'src/utils/number';
import { Debouncer } from 'src/utils/Debouncer';
import toast from 'react-hot-toast';
import { appClientUrl } from 'src/utils/urls';
import { computeTableColumns } from './utils';
import EmptyMessage from 'src/components/EmptyMessage';
import CompanyOffcanvas from '../../components/companiesList/CompanyOffcanvas';

const PREVIEW_PAGE_SIZE = 50;
const previewDebouncer = new Debouncer(1000);

const defaultNewSegment = {
  name: '',
  filters: {},
  inclusions: {},
  exclusions: {},
  limitOptions: {},
  isDynamic: false
};

export default function SegmentEditOrCreatePage() {
  const { segmentId } = useParams();
  const navigate = useNavigate();
  const { companiesSchema } = useClient();

  const isNewSegment = segmentId === 'new';

  const pageTitle = isNewSegment ? 'Create segment' : 'Edit segment';
  usePageTitle(pageTitle);

  const [isSegmentNameValid, setIsSegmentNameValid] = useState<boolean>(true);
  const [hasEverLoaded, setHasEverLoaded] = useState(false);
  const [segment, setSegment] = useState<Partial<Segment> | null>(null);
  const [companiesPreview, setCompaniesPreview] = useState<CompanyRecord[] | null>(null);
  const [previewCount, setPreviewCount] = useState<number | null>(null);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [isOffcanvasShowing, setIsOffcanvasShowing] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<null | CompanyRecord>(null);

  const loadInitialState = useCallback(async () => {
    if (isNewSegment) {
      setSegment(defaultNewSegment);
    } else if (segmentId) {
      const response = await loadSegment(segmentId);
      setSegment(response.item);
    }
    setIsLoading(false);
    setHasEverLoaded(true);
  }, [segmentId, isNewSegment]);

  useEffect(() => {
    loadInitialState();
  }, [loadInitialState]);

  const loadCompaniesPreview = useCallback(async () => {
    if (!segment) return;
    const data = await fetchCompaniesPreview(segment, PREVIEW_PAGE_SIZE);
    setCompaniesPreview(data.items);
    setPreviewCount(data.count);
    setPreviewLoading(false);
  }, [segment]);

  const updateSegment = useCallback((key: string, value: any) => {
    setSegment(existingSegment => ({ ...existingSegment, [key]: value }));
  }, []);

  const handleSave = useCallback(() => {
    const doSave = async () => {
      if (segment && segmentId) {
        if (isNewSegment) {
          const result = await createSegment(segment);
          const newSegmentId = result.item.id;
          await triggerSegmentUpdateProcess(newSegmentId);
          navigate(appClientUrl(`/segments/view/${newSegmentId}`));
        } else {
          await saveSegment(segmentId, segment);
          await triggerSegmentUpdateProcess(segmentId);
          navigate(appClientUrl(`/segments/view/${segmentId}`));
        }
      }
    };
    return toast.promise(
      doSave(),
      {
        loading: 'Saving segment',
        success: isNewSegment
          ? 'Segment added. It can take several minutes to calculate for the first time and appear.'
          : 'Segment saved and is being re-calculated. It can take several minutes for the re-calculation to complete.',
        error: 'Error saving segment'
      },
      { success: { duration: 5000 } }
    );
  }, [isNewSegment, navigate, segment, segmentId]);

  useEffect(() => {
    setPreviewLoading(true);
    if (hasEverLoaded) {
      previewDebouncer.schedule(() => loadCompaniesPreview());
    } else {
      previewDebouncer.immediate(() => loadCompaniesPreview());
    }
    // We only want to trigger on certain changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment?.filters, segment?.inclusions, segment?.exclusions, segment?.limitOptions]);

  const selectedColumns = useMemo(
    () => computeTableColumns(companiesSchema, segment?.filters, segment?.limitOptions),
    [segment?.filters, segment?.limitOptions, companiesSchema]
  );

  if (isLoading) return <PageLoader />;

  const isSegmentValidToSave =
    !!segment?.name?.trim() && previewCount !== null && previewCount > 0 && isSegmentNameValid;

  return (
    <WideLayout title={pageTitle} preheader="">
      <Tab.Container id="list-tabs" defaultActiveKey="companies">
        <Card>
          <Card.Header>Segment definition</Card.Header>
          <Card.Body>
            <div>
              <SegmentSettingsForm
                segment={segment as any}
                updateSegment={updateSegment}
                isSegmentNameValid={isSegmentNameValid}
                setIsSegmentNameValid={setIsSegmentNameValid}
              />
            </div>
            <div className="my-3 d-flex justify-content-between align-items-center">
              <div>
                {previewCount !== null &&
                  `${formatNumber(previewCount)} ${pluralize(previewCount, 'company', 'companies')}`}
              </div>
              <div>
                <Button size="sm" variant="primary" disabled={!isSegmentValidToSave} onClick={handleSave}>
                  Save segment
                </Button>
              </div>
            </div>
            <div style={{ border: '1px solid #eee', position: 'relative' }}>
              <CompaniesTable
                companies={companiesPreview ?? []}
                isLoading={previewLoading}
                schema={companiesSchema}
                enableSorting={false}
                selectedColumns={selectedColumns}
                onCompanySelect={(row: CompanyRecord) => {
                  setSelectedCompany(row);
                  setIsOffcanvasShowing(true);
                }}
                emptyComponent={
                  <EmptyMessage message="No companies matching criteria. Please modify segment definition."></EmptyMessage>
                }
              />
            </div>
          </Card.Body>
        </Card>
      </Tab.Container>
      <CompanyOffcanvas
        company={{ record: selectedCompany }}
        show={isOffcanvasShowing}
        onHide={() => setIsOffcanvasShowing(false)}
        showContactsTab={false}
        showSyncTab={false}
      />
    </WideLayout>
  );
}
