import { Button, Form, InputGroup } from 'react-bootstrap';
import { FiSearch, FiX } from 'react-icons/fi';
import { PageElementDetectionRule } from 'src/pages/staff/pageElements/types.ts';
import { SearchInput } from 'src/components/inputs/SearchInput.tsx';
import './detectionRuleForm.scss';
import Select from 'src/components/Select.tsx';

export function DetectionRuleForm({
  rule,
  update,
  deleteRule,
  pageTypesOptions,
  onPreview
}: {
  rule: PageElementDetectionRule;
  update: (update: PageElementDetectionRule) => void;
  deleteRule: () => void;
  pageTypesOptions: string[];
  onPreview: () => void;
}) {
  const loadPageTypes = async (input: string) => pageTypesOptions?.filter(i => i.includes(input)) || [];

  function isValidRegex(regex: string) {
    const regexPattern = /^\/.+\/[gimsuy]*$/;
    if (!regexPattern.test(regex)) {
      return false;
    }
    try {
      new RegExp(regex);
      return true;
    } catch (e) {
      return false;
    }
  }

  return (
    <div className={'w-100 d-flex flex-column gap-2'}>
      <div className="top-right-corner m-2 d-flex gap-2">
        <Button size={'sm'} variant={'outline-secondary'} disabled={!isValidRegex(rule.regex)} onClick={onPreview}>
          <FiSearch />
        </Button>
        <Button size={'sm'} variant={'outline-danger'} onClick={deleteRule}>
          <FiX />
        </Button>
      </div>
      <div className={'d-flex flex-row gap-2'}>
        <div className={'detectionRuleField'}>
          <InputGroup className="input-group" size={'sm'}>
            <InputGroup.Text className="input-text-header">Source</InputGroup.Text>
            <Select
              value={rule.source || ''}
              onChange={newValue => update({ ...rule, source: newValue as any })}
              options={['text', 'href', 'alt', 'src', 'html'].map(i => ({ label: i, value: i }))}
              styles={{
                control: (baseStyles: any) => ({
                  ...baseStyles,
                  borderStartStartRadius: 0,
                  borderEndStartRadius: 0
                })
              }}
            />
          </InputGroup>
        </div>
        <div className={'detectionRuleField'}>
          <SearchInput
            title={'Page types'}
            value={rule.pageTypes || []}
            loadOptions={loadPageTypes}
            onUpdate={(value: string[]) => update({ ...rule, pageTypes: value })}
          />
        </div>
      </div>
      <div className={'d-flex flex-row gap-2'}>
        <div className={'detectionRuleField'}>
          <InputGroup className={'input-group'} size={'sm'}>
            <InputGroup.Text className="input-text-header">Regex</InputGroup.Text>
            <Form.Control
              className={'input-text'}
              placeholder=""
              value={rule.regex || ''}
              onChange={e => update({ ...rule, regex: e.target.value })}
              isInvalid={!isValidRegex(rule.regex) || (rule.regex?.length > 0 && rule.functionName?.length > 0)}
            />
          </InputGroup>
        </div>
        <div className={'detectionRuleField'}>
          <InputGroup className={'input-group'} size={'sm'}>
            <InputGroup.Text className="input-text-header">Function name</InputGroup.Text>
            <Form.Control
              className={'input-text'}
              placeholder=""
              value={rule.functionName || ''}
              onChange={e => update({ ...rule, functionName: e.target.value })}
              isInvalid={rule.functionName?.length > 0 && rule.regex?.length > 0}
            />
          </InputGroup>
        </div>
      </div>
    </div>
  );
}
