import { TechnographicsConfig } from 'src/pages/staff/technographicsConfig/types.ts';

export function removeEmptyPatterns(technographicsConfig: TechnographicsConfig) {
  return technographicsConfig.jobsPatterns
    .map((pattern: any) => {
      pattern.patterns = pattern.patterns.filter((p: string) => p?.length > 0);
      return pattern;
    })
    .filter((pattern: any) => pattern.patterns?.length > 0);
}
