import { useState } from 'react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

export const Collapsable = ({
  title,
  icon,
  children,
  initiallyOpen = false,
  options,
  onCollapse
}: {
  title: string;
  icon?: any;
  children: any;
  initiallyOpen?: boolean;
  options?: any;
  onCollapse: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          onClick={() => {
            onCollapse();
            setIsOpen(!isOpen);
          }}
        >
          <h3 style={{ display: 'flex', alignItems: 'center' }}>
            {isOpen ? <FiChevronDown /> : <FiChevronRight />} {icon}
            <div> {title}</div>
          </h3>
        </div>
        {options && <div>{options}</div>}
      </div>
      {isOpen && <div style={{ paddingLeft: '2rem' }}>{children}</div>}
    </div>
  );
};
