export default function ErrorMessage({
  message,
  actions,
  fullHeight,
  style
}: {
  message?: string;
  actions?: React.ReactNode;
  fullHeight: boolean;
  style?: any;
}) {
  const classNames = ['d-flex', 'flex-column ', 'justify-content-center', 'align-items-center'];
  if (fullHeight) classNames.push('min-vh-100');

  return (
    <div style={style} className={classNames.join(' ')}>
      {message ?? 'Sorry, an error occurred.'}
      <div className="mt-3">{actions}</div>
    </div>
  );
}

ErrorMessage.defaultProps = {
  fullHeight: true,
  style: {}
};
