import { useState } from 'react';
import { Alert, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import Button from 'src/components/LoadingButton';
import { useAuth } from 'src/auth';
import goodFitLogo from '/img/goodfit-logo-text.svg';
import { FiLock } from 'react-icons/fi';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useNavigate } from 'react-router-dom';
import { validateNewPassword } from 'src/pages/auth/validatePassword.ts';

interface ChangePasswordProps {
  error?: string;
  isSubmitting?: boolean;
  onSubmit: (oldPassword: string, newPassword: string) => void;
}

function ChangePasswordForm({ onSubmit, error, isSubmitting }: ChangePasswordProps) {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState<string | undefined>(undefined);

  return (
    <div style={{ marginTop: -80 }}>
      <h1 className="display-4 text-center mb-3">
        <img className="navbar-brand-img" src={goodFitLogo} alt="GoodFit" style={{ height: 25 }} />
      </h1>
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(oldPassword, password as string);
        }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="form-group">
          <Form.Label>Old password</Form.Label>
          <Form.Control type="password" tabIndex={1} onChange={e => setOldPassword(e.target.value)} />
        </div>
        <div className="form-group">
          <Form.Label>New password</Form.Label>
          <InputGroup className="input-group-merge">
            <Form.Control
              type="password"
              tabIndex={2}
              placeholder="Enter new password"
              onChange={e => setPassword(e.target.value)}
              isInvalid={password !== undefined && validateNewPassword(password)?.details?.length > 0}
            />
            <InputGroup.Text>
              <FiLock />
            </InputGroup.Text>
          </InputGroup>
          <div className={'invalid-feedback d-block'}>
            {password !== undefined && validateNewPassword(password)?.details[0]?.message}
          </div>
        </div>
        <Button size="lg" className="w-100 mb-3" type="submit" tabIndex={3} loading={isSubmitting}>
          Change password
        </Button>
      </Form>
    </div>
  );
}

export default function ChangePasswordPage({ title }: any) {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  usePageTitle(title);

  const handlePasswordChange = async (oldPassword: string, newPassword: string) => {
    setIsSubmitting(true);
    setError('');
    try {
      await auth.changePassword(oldPassword, newPassword);
      navigate('/app');
    } catch (err: unknown) {
      console.error(err);
      setError('Error while changing password: ' + (err as Error).message);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="d-flex align-items-center min-vh-100 bg-auth">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={5} xl={4} className="">
            <ChangePasswordForm onSubmit={handlePasswordChange} error={error} isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
