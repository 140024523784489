import SourceCriteriaEditor from 'src/pages/staff/clientConfigs/components/SourcingCriteriaEditor.tsx';
import { FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';
import { useEffect, useMemo, useState } from 'react';
import { getFieldMap } from 'src/components/Filters/CCMFilters/services/service.ts';
import PageLoader from 'src/components/PageLoader.tsx';
import { DataBlockConfigs } from 'src/pages/staff/clientConfigs/sourcing/dynamicDataBlocks/DataBlockConfigs.tsx';
import { ActionWithRulesProps } from 'react-querybuilder';
import { CoverageAnalysisDTO, CoverageAnalysisResult } from 'src/pages/staff/coverageAnalysis/types.ts';
import { CompanyListModal } from 'src/pages/staff/coverageAnalysis/components/CompanyListModal.tsx';
import { RuleOrnament } from 'src/pages/staff/coverageAnalysis/sourcing/RuleOrnament.tsx';
import { ClientConfig, CompanyCriteriaGroup } from 'src/apis/clients/types.ts';
import { useAdminContext } from 'src/adminContext/hooks.ts';
import { DisabledActions, DisabledPath } from 'src/components/Filters/disabledPaths.ts';

type SourcingComponentProps = {
  schema: any;
  clientConfig: CoverageAnalysisDTO;
  isEditing: boolean;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
  coverageAnalysisResult: CoverageAnalysisResult;
  openValuesModalFor: (path: string) => void;
};

function extractDisabledPaths(
  sourcingCriteria: CompanyCriteriaGroup
): { path: number[]; disable: DisabledActions[] }[] {
  if (!sourcingCriteria) return [];
  return extractFromGroup(sourcingCriteria, []);
}

function extractFromGroup(group: CompanyCriteriaGroup, path: number[]): DisabledPath[] {
  // it returns a flat array of paths to be disabled.
  // Paths in react query builder are constructed this way, so we just use that here
  // it looks like [[],[0],[0,1],[0,2]]
  const res: DisabledPath[] = group.rules.flatMap((rule, index) => {
    const newPath = [...path, index];
    if ('rules' in rule) {
      if (rule.id?.startsWith('mandatory')) {
        return [{ path: newPath, disable: ['All'] }, ...extractFromGroup(rule, newPath)];
      }
      return extractFromGroup(rule, newPath);
    }
    if (rule.id?.startsWith('mandatory')) {
      return [{ path: newPath, disable: ['All'] }];
    }
    return [];
  });
  if (res.length > 0) {
    // to make sure the top level group is disabled as well
    return [
      { path: [], disable: ['All'] },
      { path: [0], disable: ['removeGroupAction', 'cloneGroupAction', 'groupActionOrnament'] },
      ...res
    ];
  }
  return res;
}

export function SourcingComponent({
  schema,
  clientConfig,
  isEditing,
  handleChange,
  handleCommitChanges,
  handleChangeAndCommit,
  coverageAnalysisResult,
  openValuesModalFor
}: SourcingComponentProps) {
  const { dataBlocksDefinitions, dynamicDataBlocksDefinitions } = useAdminContext();

  const [companiesModalFilters, setCompaniesModalFilters] = useState<any>({});
  const [fieldMapping, setFieldMapping] = useState<FieldMapping[]>();

  useEffect(() => {
    clientConfig.dataBlockConfigs && setFieldMapping(getFieldMap(clientConfig.dataBlockConfigs, dataBlocksDefinitions));
  }, [clientConfig.dataBlockConfigs, dataBlocksDefinitions]);

  const matchedTargetCompanies = (path: string, coverageAnalysisResult: CoverageAnalysisResult, matched: boolean) => {
    return coverageAnalysisResult.targetCompanies.filter(tc => tc.rules.find(r => r.id === path)?.included === matched);
  };

  const matchedValues = (path: string, coverageAnalysisResult: CoverageAnalysisResult, matched: boolean) => {
    if (coverageAnalysisResult.targetCompanies.length === 0) return '';
    const found = matchedTargetCompanies(path, coverageAnalysisResult, matched);
    return `${found.length} (${Math.round((found.length * 100) / coverageAnalysisResult.targetCompanies.length)}%)`;
  };

  const disabledPaths = useMemo<{ path: number[]; disable: DisabledActions[] }[]>(() => {
    return extractDisabledPaths(clientConfig.sourcingCriteria);
  }, [clientConfig.sourcingCriteria]);

  if (!fieldMapping) return <PageLoader />;

  return (
    <div>
      <SourceCriteriaEditor
        schema={schema}
        clientConfig={clientConfig as unknown as ClientConfig}
        fieldMapping={fieldMapping}
        isEditing={isEditing}
        handleChange={handleChange}
        handleCommitChanges={handleCommitChanges}
        groupActionOrnament={(f: ActionWithRulesProps, disabled: boolean | undefined) =>
          RuleOrnament(
            f,
            disabled,
            (path: string) => matchedValues(path, coverageAnalysisResult, false),
            (path: string) => setCompaniesModalFilters({ included: false, ruleId: path }),
            (path: string) => matchedValues(path, coverageAnalysisResult, true),
            (path: string) => setCompaniesModalFilters({ included: true, ruleId: path })
          )
        }
        ruleActionOrnament={(f: ActionWithRulesProps, disabled: boolean | undefined) =>
          RuleOrnament(
            f,
            disabled,
            (path: string) => matchedValues(path, coverageAnalysisResult, false),
            (path: string) => setCompaniesModalFilters({ included: false, ruleId: path }),
            (path: string) => matchedValues(path, coverageAnalysisResult, true),
            (path: string) => setCompaniesModalFilters({ included: true, ruleId: path }),
            (path: string) => openValuesModalFor(path)
          )
        }
        context={{ disabledPaths }}
      />
      <DataBlockConfigs
        schema={schema}
        dynamicDataBlocksDefinitions={dynamicDataBlocksDefinitions}
        clientConfig={clientConfig as unknown as ClientConfig}
        isEditing={isEditing}
        handleChange={handleChange}
        handleCommitChanges={handleCommitChanges}
        handleChangeAndCommit={handleChangeAndCommit}
      />
      <CompanyListModal
        show={'included' in companiesModalFilters}
        filters={companiesModalFilters}
        onHide={() => setCompaniesModalFilters({})}
        coverageReport={coverageAnalysisResult}
      />
    </div>
  );
}
