import { useRef, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Fixes bugs in react-dnd so that it does not hijack other draggable elements
export function DndProviderWrapper({ className, children }: any) {
  const context = useRef(null);
  const dndArea = context.current;
  const html5Options = useMemo(() => ({ rootElement: dndArea }), [dndArea]);
  return (
    <div className={className} ref={context}>
      {dndArea && (
        <DndProvider backend={HTML5Backend} options={html5Options}>
          {children}
        </DndProvider>
      )}
    </div>
  );
}
