import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from './hooks';

interface RequireAuthProps {
  children?: React.ReactNode;
}

export default function RequireAuth({ children }: RequireAuthProps) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const fullUrl = location.pathname + location.search + location.hash; // Including query string and hash
    const path = '/login?next=' + encodeURIComponent(fullUrl);
    return <Navigate to={path} state={{ from: location }} replace />;
  }

  return children;
}
