export enum IntegrationType {
  SALESFORCE = 'SALESFORCE',
  HUBSPOT = 'HUBSPOT',
  WEBHOOK = 'WEBHOOK',
  PIPEDRIVE = 'PIPEDRIVE'
}

export enum IntegrationFieldUpdateStrategy {
  OVERWRITE_ALWAYS = 'OVERWRITE_ALWAYS',
  OVERWRITE_ONLY_IF_EMPTY = 'OVERWRITE_ONLY_IF_EMPTY'
}

export type IntegrationExternalFieldDescription = {
  id: string;
  label: string;
  type: string;
};

export type Integration = {
  id: string;
  integrationType: IntegrationType;
  mappingSettings?: {
    companies: IntegrationFieldMapping[];
    contacts: IntegrationFieldMapping[];
  };
  companiesSyncMode?: 'UPDATE_ONLY' | 'CREATE_AND_UPDATE';
  contactsSyncMode?: 'UPDATE_ONLY' | 'CREATE_AND_UPDATE' | 'DISABLED';
  enableAutoSync?: boolean;
  syncSegmentRecordsOnly: boolean;
  segmentIds: string[];
  hasCredentials?: boolean;
  // not null only for webhooks && pipedrive
  credentials?: {
    accountUrl?: string;
    contactsUrl?: string;
    webhookBatchSize?: number;
    apiKey?: string;
    headers?: { header: string; value: string }[];
    hmacSecret?: string;
  } | null;
  isSalesforceSandbox?: boolean;
};

export type IntegrationFieldMapping = {
  id: string; // Random uuid
  integrationId: string;
  internalName: string; // dataset name (not CCM name)
  externalName: string; // CRM field name or ID
  updateStrategy?: IntegrationFieldUpdateStrategy;
  isSaving?: boolean;
};

export type IntegrationStatus = 'COMPLETE' | 'IN_PROGRESS' | 'FAILED' | 'CANCELLED';

export interface IntegrationJob {
  id: string;
  integrationType: IntegrationType;
  started: string;
  autosync: boolean;
  status: IntegrationStatus;
  progress: {
    progress: number;
    numAccountsToProcess: number;
    numAccountsCreated: number;
    numAccountsUpdated: number;
    numAccountsFailed: number;
    numContactsCreated: number;
    numContactsUpdated: number;
    numContactsFailed: number;
  };
}

export interface ErrorSummaryRow {
  recordType: 'COMPANY' | 'CONTACT';
  integrationId: string;
  integrationType: IntegrationType;
  errorCode: string;
  errorMessage: string | null;
  field: string | null;
  errorCount: number;
}

export interface ErrorDetails {
  recordType: string;
  integrationId: string;
  integrationType: string;
  errorCode: string;
  originalMessage: string;
  field: string;
  value: string;
  record: string;
  lastSyncAttempt: Date;
}

export interface ErrorsSummaryType {
  errorsCnt: number;
  errors: ErrorSummaryRow[];
}

export interface IntegrationJobsType {
  accountName: string;
  jobsCnt: number;
  jobs: IntegrationJob[];
}

export interface CheckStartResult {
  canStartJob: boolean;
  cantStartJobReason: 'INTEGRATION_JOB_ALREADY_RUNNING' | 'INTEGRATION_INCORRECTLY_CONFIGURED' | null;
}
