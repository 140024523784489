export const lifecycleLabels = {
  CALIBRATION: 'Calibration client',
  CLIENT_ONBOARDING: 'Onboarding client',
  CLIENT_ACTIVE: 'Active paying client',
  CLIENT_CHURNED: 'Churned client',
  CLIENT_TEST_ACCOUNT: 'Client test/dev account',
  GOODFIT_TEST_ACCOUNT: 'GoodFit test account',
  OTHER: 'Other'
};

export const lifecycleColors = {
  CALIBRATION: 'orange',
  CLIENT_ONBOARDING: 'cyan',
  CLIENT_ACTIVE: 'green',
  CLIENT_CHURNED: 'magenta',
  CLIENT_TEST_ACCOUNT: 'grey',
  GOODFIT_TEST_ACCOUNT: 'grey',
  OTHER: 'grey'
};
