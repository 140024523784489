import { useCallback, useMemo } from 'react';
import { deleteSavedReport, fetchSavedReports, SavedMarketEstimation } from './apis';
import { ListTable } from 'src/components/Table/listTable/ListTable';
import { ColumnHeader } from 'src/components/Table/listTable/ColumnHeader';
import { getReactTableCellRendererForType } from 'src/components/Table/cellRenderers';
import { SchemaDataType } from 'src/auth';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';

export function SavedReportsList({ onLoadReport }: { onLoadReport: (config: SavedMarketEstimation) => void }) {
  const handleDelete = useCallback((id: string) => {
    toast.promise(deleteSavedReport(id), {
      loading: 'Deleting...',
      success: 'Deleted',
      error: 'Error!'
    });
  }, []);

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        id: 'id',
        accessorFn: (row: any) => row.id,
        enableSorting: true,
        enableColumnFilter: true,
        cell: getReactTableCellRendererForType(SchemaDataType.String),
        header: ({ column }) => (
          <ColumnHeader column={column} headerName={'Name'} allowSorting={true} allowFiltering={false} />
        )
      },
      {
        id: 'clientName',
        accessorFn: (row: any) => row.config.clientName,
        enableSorting: true,
        enableColumnFilter: false,
        cell: getReactTableCellRendererForType(SchemaDataType.String),
        header: ({ column }) => (
          <ColumnHeader column={column} headerName={'Client Name'} allowSorting={true} allowFiltering={false} />
        )
      },
      {
        id: 'requestedByUserEmail',
        accessorFn: (row: any) => row.requestedByUserEmail,
        enableSorting: true,
        enableColumnFilter: false,
        cell: getReactTableCellRendererForType(SchemaDataType.String),
        header: ({ column }) => (
          <ColumnHeader column={column} headerName={'Saved By'} allowSorting={true} allowFiltering={false} />
        )
      },
      {
        id: 'count',
        accessorFn: (row: any) => row.count,
        enableSorting: true,
        enableColumnFilter: false,
        cell: getReactTableCellRendererForType(SchemaDataType.Number),
        header: ({ column }) => (
          <ColumnHeader column={column} headerName={'Count'} allowSorting={true} allowFiltering={false} />
        )
      },
      {
        id: 'Options',
        cell: c => (
          <div className={'d-flex justify-content-center'} style={{ gap: 8 }}>
            <Button size="sm" variant="white" onClick={() => onLoadReport(c.row.original)}>
              Load report
            </Button>
            <Button size="sm" variant="white" onClick={() => handleDelete(c.row.original.id)}>
              Delete
            </Button>
          </div>
        ),
        header: () => <>Options</>
      }
    ],
    [handleDelete, onLoadReport]
  );

  return (
    <ListTable
      columns={columns}
      emptyMessage={'No saved reports found'}
      list={fetchSavedReports}
      shouldPollData
      pollInterval={5000}
    />
  );
}
