import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useClient } from 'src/auth';
import { useMediaQuery } from 'src/utils/hooks/useMediaQuery';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { sleep } from 'src/utils/sleep';
import { produceSummaryTexts } from './produceSummaryTexts';
import { BadgeContent } from './BadgeContent';
import { ContactCreditPanel } from './ContactCreditPanel';

export const ContactCreditSummary: React.FC = () => {
  const client = useClient();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const [hoverActive, setHoverActive] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const summaryTexts = produceSummaryTexts(client);

  const handleHoverStart: React.MouseEventHandler<HTMLElement> = () => setHoverActive(true);
  const handleHoverEnd: React.MouseEventHandler<HTMLElement> = () => {
    setHoverActive(false);
    setCopySuccess(false);
  };

  /** Briefly show success feedback following {@link copySummaryText} */
  useEffect(() => {
    if (!copySuccess) return;
    sleep(1000).finally(() => setCopySuccess(false));
  }, [copySuccess]);

  const copySummaryText = (): void => {
    const { companiesSummary, contactCreditsSummary, enrichmentCreditsSummary } = summaryTexts;

    const summaryText = [companiesSummary, contactCreditsSummary, enrichmentCreditsSummary].filter(Boolean).join('\n');

    copyToClipboard(summaryText);
    setCopySuccess(true);
  };

  return (
    <OverlayTrigger
      placement={isSmallScreen ? 'bottom' : 'right'}
      overlay={props => (
        <Tooltip {...props} className="contact-credit-tooltip">
          <ContactCreditPanel summaryTexts={summaryTexts} />
        </Tooltip>
      )}
    >
      <Badge
        bg="light"
        onClick={copySummaryText}
        onMouseLeave={handleHoverEnd}
        onMouseEnter={handleHoverStart}
        className="contact-credit-badge"
      >
        <BadgeContent copySuccess={copySuccess} hoverActive={hoverActive} />
      </Badge>
    </OverlayTrigger>
  );
};
