import { Button, Table } from 'react-bootstrap';
import { MdOutlineBarChart } from 'react-icons/md';

import { DataDirectoryEntry, RecordType } from '../types';
import FieldMappingsDisplay from './FieldMappingsDisplay';
import FieldMappingsEditor from './FieldMappingsEditor';
import TypeTag from './TypeTag';
import Tag from 'src/components/Tag';
import { useFieldInfoOffcanvas } from 'src/components/FieldInfoOffcanvas/useFieldInfoOffcanvas';
import { FiInfo } from 'react-icons/fi';
import { TooltipWrapper } from 'src/components/TooltipWrapper';

export interface FieldMappingTableProps {
  entries: DataDirectoryEntry[];
  recordType: RecordType;
  isEditing: boolean;
}

export default function FieldMappingTable({ entries, recordType, isEditing }: FieldMappingTableProps) {
  const { showFieldInfoOffcanvas, modalElement } = useFieldInfoOffcanvas();

  return (
    <>
      <Table size="sm" className="mapping-table" bordered>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Field</th>
            <th style={{ width: '16%' }}>Data Type</th>
            <th style={{ width: '59%' }}>CRM Field Mappings</th>
            <th style={{ width: '5%' }}></th>
          </tr>
        </thead>
        <tbody>
          {entries.map(entry => (
            <tr key={entry.fieldName}>
              <td className={'text-wrap'}>
                {entry.displayName} {entry.isSystem && <Tag color="grey">System</Tag>}
                <TooltipWrapper
                  tooltipText={entry.description ?? 'No description available'}
                  customComponent={<span>{entry?.description && <FiInfo style={{ color: '#aaa' }} />}</span>}
                />
              </td>
              <td style={{ textAlign: 'center' }}>
                <TypeTag type={entry.type} />
              </td>
              <td>
                {isEditing ? (
                  <FieldMappingsEditor fieldName={entry.fieldName} mappings={entry.mappings} recordType={recordType} />
                ) : (
                  <FieldMappingsDisplay
                    ccmName={entry.ccmName}
                    fieldName={entry.fieldName}
                    mappings={entry.mappings}
                    recordType={recordType}
                  />
                )}
              </td>
              <td style={{ textAlign: 'center' }}>
                <Button size="sm" variant="light" onClick={() => showFieldInfoOffcanvas(entry, recordType)}>
                  <MdOutlineBarChart />
                  &nbsp;Statistics
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {modalElement}
    </>
  );
}
