import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { MdOutlineLightbulb, MdLightbulb, MdOutlineVideocam } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { GrHelpBook } from 'react-icons/gr';

function startHHTour(tourId: string) {
  const w = window as any;
  if (w.HelpHero) {
    w.HelpHero.startTour(tourId);
  }
}

const DEFAULT_HELP_URL = 'https://docs.goodfit.io/goodfit-docs';
const PAGES = [
  {
    path: /^\/app\/[^/]+$/,
    helpUrl: null,
    helpTitle: null,
    tourId: 'ysBzuW2FCU'
  },
  {
    path: /^\/app\/[^/]+\/scoring$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/scoring',
    helpTitle: 'Scoring guide',
    tourId: null
  },
  {
    path: /^\/app\/[^/]+\/scoring\/new$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/scoring',
    helpTitle: 'Scoring guide',
    tourId: 'f7VhSsvLT1'
  },
  {
    path: /^\/app\/[^/]+\/scoring\/analysis$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/scoring-analysis',
    helpTitle: 'Scoring Analysis guide',
    tourId: null
  },
  {
    path: /^\/app\/[^/]+\/scoring\/analysis\/new$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/scoring-analysis',
    helpTitle: 'Scoring Analysis guide',
    tourId: 'QVQC7fMHGf'
  },
  {
    path: /^\/app\/[^/]+\/scoring\/analysis\/results/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/scoring-analysis',
    helpTitle: 'Scoring Analysis guide',
    tourId: 'UzYrYfTgbE'
  },
  {
    path: /^\/app\/[^/]+\/talk-tracks$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/talk-tracks',
    helpTitle: 'Tags guide',
    tourId: null
  },
  {
    path: /^\/app\/[^/]+\/tags$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/talk-tracks',
    helpTitle: 'Tags guide',
    tourId: null
  },
  {
    path: /^\/app\/[^/]+\/settings\/connector/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/integrations',
    helpTitle: 'Integrations guide',
    tourId: null
  },
  {
    path: /^\/app\/[^/]+\/exports$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/integrations/csv-export',
    helpTitle: 'CSV export guide',
    tourId: null
  },
  {
    path: /^\/app\/[^/]+\/segments$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/segments',
    helpTitle: 'Segments guide',
    tourId: null
  },
  {
    path: /^\/app\/[^/]+\/segments\/edit\/.*/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/segments',
    helpTitle: 'Segments guide',
    tourId: '5Q5yX563Mo'
  },
  {
    path: /^\/app\/[^/]+\/segments\/view\/[^/#]+$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/segments',
    helpTitle: 'Segments guide',
    tourId: 'I6CQhKiXJH'
  },
  {
    path: /^\/app\/[^/]+\/segments\/view\/[^/#]+#companies$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/segments',
    helpTitle: 'Segments guide',
    tourId: 'I6CQhKiXJH'
  },
  {
    path: /^\/app\/[^/]+\/segments\/view\/[^/#]+#contacts$/,
    helpUrl: 'https://docs.goodfit.io/goodfit-docs/product/segments',
    helpTitle: 'Segments guide',
    tourId: 'ncNsz5uBou'
  }
];

export function FloatingHelpButton() {
  const location = useLocation();
  const path = location.pathname + location.hash;
  console.log(path);
  const w = window as any;

  const page = PAGES.find(p => path.match(p.path));

  const tourId = w.HelpHero ? page?.tourId : undefined;
  const helpUrl = page?.helpUrl;
  const helpTitle = page?.helpTitle;

  const defaultSubButtonStyle = {
    background: '#ea9308',
    height: '40px',
    width: '40px'
  };

  return (
    <>
      <Fab
        icon={page ? <MdLightbulb /> : <MdOutlineLightbulb />}
        alwaysShowTitle={true}
        mainButtonStyles={{
          background: '#ea9308',
          height: '45px',
          width: '45px'
        }}
      >
        <Action
          text="GoodFit documentation"
          onClick={() => window.open(DEFAULT_HELP_URL, '_blank')}
          style={defaultSubButtonStyle}
        >
          <GrHelpBook />
        </Action>
        {tourId && (
          <Action text="Start page tour" onClick={() => startHHTour(tourId)} style={defaultSubButtonStyle}>
            <MdOutlineVideocam />
          </Action>
        )}
        {helpUrl && (
          <Action
            text={helpTitle || 'Page help'}
            onClick={() => window.open(helpUrl, '_blank')}
            style={defaultSubButtonStyle}
          >
            <GrHelpBook />
          </Action>
        )}
      </Fab>
    </>
  );
}
