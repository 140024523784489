import { Button, Card, Col, Row } from 'react-bootstrap';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import PageLoader from 'src/components/PageLoader.tsx';
import { useEffect, useState } from 'react';
import { TechnographicsConfig } from 'src/pages/staff/technographicsConfig/types.ts';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createTechnographicsConfig,
  getTechnographicsConfig,
  optionsForTechnographicsConfigs,
  refreshTechnographicsConfig,
  updateTechnographicsConfig
} from 'src/pages/staff/technographicsConfig/api.ts';
import toast from 'react-hot-toast';
import { KeywordPattern } from 'src/components/KeywordPattern/types.ts';
import { KeywordPatternForm } from 'src/components/KeywordPattern/KeywordPatternForm.tsx';
import { FiPlus } from 'react-icons/fi';
import { SearchAndCreateInput } from 'src/components/inputs/SearchAndCreateInput.tsx';
import { SearchInput } from 'src/components/inputs/SearchInput.tsx';
import { CheckboxInput } from 'src/components/inputs/CheckboxInput.tsx';
import { BasicTextInput } from 'src/components/inputs/BasicTextInput.tsx';
import { TechnographicsPreviewModal } from 'src/pages/staff/technographicsConfig/TechnographicsPreviewModal.tsx';
import { removeEmptyPatterns } from 'src/pages/staff/technographicsConfig/utils.ts';
import { defaultKeywordPattern } from 'src/components/KeywordPattern/defaults.ts';
import LayoutTNG from 'src/layouts/LayoutTNG';

function defaultConfig() {
  return {
    technology: '',
    categories: [],
    isVerified: false,
    builtwithTechnologies: [],
    jobsPatterns: [],
    similartechTechnologies: [],
    domainElements: []
  };
}

export function TechnographicsConfigEditPage({ isNew }: { isNew: boolean }) {
  usePageTitle('Technographics Configs');

  const { technology } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [technographicsConfig, setTechnographicsConfig] = useState<TechnographicsConfig>({} as TechnographicsConfig);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  function save() {
    const toastId = toast.loading('Saving Config...');
    setIsSaving(true);
    technographicsConfig.jobsPatterns = removeEmptyPatterns(technographicsConfig);

    if (isNew) {
      if (technographicsConfig.technology === undefined || technographicsConfig.technology === '') {
        toast.error('Technology is required', { id: toastId });
        setIsLoading(false);
        setIsSaving(false);
        return;
      }

      createTechnographicsConfig(technographicsConfig)
        .catch(e => {
          toast.error(`Error saving config\n${e.message}`, { id: toastId });
          throw e;
        })
        .then(response => refreshTechnographicsConfig(response.item.technology))
        .catch(e => {
          toast.success(`Config was saved, but could not schedule refresh ${e.message}`, { id: toastId });
          navigate(`/admin/technographics-configs`);
          throw e;
        })
        .then(() => navigate(`/admin/technographics-configs`))
        .then(() => {
          toast.success('Config saved & refresh triggered', { id: toastId });
        })
        .catch(e => {
          toast.error(`Error saving config\n${e.message}`, { id: toastId });
        })
        .finally(() => setIsSaving(false));
    } else {
      updateTechnographicsConfig(technology as string, technographicsConfig)
        .catch(e => {
          toast.error(`Error saving config\n${e.message}`, { id: toastId });
          throw e;
        })
        .then(response => refreshTechnographicsConfig(response.item.technology))
        .catch(e => {
          toast.success(`Config was saved, but could not schedule refresh ${e.message}`, { id: toastId });
          navigate(`/admin/technographics-configs`);
          throw e;
        })
        .then(() => navigate(`/admin/technographics-configs`))
        .then(() => {
          toast.success('Config saved & refresh triggered', { id: toastId });
        })
        .finally(() => setIsSaving(false));
    }
  }

  const loadOptions = (field: string) => async (inputValue: string) => {
    const { options } = await optionsForTechnographicsConfigs(field, inputValue);
    return options;
  };

  useEffect(() => {
    if (isNew) {
      setTechnographicsConfig(defaultConfig());
      setIsLoading(false);
    }
    if (!isNew && technology) {
      getTechnographicsConfig(technology).then(response => {
        setTechnographicsConfig(response.item);
        setIsLoading(false);
      });
    }
  }, [isNew, technology]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <LayoutTNG
      preTitle={''}
      title={<>Technographics Configs </>}
      titleControls={
        <div className={'d-flex gap-2 justify-content-start'}>
          <Button size={'sm'} variant={'secondary'} disabled={isSaving} onClick={() => setShowPreview(true)}>
            Preview
          </Button>
          <Button variant="primary" size="sm" disabled={isSaving} onClick={() => save()}>
            Save
          </Button>
        </div>
      }
    >
      <Card>
        <Card.Header>Technographic Config</Card.Header>
        <Card.Body>
          <Row className={'d-flex gap-3'}>
            <BasicTextInput
              title={'Technology'}
              disabled={!isNew}
              value={technographicsConfig.technology}
              onUpdate={value => setTechnographicsConfig({ ...technographicsConfig, technology: value })}
            />
            <Row className={'d-flex'}>
              <SearchAndCreateInput
                title={'Categories'}
                loadOptions={loadOptions('categories')}
                value={technographicsConfig.categories || []}
                onUpdate={(categories: string[]) => setTechnographicsConfig({ ...technographicsConfig, categories })}
              />
            </Row>
            <Row className={'d-flex'}>
              <Col xs={'auto'} className={'d-flex gap-2'}>
                <CheckboxInput
                  title={'Is Verified'}
                  value={technographicsConfig.isVerified}
                  onUpdate={value =>
                    setTechnographicsConfig({
                      ...technographicsConfig,
                      isVerified: value
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <SearchInput
                title={'Builtwith'}
                loadOptions={loadOptions('builtwith')}
                value={technographicsConfig.builtwithTechnologies || []}
                onUpdate={(builtwithTechnologies: string[]) =>
                  setTechnographicsConfig({ ...technographicsConfig, builtwithTechnologies })
                }
              />
            </Row>
            <Row>
              <SearchInput
                title={'Similartech'}
                loadOptions={loadOptions('similartech')}
                value={technographicsConfig.similartechTechnologies}
                onUpdate={(similartechTechnologies: string[]) =>
                  setTechnographicsConfig({ ...technographicsConfig, similartechTechnologies })
                }
              />
            </Row>
            <Row>
              <SearchInput
                title={'Domain elements'}
                loadOptions={loadOptions('domainElements')}
                value={technographicsConfig.domainElements}
                onUpdate={(domainElements: string[]) =>
                  setTechnographicsConfig({ ...technographicsConfig, domainElements })
                }
              />
            </Row>
            <Row>
              <div className={'d-flex justify-content-between w-100 my-2 pe-0'}>
                <div>
                  <strong>Jobs Patterns</strong>
                </div>
                <div>
                  <Button
                    size={'sm'}
                    variant={'outline-primary'}
                    onClick={() => {
                      setTechnographicsConfig(prevState => ({
                        ...prevState,
                        jobsPatterns: [...prevState.jobsPatterns, defaultKeywordPattern()]
                      }));
                    }}
                  >
                    Add pattern
                    <FiPlus />
                  </Button>
                </div>
              </div>
              {technographicsConfig.jobsPatterns?.length === 0 && (
                <Card className={'mb-2 p-2'}>
                  <Card.Body>
                    <div className={'text-muted text-center'}>No jobs patterns defined</div>
                  </Card.Body>
                </Card>
              )}
              {technographicsConfig.jobsPatterns?.map((row: any, index: number) => (
                <Card className={'m-2 p-2'} key={`job-patterns-card-${index}`}>
                  <KeywordPatternForm
                    showKeyword={false}
                    key={index}
                    keywordPattern={row}
                    update={(newRow: KeywordPattern) => {
                      setTechnographicsConfig({
                        ...technographicsConfig,
                        jobsPatterns: technographicsConfig.jobsPatterns.map((r, i) => (i === index ? newRow : r))
                      });
                    }}
                    deletePattern={() => {
                      setTechnographicsConfig({
                        ...technographicsConfig,
                        jobsPatterns: technographicsConfig.jobsPatterns.filter((_, i) => i !== index)
                      });
                    }}
                  />
                </Card>
              ))}
            </Row>
          </Row>
        </Card.Body>
      </Card>
      {showPreview && (
        <TechnographicsPreviewModal
          show={showPreview}
          technographicsConfig={technographicsConfig}
          onHide={() => setShowPreview(false)}
        />
      )}
    </LayoutTNG>
  );
}
