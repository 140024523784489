import { Button, Card, Table } from 'react-bootstrap';
import WideLayout from 'src/layouts/Wide';
import ClientSelect from 'src/components/staff/ClientSelect';
import { useEffect, useState } from 'react';
import { ClientConfig } from 'src/apis/clients/types';
import { getClientConfig } from 'src/apis/clients/apis';
import { TableLoading } from 'react-bootstrap-table-loading';
import EmptyMessage from 'src/components/EmptyMessage';
import { usePageTitle } from 'src/utils/usePageTitle';
import { CSVBoxButton } from '@csvbox/react';
import toast from 'react-hot-toast';
import { useAuth } from 'src/auth';
import BatchTaskList from 'src/components/staff/BatchTasksList';
import { triggerUpload } from 'src/pages/staff/customData/api.ts';

export default function CustomDataFieldsPage() {
  const [clientName, setClientName] = useState<string | null>(null);

  usePageTitle('Custom Data');

  return (
    <WideLayout title={'Custom Data'}>
      <Card>
        <Card.Header>Previous custom data upload tasks</Card.Header>
        <Card.Body>
          <BatchTaskList taskType="CUSTOM_DATA_UPLOAD" />
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Upload custom data</Card.Header>
        <Card.Body>
          <ClientSelect setClientName={setClientName} />
        </Card.Body>
        {clientName && <CustomDataFieldsEditor clientName={clientName} />}
      </Card>
    </WideLayout>
  );
}

function CustomDataFieldsEditor({ clientName }: { clientName: string }) {
  const auth = useAuth();
  const [clientConfig, setClientConfig] = useState<ClientConfig | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (clientName) {
      setIsLoading(true);
      getClientConfig(clientName).then(data => {
        setClientConfig(data.config);
        console.log(data);
        setIsLoading(false);
      });
    }
  }, [clientName]);

  const customDataFields = clientConfig?.customDataFields ?? [];
  const uploadableCustomDataFields = customDataFields.filter((field: any) => !!field.fromField); // Remove fields without fromField as they are expression ones
  const isEmpty = customDataFields.length === 0;
  const uploadName = `${clientName}-custom-data-${new Date().toISOString()}`;

  return (
    <>
      <Card.Body>
        <Table size="sm">
          <thead>
            <tr>
              <th>Field</th>
              <th>Display Label</th>
              <th>Data Type</th>
              <th>Field Type</th>
              <th>Description</th>
              <th>From Field / Expression</th>
            </tr>
          </thead>
          {isLoading && <TableLoading columns={5} lines={3} />}
          {!isLoading && isEmpty && (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <EmptyMessage message="No custom fields defined for this client" />
                </td>
              </tr>
            </tbody>
          )}
          {!isLoading && !isEmpty && (
            <tbody>
              {customDataFields.map((field: any) => (
                <tr key={field.name}>
                  <td>{field.name}</td>
                  <td>{field.displayName}</td>
                  <td>{field.type}</td>
                  <td>{field.expressionSql ? 'Expression' : 'Upload'}</td>
                  <td>{field.description}</td>
                  <td>{field.fromField ? field.fromField : <pre>{field.expressionSql}</pre>}</td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-start">
        <div>
          {!isLoading && (
            <CSVBoxButton
              key={clientName}
              licenseKey="K9jFrp0KdmhJxsem1g9XEsJ3kSZPs0"
              user={{
                user_id: auth.user?.id,
                clientName,
                uploadName
              }}
              dynamicColumns={uploadableCustomDataFields.map((field: any) => {
                const column: any = {
                  column_name: field.fromField,
                  type: goodfitTypeToCSVBoxType(field.type)
                };
                if (field.type === 'Date') {
                  column.validators = { format: ['YYYY-MM-DD'] };
                }
                if (field.type === 'Array' || field.type === 'Multipicklist') {
                  // Make sure its a JSON list of strings
                  column.validators = { expression: '\\[("[^"]*",?)*\\]' };
                }
                return column;
              })}
              onImport={(result, data) => {
                if (result) {
                  toast.promise(triggerUploadJob(clientName, data), {
                    loading: 'Starting custom data import job',
                    success: 'Custom data import job started',
                    error: 'Error starting custom data import job'
                  });
                } else {
                  alert('Sorry, CSVbox could not upload your file. ');
                }
              }}
              render={(launch, isLoading) => {
                return (
                  <Button
                    size="sm"
                    disabled={isLoading || uploadableCustomDataFields.length === 0}
                    variant="secondary"
                    onClick={launch}
                  >
                    Upload Custom Data
                  </Button>
                );
              }}
            />
          )}
        </div>
      </Card.Footer>
    </>
  );
}

async function triggerUploadJob(clientName: string, data: any) {
  await triggerUpload({ clientName, filePath: data.raw_file });
}

function goodfitTypeToCSVBoxType(type: string) {
  if (type === 'String') return 'text';
  if (type === 'Number' || type === 'Percent' || type === 'Currency') return 'number';
  if (type === 'Date') return 'date';
  if (type === 'Boolean') return 'boolean';
  if (type === 'Multipicklist' || type === 'Array') return 'regex';
  return 'text';
}
