import { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { loadScoringAnalysis } from 'src/apis/scoring/apis';
import PageLoader from 'src/components/PageLoader';
import { Debouncer } from 'src/utils/Debouncer';
import { interpolateScoringColour } from './colors';
import last from 'lodash/last';
import first from 'lodash/first';

const previewDebouncer = new Debouncer(1000);
const nbsp = ' ';
const space = nbsp.repeat(100);

function valueLabel(v: any) {
  if (v.rangeMin === v.rangeMax) return `${v.rangeMin}`;
  return `${v.rangeMin} to ${v.rangeMax}`;
}

export default function ScoringAnalyze({ rules, setSelectedRange }: any) {
  const [values, setValues] = useState<any[] | null>(null);
  const [hasEverLoaded, setHasEverLoaded] = useState(false);

  const loadPreview = useCallback(() => {
    loadScoringAnalysis(rules).then(results => {
      setValues(results.values);
    });
    setHasEverLoaded(true);
  }, [rules]);

  useEffect(() => {
    if (hasEverLoaded) {
      previewDebouncer.schedule(() => loadPreview());
    } else {
      previewDebouncer.immediate(() => loadPreview());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules]);

  if (values === null) return <PageLoader style={{ height: 350 }} fullHeight={false} />;

  const min = first(values).rangeMin;
  const max = last(values).rangeMax;

  const data = values.map(v => v.count);
  const labels = values.map(valueLabel);

  const state = {
    series: [{ data }],
    options: {
      chart: {
        events: {
          dataPointSelection(_event: any, _chartContext: any, config: { dataPointIndex: number }) {
            const info = values[config.dataPointIndex];
            setSelectedRange([info.rangeMin, info.rangeMax]);
          },
          dataPointMouseEnter: function (event: any) {
            event.target.style.cursor = 'pointer';
          }
        }
      },
      xaxis: {
        categories: labels,
        title: {
          text: `Worst fit ${space} Score range ${space} Best fit`
        }
      },
      yaxis: {
        title: {
          text: '# Companies'
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
          const data = series[seriesIndex];
          const x = w.config.xaxis.categories[dataPointIndex];
          const value = data[dataPointIndex];
          const info = values[dataPointIndex];
          const imgs = info.sampleDomains.map(
            (d: string) =>
              `<span style="margin-right: 2px;background-color: white; background-size: contain; background-position: center center; background-image: url(https://logo.clearbit.com/${d}); display: block; float:left; width:32px;height:32px;border-radius: 16px; border: 1px solid #ccc;"></span>`
          );
          return `<div style="padding: 10px;"><div>Score range <b>${x}</b></div><div style="margin-bottom:4px">#Companies <b>${value}</b></div><div>${imgs.join('')}</div></div>`;
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '60%'
        }
      },
      colors: [
        function ({ dataPointIndex }: any) {
          const info = values[dataPointIndex];
          const mid = (info.rangeMax + info.rangeMin) / 2;
          let x = 0;
          if (mid < 0) {
            x = -(mid / min);
          }
          if (mid > 0) {
            x = mid / max;
          }
          return interpolateScoringColour(x);
        }
      ]
    }
  };

  return (
    <>
      <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
    </>
  );
}
