import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PageElementConfig, PageElementDetectionRule } from 'src/pages/staff/pageElements/types.ts';
import toast from 'react-hot-toast';
import PageLoader from 'src/components/PageLoader.tsx';
import {
  createPageElementConfig,
  getPageElementConfig,
  optionsForPageElementConfigs,
  updatePageElementConfig
} from 'src/pages/staff/pageElements/api.ts';
import { Button, Card, Dropdown, InputGroup, Row } from 'react-bootstrap';
import { BasicTextInput } from 'src/components/inputs/BasicTextInput.tsx';
import { CheckboxInput } from 'src/components/inputs/CheckboxInput.tsx';
import { FiPlus } from 'react-icons/fi';
import { DetectionRuleForm } from 'src/pages/staff/pageElements/components/DetectionRuleForm.tsx';
import { SearchAndCreateInput } from 'src/components/inputs/SearchAndCreateInput.tsx';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { DEFAULT_THEME } from 'src/utils/react-select.ts';
import LayoutTNG from 'src/layouts/LayoutTNG';
import { PreviewModal } from 'src/pages/staff/pageElements/components/PreviewModal.tsx';

function defaultConfig() {
  return {
    id: '',
    name: '',
    type: '',
    tags: [],
    returnNameAsValue: true,
    useLowercase: false,
    detectionRules: []
  } as PageElementConfig;
}

function defaultRule() {
  return {
    pageTypes: [],
    source: 'text',
    regex: '//gi',
    functionName: ''
  } as PageElementDetectionRule;
}

export function PageElementConfigEditPage({ isNew }: { isNew: boolean }) {
  usePageTitle('Page Element Config');
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<{ show: boolean; pageElementConfig?: PageElementConfig }>({
    show: false,
    pageElementConfig: undefined
  });

  const [pageElementConfig, setPageElementConfig] = useState<PageElementConfig>({} as PageElementConfig);
  const [typeOptions, setTypeOptions] = useState<string[]>();
  const [pageTypeOptions, setPageTypeOptions] = useState<string[]>();

  function save() {
    const toastId = toast.loading('Saving Config...');
    setIsSaving(true);

    if (isNew) {
      createPageElementConfig(pageElementConfig)
        .catch(e => {
          toast.error(`Error saving config\n${e.message}`, { id: toastId });
          throw e;
        })
        .then(data => {
          toast.success('Config saved', { id: toastId });
          navigate(`/admin/page-element-configs/${data.item.id}`);
        })
        .catch(e => {
          toast.error(`Error saving config\n${e.message}`, { id: toastId });
        })
        .finally(() => setIsSaving(false));
    } else {
      updatePageElementConfig(id as string, pageElementConfig)
        .catch(e => {
          toast.error(`Error saving config\n${e.message}`, { id: toastId });
          throw e;
        })
        .then(() => {
          toast.success('Config saved', { id: toastId });
        })
        .finally(() => setIsSaving(false));
    }
  }

  const loadOptions = (field: string) => async (inputValue: string) => {
    const { options } = await optionsForPageElementConfigs(field, inputValue);
    return options;
  };

  useEffect(() => {
    loadOptions('type')('').then(setTypeOptions);
    loadOptions('detectionRulesPageType')('').then(setPageTypeOptions);
  }, []);

  useEffect(() => {
    if (isNew) {
      setPageElementConfig(defaultConfig());
      setIsLoading(false);
    }
    if (!isNew && id) {
      getPageElementConfig(id).then(response => {
        setPageElementConfig(response.item);
        setIsLoading(false);
      });
    }
  }, [isNew, id]);

  if (isLoading || !typeOptions || !pageTypeOptions) {
    return <PageLoader />;
  }

  return (
    <LayoutTNG
      preTitle={'Page elements'}
      title={<>Page Element Config</>}
      titleControls={
        <div className={'d-flex gap-2 justify-content-start'}>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" size="sm" disabled={isSaving}>
              Options
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setShowPreview({ show: true, pageElementConfig: pageElementConfig })}>
                Preview
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/admin/page-element-refresh">
                Parse Pages
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button variant="primary" size="sm" disabled={isSaving} onClick={() => save()}>
            Save
          </Button>
        </div>
      }
    >
      <Card>
        <Card.Header>Page Element Config</Card.Header>
        <Card.Body>
          <div className={'d-flex gap-3 flex-column'}>
            <BasicTextInput
              title={'Name'}
              value={pageElementConfig.name}
              disabled={!isNew}
              onUpdate={value => setPageElementConfig({ ...pageElementConfig, name: value })}
            />
            {(isNew && (
              <InputGroup className={'input-group'} size={'sm'}>
                <InputGroup.Text className="input-text-header">Type</InputGroup.Text>
                <AsyncCreatableSelect
                  className={'input-select'}
                  isDisabled={!isNew}
                  cacheOptions
                  loadOptions={async prefix =>
                    typeOptions.filter(option => option.startsWith(prefix)).map(i => ({ label: i, value: i }))
                  }
                  value={{ label: pageElementConfig.type || '', value: pageElementConfig.type || '' }}
                  defaultOptions
                  onChange={(selectedOption: any) => {
                    setPageElementConfig({ ...pageElementConfig, type: selectedOption.value });
                  }}
                  theme={DEFAULT_THEME}
                />
              </InputGroup>
            )) || (
              <BasicTextInput
                title={'Type'}
                value={pageElementConfig.type}
                disabled={true}
                onUpdate={value => setPageElementConfig({ ...pageElementConfig, type: value })}
              />
            )}
            <SearchAndCreateInput
              title={'Tags'}
              loadOptions={loadOptions('tags')}
              value={pageElementConfig.tags}
              onUpdate={value => setPageElementConfig({ ...pageElementConfig, tags: value })}
            />
            <CheckboxInput
              title={'Use name as value'}
              value={pageElementConfig.returnNameAsValue}
              onUpdate={value =>
                setPageElementConfig({
                  ...pageElementConfig,
                  returnNameAsValue: value,
                  useLowercase: value ? false : pageElementConfig.useLowercase
                })
              }
            />
            <CheckboxInput
              title={'Lowercase'}
              value={pageElementConfig.useLowercase}
              disabled={pageElementConfig.returnNameAsValue}
              onUpdate={value => setPageElementConfig({ ...pageElementConfig, useLowercase: value })}
            />

            <Row>
              <div className={'d-flex justify-content-between w-100 my-2 pe-0'}>
                <div>
                  <strong>Detection rules</strong>
                </div>
                <div>
                  <Button
                    size={'sm'}
                    variant={'outline-primary'}
                    onClick={() => {
                      setPageElementConfig(prevState => ({
                        ...prevState,
                        detectionRules: [...prevState.detectionRules, defaultRule()]
                      }));
                    }}
                  >
                    Add rule
                    <FiPlus />
                  </Button>
                </div>
              </div>
              {pageElementConfig.detectionRules?.length === 0 && (
                <Card className={'mb-2 p-2'}>
                  <Card.Body>
                    <div className={'text-muted text-center'}>No detection rules defined</div>
                  </Card.Body>
                </Card>
              )}
              {pageElementConfig.detectionRules?.map((row: any, index: number) => (
                <Card className={'m-2 p-2'} key={`detection-rules-card-${index}`}>
                  <DetectionRuleForm
                    key={index}
                    rule={row}
                    pageTypesOptions={pageTypeOptions}
                    update={(newRow: PageElementDetectionRule) => {
                      setPageElementConfig({
                        ...pageElementConfig,
                        detectionRules: pageElementConfig.detectionRules.map((r, i) => (i === index ? newRow : r))
                      });
                    }}
                    deleteRule={() => {
                      setPageElementConfig({
                        ...pageElementConfig,
                        detectionRules: pageElementConfig.detectionRules.filter((_, i) => i !== index)
                      });
                    }}
                    onPreview={() => {
                      const singleDetectionRuleConfig = { ...pageElementConfig, detectionRules: [row] };
                      setShowPreview({ show: true, pageElementConfig: singleDetectionRuleConfig });
                    }}
                  />
                </Card>
              ))}
            </Row>
          </div>
        </Card.Body>
      </Card>
      {showPreview.show && (
        <PreviewModal
          show={showPreview.show}
          pageElementConfig={showPreview.pageElementConfig as PageElementConfig}
          onHide={() => setShowPreview({ show: false, pageElementConfig: undefined })}
        />
      )}
    </LayoutTNG>
  );
}
