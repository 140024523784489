import { Button, ListGroup } from 'react-bootstrap';
import TalkTrackPlaceholder from './TalkTrackPlaceholder';
import TalkTrackItem from './TalkTrackItem';
import { TalkTrack } from '../types';
import { useNavigate } from 'react-router-dom';
import { FiTag } from 'react-icons/fi';
import EmptyMessage from 'src/components/EmptyMessage.tsx';
import { appClientUrl } from 'src/utils/urls.ts';

export default function TalkTrackList({ talkTracks, isLoading, handleDelete, handleRename }: any) {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <ListGroup className="list-group-flush my-n3">
        <TalkTrackPlaceholder />
        <TalkTrackPlaceholder />
        <TalkTrackPlaceholder />
      </ListGroup>
    );
  }

  if (talkTracks.length === 0) {
    return (
      <EmptyMessage
        message="You don't have any tag fields"
        subMessage={
          <>
            Find out about how tags work:{' '}
            <a href="https://docs.goodfit.io/goodfit-docs/product/talk-tracks" target="_blank">
              Tags documentation
            </a>
          </>
        }
        icon={<FiTag />}
        actions={
          <Button size="sm" variant="primary" onClick={() => navigate(appClientUrl(``))}>
            Create your first tag field
          </Button>
        }
      />
    );
  }

  return (
    <ListGroup className="list-group-flush my-n3">
      {talkTracks.map((tt: TalkTrack) => (
        <TalkTrackItem
          key={tt.id}
          talkTrack={tt}
          onDelete={() => handleDelete(tt.id)}
          onRename={newName => handleRename(tt.id, newName)}
        />
      ))}
    </ListGroup>
  );
}
