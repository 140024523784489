import { ClientConfig, CompanyCriteriaFilter, DataBlockDefinition } from 'src/apis/clients/types.ts';
import { Button, Table } from 'react-bootstrap';
import { FiTrash2 } from 'react-icons/fi';
import { useCallback, useEffect, useState } from 'react';

import { applyToSourcingCriteriaLeafs } from 'src/pages/staff/clientConfigs/utils.ts';
import compact from 'lodash/compact';

export function DataBlockConfigsTable({
  isEditing,
  clientConfig,
  removeBlock,
  handleOpenBlock
}: {
  isEditing: boolean;
  clientConfig: ClientConfig;
  removeBlock: (index: number) => void;
  handleOpenBlock: (block: DataBlockDefinition & { index: number }) => void;
}) {
  const [dataBlocksUsed, setDataBlocksUsed] = useState<string[]>([]);

  useEffect(() => {
    const used = [];
    if (clientConfig.sourcingCriteria) {
      const usedBlocks = applyToSourcingCriteriaLeafs(
        clientConfig.sourcingCriteria,
        (criteria: CompanyCriteriaFilter) => criteria.configId
      );
      used.push(...compact(usedBlocks));
    }
    if (clientConfig.fieldDefinitions) {
      const usedBlocks = clientConfig.fieldDefinitions.map(field => field.configId);
      used.push(...compact(usedBlocks));
    }
    setDataBlocksUsed(used);
  }, [clientConfig.fieldDefinitions, clientConfig.sourcingCriteria]);

  const canRemoveBlock = useCallback((block: any) => !dataBlocksUsed.includes(block.configId), [dataBlocksUsed]);

  return (
    <Table bordered size="sm" style={{ backgroundColor: 'white', margin: 0 }}>
      <thead>
        <tr>
          <th>Data Block</th>
          <th>Config ID</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {clientConfig.dataBlockConfigs.map((block: any, index: number) => (
          <tr key={`data_block_configs_table_${index}`}>
            <td className={'p-2'}>{block.blockName}</td>
            <td className={'p-2'}>{block.configId}</td>
            <td className={'d-flex justify-content-center p-2'}>
              <Button
                size={'sm'}
                className={'py-0 mx-1'}
                onClick={() => {
                  handleOpenBlock({ ...block, index });
                }}
              >
                {isEditing ? 'Edit' : 'View'}
              </Button>
              {isEditing && (
                <Button
                  size={'sm'}
                  variant={'outline-danger'}
                  className={'py-0 mx-1'}
                  disabled={!canRemoveBlock(block)}
                  onClick={() => removeBlock(index)}
                >
                  <FiTrash2 color={'danger'} size={'1rem'} className={'m-1'} />
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
