import { useEffect, useMemo, useState } from 'react';
import { Form, Fade, Collapse } from 'react-bootstrap';
import { useClient } from 'src/auth';

export function CheckToRevealLimitOptions({ name, label, initialValue, onChange }: any) {
  const { companiesSchema } = useClient();

  const [checked, setChecked] = useState(!!initialValue?.limit);
  const [changed, setChanged] = useState(false);
  const [limitValue, setLimitValue] = useState(initialValue?.limit ?? 100);
  const [orderByValue, setOrderByValue] = useState(initialValue?.orderBy ?? '');
  const [orderDirectionValue, setOrderDirectionValue] = useState(initialValue?.orderDirection ?? 'DESC');

  const schema = useMemo(() => companiesSchema.filter(f => !f.isHiddenFromUser), [companiesSchema]);

  useEffect(() => {
    if (changed && onChange) {
      onChange(
        checked
          ? {
              limit: limitValue ?? null,
              orderBy: orderByValue || null,
              orderDirection: orderDirectionValue || null
            }
          : {}
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, limitValue, orderByValue, orderDirectionValue, changed]);

  return (
    <div>
      <Form.Check
        type="checkbox"
        checked={checked}
        onChange={ev => {
          setChecked(ev.target.checked);
          setChanged(true);
        }}
        name={name}
        id={name}
        label={label}
      />
      <Fade in={checked} timeout={300} unmountOnExit={false}>
        <Collapse in={checked}>
          <div className="ps-4 py-3">
            <div className="d-flex flex-row align-items-center justify-content-start" style={{ gap: 8 }}>
              Limit to
              <Form.Control
                size="sm"
                style={{ width: 100 }}
                value={limitValue}
                type="number"
                onChange={ev => {
                  setLimitValue(parseInt(ev.target.value));
                  setChanged(true);
                }}
              />
              companies ordered by
              <Form.Select
                size="sm"
                value={orderByValue}
                onChange={ev => {
                  setOrderByValue(ev.target.value);
                  setChanged(true);
                }}
                style={{ width: 200 }}
              >
                <option value="">Random</option>
                {schema.map(field => (
                  <option key={field.fieldName} value={field.fieldName}>
                    {field.displayName}
                  </option>
                ))}
              </Form.Select>
              {orderByValue && (
                <Form.Select
                  size="sm"
                  value={orderDirectionValue}
                  onChange={ev => {
                    setOrderDirectionValue(ev.target.value);
                    setChanged(true);
                  }}
                  style={{ width: 200 }}
                >
                  <option value="DESC">Descending</option>
                  <option value="ASC">Ascending</option>
                </Form.Select>
              )}
            </div>
          </div>
        </Collapse>
      </Fade>
    </div>
  );
}
