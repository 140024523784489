export type FieldMapping = {
  displayName: string;
  name: string;
  type: string;
  group: string;
  dataBlockName: string;
  dataBlockField: string;
  configId?: string;
  dataBlockFieldType: DataBlockFieldType;
};

export enum DataBlockFieldType {
  DynamicDataBlocks = 'DynamicDataBlocks',
  BasicDataBlocks = 'BasicDataBlocks'
}
