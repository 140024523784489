import { Fragment } from 'react';
import Tag from 'src/components/Tag';
import { DataBlockFieldDefinition } from 'src/apis/clients/types.ts';
import { CompanySchemaField } from 'src/auth';
import isArray from 'lodash/isArray';

const divStyle = {
  border: '1px solid #eee',
  padding: '3px 1px 3px 5px',
  display: 'inline-block',
  marginInlineEnd: 8,
  verticalAlign: 'top',
  maxWidth: '95%',
  minWidth: '75%',
  margin: '2px',
  fontSize: '14px'
};

const OPERATOR_NAMES = {
  notIn: 'not in',
  '~*': 'matches regex',
  '~': 'matches regex',
  stringBeginsWith: 'begins with',
  stringEndsWith: 'ends with',
  stringContains: 'contains string',
  listContains: 'contains item',
  null: 'is empty',
  notNull: 'is not empty',

  arrayContainsAnyOf: 'contains any of',
  arrayNotContainsAnyOf: 'not contains any of',
  matchesAnyText: 'matches any text',
  notMatchesAnyText: 'not matches any text'
} as any;

type SourcingCriteriaDisplayProps = {
  sourcingCriteria: any;
  companiesSchema: any[];
  ccmDataBlocksFields: DataBlockFieldDefinition[];
};

export default function CompanySourcingCriteria({
  sourcingCriteria,
  companiesSchema,
  ccmDataBlocksFields
}: SourcingCriteriaDisplayProps) {
  return (
    <>
      {sourcingCriteria && (
        <RuleGroup
          ruleGroup={sourcingCriteria}
          companiesSchema={companiesSchema}
          isTopLevel={true}
          ccmDataBlocksFields={ccmDataBlocksFields}
        ></RuleGroup>
      )}
      {!sourcingCriteria && 'No company criteria is currently set.'}
    </>
  );
}

// Convert to float only if possible (e.g. not date string etc)
function safeParseFloat(v: string) {
  const num = parseFloat(v);
  return isNaN(num) ? v : num;
}

// Split on the common term divisor patterns
function cleanUpRegex(pattern: string) {
  const stripped = pattern.replace(/(;|$)/g, '').replace(/\b/g, '').replace(/(;|^)/g, '');
  return stripped.split('|');
}

function RuleGroup({ ruleGroup, companiesSchema, ccmDataBlocksFields, isTopLevel }: any) {
  const combinator = ruleGroup.combinator || 'and';
  const parts = [];

  for (let index = 0; index < ruleGroup.rules.length; index++) {
    const rule = ruleGroup.rules[index];
    const hasMoreElements = index < ruleGroup.rules.length - 1;
    let part = null;

    if (rule.rules) {
      part = (
        <RuleGroup
          ruleGroup={rule}
          companiesSchema={companiesSchema}
          ccmDataBlocksFields={ccmDataBlocksFields}
        ></RuleGroup>
      );
    } else if (rule.field) {
      part = <Rule field={rule} companiesSchema={companiesSchema} ccmDataBlocksFields={ccmDataBlocksFields}></Rule>;
    }

    if (part) {
      parts.push(
        <Fragment key={`c:${index}`}>
          {part}
          <br />
          {hasMoreElements && (
            <Tag color="info" style={{ marginTop: 8 }}>
              {combinator.toUpperCase()}
            </Tag>
          )}
        </Fragment>
      );
    }
  }

  if (isTopLevel) {
    return <>{parts}</>;
  } else {
    return <div style={divStyle}>{parts}</div>;
  }
}

function Rule({ field, companiesSchema, ccmDataBlocksFields }: any) {
  const fieldDefinition = companiesSchema.find((fd: CompanySchemaField) => {
    return (
      (fd.configId && fd.configId === field.configId && fd.ccmName === field.field) ||
      (!fd.configId && fd.ccmName === field.field)
    );
  });
  const type = ccmDataBlocksFields.find((fd: DataBlockFieldDefinition) => fd.internalName === field.field).type;

  let label = field.field;
  if (fieldDefinition) {
    label = fieldDefinition.displayName;
  } else if (field.configId) {
    label = `${field.field} (${field.configId})`;
  }

  let values: any[] = isArray(field.value) ? field.value.sort() : [field.value];
  if (field.operator === '~*' || field.operator === '~') {
    values = cleanUpRegex(values[0]);
  }
  if (field.operator === 'between') {
    values = values.map(safeParseFloat).sort();
  }
  if (type === 'Percentage') {
    values = values.map((v: any) => `${(v * 100).toPrecision(2)}%`);
  }
  if (type === 'Boolean') {
    values = values.map((v: any) => (v === true ? 'True' : 'False'));
  }

  return (
    <div style={divStyle} className="valign-middle">
      <Tag color="primary" style={{ margin: '0px 5px 0px 5px' }}>
        {label}
      </Tag>
      &nbsp;{OPERATOR_NAMES[field.operator] || field.operator}&nbsp;
      {field.operator === 'between' && (
        <>
          <Tag color="warning">{JSON.stringify(values[0])}</Tag>
          and
          <Tag color="warning">{JSON.stringify(values[1])}</Tag>
        </>
      )}
      {field.operator !== 'between' &&
        values.map((v: any, idx: number) => (
          <Tag key={`v:${idx}`} color="warning">
            {v}
          </Tag>
        ))}
    </div>
  );
}
