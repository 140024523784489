import { ReactNode } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from 'src/components/Header';

interface WideLayoutProps {
  title: string | ReactNode;
  preheader?: string;
  titleControls?: JSX.Element;
  children: React.ReactNode;
  maxWidthCols: number;
}

export default function WideLayout({ title, preheader, children, titleControls, maxWidthCols }: WideLayoutProps) {
  if (maxWidthCols == 12) {
    return (
      <div className="main-content">
        <Header className="mt-md-5">
          {titleControls && <div className="float-end">{titleControls}</div>}
          {preheader && <Header.Pretitle>{preheader}</Header.Pretitle>}
          <Header.Title className="text-truncate">{title}</Header.Title>
        </Header>
        {children}
      </div>
    );
  }

  return (
    <div className="main-content">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={maxWidthCols}>
            <Header className="mt-md-5">
              {titleControls && <div className="float-end">{titleControls}</div>}
              {preheader && <Header.Pretitle>{preheader}</Header.Pretitle>}
              <Header.Title className="text-truncate">{title}</Header.Title>
            </Header>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

WideLayout.defaultProps = {
  maxWidthCols: 12
};
