import { Integration } from 'src/apis/integrations';
import { DataDirectoryStore } from './../state';
import { IntegrationFieldMappingInput, RecordType } from './../types';
import { fetchFieldStats } from 'src/apis/data/apis';
import { SchemaDataType } from 'src/auth';

export const getCreateFieldName = (
  state: DataDirectoryStore,
  recordType: RecordType,
  mapping: IntegrationFieldMappingInput
) => {
  const entry = [...state.dataDirectoryCompanyFields, ...state.dataDirectoryContactFields].find(
    field => field.fieldName === mapping.internalName
  );
  if (!entry) return null;

  if (entry?.presentInCCM && recordType === 'company' && state.companiesSchema) {
    const field = state.companiesSchema.find(f => f.fieldName === mapping.internalName);
    if (field?.dataBlockField?.crmName) return field?.dataBlockField?.crmName;
  }

  if (entry.isSystem) {
    return entry.displayName;
  }

  return `${entry.displayName} (GoodFit)`;
};

const DEFAULT_TYPE_MAPPING_SALESFORCE: Record<SchemaDataType, string> = {
  [SchemaDataType.Array]: 'multipicklist',
  [SchemaDataType.Boolean]: 'boolean',
  [SchemaDataType.Currency]: 'currency',
  [SchemaDataType.Date]: 'date',
  [SchemaDataType.Multipicklist]: 'multipicklist',
  [SchemaDataType.Number]: 'float',
  [SchemaDataType.Percentage]: 'percentage',
  [SchemaDataType.Picklist]: 'picklist',
  [SchemaDataType.String]: 'string'
};

const DEFAULT_TYPE_MAPPING_HUBSPOT: Record<SchemaDataType, string> = {
  [SchemaDataType.Array]: 'multipicklist',
  [SchemaDataType.Boolean]: 'boolean',
  [SchemaDataType.Currency]: 'currency',
  [SchemaDataType.Date]: 'date',
  [SchemaDataType.Multipicklist]: 'multipicklist',
  [SchemaDataType.Number]: 'number',
  [SchemaDataType.Percentage]: 'number',
  [SchemaDataType.Picklist]: 'picklist',
  [SchemaDataType.String]: 'string'
};

export const getFieldType = (
  recordType: string,
  fieldName: string,
  integration: Integration,
  state: DataDirectoryStore
) => {
  const dataDirectoryFields =
    recordType === 'company' ? state.dataDirectoryCompanyFields : state.dataDirectoryContactFields;
  const entry = dataDirectoryFields.find(field => field.fieldName === fieldName);
  if (!entry) return null;

  // If the field is in CCM and has a dataBlockField property, use that type
  if (entry?.presentInCCM && recordType === 'company' && state.companiesSchema) {
    const field = state.companiesSchema.find(f => f.fieldName === fieldName);
    if (integration.integrationType === 'HUBSPOT') return field?.dataBlockField?.hubspotType;
    if (integration.integrationType === 'SALESFORCE') return field?.dataBlockField?.salesforceType;
  }

  // Otherwise look up from above
  if (integration.integrationType === 'HUBSPOT') {
    return DEFAULT_TYPE_MAPPING_HUBSPOT[entry.type];
  }
  if (integration.integrationType === 'SALESFORCE') {
    return DEFAULT_TYPE_MAPPING_SALESFORCE[entry.type];
  }

  return null;
};

export const getPicklistValues = async (internalFieldName: string, recordType: RecordType) => {
  const fieldStats = (await fetchFieldStats(recordType, internalFieldName)) as any;
  if (!fieldStats.values) return [];
  return fieldStats.values.map((term: any) => term.value) ?? [];
};
