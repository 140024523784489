import { useCallback, useMemo } from 'react';
import './../filters.scss';
import { getOperatorsForField } from './operators';
import { CompanySchema } from 'src/auth';
import { getGroupNameForFieldOption } from 'src/utils/selectOptions';
import Filters from 'src/components/Filters/Filters.tsx';
import { CustomValueEditor } from './CustomValueEditor.tsx';

type FilterProps = {
  schema: CompanySchema;
  setQuery: (a: any) => void;
  defaultQuery: any;
  disabled?: boolean;
};

export default function ClientCompaniesFilters({ schema, setQuery, defaultQuery, disabled = false }: FilterProps) {
  const fields = useMemo(
    () => [
      { name: '', label: 'Select...' },
      ...schema
        .filter((f: any) => !f.isHiddenFromUser)
        .map((f: any) => {
          return {
            name: f.fieldName,
            label: f.displayName,
            type: f.type,
            group: getGroupNameForFieldOption(f)
          };
        })
    ],
    [schema]
  );

  const getOperators = useCallback(
    (field: string) => {
      return getOperatorsForField(schema, field);
    },
    [schema]
  );

  return (
    <Filters
      fields={fields}
      getOperators={getOperators}
      setQuery={setQuery}
      defaultQuery={defaultQuery}
      disabled={disabled}
      valueEditor={CustomValueEditor}
    />
  );
}
