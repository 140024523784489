import { useCallback, useEffect, useState } from 'react';
import API from 'src/utils/API';
import { useErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

import ClientContext, { setCurrentClientName } from './ClientContext';
import PageLoader from 'src/components/PageLoader';
import { AppSettings, ClientContextType } from './types';
import toast from 'react-hot-toast';
import flatMap from 'lodash/flatMap';

interface SessionApiResponse {
  client: ClientContextType;
}

function ClientProvider({ children }: { children: React.ReactNode }) {
  const { clientName } = useParams();
  if (clientName) setCurrentClientName(clientName);
  const [clientData, setClientData] = useState<ClientContextType>(null!);
  const [isClientDataLoading, setIsClientDataLoading] = useState(true);

  const { showBoundary } = useErrorBoundary();

  const loadClientData = useCallback(
    async (isInitial?: boolean) => {
      try {
        const data = await API.get<SessionApiResponse>('/app/client', {});
        if (data?.client) {
          const client = data.client;
          client.ccmDataBlocksFields = flatMap(client.ccmDataBlocksDefinitions, 'fields');
          client.ccmDynamicDataBlocksDefinitions = client.ccmDataBlocksDefinitions.filter(
            block => block.isParameterisedWithDynamicConfig
          );
          setClientData(client);
        }
        setIsClientDataLoading(false);

        // Periodically refresh this data, e.g. if auth permissions or field settings change
        // We dont want to use setInterval as it can cause duplication when window in background
        setTimeout(loadClientData, 60_000); // Refresh every 60 seconds
      } catch (err) {
        if ((err as any)?.['$metadata']?.httpStatusCode == 403) {
          window.location.href = '/app';
        } else {
          // Only show error if initial load. If its a refresh ignore as could be eg wifi connection error
          if (isInitial) {
            showBoundary(err);
          } else {
            // Try again in 30 seconds
            setTimeout(loadClientData, 30_000);
          }
        }
      }
    },
    [showBoundary]
  );
  useEffect(() => {
    loadClientData(true);
  }, [loadClientData]);

  const updateSettings = useCallback(async (update: Partial<AppSettings>, skipToast?: boolean) => {
    setClientData(data => {
      const appSettings = { ...(data.appSettings || {}), ...update };
      return {
        ...data,
        appSettings
      };
    });
    if (skipToast) {
      await API.post('/app/client/settings', {}, { appSettings: update });
    } else {
      await toast.promise(API.post('/app/client/settings', {}, { appSettings: update }), {
        success: 'Settings saved',
        error: 'Saving settings failed',
        loading: 'Saving settings...'
      });
    }
  }, []);

  const hasFeatureFlag = useCallback(
    (flag: string) => {
      if (!clientData.featureFlags) return false;
      return clientData.featureFlags[flag] ?? false;
    },
    [clientData]
  );

  if (isClientDataLoading) {
    return <PageLoader />;
  }

  return (
    <ClientContext.Provider value={{ ...clientData, refresh: loadClientData, updateSettings, hasFeatureFlag }}>
      {children}
    </ClientContext.Provider>
  );
}

export default ClientProvider;
