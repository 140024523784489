import API from 'src/utils/API';
import { ListTable } from '../Table/listTable/ListTable';
import { ColumnDef } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { renderDateTime } from 'src/components/Table/cellRenderers.tsx';
import Tag from '../Tag';
import { OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import { ColumnHeader } from '../Table/listTable/ColumnHeader';

async function optionsForBatchTasks(
  field: string,
  prefix: string
): Promise<{
  options: string[];
}> {
  return await API.get<{
    options: string[];
  }>(
    '/admin/batch-tasks/options',
    {
      field,
      prefix
    },
    {}
  );
}

async function listBatchTasks(
  filtering: { id: string; value: string }[],
  sorting: string,
  offset: number,
  limit: number
): Promise<{
  items: any[];
  count: number;
}> {
  const requestParams: any = {
    sorting,
    limit,
    offset
  };

  filtering.forEach(f => (requestParams[f.id] = f.value));

  return await API.get<{
    items: any[];
    count: number;
  }>('/admin/batch-tasks/items', requestParams, {});
}

async function hasRunningBatchTasks(taskType: string): Promise<boolean> {
  return (
    (
      await API.get<{
        items: any[];
        count: number;
      }>(
        '/admin/batch-tasks/items',
        {
          taskType,
          status: ['WAITING', 'PROCESSING']
        },
        {}
      )
    ).items.length > 0
  );
}

export default function BatchTaskList({
  taskType,
  onHasRunningTask,
  showClientName = true
}: {
  taskType: string;
  onHasRunningTask?: (hasRunning: boolean) => void;
  showClientName?: boolean;
}) {
  const columns: ColumnDef<any>[] = useMemo(() => {
    const columns: ColumnDef<any>[] = [];
    if (showClientName) {
      columns.push({
        id: 'clientName',
        accessorFn: (row: any) => row.clientName,
        enableSorting: true,
        enableColumnFilter: true,
        header: ({ column }) => (
          <ColumnHeader
            column={column}
            allowFiltering={true}
            headerName={'Client'}
            allowSorting={true}
            loadOptions={optionsForBatchTasks}
          />
        )
      } as ColumnDef<any>);
    }
    columns.push(
      ...[
        {
          id: 'taskDisplayName',
          accessorFn: (row: any) => row.taskDisplayName,
          enableSorting: true,
          enableColumnFilter: false,
          header: ({ column }) => <ColumnHeader column={column} headerName={'Task Name'} allowSorting={true} />
        } as ColumnDef<any>,
        {
          id: 'status',
          accessorFn: (row: any) => row.status,
          enableSorting: true,
          enableColumnFilter: true,
          cell: c => {
            const state = `${c.getValue()}`;
            let color = 'grey';
            if (state === 'COMPLETE') color = 'green';
            if (state === 'ERROR') color = 'red';
            if (state === 'PROCESSING') color = 'orange';
            return <Tag color={color}>{state}</Tag>;
          },
          header: ({ column }) => (
            <ColumnHeader
              column={column}
              allowFiltering={true}
              headerName={'Status'}
              allowSorting={true}
              loadOptions={optionsForBatchTasks}
            />
          )
        } as ColumnDef<any>,
        {
          id: 'createdAt',
          accessorFn: (row: any) => row.createdAt,
          enableSorting: true,
          enableColumnFilter: true,
          cell: renderDateTime(),
          header: ({ column }) => <ColumnHeader column={column} headerName={'Started At'} allowSorting={true} />
        } as ColumnDef<any>,
        {
          id: 'progress',
          //accessorFn: (row: any) => row.status,
          enableSorting: false,
          enableColumnFilter: false,
          cell: c => {
            const itemsTotal = c.row.original.itemsTotal;
            // or 0 to avoid NaN
            const itemsProcessed = c.row.original.itemsProcessed || 0;
            const progress = itemsProcessed / itemsTotal || 0;
            const itemsDetailedCounts = c.row.original.itemsDetailedCounts;
            return (
              <OverlayTrigger
                rootClose
                placement="left"
                overlay={
                  <Popover
                    style={{
                      minWidth: '350px'
                    }}
                  >
                    <Popover.Body>
                      <pre>{JSON.stringify(itemsDetailedCounts, null, 2)}</pre>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className="d-flex justify-content-even" style={{ gap: 8 }}>
                  <ProgressBar
                    now={progress * 100}
                    label={`${Math.round(progress * 100)}%`}
                    style={{ width: '70%', minWidth: 150 }}
                  ></ProgressBar>
                  <div style={{ textAlign: 'center' }} className="flex-grow-1">
                    {itemsProcessed} / {itemsTotal}
                  </div>
                </div>
              </OverlayTrigger>
            );
          },
          header: ({ column }) => <ColumnHeader column={column} headerName={'Progress'} allowSorting={false} />
        } as ColumnDef<any>
      ]
    );
    return columns;
  }, [showClientName]);
  const doListBatchTasks = useCallback(
    (filtering: { id: string; value: string }[], sorting: string, offset: number, limit: number) => {
      hasRunningBatchTasks(taskType).then(response => onHasRunningTask && onHasRunningTask(response));
      const finalFilters = [...filtering, { id: 'taskType', value: taskType }];
      return listBatchTasks(finalFilters, sorting, offset, limit);
    },
    [onHasRunningTask, taskType]
  );

  return <ListTable columns={columns} shouldPollData={true} pollInterval={5000} list={doListBatchTasks} />;
}
