import Slider from 'rc-slider';
import './slider-tooltip.scss';
import { interpolateScoringColour } from './colors';
import { formatNumber } from 'src/utils/number';

const tooltipFormatter = (v: any) => {
  const vs = formatNumber(v, 1);
  if (v === 0) {
    return `${vs} Neutral`;
  }
  if (v <= -8) {
    return `${vs} Very negative impact`;
  }
  if (v <= -4) {
    return `${vs} Negative impact`;
  }
  if (v <= -1) {
    return `${vs} Somewhat negative impact`;
  }
  if (v >= 8) {
    return `${vs} Very positive impact`;
  }
  if (v >= 5) {
    return `${vs} Positive impact`;
  }
  if (v >= 1) {
    return `${vs} Somewhat positive impact`;
  }
};

export default function ScoringRuleSlider({ rule, modifyRule, disabled }: any) {
  const colour = interpolateScoringColour(rule.score / 10);
  return (
    <Slider
      value={rule.score}
      onChange={value => modifyRule({ score: value })}
      min={-10}
      max={10}
      step={1}
      disabled={disabled}
      trackStyle={{ backgroundColor: colour, borderRadius: 0 }}
      handleRender={renderProps => {
        return (
          <div {...renderProps.props}>
            <div className="slider-tooltip">{tooltipFormatter(rule.score)}</div>
          </div>
        );
      }}
      startPoint={0}
    />
  );
}
