import { CompanyCriteriaFilterOperator, DataBlockFieldDataType } from 'src/apis/clients/types.ts';

export function getOperatorsForField(type: DataBlockFieldDataType) {
  if (['String', 'Picklist'].includes(type)) {
    return [
      { label: 'Is one of', name: CompanyCriteriaFilterOperator.IN },
      { label: 'Is not one of', name: CompanyCriteriaFilterOperator.NOT_IN },
      { label: 'Matches any text', name: CompanyCriteriaFilterOperator.STRING_MATCHES_ANY_TEXT },
      { label: 'Not matches any text', name: CompanyCriteriaFilterOperator.STRING_NOT_MATCHES_ANY_TEXT },
      { label: 'Equals', name: CompanyCriteriaFilterOperator.EQUALS },
      { label: 'Not equals', name: CompanyCriteriaFilterOperator.NOT_EQUALS },
      { label: 'Null', name: CompanyCriteriaFilterOperator.NULL, arity: 0 },
      { label: 'Not null', name: CompanyCriteriaFilterOperator.NOT_NULL, arity: 0 },
      { label: 'Contains', name: CompanyCriteriaFilterOperator.STRING_CONTAINS },
      { label: 'Begins with', name: CompanyCriteriaFilterOperator.STRING_BEGINS_WITH }, //string as input
      { label: 'Ends with', name: CompanyCriteriaFilterOperator.STRING_ENDS_WITH }, //string as input
      { label: 'Regex contains', name: CompanyCriteriaFilterOperator.REGEX_CONTAINS }, //string as input
      { label: 'Regex case insensitive', name: CompanyCriteriaFilterOperator.REGEX_CONTAINS_CASEINSENSITIVE }, //string as input
      { label: `Regex doesn't contains`, name: CompanyCriteriaFilterOperator.REGEX_NOT_CONTAINS }, //string as input
      {
        label: "Regex doesn't contains case insensitive",
        name: CompanyCriteriaFilterOperator.REGEX_NOT_CONTAINS_CASEINSENSITIVE
      }
    ];
  } else if (['Number', 'Percentage', 'Currency'].includes(type)) {
    return [
      { label: 'Equals', name: CompanyCriteriaFilterOperator.EQUALS },
      { label: 'Not equals', name: CompanyCriteriaFilterOperator.NOT_EQUALS },
      { label: 'Lesser than', name: CompanyCriteriaFilterOperator.LT },
      { label: 'Greater than', name: CompanyCriteriaFilterOperator.GT },
      { label: 'Less or equal', name: CompanyCriteriaFilterOperator.LTE },
      { label: 'Greater or equal', name: CompanyCriteriaFilterOperator.GTE },
      { label: 'Null', name: CompanyCriteriaFilterOperator.NULL, arity: 0 },
      { label: 'Not null', name: CompanyCriteriaFilterOperator.NOT_NULL, arity: 0 },
      { label: 'Between', name: CompanyCriteriaFilterOperator.BETWEEN, arity: 2 }
    ];
  } else if (type === 'Boolean') {
    return [
      { label: 'Equals', name: CompanyCriteriaFilterOperator.EQUALS },
      { label: 'Not equals', name: CompanyCriteriaFilterOperator.NOT_EQUALS },
      { label: 'Null', name: CompanyCriteriaFilterOperator.NULL, arity: 0 },
      { label: 'Not null', name: CompanyCriteriaFilterOperator.NOT_NULL, arity: 0 }
    ];
  } else if (['Multipicklist', 'Array'].includes(type)) {
    return [
      { label: 'Contains any of', name: CompanyCriteriaFilterOperator.ARRAY_CONTAINS_ANY_OF }, //array as input
      { label: 'Not contains any of', name: CompanyCriteriaFilterOperator.ARRAY_NOT_CONTAINS_ANY_OF }, //array as input,
      { label: 'Matches any text', name: CompanyCriteriaFilterOperator.ARRAY_MATCHES_ANY_TEXT }, //array as input
      { label: 'Not matches any text', name: CompanyCriteriaFilterOperator.ARRAY_NOT_MATCHES_ANY_TEXT }, //array as input,
      { label: 'Contains', name: CompanyCriteriaFilterOperator.LIST_CONTAINS }, // strings as inputs
      { label: 'Regex contains', name: CompanyCriteriaFilterOperator.REGEX_CONTAINS },
      { label: 'Regex case insensitive', name: CompanyCriteriaFilterOperator.REGEX_CONTAINS_CASEINSENSITIVE },
      { label: `Regex doesn't contains`, name: CompanyCriteriaFilterOperator.REGEX_NOT_CONTAINS },
      {
        label: "Regex doesn't contains case insensitive",
        name: CompanyCriteriaFilterOperator.REGEX_NOT_CONTAINS_CASEINSENSITIVE
      },
      { label: 'Not null', name: CompanyCriteriaFilterOperator.NOT_NULL, arity: 0 },
      { label: 'Null', name: CompanyCriteriaFilterOperator.NULL, arity: 0 }
    ];
  } else if (type === 'Date') {
    return [
      { label: 'Equals', name: CompanyCriteriaFilterOperator.EQUALS },
      { label: 'Not equals', name: CompanyCriteriaFilterOperator.NOT_EQUALS },
      { label: 'Before', name: CompanyCriteriaFilterOperator.LT },
      { label: 'After', name: CompanyCriteriaFilterOperator.GT },
      { label: 'Before or equal', name: CompanyCriteriaFilterOperator.LTE },
      { label: 'After or equal', name: CompanyCriteriaFilterOperator.GTE },
      { label: 'Null', name: CompanyCriteriaFilterOperator.NULL, arity: 0 },
      { label: 'Not null', name: CompanyCriteriaFilterOperator.NOT_NULL, arity: 0 },
      { label: 'Between', name: CompanyCriteriaFilterOperator.BETWEEN, arity: 2 }
    ];
  }

  return [];
}
