import { useClient } from 'src/auth';
import { useEffect, useState } from 'react';
import { Card, Nav } from 'react-bootstrap';
import CompanySourcingCriteria from 'src/pages/data-directory/components/CompanySourcingCriteria.tsx';
import ContactCriteria from 'src/pages/data-directory/components/ContactCriteria.tsx';

export function ConfigTabs() {
  const client = useClient();
  const [activeTab, setActiveTab] = useState('companies');

  // Must be enabled with the flag and have legacy contactsCriteria defined to show the panel
  const isContactsEnabled = client.isContactsEnabled && client.contactCriteria && client.contactCriteria?.length > 0;

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#contacts' && isContactsEnabled) {
      setActiveTab('contacts');
    } else {
      setActiveTab('companies');
    }
  }, [isContactsEnabled]);

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setActiveTab(eventKey);
      window.location.hash = eventKey === 'contacts' ? '#contacts' : '#companies';
    }
  };

  return (
    <Card>
      <Card.Header>
        <Nav variant="tabs" activeKey={activeTab} onSelect={handleSelect}>
          <Nav.Item>
            <Nav.Link eventKey="companies">Company Criteria</Nav.Link>
          </Nav.Item>
          {isContactsEnabled && (
            <Nav.Item>
              <Nav.Link eventKey="contacts">Contact Criteria</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </Card.Header>
      <Card.Body>
        {activeTab === 'companies' && (
          <CompanySourcingCriteria
            sourcingCriteria={client.sourcingCriteria}
            companiesSchema={client.companiesSchema}
            ccmDataBlocksFields={client.ccmDataBlocksFields}
          />
        )}
        {activeTab === 'contacts' && isContactsEnabled && <ContactCriteria contactCriteria={client.contactCriteria} />}
      </Card.Body>
    </Card>
  );
}
