import { ClientContextType } from 'src/auth';
import { formatNumber } from 'src/utils/number';

export interface CreditSummaryTexts {
  companiesSummary: string;
  contactCreditsSummary: string | null;
  enrichmentCreditsSummary: string | null;
}

export const produceSummaryTexts = (client: ClientContextType): CreditSummaryTexts => {
  const {
    creditBalances: { ENRICHMENT: enrichmentCredits, CONTACT: contactCredits },
    limits,
    usageCounts
  } = client;

  const companiesBalance = {
    limit: limits.companies ?? null,
    usage: usageCounts.companies
  };

  const hasCompaniesLimit = companiesBalance.limit !== null;

  const companiesSummary =
    `Companies: ${formatNumber(companiesBalance.usage)}` +
    `${hasCompaniesLimit ? '/' + formatNumber(companiesBalance.limit!) : ''}`;

  const contactCreditsSummary =
    contactCredits !== null ? `Contact Credits: ${formatNumber(contactCredits)} Remaining` : null;

  const enrichmentCreditsSummary =
    enrichmentCredits !== null ? `Enrichment Credits: ${formatNumber(enrichmentCredits)} Remaining` : null;

  return { companiesSummary, contactCreditsSummary, enrichmentCreditsSummary };
};
