import { InputGroup } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import './inputs.scss';
import { DEFAULT_THEME } from 'src/utils/react-select.ts';

function toOptions(values: string[]) {
  return values.map((i: string) => ({ label: i, value: i })) || [];
}

export function SearchAndCreateInput({
  title,
  loadOptions,
  value,
  onUpdate
}: {
  title: string;
  loadOptions: (inputValue: string) => Promise<string[]>;
  value: string[];
  onUpdate: (value: any[]) => void;
}) {
  return (
    <InputGroup className={'input-group'} size={'sm'}>
      <InputGroup.Text className="input-text-header">{title}</InputGroup.Text>
      <AsyncCreatableSelect
        className={'input-select'}
        cacheOptions
        loadOptions={async prefix => toOptions(await loadOptions(prefix))}
        value={toOptions(value)}
        defaultOptions
        isMulti={true}
        classNamePrefix="react-select"
        onChange={(selectedOption: any) => {
          if (selectedOption) {
            onUpdate(selectedOption.map((option: any) => option.value));
          } else {
            onUpdate([]);
          }
        }}
        theme={DEFAULT_THEME}
      />
    </InputGroup>
  );
}
