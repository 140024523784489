import { Button, Form, Modal } from 'react-bootstrap';
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { createTalkTrack, updateTalkTrack } from 'src/pages/talk-tracks/apis';

export type TalkTrackModalProps = {
  show: boolean;
  onHide: () => void;
  talkTrackId?: string;
  talkTrackName?: string;
  filters?: any;
  afterSubmit?: (update: { talkTrackId?: string; name: string; filters: any }) => void;
  header?: string;
};

export default function TalkTrackModal({
  show,
  onHide,
  talkTrackId,
  talkTrackName,
  filters,
  afterSubmit,
  header
}: TalkTrackModalProps) {
  const [name, setName] = useState<string>(talkTrackName as string);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      await toast
        .promise(
          (async () => {
            if (talkTrackId) {
              await updateTalkTrack(talkTrackId, { name, filters });
            } else {
              await createTalkTrack({ name, filters });
            }
          })(),
          {
            loading: 'Saving',
            success: 'Saved, data will refresh within a few minutes',
            error: ({ message }) => `Failed: ${message}`
          }
        )
        .then(() => {
          afterSubmit && afterSubmit({ talkTrackId, name, filters });
          onHide();
        });
    },
    [afterSubmit, filters, name, onHide, talkTrackId]
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>{header || 'Tag'}</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="talkTrackName">
            <div className="form-group">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={event => {
                  setName(event.target.value);
                }}
                required
                placeholder="Tag name"
                defaultValue={talkTrackName}
                autoFocus={true}
                type="text"
              />
            </div>
            <div style={{ textAlign: 'right' }} className={'mt-3'}>
              <Button size={'sm'} type="submit">
                Save
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
