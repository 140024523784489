import { CoverageAnalysisDomains, CoverageAnalysisDTO } from 'src/pages/staff/coverageAnalysis/types.ts';
import API from 'src/utils/API.ts';

export async function clearDomains(domains: string[]): Promise<CoverageAnalysisDomains> {
  return await API.post<CoverageAnalysisDomains>(`/admin/coverage-analyses/domains`, {}, domains);
}

export async function runCoverageAnalysis(request: CoverageAnalysisDTO): Promise<{ executionId: string }> {
  return await API.post<{ executionId: string }>(`/admin/coverage-analyses/run`, {}, request);
}

export async function getCoverageAnalysisResult(executionId: string): Promise<any> {
  return await API.get<any>(`/admin/coverage-analyses/run/${executionId}`, {});
}

export async function createCoverageAnalysis(request: CoverageAnalysisDTO): Promise<any> {
  return (
    await API.post<{
      coverageAnalysis: CoverageAnalysisDTO;
    }>(`/admin/coverage-analyses/coverage-analyses`, {}, request)
  ).coverageAnalysis;
}

export async function getCoverageAnalysis(id: string): Promise<CoverageAnalysisDTO> {
  return (
    await API.get<{
      coverageAnalysis: CoverageAnalysisDTO;
    }>(`/admin/coverage-analyses/coverage-analyses/${id}`, {})
  ).coverageAnalysis;
}

export async function getAllCoverageAnalysis(
  search: string,
  limit: number,
  offset: number
): Promise<{
  coverageAnalyses: CoverageAnalysisDTO[];
  coverageAnalysesCnt: number;
}> {
  const result = await API.get<{
    coverageAnalyses: CoverageAnalysisDTO[];
    coverageAnalysesCnt: number;
  }>(`/admin/coverage-analyses/coverage-analyses`, { search, limit, offset });
  result.coverageAnalyses = result.coverageAnalyses.map(ca => ({
    ...ca,
    updatedAt: ca.updatedAt && new Date(ca.updatedAt),
    createdAt: ca.createdAt && new Date(ca.createdAt)
  }));
  return result;
}

export async function updateCoverageAnalysis(id: string, request: CoverageAnalysisDTO): Promise<any> {
  return await API.put<any>(`/admin/coverage-analyses/coverage-analyses/${id}`, {}, request);
}

export async function deleteCoverageAnalysis(id: string): Promise<any> {
  return await API.del(`/admin/coverage-analyses/coverage-analyses/${id}`, {});
}
