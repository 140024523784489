import { Col, Placeholder, Row } from 'react-bootstrap';
import { formatCurrency, formatNumber, formatPercent } from 'src/utils/number';

function MetricCard({ label, value, isLoading }: any) {
  return (
    <>
      <div className="text-muted py-2">{label}</div>
      {isLoading ? (
        <Placeholder animation="glow" className="fs-1 mb-4" style={{ opacity: 0.5 }}>
          <Placeholder xs={6} bg="secondary" />
        </Placeholder>
      ) : (
        <div className="fs-1 mb-4">{value || '-'}</div>
      )}
    </>
  );
}

export default function FieldStatsOffcanvasMetrics({ isLoading, fieldStats, fieldType }: any) {
  const totalRows = fieldStats?.totalRows ?? 0;
  const missingRows = fieldStats?.missingRows ?? 0;
  const coverage = 1 - missingRows / totalRows;

  let format = (v: number) => formatNumber(v);
  if (fieldType === 'Percentage') format = (v: number) => formatPercent(v);
  if (fieldType === 'Currency') format = (v: number) => formatCurrency(v);

  const showNumeric = ['Number', 'Currency', 'Percentage'].includes(fieldType);
  return (
    <>
      <hr />
      <h3>Field statistics</h3>
      <Row>
        <Col>
          <MetricCard label="Coverage" value={formatPercent(coverage)} isLoading={isLoading} />
        </Col>
        <Col>
          <MetricCard label="Missing values" value={formatNumber(fieldStats?.missingRows)} isLoading={isLoading} />
        </Col>
        <Col>
          <MetricCard
            label="Unique Values"
            value={formatNumber(fieldStats?.totalDistinctValues)}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      {showNumeric && (
        <Row>
          <Col>
            <MetricCard label="Minimum" value={format(fieldStats?.minValue)} isLoading={isLoading} />
          </Col>
          <Col>
            <MetricCard label="Maximum" value={format(fieldStats?.maxValue)} isLoading={isLoading} />
          </Col>
          <Col>
            <MetricCard label="Average" value={format(fieldStats?.avgValue)} isLoading={isLoading} />
          </Col>
        </Row>
      )}
    </>
  );
}
