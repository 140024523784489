import { Outlet, useLocation } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import Sitenav from './components/Sidenav';
import ErrorMessage from 'src/components/ErrorMessage';
import { FloatingAdminHelpButton } from 'src/layouts/AdminDashboard/components/FloatingAdminHelpButton.tsx';

export default function AdminDashboard() {
  const location = useLocation();

  return (
    <>
      <Sitenav />
      <div className="main-content" style={{ paddingBottom: 50 }}>
        <Container fluid>
          <Row className="justify-content-center">
            {/* 1440 -250 (sidenav)*/}
            <div style={{ maxWidth: '1190px' }}>
              <ErrorBoundary key={location.pathname} fallback={<ErrorMessage />}>
                <Outlet />
              </ErrorBoundary>
            </div>
          </Row>
        </Container>
      </div>
      <FloatingAdminHelpButton />
    </>
  );
}
