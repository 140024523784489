import JsonEditor from 'src/components/JsonEditor/JsonEditor.tsx';
import { ClientConfig } from 'src/apis/clients/types.ts';

export function ExcludeCompanyIds({
  isEditing,
  schema,
  clientConfig,
  handleChange
}: {
  isEditing: boolean;
  schema: any;
  clientConfig: ClientConfig;
  handleChange: (update: any, path: string) => void;
}) {
  return (
    <>
      <h3>Exclude companyIds</h3>
      <JsonEditor
        schema={{
          items: {},
          type: 'array'
        }}
        referenceSchema={schema}
        json={clientConfig.excludeCompanyIds}
        isEditing={isEditing}
        onChange={(update: any) => {
          handleChange(update, 'excludeCompanyIds');
        }}
      />
    </>
  );
}
