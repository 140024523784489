type ClientLifecycleStage =
  | 'CALIBRATION'
  | 'CLIENT_ONBOARDING'
  | 'CLIENT_ACTIVE'
  | 'CLIENT_CHURNED'
  | 'CLIENT_TEST_ACCOUNT'
  | 'GOODFIT_TEST_ACCOUNT'
  | 'OTHER';

export type ClientConfig = {
  clientName: string;
  contactsEnabled: boolean;
  syncWithoutContacts: boolean;
  syncWithoutEmails: boolean;
  crmDataPullEnabled: boolean;
  autoSyncEnabled: boolean;
  sourcingCriteria: CompanyCriteriaGroup;
  fieldDefinitions: FieldMappingDefinition[];
  enrichmentDestinations: string[];
  otherEnrichmentStages: any[];
  contactCriteria: ContactCriteria[];
  dataBlockConfigs: any[];
  excludeCompanyIds: any[];
  crmDataPullConfig?: any;
  customDataFields: CustomDataField[];
  gfAppFeatureFlags: any;
  lifecycleStage: ClientLifecycleStage;
  scheduledForDeletionAt?: Date;
};

export type CustomDataField = {
  name: string;
  type: DataBlockFieldDataType;
  expressionSql?: string;
  fromField?: string;
  description?: string;
  displayName?: string;
};

export type ClientConfigShort = {
  clientName: string;
  lastDataSyncStartedAt: Date;
  lastDataSyncCompletedAt: Date;
  lifecycleStage: ClientLifecycleStage;
  scheduledForDeletionAt?: Date;
};

export interface FieldMappingDefinition {
  internalName: string;
  externalName?: string;
  configId?: string;
}

export interface DataBlockDefinition {
  blockName: string;
  publicDescription?: string;
  internalNotes?: string;
  isDepreciated?: boolean;
  isParameterisedWithDynamicConfig: boolean;
  fields: DataBlockFieldDefinition[];
  exampleConfig?: any;
}

export interface DataBlockFieldDefinition {
  dataBlock: string;
  internalName: string;
  publicName: string;
  type: DataBlockFieldDataType;
  redshiftType?: RedshiftType;
  salesforceType:
    | 'integer'
    | 'float'
    | 'percentage'
    | 'string'
    | 'boolean'
    | 'picklist'
    | 'multipicklist'
    | 'currency'
    | 'date';
  hubspotType: 'number' | 'string' | 'boolean' | 'picklist' | 'multipicklist' | 'currency' | 'date';
  publicDescription?: string;
  internalNotes?: string;
  publicRefreshFrequencyNotes?: string;
  isDepreciated?: boolean;
  // default ranges to be applied. In case it's a date, it should be in the format 'YYYY-MM-DD'
  defaultRanges?: (number | string)[];
}

export type DataBlockFieldDataType =
  | 'Boolean'
  | 'String'
  | 'Picklist'
  | 'Multipicklist'
  | 'Number'
  | 'Currency'
  | 'Date'
  | 'Array'
  | 'Percentage';

export type RedshiftType =
  | 'bigint'
  | 'boolean'
  | 'character varying'
  | 'double precision'
  | 'integer'
  | 'numeric'
  | 'timestamp with time zone'
  | 'super';

export type DynamicDataBlockConfig = {
  configId: string;
  blockName: string;
  config: any;
};

export enum CompanyCriteriaFilterOperator {
  EQUALS = '=',
  NOT_EQUALS = '!=',
  LT = '<',
  GT = '>',
  LTE = '<=',
  GTE = '>=',
  NULL = 'null',
  REGEX_CONTAINS = '~',
  REGEX_CONTAINS_CASEINSENSITIVE = '~*',
  REGEX_NOT_CONTAINS = '!~',
  REGEX_NOT_CONTAINS_CASEINSENSITIVE = '!~*',
  NOT_NULL = 'notNull',
  IN = 'in',
  NOT_IN = 'notIn',
  STRING_CONTAINS = 'stringContains',
  LIST_CONTAINS = 'listContains',
  STRING_BEGINS_WITH = 'stringBeginsWith',
  STRING_ENDS_WITH = 'stringEndsWith',
  BETWEEN = 'between',
  STRING_MATCHES_ANY_TEXT = 'stringMatchesAnyText',
  STRING_NOT_MATCHES_ANY_TEXT = 'stringNotMatchesAnyText',
  ARRAY_CONTAINS_ANY_OF = 'arrayContainsAnyOf',
  ARRAY_NOT_CONTAINS_ANY_OF = 'arrayNotContainsAnyOf',
  ARRAY_MATCHES_ANY_TEXT = 'arrayMatchesAnyText',
  ARRAY_NOT_MATCHES_ANY_TEXT = 'arrayNotMatchesAnyText'
}

export interface CompanyCriteriaFilter {
  id?: string;
  label?: string;
  field: string;
  operator: CompanyCriteriaFilterOperator;
  value?: any;
  configId?: string;
}

export enum CompanyCriteriaGroupCombinationMode {
  AND = 'and',
  OR = 'or'
}

export interface CompanyCriteriaGroup {
  id?: string;
  label?: string;
  combinator?: CompanyCriteriaGroupCombinationMode;
  not?: boolean;
  rules: (CompanyCriteriaFilter | CompanyCriteriaGroup)[];
}

export type CompanyCriteria = CompanyCriteriaGroup;

export type ContactCriteria = {
  persona?: string;
  seniority: string[];
  inclusionKeywords: string[];
  exclusionKeywords?: string[];
  employeeCountBounds: { min: number; max: number };
};
