import { Button, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SimplePaginator from 'src/components/Table/SimplePaginator';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import isBoolean from 'lodash/isBoolean';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import { CoverageAnalysisResult, LeafRule, NodeRule } from 'src/pages/staff/coverageAnalysis/types.ts';
import { IdCardCell } from 'src/components/Table/IdCardCell.tsx';
import { FiInfo } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { SchemaDataType } from 'src/auth';
import { CELL_RENDERERS } from 'src/components/Table/cellRenderers.tsx';
import { getParentStatus } from 'src/pages/staff/coverageAnalysis/components/parentStatus.ts';
import { useAdminContext } from 'src/adminContext/hooks.ts';

const PAGE_SIZE = 50;

export function CompanyListModal({
  show,
  filters,
  onHide,
  coverageReport
}: {
  show: boolean;
  filters: { included: boolean; ruleId?: string };
  onHide: () => void;
  coverageReport: CoverageAnalysisResult;
}) {
  const { dataBlocksDefinitions } = useAdminContext();

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const filteredCompanies = useMemo(
    () =>
      orderBy(
        coverageReport.targetCompanies.filter((c: any) =>
          filters?.ruleId
            ? c.rules.find((r: any) => r.id === filters.ruleId)?.included === filters.included
            : filters?.included === c.included
        ),
        'domain'
      ),
    [coverageReport.targetCompanies, filters]
  );

  useEffect(() => {
    setTotalPages(Math.ceil(filteredCompanies.length / PAGE_SIZE));
  }, [filteredCompanies]);

  const companiesForPage = useMemo(() => {
    return filteredCompanies.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);
  }, [filteredCompanies, currentPage]);

  const getChildren = useCallback(
    (coverageReport: CoverageAnalysisResult, ruleId: string): { fieldName: string; configId?: string }[] => {
      const rule = coverageReport.flatRules.find((r: any) => r.id === ruleId);
      if (!rule) return [];
      if ('children' in rule) {
        return uniqWith(flatMap(rule.children.map((child: string) => getChildren(coverageReport, child))), isEqual);
      } else {
        // this is the leaf
        return [{ fieldName: rule.fieldName, configId: rule.configId }];
      }
    },
    []
  );

  const fieldsToDisplay: { fieldName: string; configId?: string }[] = useMemo(() => {
    if (filters?.ruleId) {
      const rule = coverageReport.flatRules.find(r => r.id === filters.ruleId);
      if (!rule) return [];
      if ('children' in rule) {
        // this is the node
        return getChildren(coverageReport, filters.ruleId);
      } else {
        // this is the leaf
        return [{ fieldName: rule.fieldName, configId: rule.configId }];
      }
    } else {
      // just do not display nodes.
      return uniqWith(
        compact(
          coverageReport.flatRules.map(rule => {
            if ('fieldName' in rule) return { fieldName: rule.fieldName, configId: rule.configId };
            else return null;
          })
        ),
        isEqual
      );
    }
  }, [coverageReport, filters?.ruleId, getChildren]);

  const valuesToDisplay = useCallback(
    (targetCompanyRules: any[]) => {
      return uniq(
        fieldsToDisplay?.map(field =>
          targetCompanyRules.find(tgr => tgr.fieldName === field.fieldName && tgr.configId === field.configId)
        )
      );
    },
    [fieldsToDisplay]
  );

  const parentStatus = useCallback(
    (targetCompanyRules: (NodeRule | LeafRule)[], fromRuleId: string, toRuleId: string): boolean => {
      return getParentStatus(targetCompanyRules, fromRuleId, toRuleId);
    },
    []
  );

  const inclusionStatus = useCallback(
    (targetCompanyRules: (NodeRule | LeafRule)[], fieldName: string, configId?: string, ruleId?: string) => {
      const allFields: LeafRule[] = targetCompanyRules
        .filter(tgr => !ruleId || tgr.id.startsWith(ruleId))
        .filter(tgr => 'fieldName' in tgr && tgr.fieldName === fieldName && tgr.configId === configId) as LeafRule[];

      if (!allFields?.length) {
        return null;
      }
      if (allFields?.length === 1) {
        return parentStatus(targetCompanyRules, allFields[0].id, ruleId || '');
      }
      const allTrue = allFields.every(f => parentStatus(targetCompanyRules, f.id, ruleId || ''));
      const allFalse = allFields.every(f => !parentStatus(targetCompanyRules, f.id, ruleId || ''));

      if (allTrue) {
        return true;
      } else if (allFalse) {
        return false;
      } else {
        return false;
      }
    },
    [parentStatus]
  );

  const colourToDisplay = useCallback(
    (targetCompanyRules: (NodeRule | LeafRule)[], fieldName: string, configId?: string, ruleId?: string) => {
      const green = '#bdfcce';
      const red = '#ff9eab';
      const partial = '#fff3cd';
      const status = inclusionStatus(targetCompanyRules, fieldName, configId, ruleId);
      if (status === true) {
        return green;
      } else if (status === false) {
        return red;
      } else {
        return partial;
      }
    },
    [inclusionStatus]
  );

  const copyToClipboard = useCallback(() => {
    function valueToTsv(value: any) {
      if (value === null || value === undefined) return '-';
      if (isBoolean(value)) return value ? 'true' : 'false';
      return `${value}`;
    }

    function booleanToTsv(bool: boolean | null) {
      if (bool === null || bool === undefined) return '-';
      return bool ? 'Yes' : 'No';
    }

    const lines = [];
    const header = ['Domain', 'Original Domain', 'Included overall?'];
    for (const field of fieldsToDisplay) {
      header.push(`${field.fieldName + `${field.configId ? `(${field.configId})` : ''}`} value`);
      header.push(`${field.fieldName + `${field.configId ? `(${field.configId})` : ''}`} included?`);
    }
    lines.push(header.join('\t'));

    for (const company of filteredCompanies) {
      const row = [company.domain, company.originalDomain, booleanToTsv(company.included)];
      const fields = valuesToDisplay(company.rules);

      for (const field of fields) {
        row.push(valueToTsv(field.rawValue));
        row.push(booleanToTsv(inclusionStatus(company.rules, field.fieldName, field.configId, filters.ruleId)));
      }

      lines.push(row.join('\t'));
    }
    const textTsv = lines.join('\n');
    navigator.clipboard.writeText(textTsv);

    toast.success(`Copied ${filteredCompanies.length} rows to clipboard!`);
  }, [fieldsToDisplay, filteredCompanies, inclusionStatus, valuesToDisplay, filters]);

  const hide = useCallback(() => {
    setCurrentPage(0);
    setTotalPages(0);
    onHide();
  }, [onHide]);

  const getType = useCallback(
    (fieldName: string): SchemaDataType => {
      const type = dataBlocksDefinitions.flatMap(d => d.fields).find(f => f?.internalName === fieldName)?.type;
      // @ts-expect-error These are basically the same types
      return SchemaDataType[type as string];
    },
    [dataBlocksDefinitions]
  );

  return (
    <Modal scrollable show={show} size={fieldsToDisplay.length > 1 ? 'xl' : undefined} onHide={hide}>
      <Modal.Header closeButton>Companies</Modal.Header>
      <Modal.Body className="p-0">
        <Table responsive bordered className="table-centered" size="sm">
          <thead>
            <tr>
              <th style={{ minWidth: '160px' }}>Company</th>
              {fieldsToDisplay?.map((field, idx) => (
                <th key={field.fieldName + idx}>
                  {field.fieldName + `${field.configId ? `(${field.configId})` : ''}`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {companiesForPage.map((c: any, idx) => (
              <tr key={c.domain + idx}>
                <td>
                  <IdCardCell imageSrc={`https://logo.clearbit.com/${c.domain}`} name={c.domain}>
                    {c.domain !== c.originalDomain && (
                      <div>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-right">Original domain was {c?.originalDomain}</Tooltip>}
                        >
                          <div>
                            <FiInfo color={'primary'} />
                          </div>
                        </OverlayTrigger>
                      </div>
                    )}
                  </IdCardCell>
                </td>
                {valuesToDisplay(c.rules)?.map((rv, idx) => (
                  <td
                    key={c.domain + idx}
                    style={{ backgroundColor: colourToDisplay(c.rules, rv?.fieldName, rv?.configId, filters.ruleId) }}
                  >
                    {CELL_RENDERERS[getType(rv?.fieldName)](rv?.rawValue)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <SimplePaginator pageIndex={currentPage} totalPages={totalPages} setPage={setCurrentPage} />

        <div>
          <Button variant="white" size="sm" className="me-2" onClick={copyToClipboard}>
            Copy to clipboard
          </Button>
          <Button variant="secondary" size="sm" onClick={hide}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
