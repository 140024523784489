import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import MultiSelect, { SelectOption } from 'src/components/inputs/MultiSelect';
import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { loadSegments } from 'src/pages/segments/apis';
import { integrationToast } from '../../utils';
import { useIntegrationStore } from 'src/pages/settings/connectors/integrationsStore';
import { Integration } from 'src/apis/integrations';

interface SegmentSyncSettingsProps {
  integration: Integration;
}

interface SelectState {
  selectOptions: SelectOption[];
  initialSelections: SelectOption[];
}

export function SegmentSyncSettings({ integration }: SegmentSyncSettingsProps) {
  const updateConnector = useIntegrationStore(state => state.updateIntegration);

  const [selectState, setSelectState] = useState<SelectState>({ selectOptions: [], initialSelections: [] });

  useEffect(() => {
    loadSegments().then(({ items }) => {
      const selectOptions = items
        .filter(({ isDeleted }) => !isDeleted)
        .map(({ name: label, id: value }) => ({ label, value }));

      const initialSelections = selectOptions.filter(({ value }) => integration.segmentIds.includes(value));

      setSelectState({ selectOptions, initialSelections });
    });
  }, [integration.segmentIds]);

  const handleSyncOnlyToggle: React.ChangeEventHandler<HTMLInputElement> = async ev => {
    await integrationToast(
      async () =>
        await updateConnector(integration.id as string, {
          syncSegmentRecordsOnly: ev.target.checked
        })
    );
  };

  const handleSegmentSelection = async (segmentOptions: Array<SelectOption>): Promise<void> => {
    const segmentIds = segmentOptions.map(({ value }) => value);

    await integrationToast(async () => await updateConnector(integration.id, { segmentIds }));
  };

  return (
    <>
      <Form.Group className="mb-3" controlId="segmentsAutoSync">
        <Form.Check
          type="switch"
          id="sync-segment-records-only"
          name="sync-segment-records-only"
          onChange={handleSyncOnlyToggle}
          disabled={integration?.enableAutoSync === false}
          defaultChecked={integration.syncSegmentRecordsOnly}
          label={
            <span className="d-flex align-items-center gap-2">
              <span>Only sync records in the following segments</span>
              <TooltipWrapper
                placement="bottom"
                tooltipText="Select this and only records that are currently in or have ever been in certain segments will be synced to your CRM"
              />
            </span>
          }
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="segmentIdsToSync">
        <Form.Label>Segments to auto sync</Form.Label>
        <MultiSelect
          nameAttr={'segments-select'}
          placeholder="Select segments..."
          onChange={handleSegmentSelection}
          options={selectState.selectOptions}
          initialValues={selectState.initialSelections}
          disabled={integration?.enableAutoSync === false || integration.syncSegmentRecordsOnly === false}
        />
      </Form.Group>
    </>
  );
}
