import { useEffect, useState } from 'react';
import Select from 'react-select';

import { DEFAULT_THEME } from 'src/utils/react-select';

export type SelectOption = { label: string; value: any };

export type FieldValueSelectProps = {
  nameAttr: string;
  disabled?: boolean;
  placeholder?: string;
  options: SelectOption[];
  initialValues: SelectOption[];
  onChange: (value: SelectOption[]) => void;
};

export default function MultiSelect({
  nameAttr,
  disabled,
  placeholder,
  options,
  initialValues,
  onChange
}: FieldValueSelectProps) {
  const [values, setValues] = useState<SelectOption[]>(initialValues);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleChange = (newValues: any): void => {
    setValues(newValues);
    onChange(newValues);
  };

  return (
    <Select
      isMulti
      value={values}
      name={nameAttr}
      options={options}
      theme={DEFAULT_THEME}
      isDisabled={disabled}
      onChange={handleChange}
      id={`select_${nameAttr}`}
      classNamePrefix="react-select"
      className="react-select-container"
      placeholder={placeholder ?? 'Select...'}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          minWidth: 300,
          width: '100%'
        })
      }}
    />
  );
}
