import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

export function ExportModal({ proceed, show }: any) {
  const [exportName, setExportName] = useState<string>('export.csv');
  const [limit, setLimit] = useState<string>('1000');

  return (
    <Modal animation={true} show={show} onHide={() => proceed(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Export market estimation sample</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3" controlId="export.exportName">
          <Form.Label>Export name</Form.Label>
          <Form.Control
            type="text"
            onChange={ev => setExportName(ev.target.value.trim())}
            value={exportName}
            autoFocus
            size="sm"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="export.limit">
          <Form.Label>Limit </Form.Label>
          <Form.Control type="number" onChange={ev => setLimit(ev.target.value)} value={limit} size="sm" />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="light" onClick={() => proceed(null)}>
          Cancel
        </Button>
        <Button
          size="sm"
          variant="primary"
          disabled={!exportName}
          onClick={() =>
            proceed({
              exportName,
              limit: parseInt(limit)
            })
          }
        >
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
