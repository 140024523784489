import startCase from 'lodash/startCase';
import { useEffect, useRef, useState } from 'react';

import { Integration, isAnyJobRunning } from 'src/apis/integrations';

/**
 * This hook is responsible for polling (2.5s) our jobs endpoint to establish
 * the current job status for the target integration.
 *
 * If the target integration is incorrectly configured, we stop making calls to
 * the API and return this information as `isConnectorFaulty`.
 *
 * We also track whether the component using this hook is mounted to avoid
 * making network calls if the component isn't currently visible. This hack was
 * introduced due to the fact that `Dropdown.Menu` never unmounts from the DOM
 * which causes the component to continuously poll regardless of visibility.
 *
 * @returns
 * - `isSyncing` indicates whether the integration is currently running a job
 * - `isConnectorFaulty` indicates whether the integration is correctly setup
 */
export const useSyncStatus = (integration: Integration): { isSyncing: boolean; isConnectorFaulty: boolean } => {
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [isConnectorFaulty, setIsConnectorFaulty] = useState<boolean>(false);

  const timeoutRef = useRef<number | null>(null);
  const isMountedRef = useRef<boolean>(true);

  const integrationName = startCase(integration.integrationType.toLowerCase());

  useEffect(() => {
    isMountedRef.current = true;

    const checkSyncStatus = async () => {
      if (!isMountedRef.current) return;

      const result = await isAnyJobRunning(integration.id);

      setIsSyncing(result.canStartJob === false);

      // Stop checking integration status; the user has to take action here
      if (!result.canStartJob && result.cantStartJobReason === 'INTEGRATION_INCORRECTLY_CONFIGURED') {
        return setIsConnectorFaulty(true);
      }

      timeoutRef.current = setTimeout(checkSyncStatus, 2500);
    };

    checkSyncStatus();

    return () => {
      isMountedRef.current = false;
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [integration.id, integrationName]);

  return { isSyncing, isConnectorFaulty };
};
