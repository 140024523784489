import { Button, ButtonProps } from 'react-bootstrap';
import { useLinkClickHandler } from 'react-router-dom';

interface NavButtonProps extends ButtonProps {
  to?: string;
}

export default function NavButton({ children = null, to, ...rest }: NavButtonProps) {
  const handleClick = useLinkClickHandler(to as any);
  return (
    <Button {...rest} href={to} onClick={handleClick as any}>
      {children}
    </Button>
  );
}
