import { ClientConfig } from 'src/apis/clients/types';
import API from 'src/utils/API';
import { sleep } from 'src/utils/sleep';

export async function getExportStatus(executionId: string) {
  return await API.get<{ status: string; output: any }>(`/admin/market-estimation/${executionId}`, {});
}

export async function triggerMarketEstimationExport(
  config: Partial<ClientConfig>,
  exportName: string,
  limit: number,
  requestedByUserEmail: string
): Promise<{
  exportId: string;
}> {
  let response = null;
  const payload = { ...config, exportName, limit, requestedByUserEmail, command: 'export' };
  const { executionId } = await API.post<{ executionId: string }>(`/admin/market-estimation`, {}, payload);
  if (executionId) {
    let running = true;
    while (running) {
      await sleep(3000);
      const result = await getExportStatus(executionId);
      if (['FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status)) {
        running = false;
        throw new Error('Export failed');
      } else if (result.status === 'SUCCEEDED') {
        running = false;
        response = result.output;
      }
    }
  }
  return response;
}

export async function fetchExports(): Promise<{ items: any[] }> {
  const result = await API.get<{ items: any[] }>(`/admin/exports/`, {});
  return result;
}

export async function fetchExportSingedDownloadUrl(exportId: string): Promise<string> {
  const result = await API.post<{ signedUrl: string }>(`/admin/exports/${exportId}/download`, {}, {});
  return result.signedUrl;
}
