import { PostHogProvider } from 'posthog-js/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import UserAuthProvider from './auth/UserAuthProvider.tsx';
import { AppRouter } from './Routes';
import { Amplify } from 'aws-amplify';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorMessage from 'src/components/ErrorMessage';
import { Toaster } from 'react-hot-toast';
import 'react-querybuilder/dist/query-builder.css';
import Analytics from './components/Analytics.tsx';
import AppVersionReloader from './components/AppVersionReloader.tsx';

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
        userPoolClientId: import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID
      }
    },
    API: {
      REST: {
        defaultApi: {
          endpoint: import.meta.env.VITE_DEFAULT_API_BASE
        }
      }
    }
  },
  {
    API: {
      REST: {
        headers: async () => {
          const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
          return { Authorization: authToken || '' };
        }
      }
    }
  }
);

function App() {
  return (
    <>
      <Toaster />
      <ErrorBoundary fallback={<ErrorMessage />}>
        <PostHogProvider
          apiKey={import.meta.env.VITE_POSTHOG_PUBLIC_KEY}
          options={{ api_host: import.meta.env.VITE_POSTHOG_HOST }}
        >
          <UserAuthProvider>
            <Analytics />
            <AppRouter />
          </UserAuthProvider>
        </PostHogProvider>
      </ErrorBoundary>
      <AppVersionReloader />
    </>
  );
}

export default App;
