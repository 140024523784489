import { flexRender, Table as ReactTable } from '@tanstack/react-table';
import { Spinner, Table } from 'react-bootstrap';
import { TableLoading } from 'react-bootstrap-table-loading';
import EmptyMessage from '../EmptyMessage';
import { useMemo } from 'react';

export interface TableGridProps {
  table: ReactTable<any>;
  isLoading: boolean;
  emptyMessage?: JSX.Element | string;
  numLoadingRows: number;
  className: string;
  wrapperClassName?: string;
  responsive: boolean;
  showEmptyTable?: boolean;
  /** See the class .table-uniform-width-columns for usage */
  dataColumns?: number;
}

export default function TableGrid({
  table,
  isLoading,
  emptyMessage,
  numLoadingRows,
  className,
  wrapperClassName,
  responsive,
  showEmptyTable,
  dataColumns
}: TableGridProps) {
  const isEmpty = !isLoading && table.getRowModel().rows?.length === 0;

  const isBordered = (className ?? '').includes('table-bordered');

  const emptyComponent = useMemo(() => {
    if (typeof emptyMessage === 'string') {
      return (
        <EmptyMessage
          message={emptyMessage}
          style={{ height: 250, ...(isBordered ? { border: '1px solid #eee', marginBottom: 5 } : {}) }}
        />
      );
    } else {
      return emptyMessage;
    }
  }, [emptyMessage, isBordered]);

  return (
    <div className={`table-loading ${wrapperClassName || ''}`}>
      {isEmpty && !showEmptyTable ? (
        emptyComponent
      ) : (
        <Table size="sm" className={className} data-columns={dataColumns} responsive={responsive}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} style={{ width: (header.column?.columnDef?.meta as any)?.['width'] }}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {isLoading && (table.getRowModel().rows === null || table.getRowModel().rows?.length === 0) ? (
            <TableLoading columns={table.getHeaderGroups()[0].headers.length} lines={numLoadingRows} />
          ) : showEmptyTable && table.getRowModel().rows?.length === 0 ? (
            // do not display table
            <></>
          ) : (
            <tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      )}
      {isLoading && table.getRowModel().rows?.length > 0 && (
        <div className="table-loading-overlay">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!isLoading && showEmptyTable && table.getRowModel().rows?.length === 0 && emptyComponent}
    </div>
  );
}

TableGrid.defaultProps = {
  numLoadingRows: 5,
  emptyMessage: 'No items',
  className: 'table-centered',
  responsive: false
};
