import { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Card, Col, Collapse, Form, InputGroup, Row, Spinner, Table } from 'react-bootstrap';
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { TableLoading } from 'react-bootstrap-table-loading';
import { FiFilter, FiSearch, FiSettings } from 'react-icons/fi';

import { useClient } from 'src/auth';
import ClientCompaniesFilters from '../../../components/Filters/ClientCompaniesFilters/ClientCompaniesFilters.tsx';
import LoadingButton from 'src/components/LoadingButton';
import EmptyMessage from 'src/components/EmptyMessage';
import CardPaginator from 'src/components/Table/CardPaginator';
import CompaniesTableSettingsModal from '../CompaniesTableSettingsModal.tsx';
import TalkTrackModal from 'src/pages/talk-tracks/components/TalkTrackModal';
import { buildColumns } from './buildColumns';
import { TableHeaderCell } from './TableHeaderCell';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';

export default function CompaniesTable({
  companies,
  onCompanySelect,
  isLoading,
  state,
  modifyState,
  schema,
  totalItems
}: any) {
  const [showFilters, setShowFilters] = useState(
    (!!state.filters && state.filters?.rules?.length > 0) || window.location.hash === '#show-filters'
  ); // Initial show if query set, and contains rules upon boot
  const { companiesSchema, appSettings } = useClient();

  const [selectedColumns, setSelectedColumns] = useState(() => {
    const allColumns = companiesSchema.filter(f => !f.isHiddenFromUser).map(f => f.fieldName);
    // if there are columns defined in state
    if (state?.columns) {
      return allColumns.filter(colName => state.columns.includes(colName));
    }
    // If they have settings for the saved column order, we use that, however check that they exist
    if (appSettings.companiesTableFields !== null && isArray(appSettings.companiesTableFields)) {
      return appSettings.companiesTableFields.filter(colName => allColumns.includes(colName));
    }
    return allColumns;
  });

  (window as any).showFilters = () => {
    // Used by help hero tour
    setShowFilters(true);
  };

  const updateStateWithSelectedColumns = useCallback(
    (columns: string[]) => {
      modifyState({ columns: columns });
    },
    [modifyState]
  );

  useEffect(() => {
    const allColumns = companiesSchema.filter(f => !f.isHiddenFromUser).map(f => f.fieldName);
    // if there are columns defined in state
    if (state?.columns && isArray(state.columns)) {
      setSelectedColumns(state.columns.filter((colName: string) => allColumns.includes(colName)));
    }
    // If they have settings for the saved column order, we use that, however check that they exist
    else if (appSettings.companiesTableFields !== null && isArray(appSettings.companiesTableFields)) {
      setSelectedColumns(appSettings.companiesTableFields.filter(colName => allColumns.includes(colName)));
    } else {
      setSelectedColumns(allColumns);
    }
  }, [appSettings.companiesTableFields, companiesSchema, state.columns]);

  const columns = useMemo(
    () => buildColumns(schema, selectedColumns, onCompanySelect),
    [schema, onCompanySelect, selectedColumns]
  );
  const [showSettings, setShowSettings] = useState(false);

  const [newSearch, setNewSearch] = useState<string | undefined>(undefined);
  const [newQuery, setNewQuery] = useState(state.filters);
  const [showSaveTalkTrackModel, setShowSaveTalkTrackModel] = useState(false);
  const [showUpdateTalkTrackModel, setShowUpdateTalkTrackModel] = useState(false);

  const pageCount = Math.ceil(totalItems / state.pageSize);

  const table = useReactTable({
    columns,
    data: companies ?? [],
    manualPagination: true,
    manualSorting: true,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: { pageSize: state.pageSize, pageIndex: state.pageIndex },
      sorting: [{ id: state.sortby, desc: state.sortdirection === 'desc' }]
    }
  });

  const numRules = newQuery?.rules?.length || 0;

  const doSearch = useCallback(
    (newNewSearch?: string) => {
      modifyState({ filters: newQuery, search: newNewSearch ?? newSearch, pageIndex: 0 });
    },
    [modifyState, newQuery, newSearch]
  );

  const setPage = useCallback(
    (pageIndex: number) => {
      modifyState({ pageIndex });
    },
    [modifyState]
  );

  const toggleSortColumn = useCallback(
    (column: string) => {
      let actualColumn = column;
      const isSameColumn = state.sortby === column;
      let newSortDirection = isSameColumn && state.sortdirection === 'desc' ? 'asc' : 'desc';
      if (isSameColumn && state.sortdirection === 'asc' && newSortDirection === 'desc') {
        newSortDirection = 'desc';
        actualColumn = '__company_id';
      }
      modifyState({ sortby: actualColumn, sortdirection: newSortDirection, pageIndex: 0 });
    },
    [state.sortby, state.sortdirection, modifyState]
  );

  const isEmpty = !isLoading && companies?.length === 0;

  const onUpdateTalkTrack = useCallback(
    async (update: { talkTrackId?: string; name: string; filters: any }) => {
      modifyState({ talkTrackName: update.name, talkTrackId: update.talkTrackId, filters: update.filters });
    },
    [modifyState]
  );

  const filtersChanged = useCallback(() => {
    return !(isEqual(state.filters, newQuery) && newSearch === state.search);
  }, [newQuery, newSearch, state.filters, state.search]);

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col xs="auto" className="me-n3">
              <Button variant={showFilters ? 'light' : 'white'} size="sm" onClick={() => setShowFilters(!showFilters)}>
                <FiFilter /> Filter {!!numRules && <Badge className="bg-primary text-light">{numRules}</Badge>}
              </Button>
            </Col>
            <Col className="me-n3">
              <InputGroup size="sm" className="input-group-merge input-group-reverse">
                <Form.Control
                  type="search"
                  placeholder="Search name or domain"
                  defaultValue={state.search}
                  onChange={e => {
                    setNewSearch(e.target.value ? e.target.value : undefined);
                    if (newSearch && !e.target.value) doSearch('');
                  }}
                  className="search"
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      doSearch();
                    }
                  }}
                />
                <InputGroup.Text>
                  <FiSearch />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            {!showFilters && (
              <Col xs="auto" className="me-n3">
                <LoadingButton size="sm" loading={isLoading} onClick={() => doSearch()}>
                  Search
                </LoadingButton>
              </Col>
            )}
            <Col xs="auto">
              <Button size="sm" variant="white" onClick={() => setShowSettings(true)}>
                <FiSettings />
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Collapse in={showFilters} dimension="height">
          <div className="filters-wrapper">
            <ClientCompaniesFilters schema={companiesSchema} defaultQuery={state.filters} setQuery={setNewQuery} />
            <div style={{ padding: '0px 8px' }} className="d-flex justify-content-between">
              <LoadingButton size="sm" loading={isLoading} onClick={() => doSearch()}>
                Search
              </LoadingButton>
              <div>
                <div>
                  {state.currentTalkTrackId && (
                    <LoadingButton
                      variant="white"
                      size="sm"
                      disabled={numRules === 0 || filtersChanged()}
                      overlayText={'Press search and preview results before saving tag'}
                      onClick={() => setShowUpdateTalkTrackModel(true)}
                    >
                      Update tag "{state.talkTrackName}"
                    </LoadingButton>
                  )}
                  &nbsp;
                  <LoadingButton
                    variant="white"
                    size="sm"
                    disabled={numRules === 0 || filtersChanged()}
                    onClick={() => setShowSaveTalkTrackModel(true)}
                    overlayText={'Press search and preview results before saving tag'}
                  >
                    Create new tag
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="table-loading">
          {isEmpty ? (
            <EmptyMessage message="No matching companies could be found" style={{ height: 300 }} />
          ) : (
            <Table
              size="sm"
              className="card-table table-nowrap companies-table table-centered table-fixed-first-column table-sticky-header"
              style={{ position: 'relative' }}
              hover
              responsive
            >
              <thead style={{ position: 'sticky', top: '0' }}>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <TableHeaderCell
                        key={header.id}
                        schema={schema}
                        header={header}
                        toggleSortColumn={toggleSortColumn}
                      />
                    ))}
                  </tr>
                ))}
              </thead>
              {isLoading && (companies === null || companies?.length === 0) ? (
                <TableLoading columns={columns.length} lines={15} />
              ) : (
                <tbody className="fs-base">
                  {table.getRowModel().rows.map(row => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          )}
          {isLoading && (
            <div className="table-loading-overlay">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
        <Card.Footer>
          <CardPaginator pageIndex={state.pageIndex} totalPages={pageCount} setPage={setPage} />
        </Card.Footer>
      </Card>
      <CompaniesTableSettingsModal
        setSelectedColumns={updateStateWithSelectedColumns}
        selectedColumns={selectedColumns}
        schema={companiesSchema}
        show={showSettings}
        onHide={() => setShowSettings(false)}
      />
      <TalkTrackModal
        show={showSaveTalkTrackModel}
        onHide={() => setShowSaveTalkTrackModel(false)}
        header={'Create new tag'}
        filters={state.filters}
        afterSubmit={onUpdateTalkTrack}
      />
      <TalkTrackModal
        show={showUpdateTalkTrackModel}
        onHide={() => setShowUpdateTalkTrackModel(false)}
        header={'Update existing tag'}
        talkTrackId={state.currentTalkTrackId}
        talkTrackName={state.talkTrackName}
        filters={state.filters}
        afterSubmit={onUpdateTalkTrack}
      />
    </>
  );
}
