import { useCallback, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Card, Dropdown } from 'react-bootstrap';
import { listTechnographicsConfigs, optionsForTechnographicsConfigs, refreshTechnographicsConfig } from './api.ts';
import { ColumnHeader } from 'src/components/Table/listTable/ColumnHeader.tsx';
import { ListTable } from 'src/components/Table/listTable/ListTable.tsx';
import { booleanOptions } from 'src/components/Table/listTable/defaultOptions.ts';
import { FiCheck, FiMoreVertical, FiRefreshCw, FiX } from 'react-icons/fi';
import { getReactTableCellRendererForType, renderDateTime } from 'src/components/Table/cellRenderers.tsx';
import { SchemaDataType } from 'src/auth';
import NavButton from 'src/components/NavButton.tsx';
import toast from 'react-hot-toast';
import { TechnographicsPreviewModal } from 'src/pages/staff/technographicsConfig/TechnographicsPreviewModal.tsx';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import LayoutTNG from 'src/layouts/LayoutTNG';

export function TechnographicsConfigListPage() {
  usePageTitle('Technographics Configs');
  const [showPreview, setShowPreview] = useState<{ show: boolean; technology: string }>({
    show: false,
    technology: ''
  });

  const handleRefresh = useCallback(async (id: string) => {
    await toast.promise(refreshTechnographicsConfig(id), {
      success: 'Refresh scheduled!',
      error: e => `Error scheduling refresh: ${e.message}`,
      loading: 'Scheduling refresh...'
    });
  }, []);

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        id: 'technology',
        accessorFn: (row: any) => row.technology,
        enableSorting: true,
        enableColumnFilter: true,
        cell: getReactTableCellRendererForType(SchemaDataType.String),
        header: ({ column }) => (
          <ColumnHeader
            column={column}
            headerName={'Technology'}
            allowSorting={true}
            allowFiltering={true}
            loadOptions={optionsForTechnographicsConfigs}
          />
        )
      },
      {
        id: 'categories',
        accessorFn: (row: any) => row.categories,
        enableSorting: true,
        enableColumnFilter: true,
        cell: getReactTableCellRendererForType(SchemaDataType.Array),
        header: ({ column }) => (
          <ColumnHeader
            column={column}
            headerName={'Categories'}
            allowSorting={true}
            allowFiltering={true}
            loadOptions={optionsForTechnographicsConfigs}
          />
        )
      },
      {
        id: 'isVerified',
        accessorFn: (row: any) => row.isVerified,
        enableSorting: true,
        enableColumnFilter: true,
        cell: getReactTableCellRendererForType(SchemaDataType.Boolean),
        header: ({ column }) => (
          <ColumnHeader
            column={column}
            headerName={'Is Verified'}
            allowSorting={true}
            allowFiltering={true}
            options={booleanOptions}
          />
        )
      },
      {
        id: 'currentRefreshStatus',
        accessorFn: (row: any) => row.currentRefreshStatus,
        enableSorting: true,
        enableColumnFilter: true,
        cell: info => {
          switch (info.getValue()) {
            case 'SUCCESS':
              return <FiCheck color={'green'} />;
            case 'ERROR':
              return <FiX color={'red'} />;
            case 'REFRESHING':
              return <FiRefreshCw color={'blue'} />;
            case 'REFRESH_SCHEDULED':
              return <FiRefreshCw color={'grey'} />;
          }
          return '';
        },
        header: ({ column }) => <ColumnHeader column={column} headerName={'Refresh status'} allowSorting={true} />
      },
      {
        id: 'lastRefreshAt',
        accessorFn: (row: any) => row.lastRefreshAt,
        enableSorting: true,
        cell: renderDateTime(),
        header: ({ column }) => (
          <ColumnHeader column={column} allowSorting={true} headerName={'Last Refresh At'} options={booleanOptions} />
        )
      },
      {
        id: 'Options',
        cell: c => (
          <div className={'d-flex justify-content-center'}>
            <NavButton
              size="sm"
              className={'mx-2'}
              to={`/admin/technographics-configs/${encodeURIComponent(c.row.original.technology)}`}
            >
              Edit
            </NavButton>
            <Dropdown className="position-static">
              <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                <FiMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowPreview({ show: true, technology: c.row.original.technology })}>
                  Preview
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    // @ts-expect-error refreshTable is a method in the state object
                    handleRefresh(c.row.original.technology).then(() => c.table.getState().refreshTable());
                  }}
                >
                  Refresh
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        header: () => <>Options</>
      }
    ],
    [handleRefresh]
  );

  return (
    <LayoutTNG title={'Technographics Configs'}>
      <Card>
        <Card.Body>
          <ListTable
            columns={columns}
            emptyMessage={'No technologies matching search'}
            list={listTechnographicsConfigs}
            pollInterval={5000}
            shouldPollData={!showPreview.show}
            addButton={
              <NavButton size="sm" to={`/admin/technographics-configs/new`}>
                Add new
              </NavButton>
            }
          />
        </Card.Body>
      </Card>
      {showPreview && (
        <TechnographicsPreviewModal
          show={showPreview.show}
          technology={showPreview.technology}
          useSavedDetections
          onHide={() => setShowPreview({ show: false, technology: '' })}
        />
      )}
    </LayoutTNG>
  );
}
