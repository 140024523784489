import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { overrideTrainingExampleLabel } from 'src/pages/predictive-labels/apis.ts';
import { InMemoryTableWithPagination } from 'src/components/Table/InMemoryTableWithPagination.tsx';
import { FiCheck } from 'react-icons/fi';

type MislabellingsProps = {
  modelId: string;
  labels: string[];
  mislabellings: any[];
  show: boolean;
  onHide: () => void;
};

export default function MislabellingsModal({ modelId, labels, mislabellings, show, onHide }: MislabellingsProps) {
  const [mislabellingsEdit, setMislabellingsEdit] = useState<any[]>([]);

  const closeModal = () => {
    onHide();
  };

  function setLabelFor(domain: string, label: string) {
    overrideTrainingExampleLabel(modelId, domain, label, undefined).then(() => {
      setMislabellingsEdit(
        mislabellingsEdit.map(m => {
          if (m.domain === domain) {
            m.targetLabel = label;
          }
          return m;
        })
      );
    });
  }

  function setDoubleVerifiedFor(domain: string, doubleVerified: boolean) {
    overrideTrainingExampleLabel(modelId, domain, undefined, doubleVerified).then(() => {
      setMislabellingsEdit(
        mislabellingsEdit.map(m => {
          if (m.domain === domain) {
            m.doubleVerified = doubleVerified;
          }
          return m;
        })
      );
    });
  }

  const columns = [
    {
      header: 'Domain',
      id: 'domain',
      cell: (c: any) => (
        <a href={`https://${c.row.original.domain}`} target="_blank">
          {c.row.original.domain}
        </a>
      ),
      enableSorting: false
    },
    {
      header: 'Manual Label',
      id: 'targetLabel',
      cell: (c: any) => (
        <div className={'d-flex justify-content-center gap-2'}>
          <div>
            <Dropdown drop={'down-centered'} className="position-static">
              <Dropdown.Toggle size={'sm'} variant="white" id="select-label">
                {c.row.original.targetLabel}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {labels
                  .filter(l => l !== c.row.original.targetLabel)
                  .map((label, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setLabelFor(c.row.original.domain, label);
                      }}
                    >
                      {label}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <Button
              size={'sm'}
              variant={c.row.original.doubleVerified ? 'success' : 'outline-secondary'}
              onClick={() => {
                setDoubleVerifiedFor(c.row.original.domain, !c.row.original.doubleVerified);
              }}
            >
              <FiCheck />
              <FiCheck />
            </Button>
          </div>
        </div>
      ),
      enableSorting: false
    },
    {
      header: 'Predicted Label',
      id: 'predictedLabel',
      accessorFn: (row: any) => row.predictedLabel,
      enableSorting: false
    }
  ];

  useEffect(() => {
    setMislabellingsEdit(mislabellings.sort((a, b) => b.errorRatio - a.errorRatio));
  }, [mislabellings]);

  return (
    <Modal show={show} onHide={closeModal} size={'lg'}>
      <Modal.Header closeButton>Mislabelled examples</Modal.Header>
      <Modal.Body className={'pb-0'}>
        <InMemoryTableWithPagination data={mislabellingsEdit} columns={columns} stayOnPageEvenIfDataChanges={true} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
