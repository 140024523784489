import { Container, Row, Col } from 'react-bootstrap';
import Header from 'src/components/Header';

interface NarrowLayoutProps {
  title: JSX.Element | string;
  preheader?: string;
  titleControls?: JSX.Element;
  children: React.ReactNode;
  maxWidthCols: number;
}

export default function NarrowLayout({ title, preheader, children, titleControls, maxWidthCols }: NarrowLayoutProps) {
  return (
    <div className="main-content">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={maxWidthCols}>
            <Row>
              <Header className="mt-md-5">
                <Header.Body className="border-0">
                  {titleControls && <div className="float-end">{titleControls}</div>}
                  <Header.Pretitle>{preheader ?? 'Settings'}</Header.Pretitle>
                  <Header.Title className="text-truncate">{title}</Header.Title>
                </Header.Body>
              </Header>
            </Row>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

NarrowLayout.defaultProps = {
  maxWidthCols: 8
};
