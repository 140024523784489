/**
 * Helper Util: Copy to clipboard
 *
 * This method copies the provided text to the user's clipboard.
 *
 * If the Clipboard API is not available, we fall back to using `execCommand`.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
 */
export const copyToClipboard = (toCopy: string): void => {
  try {
    void navigator.clipboard.writeText(toCopy);
  } catch {
    const textArea = document.createElement('textarea');
    textArea.value = toCopy;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
};
