import { Button, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { DataBlockFieldType, FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';
import { useCallback, useEffect, useState } from 'react';
import { FiMinusSquare, FiPlusSquare } from 'react-icons/fi';

import { findMapping } from 'src/components/Filters/CCMFilters/services/service.ts';
import { FieldMappingDefinition } from 'src/apis/clients/types.ts';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

type FieldDefinitionsModalProps = {
  show: boolean;
  onHide: () => void;
  fieldDefinitions: FieldMappingDefinition[];
  fieldMapping: FieldMapping[];
  addFields: (newFields: any[]) => void;
};

type ShortFieldType = {
  field: string;
  configId?: string;
  dataBlockFieldType: DataBlockFieldType;
};

export function FieldDefinitionsModal({
  show,
  onHide,
  fieldDefinitions,
  fieldMapping,
  addFields
}: FieldDefinitionsModalProps) {
  const [availableFields, setAvailableFields] = useState<ShortFieldType[]>([]);
  const [fieldsToAdd, setFieldsToAdd] = useState<ShortFieldType[]>([]);
  const [search, setSearch] = useState('');

  const calculateAvailableFields = useCallback(() => {
    return (
      fieldMapping
        // filter out fields that are already in the dataset, but keep ConfigDataBlocksDefinition
        .filter(
          mapping =>
            !fieldDefinitions.find(
              fieldDefinition =>
                fieldDefinition.internalName === mapping.dataBlockField && fieldDefinition.configId === mapping.configId
            )
        )
        .map(f => ({
          field: f.name,
          internalName: f.dataBlockField,
          configId: f.configId,
          dataBlockFieldType: f.dataBlockFieldType
        }))
    );
  }, [fieldDefinitions, fieldMapping]);

  useEffect(() => {
    setAvailableFields(calculateAvailableFields());
  }, [fieldMapping, fieldDefinitions, calculateAvailableFields]);

  const addField = useCallback((fieldMapping: any) => {
    setFieldsToAdd(prev => [...prev, fieldMapping]);
    setAvailableFields(prev => {
      return orderBy(
        prev.filter(f => {
          return !isEqual(f, fieldMapping);
        }),
        'field'
      );
    });
  }, []);

  const removeFromAddField = useCallback((fieldMapping: any) => {
    setFieldsToAdd(prev => {
      return prev.filter(f => !isEqual(f, fieldMapping));
    });
    setAvailableFields(prev => orderBy([...prev, fieldMapping], 'field'));
  }, []);

  const closeModal = useCallback(() => {
    setFieldsToAdd([]);
    setSearch('');
    setAvailableFields(calculateAvailableFields());
    onHide();
  }, [onHide, calculateAvailableFields]);

  const save = useCallback(() => {
    const newList = fieldsToAdd.map(f => {
      const mapping = findMapping(f, fieldMapping, 'to');
      return {
        internalName: mapping?.dataBlockField,
        externalName: mapping?.dataBlockFieldType === DataBlockFieldType.BasicDataBlocks ? mapping.name : '',
        configId: mapping?.configId
      };
    });
    addFields(newList);
    closeModal();
  }, [fieldsToAdd, fieldMapping, addFields, closeModal]);

  return (
    <Modal show={show} onHide={closeModal} size={'lg'}>
      <Modal.Header closeButton>Field Definitions</Modal.Header>
      <Modal.Body>
        <Row className={'mb-2'}>
          <Col xs={6}>
            Search
            <Form.Control type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            Available fields
            <div className="border" style={{ overflowY: 'auto', height: 400 }}>
              <ListGroup variant="flush">
                {availableFields
                  ?.filter(f => f.field.toLowerCase().includes(search))
                  .map((f, index) => {
                    return (
                      <ListGroup.Item style={{ padding: 5 }} key={'available_' + index}>
                        <div className={'m-1'}>
                          <FiPlusSquare
                            size={'1rem'}
                            className={'mx-1'}
                            color={'#479f76'}
                            onClick={() => addField(f)}
                          />
                          <span>{f.field}</span>
                        </div>
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </div>
          </Col>
          <Col xs={6}>
            Fields to add
            <div className="border" style={{ overflowY: 'auto', height: 400 }}>
              <ListGroup variant="flush">
                {fieldsToAdd?.map((f, index) => {
                  return (
                    <ListGroup.Item style={{ padding: 5 }} key={'add_' + index}>
                      <div className={'m-1'}>
                        <FiMinusSquare
                          size={'1rem'}
                          className={'mx-1'}
                          color={'#e35d6a'}
                          onClick={() => removeFromAddField(f)}
                        />
                        <span>{f.field}</span>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" size="sm" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={save}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
