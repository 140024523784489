import { SchemaDataType } from 'src/auth';
import { FilterOperator } from './types';

const EXISTS_OPS = [
  {
    name: FilterOperator.exists,
    label: 'Exists',
    arity: 0
  },
  {
    name: FilterOperator.notExists,
    label: 'Not exists',
    arity: 0
  }
];

export function getOperatorsForField(schema: any[], fieldName: string) {
  const field = schema.find(f => f.fieldName === fieldName);
  if (!field) return [];
  const type = field.type;

  if (type === SchemaDataType.String || type === SchemaDataType.Picklist) {
    return [
      {
        name: FilterOperator.stringEqualOneOf,
        label: 'Is one of'
      },
      {
        name: FilterOperator.stringNotEqualOneOf,
        label: 'Not one of'
      },
      {
        name: FilterOperator.stringContainsOneOf,
        label: 'Contains text'
      },
      {
        name: FilterOperator.stringNotContainsOneOf,
        label: 'Not contains text'
      },
      ...EXISTS_OPS
    ];
  } else if (type === SchemaDataType.Number || type === SchemaDataType.Percentage || type === SchemaDataType.Currency) {
    return [
      {
        name: FilterOperator.equalTo,
        label: 'Equal to'
      },
      {
        name: FilterOperator.notEqualTo,
        label: 'Not equal to'
      },
      {
        name: FilterOperator.gt,
        label: 'Greater than'
      },
      {
        name: FilterOperator.gte,
        label: 'Greater than or equal to'
      },
      {
        name: FilterOperator.lt,
        label: 'Less than'
      },
      {
        name: FilterOperator.lte,
        label: 'Less than or equal to'
      },
      {
        name: FilterOperator.between,
        label: 'Between',
        arity: 2
      },
      ...EXISTS_OPS
    ];
  } else if (type === SchemaDataType.Boolean) {
    return [
      {
        name: FilterOperator.booleanTrue,
        label: 'Is yes',
        arity: 0
      },
      {
        name: FilterOperator.booleanFalse,
        label: 'Is no',
        arity: 0
      },
      ...EXISTS_OPS
    ];
  } else if (type === SchemaDataType.Multipicklist || type === SchemaDataType.Array) {
    return [
      {
        name: FilterOperator.arrayContainsAnyOf,
        label: 'Contains any of'
      },
      {
        name: FilterOperator.arrayNotContainsAnyOf,
        label: 'Not contains any of'
      },
      {
        name: FilterOperator.arrayLengthGt,
        label: 'Has more items than'
      },
      {
        name: FilterOperator.arrayLengthLt,
        label: 'Has less items than'
      },
      ...EXISTS_OPS
    ];
  } else if (type === SchemaDataType.Date) {
    return [
      {
        name: FilterOperator.dateEqual,
        label: 'Equal to'
      },
      {
        name: FilterOperator.dateNotEqual,
        label: 'Not equal to'
      },
      {
        name: FilterOperator.dateSince,
        label: 'Since date'
      },
      {
        name: FilterOperator.dateBefore,
        label: 'Before date'
      },
      {
        name: FilterOperator.dateBetween,
        label: 'Between dates',
        arity: 2
      },
      {
        name: FilterOperator.dateSinceRelative,
        label: 'Since date (relative)'
      },
      {
        name: FilterOperator.dateBeforeRelative,
        label: 'Before date (relative)'
      },
      ...EXISTS_OPS
    ];
  }

  return [];
}
