import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import FieldValueSelect from 'src/components/Filters/Inputs/FieldValueSelect.tsx';
import JsonEditor from 'src/components/JsonEditor/JsonEditor.tsx';
import { useCallback, useEffect, useRef, useState } from 'react';

import { DataBlockDefinition } from 'src/apis/clients/types.ts';
import isEmpty from 'lodash/isEmpty';

type DynamicDataBlocksModalProps = {
  show: boolean;
  isEditing: boolean;
  onHide: () => void;
  dataBlockConfig: any | null;
  addBlock: (block: any) => void;
  dynamicDataBlocksDefinitions: DataBlockDefinition[];
};

export function DataBlockConfigsModal({
  show,
  dynamicDataBlocksDefinitions,
  isEditing,
  onHide,
  dataBlockConfig,
  addBlock
}: DynamicDataBlocksModalProps) {
  const [blockName, setBlockName] = useState<string>();
  const [configId, setConfigId] = useState<string>();
  const [createMode, setCreateMode] = useState<boolean>(true);
  const config = useRef<any>();

  const closeModal = useCallback(() => {
    setBlockName('');
    setConfigId('');
    config.current = {};
    setCreateMode(true);
    onHide();
  }, [onHide]);

  const handleBlockNameChange = useCallback(
    (blockName: string) => {
      setBlockName(blockName);
      // If the config is currently empty, we replace it with the selected block's exampleConfig
      if (isEmpty(config.current)) {
        const dataBlock = dynamicDataBlocksDefinitions.find(block => block.blockName === blockName);
        if (dataBlock && dataBlock.exampleConfig) {
          config.current = dataBlock.exampleConfig;
        }
      }
    },
    [dynamicDataBlocksDefinitions]
  );

  const save = useCallback(() => {
    const block: any = { blockName: blockName, configId: configId, config: config.current };
    if (dataBlockConfig?.index !== undefined) {
      block.index = dataBlockConfig.index;
    }
    addBlock(block);
    closeModal();
  }, [dataBlockConfig?.index, addBlock, blockName, closeModal, configId]);

  useEffect(() => {
    setBlockName(dataBlockConfig?.blockName || '');
    setConfigId(dataBlockConfig?.configId || '');
    config.current = dataBlockConfig?.config || {};
    setCreateMode(!dataBlockConfig?.blockName);
  }, [dataBlockConfig]);

  return (
    <Modal show={show} onHide={closeModal} size={'lg'}>
      <Modal.Header closeButton>Data Block Config</Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={6}>
            <div>
              <h4>Data Block</h4>
            </div>
            <FieldValueSelect
              onChange={(value: any) => handleBlockNameChange(value)}
              value={[blockName]}
              fieldName={'fieldName'}
              allowNew={false}
              // placeholder={}
              // style={}
              disabled={!createMode || !isEditing}
              loadOptions={async (_fieldName, search) =>
                ['Select...', ...dynamicDataBlocksDefinitions.map(db => db.blockName)]
                  .filter(f => f?.toLowerCase().startsWith(search?.toLowerCase()))
                  .map(k => ({ label: k, value: k }))
              }
              isMulti={false}
            />
            <div className={'mt-2'}>
              <h4>Config Id</h4>
            </div>
            <Form.Control
              className={'py-0'}
              style={{
                backgroundColor: !createMode || !isEditing ? 'hsl(0, 0%, 95%)' : 'inherit',
                color: !createMode || !isEditing ? '#6e84a3' : 'inherit'
              }}
              type="text"
              size="sm"
              value={configId || ''}
              aria-label="Config Id"
              placeholder=""
              onChange={e => setConfigId(e.target.value.trim())}
              disabled={!createMode || !isEditing}
              required={true}
            />
          </Col>
          <Col xs={6}>
            <div>
              <h4>Config</h4>
            </div>

            <JsonEditor
              schema={{
                type: 'object'
              }}
              referenceSchema={{}}
              json={config?.current || {}}
              isEditing={isEditing}
              onChange={(update: any) => {
                config.current = update;
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" size="sm" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={save}
          disabled={
            !blockName || blockName === 'Select...' || !configId || !isEditing || !config.current || !config.current
          }
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
