import API from 'src/utils/API.ts';
import { ClientEnrichmentStatus } from 'src/pages/staff/sourcerEnrichment/types.ts';

export async function triggerCheck(clientName: string): Promise<{ executionId: string }> {
  return await API.post<{ executionId: string }>(`/admin/sourcer-enrichment`, {}, { clientName });
}

export async function getLatestResults(clientName: string) {
  return await API.get<ClientEnrichmentStatus>(`/admin/sourcer-enrichment/status`, { clientName });
}

export async function getResults(executionId: string) {
  return await API.get<ClientEnrichmentStatus>(`/admin/sourcer-enrichment/status/`, { executionId });
}
