import { useMemo } from 'react';
import Select, { OptionsOrGroups } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { DEFAULT_THEME } from 'src/utils/react-select';
import { components } from 'react-select';
import trim from 'lodash/trim';

// String list component with optional options and paste support
export function StringListInput({
  value,
  onChange,
  options
}: {
  value: string[] | null;
  onChange: (v: string[]) => void;
  options?: OptionsOrGroups<string, any>;
}) {
  const selectedOptions = useMemo(() => {
    const theValue = (value ?? []) as string[];
    const selected = [];
    // Need to loop over options and also options of options (if groups)
    if (options) {
      for (const opt of options) {
        if (theValue.includes(opt.value)) {
          selected.push(opt);
        }
        if (opt.options) {
          selected.push(...opt.options.filter((o: any) => theValue.includes(o.value)));
        }
      }
      return selected;
    } else {
      return theValue.map((o: string) => ({
        label: o,
        value: o
      }));
    }
  }, [value, options]);

  // Override the keypress on the input element to support list paste
  // Note that this is not ideal as you need to paste in the option VALUES not the labels, but sobeit
  const componentsOverride = {
    Input: (props: any) => (
      <components.Input
        {...props}
        // We cant use oncopy as it only fires if they select text
        onKeyDown={event => {
          if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
            event.preventDefault();
            const text = value?.join('\n');
            if (text) navigator.clipboard.writeText(text);
          }
        }}
        onPaste={event => {
          event.preventDefault();
          const clipboardData = event.nativeEvent.clipboardData?.getData('text/plain');
          console.log(clipboardData);
          if (clipboardData)
            onChange(
              clipboardData
                ?.split('\n')
                .map(trim)
                .filter(v => !!v)
                .slice(0, 100) // Limit 100 items to prevent madness
            );
        }}
      />
    )
  };

  // If options we dont allow creation
  if (options) {
    return (
      <Select
        isMulti
        value={selectedOptions}
        onChange={options => onChange(options.map(o => o.value ?? ''))}
        classNamePrefix="react-select"
        options={options}
        theme={DEFAULT_THEME}
        components={componentsOverride}
      />
    );
  }
  return (
    <CreatableSelect
      isMulti
      formatCreateLabel={s => `Use keyword: ${s}`}
      value={selectedOptions}
      onChange={options => onChange(options.map(o => o.value ?? ''))}
      classNamePrefix="react-select"
      theme={DEFAULT_THEME}
      components={componentsOverride}
    />
  );
}
