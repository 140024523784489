import { useCallback, useEffect, useState } from 'react';
import { estimationResultsEndpoint, triggerEstimation } from 'src/pages/staff/pageElements/api.ts';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import PageLoader from 'src/components/PageLoader.tsx';
import { useLongRunningTask } from 'src/utils/useLongRunningTask.ts';

export function TriggerRefreshModal({
  companyCriteria,
  dataBlockConfigs,
  clientDestination,
  show,
  onSubmit,
  onHide
}: {
  companyCriteria: any;
  dataBlockConfigs: any;
  clientDestination?: string;
  show: boolean;
  onSubmit: any;
  onHide: () => void;
}) {
  const [timePassed, setTimePassed] = useState(false);

  const trigger = useCallback(async () => {
    return (await triggerEstimation(companyCriteria, dataBlockConfigs, clientDestination)).executionId;
  }, [dataBlockConfigs, companyCriteria, clientDestination]);

  const { isLoading, result, error } = useLongRunningTask(trigger, estimationResultsEndpoint, 2000);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimePassed(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal show={show} onHide={onHide}>
      <Form
        onSubmit={ev => {
          ev.preventDefault();
          onSubmit(companyCriteria);
        }}
      >
        <Modal.Header closeButton>{'Trigger Page refresh'}</Modal.Header>
        <Modal.Body style={{ maxHeight: '160px' }}>
          {(isLoading && <PageLoader fullHeight={false} message={'Estimating number of pages matching criteria'} />) ||
            (!!error && (
              <Alert variant={'danger'} className={'mb-0'}>
                {error?.errorCause?.errorMessage || error?.message || JSON.stringify(error)}
              </Alert>
            )) || (
              <div>
                <ul>
                  <li>
                    Estimated # of companies: <strong>{result?.estimate.estimatedCompanies}</strong>
                  </li>
                  <li>
                    Estimated # of domains: <strong>{result?.estimate.estimatedDomains}</strong>
                  </li>
                  <li>
                    Estimated # of pages: <strong>{result?.estimate.estimatedPages}</strong>
                  </li>
                  <li>
                    Estimated cost: <strong>{result?.estimate.estimatedCost?.toFixed(2)}$</strong>
                  </li>
                  <li>
                    Estimated time: <strong>{result?.estimate.estimatedTime}</strong>
                  </li>
                </ul>
              </div>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="white" onClick={onHide}>
            Cancel
          </Button>
          <Button
            disabled={!!error || (!timePassed && (isLoading || result?.estimatedPages === 0))}
            type="submit"
            size="sm"
          >
            Trigger
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
