import { Button, Form, Modal } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import FieldValueSelect from 'src/components/Filters/Inputs/FieldValueSelect.tsx';
import { getClientConfigs } from 'src/apis/clients/apis.ts';
import { ClientConfigShort } from 'src/apis/clients/types.ts';

export type SaveAnalysisModalProps = {
  show: boolean;
  onHide: () => void;
  onSubmit: (name: string, clientName: string) => Promise<void>;
};

export default function SaveAnalysisModal({ show, onHide, onSubmit }: SaveAnalysisModalProps) {
  const [name, setName] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [allClients, setAllClients] = useState<ClientConfigShort[]>([]);

  const onClose = useCallback(() => {
    setName('');
    setClientName('');
    setIsValid(true);
    setIsLoading(false);
    onHide();
  }, [onHide]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      if (!isValid) return;
      setIsLoading(true);
      onSubmit(name, clientName)
        .then(() => onClose())
        .catch(() => setIsLoading(false));
    },
    [isValid, onSubmit, name, clientName, onClose]
  );

  useEffect(() => {
    getClientConfigs().then(configs => setAllClients(configs));
  }, []);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>New Coverage Analysis</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="newCoverageAnalysis">
            <Form.Label>Analysis Name</Form.Label>
            <Form.Control
              onChange={event => {
                const value = event.target.value;
                setName(value);
              }}
              required
              placeholder="Name"
              defaultValue={''}
              autoFocus={true}
              type="text"
            />
            {name?.length > 0 && (
              <Form.Control.Feedback type="invalid">
                Name can only contain alphanumeric characters.
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="newCoverageAnalysisClient">
            <Form.Label>Client Name</Form.Label>
            <FieldValueSelect
              style={{ minHeight: '40px' }}
              value={[clientName]}
              fieldName={'clientName'}
              onChange={value => {
                const isValid = /^[a-z0-9_-]+$/.test(value as unknown as string);
                setIsValid(isValid);
                setClientName(value as unknown as string);
              }}
              loadOptions={async (fieldName, search) => {
                fieldName;
                return allClients
                  .filter(c => c.clientName.startsWith(search))
                  .map(c => ({ value: c.clientName, label: c.clientName }));
              }}
              isMulti={false}
              allowNew={true}
            />
            {!isValid && clientName?.length > 0 && (
              <div style={{ color: 'red' }}>Name can only contain alphanumeric characters.</div>
            )}
          </Form.Group>
          <div style={{ textAlign: 'right' }} className={'mt-3'}>
            <Button size={'sm'} type="submit" disabled={!isValid || isLoading}>
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
