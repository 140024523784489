import { useEffect, useState } from 'react';
import { ProgressBar, ProgressBarProps } from 'react-bootstrap';

interface RollingProgressBarProps {
  value: number;
  className?: string;
  /** Control the speed of the animation */
  delay?: number;
  /** Control the increment used to animate the value */
  incrementStep?: number;
}

/** Custom Component: ProgressBar with rolling progress animation */
export const RollingProgressBar: React.FC<RollingProgressBarProps & ProgressBarProps> = ({
  className,
  value,
  delay = 10,
  incrementStep = 150,
  ...props
}) => {
  const [nowValue, setNowValue] = useState<number>(0);

  useEffect(() => {
    let currentValue = 0;

    const interval = setInterval(() => {
      const remainingValue = value - currentValue;

      if (remainingValue <= 0) {
        clearInterval(interval);
      } else {
        const increment = Math.min(incrementStep, remainingValue);
        setNowValue(prev => prev + increment);
        currentValue += increment;
      }
    }, delay);

    return () => clearInterval(interval);
  }, [delay, incrementStep, value]);

  return <ProgressBar now={nowValue} visuallyHidden {...(className ? { className } : {})} {...props} />;
};
