import { titleCase } from 'src/utils/string';
import { IntegrationFieldMappingInput, RecordType, updateStrategyLabels } from '../types';
import Tag from 'src/components/Tag';
import { OverlayTrigger, Placeholder, Tooltip } from 'react-bootstrap';
import { IoWarning } from 'react-icons/io5';
import { FaCheckCircle } from 'react-icons/fa';
import { useDataDirectoryStore } from '../state';

export interface FieldMappingsDisplayProps {
  mappings: IntegrationFieldMappingInput[];
  recordType: RecordType;
  ccmName: string | undefined;
  fieldName: string;
}

export default function FieldMappingsDisplay({ fieldName, mappings, recordType }: FieldMappingsDisplayProps) {
  const filteredMappings = mappings.filter(m => !!m.externalName);
  if (filteredMappings.length === 0) {
    return <>Not mapped</>;
  }

  const integrationFieldLookup: Record<string, string> = {}; // Use this to work out the first mapping for the field per integration

  return filteredMappings.map(mapping => {
    if (!mapping.externalName) return <></>;
    let isPrimaryIdField = false;
    // If its the domain field and its the FIRST mapping for this integration id then its the primary
    if (fieldName === 'domain' && !integrationFieldLookup[mapping.integrationId]) isPrimaryIdField = true;
    integrationFieldLookup[mapping.integrationId] = mapping.id;
    return (
      <FieldMappingDisplay
        key={mapping.id}
        mapping={mapping}
        recordType={recordType}
        isPrimaryIdField={isPrimaryIdField}
      />
    );
  });
}

type FieldMappingDisplayProps = {
  mapping: IntegrationFieldMappingInput;
  recordType: RecordType;
  isPrimaryIdField: boolean;
};
function FieldMappingDisplay({ mapping, recordType, isPrimaryIdField }: FieldMappingDisplayProps) {
  const updateStrategyLabel = mapping.updateStrategy ? updateStrategyLabels[mapping.updateStrategy] : '';

  const state = useDataDirectoryStore();

  const integration = state.integrations.find(i => i.id === mapping.integrationId);
  if (!mapping.externalName) return <></>;
  if (!integration) return <></>;

  const integrationProperties =
    (recordType === 'contact'
      ? state.integrationContactFieldDescriptions[mapping.integrationId]
      : state.integrationCompanyFieldDescriptions[mapping.integrationId]) || [];

  const isIntegrationPropertiesLoaded = mapping.integrationId in state.integrationCompanyFieldDescriptions;
  const integrationProperty = integrationProperties.find(prop => prop.id === mapping.externalName);

  let errorMessage = '';
  if (!integrationProperty) {
    errorMessage = 'Mapped field does not exist in CRM. You should remove the mapping or create the field.';
  }
  if (!mapping.externalName) {
    errorMessage = 'Please select CRM field to map to';
  }

  return (
    <div className="valign-middle">
      <Tag color="grey" style={{ width: 100, opacity: 0.5 }}>
        {titleCase(integration.integrationType)}
      </Tag>
      &nbsp;
      {isIntegrationPropertiesLoaded ? (
        <>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={props => <Tooltip {...props}>{errorMessage || 'Field is correctly mapped'}</Tooltip>}
          >
            <span>
              {errorMessage ? (
                <IoWarning style={{ fontSize: 18, marginRight: 2, marginTop: -2, color: 'red' }} />
              ) : (
                <FaCheckCircle style={{ fontSize: 16, marginRight: 4, marginTop: -2, color: 'green' }} />
              )}
            </span>
          </OverlayTrigger>
          {integrationProperty?.label ?? mapping.externalName}
          &nbsp; ({updateStrategyLabel})
        </>
      ) : (
        <Placeholder as="span" animation="wave" style={{ fontSize: 14, borderRadius: 3, opacity: 0.25 }}>
          <Placeholder style={{ width: '50%', height: 22 }} />
        </Placeholder>
      )}
      {isPrimaryIdField && (
        <Tag
          className="ms-2"
          color="gold"
          overlayText="This is the field that is used to resolve GoodFit records to existing records in your CRM"
        >
          Primary ID field
        </Tag>
      )}
    </div>
  );
}
