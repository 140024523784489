import { Badge, Col, Dropdown, ListGroup, Row } from 'react-bootstrap';
import { FiMoreVertical } from 'react-icons/fi';
import { useClient } from 'src/auth';
import { ScoringConfig } from 'src/apis/scoring/types';
import { showConfirm } from 'src/utils/modals';
import NavButton from 'src/components/NavButton';

export interface ScoringConfigItemProps {
  scoringConfig: ScoringConfig;
  handleDelete: (id: string) => void;
  handleChangeDefault: (id: string) => void;
}

function navigateToEdit(clientName: string, scoringConfig: ScoringConfig) {
  return `/app/${clientName}/scoring/${scoringConfig.id}`;
}

export default function ScoringConfigItem({
  scoringConfig,
  handleDelete,
  handleChangeDefault
}: ScoringConfigItemProps) {
  const { clientName } = useClient();

  const isScoringConfigUpdating = scoringConfig.processingStatus !== 'Fresh';

  return (
    <>
      <ListGroup.Item key={scoringConfig.id}>
        <Row className="align-items-center">
          <Col xs={7} className="ms-n2">
            <h4>
              {scoringConfig.name} {scoringConfig.isDefault && <Badge className="me-2">Default</Badge>}
              {isScoringConfigUpdating && <Badge bg="secondary">Updating...</Badge>}
            </h4>
          </Col>
          <Col xs="auto" className="ms-auto me-n3">
            <NavButton size="sm" to={navigateToEdit(clientName, scoringConfig)}>
              Edit field
            </NavButton>
          </Col>
          <Col xs="auto">
            <Dropdown align="end">
              <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                <FiMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={async () => {
                    const isSure = await showConfirm(
                      'This will remove this scoring field from your dataset. Are you sure?',
                      'Yes, delete',
                      'No, cancel',
                      'Confirm deletion'
                    );
                    if (isSure) handleDelete(scoringConfig.id);
                  }}
                >
                  Delete scoring field
                </Dropdown.Item>
                <Dropdown.Item disabled={scoringConfig.isDefault} onClick={() => handleChangeDefault(scoringConfig.id)}>
                  Make default score
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </ListGroup.Item>
    </>
  );
}
