import { Button, Modal } from 'react-bootstrap';

export function NavigateWithoutSavingWarning({ blocker, handleSave, canSave = true, message }: any) {
  return (
    <Modal show={blocker.state === 'blocked'} onHide={blocker?.reset}>
      <Modal.Header closeButton>
        <Modal.Title>You have unsaved changes</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message || 'You have not saved your changes. Would you like to save or discard them?'}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => blocker.proceed()}>
          Discard changes
        </Button>
        <Button variant="primary" disabled={!canSave} onClick={() => handleSave().then(blocker.proceed())}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
