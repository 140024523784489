import { useContext } from 'react';

import AuthContext from './AuthContext';
import ClientContext from './ClientContext';

export function useAuth(context = AuthContext) {
  return useContext(context);
}

export function useClient(context = ClientContext) {
  return useContext(context);
}
