import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './styles/theme.scss';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Sticky table headers hack
window.addEventListener(
  'scroll',
  () => {
    const tables = document.querySelectorAll('.table-sticky-header');
    for (const table of tables) {
      const bounds = table.getBoundingClientRect();
      const thead = table.querySelector('thead');
      if (thead) {
        if (bounds.y < 0) {
          thead.style.setProperty('transform', `translateY(${-bounds.y - 1}px)`);
          thead.style.setProperty('box-shadow', '0px 1px 1px rgba(0, 0, 0, 0.09)');
        } else {
          thead.style.removeProperty('transform');
          thead.style.removeProperty('box-shadow');
        }
      }
    }
  },
  { passive: false, capture: false }
);
