import API from 'src/utils/API';
import { useCallback, useState } from 'react';
import { OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import { usePeriodic } from 'src/utils/usePeriodic.ts';

export default function BatchTaskProgressBar({
  id,
  onComplete,
  onError
}: {
  id: string;
  onComplete?: (task: any) => void;
  onError?: (task: any) => void;
}) {
  const [shouldLoad, setShouldLoad] = useState(true);
  const [task, setTask] = useState<{
    itemsTotal: number;
    itemsProcessed: number;
    progress: number;
    itemsDetailedCounts: any;
  }>({
    itemsTotal: 0,
    itemsProcessed: 0,
    progress: 0,
    itemsDetailedCounts: {}
  });

  const fetchData = useCallback(() => {
    API.get<{
      item: any;
    }>('/admin/batch-tasks/items/' + id, {}, {})
      .then(response => {
        if (response.item.status === 'COMPLETE' || response.item.status === 'ERROR') {
          setShouldLoad(false);
        }
        setTask({
          itemsTotal: response.item.itemsTotal,
          itemsProcessed: response.item.itemsProcessed || 0,
          progress: response.item.itemsProcessed / response.item.itemsTotal || 0,
          itemsDetailedCounts: response.item.itemsDetailedCounts
        });
        onComplete && response.item.status === 'COMPLETE' && onComplete(response.item);
        onError && response.item.status === 'ERROR' && onError(response.item);
      })
      .catch(() => {
        setShouldLoad(false);
      });
  }, [id, onComplete, onError]);

  usePeriodic(() => shouldLoad && fetchData(), 2000, [fetchData, shouldLoad], false);

  return (
    <OverlayTrigger
      rootClose
      placement="left"
      overlay={
        <Popover
          style={{
            minWidth: '350px'
          }}
        >
          <Popover.Body>
            <pre>{JSON.stringify(task.itemsDetailedCounts, null, 2)}</pre>
          </Popover.Body>
        </Popover>
      }
    >
      <div className="d-flex justify-content-even align-items-center" style={{ gap: 8 }}>
        <ProgressBar
          className="flex-grow-1"
          now={task.progress * 100}
          label={`${Math.round(task.progress * 100)}%`}
          style={{ minWidth: 150 }}
        />
        <div>
          {task.itemsProcessed} / {task.itemsTotal}
        </div>
      </div>
    </OverlayTrigger>
  );
}
