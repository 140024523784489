import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

function fieldsToTsv(fields: any[]) {
  const lines: string[] = [];
  for (const field of fields) {
    const row = [field.internalName, field.externalName || field.internalName];
    if (field.configId) {
      row.push(field.configId);
    }
    lines.push(row.join('\t'));
  }
  return lines.join('\n');
}

function tsvToFields(tsv: string) {
  const lines = tsv.split('\n');
  const fields = [];

  for (const line of lines) {
    const [internalName, externalName, configId] = line.split('\t');

    if (internalName) {
      const item: any = {
        internalName,
        externalName
      };
      if (externalName != internalName) item.externalName = externalName;
      if (configId) item.configId = configId;
      fields.push(item);
    }
  }

  return fields;
}

export default function FieldDefinitionsPasteFromSheet({
  fieldDefinitions,
  updateFieldDefinitions,
  show,
  onHide
}: any) {
  const [tsv, setTsv] = useState(fieldsToTsv(fieldDefinitions));

  // Update default text if config changes
  useEffect(() => setTsv(fieldsToTsv(fieldDefinitions)), [fieldDefinitions]);

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>Paste from sheet</Modal.Header>
      <Modal.Body>
        <p>
          Paste in from copy from google sheet (Tab separated values). There must be 3 columns: internalName,
          externalName and configId (optional).
        </p>
        <Form.Control
          style={{ fontSize: 11, fontFamily: 'monospace' }}
          value={tsv}
          as="textarea"
          rows={20}
          onChange={ev => setTsv(ev.target.value)}
        ></Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            updateFieldDefinitions(tsvToFields(tsv));
            onHide();
          }}
        >
          Update config
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
