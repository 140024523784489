import { useState } from 'react';
import { Form, Fade, Collapse } from 'react-bootstrap';
import { Debouncer } from 'src/utils/Debouncer';

const changeDebouncer = new Debouncer(500);

function parseDomains(value: string) {
  return (value ?? '')
    .split('\n')
    .map((s: string) => s.trim())
    .filter((s: string) => !!s);
}

export function CheckToRevealDomainsInput({ name, label, initialValue, onChange, maxDomains = 1000 }: any) {
  const [checked, setChecked] = useState(!!initialValue);
  const [value, setValue] = useState((initialValue ?? []).join('\n'));
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <div>
      <Form.Check
        type="checkbox"
        checked={checked}
        onChange={ev => {
          setChecked(ev.target.checked);
          const domains = parseDomains(value).slice(0, maxDomains);
          if (onChange) onChange(ev.target.checked ? domains : null);
        }}
        name={name}
        id={name}
        label={label}
      />
      <Fade in={checked} timeout={300} unmountOnExit={false}>
        <Collapse in={checked}>
          <div className="ps-4 py-3">
            <Form.Control
              as="textarea"
              rows={6}
              style={{ maxWidth: 300 }}
              value={value}
              onChange={ev => {
                setValue(ev.target.value);
                const allDomains = parseDomains(ev.target.value).slice(0, maxDomains);
                setErrorMessage(allDomains.length >= maxDomains ? 'Too many domains' : null);
                const domains = allDomains.slice(0, maxDomains);
                console.log('allDomains.length', allDomains.length);

                changeDebouncer.schedule(() => {
                  // Prevent updating each keypress, 500ms grace
                  if (onChange) onChange(checked ? domains : null);
                });
              }}
              size="sm"
            />
            <Form.Text>
              Please paste domains. Max {maxDomains}.
              {errorMessage && <span style={{ color: 'red' }}> {errorMessage}</span>}
            </Form.Text>
          </div>
        </Collapse>
      </Fade>
    </div>
  );
}
