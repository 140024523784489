import classNames from 'classnames';
import { Nav, NavProps } from 'react-bootstrap';
import { BsPrefixProps } from 'react-bootstrap/esm/helpers';

interface HeaderElementProps extends BsPrefixProps, React.HTMLAttributes<HTMLElement> {
  className?: string;
}

function Header({ className, ...props }: HeaderElementProps) {
  const classes = classNames('header', className);
  return <div className={classes} {...props}></div>;
}

// @todo AvatarTop header-avatar-top
// @todo ImageTop header-img-top

Header.Pretitle = function ({ className, ...props }: HeaderElementProps) {
  const classes = classNames('header-pretitle', className);
  return <h6 className={classes} {...props}></h6>;
};

Header.Title = function ({ className, ...props }: HeaderElementProps) {
  const classes = classNames('header-title', className);
  return <h1 className={classes} {...props}></h1>;
};

Header.Body = function ({ className, ...props }: HeaderElementProps) {
  const classes = classNames('header-body', className);
  return <div className={classes} {...props}></div>;
};

Header.Footer = function ({ className, ...props }: HeaderElementProps) {
  const classes = classNames('header-footer', className);
  return <div className={classes} {...props}></div>;
};

Header.Subtitle = function ({ className, ...props }: HeaderElementProps) {
  const classes = classNames('header-subtitle', className);
  return <p className={classes} {...props}></p>;
};

Header.Tabs = function ({ className, ...props }: NavProps) {
  const classes = classNames('header-tabs', className);
  return <Nav variant="tabs" className={classes} {...props} />;
};

export default Header;
