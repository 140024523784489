import { RecordType } from 'src/pages/data-directory/types';
import {
  CheckStartResult,
  ErrorDetails,
  ErrorsSummaryType,
  ErrorSummaryRow,
  Integration,
  IntegrationJobsType,
  IntegrationType
} from './types';
import API from 'src/utils/API.ts';

export async function loadIntegrations(): Promise<Integration[]> {
  return await API.get<Integration[]>('/app/integrations', {}, {});
}

export async function createIntegration(createBody: any) {
  return await API.post<Integration>(`/app/integrations`, {}, createBody);
}

export async function updateIntegration(integrationId: string, update: Partial<Integration>) {
  return await API.put<Integration>(`/app/integrations/${integrationId}`, {}, update);
}

export async function loadIntegrationJobs(
  connectorType: IntegrationType,
  limit?: number,
  offset?: number
): Promise<IntegrationJobsType> {
  return await API.get<IntegrationJobsType>(
    `/app/integration-jobs`,
    { integrationType: connectorType, limit, offset },
    {}
  );
}

export async function isAnyJobRunning(integrationId: string): Promise<CheckStartResult> {
  return await API.post<CheckStartResult>(`/app/integration-jobs/check-start`, {}, { integrationId });
}

export async function startJob(
  integrationId: string,
  filters?: any,
  segmentIds?: string[]
): Promise<{ started: boolean }> {
  return await API.post<{ started: boolean }>(
    `/app/integration-jobs/start`,
    {},
    {
      integrationId,
      filters: filters ? filters : [],
      ...(segmentIds ? { segmentIds } : {})
    }
  );
}

export async function cancelIntegrationJob(jobId: string) {
  return await API.post(`/app/integration-jobs/stop`, {}, { jobId });
}

export async function loadErrorsSummary(integrationType: IntegrationType, limit?: number, offset?: number) {
  return await API.get<ErrorsSummaryType>(
    '/app/integration-jobs/errors/summary',
    { integrationType, limit, offset },
    {}
  );
}

export async function loadErrorsDetails(errorSummaryRow: ErrorSummaryRow, limit: number, offset: number) {
  return await API.get<ErrorDetails[]>(
    '/app/integration-jobs/errors/details',
    {
      recordType: errorSummaryRow.recordType as string,
      field: errorSummaryRow.field,
      errorCode: errorSummaryRow.errorCode,
      integrationType: errorSummaryRow.integrationType,
      limit,
      offset
    },
    {}
  );
}

export async function loadIntegrationRecordURL(integrationId: string, externalId: string) {
  const result = await API.post<{ externalUrl: string }>(
    `/app/integrations/${integrationId}/external-record-url`,
    {},
    {
      externalId
    }
  );
  return result?.externalUrl;
}

export async function loadIntegrationFieldNames(integrationId: string) {
  return await API.get(`/app/integrations/${integrationId}/describe-external-fields`, {});
}

export async function startCreateExternalFieldProcess(
  integration: Integration,
  recordType: RecordType,
  internalFieldName: string,
  externalFieldName: string,
  externalFieldType: string,
  picklistValues: string[]
): Promise<{ jobId: string }> {
  const result = await API.post<{ jobId: string }>(
    `/app/integrations/${integration.id}/create-external-fields`,
    {},
    {
      fields: [
        {
          recordType,
          internalFieldName: internalFieldName,
          externalFieldName,
          externalFieldType,
          picklistValues
        }
      ]
    }
  );
  return result;
}

export async function checkCreateExternalFieldProcess(
  integration: Integration,
  jobId: string
): Promise<{ status: string; results: any[] }> {
  return await API.get<{ status: 'SUCCEEDED' | 'FAILED'; results: any[] }>(
    `/app/integrations/${integration.id}/create-external-fields/${jobId}`,
    {}
  );
}
