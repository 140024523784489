import toast from 'react-hot-toast';
import { loadIntegrationRecordURL } from 'src/apis/integrations';
import { FilterOperator } from 'src/components/Filters/ClientCompaniesFilters/types';

// Combine 'search' and 'filters' into one set of filters
export function combineFiltersWithSearch(search: string | undefined, filters: any) {
  if (search) {
    const searchFilter = {
      id: 'search',
      combinator: 'or',
      rules: [
        {
          id: 'name_search',
          field: 'name',
          operator: FilterOperator.stringContains,
          value: search
        },
        {
          id: 'domain_search',
          field: 'domain',
          operator: FilterOperator.stringContains,
          value: search
        }
      ]
    };
    if (filters !== undefined) {
      return {
        id: 'actualFilters',
        combinator: 'and',
        rules: [filters, searchFilter]
      };
    } else {
      return searchFilter;
    }
  } else {
    return filters;
  }
}

export async function navigateToCRMRecord(integrationId: string, externalId: string) {
  toast
    .promise(loadIntegrationRecordURL(integrationId, externalId), {
      loading: 'Fetching Record URL',
      error: 'Could not fetch URL for record',
      success: 'Opening window'
    })
    .then(url => {
      if (url) window.open(url);
      if (!url) toast.error('Could not fetch URL for record');
    });
}
