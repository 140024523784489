import { ClientContextType } from 'src/auth';

export function generateAlertsForClient(client: ClientContextType) {
  const alerts = [];

  if (client.creditBalances?.CONTACT !== null && client.creditBalances?.CONTACT <= 0) {
    alerts.push('You have run out of contact credits.');
  }
  if (client.creditBalances?.ENRICHMENT !== null && client.creditBalances?.ENRICHMENT <= 0) {
    alerts.push('You have run out of enrichment credits.');
  }
  if (client.limits.companies && client.usageCounts.companies > client.limits.companies) {
    alerts.push('You are over your account company limit.');
  }
  if (client.limits.contacts && client.usageCounts.contacts > client.limits.contacts) {
    alerts.push('You are over your account contact limit.');
  }

  return alerts;
}
