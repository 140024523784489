import { Offcanvas } from 'react-bootstrap';

import { titleCase } from 'src/utils/string';
import { Integration, IntegrationType } from 'src/apis/integrations';
import { WebhookForm } from './forms/WebhookForm';
import { SalesforceHubspotForm } from './forms/SalesforceHubspotForm';
import { AutoSyncSettingsForm } from './forms/AutoSyncSettingsForm';
import { PipeDriveForm } from './forms/PipeDriveForm';

export interface ConnectorSettingsOffcanvasProps {
  show?: boolean;
  integration: Integration | null;
  onHide?: () => void;
}

function IntegrationSettingsForm({ integration }: { integration: Integration }) {
  switch (integration.integrationType) {
    case IntegrationType.WEBHOOK:
      return <WebhookForm integration={integration} />;
    case IntegrationType.SALESFORCE:
    case IntegrationType.HUBSPOT:
      return <SalesforceHubspotForm integration={integration} />;
    case IntegrationType.PIPEDRIVE:
      return <PipeDriveForm integration={integration} />;
    default:
      return null;
  }
}

export default function ConnectorSettingsOffcanvas({ show, integration, onHide }: ConnectorSettingsOffcanvasProps) {
  if (!integration || !integration.integrationType) return <></>;
  if (!integration.id && integration.integrationType != 'WEBHOOK') return <>Not connected</>;

  return (
    <Offcanvas show={show} placement="end" style={{ minWidth: 600 }} onHide={onHide}>
      <Offcanvas.Header closeButton>Configure: {titleCase(integration.integrationType)}</Offcanvas.Header>
      <Offcanvas.Body>
        <AutoSyncSettingsForm integration={integration} />
        <IntegrationSettingsForm integration={integration} />
      </Offcanvas.Body>
    </Offcanvas>
  );
}
