import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, Dropdown, Modal } from 'react-bootstrap';
import PageLoader from 'src/components/PageLoader';
import ScoringAnalysisFindingsTable from './components/results/ScoringAnalysisFindingsTable';
import './scoring-analysis.scss';
import { FieldGroup, FieldValueFinding } from './types';
import { computeScoringRuleForFinding, enrichBasicScore, groupFindings } from './data';
import { useClient } from 'src/auth';
import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import LoadingButton from 'src/components/LoadingButton';
import { usePageTitle } from 'src/utils/usePageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { pluralize } from 'src/utils/string';
import { ScoringRule } from 'src/apis/scoring/types';
import ScoringPreview from 'src/pages/scoring/components/editor/ScoringPreview';
import { triggerCsvExport } from 'src/apis/csvExports/apis';
import toast from 'react-hot-toast';
import { loadScoringAnalysisReport } from './apis';
import ErrorMessage from 'src/components/ErrorMessage';
import { appClientUrl, encodeState } from 'src/utils/urls';
import { AISummaryBar } from './components/results/AISummaryBar';
import orderBy from 'lodash/orderBy';

const MAX_RULES = 100;
let loadTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

export default function ScoringAnalysisResultsPage() {
  const navigate = useNavigate();
  const client = useClient();
  const { scoringAnalysisReportId } = useParams();
  const [scoringAnalysisReport, setScoringAnalysisReport] = useState<any>(null);
  const [scoringAnalysisResults, setScoringAnalysisResults] = useState<any>(null);
  const [selectedRuleIds, setSelectedRuleIds] = useState<string[]>([]);
  const [groupedFindings, setGroupedFindings] = useState<FieldGroup[]>([]);
  const [previewRules, setPreviewRules] = useState<ScoringRule[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [showDomains, setShowDomains] = useState<string[] | null>(null);

  usePageTitle('Scoring analysis results');

  const loadScoringAnalysisResults = useCallback(() => {
    if (scoringAnalysisReportId) {
      loadScoringAnalysisReport(scoringAnalysisReportId).then(response => {
        const report = response.report;
        setScoringAnalysisReport(report);
        const result = report.results;
        if (report.status === 'Processed') {
          const allScores = enrichBasicScore(result.scores, client.companiesSchema);
          result.scores = allScores;

          setScoringAnalysisResults(result);

          const groups = groupFindings(allScores, client.companiesSchema);
          setGroupedFindings(groups);

          const impactfulGroups = groups.filter(g => g.isImpactful);
          const newSelectedRuleIds: string[] = [];
          for (const g of impactfulGroups) {
            for (const f of g.findings) {
              if (f.isDefaultActive) newSelectedRuleIds.push(f.id);
            }
          }
          setSelectedRuleIds(newSelectedRuleIds);
        } else {
          // Reload in 5s
          if (loadTimeout) clearTimeout(loadTimeout);
          loadTimeout = setTimeout(loadScoringAnalysisResults, 5000);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Dont want to retrigger when config reloads

  useEffect(() => {
    loadScoringAnalysisResults();
  }, [loadScoringAnalysisResults]);

  const resetAllSelections = () => setSelectedRuleIds([]);

  const toggleRule = useCallback(
    (ruleId: string, isSelected: boolean) => {
      if (isSelected) {
        setSelectedRuleIds([...selectedRuleIds, ruleId]);
      } else {
        setSelectedRuleIds(selectedRuleIds.filter(rId => rId !== ruleId));
      }
    },
    [selectedRuleIds]
  );

  const addAllSelectedRules = useCallback(() => {
    const allScores = scoringAnalysisResults.scores as FieldValueFinding[];
    const selectedScores = allScores.filter(score => selectedRuleIds.includes(score.id));
    const selectedRules = orderBy(
      selectedScores.map(s => {
        return computeScoringRuleForFinding(s);
      }),
      'field'
    );
    const state = { rules: selectedRules, associatedScoringAnalysisReportId: scoringAnalysisReportId };
    const stateEncoded = encodeState(state);
    navigate(`/app/${client.clientName}/scoring/new#state=${stateEncoded}`);
  }, [client.clientName, navigate, scoringAnalysisResults?.scores, selectedRuleIds, scoringAnalysisReportId]);

  const handleShowPreview = useCallback(() => {
    const allScores = scoringAnalysisResults.scores as FieldValueFinding[];
    const selectedScores = allScores.filter(score => selectedRuleIds.includes(score.id));
    const selectedRules = orderBy(
      selectedScores.map(s => {
        return computeScoringRuleForFinding(s);
      }),
      'field'
    );
    setPreviewRules(selectedRules);
    setShowPreview(true);
  }, [scoringAnalysisResults?.scores, selectedRuleIds]);

  const triggerExport = useCallback(() => {
    toast.promise(
      triggerCsvExport('SCORING_ANALYSIS', { scoringAnalysisReportId }),
      {
        loading: 'Starting export...',
        success: (
          <span>
            CSV export has been started, you will receive an email with the link to download it shortly. You can also
            monitor progress on the <a href={`/app/${client.clientName}/exports`}>exports page</a>
          </span>
        ),
        error: 'Could not start CSV export'
      },
      { success: { duration: 5000 } }
    );
  }, [client.clientName, scoringAnalysisReportId]);

  if (scoringAnalysisReport === null) {
    return <PageLoader fullHeight message="Loading..." />;
  }

  if (scoringAnalysisReport.status === 'Error') {
    return (
      <ErrorMessage
        message={scoringAnalysisReport?.results?.errorMessage ?? 'There was an error calculating this report'}
        actions={
          <Button size="sm" onClick={() => navigate(appClientUrl(`/scoring/analysis/edit/${scoringAnalysisReportId}`))}>
            Edit settings
          </Button>
        }
      />
    );
  }

  if (scoringAnalysisReport.status !== 'Processed') {
    return <PageLoader fullHeight message="Calculating Scoring Analysis" />;
  }

  const numDomainsFound = scoringAnalysisResults?.goodSetSize;
  const numDomainsNotFound = scoringAnalysisResults?.goodDomainsNotFound?.length;

  const numOtherDomainsFound = scoringAnalysisResults?.otherSetSize;
  const numOtherDomainsNotFound = scoringAnalysisResults?.otherDomainsNotFound?.length;

  const isVsOtherMode = scoringAnalysisReport?.inputs?.mode === 'vsOther';

  return (
    <NarrowLayout title={<>{scoringAnalysisReport?.name}</>} preheader="" maxWidthCols={12}>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          Scoring analysis results
          <div style={{ minWidth: 500, alignItems: 'center', gap: 8 }} className="d-flex justify-content-end">
            {selectedRuleIds.length} {pluralize(selectedRuleIds.length, 'rule', 'rules')} selected
            <Button size="sm" variant="white" onClick={handleShowPreview}>
              Preview scoring
            </Button>
            <Button size="sm" variant="white" disabled={selectedRuleIds.length === 0} onClick={resetAllSelections}>
              Unselect all
            </Button>
            <LoadingButton
              size="sm"
              variant="primary"
              onClick={addAllSelectedRules}
              disabled={selectedRuleIds.length === 0 || selectedRuleIds.length > MAX_RULES}
              overlayText={selectedRuleIds.length > MAX_RULES ? `You must select less than ${MAX_RULES} rules` : ''}
            >
              Save as scoring field
            </LoadingButton>
            <Dropdown>
              <Dropdown.Toggle variant="white" size="sm"></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={triggerExport}>Export findings as CSV</Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate(appClientUrl(`/scoring/analysis/edit/${scoringAnalysisReport.id}`));
                  }}
                >
                  Edit report
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Header>
        <Card.Body>
          <Alert>
            We found{' '}
            <a
              href="#"
              onClick={ev => {
                ev.preventDefault();
                setShowDomains(scoringAnalysisResults.goodDomainsFound);
              }}
            >
              {numDomainsFound ?? 0} companies
            </a>{' '}
            in your dataset matching the target domains you supplied.
            {numDomainsNotFound > 0 ? (
              <>
                {' '}
                <a
                  href="#"
                  onClick={ev => {
                    ev.preventDefault();
                    setShowDomains(scoringAnalysisResults.goodDomainsNotFound);
                  }}
                >
                  {numDomainsNotFound} {pluralize(numDomainsNotFound, 'domain', 'domains')}
                </a>
                {pluralize(numDomainsNotFound, ' was ', ' were ')}
                not found.
              </>
            ) : (
              ''
            )}
            {isVsOtherMode && (
              <>
                {' '}
                For the other set, we found{' '}
                <a
                  href="#"
                  onClick={ev => {
                    ev.preventDefault();
                    setShowDomains(scoringAnalysisResults.otherDomainsFound);
                  }}
                >
                  {numOtherDomainsFound ?? 0} companies
                </a>{' '}
                in your dataset matching the other domains you supplied.
                {scoringAnalysisResults.otherDomainsNotFound?.length > 0 ? (
                  <>
                    {' '}
                    <a
                      href="#"
                      onClick={ev => {
                        ev.preventDefault();
                        setShowDomains(scoringAnalysisResults.otherDomainsNotFound);
                      }}
                    >
                      {numOtherDomainsNotFound} {pluralize(numOtherDomainsNotFound, 'domain', 'domains')}
                    </a>
                    {pluralize(numOtherDomainsNotFound, ' was ', ' were ')}
                    not found.
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </Alert>
          <AISummaryBar scoringAnalysisReport={scoringAnalysisReport} />
          <ScoringAnalysisFindingsTable
            toggleRule={toggleRule}
            groupedFindings={groupedFindings}
            selectedRuleIds={selectedRuleIds}
            setSelectedRuleIds={setSelectedRuleIds}
            scoringAnalysisReport={scoringAnalysisReport}
          />
        </Card.Body>
      </Card>

      <Modal className="ca-preview-modal" show={showPreview} onHide={() => setShowPreview(false)} size="xl">
        <Modal.Header closeButton>Scoring Preview</Modal.Header>
        <ScoringPreview rules={previewRules} />
      </Modal>

      <Modal show={!!showDomains} onHide={() => setShowDomains(null)}>
        <Modal.Header closeButton>{showDomains?.length} Domains</Modal.Header>
        <Modal.Body>
          <pre>{showDomains ? showDomains.join('\n') : ''}</pre>
        </Modal.Body>
      </Modal>
    </NarrowLayout>
  );
}
