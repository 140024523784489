export class Debouncer {
  protected timeout: number = 0;
  constructor(protected delay: number) {}

  public schedule(fn: CallableFunction, customDelay?: number) {
    this.cancel();
    this.timeout = setTimeout(fn, customDelay ?? this.delay);
  }

  public immediate(fn: CallableFunction) {
    this.cancel();
    fn();
  }

  public cancel() {
    if (this.timeout) clearTimeout(this.timeout);
  }
}
