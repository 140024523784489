import { Card, Form } from 'react-bootstrap';
import { Toggle } from 'src/pages/staff/clientConfigs/components/Toggle.tsx';
import { ClientConfigErrors } from 'src/pages/staff/clientConfigs/validation/types.ts';
import { lifecycleLabels } from '../lifecycles';
import moment from 'moment';

type LifecycleTabProps = {
  schema: any;
  clientConfig: any;
  isEditing: boolean;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
  errors: ClientConfigErrors | null;
};

export function LifecycleTab({ clientConfig, isEditing, handleChangeAndCommit }: LifecycleTabProps) {
  return (
    <Card>
      <Card.Body>
        <div className="d-grid gap-2">
          <Form.Group className="mb-3">
            <Form.Label htmlFor="lifecycleStage">Lifecycle Stage</Form.Label>
            <Form.Select
              style={{ maxWidth: 300 }}
              id="lifecycleStage"
              size="sm"
              disabled={!isEditing}
              value={clientConfig.lifecycleStage}
              onChange={ev => handleChangeAndCommit(ev.target.value, 'lifecycleStage')}
            >
              {Object.entries(lifecycleLabels).map(([value, label]) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
            <div className="text-muted mt-2">
              Use this to organise clients so we know what stage of their lifecycle they are in.
            </div>
          </Form.Group>
          <hr />
          <div>
            <Toggle
              flag={!!clientConfig.scheduledForDeletionAt}
              label="Schedule for deletion"
              isEditing={isEditing}
              id="client-config-scheduledForDeletionAt"
              onChange={(checked: any) => {
                let updateValue: any = null;
                if (checked) {
                  updateValue = moment().utc().add(7, 'days').toDate();
                }
                handleChangeAndCommit(updateValue, 'scheduledForDeletionAt');
              }}
            />
            <div className="text-muted">
              Checking this checkbox will schedule the client config for deletion and cleanup after 7 days from now.
              Within this time limit you can unschedule it by unchecking the checkbox.
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
