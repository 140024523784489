import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { ConnectorSettingsOffcanvasProps } from '..';
import { useIntegrationStore } from '../../../integrationsStore';
import { integrationToast } from '../utils';

export function PipeDriveForm({ integration }: ConnectorSettingsOffcanvasProps) {
  const [credentials, setCredentials] = useState(integration?.credentials);
  const updateConnector = useIntegrationStore(state => state.updateIntegration);
  const [debouncedCredentials, setDebouncedCredentials] = useState<any>();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      debouncedCredentials !== credentials && setDebouncedCredentials(credentials);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [debouncedCredentials, credentials]);

  useEffect(() => {
    if (debouncedCredentials && debouncedCredentials !== integration?.credentials) {
      integrationToast(async () => {
        return await updateConnector(integration?.id as string, {
          credentials: credentials
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCredentials]);

  return (
    <>
      <Form.Group className="mb-3" controlId="apiKey">
        <Form.Label>Api Key</Form.Label>
        <Form.Control
          onChange={event => {
            setCredentials({ apiKey: event.target.value ?? null });
          }}
          value={credentials?.apiKey ?? ''}
        ></Form.Control>
        <Form.Text>
          The API key to authorize GoodFit to access your Pipedrive account. You can find this in your Pipedrive
        </Form.Text>
      </Form.Group>
    </>
  );
}
