import {
  PredictiveLabelsModel,
  PredictiveLabelsModelLabelConfig,
  PredictiveLabelsModelMode,
  TrainingExample
} from 'src/pages/predictive-labels/types.ts';
import { useState } from 'react';
import { Alert, Button, Card, Dropdown, Table } from 'react-bootstrap';
import { FiMoreVertical } from 'react-icons/fi';
import { TrainingExamplesModal } from 'src/pages/predictive-labels/components/TrainingExamplesModal.tsx';
import LabelModal from 'src/pages/predictive-labels/components/LabelModal.tsx';

export function LabelsCard({
  model,
  labels,
  setLabels,
  trainingExamples,
  setTrainingExamples,
  has10ValidExamplesPerLabel,
  domainsInMultipleLabels
}: {
  model: PredictiveLabelsModel;
  labels: PredictiveLabelsModelLabelConfig[];
  setLabels: (
    value:
      | ((prevState: PredictiveLabelsModelLabelConfig[]) => PredictiveLabelsModelLabelConfig[])
      | PredictiveLabelsModelLabelConfig[]
  ) => void;
  trainingExamples: TrainingExample[];
  setTrainingExamples: (value: ((prevState: TrainingExample[]) => TrainingExample[]) | TrainingExample[]) => void;
  has10ValidExamplesPerLabel: boolean;
  domainsInMultipleLabels: string[];
}) {
  const [openNewLabelModalFor, setOpenNewLabelModalFor] = useState<
    { label: string; description: string } | undefined
  >();
  const [openTrainingExamplesModalFor, setOpenTrainingExamplesModalFor] = useState<{ label: string } | undefined>();

  function upsertLabel(oldLabel: string, newLabel: string, description: string) {
    if (labels.find(label => label.label === oldLabel)) {
      setLabels(labels =>
        labels.map(label =>
          label.label === oldLabel ? { label: newLabel, description, trainingExamples: label.trainingExamples } : label
        )
      );
    } else {
      setLabels(labels => [...labels, { label: newLabel, description, trainingExamples: [] }]);
    }
  }

  function canDeleteLabel(label: string) {
    if (model.mode === PredictiveLabelsModelMode.BINARY) {
      return false;
    }
    return label !== 'Other';
  }

  function deleteLabel(name: string) {
    setLabels(labels => labels.filter(label => label.label !== name));
  }

  function setLabelExamples(label: string, newTrainingExamples: TrainingExample[]) {
    const trainingExamplesToSet = trainingExamples.filter(example => example.targetLabel !== label);
    const nonEmptyExamples = newTrainingExamples.filter(example => example.domain?.length > 0);
    trainingExamplesToSet.push(...nonEmptyExamples);
    setTrainingExamples(trainingExamplesToSet);
    setLabels(prevState =>
      prevState.map(l => {
        if (l.label === label) {
          l.trainingExamples = nonEmptyExamples;
        }
        return l;
      })
    );
  }

  return (
    <>
      {!has10ValidExamplesPerLabel && (
        <Alert variant={'danger'}>
          <Alert.Heading>Error</Alert.Heading>
          <p>One or more labels is missing 10 valid examples, you can save, but cannot proceed to training</p>
        </Alert>
      )}
      {domainsInMultipleLabels.length > 0 && (
        <Alert variant={'danger'}>
          <Alert.Heading>Error</Alert.Heading>
          <p>Every domain must belong to only a single label. Following domains fail this check:</p>
          <ul>
            {domainsInMultipleLabels.slice(0, 10).map((label, index) => (
              <li key={`label-${index}`}>{label}</li>
            ))}
            {domainsInMultipleLabels.length > 10 && <li>...</li>}
          </ul>
        </Alert>
      )}

      <Card>
        <Card.Header>
          Labels
          <div className={'d-flex justify-content-end'}>
            <Button
              variant="secondary"
              size={'sm'}
              disabled={model.mode === PredictiveLabelsModelMode.BINARY}
              onClick={() => setOpenNewLabelModalFor({ label: `New label ${labels.length}`, description: '' })}
            >
              Add label
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table bordered>
            <thead>
              <tr>
                <th style={{ width: '15%' }}>Label</th>
                <th>Description</th>
                <th style={{ width: '25%' }}>Training Examples</th>
                <th style={{ width: '5%' }}></th>
              </tr>
            </thead>
            <tbody>
              {labels?.map((label, index) => (
                <tr key={`label-${index}`}>
                  <td>{label.label}</td>
                  <td>{label.description}</td>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className={'mx-2'}>
                        {label.trainingExamples?.filter(example => example.status === 'OK')?.length || 0}
                      </div>
                      <Button
                        variant="white"
                        size="sm"
                        onClick={() => setOpenTrainingExamplesModalFor({ label: label.label })}
                      >
                        Add examples
                      </Button>
                    </div>
                  </td>
                  <td>
                    <Dropdown align="end">
                      <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                        <FiMoreVertical />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            setOpenNewLabelModalFor({ label: label.label, description: label.description })
                          }
                        >
                          Edit label
                        </Dropdown.Item>
                        {canDeleteLabel(label.label) && (
                          <Dropdown.Item onClick={() => deleteLabel(label.label)}>Delete label</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {!!openTrainingExamplesModalFor && (
        <TrainingExamplesModal
          modelId={model.id}
          label={openTrainingExamplesModalFor?.label}
          trainingExamples={trainingExamples.filter(
            example => example.targetLabel === openTrainingExamplesModalFor?.label
          )}
          show={!!openTrainingExamplesModalFor}
          onHide={() => setOpenTrainingExamplesModalFor(undefined)}
          onSave={setLabelExamples}
        />
      )}
      <LabelModal
        label={openNewLabelModalFor?.label}
        description={openNewLabelModalFor?.description}
        show={!!openNewLabelModalFor}
        onHide={() => setOpenNewLabelModalFor(undefined)}
        onSave={upsertLabel}
      />
    </>
  );
}
