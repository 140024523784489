import { Col, ListGroup, Row } from 'react-bootstrap';
import { ClientConfigShort } from 'src/apis/clients/types.ts';
import { Link } from 'react-router-dom';
import { formatDate } from 'src/utils/string.ts';
import Tag from 'src/components/Tag';
import { lifecycleColors, lifecycleLabels } from '../lifecycles';
import NavButton from 'src/components/NavButton';

export interface ClientConfigItemProps {
  clientConfig: ClientConfigShort;
}

export default function ClientConfigItem({ clientConfig }: ClientConfigItemProps) {
  return (
    <>
      <ListGroup.Item key={clientConfig.clientName}>
        <Row className="align-items-center">
          <Col xs={7} className="ms-n2">
            <h3>
              <Link to={`/admin/clients/${clientConfig.clientName}`}>{clientConfig.clientName}</Link>
              <Tag className="mx-3" color={lifecycleColors[clientConfig.lifecycleStage]}>
                {lifecycleLabels[clientConfig.lifecycleStage]}
              </Tag>
              {clientConfig.scheduledForDeletionAt && <Tag color="red">Scheduled for deletion</Tag>}
            </h3>
            <ul className="text-muted m-0 list-unstyled">
              <li>Last dataset build at: {formatDate(clientConfig?.lastDataSyncCompletedAt)}</li>
            </ul>
          </Col>

          <Col xs="auto" className="ms-auto me-n3">
            <NavButton size="sm" to={`/admin/clients/${clientConfig.clientName}`}>
              Edit Config
            </NavButton>
          </Col>
        </Row>
      </ListGroup.Item>
    </>
  );
}
