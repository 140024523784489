import { Button, Modal } from 'react-bootstrap';
import { InMemoryTableWithPagination } from 'src/components/Table/InMemoryTableWithPagination';
import { formatPercent } from 'src/utils/number';

export function SamplePreviewModal({ examples, show, onHide }: any) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Sample classifications</Modal.Header>
      <Modal.Body className="py-0">
        <InMemoryTableWithPagination
          data={examples.map((e: any) => ({
            ...e,
            probability: e.labelProbabilities[e.predictedLabel]?.toFixed(4)
          }))}
          columns={[
            {
              header: 'Domain',
              id: 'domain',
              accessorKey: 'domain',
              enableSorting: true,
              cell: c => (
                <a href={`https://${c.row.original.domain}`} target="_blank">
                  {c.row.original.domain}
                </a>
              )
            },
            {
              header: 'Predicted Label',
              id: 'predictedLabel',
              accessorKey: 'predictedLabel',
              enableSorting: true
            },
            {
              header: 'Probability',
              id: 'probability',
              accessorKey: 'probability',
              enableSorting: true,
              cell: c => formatPercent(c.row.original.probability, 2)
            }
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
