import { SchemaDataType } from 'src/auth';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatCurrency, formatNumber, formatPercent } from 'src/utils/number';
import { CellContext } from '@tanstack/react-table';
import isArray from 'lodash/isArray';

export type CellRenderer = (value: any) => JSX.Element;

export const EMPTY_ELEMENT = <span className="text-muted">-</span>;

const DEFAULT_RENDERER: CellRenderer = value => {
  // If its a URL we render as clickable link
  if (typeof value === 'string' && (value.startsWith('http://') || value.startsWith('https://'))) {
    return (
      <a href={value} target="_blank">
        {value}
      </a>
    );
  }
  return value || EMPTY_ELEMENT;
};

const ARRAY_RENDERER: CellRenderer = value => {
  if (!value || !isArray(value)) return EMPTY_ELEMENT;
  if (value.length === 0) return EMPTY_ELEMENT;

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {value.map((v: string, idx: number) => (
        <Badge style={{ margin: 3, fontSize: '1em' }} bg="light" key={idx}>
          {v}
        </Badge>
      ))}
    </Tooltip>
  );

  // We rely on textOverflow ellipsis to hide too many elements
  return (
    <OverlayTrigger overlay={renderTooltip}>
      <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {value.map((v: string, idx: number) => (
          <Badge style={{ marginRight: 3, fontSize: '1em' }} bg="light" key={idx}>
            {v}
          </Badge>
        ))}
      </div>
    </OverlayTrigger>
  );
};

export const CELL_RENDERERS: Record<SchemaDataType, CellRenderer> = {
  [SchemaDataType.String]: DEFAULT_RENDERER,
  [SchemaDataType.Picklist]: DEFAULT_RENDERER,

  [SchemaDataType.Boolean]: value => {
    if (value === true) {
      return (
        <Badge bg="success" style={{ fontSize: '1em' }}>
          Yes
        </Badge>
      );
    } else if (value === false) {
      return (
        <Badge bg="light" style={{ fontSize: '1em' }}>
          No
        </Badge>
      );
    }
    return EMPTY_ELEMENT;
  },

  [SchemaDataType.Date]: value => {
    // JSON sends as string across the wire, however we also accept Date
    if (!value) {
      return EMPTY_ELEMENT;
    }
    // Format as YYYY-MM-DD
    return <>{value.toString().substring(0, 10)}</>;
  },
  [SchemaDataType.Multipicklist]: ARRAY_RENDERER,
  [SchemaDataType.Array]: ARRAY_RENDERER,
  [SchemaDataType.Number]: value => <>{formatNumber(value) || EMPTY_ELEMENT}</>,
  [SchemaDataType.Percentage]: value => <>{formatPercent(value) || EMPTY_ELEMENT}</>,
  [SchemaDataType.Currency]: value => <>{formatCurrency(value) || EMPTY_ELEMENT}</>
};

// For use in non react table places
export function renderCell(type: SchemaDataType, value: any): JSX.Element {
  const renderer = CELL_RENDERERS[type] || DEFAULT_RENDERER;
  return renderer(value);
}

// Use for react table
export function getReactTableCellRendererForType(type: SchemaDataType) {
  return (props: CellContext<any, unknown>) => CELL_RENDERERS[type](props.getValue());
}

// not adding it in to the CELL_RENDERERS as it is not a type that we will send to Salesforce, or HS
export function renderDateTime() {
  return (props: CellContext<any, unknown>) => {
    if (!props.getValue()) {
      return EMPTY_ELEMENT;
    }
    const date = new Date(props.getValue() as string);
    // Format as YYYY-MM-DD HH:MM:SS
    return <>{date.toISOString().slice(0, 19).replace('T', ' ')}</>;
  };
}
