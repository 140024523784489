import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Nav } from 'react-bootstrap';
import { loadScoringPreview } from 'src/apis/scoring/apis';
import { Debouncer } from 'src/utils/Debouncer';
import ScoringPreviewTable from './ScoringPreviewTable';
import ScoringAnalyze from './ScoringAnalyze';
import ClientCompaniesFieldValueMultiSelect from 'src/components/Filters/ClientCompaniesFilters/ClientCompaniesFieldValueMultiSelect.tsx';
import CompanyOffcanvas from 'src/components/companiesList/CompanyOffcanvas';

function getInitialPreviewDomainsFromLocalStorage() {
  if (localStorage.getItem('scoringPreviewDomains')) {
    return JSON.parse(localStorage.getItem('scoringPreviewDomains') || '[]');
  }
  return [];
}

const previewDebouncer = new Debouncer(1000);

export default function ScoringPreview({ rules }: any) {
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [hasEverLoaded, setHasEverLoaded] = useState(false);
  const [previewDomains, setPreviewDomains] = useState<string[]>(getInitialPreviewDomainsFromLocalStorage());
  const [previewOrder, setPreviewOrder] = useState('DESC');
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedRange, setSelectedRange] = useState<number[] | null>(null);

  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [isOffcanvasShowing, setIsOffcanvasShowing] = useState(false);

  const loadPreview = useCallback(() => {
    setIsPreviewLoading(true);
    loadScoringPreview(rules, previewOrder, previewDomains, selectedRange).then(results => {
      setIsPreviewLoading(false);
      setCompanies(results.companies);
    });
    setHasEverLoaded(true);
  }, [rules, previewOrder, previewDomains, selectedRange]);

  useEffect(() => {
    if (hasEverLoaded) {
      previewDebouncer.schedule(() => loadPreview());
    } else {
      previewDebouncer.immediate(() => loadPreview());
    }
    // We only want to trigger on certain changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules, previewOrder, previewDomains]);

  useEffect(() => {
    if (hasEverLoaded) {
      previewDebouncer.immediate(() => loadPreview());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRange]);

  return (
    <Card>
      <Card.Header style={{ borderBottom: 'none' }}>
        <div>Scoring Preview</div>
      </Card.Header>
      <Card.Body style={{ height: 370 }}>
        <ScoringAnalyze rules={rules} setSelectedRange={setSelectedRange} />
      </Card.Body>
      <Card.Header>
        <div>
          <Nav
            variant="tabs"
            activeKey={previewOrder}
            onSelect={tab => {
              setHasEverLoaded(false);
              setPreviewOrder(tab as string);
            }}
          >
            <Nav.Item>
              <Nav.Link eventKey="DESC">Best fit</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="ASC">Worst fit</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div style={{ minWidth: 500, maxWidth: 600 }}>
          <ClientCompaniesFieldValueMultiSelect
            fieldName="domain"
            value={previewDomains}
            placeholder="Search for specific companies by domain(s)"
            onChange={value => {
              localStorage.setItem('scoringPreviewDomains', JSON.stringify(value));
              setPreviewDomains(value);
            }}
          />
        </div>
      </Card.Header>
      <Card.Body>
        {selectedRange && (
          <div className="mb-3">
            Showing score range {selectedRange[0]} to {selectedRange[1]}
            <Button className="ms-2" size="sm" variant="white" onClick={() => setSelectedRange(null)}>
              Clear range
            </Button>
          </div>
        )}
        <ScoringPreviewTable
          rules={rules}
          isLoading={isPreviewLoading}
          companies={companies}
          onSelectCompany={(companyId: string) => {
            setSelectedCompanyId(companyId);
            setIsOffcanvasShowing(true);
          }}
        />
        <CompanyOffcanvas
          company={{ companyId: selectedCompanyId as string }}
          show={isOffcanvasShowing}
          onHide={() => {
            setIsOffcanvasShowing(false);
            setSelectedCompanyId(undefined);
          }}
          showSyncTab={false}
          showContactsTab={false}
        />
      </Card.Body>
    </Card>
  );
}
