import { Alert, Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useCallback, useMemo, useState } from 'react';
import { PageElementConfig } from 'src/pages/staff/pageElements/types.ts';
import { getDownloadUrl, previewResultsEndpoint, triggerPreview } from '../api';
import BatchTaskProgressBar from 'src/components/staff/BatchTaskProgressBar.tsx';
import toast from 'react-hot-toast';
import { InMemoryTableWithPagination } from 'src/components/Table/InMemoryTableWithPagination.tsx';
import { ColumnDef } from '@tanstack/react-table';
import { getReactTableCellRendererForType } from 'src/components/Table/cellRenderers.tsx';
import { SchemaDataType } from 'src/auth';
import { useLongRunningTask } from 'src/utils/useLongRunningTask.ts';

import { FiDownload } from 'react-icons/fi';
import isEmpty from 'lodash/isEmpty';

function InputPreviewStep({
  pageElementConfig,
  onNext,
  onHide
}: {
  pageElementConfig: PageElementConfig;
  onNext: (trigger: any) => void;
  onHide: () => void;
}) {
  const [domains, setDomains] = useState<string>('');
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const onPreview = useCallback(async () => {
    try {
      setIsRunning(true);
      const trigger = await triggerPreview(pageElementConfig, 'domains', domains?.split('\n'));
      onNext(trigger);
    } catch (e: any) {
      setError(e.message);
    }
    setIsRunning(false);
  }, [onNext, pageElementConfig, domains]);

  const onRandom = useCallback(async () => {
    try {
      setIsRunning(true);
      const trigger = await triggerPreview(pageElementConfig, 'random', []);
      onNext(trigger);
    } catch (e: any) {
      setError(e.message);
    }
    setIsRunning(false);
  }, [onNext, pageElementConfig]);

  return (
    <>
      <Modal.Body style={{ minHeight: '220px' }} className={'py-1'}>
        {!!error && (
          <Alert variant={'danger'} className={'mb-0'}>
            {error}
          </Alert>
        )}
        <Form.Label column={'sm'}>Domains to check</Form.Label>
        <Form.Control
          id={'edit-multiple'}
          style={{ fontSize: 11, fontFamily: 'monospace' }}
          value={domains}
          as="textarea"
          rows={10}
          onChange={ev => setDomains(ev.target.value)}
        ></Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="white" onClick={onHide}>
          Cancel
        </Button>
        <Button size="sm" variant="secondary" disabled={isRunning} onClick={onRandom}>
          Random sample
        </Button>
        <Button size="sm" variant="primary" disabled={isRunning || isEmpty(domains)} onClick={onPreview}>
          Preview
        </Button>
      </Modal.Footer>
    </>
  );
}

function ProgressPreviewStep({
  trigger,
  onNext,
  onHide
}: {
  trigger: any;
  onNext: (trigger: any) => void;
  onHide: () => void;
}) {
  return (
    <>
      <Modal.Body style={{ minHeight: '100px' }} className={'d-flex justify-content-center align-items-center'}>
        <div className={'w-100'}>
          <div>
            {trigger.batchTaskId && (
              <BatchTaskProgressBar
                id={trigger.batchTaskId}
                onComplete={() => onNext(trigger)}
                onError={() => toast.error('Failed to run the preview')}
              />
            )}
          </div>
          <div>
            <div className={'d-flex justify-content-center'}>Making progress looking for page elements</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="white" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
}

export function ResultsPreviewStep({ trigger, onHide }: { trigger: any; onHide: () => void }) {
  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        id: 'url',
        accessorFn: (row: any) => row.url || row.domain,
        enableSorting: false,
        enableColumnFilter: false,
        cell: c => (
          <OverlayTrigger overlay={<Tooltip>{c.row.original.url || c.row.original.domain}</Tooltip>} placement="top">
            <div className={'text-wrap'}>
              <a href={c.row.original.url || c.row.original.domain} target="_blank">
                {c.row.original.url || c.row.original.domain}
              </a>
              <FiDownload
                className={'ms-2'}
                onClick={() => {
                  const toastId = toast.loading('Downloading');
                  getDownloadUrl(c.row.original.domain, c.row.original.url)
                    .then(part => window.open(part.downloadUrl))
                    .catch(() => {
                      toast.dismiss(toastId);
                      toast.error('Download failed');
                    })
                    .then(() => toast.dismiss(toastId));
                }}
              />
            </div>
          </OverlayTrigger>
        ),
        header: 'Page'
      },
      {
        id: 'count',
        accessorFn: (row: any) => row.count || 0,
        enableSorting: false,
        enableColumnFilter: false,
        cell: getReactTableCellRendererForType(SchemaDataType.Number),
        header: 'Found'
      },
      {
        id: 'element',
        accessorFn: (row: any) => row.element,
        enableSorting: false,
        enableColumnFilter: false,
        cell: getReactTableCellRendererForType(SchemaDataType.String),
        header: 'Value'
      },
      {
        id: 'foundUsing',
        enableSorting: false,
        enableColumnFilter: false,
        cell: c => (
          <div>
            <OverlayTrigger
              overlay={props => {
                return (
                  <Tooltip {...props}>
                    <div>{c.row.original?.foundUsing?.[0] ?? ''} </div>
                  </Tooltip>
                );
              }}
            >
              <div
                style={{
                  maxWidth: '200px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {/*if it's very short just show the full thing in the preview */}
                {c.row.original.foundUsing?.[0].length <= 20 + c.row.original.element?.length
                  ? c.row.original.foundUsing?.[0]
                  : // otherwise try to get the center of it
                    c.row.original.foundUsing?.[0]
                    ? `...${c.row.original.foundUsing?.[0].slice(
                        (c.row.original.foundUsing?.[0].length - c.row.original.element?.length) / 2 - 10,
                        (c.row.original.foundUsing?.[0].length - c.row.original.element?.length) / 2 + 10
                      )}...`
                    : ''}
              </div>
            </OverlayTrigger>
          </div>
        ),
        header: 'Snippet'
      }
    ],
    []
  );

  const getTaskId = useCallback(async () => {
    return trigger.executionId;
  }, [trigger]);

  const { isLoading, result, error } = useLongRunningTask(getTaskId, previewResultsEndpoint, 2000);

  return (
    <>
      <Modal.Body className={'py-0'}>
        {(!!error && (
          <Alert variant={'danger'} className={'mb-0'}>
            {error?.errorCause?.errorMessage || error?.message || JSON.stringify(error)}
          </Alert>
        )) || (
          <InMemoryTableWithPagination
            isLoading={isLoading}
            data={result?.previewResult || []}
            columns={columns}
            stayOnPageEvenIfDataChanges={true}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="white" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
}

export function PreviewModal({
  pageElementConfig,
  show,
  onHide
}: {
  pageElementConfig: PageElementConfig;
  show: boolean;
  onHide: () => void;
}) {
  const [step, setStep] = useState<'input' | 'progress' | 'results'>('input');
  const [trigger, setTrigger] = useState();

  return (
    <Modal show={show} onHide={onHide} size={step === 'results' ? 'lg' : undefined}>
      <Modal.Header closeButton>{'Page element preview'}</Modal.Header>
      {(step === 'input' && (
        <InputPreviewStep
          onHide={onHide}
          pageElementConfig={pageElementConfig}
          onNext={trigger => {
            setStep('progress');
            setTrigger(trigger);
          }}
        />
      )) ||
        (step === 'progress' && (
          <ProgressPreviewStep
            onHide={onHide}
            trigger={trigger}
            onNext={trigger => {
              setStep('results');
              setTrigger(trigger);
            }}
          />
        )) ||
        (step === 'results' && <ResultsPreviewStep trigger={trigger} onHide={onHide} />)}
    </Modal>
  );
}
