import { ContactCriteria } from 'src/apis/clients/types.ts';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { usePeriodic } from 'src/utils/usePeriodic.ts';
import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import TableGrid from 'src/components/Table/TableGrid.tsx';
import toast from 'react-hot-toast';
import { getContactsBreakdown, getContactsBreakdownResults } from 'src/pages/staff/clientConfigs/contacts/api.ts';
import { Breakdown, BreakdownBy } from 'src/pages/staff/clientConfigs/contacts/types.ts';

export function BreakdownTab({
  clientName,
  contactCriteria,
  breakdownBy
}: {
  clientName: string | undefined;
  contactCriteria: ContactCriteria[] | undefined;
  breakdownBy: BreakdownBy;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [breakdown, setContacts] = useState<Breakdown[]>([]);
  const [executionId, setExecutionId] = useState<string>();

  useEffect(() => {
    if (clientName === undefined || contactCriteria === undefined) {
      return;
    }
    setIsLoading(true);
    getContactsBreakdown({
      clientName,
      contactCriteria,
      breakdownBy
    })
      .then(executionId => {
        setExecutionId(executionId.executionId);
      })
      .catch(() => {
        toast.error('There has been a problem with your request, please contact eng');
        setIsLoading(false);
      });
  }, [clientName, contactCriteria, breakdownBy]);

  usePeriodic(
    () => {
      if (executionId) {
        getContactsBreakdownResults(executionId).then(response => {
          if (response.status === 'SUCCEEDED') {
            setContacts(response.output.breakdown);
            setIsLoading(false);
            setExecutionId(undefined);
          } else if (response.status === 'FAILED') {
            console.error('Failed to fetch breakdown');
            setIsLoading(false);
            setExecutionId(undefined);
          }
        });
      }
    },
    5000,
    [executionId]
  );

  const table = useReactTable<Breakdown>({
    columns: [
      {
        header: 'Breakdown by',
        id: 'breakdown_by',
        accessorFn: row => row.key,
        enableSorting: false
      },
      {
        header: 'Count',
        id: 'count',
        accessorFn: row => row.count,
        enableSorting: false
      }
    ],
    data: breakdown,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  const copyToClipboard = useCallback(() => {
    const lines = [];
    const header = ['Breakdown By', 'Count'];

    lines.push(header.join('\t'));
    breakdown.forEach(b => {
      lines.push([b.key, b.count].join('\t'));
    });

    const textTsv = lines.join('\n');
    navigator.clipboard.writeText(textTsv);

    toast.success(`Copied ${breakdown.length} rows to clipboard!`);
  }, [breakdown]);

  return (
    <Col>
      {breakdownBy === 'exclusionKeywords' && (
        <Alert variant="info" className="mt-3">
          This breakdown calculates how many additional contacts would have been included (match Inclusion Keywords) if
          the specific Exclusion Keyword was to be removed.
        </Alert>
      )}
      <Row xs={'auto'} className={'d-flex justify-content-between'}>
        <div></div>
        <Button variant="white" size="sm" className="me-2" onClick={copyToClipboard}>
          Copy to clipboard
        </Button>
      </Row>
      <Row className={'my-4'}>
        <TableGrid
          table={table}
          isLoading={isLoading}
          emptyMessage="No breakdown to show"
          numLoadingRows={5}
          className="breakdown-sample-table table-centered table-compact table-nowrap"
        />
      </Row>
    </Col>
  );
}
