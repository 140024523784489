import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, Form, InputGroup } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import WideLayout from 'src/layouts/Wide/WideLayout';
import { CollectionHelper } from 'src/utils/CollectionHelper';
import { ScoringRule, ScoringConfig } from 'src/apis/scoring/types';
import ScoringRuleEditor from './components/editor/ScoringRuleEditor';
import ScoringPreview from './components/editor/ScoringPreview';
import EmptyMessage from 'src/components/EmptyMessage';
import { FiSliders } from 'react-icons/fi';
import './scoring.scss';
import { Link, useBlocker, useNavigate, useParams } from 'react-router-dom';
import PageLoader from 'src/components/PageLoader';
import { createScoringConfig, loadScoringConfig, loadScoringConfigs, updateScoringConfig } from 'src/apis/scoring/apis';
import LoadingButton from 'src/components/LoadingButton';
import toast from 'react-hot-toast';
import { BasicField, useClient } from 'src/auth';
import { appClientUrl, decodeState } from 'src/utils/urls';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { NavigateWithoutSavingWarning } from 'src/components/NavigateWithoutSavingWarning';
import { useFieldInfoOffcanvas } from 'src/components/FieldInfoOffcanvas/useFieldInfoOffcanvas';

const defaults: ScoringConfig = {
  id: 'new',
  isDefault: false,
  name: 'GoodFit Score',
  rules: [],
  processingStatus: 'Waiting',
  associatedScoringAnalysisReportId: undefined
};

function inflateFromUrl() {
  try {
    if (window.location.hash.startsWith('#state=')) {
      return decodeState(window.location.hash.substring(7));
    }
  } catch (err) {
    console.warn('Could not parse initial state');
  }
  return {};
}

const collection = new CollectionHelper<ScoringRule>('id');

export default function ScoringEditorPage({ title }: any) {
  const navigate = useNavigate();
  const { refresh, appSettings, updateSettings } = useClient();
  const { scoringConfigId: scoringConfigIdInput } = useParams();
  const scoringConfigId = scoringConfigIdInput as string;

  const [isLoading, setIsLoading] = useState(true);
  const [config, setConfig] = useState<ScoringConfig | null>(null);
  const [hasChanged, setHasChanged] = useState(false);
  const { showFieldInfoOffcanvas, modalElement } = useFieldInfoOffcanvas();

  usePageTitle(title);

  useEffect(() => {
    if (scoringConfigId === 'new') {
      loadScoringConfigs().then(response => {
        const numExistingConfigs = response.configs.length;
        const name = `GoodFit Score ${numExistingConfigs ? numExistingConfigs + 1 : ''}`.trim();
        setConfig({ ...defaults, name, ...inflateFromUrl() });
        setIsLoading(false);
      });
    } else {
      loadScoringConfig(scoringConfigId).then(loadedConfig => {
        setConfig(loadedConfig);
        setIsLoading(false);
      });
    }
  }, [scoringConfigId]);

  const showFieldInfo = useCallback(
    (field: BasicField) => {
      showFieldInfoOffcanvas(field, 'company');
    },
    [showFieldInfoOffcanvas]
  );

  const saveScoringConfig = useCallback(() => {
    const isCreate = scoringConfigId === 'new';
    const payload = {
      rules: config?.rules,
      name: config?.name,
      associatedScoringAnalysisReportId: config?.associatedScoringAnalysisReportId
    };
    const doSave = async () => {
      if (isCreate) {
        const result = (await createScoringConfig(payload)) as ScoringConfig;
        const newId = result.id;

        if (appSettings.companiesTableFields && appSettings.companiesTableFields.length > 0) {
          // Prepend this new scoring field to the start of the list
          const fieldName = `goodfit_score_${newId.substring(0, 8)}`;
          await updateSettings({ companiesTableFields: [fieldName, ...appSettings.companiesTableFields] }, true);
        }
        setHasChanged(false);
        await refresh(); // Refresh client config data as schema may have changed
        navigate(appClientUrl(`/scoring/${newId}`));
      } else {
        await updateScoringConfig(scoringConfigId, payload);
        setHasChanged(false);
      }
      await refresh(); // Refresh client config data as schema may have changed
    };
    return toast.promise(
      doSave(),
      {
        loading: 'Saving scoring field',
        success: isCreate
          ? 'Scoring field added and is being calculated. It can take several minutes to calculate for the first time and appear.'
          : 'Scoring field saved and is being re-calculated. It can take several minutes for the re-calculation to complete.',
        error: 'Error saving scoring field'
      },
      { success: { duration: 10000 } }
    );
  }, [
    scoringConfigId,
    config?.rules,
    config?.name,
    config?.associatedScoringAnalysisReportId,
    refresh,
    appSettings.companiesTableFields,
    navigate,
    updateSettings
  ]);

  const setName = useCallback(
    (newName: string) => {
      setHasChanged(true);
      setConfig({ ...config, name: newName } as ScoringConfig);
    },
    [config]
  );

  const addRule = useCallback(() => {
    if (config) {
      setHasChanged(true);
      setConfig({
        ...config,
        rules: collection.addItem(config.rules, {
          id: uuidv4(),
          field: null,
          operator: null,
          value: null,
          score: 1
        })
      });
    }
  }, [config]);

  const removeRule = useCallback(
    (id: string) => {
      if (config) {
        setHasChanged(true);
        setConfig({
          ...config,
          rules: collection.deleteItem(config.rules, id)
        });
      }
    },
    [config]
  );

  const updateRule = useCallback(
    (id: string, update: Partial<ScoringRule>) => {
      if (config) {
        setHasChanged(true);
        setConfig({
          ...config,
          rules: collection.updateItem(config.rules, id, update)
        });
      }
    },
    [config]
  );

  const blocker = useBlocker(() => hasChanged);

  if (!config || isLoading) return <PageLoader />;

  const hasRules = config.rules?.length > 0 && config.rules[0].field;
  const canSave = config?.name && hasRules;

  return (
    <WideLayout title="Scoring" maxWidthCols={12}>
      <Card>
        <Card.Header>Scoring Configuration</Card.Header>
        <Card.Body>
          <div>
            {config.associatedScoringAnalysisReportId && (
              <Alert>
                This scoring field was created from a scoring analysis report.{' '}
                <Link to={appClientUrl(`/scoring/analysis/results/${config.associatedScoringAnalysisReportId}`)}>
                  View report
                </Link>
                .
              </Alert>
            )}
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text style={{ backgroundColor: '#f5f5f5' }}>Field name</InputGroup.Text>
              <Form.Control
                name="name"
                size="sm"
                onChange={ev => setName(ev.target.value)}
                value={config.name}
                placeholder="Untitled scoring field"
              />
            </InputGroup>
          </div>
          <hr />
          {config.rules?.length === 0 && (
            <EmptyMessage
              message="You have no rules defined"
              subMessage={
                <>
                  Find out about how scoring works:{' '}
                  <a
                    href="https://docs.goodfit.io/product-docs/guides/prioritisation/how-to-build-your-first-score"
                    target="_blank"
                  >
                    Scoring documentation
                  </a>
                </>
              }
              icon={<FiSliders />}
              actions={
                <>
                  <Button size="sm" variant="primary" onClick={() => addRule()}>
                    Create your first scoring rule
                  </Button>
                </>
              }
            />
          )}
          {config.rules.map(rule => (
            <ScoringRuleEditor
              rule={rule}
              key={rule.id}
              showFieldInfo={showFieldInfo}
              modifyRule={(update: Partial<ScoringRule>) => {
                updateRule(rule.id, update);
              }}
              removeRule={() => {
                removeRule(rule.id);
              }}
            />
          ))}
        </Card.Body>
        <Card.Footer className="d-flex flex-row justify-content-between align-items-center">
          <div>
            <Button size="sm" variant="white" onClick={() => addRule()}>
              + Add scoring rule
            </Button>
          </div>
          <div>
            <LoadingButton
              size="sm"
              variant="primary"
              disabled={!canSave}
              overlayText={canSave ? '' : 'Please set a name and select at least one filter'}
              onClick={() => saveScoringConfig()}
            >
              Save scoring configuration
            </LoadingButton>
          </div>
        </Card.Footer>
      </Card>
      <ScoringPreview rules={config.rules} />
      <NavigateWithoutSavingWarning blocker={blocker} handleSave={() => saveScoringConfig()} />
      {modalElement}
    </WideLayout>
  );
}
