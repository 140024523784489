import './validation-modal-styles.scss';

import { Button, Modal } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi';

import { BreakingSegments } from './BreakingSegments';
import { BreakingChanges } from 'src/apis/types';

interface ValidationErrorsModalProps {
  clientName: string;
  resourceName: string;
  breakingChanges: BreakingChanges;
  proceed(args: unknown): void;
  show: boolean;
}

export const BreakingChangesModal: React.FC<ValidationErrorsModalProps> = ({
  resourceName,
  clientName,
  breakingChanges,
  proceed,
  show
}) => (
  <Modal size="lg" show={show} animation={true} dialogClassName="modal-50w" onHide={() => proceed(null)}>
    <Modal.Header closeButton>
      <Modal.Title className="mb-0">
        <div className="d-flex align-items-center">
          <FiAlertTriangle className="me-2" size={25} color="red" />
          Unable to delete {resourceName}
        </div>
      </Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ height: 550, padding: 0, overflowY: 'auto' }} className="p-4">
      <BreakingSegments
        clientName={clientName}
        resourceName={resourceName}
        breakingSegments={breakingChanges.segments}
      />
    </Modal.Body>

    <Modal.Footer>
      <Button size="sm" variant="light" onClick={() => proceed(null)}>
        Okay
      </Button>
    </Modal.Footer>
  </Modal>
);
