import { Toggle } from 'src/pages/staff/clientConfigs/components/Toggle.tsx';
import JsonEditor from 'src/components/JsonEditor/JsonEditor.tsx';
import { ClientConfig } from 'src/apis/clients/types.ts';
import { useState } from 'react';
import CCMFilters from 'src/components/Filters/CCMFilters/CCMFilters.tsx';
import { FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';
import { FilterContext } from 'src/components/Filters/Filters.tsx';

type SourceCriteriaEditorProps = {
  isEditing: boolean;
  schema: any;
  clientConfig: ClientConfig;
  fieldMapping: FieldMapping[];
  handleChange: (update: any, path: string) => void;
  handleCommitChanges?: () => void;
  ruleActionOrnament?: (f: any, disabled: boolean | undefined) => JSX.Element;
  groupActionOrnament?: (f: any, disabled: boolean | undefined) => JSX.Element;
  context?: FilterContext;
};

export default function SourceCriteriaEditor({
  isEditing,
  schema,
  clientConfig,
  fieldMapping,
  handleChange,
  handleCommitChanges,
  ruleActionOrnament,
  groupActionOrnament,
  context
}: SourceCriteriaEditorProps) {
  const [editAsJSON, setEditAsJSON] = useState(false);
  return (
    <div className={' mb-2'}>
      <div className={'d-flex justify-content-between'}>
        <h3>Criteria</h3>
        <Toggle
          flag={editAsJSON}
          label={'As JSON'}
          isEditing={true}
          onChange={() => {
            handleCommitChanges && handleCommitChanges();
            setEditAsJSON(!editAsJSON);
          }}
        />
      </div>
      {(editAsJSON && (
        <JsonEditor
          schema={schema.definitions.CompanyCriteriaGroup}
          referenceSchema={schema}
          json={clientConfig.sourcingCriteria}
          isEditing={isEditing}
          onChange={(update: any) => {
            handleChange(update, 'sourcingCriteria');
          }}
        />
      )) || (
        <CCMFilters
          sourcingCriteria={clientConfig.sourcingCriteria}
          dataBlockConfigs={clientConfig.dataBlockConfigs}
          fieldMapping={fieldMapping}
          handleChange={(update: any) => {
            handleChange(update, 'sourcingCriteria');
          }}
          disabled={!isEditing}
          ruleActionOrnament={ruleActionOrnament}
          groupActionOrnament={groupActionOrnament}
          context={context}
        />
      )}
    </div>
  );
}
