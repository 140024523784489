import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, InputGroup, Row } from 'react-bootstrap';
import { FiEdit, FiFilter, FiSearch, FiSettings } from 'react-icons/fi';
import { CompanySchema, SchemaDataType, useClient } from 'src/auth';
import CardPaginator from 'src/components/Table/CardPaginator';
import { CompaniesAPIResponse, CompanyRecord } from 'src/pages/companies/types';
import API from 'src/utils/API';
import { useNavigate } from 'react-router-dom';
import CompaniesTableSettingsModal from 'src/pages/companies/CompaniesTableSettingsModal';
import CompaniesTable, { SortDefinition } from 'src/components/companiesList/CompaniesTable';
import { appClientUrl } from 'src/utils/urls';
import { computeTableColumns, ensureRequiredTableColumnsExist } from '../../utils';
import CompanyOffcanvas from 'src/components/companiesList/CompanyOffcanvas';
import EmptyMessage from 'src/components/EmptyMessage';
import LoadingButton from 'src/components/LoadingButton';
import ExportButton from './ExportButton';
import { Segment } from '../../types';
import { saveSegment } from '../../apis';
import { CELL_RENDERERS } from 'src/components/Table/cellRenderers';
import { GrGroup } from 'react-icons/gr';
import { showModal } from 'src/utils/modals';
import { CompanyContactsModal } from '../modals/CompanyContactsModal';
import Tag from 'src/components/Tag';
import { formatDateString } from 'src/utils/string';

const PAGE_SIZE = 50;

export function CompaniesTab({ segment }: { segment: Segment }) {
  const { companiesSchema } = useClient();
  const navigate = useNavigate();

  const searchRef = useRef<any>(null); // Holds current search element and value
  const [currentSearch, setCurrentSearch] = useState(''); // Search currently active
  const [pageIndex, setPageIndex] = useState(0);
  const [sort, setSort] = useState<SortDefinition>({ orderDirection: 'ASC' });

  const [includeActive, setIncludeActive] = useState(true);
  const [includeRemoved, setIncludeRemoved] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [companies, setCompanies] = useState<CompanyRecord[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showTableConfig, setShowTableConfig] = useState(false);

  const [isOffcanvasShowing, setIsOffcanvasShowing] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<null | CompanyRecord>(null);
  const [overriddenSelectedColumns, setOverriddenSelectedColumns] = useState<undefined | string[]>(
    ensureRequiredTableColumnsExist(segment?.tableOptions?.selectedColumns)
  );

  const loadCompanies = useCallback(async () => {
    setIsLoading(true);
    const data = await API.get<CompaniesAPIResponse>(`/app/segments/items/${segment.id}/companies`, {
      limit: PAGE_SIZE,
      offset: pageIndex * PAGE_SIZE,
      search: currentSearch,
      sortby: sort.orderBy,
      sortdirection: sort.orderDirection?.toLowerCase(),
      include_active: includeActive,
      include_removed: includeRemoved
    });
    setCompanies(data.companies);
    setTotalItems(data?.companiesCnt);
    setIsLoading(false);
  }, [pageIndex, currentSearch, segment.id, sort, includeActive, includeRemoved]);

  const changeSelectedColumns = useCallback(
    async (selectedColumns: string[]) => {
      await saveSegment(segment.id, { tableOptions: { ...segment.tableOptions, selectedColumns } });
      setOverriddenSelectedColumns(selectedColumns);
    },
    [segment.id, segment.tableOptions]
  );

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  const defaultSelectedColumns = useMemo(
    () => computeTableColumns(companiesSchema, segment?.filters, segment?.limitOptions),
    [segment?.filters, segment?.limitOptions, companiesSchema]
  );

  const schema = useMemo<CompanySchema>(() => {
    return [
      companiesSchema[0],
      {
        fieldName: 'matched_personas',
        isMappableInIntegration: false,
        displayName: 'Matched Personas',
        allowInTalkTracks: false,
        fieldSource: 'frontend',
        type: SchemaDataType.Array,
        description: 'Which segment personas exist in this company',
        customColumnDef: {
          header: 'Matched Personas',
          accessorKey: 'matched_personas',
          enableSorting: true,
          cell: props => {
            const company = props.row.original;
            const personas = Object.values(company.matched_personas ?? {});

            const showCompanyContactsModal = () =>
              showModal(CompanyContactsModal, {
                companyId: company.__company_id,
                segmentId: segment.id,
                companyName: company.name
              });

            return (
              <>
                <Button
                  size="sm"
                  variant="white"
                  title="View all contacts for this company"
                  style={{
                    margin: '3px 0 0 2px',
                    padding: '2px 4px',
                    lineHeight: 1.2,
                    float: 'right'
                  }}
                  onClick={showCompanyContactsModal}
                >
                  <GrGroup style={{ fontSize: 12 }} />
                </Button>
                <div>{CELL_RENDERERS[SchemaDataType.Array](personas)}</div>
              </>
            );
          }
        }
      },
      ...companiesSchema.slice(1),
      {
        fieldName: 'inclusion_status',
        isMappableInIntegration: false,
        displayName: 'Inclusion Status',
        allowInTalkTracks: false,
        fieldSource: 'frontend',
        type: SchemaDataType.Array,
        description: 'Current status of this company in the segment',
        customColumnDef: {
          header: 'Inclusion Status',
          accessorKey: 'inclusion_status',
          enableSorting: true,
          cell: props => {
            const isActive = props.row.original.__segmentStatus.isActive;
            const addedAt = props.row.original.__segmentStatus.addedAt;
            const removedAt = props.row.original.__segmentStatus.removedAt;

            if (isActive) {
              return (
                <Tag color="green" overlayText={`Company was added ${formatDateString(addedAt)}`}>
                  Included
                </Tag>
              );
            } else {
              return (
                <Tag color="orange" overlayText={`Company was removed ${formatDateString(removedAt)}`}>
                  Removed
                </Tag>
              );
            }
          }
        }
      }
    ];
  }, [companiesSchema, segment.id]);

  return (
    <>
      <Card.Body className="px-0 py-0">
        <Row className="p-3">
          <Col className="d-flex justify-content-between" style={{ gap: 8 }}>
            <InputGroup size="sm" className="input-group-merge input-group-reverse">
              <Form.Control
                type="search"
                placeholder="Search name or domain"
                defaultValue={''}
                ref={searchRef}
                className="search"
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    if (searchRef.current) setCurrentSearch(searchRef.current?.value);
                  }
                }}
                onChange={event => {
                  if (event.target.value == '') {
                    setCurrentSearch('');
                  }
                }}
              />
              <InputGroup.Text>
                <FiSearch />
              </InputGroup.Text>
            </InputGroup>
            <LoadingButton
              loading={isLoading}
              size="sm"
              variant="primary"
              onClick={() => setCurrentSearch(searchRef?.current.value)}
            >
              Search
            </LoadingButton>
          </Col>
          <Col className="d-flex justify-content-end" style={{ gap: 8 }}>
            <Button
              size="sm"
              variant="white"
              onClick={() => {
                navigate(appClientUrl(`/segments/edit/${segment.id}`));
              }}
            >
              <FiEdit className="me-1" /> Edit segment
            </Button>
            <Button size="sm" variant="white" onClick={() => setShowTableConfig(true)}>
              <FiSettings className="me-1" /> Columns
            </Button>
            <ExportButton
              segment={segment}
              isLoading={isLoading}
              exportConfig={{ columns: overriddenSelectedColumns ?? defaultSelectedColumns }}
            />
            <Dropdown autoClose={'outside'}>
              <Dropdown.Toggle size="sm" variant="white">
                <FiFilter /> View
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.ItemText as="label" className="text-nowrap">
                  <input
                    type="checkbox"
                    defaultChecked={includeActive}
                    className="me-2"
                    style={{ top: 2, position: 'relative' }}
                    onChange={ev => setIncludeActive(ev.target.checked)}
                  />
                  Show active members
                </Dropdown.ItemText>
                <Dropdown.ItemText as="label" className="text-nowrap">
                  <input
                    type="checkbox"
                    defaultChecked={includeRemoved}
                    className="me-2"
                    style={{ top: 2, position: 'relative' }}
                    onChange={ev => setIncludeRemoved(ev.target.checked)}
                  />
                  Show historic members
                </Dropdown.ItemText>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <div style={{ position: 'relative', borderTop: '1px solid #eee' }}>
          <CompaniesTable
            selectedColumns={overriddenSelectedColumns ?? defaultSelectedColumns}
            companies={companies ?? []}
            isLoading={isLoading}
            schema={schema}
            onCompanySelect={(row: CompanyRecord) => {
              setSelectedCompany(row);
              setIsOffcanvasShowing(true);
            }}
            enableSorting={true}
            onSortChange={sort => setSort(sort)}
            emptyComponent={<EmptyMessage message="No companies matching search."></EmptyMessage>}
          />
        </div>
        <CompaniesTableSettingsModal
          show={showTableConfig}
          schema={schema}
          selectedColumns={overriddenSelectedColumns ?? defaultSelectedColumns}
          setSelectedColumns={columns => changeSelectedColumns(columns)}
          showUpdateGlobalSettings={false}
          onHide={() => setShowTableConfig(false)}
        />
      </Card.Body>
      <Card.Footer>
        <CardPaginator
          pageIndex={pageIndex}
          totalPages={Math.ceil(totalItems / PAGE_SIZE)}
          setPage={idx => setPageIndex(idx)}
        />
      </Card.Footer>
      <CompanyOffcanvas
        company={{ record: selectedCompany }}
        show={isOffcanvasShowing}
        onHide={() => setIsOffcanvasShowing(false)}
        showContactsTab={false}
        showSyncTab={false}
      />
    </>
  );
}
