import QueryBuilder, { ValueEditorProps } from 'react-querybuilder';
import { Button } from 'react-bootstrap';
import { COLLAPSE_ACTION, DEFAULT_CLONE_ACTION, DEFAULT_REMOVE_ACTION } from 'src/components/Filters/defaults.tsx';
import { useCallback } from 'react';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { DndProviderWrapper } from './DndProviderWrapper';
import { FieldSelector } from 'src/components/Filters/Inputs/FieldSelector.tsx';
import { DisabledPath, isDisabledPath } from 'src/components/Filters/disabledPaths.ts';
import { OperatorSelector } from 'src/components/Filters/Inputs/OperatorSelector.tsx';
import isEmpty from 'lodash/isEmpty';

export type FilterContext = {
  disabledPaths: DisabledPath[];
};

type FilterProps = {
  fields: any[];
  getOperators: (field: string) => any[];
  setQuery: (a: any) => void;
  defaultQuery: any;
  disabled?: boolean;
  valueEditor: (props: ValueEditorProps) => JSX.Element;
  ruleActionOrnament?: (f: any, disabled: boolean | undefined) => JSX.Element;
  groupActionOrnament?: (f: any, disabled: boolean | undefined) => JSX.Element;
  context?: FilterContext;
};

export default function Filters({
  fields,
  getOperators,
  setQuery,
  defaultQuery,
  disabled = false,
  valueEditor,
  ruleActionOrnament,
  groupActionOrnament,
  context
}: FilterProps) {
  // This has to be a useCallback to avoid focus loss on keypress
  const valueEditorBuilder = useCallback(
    (f: any) => {
      return valueEditor({ ...f, disabled: disabled || isDisabledPath(f, 'valueEditor') });
    },
    [disabled, valueEditor]
  );

  return (
    <DndProviderWrapper>
      <QueryBuilderDnD enableDragAndDrop dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
        <QueryBuilder
          combinators={[
            { name: 'and', label: 'Match all' },
            { name: 'or', label: 'Match any' }
          ]}
          showCloneButtons={true}
          showLockButtons={true}
          disabled={disabled ? [[]] : undefined}
          onQueryChange={setQuery}
          defaultQuery={isEmpty(defaultQuery) ? null : defaultQuery}
          fields={fields}
          addRuleToNewGroups={false}
          showCombinatorsBetweenRules={false}
          context={context}
          controlClassnames={{
            rule: 'queryBuilderRule',
            combinators: 'form-select form-select-sm d-inline w-auto',
            operators: 'form-select form-select-sm d-inline w-auto',
            fields: 'form-select form-select-sm d-inline w-auto',
            value: 'form-control form-control-sm  d-inline w-auto'
          }}
          listsAsArrays={true}
          getOperators={getOperators}
          controlElements={{
            combinatorSelector: f => {
              return (
                <select
                  value={f.value}
                  onChange={e => f.handleOnChange(e.target.value)}
                  className="ruleGroup-combinators form-select form-select-sm d-inline w-auto"
                  disabled={f.disabled || disabled || isDisabledPath(f, 'combinatorSelector')}
                >
                  {f.options.map(combinator => (
                    // @ts-expect-error ts-migrate(7006) Parameter 'combinator' implicitly has an 'any' type.
                    <option key={combinator.label} value={combinator.name}>
                      {combinator.label}
                    </option>
                  ))}
                </select>
              );
            },
            addGroupAction: f => (
              <Button
                size="sm"
                variant="white"
                onClick={f.handleOnClick}
                disabled={f.disabled || disabled || isDisabledPath(f, 'addGroupAction')}
              >
                {f.label}
              </Button>
            ),
            // there is no way to add any action out of box, so we are hacking this, by adding additional buttons around clone actions
            cloneGroupAction: f => {
              if (groupActionOrnament)
                return groupActionOrnament(f, disabled || isDisabledPath(f, 'groupActionOrnament'));
              return DEFAULT_CLONE_ACTION(f, disabled || isDisabledPath(f, 'cloneGroupAction'));
            },
            cloneRuleAction: f => {
              if (ruleActionOrnament) return ruleActionOrnament(f, disabled || isDisabledPath(f, 'ruleActionOrnament'));
              return DEFAULT_CLONE_ACTION(f, disabled || isDisabledPath(f, 'cloneRuleAction'));
            },
            lockGroupAction: f => COLLAPSE_ACTION(f, disabled || isDisabledPath(f, 'collapseGroupAction')),
            lockRuleAction: () => null,
            addRuleAction: f => (
              <Button
                size="sm"
                variant="white"
                onClick={f.handleOnClick}
                disabled={f.disabled || disabled || isDisabledPath(f, 'addRuleAction')}
              >
                {f.label}
              </Button>
            ),
            removeRuleAction: f => {
              return DEFAULT_REMOVE_ACTION(f, disabled || isDisabledPath(f, 'removeRuleAction'));
            },
            removeGroupAction: f => {
              return DEFAULT_REMOVE_ACTION(f, disabled || isDisabledPath(f, 'removeGroupAction'));
            },
            operatorSelector: OperatorSelector,
            fieldSelector: FieldSelector,
            valueEditor: valueEditorBuilder
          }}
        />
      </QueryBuilderDnD>
    </DndProviderWrapper>
  );
}
