import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { TestPage } from './pages/test';
import { LoginPage, LogoutPage, SelectClientPage } from './pages/auth';
import Dashboard from './layouts/Dashboard';
import RequireAuth from './auth/RequireAuth';
import ClientProvider from './auth/ClientProvider';
import CompaniesPage from './pages/companies/CompaniesPage';
import UserManagementPage from './pages/settings/users/UserManagementPage';
import { RequirePermissions, RequireStaffPermissions, StaffPermission, UserPermission } from './auth';
import { TalkTracksListPage } from './pages/talk-tracks';
import { ConnectorsPage } from './pages/settings/connectors';
import { ConnectorLogsPage } from './pages/settings/connector-logs';
import SalesforceCodeCapturePage from 'src/pages/settings/connectors/SalesforceCodeCapturePage.tsx';
import HubSpotCodeCapturePage from 'src/pages/settings/connectors/HubSpotCodeCapturePage.tsx';
import NavigateBackToConnectorSettings from 'src/pages/settings/connectors/NavigateBackToConnectorSettings.tsx';
import { DataDirectoryPage } from './pages/data-directory';
import { ExportListPage, ExportViewPage } from './pages/exports';
import ChangePasswordPage from 'src/pages/auth/ChangePasswordPage.tsx';
import ResetPasswordPage from 'src/pages/auth/ResetPasswordPage.tsx';
import ConfirmResetPasswordPage from './pages/auth/ConfirmResetPasswordPage';
import { ScoringEditorPage } from './pages/scoring';
import ScoringConfigListPage from './pages/scoring/ScoringConfigListPage';
import ClientListPage from 'src/pages/staff/clientConfigs/ClientListPage.tsx';
import ClientConfigEditorPage from 'src/pages/staff/clientConfigs/ClientConfigEditorPage.tsx';
import AdminDashboard from 'src/layouts/AdminDashboard';
import ErrorMessage from './components/ErrorMessage';
import MarketEstimationsPage from 'src/pages/staff/marketEstimations/MarketEstimationsPage.tsx';
import EnrichmentUploadPage from './pages/staff/enrichmentUpload/EnrichmentUploadPage';
import ScoringAnalysisResultsPage from './pages/scoring-analysis/ScoringAnalysisResultsPage';
import ToolboxPage from './pages/staff/toolBox/ToolboxPage';
import SourcerEnrichmentPage from 'src/pages/staff/sourcerEnrichment/SourcerEnrichmentPage.tsx';
import ScoringAnalysisReportListPage from './pages/scoring-analysis/ScoringAnalysisReportListPage';
import NewScoringAnalysisPage from './pages/scoring-analysis/NewScoringAnalysisPage';
import EditScoringAnalysisPage from './pages/scoring-analysis/EditScoringAnalysisPage';
import { DataBlocksDictionaryPage } from 'src/pages/staff/dataBlocksDictionary/DataBlocksDictionaryPage.tsx';
import CoverageAnalysisPage from 'src/pages/staff/coverageAnalysis/CoverageAnalysisPage.tsx';
import CoverageAnalysisListPage from 'src/pages/staff/coverageAnalysis/CoverageAnalysisListPage.tsx';
import { PredictiveLabelsTrainingIterationPage } from './pages/predictive-labels/PredictiveLabelsTrainingIteration.tsx';
import AdminProvider from 'src/adminContext/AdminProvider.tsx';
import { PredictiveLabelsListPage } from 'src/pages/predictive-labels/PredictiveLabelsListPage.tsx';
import { PredictiveLabelsModelEditPage } from 'src/pages/predictive-labels/PredictiveLabelsModelEditPage.tsx';
import { TechnographicsConfigListPage } from 'src/pages/staff/technographicsConfig/TechnographicsConfigListPage.tsx';
import { TechnographicsConfigEditPage } from 'src/pages/staff/technographicsConfig/TechnographicsConfigEditPage.tsx';
import CustomDataFieldsPage from './pages/staff/customData/CustomDataFieldsPage.tsx';
import { PageElementConfigListPage } from 'src/pages/staff/pageElements/PageElementsConfigListPage.tsx';
import { PageElementConfigEditPage } from 'src/pages/staff/pageElements/PageElementsConfigEditPage.tsx';
import { PageElementsConfigRefreshPage } from 'src/pages/staff/pageElements/PageElementsConfigRefreshPage.tsx';
import SegmentListPage from './pages/segments/SegmentsListPage/index.tsx';
import SegmentEditOrCreatePage from './pages/segments/SegmentsEditOrCreatePage.tsx';
import SegmentViewPage from './pages/segments/SegmentsViewPage.tsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" errorElement={<ErrorMessage />}>
        <Route
          path="/app/:clientName"
          element={
            <RequireAuth>
              <ClientProvider>
                <Dashboard />
              </ClientProvider>
            </RequireAuth>
          }
        >
          <Route path="" element={<CompaniesPage title={`Companies`} />} />
          <Route path="tags" element={<TalkTracksListPage title={`Tags`} />} />
          <Route path="data-directory" element={<DataDirectoryPage title={`Data Directory`} />} />
          <Route path="exports" element={<ExportListPage title={`Exports`} />} />
          <Route path="exports/:exportId" element={<ExportViewPage title={`Exports`} />} />
          <Route path="scoring" element={<ScoringConfigListPage title={`Scoring`} />} />
          <Route path="scoring/:scoringConfigId" element={<ScoringEditorPage title={`Scoring`} />} />
          <Route
            path="settings/connectors"
            element={
              <RequirePermissions permissions={[UserPermission.ADMIN]}>
                <ConnectorsPage title={`Connectors`} />
              </RequirePermissions>
            }
          />
          <Route
            path="settings/connectors/salesforce"
            element={
              <RequirePermissions permissions={[UserPermission.ADMIN]}>
                <SalesforceCodeCapturePage title={`Connectors`} />
              </RequirePermissions>
            }
          />
          <Route
            path="settings/connectors/hubspot"
            element={
              <RequirePermissions permissions={[UserPermission.ADMIN]}>
                <HubSpotCodeCapturePage title={`Connectors`} />
              </RequirePermissions>
            }
          />
          <Route
            path="settings/connector-logs"
            element={
              <RequirePermissions permissions={[UserPermission.ADMIN]}>
                <ConnectorLogsPage title={`Connector Logs`} />
              </RequirePermissions>
            }
          />
          <Route
            path="settings/users"
            element={
              <RequirePermissions permissions={[UserPermission.ADMIN]}>
                <UserManagementPage title={`User Management`} />
              </RequirePermissions>
            }
          />

          <Route path="predictive-labels" element={<PredictiveLabelsListPage />} />
          <Route path="predictive-labels/new" element={<PredictiveLabelsModelEditPage isNew={true} />} />
          <Route path="predictive-labels/:modelId" element={<PredictiveLabelsModelEditPage isNew={false} />} />
          <Route
            path="predictive-labels/:modelId/version/:version"
            element={<PredictiveLabelsTrainingIterationPage />}
          />

          <Route path="scoring/analysis" element={<ScoringAnalysisReportListPage />} />
          <Route path="scoring/analysis/new" element={<NewScoringAnalysisPage />} />
          <Route path="scoring/analysis/results/:scoringAnalysisReportId" element={<ScoringAnalysisResultsPage />} />
          <Route path="scoring/analysis/edit/:scoringAnalysisReportId" element={<EditScoringAnalysisPage />} />

          <Route path="segments" element={<SegmentListPage />} />
          <Route path="segments/edit/:segmentId" element={<SegmentEditOrCreatePage />} />
          <Route path="segments/view/:segmentId" element={<SegmentViewPage />} />

          <Route path="test" element={<TestPage title={`Test`} />} />
          <Route path="*" element={<Navigate to="" replace />} />
        </Route>
        <Route
          path="/app"
          element={
            <RequireAuth>
              <SelectClientPage title={`Select Client`} />
            </RequireAuth>
          }
        />
        <Route
          //todo add require permissions for gf staff
          path="/admin/"
          element={
            <RequireAuth>
              <RequireStaffPermissions staffPermissions={[StaffPermission.VIEW_ANY_ACCOUNT]}>
                <AdminProvider>
                  <AdminDashboard />
                </AdminProvider>
              </RequireStaffPermissions>
            </RequireAuth>
          }
        >
          <Route path="" element={<Navigate to="/admin/clients" replace />} />
          <Route path="clients" element={<ClientListPage title={`Clients`} />} />
          <Route path="clients/:id" element={<ClientConfigEditorPage title={`Clients`} />} />
          <Route path="market-estimations" element={<MarketEstimationsPage title="Market estimations" />} />
          <Route path="enrichment" element={<EnrichmentUploadPage />} />
          <Route path="sourcer-enrichment" element={<SourcerEnrichmentPage title={'Sourcer enrichment'} />} />
          <Route path="toolbox" element={<ToolboxPage />} />
          <Route path="data-blocks-dictionary" element={<DataBlocksDictionaryPage />} />
          <Route path="custom-data" element={<CustomDataFieldsPage />} />
          <Route path="coverage-analyses" element={<CoverageAnalysisListPage title={'Coverage Analysis'} />} />
          <Route
            path="coverage-analysis"
            element={<CoverageAnalysisPage title={'Coverage Analysis'} readById={false} />}
          />
          <Route
            path="coverage-analysis/:id"
            element={<CoverageAnalysisPage title={'Coverage Analysis'} readById={true} />}
          />
          <Route path="technographics-configs" element={<TechnographicsConfigListPage />} />
          <Route path="technographics-configs/new" element={<TechnographicsConfigEditPage isNew={true} />} />
          <Route path="technographics-configs/:technology" element={<TechnographicsConfigEditPage isNew={false} />} />

          <Route path="page-element-configs" element={<PageElementConfigListPage />} />
          <Route path="page-element-configs/new" element={<PageElementConfigEditPage isNew={true} />} />
          <Route path="page-element-configs/:id" element={<PageElementConfigEditPage isNew={false} />} />
          <Route path="page-element-refresh" element={<PageElementsConfigRefreshPage />} />
        </Route>
        <Route path="/login" element={<LoginPage title={`Login`} />} />
        <Route path="/logout" element={<LogoutPage title={`Logout`} />} />
        <Route path="/change-password" element={<ChangePasswordPage title={`Change Password`} />} />
        <Route path="/reset-password" element={<ResetPasswordPage title={`Reset Password`} />} />
        <Route path="/confirm-reset-password" element={<ConfirmResetPasswordPage title={`Confirm Reset Password`} />} />
        <Route path="/connectors/connect" element={<NavigateBackToConnectorSettings title={`Connectors`} />} />
        <Route path="/connectors/hubspot" element={<NavigateBackToConnectorSettings title={`Connectors`} />} />
        <Route path="/connectors/salesforce" element={<NavigateBackToConnectorSettings title={`Connectors`} />} />
        <Route path="" element={<Navigate to="/app" replace />} />
      </Route>
      <Route path="*" element={<Navigate to="/app" replace />} />
    </>
  )
);
router.subscribe(() => {
  // Need to capture pageviews from internal react router navigation
  const posthog = (window as any)._posthog;
  if (posthog) {
    posthog.capture('$pageview');
  }
  if ((window as any).gfRequiresReload) {
    // If AppVersionReloader says we need to reload, reload on navigate
    console.log('AppVersionReloader: reloading page');
    window.location.reload();
  }
});

export function AppRouter() {
  return <RouterProvider router={router} />;
}
