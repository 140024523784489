import { InputGroup } from 'react-bootstrap';
import './inputs.scss';

export function CheckboxInput({
  title,
  value,
  disabled = false,
  onUpdate
}: {
  title: string;
  value: boolean;
  disabled?: boolean;
  onUpdate: (value: boolean) => void;
}) {
  return (
    <InputGroup className="input-group" size={'sm'}>
      <InputGroup.Text className="input-text-header">{title}</InputGroup.Text>
      <InputGroup.Checkbox checked={value || false} disabled={disabled} onChange={() => onUpdate(!value)} />
    </InputGroup>
  );
}
