import { Header, flexRender, ColumnDef, CellContext } from '@tanstack/react-table';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { CompanySchema, SchemaDataType } from 'src/auth';
import { CRM_CELL_RENDERERS } from 'src/pages/companies/renderers';
import { CompanyRecord } from 'src/pages/companies/types';
import { navigateToCRMRecord } from 'src/pages/companies/utils';
import { getLogoForCompany } from 'src/utils/logos';
import { titleCase } from 'src/utils/string';
import { getReactTableCellRendererForType } from '../Table/cellRenderers';
import { IdCardCell, IdCardIcon } from '../Table/IdCardCell';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { MdOutlineMenuOpen } from 'react-icons/md';

export function renderTableHeaderCell(
  header: Header<CompanyRecord, unknown>,
  schema: CompanySchema,
  toggleSortColumn?: (columnId: string) => void
) {
  const isFixedCell = header.id === 'name';
  const renderSorter = header.column.getCanSort();
  const currentSortDirection = header.column.getIsSorted() as string;

  return (
    <th
      key={header.id}
      className={renderSorter ? 'sortable' : ''}
      onClick={() => toggleSortColumn && toggleSortColumn(header.id)}
    >
      <div className={isFixedCell ? 'fixed-cell' : ''}>
        <OverlayTrigger
          placement="bottom"
          overlay={props => {
            const fieldInfo = schema.find(f => f.fieldName === header.id);
            return <Tooltip {...props}>{fieldInfo?.description}</Tooltip>;
          }}
        >
          <div>
            {renderSorter && (
              <div className="table-column-sorter">
                <FaSortUp className={currentSortDirection === 'asc' ? 'table-column-sorter-selected' : ''} />
                <FaSortDown className={currentSortDirection === 'desc' ? 'table-column-sorter-selected' : ''} />
              </div>
            )}
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </div>
        </OverlayTrigger>
      </div>
    </th>
  );
}

export function buildColumns(
  schema: CompanySchema,
  selectedColumns: string[],
  enableSorting: boolean,
  onCompanySelect?: (row: any) => any
) {
  // The 'company id panel' is always present
  const columns: ColumnDef<CompanyRecord>[] = [
    {
      header: 'Company',
      accessorKey: 'name',
      enableSorting,
      cell: props => {
        const company = props.row.original;
        const companyName = company.name;
        const domain = company.__domain;
        const logoUrl = getLogoForCompany(company);
        const linkedInId = company.__linked_in_id;

        return (
          <div className="fixed-cell">
            <Button
              size="sm"
              variant="white"
              style={{ margin: '8px 0 0 2px', padding: '2px 4px', lineHeight: 1.2, float: 'right' }}
              onClick={() => onCompanySelect && onCompanySelect(company)}
            >
              <MdOutlineMenuOpen style={{ fontSize: 12 }} />
            </Button>
            <IdCardCell
              name={companyName}
              imageSrc={logoUrl}
              onNameClick={() => onCompanySelect && onCompanySelect(company)}
            >
              <IdCardIcon imageSrc="/img/chain.svg" href={`https://${domain}/`} alt="Website" />
              <IdCardIcon
                imageSrc="/img/linkedin.svg"
                href={`https://www.linkedin.com/company/${linkedInId}`}
                alt="Linkedin"
              />
              {schema
                .filter(
                  field =>
                    (field.fieldName === 'crm_sync_hubspot/external_id' ||
                      field.fieldName === 'crm_sync_salesforce/external_id') &&
                    company[field.fieldName]
                )
                .map(field => {
                  const parts = field.fieldSource.split('/');
                  const type = parts[1];
                  const integrationId = parts[2];
                  const externalId = company[field.fieldName];
                  return (
                    <IdCardIcon
                      key={integrationId}
                      imageSrc={`/img/logos/${type}_logo.svg`}
                      style={type === 'salesforce' ? { opacity: 0.5, maxWidth: 12, marginTop: 1 } : { opacity: 0.5 }}
                      alt={`${titleCase(type)} record`}
                      onClick={() => navigateToCRMRecord(integrationId, externalId)}
                    />
                  );
                })}
            </IdCardCell>
          </div>
        );
      }
    }
  ];
  // Map other columns from schema
  for (const fieldName of selectedColumns) {
    const column = schema.find(f => f.fieldName === fieldName);
    if (column) {
      if (column.customColumnDef) {
        columns.push(column.customColumnDef);
        continue;
      }
      if (column.fieldName === 'domain' || column.fieldName === 'name') continue;
      if (fieldName.includes('crm_sync_') && CRM_CELL_RENDERERS[fieldName.split('/')[1]]) {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: (props: CellContext<any, unknown>) => CRM_CELL_RENDERERS[fieldName.split('/')[1]](props.getValue()),
          enableSorting
        });
      } else if (fieldName.includes('year')) {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: getReactTableCellRendererForType(SchemaDataType.String), // Year is not formatted like a number
          enableSorting
        });
      } else {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: getReactTableCellRendererForType(column.type),
          enableSorting
        });
      }
    }
  }
  return columns;
}
