import { BreakingChanges } from 'src/apis/types';
import { objectKeys } from 'src/utils/objectKeys';

/**
 * Helper Util: Verify whether a Scoring Config has dependant features
 *
 * When deleting a ScoringConfig, we first make a call to establish whether any
 * other features are dependant upon that ScoringConfig. This function accepts a
 * validation response and returns a boolean indicating whether the validation
 * contains dependant resources which would be affected by deleting the config.
 */
export const configHasDependantResources = (validation: BreakingChanges): boolean => {
  const resourceKeys = objectKeys(validation);

  return resourceKeys.some(key => validation[key].length > 0);
};
