import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { useErrorBoundary } from 'react-error-boundary';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import API from 'src/utils/API';
import ErrorMessage from 'src/components/ErrorMessage';
import PageLoader from 'src/components/PageLoader';
import { usePageTitle } from 'src/utils/usePageTitle';
import { appClientUrl } from 'src/utils/urls';

function getDashboardUrl(clientName: string, path?: string) {
  return appClientUrl(path ?? '', clientName);
}

interface UserApiClient {
  clientName: string;
  userId: string;
  userPermissions: string[];
}

interface UserApiUser {
  clients: UserApiClient[];
  userId: string;
  email: string;
}

interface UserApiResponse {
  user: UserApiUser;
}

function SelectClientPage({ title }: any) {
  const { showBoundary } = useErrorBoundary();
  const [clients, setClients] = useState<UserApiClient[]>([]);
  const [search, setSearch] = useState(``);
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const gotoPath = searchParams.get('path') ?? '';

  usePageTitle(title);

  const loadPossibleClients = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await API.get<UserApiResponse>('/auth/user', {});
      setClients(data.user.clients);
      setIsLoading(false);
    } catch (err) {
      showBoundary(err);
    }
  }, [showBoundary]);

  useEffect(() => {
    loadPossibleClients();
  }, [loadPossibleClients]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (clients.length === 0) {
    // If 0 then error
    return <ErrorMessage message="You do not have access to any accounts. Please contact support." />;
  }

  if (clients.length === 1) {
    // If only 1 then redirect to that one
    return <Navigate to={getDashboardUrl(clients[0].clientName, gotoPath)} replace />;
  }

  // Otherwise show the user a list of clients they can access
  return (
    <div className="d-flex align-items-center min-vh-100 bg-auth">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={5} xl={4} className="">
            <Card>
              <Card.Header>Choose account</Card.Header>
              <Form.Control
                className={''}
                placeholder={'search...'}
                onChange={event => setSearch(event.target.value)}
              />
              <ListGroup className="list-group-flush" style={{ maxHeight: 400, overflowY: 'auto' }}>
                {clients
                  .filter(c => c.clientName?.includes(search?.toLowerCase()))
                  .map((item, index) => (
                    <ListGroup.Item key={index} className="p-3 m-0">
                      <Link to={getDashboardUrl(item.clientName, gotoPath)}>{item.clientName}</Link>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SelectClientPage;
