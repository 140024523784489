import { useCallback, useEffect, useState } from 'react';
import { CompanyRecord, ContactRecord, ContactsAPIResponse } from 'src/pages/companies/types.ts';
import API from 'src/utils/API';
import { SchemaDataType, useClient } from 'src/auth';
import { useErrorBoundary } from 'react-error-boundary';
import { Badge, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import { titleCase } from 'src/utils/string.ts';
import { BsCircleFill } from 'react-icons/bs';
import { IdCardCell, IdCardIcon } from 'src/components/Table/IdCardCell';
import { renderCell } from 'src/components/Table/cellRenderers';
import CardPaginator from 'src/components/Table/CardPaginator';
import { TableLoading } from 'react-bootstrap-table-loading';
import { CRM_CELL_RENDERERS } from 'src/pages/companies/renderers.tsx';

const pageSize = 10;

function Email(props: { contact: any }) {
  const contact = props.contact;
  if (!props.contact.email) {
    return <span className="text-muted">-</span>;
  }
  return (
    <div>
      <OverlayTrigger
        overlay={props => (
          <Tooltip id={`button-tooltip-${contact.email}`} {...props}>
            {titleCase(contact.email_verification_status)}
          </Tooltip>
        )}
      >
        <div>
          <BsCircleFill
            style={{
              marginRight: 4,
              fontSize: 8
            }}
            className={
              props.contact.email_verification_status === 'verified'
                ? 'icon-success'
                : props.contact.email_verification_status === 'invalid'
                  ? 'icon-danger'
                  : 'icon-warning'
            }
          />
          <>{props.contact.email}</>
        </div>
      </OverlayTrigger>
    </div>
  );
}

function formatTenure(tenure: number) {
  if (isNaN(tenure) || !tenure) return '';
  if (tenure < 12) return `${tenure} months`;
  if (tenure < 24) return `${Math.floor(tenure / 12)} year ${tenure % 12} months`;
  return `${Math.floor(tenure / 12)} years ${tenure % 12} months`;
}

export default function ContactsTable({ company }: any) {
  const { isContactsEnabled, contactsSchema } = useClient();
  const { showBoundary } = useErrorBoundary();
  const [contacts, setContacts] = useState<ContactRecord[] | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  const totalPages = Math.ceil(totalItems / pageSize);

  const loadPage = useCallback(
    async (company: CompanyRecord, pageIndex: number) => {
      setPageIndex(pageIndex);
      setIsLoading(true);
      try {
        const data = await API.get<ContactsAPIResponse>('/app/contacts', {
          companyId: company.__company_id,
          offset: `${pageIndex * pageSize}`,
          limit: `${pageSize}`
        });
        setContacts(data.contacts);
        setTotalItems(data.contactsCnt);
        setIsLoading(false);
      } catch (err) {
        showBoundary(err);
      }
    },
    [showBoundary]
  );

  useEffect(() => {
    if (isContactsEnabled && company?.contacts_count > 0) {
      loadPage(company, pageIndex);
    } else {
      setContacts([]);
    }
  }, [company, pageIndex, isContactsEnabled, loadPage]);

  return (
    <div className="table-loading">
      <Table size="sm" className="table-nowrap table-centered" bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Position</th>
            <th>Country</th>
            <th>Location</th>
            <th>Tenure</th>
            <th>Is former employee</th>
            <th>Added to dataset</th>
            {contactsSchema
              .filter(column => column.fieldName.includes('crm_sync_') && !column.isHiddenFromUser)
              .map(field => (
                <th>{field.displayName}</th>
              ))}
          </tr>
        </thead>
        {isLoading && (contacts === null || contacts?.length === 0) ? (
          <TableLoading columns={8} lines={10} />
        ) : (
          <tbody>
            {contacts?.map(contact => {
              return (
                <tr key={contact.contact_id}>
                  <td className="text-start">
                    <IdCardCell hideAvatar name={`${contact.first_name ?? ''} ${contact.last_name ?? ''}`}>
                      <IdCardIcon imageSrc="/img/linkedin.svg" href={contact.person_url} />
                    </IdCardCell>
                  </td>
                  <td>
                    <Email contact={contact} />
                  </td>
                  <td style={{ minWidth: 200 }}>{renderCell(SchemaDataType.String, contact.position)}</td>
                  <td>{renderCell(SchemaDataType.String, contact.country)}</td>
                  <td style={{ minWidth: 200 }}>{renderCell(SchemaDataType.String, contact.location)}</td>
                  <td>{renderCell(SchemaDataType.String, formatTenure(contact.tenure))}</td>
                  <td>
                    {contact.is_former_employment ? <Badge bg="success">Yes</Badge> : <Badge bg="light">No</Badge>}
                  </td>
                  <td>{renderCell(SchemaDataType.Date, contact.added_to_dataset_at)}</td>
                  {contactsSchema
                    .filter(column => column.fieldName.includes('crm_sync_') && !column.isHiddenFromUser)
                    .map(field => (
                      <td>{CRM_CELL_RENDERERS?.[field.fieldName.split('/')[1]](contact[field.fieldName])}</td>
                    ))}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
      {isLoading && (
        <div className="table-loading-overlay">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <br />
      <CardPaginator pageIndex={pageIndex} totalPages={totalPages} setPage={setPageIndex} />
    </div>
  );
}
