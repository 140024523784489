import { ClientConfigShort } from 'src/apis/clients/types.ts';
import { ListGroup } from 'react-bootstrap';
import ClientConfigItem from './ClientConfigItem.tsx';
import ClientConfigPlaceholder from './ClientConfigPlaceholder';

type ClientConfigslistProps = {
  clientConfigs: ClientConfigShort[];
  isLoading: boolean;
};

export const ClientConfigList = ({ clientConfigs, isLoading }: ClientConfigslistProps) => {
  if (isLoading) {
    return (
      <ListGroup className="list-group-flush my-n3">
        <ClientConfigPlaceholder />
        <ClientConfigPlaceholder />
        <ClientConfigPlaceholder />
      </ListGroup>
    );
  }

  return (
    <ListGroup className="list-group-flush my-n3">
      {!!clientConfigs &&
        clientConfigs?.map((cc: ClientConfigShort, ind) => (
          <ClientConfigItem key={cc.clientName + ind} clientConfig={cc} />
        ))}
      {!clientConfigs?.length && <div>No results</div>}
    </ListGroup>
  );
};
