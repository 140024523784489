import { useEffect, useState } from 'react';
import PageLoader from 'src/components/PageLoader.tsx';
import { DataBlockDefinition } from 'src/apis/clients/types.ts';
import WideLayout from 'src/layouts/Wide';
import { Button, Card, Row, Table } from 'react-bootstrap';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import TypeTag from 'src/pages/data-directory/components/TypeTag.tsx';
import Tag from 'src/components/Tag.tsx';
import { useAdminContext } from 'src/adminContext/hooks.ts';

function DataBlockItem({ dataBlock, expand }: { dataBlock: DataBlockDefinition; expand: boolean }) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(expand);
  }, [expand]);
  return (
    <Row>
      <h3>
        {expanded ? (
          <FiChevronDown onClick={() => setExpanded(false)} />
        ) : (
          <FiChevronRight onClick={() => setExpanded(true)} />
        )}
        {dataBlock.blockName}
        {dataBlock.isParameterisedWithDynamicConfig && <Tag className={'mx-2'}>Dynamic</Tag>}
        {dataBlock.isDepreciated && (
          <Tag color="red" className={'mx-2'}>
            Depreciated
          </Tag>
        )}
      </h3>
      {expanded && (
        <div>
          <div>
            {dataBlock.publicDescription && <p>{dataBlock.publicDescription}</p>}
            {dataBlock.internalNotes && <p>{dataBlock.internalNotes}</p>}
          </div>
          <Table size="sm" className="mapping-table" bordered key={`table_${dataBlock.blockName}`}>
            <thead>
              <tr>
                <th style={{ width: '20%' }}>Name</th>
                <th style={{ width: '15%' }}>Type</th>
                <th style={{ width: '40%' }}>Description</th>
                <th style={{ width: '25%' }}>Internal Notes</th>
              </tr>
            </thead>
            <tbody>
              {dataBlock.fields.map(entry => (
                <tr key={entry.internalName}>
                  <td style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                    {entry.internalName}
                    {entry.isDepreciated && (
                      <Tag color="red" className={'mx-2'}>
                        Depreciated
                      </Tag>
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <TypeTag type={entry.type} />
                  </td>
                  <td style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{entry.publicDescription}</td>
                  <td style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{entry.internalNotes}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {dataBlock.exampleConfig && (
            <pre
              style={{ fontSize: 10, border: '1px solid #eee', marginBottom: '2em', padding: 8, background: '#fafafb' }}
            >
              {JSON.stringify(dataBlock.exampleConfig, null, 2)}
            </pre>
          )}
        </div>
      )}
    </Row>
  );
}

function DataBlocksDictionary({ dataBlocksDictionary }: { dataBlocksDictionary: DataBlockDefinition[] }) {
  const [expandAll, setExpandAll] = useState<boolean>(false);
  return (
    <Card>
      <Card.Body>
        <Button size={'sm'} className={'mb-2'} onClick={() => setExpandAll(!expandAll)}>
          {expandAll ? 'Collapse All' : 'Expand All'}
        </Button>
        {dataBlocksDictionary.map(dataBlock => (
          <DataBlockItem key={dataBlock.blockName} dataBlock={dataBlock} expand={expandAll} />
        ))}
      </Card.Body>
    </Card>
  );
}

export function DataBlocksDictionaryPage() {
  const { dataBlocksDefinitions } = useAdminContext();
  return (
    <WideLayout title={'Data Blocks Dictionary'}>
      {dataBlocksDefinitions ? <DataBlocksDictionary dataBlocksDictionary={dataBlocksDefinitions} /> : <PageLoader />}
    </WideLayout>
  );
}
