import { CompanySchema, SchemaDataType } from 'src/auth';
import { v4 as uuidv4 } from 'uuid';
import { FieldValueFinding, FieldGroup } from './types';
import { FilterOperator } from 'src/components/Filters/ClientCompaniesFilters/types';
import groupBy from 'lodash/groupBy';
import lodashMax from 'lodash/max';
import orderBy from 'lodash/orderBy';
import lodashMin from 'lodash/min';

function calculateScore(s: any) {
  const score = s.impact * 20;
  if (score < -10) return -10;
  if (score > 10) return 10;
  return score;
}

export function enrichBasicScore(
  basicScores: FieldValueFinding[],
  companiesSchema: CompanySchema
): FieldValueFinding[] {
  let cntActive = 0;
  return (
    basicScores
      //.filter((s: any) => s.impact !== 0)
      //.filter((s: any) => s.isSignificant)
      .map((s: any) => {
        const score = calculateScore(s);
        const field = companiesSchema.find(f => f.fieldName === s.fieldName);
        return {
          id: s.id ?? uuidv4(),
          ...s,
          fieldType: field?.type,
          //impact: s.conversion / s.baselineConversion - 1,
          isDefaultActive: s.isSignificant && Math.abs(score) >= 3 && cntActive++ < 100,
          score
        };
      })
      .filter((s: any) => Math.abs(s.score) >= 0.05)
  );
  //.filter((s: any) => Math.abs(s.score) >= 3);
}

export function groupFindings(scores: FieldValueFinding[], companiesSchema: CompanySchema): FieldGroup[] {
  const groupedScores = groupBy(scores, 'fieldName');

  const ret = [];
  for (const groupName of Object.keys(groupedScores)) {
    const groupScores = groupedScores[groupName];
    const field = companiesSchema.find(f => f.fieldName === groupName);

    const scores = groupScores.map(v => v.score);
    let min = lodashMin(scores) ?? 0;
    let max = lodashMax(scores) ?? 0;

    if (min > 0) min = 0;
    if (max < 0) max = 0;

    let weight = 0;
    for (const s of groupScores) {
      weight += Math.max(Math.abs(s.score) - 3, 0);
    }
    weight = weight / Math.log10(groupScores.length + 1);

    ret.push({
      fieldName: groupName,
      findings: groupScores,
      isImpactful: false, // Set below
      weight, //max - min,
      displayLabel: field?.displayName ?? groupName,
      cnt: groupScores.length,
      cntPositive: groupScores.filter(v => v.score > 3).length,
      cntNegative: groupScores.filter(v => v.score < -3).length
    });
  }

  const ret2 = orderBy(ret, 'weight').reverse();

  let i = 0;
  for (const group of ret2) {
    const cnt = group.cntPositive + group.cntNegative;
    group.isImpactful = (cnt > 0 || i < 5) && i < 15; // Is impactful and between 5 and 15 groups
    i++;
  }

  return ret2;
}

export function computeScoringRuleForFinding(s: FieldValueFinding) {
  const value = s.value;
  const fieldType = s.fieldType;

  const base = { score: s.score, field: s.fieldName, id: s.id };

  if (value === null) {
    return { operator: FilterOperator.notExists, value: undefined, ...base };
  }

  if (
    fieldType === SchemaDataType.Number ||
    fieldType === SchemaDataType.Currency ||
    fieldType === SchemaDataType.Percentage
  ) {
    const range = value.split(':').slice(1);
    if (range[0] === 'undefined') {
      return { value: parseFloat(range[1]), operator: FilterOperator.lt, ...base };
    } else if (range[1] === 'undefined') {
      return { value: parseFloat(range[0]), operator: FilterOperator.gte, ...base };
    }
    return { value: [parseFloat(range[0]), parseFloat(range[1])], operator: FilterOperator.between, ...base };
  }
  if (fieldType === SchemaDataType.Multipicklist || fieldType === SchemaDataType.Array) {
    if (value.startsWith('length:')) {
      const len = parseInt(value.split(':')[1]);
      return {
        operator: FilterOperator.arrayLengthGt,
        value: len - 1,
        ...base
      };
    }
    return {
      operator: FilterOperator.arrayContainsAnyOf,
      value: [value],
      ...base
    };
  }
  if (fieldType === SchemaDataType.Boolean) {
    return {
      operator: value ? FilterOperator.booleanTrue : FilterOperator.booleanFalse,
      value: undefined,
      ...base
    };
  }

  return {
    operator: FilterOperator.stringEqualOneOf,
    value: [value],
    ...base
  };
}
