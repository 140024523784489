// Default theme to make react-select similar size to bootstrap
export const DEFAULT_THEME = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#8c6cf5'
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 20,
    baseUnit: 1.75,
    menuGutter: 8,
    borderRadius: 3
  }
});
