import { useState } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { PersonaContactsPreviewTable } from './PersonaContactsPreviewTable';
import { PersonaCompaniesPreviewTable } from './PersonaCompaniesPreviewTable';

type TabKey = 'contact_sample' | 'unmatched_companies' | 'matched_companies';

export function PersonaPreviewModal({ segmentId, proceed, show, criteriaGroups }: any) {
  const [key, setKey] = useState<TabKey>('contact_sample');

  const companiesProps = { segmentId, criteriaGroups };

  return (
    <Modal animation={true} show={show} onHide={() => proceed(null)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Preview persona</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ height: 600, padding: 0 }}>
        <Tabs justify mountOnEnter activeKey={key} id="persona_preview_tabs" onSelect={k => setKey(k as TabKey)}>
          <Tab eventKey="contact_sample" title="Contacts Sample" className="ps-1 pe-1">
            <PersonaContactsPreviewTable {...companiesProps} />
          </Tab>

          <Tab eventKey="unmatched_companies" title="Unmatched Companies" className="ps-1 pe-1">
            <PersonaCompaniesPreviewTable {...companiesProps} companyMatchType="COMPANIES_WITHOUT_CONTACTS" />
          </Tab>

          <Tab eventKey="matched_companies" title="Matched Companies" className="ps-1 pe-1">
            <PersonaCompaniesPreviewTable {...companiesProps} companyMatchType="COMPANIES_WITH_CONTACTS" />
          </Tab>
        </Tabs>
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="light" onClick={() => proceed(null)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
