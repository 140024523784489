import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useClient, UserPermission } from 'src/auth';
import WideLayout from 'src/layouts/Wide';
import { DataDirectoryStore, useDataDirectoryStore } from './state';
import { useEffect, useState } from 'react';
import FieldMappingTable from './components/FieldMappingTable';
import PageLoader from 'src/components/PageLoader';
import './data-directory.scss';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { ConfigTabs } from 'src/pages/data-directory/components/ConfigTabs.tsx';
import { FiDownload } from 'react-icons/fi';
import { downloadFields } from './download';

function calculateNumberOfUnmappedFields(store: DataDirectoryStore): {
  numUnmappedContactFields: number;
  numUnmappedCompanyFields: number;
} {
  let numUnmappedContactFields = 0;
  let numUnmappedCompanyFields = 0;

  for (const entry of store.dataDirectoryCompanyFields) {
    if (entry.mappings.length === 0 && !entry.isSystem) {
      numUnmappedCompanyFields++;
    }
  }
  for (const entry of store.dataDirectoryContactFields) {
    if (entry.mappings.length === 0 && !entry.isSystem) {
      numUnmappedContactFields++;
    }
  }

  return { numUnmappedCompanyFields, numUnmappedContactFields };
}

function calculateUnmappedFieldsWarning({ numUnmappedCompanyFields, numUnmappedContactFields }: any) {
  if (numUnmappedCompanyFields === 0 && numUnmappedContactFields === 0) return null;
  const parts = [];
  if (numUnmappedCompanyFields > 0) parts.push(`${numUnmappedCompanyFields} company`);
  if (numUnmappedContactFields > 0) parts.push(`${numUnmappedContactFields} contact`);
  return `You have ${parts.join(
    ' and '
  )} fields currently unmapped and therefore not synced to your CRM. You can add mappings to connect this data to your CRM below.`;
}

export default function DataDirectoryPage({ title }: any) {
  const client = useClient();

  const state = useDataDirectoryStore();
  const [isEditingCompanies, setIsEditingCompanies] = useState(false);
  const [isEditingContacts, setIsEditingContacts] = useState(false);

  const unmappedFieldsMessage = calculateUnmappedFieldsWarning(calculateNumberOfUnmappedFields(state));

  usePageTitle(title);

  useEffect(() => {
    state.loadDataDirectory();
    // We dont want this to depend on state, otherwise it will reload each change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isDataDirectoryLoading) {
    return <PageLoader />;
  }

  // Need to be admin to edit the mappings
  const canEditMappings = client.userPermissions.includes(UserPermission.ADMIN);

  return (
    <WideLayout title="Data Directory" maxWidthCols={12}>
      <ConfigTabs />
      {unmappedFieldsMessage && <Alert variant="warning">{unmappedFieldsMessage}</Alert>}
      <Card>
        <Card.Header>
          <Row>
            <Col xs={8}>Company field definitions</Col>
            <Col xs={4} className="text-end">
              <Button
                size="sm"
                variant="white"
                className="me-3"
                onClick={() =>
                  downloadFields(state.dataDirectoryCompanyFields, state.integrations, 'GoodFit_Company_Fields.csv')
                }
              >
                <FiDownload /> Export fields
              </Button>
              {canEditMappings && (
                <>
                  Edit mappings:
                  <Form.Check
                    className="d-inline-block"
                    type="switch"
                    checked={isEditingCompanies}
                    onChange={ev => setIsEditingCompanies(ev.target.checked)}
                  />
                </>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <FieldMappingTable
            entries={state.dataDirectoryCompanyFields}
            recordType="company"
            isEditing={isEditingCompanies}
          />
        </Card.Body>
      </Card>
      {client.isContactsEnabled && (
        <Card>
          <Card.Header>
            <Row>
              <Col xs={8}>Contact field definitions</Col>
              <Col xs={4} className="text-end">
                <Button
                  size="sm"
                  variant="white"
                  className="me-3"
                  onClick={() =>
                    downloadFields(state.dataDirectoryContactFields, state.integrations, 'GoodFit_Contact_Fields.csv')
                  }
                >
                  <FiDownload /> Export fields
                </Button>
                {canEditMappings && (
                  <>
                    Edit mappings:
                    <Form.Check
                      className="d-inline-block"
                      type="switch"
                      checked={isEditingContacts}
                      onChange={ev => setIsEditingContacts(ev.target.checked)}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <FieldMappingTable
              entries={state.dataDirectoryContactFields}
              recordType="contact"
              isEditing={isEditingContacts}
            />
          </Card.Body>
        </Card>
      )}
    </WideLayout>
  );
}
