import { Alert, Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import { useDataDirectoryStore } from '../state';
import { useEffect, useState } from 'react';
import { IntegrationFieldMappingInput, RecordType } from '../types';
import toast from 'react-hot-toast';
import { getCreateFieldName } from '../utils/createFieldUtils';
import { titleCase } from 'src/utils/string';

type FieldCreationModalProps = {
  show: boolean;
  onHide: () => void;
  mapping: IntegrationFieldMappingInput;
  recordType: RecordType;
};

const expectedCreateDuration = 20_000;

export default function FieldCreationModal({ show, onHide, mapping, recordType }: FieldCreationModalProps) {
  const store = useDataDirectoryStore();
  const integration = store.integrations.find(i => i.id === mapping.integrationId);
  const [createFieldName, setCreateFieldName] = useState(getCreateFieldName(store, recordType, mapping));
  const [isCreatingField, setIsCreatingField] = useState(false);
  const [modalError, setModalError] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isCreatingField) {
      const timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress === 95) {
            clearInterval(timer);
          }
          const ticks = expectedCreateDuration / 250;
          return Math.min(oldProgress + 100 / ticks, 99);
        });
      }, 250);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isCreatingField]);

  const handleModalOk = async () => {
    if (!integration) return;
    setIsCreatingField(true);
    setProgress(0);

    const response = await store.createExternalField(integration, recordType, mapping, createFieldName);

    if (response?.success) {
      toast.success(`Field ${createFieldName} has been created successfully`);
      onHide();
    } else {
      toast.error('Something went wrong creating this field');
      setModalError(response?.error || 'Something went wrong');
    }
    setProgress(100);
    setIsCreatingField(false);
  };

  const createFieldNameLength = createFieldName.length;
  const createFieldNameTooLong = createFieldNameLength > 40;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Create {titleCase(integration?.integrationType ?? '')} Field</Modal.Header>
      <Modal.Body>
        {isCreatingField ? (
          <ProgressBar now={progress} />
        ) : (
          <>
            {modalError && <Alert variant="danger">{modalError}</Alert>}
            <Form.Group>
              <Form.Label>Field name</Form.Label>
              <Form.Control value={createFieldName} onChange={e => setCreateFieldName(e.target.value)} />
              <Form.Text className="text-end" style={createFieldNameTooLong ? { color: 'red' } : {}}>
                Length {`${createFieldNameLength}/40`}
                {createFieldNameTooLong ? '. Name must be 40 characters or less' : ''}
              </Form.Text>
            </Form.Group>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          size="sm"
          disabled={isCreatingField || createFieldNameTooLong}
          onClick={handleModalOk}
        >
          Create Field
        </Button>
        <Button variant="white" size="sm" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
