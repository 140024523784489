import { ActionWithRulesProps } from 'react-querybuilder';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FiCopy, FiMaximize2, FiMinimize2, FiX } from 'react-icons/fi';
import { useState } from 'react';

export function DEFAULT_REMOVE_ACTION(f: ActionWithRulesProps, disabled: boolean | undefined) {
  return (
    <Button
      size="sm"
      className="removeRule"
      variant="white"
      onClick={f.handleOnClick}
      disabled={f.disabled || disabled}
    >
      <FiX />
    </Button>
  );
}

export function DEFAULT_CLONE_ACTION(f: ActionWithRulesProps, disabled: boolean | undefined) {
  return (
    <OverlayTrigger overlay={<Tooltip id={`tooltip-clone`}>Clone</Tooltip>} placement="top">
      <Button className={'cloneRule'} size="sm" variant="white" onClick={f.handleOnClick} disabled={disabled}>
        <FiCopy />
      </Button>
    </OverlayTrigger>
  );
}

export function COLLAPSE_ACTION(f: ActionWithRulesProps, disabled: boolean | undefined) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <OverlayTrigger
      overlay={<Tooltip id={`tooltip-collapse`}>{collapsed ? 'Expand' : 'Collapse'}</Tooltip>}
      placement="top"
    >
      <Button
        className={'lockRule' + (collapsed ? ' collapsed' : '')}
        size="sm"
        variant="white"
        onClick={() => setCollapsed(!collapsed)}
        disabled={disabled}
      >
        {collapsed ? <FiMaximize2 /> : <FiMinimize2 />}
        {collapsed && <span className="lockRuleCount">{f.rules?.length}</span>}
      </Button>
    </OverlayTrigger>
  );
}
