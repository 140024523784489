import { useState } from 'react';
import { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import { getClientConfigs } from 'src/apis/clients/apis';

export default function ClientSelect({ clientName, setClientName, name }: any) {
  const [clientOptions, setClientOptions] = useState<any[]>([]);

  const loadOptions = (_inputValue: string, callback: (options: any[]) => void) => {
    getClientConfigs().then(cc => {
      const opts = cc.map(c => ({ value: c.clientName, label: `${c.clientName} (${c.lifecycleStage})` }));
      setClientOptions(opts);
      callback(opts);
    });
  };

  return (
    <AsyncSelect
      cacheOptions
      value={clientOptions.find(c => c.value === clientName)}
      onChange={item => setClientName(item?.value)}
      filterOption={createFilter({})}
      loadOptions={loadOptions}
      defaultOptions
      isClearable={true}
      name={name ?? 'clientName'}
      placeholder="Select client"
    />
  );
}
