import { useState, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useIntegrationStore } from 'src/pages/settings/connectors/integrationsStore';
import { isAutoSyncEnabledForSegment } from '../../components/sync/utils/isAutoSyncEnabledForSegment';
import { Segment } from '../../types';
import { Integration } from 'src/apis/integrations';
import { usePeriodic } from 'src/utils/usePeriodic';

export interface SegmentSyncStatuses {
  [key: Segment['id']]: {
    isAutoSync: boolean;
    autoSyncIntegrations: Integration['integrationType'][];
  };
}

/**
 * This hook is responsible for resolving the sync status for a given
 * {@link Segment}.
 *
 * We use this information to render a sync status icon for each segment.
 *
 * For each segment we calculate whether auto sync is enabled and for which
 * integrations auto sync is enabled for.
 *
 * We periodically refresh integrations to keep the statuses in the UI in sync.
 */
export const useSegmentsSyncStatuses = (segments: Segment[]) => {
  const [segmentSyncStatuses, setSegmentSyncStatuses] = useState<SegmentSyncStatuses>({});

  const { fetchIntegrations, integrations } = useIntegrationStore(
    useShallow(state => ({ fetchIntegrations: state.fetchIntegrations, integrations: state.integrations }))
  );

  // Periodically fetching integrations keeps the UI in sync with the statuses
  usePeriodic(() => fetchIntegrations(), 5000, [segments, fetchIntegrations]);

  useEffect(() => {
    if (!segments) return;

    const segmentStatuses = segments.reduce((segAcc, segment) => {
      const autoSyncIntegrations = integrations.reduce(
        (intAcc, integration) => {
          if (isAutoSyncEnabledForSegment(segment, integration)) return [...intAcc, integration.integrationType];
          return intAcc;
        },
        [] as SegmentSyncStatuses[number]['autoSyncIntegrations']
      );

      const isAutoSync = autoSyncIntegrations.length > 0;

      return { ...segAcc, [segment.id]: { isAutoSync, autoSyncIntegrations } };
    }, {} as SegmentSyncStatuses);

    setSegmentSyncStatuses(segmentStatuses);
  }, [integrations, segments]);

  return segmentSyncStatuses;
};
