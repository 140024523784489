import API from 'src/utils/API.ts';
import { TechnographicsConfig, TechnographicsPreview } from 'src/pages/staff/technographicsConfig/types.ts';

export async function createTechnographicsConfig(create: Partial<TechnographicsConfig>) {
  return await API.post<{ item: TechnographicsConfig }>(`/admin/technographics/configs/items`, {}, create);
}

export async function listTechnographicsConfigs(
  filtering: { id: string; value: string }[],
  sorting: string,
  offset: number,
  limit: number
): Promise<{
  items: TechnographicsConfig[];
  count: number;
}> {
  const requestParams: any = {
    sorting,
    limit,
    offset
  };

  filtering.forEach(f => (requestParams[f.id] = f.value));

  return await API.get<{
    items: TechnographicsConfig[];
    count: number;
  }>('/admin/technographics/configs/items', requestParams, {});
}

export async function getTechnographicsConfig(technology: string) {
  return await API.get<{ item: TechnographicsConfig }>(
    `/admin/technographics/configs/items/${encodeURIComponent(technology)}`,
    {}
  );
}

export async function updateTechnographicsConfig(id: string, update: Partial<TechnographicsConfig>) {
  return await API.put<{ item: TechnographicsConfig }>(
    `/admin/technographics/configs/items/${encodeURIComponent(id)}`,
    {},
    update
  );
}

export async function deleteTechnographicsConfig(id: string) {
  return await API.del(`/admin/technographics/configs/items/${encodeURIComponent(id)}`, {});
}

export async function refreshTechnographicsConfig(id: string) {
  return await API.post(`/admin/technographics/configs/${encodeURIComponent(id)}/refresh`, {});
}

export async function optionsForTechnographicsConfigs(
  field: string,
  prefix: string
): Promise<{
  options: string[];
}> {
  return await API.get<{
    options: string[];
  }>(
    '/admin/technographics/configs/options',
    {
      field,
      prefix
    },
    {}
  );
}

export async function requestPreview(config: Partial<TechnographicsConfig & { domain?: string }>): Promise<{
  executionId: string;
}> {
  return await API.post('/admin/technographics/preview', {}, config);
}

export async function previewResult(executionId: string): Promise<{
  status: 'RUNNING' | 'SUCCEEDED' | 'FAILED' | 'TIMED_OUT' | 'ABORTED';
  output: TechnographicsPreview;
}> {
  return await API.get(`/admin/technographics/preview/${executionId}`, {});
}
