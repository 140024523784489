import { Button, Col, Row, Spinner } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts';
import { formatNumber } from 'src/utils/number.ts';
import { ApexOptions } from 'apexcharts';
import { useState } from 'react';
import { CoverageAnalysisResult } from 'src/pages/staff/coverageAnalysis/types.ts';
import { CompanyListModal } from 'src/pages/staff/coverageAnalysis/components/CompanyListModal.tsx';

export function CoverageBar({
  isRunningCoverageAnalysis,
  isRunningMarketEstimations,
  coverageAnalysisResult,
  marketEstimationsValue
}: {
  isRunningCoverageAnalysis: boolean;
  isRunningMarketEstimations: boolean;
  coverageAnalysisResult: CoverageAnalysisResult;
  marketEstimationsValue: number | undefined;
}) {
  const [companiesModalFilters, setCompaniesModalFilters] = useState<any>({});
  const totalTargetCompanies = coverageAnalysisResult.targetCompanies.length;
  const includedTargetCompanies = coverageAnalysisResult.targetCompanies.filter((c: any) => c.included).length;

  let chartSeries;
  if (totalTargetCompanies === 0) {
    chartSeries = [0];
  } else {
    chartSeries = [Math.round((100 * includedTargetCompanies) / totalTargetCompanies)];
  }
  const chartOptions = {
    chart: {
      height: 200,
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%'
        }
      }
    },
    labels: ['Coverage']
  } as ApexOptions;

  return (
    <Row style={{ minHeight: '170px' }}>
      <Col>
        <div className={'d-flex justify-content-center align-items-center'} style={{ height: '100%' }}>
          {(isRunningCoverageAnalysis && (
            <Spinner animation="border" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )) || <ReactApexChart options={chartOptions} series={chartSeries} type="radialBar" height={200} />}
        </div>
      </Col>
      <Col>
        {(isRunningCoverageAnalysis && (
          <div className={'d-flex justify-content-center align-items-center'} style={{ height: '100%' }}>
            <Spinner animation="border" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )) || (
          <>
            <div className="text-muted py-2">Target companies included</div>
            <div className="fs-1 mb-4">{formatNumber(includedTargetCompanies)}</div>
            <Button size="sm" variant="white" onClick={() => setCompaniesModalFilters({ included: true })}>
              View companies
            </Button>
          </>
        )}
      </Col>
      <Col>
        {(isRunningCoverageAnalysis && (
          <div className={'d-flex justify-content-center align-items-center'} style={{ height: '100%' }}>
            <Spinner animation="border" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )) || (
          <>
            <div className="text-muted py-2">Target companies excluded</div>
            <div className="fs-1 mb-4">{formatNumber(totalTargetCompanies - includedTargetCompanies)}</div>
            <Button size="sm" variant="white" onClick={() => setCompaniesModalFilters({ included: false })}>
              View companies
            </Button>
          </>
        )}
      </Col>
      <Col>
        {(isRunningMarketEstimations && (
          <div className={'d-flex justify-content-center align-items-center'} style={{ height: '100%' }}>
            <Spinner animation="border" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )) || (
          <>
            <div className="text-muted py-2">Estimated market size</div>
            <div className="fs-1 mb-4">{marketEstimationsValue && formatNumber(marketEstimationsValue)}</div>
          </>
        )}
      </Col>
      <CompanyListModal
        show={'included' in companiesModalFilters}
        filters={companiesModalFilters}
        onHide={() => setCompaniesModalFilters({})}
        coverageReport={coverageAnalysisResult}
      />
    </Row>
  );
}
