import { CompanyCriteriaFilter, CompanyCriteriaGroup } from 'src/apis/clients/types.ts';

export function applyToSourcingCriteriaLeafs(
  sourcingCriteria: CompanyCriteriaGroup,
  func: (criteria: CompanyCriteriaFilter) => any
): any[] {
  let results: any[] = [];

  if ('rules' in sourcingCriteria) {
    sourcingCriteria.rules.forEach(rule => {
      results = results.concat(applyToSourcingCriteriaLeafs(rule as CompanyCriteriaGroup, func));
    });
  } else {
    results.push(func(sourcingCriteria));
  }

  return results;
}
