import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import DomainOverride from './tools/DomainOverride';
import LookupIndentityResolution from './tools/LookupIndentityResolution';

export default function ToolboxPage() {
  usePageTitle('Toolbox');

  return (
    <NarrowLayout title={'Toolbox'} preheader="Staff admin">
      <DomainOverride />
      <LookupIndentityResolution />
    </NarrowLayout>
  );
}
