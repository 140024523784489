import { Alert, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TechnographicsConfig, TechnographicsPreview } from 'src/pages/staff/technographicsConfig/types.ts';
import { BasicTextInput } from 'src/components/inputs/BasicTextInput.tsx';
import { InMemoryTableWithPagination } from 'src/components/Table/InMemoryTableWithPagination.tsx';
import { ColumnDef } from '@tanstack/react-table';
import { getReactTableCellRendererForType } from 'src/components/Table/cellRenderers.tsx';
import { SchemaDataType } from 'src/auth';
import { getTechnographicsConfig, previewResult, requestPreview } from 'src/pages/staff/technographicsConfig/api.ts';
import { usePeriodic } from 'src/utils/usePeriodic.ts';
import toast from 'react-hot-toast';
import { removeEmptyPatterns } from 'src/pages/staff/technographicsConfig/utils.ts';
import cloneDeep from 'lodash/cloneDeep';

type TechnographicsPreviewModalProps = {
  show: boolean;
  onHide: () => void;
  technology?: string;
  technographicsConfig?: TechnographicsConfig;
  useSavedDetections?: boolean;
};

export function TechnographicsPreviewModal({
  show,
  onHide,
  technology,
  technographicsConfig,
  useSavedDetections
}: TechnographicsPreviewModalProps) {
  const [results, setResults] = useState<TechnographicsPreview>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [executionId, setExecutionId] = useState<string>();
  const [domain, setDomain] = useState<string>('');
  const [didError, setDidError] = useState(false);
  const [executionResult, setExecutionResult] = useState<any>(null);

  const closeModal = useCallback(() => {
    setDomain('');
    setExecutionId(undefined);
    onHide();
  }, [onHide]);

  const prepareRequest = useCallback(
    async (domain?: string) => {
      let request: any = cloneDeep(technographicsConfig);
      if (!technographicsConfig && technology) {
        request = (await getTechnographicsConfig(technology)).item;
      }
      if (domain) {
        request.domain = domain;
      }
      request.jobsPatterns = removeEmptyPatterns(request);
      request.useSavedDetections = useSavedDetections;
      return request;
    },
    [technographicsConfig, technology, useSavedDetections]
  );

  const checkForDomain = useCallback(() => {
    setIsLoading(true);
    prepareRequest(domain)
      .then(req => requestPreview(req))
      .then(response => {
        setExecutionId(response.executionId);
      });
  }, [domain, prepareRequest]);

  useEffect(() => {
    if (!show) return;
    setIsLoading(true);
    if (technographicsConfig) {
      prepareRequest()
        .then(req => requestPreview(req))
        .then(response => {
          setExecutionId(response.executionId);
        });
    } else if (technology) {
      prepareRequest()
        .then(req => requestPreview(req))
        .then(response => {
          setExecutionId(response.executionId);
        });
    }
  }, [show, technology, technographicsConfig, prepareRequest]);

  usePeriodic(
    () => {
      if (executionId) {
        previewResult(executionId).then(response => {
          if (response.status === 'SUCCEEDED') {
            setResults(response.output);
            setExecutionResult(response);
            setExecutionId(undefined);
            setIsLoading(false);
          } else if (['FAILED', 'TIMED_OUT', 'ABORTED'].includes(response.status)) {
            setResults(undefined);
            setExecutionId(undefined);
            setIsLoading(false);
            setExecutionResult(response);
            console.warn('Preview failed', response.status);
            toast.error('Preview failed');
            setDidError(true);
          }
        });
      }
    },
    3000,
    [executionId]
  );

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        id: 'domain',
        enableSorting: false,
        enableColumnFilter: false,
        cell: c => (
          <a href={`https://${c.row.original.domain}`} target="_blank">
            {c.row.original.domain}
          </a>
        ),
        header: 'Company'
      },
      {
        id: 'detectionSources',
        accessorFn: (row: any) => row.detectionSources,
        enableSorting: false,
        enableColumnFilter: false,
        cell: getReactTableCellRendererForType(SchemaDataType.Array),
        header: 'Detected On'
      },
      {
        id: 'detectionCount',
        accessorFn: (row: any) => row.detectionCount,
        enableSorting: false,
        enableColumnFilter: false,
        cell: getReactTableCellRendererForType(SchemaDataType.Number),
        header: 'Detection Count'
      },
      {
        id: 'sampleUrl',
        enableSorting: false,
        enableColumnFilter: false,
        cell: c => (
          <div>
            <OverlayTrigger
              overlay={props => {
                return (
                  <Tooltip {...props}>
                    {c.row.original.sampleUrls.map((url: string, index: number) => (
                      <div key={`url-${index}`}>{url}</div>
                    ))}
                  </Tooltip>
                );
              }}
            >
              <div
                style={{
                  maxWidth: '200px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <a href={c.row.original.sampleUrl} target="_blank">
                  {c.row.original.sampleUrl}
                </a>
              </div>
            </OverlayTrigger>
          </div>
        ),
        header: 'Sample URL'
      },
      {
        id: 'lastSeenAt',
        accessorFn: (row: any) => row.lastSeenAt,
        enableSorting: false,
        enableColumnFilter: false,
        cell: getReactTableCellRendererForType(SchemaDataType.Date),
        header: 'Last Seen At'
      },
      {
        id: 'snippet',
        accessorFn: (row: any) => row.snippet,
        enableSorting: false,
        enableColumnFilter: false,
        cell: c => (
          <div>
            <OverlayTrigger
              overlay={props => {
                return (
                  <Tooltip {...props}>
                    <div>{c.row.original.snippet}</div>
                  </Tooltip>
                );
              }}
            >
              <div
                style={{
                  maxWidth: '200px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {c.row.original.snippet ? `...${c.row.original.snippet.slice(40, -40)}...` : ''}
              </div>
            </OverlayTrigger>
          </div>
        ),
        header: 'Snippet'
      }
    ],
    []
  );

  return (
    <Modal show={show} onHide={closeModal} size={'xl'} backdrop="static">
      <Modal.Header closeButton>
        Preview technographics for:&nbsp;
        <strong>{technographicsConfig?.technology || technology}</strong>
        {useSavedDetections ? <>&nbsp;(previously generated detections)</> : ''}
      </Modal.Header>
      <Modal.Body className={'pb-0'}>
        <div className={'mb-2 '}>
          <BasicTextInput
            title={'Domain'}
            value={domain}
            onUpdate={setDomain}
            disabled={isLoading}
            adornment={
              <Button size={'sm'} disabled={isLoading} onClick={checkForDomain}>
                Check
              </Button>
            }
          />
        </div>
        {didError && (
          <Alert className="my-5" variant="danger">
            Error occurred generating preview.&nbsp;
            <a href={executionResult.awsConsoleLink} target="_blank">
              View execution in AWS console
            </a>
            .
            <pre style={{ textWrap: 'wrap' }}>
              <b>{executionResult.error}:</b> {executionResult.errorCause?.errorMessage}
            </pre>
          </Alert>
        )}
        {!didError && (
          <>
            {!isLoading && (
              <div>
                Found <strong>{results?.companyCount}</strong> companies (<strong>{results?.activeCompanyCount}</strong>{' '}
                active)
              </div>
            )}
            <div className={'mb-2'}></div>
            <InMemoryTableWithPagination
              columns={columns}
              data={results?.preview || []}
              emptyMessage="No data available for preview"
              isLoading={isLoading}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="light" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
