import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import gravatarUrl from 'gravatar-url';
import { Collapse, Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { FiBookmark, FiBriefcase, FiChevronDown, FiList, FiSettings, FiSliders, FiTag } from 'react-icons/fi';

import goodFitLogo from '/img/goodfit-logo-text.svg';
import { useAuth, useClient } from 'src/auth';
import { GrGroup } from 'react-icons/gr';
import { ContactCreditSummary } from './ContactCreditSummary';

interface NavItem {
  id: string;
  children?: string[];
  title?: string;
  url?: string;
  icon?: JSX.Element;
  requirePermission?: string;
  requireStaffPermission?: string;
  topLevel?: boolean;
  requireFeatureFlag?: string;
  excludeWithFeatureFlag?: string;
}

function relativePath(path: string, clientName: string): string {
  if (path.startsWith('~')) {
    return (`/app/${clientName}` + path.slice(1)).replace(/\/$/, '');
  }
  return path;
}

const allMenuItems: Record<string, NavItem> = {
  base: {
    children: ['companies', 'segments', 'tags', 'scoring', 'predictiveLabels', 'dataDirectory', 'settings', 'staff'],
    id: 'base',
    topLevel: true
  },
  companies: {
    id: 'companies',
    title: 'Companies',
    icon: <FiBriefcase className="feather" />,
    url: '~/'
  },
  scoring: {
    id: 'scoring',
    title: 'Scoring',
    children: ['scoringFields', 'scoringAnalysis'],
    icon: <FiSliders className="feather" />
  },
  scoringFields: {
    id: 'scoringFields',
    title: 'Scoring fields',
    url: '~/scoring'
  },
  scoringAnalysis: {
    id: 'scoringAnalysis',
    title: 'Scoring analysis',
    url: '~/scoring/analysis'
  },
  dataDirectory: {
    id: 'dataDirectory',
    title: 'Data Directory',
    icon: <FiList className="feather" />,
    url: '~/data-directory'
  },
  segments: {
    id: 'segments',
    title: 'Segments',
    icon: <GrGroup className="feather" />,
    url: '~/segments'
  },
  predictiveLabels: {
    id: 'predictiveLabels',
    title: 'Predictive Labels',
    icon: <FiBookmark className="feather" />,
    url: '~/predictive-labels',
    requireStaffPermission: 'ADMIN_BETA_TESTER'
  },
  settings: {
    children: ['connectors', 'connectorLogs', 'users', 'exports'],
    requirePermission: 'ADMIN',
    id: 'settings',
    title: 'Settings',
    icon: <FiSettings className="feather" />
  },
  tags: {
    id: 'tags',
    title: 'Tags',
    url: '~/tags',
    icon: <FiTag className="feather" />
  },
  users: {
    id: 'users',
    title: 'User Management',
    url: '~/settings/users',
    requirePermission: 'ADMIN'
  },
  connectors: {
    id: 'connectors',
    title: 'Connectors',
    url: '~/settings/connectors',
    requirePermission: 'ADMIN'
  },
  connectorLogs: {
    id: 'connectorLogs',
    title: 'Connector Logs',
    url: '~/settings/connector-logs',
    requirePermission: 'ADMIN'
  },
  exports: {
    id: 'exports',
    title: 'Exports',
    url: '~/exports',
    requirePermission: 'ADMIN'
  }
};

function filterMenuItems(
  userPermissions: string[],
  featureFlags: Record<string, boolean>,
  staffPermissions?: string[]
) {
  const ret: Record<string, NavItem> = {};

  for (const id of Object.keys(allMenuItems)) {
    const item = allMenuItems[id];
    if (item.requirePermission) {
      if (!userPermissions.includes(item.requirePermission)) continue;
    }
    if (item.requireStaffPermission) {
      if (!staffPermissions?.includes(item.requireStaffPermission)) continue;
    }
    if (item.requireFeatureFlag) {
      if (!featureFlags[item.requireFeatureFlag]) continue;
    }
    if (item.excludeWithFeatureFlag) {
      if (featureFlags[item.excludeWithFeatureFlag]) continue;
    }
    ret[id] = item;
  }

  return ret;
}

export default function Sidenav({ ...props }) {
  const { pathname } = useLocation();
  const { clientName, userPermissions, featureFlags } = useClient();
  const { user } = useAuth();
  const data = useMemo(
    () => filterMenuItems(userPermissions, featureFlags, user?.staffPermissions),
    [userPermissions, featureFlags, user?.staffPermissions]
  );

  const auth = useAuth();
  const userEmail = auth.user?.email as string;
  const userImageUrl = gravatarUrl(userEmail, {
    size: 200,
    default: 'retro'
  });

  const [activeItemId, setActiveItemId] = useState(() => {
    return Object.keys(data).filter(itemId => {
      const relativeTabPath = data[itemId].url;
      const tabPath = relativePath(relativeTabPath || '', clientName);
      const isActive =
        (relativeTabPath && relativeTabPath !== '~/' && pathname.startsWith(tabPath)) || pathname === tabPath;
      return isActive;
    })[0];
  });

  function isExpanded(itemId: string) {
    if (activeItemId === itemId) {
      return true;
    }

    return isParent(itemId);
  }

  function isParent(itemId: string) {
    const item = data[itemId];
    if (!item) return false;

    if (!item.children) {
      return false;
    }

    if (item.children.includes(activeItemId)) {
      return true;
    }

    let result = false;

    item.children.forEach((childId: string) => {
      if (!data[childId]) return;
      if (isParent(childId)) {
        result = true;
      }
    });

    return result;
  }

  function getItems(ids: string[]) {
    return ids.map(function (id: string, index: number) {
      const item = data[id];
      if (!item) return null;
      if (!item.topLevel) return null;

      return (
        <div key={id}>
          {index > 0 && <hr className="navbar-divider" />}
          {item.title && <h6 className="navbar-heading">{item.title}</h6>}
          {item.children && <Nav>{getSubitems(item.children, id, ids)}</Nav>}
        </div>
      );
    });
  }

  function getSubitems(ids: string[], parentId: string, arr: string[]) {
    let bestActiveId = '';
    let bestActiveIdUrlLength = 0;

    for (const id of ids) {
      const relativeTabPath = data[id]?.url;
      if (relativeTabPath) {
        const tabPath = relativePath(relativeTabPath || '', clientName);
        const isActive =
          (relativeTabPath && relativeTabPath !== '~/' && pathname.startsWith(tabPath)) || pathname === tabPath;
        const urlLength = tabPath.length;

        if (isActive && bestActiveIdUrlLength < urlLength) {
          bestActiveIdUrlLength = urlLength;
          bestActiveId = id;
        }
      }
    }

    return ids.map(function (id) {
      //const idx = arr.splice(arr.indexOf(id), 1) as unknown as string;
      const item = data[id];
      if (!item) return <div key={id}></div>;

      //const relativeTabPath = data[id].url;
      //const tabPath = relativePath(relativeTabPath || '', clientName);
      const isActive = id === bestActiveId;
      // (relativeTabPath && relativeTabPath !== '~/' && pathname.startsWith(tabPath)) || pathname === tabPath;

      return (
        <Nav.Item key={id}>
          {item.children ? (
            <>
              <Nav.Link onClick={() => handleClick(id, parentId)} role="button">
                {item.icon}
                {item.title}
                <FiChevronDown className={`ms-auto nav-chevron ${isExpanded(id) && 'active'}`} />
              </Nav.Link>
              <Collapse in={isExpanded(id)}>
                <div>
                  <div className="nav nav-sm flex-column">{getSubitems(item.children, id, arr)}</div>
                </div>
              </Collapse>
            </>
          ) : (
            <Nav.Link
              as={Link}
              to={relativePath(item.url || '~/', clientName)}
              active={isActive}
              onClick={() => handleClick(id, parentId)}
            >
              {item.icon}
              {item.title}
            </Nav.Link>
          )}
        </Nav.Item>
      );
    });
  }

  function handleClick(itemId: string, parentId: string, setVisible?: (isVisible: boolean) => void) {
    setActiveItemId(isExpanded(itemId) ? parentId : itemId);

    if (setVisible) {
      setVisible(false);
    }
  }

  const toggler = <Navbar.Toggle />;

  const brand = (
    <div className="navbar-brand-container">
      <Navbar.Brand as={Link} to={relativePath('~/', clientName)} style={{ padding: '16px 0 24px' }}>
        <img className="navbar-brand-img" src={goodFitLogo} alt="GoodFit" style={{ height: 20 }} />
      </Navbar.Brand>
    </div>
  );

  const userCanAccessManyAccounts = !!(auth?.user && auth.user?.clients?.length > 1);
  const userHasStaffPermissions = !!(auth?.user?.staffPermissions?.length && auth?.user?.staffPermissions?.length > 0);

  const footer = (
    <div className="navbar-user d-none d-md-flex">
      <Dropdown drop="up">
        <Dropdown.Toggle
          size="sm"
          className="avatar avatar-online"
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            margin: 0,
            width: 40,
            height: 40
          }}
          role="button"
        >
          <img className="rounded-circle avatar-img" src={userImageUrl} alt={userEmail} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {userCanAccessManyAccounts && (
            <>
              <Dropdown.Item as={Link} to="/app">
                Switch account
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          {userHasStaffPermissions && (
            <>
              <Dropdown.Item as={Link} to="/admin">
                Go to Admin
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={`/admin/clients/${clientName}`}>
                Admin: View client
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item as={Link} to="/change-password">
            Change password
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/logout">
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const collapse = (
    <Navbar.Collapse {...props}>
      {getItems(Object.keys(data))}
      <div className="mt-auto mb-md-4" />
      <ContactCreditSummary />
      {footer}
    </Navbar.Collapse>
  );

  return (
    <>
      <Navbar expand="md" className="navbar-vertical fixed-start" collapseOnSelect={true} {...props}>
        <Container fluid>
          {toggler}
          {brand}
          {collapse}
        </Container>
      </Navbar>
    </>
  );
}
