/** To be kept in sync with gf-sourcers/src/commons/databases/src/db-models/ResourceLock.model.ts */
export enum LockableResource {
  ClientConfig = 'client_config'
}

/**
 * To be kept in sync with gf-sourcers/src/commons/databases/src/db-models/ResourceLock.model.ts
 *
 * NOTE: Here on the front end, lockedAt and releasedAt are strings - we don't
 * send Date objects over the wire.
 */
export interface ResourceLock {
  /** @see {@link LockableResource} */
  resourceType: LockableResource;
  resourceId: string;
  /** A user email, system name or unique identifier for the lockee */
  lockedBy: string;
  /** The time the last lock was secured against the resource */
  lockedAt: string;
  /** The time the last lock was released for the resource */
  releasedAt: string | null;
}
