import {
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  Header,
  useReactTable
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { Button, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import { TableLoading } from 'react-bootstrap-table-loading';
import { BsCircleFill } from 'react-icons/bs';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { MdOutlineMenuOpen } from 'react-icons/md';
import { CompanySchema, SchemaDataType, useClient } from 'src/auth';
import EmptyMessage from 'src/components/EmptyMessage';
import PageLoader from 'src/components/PageLoader';
import { CELL_RENDERERS, getReactTableCellRendererForType } from 'src/components/Table/cellRenderers';
import { IdCardCell, IdCardIcon } from 'src/components/Table/IdCardCell';
import Tag from 'src/components/Tag';
import { CRM_CELL_RENDERERS } from 'src/pages/companies/renderers';
import { CompanyRecord } from 'src/pages/companies/types';
import { formatPhoneNumber, getCountryCodeFromPhoneNumber } from 'src/utils/phoneNumbers';
import { formatDateString, snakeCaseToWords, titleCase } from 'src/utils/string';

function formatRemovalReason(reason: string): string {
  if (reason === 'CONTACT_NO_LONGER_MATCH_CRITERIA') return 'Contact no Longer Matches Criteria';
  return snakeCaseToWords(reason);
}

function Email(props: { contact: any }) {
  const contact = props.contact;
  if (!props.contact.email) {
    return <span className="text-muted">-</span>;
  }
  return (
    <div>
      <OverlayTrigger
        overlay={props => (
          <Tooltip id={`button-tooltip-${contact.email}`} {...props}>
            {titleCase(contact.email_verification_status)}
          </Tooltip>
        )}
      >
        <div>
          <BsCircleFill
            style={{
              marginRight: 4,
              fontSize: 8
            }}
            className={
              props.contact.email_verification_status === 'VERIFIED'
                ? 'icon-success'
                : props.contact.email_verification_status === 'RISKY'
                  ? 'icon-warning'
                  : 'icon-danger'
            }
          />
          <>{props.contact.email}</>
        </div>
      </OverlayTrigger>
    </div>
  );
}

function renderTableHeaderCell(
  header: Header<CompanyRecord, unknown>,
  toggleSortColumn: (columnId: string) => void,
  schema: CompanySchema
) {
  const isFixedCell = header.id === 'raw_name';
  const renderSorter = header.column.getCanSort();
  const currentSortDirection = header.column.getIsSorted() as string;

  return (
    <th key={header.id} className={renderSorter ? 'sortable' : ''} onClick={() => toggleSortColumn(header.id)}>
      <div className={isFixedCell ? 'fixed-cell' : ''}>
        <OverlayTrigger
          overlay={props => {
            const fieldInfo = schema.find(f => f.fieldName === header.id);
            let description = fieldInfo?.description;
            if (header.id === 'raw_name') description = 'Persons name and LinkedIn URL';
            if (header.id === 'company_name') description = 'Company they work for';
            if (header.id === 'persona_name') description = 'Persona they matched';
            if (header.id === 'inclusion_status') description = 'Status of this contact in the segment';
            if (header.id === 'persona_rank')
              description = 'Persona rank against other people matching persona filters';
            return <Tooltip {...props}>{description}</Tooltip>;
          }}
        >
          <div>
            {renderSorter && (
              <div className="table-column-sorter">
                <FaSortUp className={currentSortDirection === 'asc' ? 'table-column-sorter-selected' : ''} />
                <FaSortDown className={currentSortDirection === 'desc' ? 'table-column-sorter-selected' : ''} />
              </div>
            )}
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </div>
        </OverlayTrigger>
      </div>
    </th>
  );
}

const PAGE_SIZE = 50;

function getLogoForCompany(company: CompanyRecord) {
  return `https://logo.clearbit.com/${company.company_domain}`;
}

function buildColumns(
  schema: CompanySchema,
  selectedColumns: string[],
  onCompanySelect: (row: any) => any,
  hasPhoneNumbersFeature: boolean
) {
  // The 'company id panel' is always present
  const columns: ColumnDef<CompanyRecord>[] = [
    {
      accessorKey: 'raw_name',
      header: 'Person',
      enableSorting: true,
      cell: props => {
        const contact = props.row.original;

        return (
          <div className="fixed-cell">
            <IdCardCell hideAvatar name={`${contact.first_name ?? ''} ${contact.last_name ?? ''}`}>
              <IdCardIcon imageSrc="/img/linkedin.svg" href={contact.person_url} />
            </IdCardCell>
          </div>
        );
      }
    },
    {
      header: 'Company',
      accessorKey: 'company_name',
      enableSorting: true,
      cell: props => {
        const company = props.row.original;
        const companyName = company.company_name;
        const domain = company.company_domain;
        const logoUrl = getLogoForCompany(company);
        const linkedInId = company.company_linked_in_id;

        return (
          <div style={{ minWidth: 250 }}>
            <Button
              size="sm"
              variant="white"
              style={{ margin: '8px 0 0 2px', padding: '2px 4px', lineHeight: 1.2, float: 'right' }}
              onClick={() => onCompanySelect(company)}
            >
              <MdOutlineMenuOpen style={{ fontSize: 12 }} />
            </Button>
            <IdCardCell name={companyName} imageSrc={logoUrl} onNameClick={() => onCompanySelect(company)}>
              <IdCardIcon imageSrc="/img/chain.svg" href={`https://${domain}/`} alt="Website" />
              <IdCardIcon
                imageSrc="/img/linkedin.svg"
                href={`https://www.linkedin.com/company/${linkedInId}`}
                alt="Linkedin"
              />
            </IdCardCell>
          </div>
        );
      }
    },
    {
      header: 'Matched persona',
      accessorKey: 'persona_name',
      cell: props => {
        const contact = props.row.original;
        return CELL_RENDERERS['Array']([contact.persona_name]);
      },
      enableSorting: true
    },
    {
      header: 'Persona rank',
      accessorKey: 'persona_rank',
      enableSorting: true
    }
  ];
  // Map other columns from schema
  for (const fieldName of selectedColumns) {
    const column = schema.find(f => f.fieldName === fieldName);
    if (column) {
      if (column.fieldName === 'domain' || column.fieldName === 'name') continue;
      if (fieldName.includes('crm_sync_') && CRM_CELL_RENDERERS[fieldName.split('/')[1]]) {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: (props: CellContext<any, unknown>) => CRM_CELL_RENDERERS[fieldName.split('/')[1]](props.getValue()),
          enableSorting: true
        });
      } else if (fieldName.includes('email')) {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: props => <Email contact={props.row.original} />,
          enableSorting: true
        });
      } else if (fieldName.includes('year')) {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: getReactTableCellRendererForType(SchemaDataType.String), // Year is not formatted like a number
          enableSorting: true
        });
      } else if (fieldName.includes('phone_number')) {
        if (hasPhoneNumbersFeature) {
          columns.push({
            header: column.displayName,
            accessorKey: column.fieldName,
            cell: props => {
              const phoneNumber = props.row.original.phone_number;
              const countryCode = getCountryCodeFromPhoneNumber(phoneNumber);

              return (
                <div className="d-flex gap-2 align-items-center">
                  {countryCode && phoneNumber ? (
                    <img
                      width="16px"
                      height="16px"
                      alt={countryCode}
                      title={countryCode}
                      src={`https://flagsapi.com/${countryCode}/shiny/16.png`}
                    />
                  ) : (
                    <span style={{ width: '16px', height: '16px' }} />
                  )}
                  {formatPhoneNumber(phoneNumber)}
                </div>
              );
            },
            enableSorting: true
          });
        }
      } else {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: getReactTableCellRendererForType(column.type),
          enableSorting: true
        });
      }
    }
  }
  columns.push({
    header: 'Inclusion Status',
    accessorKey: 'inclusion_status',
    cell: props => {
      const inclusionStatus = props.row.original.inclusion_status;
      const includedAt = props.row.original.included_at;
      const removedAt = props.row.original.removed_at;
      const removalReason = props.row.original.removed_reason;

      if (inclusionStatus === 'INCLUDED') {
        return (
          <Tag color="green" overlayText={`Contact was added ${formatDateString(includedAt)}`}>
            Included
          </Tag>
        );
      } else if (inclusionStatus === 'WAS_INCLUDED_REMOVED') {
        return (
          <Tag
            color="orange"
            overlayText={`Contact was removed ${formatDateString(removedAt)}: ${formatRemovalReason(removalReason)}`}
          >
            Removed
          </Tag>
        );
      } else if (inclusionStatus === 'CANDIDATE_CHECKED_NOT_INCLUDED') {
        return (
          <Tag color="grey" overlayText="We could not find email address for this contact">
            Invalid.
          </Tag>
        );
      }
      return <Tag>{inclusionStatus}</Tag>;
    },
    enableSorting: true
  });
  return columns;
}

const defaultSelectedColumns = ['email', 'position', 'country', 'is_former_employment', 'phone_number'];

type SortDefinition = { orderBy?: string; orderDirection: 'ASC' | 'DESC' };

export default function ContactsTable({
  contacts,
  onCompanySelect,
  isLoading,
  totalItems,
  schema,
  selectedColumns,
  isSearchOngoing,
  onSortChange
}: any) {
  const [sort, setSort] = useState<SortDefinition>({ orderBy: undefined, orderDirection: 'ASC' });

  const { hasFeatureFlag } = useClient();
  const hasPhoneNumbersFeature = hasFeatureFlag('phoneNumbers');

  const columns = useMemo(
    () => buildColumns(schema, selectedColumns ?? defaultSelectedColumns, onCompanySelect, hasPhoneNumbersFeature),
    [schema, onCompanySelect, selectedColumns, hasPhoneNumbersFeature]
  );

  const pageCount = Math.ceil(totalItems / PAGE_SIZE);

  const table = useReactTable({
    initialState: {
      columnOrder: [
        'raw_name',
        'company_name',
        'persona_name',
        'persona_rank',
        'email',
        'phone_number',
        'position',
        'country'
      ]
    },
    columns,
    data: contacts ?? [],
    manualPagination: true,
    manualSorting: true,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting: [{ id: sort.orderBy ?? '', desc: sort.orderDirection === 'DESC' }]
    }
  });

  if (contacts?.length === 0) {
    if (isSearchOngoing) {
      return (
        <PageLoader
          style={{ paddingTop: '10em', paddingBottom: '10em' }}
          fullHeight={false}
          message="Searching. No contacts found yet... please wait"
        />
      );
    } else {
      return (
        <EmptyMessage
          style={{ paddingTop: '10em', paddingBottom: '10em' }}
          message="No contacts found matching persona(s)"
        />
      );
    }
  }

  return (
    <div className="table-loading">
      <Table
        size="sm"
        className="card-table table-nowrap companies-table table-centered table-fixed-first-column table-sticky-header"
        style={{ position: 'relative' }}
        hover
        responsive
      >
        <thead style={{ position: 'sticky', top: '0' }}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header =>
                renderTableHeaderCell(
                  header,
                  (columnId: string) => {
                    let newSort: SortDefinition | undefined;
                    if (sort?.orderBy !== columnId) {
                      newSort = {
                        orderBy: columnId,
                        orderDirection: 'ASC'
                      };
                    } else if (sort.orderDirection == 'ASC') {
                      newSort = {
                        orderBy: columnId,
                        orderDirection: 'DESC'
                      };
                    } else {
                      // 3rd click to unset sorting
                      newSort = {
                        orderDirection: 'ASC'
                      };
                    }
                    setSort(newSort);
                    if (onSortChange) onSortChange(newSort);
                  },
                  schema
                )
              )}
            </tr>
          ))}
        </thead>
        {isLoading && (contacts === null || contacts?.length === 0) ? (
          <TableLoading columns={columns.length} lines={15} />
        ) : (
          <tbody className="fs-base">
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
      {isLoading && table.getRowModel().rows?.length > 0 && (
        <div className="table-loading-overlay">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}
