export const defaultBinaryLabels = [
  {
    label: 'True',
    description: '',
    trainingExamples: []
  },
  {
    label: 'False',
    description: '',
    trainingExamples: []
  }
];

export const defaultMulticlassLabels = [
  {
    label: 'Other',
    description: '',
    trainingExamples: []
  }
];
