import seniorityMatrix from './components/view/personas/seniorityOptions.json';

import { CompanySchema } from 'src/auth';
import { Segment } from './types';
import uniq from 'lodash/uniq';

const defaultColumns = ['name', 'matched_personas', 'country', 'linked_in_industry'];

export function computeTableColumns(_schema: CompanySchema, filters: any, limitOptions: any) {
  const ret: string[] = [];
  collectFields(filters, ret);
  if (limitOptions?.orderBy) {
    ret.push(limitOptions.orderBy);
  }
  return uniq([...defaultColumns, ...uniq(ret), 'inclusion_status']);
}

function collectFields(filtersOrRule: any, fields: string[]) {
  if (filtersOrRule?.field) {
    fields.push(filtersOrRule.field);
  }
  if (filtersOrRule?.rules?.length > 0) {
    for (const f of filtersOrRule.rules) {
      collectFields(f, fields);
    }
  }
}

export function ensureRequiredTableColumnsExist(columns: string[] | undefined) {
  if (!columns) return columns;
  let modifiedColumns = [...columns];
  if (!columns.includes('inclusion_status')) modifiedColumns = ['inclusion_status', ...modifiedColumns];
  if (!columns.includes('matched_personas')) modifiedColumns = ['matched_personas', ...modifiedColumns];
  return modifiedColumns;
}

export const mapSeniority = (seniority: string): string =>
  seniorityMatrix.find(({ value }) => value === seniority)?.label ?? seniority;

/**
 * Helper Util: Identify whether the given segment has any segment personas in
 * an error state.
 *
 * NOTE: This util is reliant upon Segments with Relations.
 *
 * @see `apis.(loadSegments|loadSegment)` for more details.
 */
export const segmentPersonasHaveErrors = (segment: Segment) =>
  segment.segmentPersonas?.some(({ searchStatus }) => searchStatus === 'ERROR');
