import { Button, Offcanvas, Stack } from 'react-bootstrap';
import { ErrorDetails, ErrorSummaryRow, isAnyJobRunning, loadErrorsDetails, startJob } from 'src/apis/integrations';
import { mapError } from 'src/pages/settings/connector-logs/components/errorMaps.ts';
import { useCallback, useEffect, useState } from 'react';
import TableGrid from 'src/components/Table/TableGrid.tsx';
import SimplePaginator from 'src/components/Table/SimplePaginator.tsx';
import { CellContext, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { TooltipWithSameValue } from 'src/utils/TooltipWithSameValue.tsx';
import toast from 'react-hot-toast';
import moment from 'moment/moment';
import { triggerCsvExport } from 'src/apis/csvExports/apis';
import { useClient } from 'src/auth';
import { FilterOperator } from 'src/components/Filters/ClientCompaniesFilters/types';

const PAGE_SIZE = 20;

export interface CompanyOffcanvasProps {
  error: ErrorSummaryRow;
  show: boolean;
  onHide: () => void;
}

export default function ErrorDetailsOffcanvas({ error, show, onHide }: CompanyOffcanvasProps): JSX.Element {
  const { clientName } = useClient();
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<ErrorDetails[]>([]);

  useEffect(() => setPage(0), [show]); // Reset to page 1 if show changes

  useEffect(() => {
    setIsLoading(true);
    loadErrorsDetails(error, PAGE_SIZE, page * PAGE_SIZE).then(data => {
      setRows(data as ErrorDetails[]);
      setNumPages(Math.ceil(error.errorCount / PAGE_SIZE));
      setIsLoading(false);
    });
  }, [error, page]);

  const triggerResync = useCallback(async () => {
    const fieldNamePrefix = `crm_sync_${error.integrationType.toLowerCase()}`;

    // Match if both last_sync_error_field and last_sync_error_code
    const filters = {
      id: 'resync',
      combinator: 'and',
      rules: [
        {
          id: 'last_sync_error_code',
          field: `${fieldNamePrefix}/last_sync_error_code`,
          operator: FilterOperator.stringEqualOneOf,
          value: [error.errorCode]
        }
      ]
    };

    if (error.field) {
      filters.rules.push({
        id: `last_sync_error_field`,
        field: `${fieldNamePrefix}/last_sync_error_field`,
        operator: FilterOperator.stringEqualOneOf,
        value: [error.field]
      });
    }

    const result = await isAnyJobRunning(error.integrationId);
    if (result.canStartJob) {
      toast.promise(startJob(error.integrationId, filters), {
        loading: 'Starting new sync job...',
        error: "Couldn't start the sync job.",
        success: 'Sync job started'
      });
    } else {
      if (result.cantStartJobReason === 'INTEGRATION_JOB_ALREADY_RUNNING') {
        toast.error(
          `Couldn't start the sync job. Connector is currently running a sync, please try again once sync completes.`
        );
      } else {
        toast.error(`Couldn't start the sync job, there is a problem with your connector configuration`);
      }
    }
  }, [error.errorCode, error.field, error.integrationId, error.integrationType]);

  const triggerExport = useCallback(() => {
    toast.promise(
      triggerCsvExport('CRM_ERRORS', { ...error }),
      {
        loading: 'Starting export...',
        success: (
          <span>
            CSV export has been started, you will receive an email with the link to download it shortly. You can also
            monitor progress on the <a href={`/app/${clientName}/exports`}>exports page</a>
          </span>
        ),
        error: 'Could not start CSV export'
      },
      { success: { duration: 5000 } }
    );
  }, [error, clientName]);

  const table = useReactTable<ErrorDetails>({
    columns: [
      {
        header: 'Record',
        id: 'record',
        accessorKey: 'record',
        enableSorting: false,
        cell: (props: CellContext<any, string>) => <TooltipWithSameValue value={props.getValue()} />
      },
      {
        header: 'Field',
        accessorFn: row => row.field || '-',
        enableSorting: false,
        cell: (props: CellContext<any, string>) => <TooltipWithSameValue value={props.getValue()} />
      },
      {
        header: 'Value',
        accessorFn: row => row.value || '-',
        enableSorting: false,
        cell: (props: CellContext<any, string>) => <TooltipWithSameValue value={props.getValue()} />
      },
      {
        header: 'Timestamp',
        accessorKey: 'lastSyncAttempt',
        // @todo we need to work out about timezones. This is UTC.
        cell: props => moment(props.getValue()).format('YYYY-MM-DD HH:mm:ss'),
        enableSorting: false
      }
    ],
    data: rows,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  return (
    <Offcanvas show={show} placement="end" onHide={onHide} style={{ width: 800 }}>
      {error && (
        <>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title as="div" className="d-flex flex-row">
              Error:{' '}
              {isLoading ? '' : mapError(error.errorCode, error.errorMessage as string, error.field)?.shortDescription}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div>
              <p>{mapError(error.errorCode, error.errorMessage as string, error.field)?.longDescription}</p>
            </div>
            <Stack direction="horizontal" gap={3} className={'mb-2'}>
              <div className="p">
                <h3>Instances of error</h3>
              </div>
              <div className="p ms-auto">
                <Button variant="white" size="sm" onClick={triggerExport}>
                  Download errors as CSV
                </Button>
              </div>
              {error.recordType === 'COMPANY' && (
                <div className="p">
                  <Button variant="white" size="sm" onClick={() => triggerResync()}>
                    Resync records
                  </Button>
                </div>
              )}
            </Stack>

            <TableGrid
              table={table}
              isLoading={isLoading}
              emptyMessage="No details found"
              numLoadingRows={PAGE_SIZE}
              className="table-centered table-compact table-nowrap table-fixed errors-summary-table"
            />
            <div className="d-flex justify-content-end px-5 pb-3">
              <SimplePaginator pageIndex={page} totalPages={numPages} setPage={setPage} />
            </div>
          </Offcanvas.Body>
        </>
      )}
    </Offcanvas>
  );
}
