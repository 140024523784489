import { LeafRule, NodeRule } from 'src/pages/staff/coverageAnalysis/types.ts';
import isEmpty from 'lodash/isEmpty';

export function getParentStatus(
  targetCompanyRules: (NodeRule | LeafRule)[],
  fromRuleId: string,
  toRuleId: string
): boolean {
  const parent = fromRuleId.split('.').slice(0, -1).join('.');
  const node = targetCompanyRules.find(tgr => tgr.id === fromRuleId);
  if (node?.included) {
    return true;
  }
  if (fromRuleId === toRuleId || (isEmpty(parent) && isEmpty(toRuleId))) {
    return node?.included || false;
  }
  if (parent === toRuleId) {
    const parentS = targetCompanyRules.find(tgr => tgr.id === parent)?.included || false;
    const nodeS = node?.included || false;
    return parentS || nodeS;
  }
  return getParentStatus(targetCompanyRules, parent, toRuleId);
}
