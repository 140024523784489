import API from 'src/utils/API';
import { CsvExportItem, CsvExportListResponse, CsvExportType } from './types';

export async function triggerCsvExport(exportType: CsvExportType, exportConfig: any, exportName?: string) {
  return await API.post(
    '/app/csv-exports',
    {},
    {
      exportType,
      exportConfig,
      exportName
    }
  );
}

export async function getCsvExportDownloadUrls(exportId: string) {
  return await API.post<any>(`/app/csv-exports/${exportId}/download`, {});
}

export async function getCsvExport(exportId: string) {
  return await API.get<CsvExportItem>(`/app/csv-exports/${exportId}`, {});
}

export async function listCsvExports() {
  return await API.get<CsvExportListResponse>('/app/csv-exports', {});
}
