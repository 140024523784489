import API from 'src/utils/API.ts';
import { ClientConfig } from 'src/apis/clients/types.ts';

export async function triggerEstimation(config: Partial<ClientConfig>): Promise<{ executionId: string }> {
  return await API.post<{ executionId: string }>(`/admin/market-estimation`, {}, config);
}

export async function getResults(executionId: string) {
  return await API.get<{ status: string; output: any }>(`/admin/market-estimation/${executionId}`, {});
}

export async function generateSql(config: Partial<ClientConfig>): Promise<{ formattedUnsafeSql: string }> {
  return await API.post<{ formattedUnsafeSql: string }>(`/admin/market-estimation/generate-sql`, {}, config);
}
