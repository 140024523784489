import SourceCriteriaEditor from 'src/pages/staff/clientConfigs/components/SourcingCriteriaEditor.tsx';
import { FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';
import { useEffect, useState } from 'react';
import { getFieldMap } from 'src/components/Filters/CCMFilters/services/service.ts';
import PageLoader from 'src/components/PageLoader.tsx';
import { DataBlockConfigs } from 'src/pages/staff/clientConfigs/sourcing/dynamicDataBlocks/DataBlockConfigs.tsx';
import { ClientConfig, CompanyCriteriaGroup } from 'src/apis/clients/types.ts';
import { useClient } from 'src/auth';

type SourcingComponentProps = {
  clientConfig: { sourcingCriteria: CompanyCriteriaGroup; dataBlockConfigs: any[] };
  isEditing: boolean;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
};

export function SourcingComponent({
  clientConfig,
  isEditing,
  handleChange,
  handleCommitChanges,
  handleChangeAndCommit
}: SourcingComponentProps) {
  const { ccmSchema, ccmDataBlocksDefinitions, ccmDynamicDataBlocksDefinitions } = useClient();

  const [fieldMapping, setFieldMapping] = useState<FieldMapping[]>();

  useEffect(() => {
    clientConfig.dataBlockConfigs &&
      setFieldMapping(getFieldMap(clientConfig.dataBlockConfigs, ccmDataBlocksDefinitions));
  }, [clientConfig.dataBlockConfigs, ccmDataBlocksDefinitions]);

  if (!fieldMapping) return <PageLoader />;

  return (
    <div>
      <SourceCriteriaEditor
        schema={ccmSchema}
        clientConfig={clientConfig as unknown as ClientConfig}
        fieldMapping={fieldMapping}
        isEditing={isEditing}
        handleChange={handleChange}
        handleCommitChanges={handleCommitChanges}
      />
      <DataBlockConfigs
        schema={ccmSchema}
        dynamicDataBlocksDefinitions={ccmDynamicDataBlocksDefinitions}
        clientConfig={clientConfig as unknown as ClientConfig}
        isEditing={isEditing}
        handleChange={handleChange}
        handleCommitChanges={handleCommitChanges}
        handleChangeAndCommit={handleChangeAndCommit}
      />
    </div>
  );
}
