import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Table } from 'react-bootstrap';
import { LeafRule, TargetCompany } from 'src/pages/staff/coverageAnalysis/types.ts';
import SimplePaginator from 'src/components/Table/SimplePaginator.tsx';
import toast from 'react-hot-toast';
import { getParentStatus } from 'src/pages/staff/coverageAnalysis/components/parentStatus.ts';
import isEmpty from 'lodash/isEmpty';

type ValueListModalProps = {
  onHide: () => void;
  targetCompanies: TargetCompany[];
  rule: string;
  canSetRanges: (rule: string) => boolean;
  openRangesSetupModal: (rule: string) => void;
};

export const ValueListModal = ({
  targetCompanies,
  onHide,
  rule,
  canSetRanges,
  openRangesSetupModal
}: ValueListModalProps) => {
  const [valueCounts, setValueCounts] = useState<{
    [key: string]: { count: number; excludedCompaniesCount: number };
  }>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const hide = useCallback(() => {
    setCurrentPage(0);
    setTotalPages(0);
    onHide();
  }, [onHide]);

  useEffect(() => {
    const counts: { [key: string]: { count: number; excludedCompaniesCount: number } } = {};
    targetCompanies.forEach(tc => {
      tc.rules
        .filter(tcr => tcr.id === rule)
        .forEach(companyRule => {
          if ('fieldName' in companyRule && !companyRule.included) {
            if ('value' in companyRule) {
              if (Array.isArray(companyRule.value)) {
                if (isEmpty(companyRule.value)) {
                  const newCounts = counts['null'] || {
                    count: 0,
                    totalExcludedCount: 0,
                    excludedCompaniesCount: 0
                  };
                  newCounts.count++;
                  if (!getParentStatus(tc.rules, rule, '')) {
                    if (!companyRule.included) newCounts.excludedCompaniesCount++;
                  }
                  counts['null'] = newCounts;
                }
                companyRule.value.forEach(v => {
                  const newCounts = counts[v] || {
                    count: 0,
                    totalExcludedCount: 0,
                    excludedCompaniesCount: 0
                  };
                  newCounts.count++;
                  if (!getParentStatus(tc.rules, rule, '')) {
                    if (!companyRule.included) newCounts.excludedCompaniesCount++;
                  }
                  counts[v] = newCounts;
                });
              } else {
                const newCounts = counts[companyRule.value] || {
                  count: 0,
                  totalExcludedCount: 0,
                  excludedCompaniesCount: 0
                };
                newCounts.count++;
                if (!getParentStatus(tc.rules, rule, '')) {
                  if (!companyRule.included) newCounts.excludedCompaniesCount++;
                }
                counts[companyRule.value] = newCounts;
              }
            }
          }
        });
    });
    setValueCounts(counts);
    setTotalPages(Math.ceil(Object.keys(counts).length / 10));
  }, [targetCompanies, rule]);

  const copyToClipboard = useCallback(() => {
    const field = targetCompanies[0].rules.find((r: any) => r.id === rule) as LeafRule;

    const lines = [];
    const header = ['Field Name', 'Value', 'Count', '% of all excluded companies'];

    lines.push(header.join('\t'));
    Object.entries(valueCounts)
      .sort((a, b) => b[1].excludedCompaniesCount - a[1].excludedCompaniesCount) // Sort by value impact
      .forEach(([value, count]) => {
        lines.push(
          [
            field.fieldName + `${field.configId ? ` (${field.configId})` : ''}`,
            value,
            count.count,
            `${
              count.excludedCompaniesCount &&
              `${((count.excludedCompaniesCount * 100) / targetCompanies.filter(f => !f.included)?.length).toFixed(2)}%`
            }`
          ].join('\t')
        );
      });

    const textTsv = lines.join('\n');
    navigator.clipboard.writeText(textTsv);

    toast.success(`Copied ${Object.keys(valueCounts).length} rows to clipboard!`);
  }, [rule, targetCompanies, valueCounts]);

  return (
    <Modal scrollable show={!!rule} size={undefined} onHide={hide}>
      <Modal.Header closeButton>Excluded values</Modal.Header>
      <Modal.Body className="p-0">
        <Table responsive bordered className="table-centered" size="sm">
          <thead>
            <tr>
              <th style={{ minWidth: '160px' }}>Value</th>
              <th>Count</th>
              <th>% of all excluded companies</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(valueCounts)
              .sort((a, b) => b[1].excludedCompaniesCount - a[1].excludedCompaniesCount) // Sort by impact
              .map(([value, count]) => (
                <tr key={value}>
                  <td>{value}</td>
                  <td>{count.count}</td>
                  <td>
                    {count.excludedCompaniesCount &&
                      `${((count.excludedCompaniesCount * 100) / targetCompanies.filter(f => !f.included)?.length).toFixed(2)}%`}
                  </td>
                </tr>
              ))
              .slice(currentPage * 10, (currentPage + 1) * 10)}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <SimplePaginator pageIndex={currentPage} totalPages={totalPages} setPage={setCurrentPage} />
        <div>
          <Button variant="white" size="sm" className="me-2" onClick={copyToClipboard}>
            Copy to clipboard
          </Button>
          {canSetRanges(rule) && (
            <Button variant="white" size="sm" className="me-2" onClick={() => openRangesSetupModal(rule)}>
              Set Ranges
            </Button>
          )}
          <Button variant="primary" size="sm" onClick={hide}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
