import { Button, Card, Dropdown, Form, InputGroup, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { FiMoreVertical, FiSearch } from 'react-icons/fi';
import toast from 'react-hot-toast';
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable
} from '@tanstack/react-table';

import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useClient } from 'src/auth';
import WideLayout from 'src/layouts/Wide/WideLayout';
import CardPaginator from 'src/components/Table/CardPaginator';
import { Segment } from '../types';
import { deleteSegment, loadSegments, triggerSegmentRefresh } from '../apis';
import { usePeriodic } from 'src/utils/usePeriodic';
import { formatDateString, pluralize } from 'src/utils/string';
import EmptyMessage from 'src/components/EmptyMessage';
import { FaCaretDown, FaCaretUp, FaPeopleGroup } from 'react-icons/fa6';
import { appClientUrl } from 'src/utils/urls';
import { showConfirm } from 'src/utils/modals';
import { CollectionHelper } from 'src/utils/CollectionHelper';
import { ColumnHeader } from 'src/components/Table/listTable/ColumnHeader';
import TableGrid from 'src/components/Table/TableGrid';
import Tag from 'src/components/Tag';
import NavButton from 'src/components/NavButton';
import { useSegmentsSyncStatuses } from './hooks/useSegmentsSyncStatuses';
import { SyncStatusIcon } from './SyncStatusIcon';
import { PersonaErrorStatusIcon } from '../components/PersonaErrorStatusIcon';
import { GuidesBar } from 'src/pages/segments/components/guides/GuidesBar.tsx';

const collectionHelper = new CollectionHelper<Segment>('id');

function navigateToNewSegment(clientName: string) {
  return appClientUrl('/segments/edit/new', clientName);
}

function navigateToEditSegment(clientName: string, segment: Segment) {
  return appClientUrl(`/segments/edit/${segment.id}`, clientName);
}

function navigateToViewSegment(clientName: string, segment: Segment) {
  return appClientUrl(`/segments/view/${segment.id}`, clientName);
}

function formatSegmentSize(segment: Segment) {
  if (segment.updateStatus === 'ERROR') return <Tag color="danger">Error</Tag>;
  if (segment.updateStatus === 'RUNNING') return <Tag color="primary">Updating</Tag>;

  if (segment.sizeCompanies === null) return 'Not yet generated';
  if (segment.sizeContacts === null)
    return `${segment.sizeCompanies} ${pluralize(segment.sizeCompanies, 'company', 'companies')}`;
  return `${segment.sizeCompanies} ${pluralize(segment.sizeCompanies, 'company', 'companies')}, ${segment.sizeContacts} ${pluralize(segment.sizeContacts, 'contact', 'contacts')}`;
}

function formatPlusMinus(value: number, thing: string) {
  if (value === 0)
    return (
      <>
        {value} {thing}
      </>
    );
  if (value > 0)
    return (
      <>
        <FaCaretUp color="lightgreen" /> {value} {thing}
      </>
    );
  return (
    <>
      <FaCaretDown color="orange" /> {Math.abs(value)} {thing}
    </>
  );
}

function formatSegmentSizeDelta(segment: Segment) {
  if (!segment.isDynamic) return <span className="text-muted">-</span>;
  if (segment.sizeCompanies === null) return <span className="text-muted">No change</span>;

  const companiesDelta = segment.sizeCompaniesAddedRecently - segment.sizeCompaniesRemovedRecently;
  const contactsDelta = segment.sizeContacts
    ? segment.sizeContactsAddedRecently - segment.sizeContactsRemovedRecently
    : null;

  if (!companiesDelta && !contactsDelta) return <span className="text-muted">No change</span>;

  return (
    <OverlayTrigger
      overlay={(props: any) => (
        <Tooltip {...props}>
          In the last 7 days: <br />
          Companies added: {segment.sizeCompaniesAddedRecently ?? 0}
          <br />
          Companies removed: {segment.sizeCompaniesRemovedRecently ?? 0}
          <br />
          Contacts added: {segment.sizeContactsAddedRecently ?? 0}
          <br />
          Contacts removed: {segment.sizeContactsRemovedRecently ?? 0}
          <br />
        </Tooltip>
      )}
    >
      <span>
        {formatPlusMinus(companiesDelta, 'companies')} &nbsp;
        {contactsDelta ? formatPlusMinus(contactsDelta, 'contacts') : ''}
      </span>
    </OverlayTrigger>
  );
}

const PAGE_SIZE = 25;

export default function SegmentListPage() {
  const { clientName } = useClient();
  const navigate = useNavigate();

  const [segments, setSegments] = useState<Segment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: PAGE_SIZE
  });
  const [sorting, setSorting] = useState<SortingState>([{ id: 'createdAt', desc: true }]);
  const [searchText, setSearchText] = useState('');

  const segmentIntegrationStatuses = useSegmentsSyncStatuses(segments);

  usePageTitle('Segments');

  // Load now and every 5s invisibly
  usePeriodic(
    () => {
      // Load 1000 segments to do pagination / search client side
      loadSegments(1000, undefined, true).then(response => {
        // As this runs periodicaly, we want to compare the old and new values and only call setSegments
        // If they have changed.... otherwise react table will rerender all rows which can cause Dropdowns to disappear
        const currentJson = JSON.stringify(segments);
        const newJson = JSON.stringify(response.items);
        if (newJson != currentJson) setSegments(response.items);
        setIsLoading(false);
      });
    },
    5000,
    [segments]
  );

  const handleDelete = useCallback(
    (segmentId: string) => {
      toast
        .promise(
          (async () => {
            return deleteSegment(segmentId);
          })(),
          {
            loading: 'Deleting...',
            success: 'Segment deleted',
            error: 'Error deleting segment'
          }
        )
        .then(() => {
          setSegments(collectionHelper.deleteItem(segments, segmentId));
        });
    },
    [segments]
  );

  const handleSegmentRefresh = useCallback(
    (segmentId: string) => {
      toast
        .promise(
          (async () => {
            return triggerSegmentRefresh(segmentId);
          })(),
          {
            loading: 'Triggering refresh...',
            success: 'Segment refresh started',
            error: 'Error triggering refresh'
          }
        )
        .then(() => {
          setSegments(collectionHelper.updateItem(segments, segmentId, { refreshStatus: 'RUNNING' }));
        });
    },
    [segments]
  );

  const columns: ColumnDef<Segment>[] = [
    {
      accessorKey: 'name',
      cell: c => {
        return (
          <Link className="text-dark" to={navigateToViewSegment(clientName, c.row.original)}>
            {c.row.original.name}
            &nbsp; &nbsp;
            <SyncStatusIcon segmentSyncStatus={segmentIntegrationStatuses[c.row.original.id]} />
            &nbsp; &nbsp;
            {c.row.original.isDynamic && (
              <Tag overlayText="Dynamic segments will automatically add or remove records as your dataset changes.">
                Dynamic
              </Tag>
            )}
            {c.row.original.refreshStatus === 'RUNNING' && (
              <OverlayTrigger overlay={(props: any) => <Tooltip {...props}>Segment is refreshing</Tooltip>}>
                <Spinner size="sm" className="ms-2" variant="light" style={{ position: 'relative', top: 2 }} />
              </OverlayTrigger>
            )}
          </Link>
        );
      },
      meta: {
        width: '25%'
      },
      header: ({ column }) => <ColumnHeader column={column} headerName={'Segment Name'} allowSorting={true} />
    },
    {
      accessorKey: 'sizeCompanies',
      cell: c => (
        <span className="d-flex align-items-center justify-content-center">
          {formatSegmentSize(c.row.original)}
          <PersonaErrorStatusIcon segment={c.row.original} />
        </span>
      ),
      meta: {
        width: '25%'
      },
      header: ({ column }) => <ColumnHeader column={column} headerName={'Segment Size'} allowSorting={true} />
    },
    {
      accessorKey: 'sizeCompaniesAddedRecently',
      cell: c => formatSegmentSizeDelta(c.row.original),
      meta: {
        width: '25%'
      },
      header: ({ column }) => <ColumnHeader column={column} headerName={'Dynamic changes'} allowSorting={true} />
    },
    {
      accessorKey: 'createdAt',
      meta: {
        width: '20%'
      },
      cell: c => {
        return formatDateString(c.row.original.createdAt);
      },
      header: ({ column }) => <ColumnHeader column={column} headerName={'Created At'} allowSorting={true} />
    },
    {
      id: 'options',
      header: ({ column }) => <ColumnHeader column={column} headerName={''} allowSorting={false} />,
      meta: {
        width: '10%'
      },
      cell: c => {
        const s = c.row.original as Segment;
        return (
          <div className="d-flex justify-content-end align-items-center" style={{ gap: 8 }}>
            <NavButton size="sm" variant="white" to={navigateToViewSegment(clientName, s)}>
              View segment
            </NavButton>
            <Dropdown align="end">
              <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                <FiMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={async () => {
                    const isSure = await showConfirm(
                      'This will remove this segment. Are you sure?',
                      'Yes, delete',
                      'No, cancel',
                      'Confirm deletion'
                    );
                    if (isSure) handleDelete(s.id);
                  }}
                >
                  Delete segment
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate(navigateToEditSegment(clientName, s));
                  }}
                >
                  Edit segment
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={s.refreshStatus === 'RUNNING'}
                  onClick={() => {
                    handleSegmentRefresh(s.id);
                  }}
                >
                  Refresh segment
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      }
    }
  ];

  const table = useReactTable({
    columns,
    data: segments,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    autoResetPageIndex: false,
    state: {
      sorting,
      pagination,
      columnFilters: [{ id: 'name', value: searchText }]
    }
  });

  return (
    <WideLayout title="Segments" preheader="">
      <GuidesBar />
      <Card>
        <Card.Header>
          <div style={{ gap: 8 }} className="d-flex justify-content-start">
            <InputGroup size="sm" className="input-group-merge input-group-reverse" style={{ maxWidth: 300 }}>
              <Form.Control
                type="search"
                placeholder="Search"
                value={searchText}
                onChange={ev => setSearchText(ev.target.value)}
                className="search"
              />
              <InputGroup.Text>
                <FiSearch />
              </InputGroup.Text>
            </InputGroup>
          </div>
          <Button
            size="sm"
            onClick={() => {
              navigate(navigateToNewSegment(clientName));
            }}
          >
            + Add Segment
          </Button>
        </Card.Header>
        <TableGrid
          isLoading={isLoading}
          table={table}
          className="mb-0 table-hover table-extra-padding-first-last-column table-centered table-centered-first-column-left"
          emptyMessage={
            segments.length == 0 ? (
              <EmptyMessage
                style={{ marginTop: '5em', marginBottom: '5em' }}
                message="You don't have any segments"
                subMessage={
                  <>
                    Find out about how segments work:{' '}
                    <a href="https://docs.goodfit.io/goodfit-docs/product/segments" target="_blank">
                      Segments documentation
                    </a>
                  </>
                }
                icon={<FaPeopleGroup />}
                actions={
                  <Button size="sm" variant="primary" onClick={() => navigate(navigateToNewSegment(clientName))}>
                    Create your first segment
                  </Button>
                }
              />
            ) : (
              'No segments matching search'
            )
          }
        />

        <Card.Footer>
          <CardPaginator
            pageIndex={table.getState().pagination.pageIndex}
            totalPages={table.getPageCount()}
            setPage={idx => table.setPageIndex(idx)}
          />
        </Card.Footer>
      </Card>
    </WideLayout>
  );
}
