import { CompanyCriteriaGroup } from 'src/apis/clients/types.ts';

export type PredictiveLabelsModel = {
  id: string;
  name: string;
  description: string;
  clientName?: string; // Optional, null for global
  activeVersion: number;
  mode: PredictiveLabelsModelMode;
  status?: PredictiveLabelsModelStatus;
  labels: PredictiveLabelsModelLabelConfig[];
  isGlobal?: boolean;
  universeConfig: {
    sourcingCriteria: CompanyCriteriaGroup;
    dataBlockConfigs: any[];
  };
};

export enum PredictiveLabelsModelMode {
  BINARY = 'BINARY',
  MULTI_CLASS = 'MULTI_CLASS'
}

export enum PredictiveLabelsModelStatus {
  TRAINING = 'TRAINING',
  DEPLOYING = 'DEPLOYING',
  DEPLOYED = 'DEPLOYED'
}

export enum PredictiveLabelsModelVersionStatus {
  TRAINING = 'TRAINING',
  TRAINED = 'TRAINED',
  ERROR = 'ERROR'
}

export type PredictiveLabelsModelLabelConfig = {
  label: string;
  description: string;
  trainingExamples?: TrainingExample[];
};

export type TrainingExample = {
  modelId: string;
  domain: string;
  targetLabel: string;
  status?: TrainingExampleStatus;
};

export type TrainingExampleStatus = 'OK' | 'RUNNING' | 'NOT_FOUND' | 'ERROR';

export type PredictiveLabelsModelVersion = {
  modelId: string;
  version: number;
  modelFilePath: string;
  status: PredictiveLabelsModelVersionStatus | null;
  trainingResults: any;
};

export type PredictiveLabelsModelVersionShort = {
  modelId: string;
  version: number;
  status: PredictiveLabelsModelVersionStatus;
  createdAt: Date;
  updatedAt: Date;
  avgWeighedF1Score: number;
  overfittedWeighteedF1Score?: number;
};

export type PredictiveLabelsModelVersionResponse = {
  versions: PredictiveLabelsModelVersionShort[];
  activeVersion?: number;
  status: PredictiveLabelsModelStatus;
};

export type ConfusionExample = {
  domain: string;
  predictedLabel: string;
  action: 'correct' | 'incorrect' | undefined;
  correctLabel?: string;
};
