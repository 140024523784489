import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi';

import { useResourceLockStore } from '../../../resourceLockStore';
import { ResourceLock } from 'src/apis/resourceLocks/types';
import { formatDateString } from 'src/utils/string';

interface StaleResourceModalContentProps {
  resourceLock: ResourceLock;
  handleClose(): void;
}

export const StaleResourceModalContent: React.FC<StaleResourceModalContentProps> = ({ resourceLock, handleClose }) => {
  const isResourceStale = useResourceLockStore(
    resourceLock.resourceType,
    resourceLock.resourceId,
    state => state.isResourceStale
  );

  const reloadPage: React.FormEventHandler<HTMLFormElement> = evt => {
    evt.preventDefault();
    location.reload();
  };

  if (!isResourceStale) return null;

  return (
    <Form onSubmit={reloadPage}>
      <Modal.Header closeButton onHide={handleClose}>
        <FiAlertTriangle className="me-2" fontSize={20} color="red" />
        Resource Out of Sync
      </Modal.Header>

      <Modal.Body>
        This resource was last edited by <a href={`mailto:${resourceLock.lockedBy}`}>{resourceLock.lockedBy}</a> at{' '}
        {formatDateString(resourceLock.lockedAt)}.
        <br />
        <br />
        Continuing to edit this resource may result in changes being overwritten.
        <br />
        <br />
        Please make a note of your changes and reload the page to continue.
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="white" className="me-2" onClick={handleClose}>
          Close
        </Button>

        <Button size="sm" type="submit" className="d-flex align-items-center">
          <span>Reload Page</span>
        </Button>
      </Modal.Footer>
    </Form>
  );
};
