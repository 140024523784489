import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import gravatarUrl from 'gravatar-url';
import { Collapse, Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { FiChevronDown } from 'react-icons/fi';

import goodFitLogo from '/img/goodfit-logo-text.svg';
import { useAuth } from 'src/auth';

interface NavItem {
  id: string;
  children?: string[];
  title?: string;
  url?: string;
  icon?: JSX.Element;
  requirePermission?: string;
  topLevel?: boolean;
}

function relativePath(path: string): string {
  return path;
}

const allMenuItems: Record<string, NavItem> = {
  base: {
    children: [
      'godCompanies',
      'godMarketEstimations',
      'godCoverageAnalysis',
      'godEnrichments',
      'godSourcerEnrichment',
      'godToolbox',
      'godDataBlocksDictionary',
      'godTechnographicsConfigs',
      'godCustomData',
      'godPageElements'
    ],
    id: 'base',
    topLevel: true
  },
  godCompanies: {
    id: 'godCompanies',
    title: 'All Clients',
    url: '/admin/clients'
  },
  godMarketEstimations: {
    id: 'godMarketEstimations',
    title: 'Market Estimations',
    url: '/admin/market-estimations'
  },
  godCoverageAnalysis: {
    id: 'godCoverageAnalysis',
    title: 'Coverage Analysis',
    url: '/admin/coverage-analyses'
  },
  godEnrichments: {
    id: 'godEnrichments',
    title: 'Enrichment',
    url: '/admin/enrichment'
  },
  godSourcerEnrichment: {
    id: 'godSourcerEnrichment',
    title: 'Sourcer Enrichment',
    url: '/admin/sourcer-enrichment'
  },
  godToolbox: {
    id: 'godToolbox',
    title: 'Toolbox',
    url: '/admin/toolbox'
  },
  godDataBlocksDictionary: {
    id: 'godToolbox',
    title: 'Data Blocks Dictionary',
    url: '/admin/data-blocks-dictionary'
  },
  godTechnographicsConfigs: {
    id: 'godTechnographicsConfigs',
    title: 'Technographics Configs',
    url: '/admin/technographics-configs',
    requirePermission: 'ADMIN_BETA_TESTER'
  },
  godCustomData: {
    id: 'godCustomData',
    title: 'Custom Data',
    url: '/admin/custom-data',
    requirePermission: 'ADMIN_BETA_TESTER'
  },
  godPageElements: {
    id: 'godPageElements',
    title: 'Page Elements',
    children: ['godPageElementConfigs', 'godPageElementRefresh']
  },
  godPageElementConfigs: {
    id: 'godPageElementConfigs',
    title: 'Page Element Configs',
    url: '/admin/page-element-configs'
  },
  godPageElementRefresh: {
    id: 'godPageElementRefresh',
    title: 'Page Element Refresh',
    url: '/admin/page-element-refresh'
  }
};

function filterMenuItems(staffPermissions?: string[]) {
  const ret: Record<string, NavItem> = {};

  for (const id of Object.keys(allMenuItems)) {
    const item = allMenuItems[id];
    if (item.requirePermission) {
      if (!staffPermissions?.includes(item.requirePermission)) continue;
    }
    ret[id] = item;
  }

  return ret;
}

export default function Sidenav({ ...props }) {
  const { pathname } = useLocation();
  const auth = useAuth();
  const data = useMemo(() => filterMenuItems(auth.user?.staffPermissions), [auth.user?.staffPermissions]);
  const userEmail = auth.user?.email as string;
  const userImageUrl = gravatarUrl(userEmail, {
    size: 200,
    default: 'retro'
  });

  const [activeItemId, setActiveItemId] = useState(() => {
    return Object.keys(data).filter(itemId => {
      const relativeTabPath = data[itemId].url;
      const tabPath = relativePath(relativeTabPath || '');
      const isActive =
        (relativeTabPath && relativeTabPath !== '~/' && pathname.startsWith(tabPath)) || pathname === tabPath;
      return isActive;
    })[0];
  });

  function isExpanded(itemId: string) {
    if (activeItemId === itemId) {
      return true;
    }

    return isParent(itemId);
  }

  function isParent(itemId: string) {
    const item = data[itemId];
    if (!item) return false;

    if (!item.children) {
      return false;
    }

    if (item.children.includes(activeItemId)) {
      return true;
    }

    let result = false;

    item.children.forEach((childId: string) => {
      if (!data[childId]) return;
      if (isParent(childId)) {
        result = true;
      }
    });

    return result;
  }

  function getItems(ids: string[]) {
    return ids.map(function (id: string, index: number) {
      const item = data[id];
      if (!item) return null;
      if (!item.topLevel) return null;

      return (
        <div key={id}>
          {index > 0 && <hr className="navbar-divider" />}
          {item.title && <h6 className="navbar-heading">{item.title}</h6>}
          {item.children && <Nav>{getSubitems(item.children, id, ids)}</Nav>}
        </div>
      );
    });
  }

  function getSubitems(ids: string[], parentId: string, arr: string[]) {
    return ids.map(function (id) {
      //const idx = arr.splice(arr.indexOf(id), 1) as unknown as string;
      const item = data[id];
      if (!item) return <div key={id}></div>;

      const relativeTabPath = data[id].url;
      const tabPath = relativePath(relativeTabPath || '');
      const isActive =
        (relativeTabPath && relativeTabPath !== '~/' && pathname.startsWith(tabPath)) || pathname === tabPath;

      return (
        <Nav.Item key={id}>
          {item.children ? (
            <>
              <Nav.Link onClick={() => handleClick(id, parentId)} role="button">
                {item.icon}
                {item.title}
                <FiChevronDown className={`ms-auto nav-chevron ${isExpanded(id) && 'active'}`} />
              </Nav.Link>
              <Collapse in={isExpanded(id)}>
                <div>
                  <div className="nav nav-sm flex-column">{getSubitems(item.children, id, arr)}</div>
                </div>
              </Collapse>
            </>
          ) : (
            <Nav.Link
              as={Link}
              to={relativePath(item.url || '~/')}
              active={isActive}
              onClick={() => handleClick(id, parentId)}
            >
              {item.icon}
              {item.title}
            </Nav.Link>
          )}
        </Nav.Item>
      );
    });
  }

  function handleClick(itemId: string, parentId: string, setVisible?: (isVisible: boolean) => void) {
    setActiveItemId(isExpanded(itemId) ? parentId : itemId);

    if (setVisible) {
      setVisible(false);
    }
  }

  const toggler = <Navbar.Toggle />;

  const brand = (
    <div
      style={{
        borderBottom: '1px solid #eee',
        borderTop: 'none',
        margin: '0 -24px 20px -24px'
      }}
    >
      <Navbar.Brand as={Link} to={relativePath('~/')} style={{ padding: '16px 0 24px 0' }}>
        <img className="navbar-brand-img" src={goodFitLogo} alt="GoodFit" style={{ height: 20 }} />
      </Navbar.Brand>
    </div>
  );

  const footer = (
    <div className="navbar-user d-none d-md-flex">
      <Dropdown drop="up">
        <Dropdown.Toggle
          size="sm"
          className="avatar avatar-online"
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            margin: 0,
            width: 40,
            height: 40
          }}
          role="button"
        >
          <img className="rounded-circle avatar-img" src={userImageUrl} alt={userEmail} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <>
            <Dropdown.Item as={Link} to="/app">
              Go to client dashboard
            </Dropdown.Item>
            <Dropdown.Divider />
          </>
          <Dropdown.Item as={Link} to="/change-password">
            Change password
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/logout">
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const collapse = (
    <Navbar.Collapse {...props}>
      {getItems(Object.keys(data))}
      <div className="mt-auto mb-md-4" />
      {footer}
    </Navbar.Collapse>
  );

  return (
    <>
      <Navbar expand="md" className="navbar-vertical fixed-start" collapseOnSelect={true} {...props}>
        <Container fluid>
          {toggler}
          {brand}
          {collapse}
        </Container>
      </Navbar>
    </>
  );
}
