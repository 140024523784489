import { useCallback, useState } from 'react';
import { usePeriodic } from 'src/utils/usePeriodic';

export default function AppVersionReloader() {
  if (import.meta.env.VITE_ENABLE_VERSION_RELOAD !== 'true') {
    console.log('App version reloader disabled');
    return <></>;
  }
  return <AppVersionReloaderInner />;
}

// Global variable thats used by Routes.tsx events to decide to reload
(window as any).gfRequiresReload = false;
(window as any).gfCurrentVersion = null;

// Check /version.json every 5m for increased timestamp param
function AppVersionReloaderInner() {
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);

  const checkVersion = useCallback(() => {
    fetch('/version.json')
      .then(res => res.json())
      .then((data: any) => {
        const version = data.timestamp;
        if (!currentVersion) {
          setCurrentVersion(version);
          (window as any).gfCurrentVersion = version;
        } else if (version > currentVersion) {
          console.log('AppVersionReloader: New version detection, will reload next navigation');
          (window as any).gfRequiresReload = true;
        }
      });
  }, [setCurrentVersion, currentVersion]);

  usePeriodic(checkVersion, 300_000, [checkVersion]);

  return <></>;
}
