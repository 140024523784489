import { ListGroup, Row, Col, Placeholder } from 'react-bootstrap';

export function UserPlaceholder() {
  return (
    <ListGroup.Item>
      <Row className="align-items-center">
        <Col xs="auto">
          <div
            style={{
              width: 48,
              height: 48,
              background: '#aaa',
              borderRadius: 23
            }}
          ></div>
        </Col>
        <Col xs={5} className="ms-n2">
          <Placeholder as="div" animation="glow">
            <Placeholder xs={10} />
          </Placeholder>
          <Placeholder as="div" animation="glow">
            <Placeholder xs={3} />
          </Placeholder>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
