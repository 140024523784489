import { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import LoadingButton from 'src/components/LoadingButton';
import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import { usePageTitle } from 'src/utils/usePageTitle';
import { loadScoringAnalysisReport, updateScoringAnalysisReport } from './apis';
import { useNavigate, useParams } from 'react-router-dom';
import { appClientUrl } from 'src/utils/urls';
import { ScoringAnalysisReportFormState, canSubmitForm } from './components/edit/utils';
import ScoringAnalysisReportForm from './components/edit/ScoringAnalysisReportForm';
import PageLoader from 'src/components/PageLoader';

export default function EditScoringAnalysisPage() {
  const navigate = useNavigate();
  const [state, setState] = useState<ScoringAnalysisReportFormState | null>(null);
  const { scoringAnalysisReportId } = useParams();

  usePageTitle('Edit scoring analysis');

  useEffect(() => {
    if (scoringAnalysisReportId) {
      loadScoringAnalysisReport(scoringAnalysisReportId).then(result => {
        const report = result.report;
        console.log(report);
        setState({
          ...report.inputs,
          name: report.name
        });
      });
    }
  }, [scoringAnalysisReportId]);

  const handleSaveScoringAnalysisReport = useCallback(async () => {
    if (state && scoringAnalysisReportId) {
      const response = await updateScoringAnalysisReport(scoringAnalysisReportId, state?.name, {
        ...state
      });
      navigate(appClientUrl(`/scoring/analysis/results/${response.report.id}`));
    }
  }, [navigate, scoringAnalysisReportId, state]);

  const handleUpdate = useCallback((updates: any) => {
    setState(s => ({ ...s, ...updates }));
  }, []);

  if (state === null) {
    return <PageLoader />;
  }

  const canSubmit = canSubmitForm(state);

  return (
    <NarrowLayout title="Edit scoring analysis" preheader="">
      <Card>
        <Card.Header>Analysis settings</Card.Header>
        <Card.Body>
          <ScoringAnalysisReportForm state={state} handleUpdate={handleUpdate} />
        </Card.Body>
        <Card.Footer>
          <LoadingButton size="sm" variant="primary" disabled={!canSubmit} onClick={handleSaveScoringAnalysisReport}>
            Update scoring analysis report
          </LoadingButton>
        </Card.Footer>
      </Card>
    </NarrowLayout>
  );
}
