import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { appClientUrl } from 'src/utils/urls';
import {
  PredictiveLabelsModel,
  PredictiveLabelsModelLabelConfig,
  PredictiveLabelsModelMode,
  TrainingExample
} from 'src/pages/predictive-labels/types.ts';
import { useClient } from 'src/auth';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createModel,
  createVersion,
  getModel,
  getTrainingExamples,
  getVersions,
  regenerateUniverseSet,
  trainVersion,
  updateModel,
  upsertTrainingExamples
} from 'src/pages/predictive-labels/apis.ts';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import PageLoader from 'src/components/PageLoader.tsx';
import NarrowLayout from 'src/layouts/Narrow';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import VersionListModal from 'src/pages/predictive-labels/components/VersionListModal.tsx';
import { ModelSettingsCard } from 'src/pages/predictive-labels/components/ModelSettingsCard.tsx';
import { defaultBinaryLabels, defaultMulticlassLabels } from 'src/pages/predictive-labels/defaults.ts';
import { LabelsCard } from 'src/pages/predictive-labels/components/LabelsCard.tsx';
import { SourceCriteriaCard } from 'src/pages/predictive-labels/components/SourcingCriteriaCard.tsx';
import { CompanyCriteriaGroup } from 'src/apis/clients/types.ts';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';

export function PredictiveLabelsModelEditPage({ isNew }: { isNew: boolean }) {
  usePageTitle('Predictive label');
  const { clientName } = useClient();
  const { modelId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [model, setModel] = useState<PredictiveLabelsModel>({} as PredictiveLabelsModel);
  const [trainingExamples, setTrainingExamples] = useState<TrainingExample[]>([]);
  const [labels, setLabels] = useState<PredictiveLabelsModelLabelConfig[]>([]);
  const [errorsOpen, setErrorsOpen] = useState<boolean>(false);
  const [maxVersion, setMaxVersion] = useState<number>();

  const universeConfigRef = useRef<{ sourcingCriteria: CompanyCriteriaGroup; dataBlockConfigs: any[] }>({
    sourcingCriteria: { rules: [] },
    dataBlockConfigs: []
  });

  const [openVersionsListFor, setOpenVersionsListFor] = useState<string | null>(null);

  async function proceedToTraining(modelId: string) {
    const version = (await createVersion(modelId)).version;
    await trainVersion(modelId, version.version);
    navigate(appClientUrl(`/predictive-labels/${modelId}/version/${version.version}`));
  }

  async function continueTraining(modelId: string) {
    navigate(appClientUrl(`/predictive-labels/${modelId}/version/${maxVersion}`));
  }

  useEffect(() => {
    modelId &&
      getVersions(modelId).then(response => {
        response.versions?.length > 0 && setMaxVersion(Math.max(...response.versions.map(v => v.version)));
      });
  }, [modelId]);

  function saveModel(startTraining: boolean = false) {
    const toastId = toast.loading('Saving model...');
    setIsSaving(true);
    if (isNew) {
      createModel({
        ...model,
        id: modelId || uuidv4(),
        activeVersion: 1,
        clientName: model.isGlobal ? undefined : clientName,
        labels,
        universeConfig: universeConfigRef.current
      })
        .then(response => {
          upsertTrainingExamples(response.model.id, { trainingExamples: trainingExamples })
            .then(() => {
              toast.success('Model saved', { id: toastId });
              if (startTraining) {
                proceedToTraining(response.model.id).then();
              } else {
                navigate(appClientUrl(`/predictive-labels/${response.model.id}`));
              }
            })
            .catch(() => {
              toast.error('Failed to save model', { id: toastId });
            })
            .finally(() => {
              setIsSaving(false);
            });
        })
        .catch(() => {
          toast.error('Failed to save model', { id: toastId });
          setErrorsOpen(true);
          setIsSaving(false);
        });
    } else {
      // Update model
      updateModel({
        id: modelId as string,
        name: model.name,
        description: model.description,
        labels,
        universeConfig: universeConfigRef.current
      })
        .then(() => {
          upsertTrainingExamples(modelId as string, { trainingExamples: trainingExamples })
            .then(() => {
              toast.success('Model saved', { id: toastId });
              if (startTraining) {
                proceedToTraining(modelId as string).then();
              }
            })
            .catch(() => {
              toast.error('Failed to save model', { id: toastId });
            })
            .finally(() => {
              setIsSaving(false);
            });
        })
        .catch(() => {
          toast.error('Failed to save model', { id: toastId });
          setErrorsOpen(true);
          setIsSaving(false);
        });
    }
  }

  useEffect(() => {
    if (isNew) {
      setModel({
        id: uuidv4(),
        name: '',
        description: '',
        clientName: clientName,
        activeVersion: 1,
        mode: PredictiveLabelsModelMode.BINARY,
        labels: [],
        isGlobal: false,
        universeConfig: {
          sourcingCriteria: {
            rules: []
          },
          dataBlockConfigs: []
        }
      });
      setTrainingExamples([]);
      setLabels(cloneDeep(defaultBinaryLabels));
      setIsLoading(false);
    }
    if (!isNew && modelId) {
      getModel(modelId).then(response => {
        const model = response.model;
        if (!model.universeConfig || !model.universeConfig.sourcingCriteria) {
          model.universeConfig = {
            sourcingCriteria: {
              rules: []
            },
            dataBlockConfigs: []
          };
        }
        setModel(model);
        universeConfigRef.current = model.universeConfig;

        getTrainingExamples(modelId).then(response => {
          setTrainingExamples(response.trainingExamples);
          model.labels.forEach(label => {
            label.trainingExamples = response.trainingExamples.filter(example => example.targetLabel === label.label);
          });
          setLabels(model.labels);
          setIsLoading(false);
        });
      });
    }
  }, [clientName, isNew, modelId]);

  useEffect(() => {
    let defaults: PredictiveLabelsModelLabelConfig[];
    if (model.mode === PredictiveLabelsModelMode.MULTI_CLASS) {
      defaults = cloneDeep(defaultMulticlassLabels);
    } else {
      defaults = cloneDeep(defaultBinaryLabels);
    }
    setLabels(defaults);
    setModel(prevState => ({ ...prevState, labels: defaults }));
  }, [model.mode]);

  const has10ValidExamplesPerLabel = useMemo(
    () => !labels.find(l => !(l.trainingExamples && l.trainingExamples.filter(ex => ex.status === 'OK').length >= 10)),
    [labels]
  );

  const domainsInMultipleLabels = useMemo(
    () =>
      Object.entries(groupBy(trainingExamples, 'domain'))
        .filter(ex => ex[1].length > 1)
        .map(ex => ex[0]),
    [trainingExamples]
  );

  const handleChange = useCallback(
    (update: any, path: string) => {
      universeConfigRef.current = { ...universeConfigRef.current, [path]: update };
    },
    [universeConfigRef]
  );

  const handleChangeAndCommit = useCallback(
    (update: any, path: string) => {
      universeConfigRef.current = { ...universeConfigRef.current, [path]: update };
      setModel(prevState => ({ ...prevState, universeConfig: { ...prevState.universeConfig, [path]: update } }));
    },
    [setModel, universeConfigRef]
  );

  const handleCommitChanges = useCallback(() => {
    setModel(prevState => ({ ...prevState, universeConfig: universeConfigRef.current }));
  }, [universeConfigRef, setModel]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <NarrowLayout
      maxWidthCols={10}
      preheader={''}
      title={<>Predictive Labels</>}
      titleControls={
        <div className={'d-flex gap-2 justify-content-start'}>
          <Dropdown drop="down">
            <Dropdown.Toggle size="sm" variant={'secondary'} role="button">
              Options
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => saveModel()}>Save model</Dropdown.Item>
              <Dropdown.Item
                disabled={!maxVersion}
                onClick={() => {
                  setOpenVersionsListFor(modelId as string);
                }}
              >
                View versions
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!maxVersion}
                onClick={async () => {
                  await continueTraining(modelId as string);
                }}
              >
                Continue training
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!maxVersion}
                onClick={async () => {
                  toast.promise(regenerateUniverseSet(modelId as string), {
                    loading: 'Please wait',
                    success: 'Model will regenerate universe set next iteration',
                    error: 'Error marking model for regeneration'
                  });
                }}
              >
                Regenerate universe set
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            variant="primary"
            size="sm"
            disabled={!has10ValidExamplesPerLabel || domainsInMultipleLabels.length > 0 || isSaving}
            onClick={() => saveModel(true)}
          >
            Save & Proceed to training
          </Button>
        </div>
      }
    >
      <ModelSettingsCard setModel={setModel} model={model} isNew={isNew} />
      <LabelsCard
        model={model}
        labels={labels}
        setLabels={setLabels}
        trainingExamples={trainingExamples}
        setTrainingExamples={setTrainingExamples}
        has10ValidExamplesPerLabel={has10ValidExamplesPerLabel}
        domainsInMultipleLabels={domainsInMultipleLabels}
      />
      <SourceCriteriaCard
        model={model}
        handleChange={handleChange}
        handleCommitChanges={handleCommitChanges}
        handleChangeAndCommit={handleChangeAndCommit}
        errorsOpen={errorsOpen}
      />

      {openVersionsListFor && (
        <VersionListModal
          modelId={openVersionsListFor}
          show={!!openVersionsListFor}
          onHide={() => {
            setOpenVersionsListFor(null);
          }}
        />
      )}
    </NarrowLayout>
  );
}
