import { Accordion, Button, Form, Table } from 'react-bootstrap';
import Tag from 'src/components/Tag';
import ScoreHover from './ScoreHover';
import { formatDate, pluralize } from 'src/utils/string';
import { FieldGroup, FieldValueFinding } from '../../types';
import ScoringRuleSlider from 'src/pages/scoring/components/editor/ScoringRuleSlider';
import { useState } from 'react';

function ScoringAnalysisFinding({
  group,
  toggleRule,
  selectedRuleIds,
  setSelectedRuleIds
}: {
  group: FieldGroup;
  toggleRule: any;
  selectedRuleIds: string[];
  setSelectedRuleIds(value: React.SetStateAction<string[]>): void;
}) {
  const cntActive = group.findings.filter(f => selectedRuleIds.includes(f.id)).length;
  const isItemChecked = (item: FieldValueFinding) => selectedRuleIds.includes(item.id);
  const resetGroup: React.MouseEventHandler<HTMLButtonElement> = evt => {
    evt.stopPropagation();
    setSelectedRuleIds(ruleIds => ruleIds.filter(ruleId => !group.findings.find(({ id }) => id === ruleId)));
  };
  const selectAll: React.MouseEventHandler<HTMLButtonElement> = evt => {
    evt.stopPropagation();
    setSelectedRuleIds(ruleIds => [...ruleIds, ...group.findings.map(({ id }) => id)]);
  };

  const hasUnselectedRules = group.findings.map(({ id }) => id).some(id => !selectedRuleIds.includes(id));

  return (
    <Accordion.Item eventKey={group.fieldName}>
      <Accordion.Header>
        {group.displayLabel}
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        <span className="text-muted">
          {group.cntPositive} strong positive {pluralize(group.cntPositive, 'indicator', 'indicators')} and{' '}
          {group.cntNegative} strong negative {pluralize(group.cntNegative, 'indicator', 'indicators')}
        </span>
        &nbsp;&nbsp;
        {cntActive ? <Tag color="#8c6cf5">{cntActive} selected</Tag> : ''}
        {cntActive && (
          <Button size="sm" style={{ marginLeft: '3px' }} variant="white" onClick={resetGroup}>
            Unselect all
          </Button>
        )}
        {hasUnselectedRules && (
          <Button size="sm" style={{ marginLeft: '3px' }} variant="white" onClick={selectAll}>
            Select all
          </Button>
        )}
      </Accordion.Header>
      <Accordion.Body>
        <Table bordered size="sm" className="table-centered">
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Value</th>
              <th style={{ width: '20%' }}>Add rule?</th>
              <th style={{ width: '50%' }}>Score</th>
            </tr>
          </thead>
          <tbody>
            {group.findings.map(item => (
              <tr key={item.id}>
                <td>{valueToDisplay(item.value)}</td>
                <td>
                  <Form.Check checked={isItemChecked(item)} onChange={ev => toggleRule(item.id, ev.target.checked)} />
                </td>
                <td>
                  <div className="d-flex flex-row align-items-center">
                    <ScoringRuleSlider rule={item} modifyRule={() => {}} disabled />
                    &nbsp;&nbsp;
                    <ScoreHover item={item} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  );
}

type ScoringAnalysisFindingsTableParams = {
  toggleRule: (ruleId: string, isActive: boolean) => void;
  groupedFindings: FieldGroup[];
  selectedRuleIds: string[];
  setSelectedRuleIds(value: React.SetStateAction<string[]>): void;
  scoringAnalysisReport: any;
};

export default function ScoringAnalysisFindingsTable({
  groupedFindings,
  toggleRule,
  selectedRuleIds,
  setSelectedRuleIds,
  scoringAnalysisReport
}: ScoringAnalysisFindingsTableParams) {
  const [showAllFields, setShowAllFields] = useState(false);

  const countAll = groupedFindings.length;
  const countImpactful = groupedFindings.filter(g => g.isImpactful).length;
  const hasMoreGroups = countAll > countImpactful;

  return (
    <>
      <Accordion>
        {groupedFindings
          .filter(g => showAllFields || g.isImpactful)
          .map(group => (
            <ScoringAnalysisFinding
              key={group.fieldName}
              group={group}
              toggleRule={toggleRule}
              selectedRuleIds={selectedRuleIds}
              setSelectedRuleIds={setSelectedRuleIds}
            />
          ))}
      </Accordion>
      <div className="d-flex mt-4 flex-row justify-content-between">
        <div>
          {hasMoreGroups && (
            <div className="flex-grow-1 d-flex">
              <Form.Switch checked={!showAllFields} onChange={() => setShowAllFields(!showAllFields)} /> Hide low impact
              fields
            </div>
          )}
        </div>
        <div>Scoring analysis generated {formatDate(new Date(scoringAnalysisReport.updatedAt))}</div>
      </div>
    </>
  );
}

function valueToDisplay(str: any) {
  if (typeof str === 'string' && str.startsWith('range:')) {
    const parts = str.split(':').slice(1);
    if (parts[0] === 'undefined') {
      return `<${parts[1]}`;
    } else if (parts[1] === 'undefined') {
      return `${parts[0]}+`;
    }
    return `${parts[0]} - ${parts[1]}`;
  }
  if (typeof str === 'string' && str.startsWith('length:')) {
    const parts = str.split(':').slice(1);
    return `Length: >= ${parts[0]} items`;
  }
  if (str === true) {
    return <Tag>True</Tag>;
  }
  if (str === false) {
    return <Tag>False</Tag>;
  }
  if (str === null) {
    return <Tag>NULL</Tag>;
  }
  return JSON.stringify(str);
}
