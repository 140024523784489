import { useCallback, useEffect, useState } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { useErrorBoundary } from 'react-error-boundary';
import API from 'src/utils/API';
import { useClient } from 'src/auth';
import NarrowLayout from 'src/layouts/Narrow';
import { AddUserModal } from './components/AddUserModal';
import { UserList } from './components/UserList';
import { User, UserApiList } from './types';
import toast from 'react-hot-toast';
import { ModifyUserModal } from './components/ModifyUserModal';
import { FiUserPlus } from 'react-icons/fi';
import { usePageTitle } from 'src/utils/usePageTitle.ts';

export default function UserManagementPage({ title }: any) {
  const { clientName } = useClient();
  const { showBoundary } = useErrorBoundary();

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [modifySelectedUser, setModifySelectedUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await API.get<UserApiList>('/app/users', {});
      setUsers(data.users);
      setIsLoading(false);
    } catch (err) {
      showBoundary(err);
    }
  }, [showBoundary]);

  const addUser = useCallback(
    async (email: string, userPermissions: string[]) => {
      // Check if exists
      const existingUser = users.find(u => u.email === email);
      if (existingUser) {
        toast.error('That user already exists in this account. Please modify the existing user instead.');
        return;
      }
      toast.promise(
        (async () => {
          await API.post<UserApiList>('/app/users', {}, { email, userPermissions });
          loadUsers();
        })(),
        {
          loading: 'Adding User...',
          success: 'User was added',
          error: 'Error adding user'
        }
      );
    },
    [loadUsers, users]
  );

  const modifyUser = useCallback(
    async (userId: string, userPermissions: string[]) => {
      // Check if exists
      const existingUser = users.find(u => u.userId === userId);
      if (!existingUser) {
        toast.error('That user is not added to this account.');
        return;
      }
      toast.promise(
        (async () => {
          await API.post<{ user: User }>('/app/users/' + userId, {}, { userPermissions });
          loadUsers();
        })(),
        {
          loading: 'Modifying User...',
          success: 'User was modified',
          error: 'Error modifying user'
        }
      );
    },
    [loadUsers, users]
  );

  const removeUser = useCallback(
    async (userId: string) => {
      toast.promise(
        (async () => {
          await API.del('/app/users/' + userId, {});
          loadUsers();
        })(),
        {
          loading: 'Deleting User...',
          success: 'User was deleted',
          error: 'Error deleting user'
        }
      );
    },
    [loadUsers]
  );

  usePageTitle(title);

  // Load users upon first render
  useEffect(() => {
    loadUsers();
  }, [clientName, loadUsers]);

  return (
    <>
      <NarrowLayout title="User Management">
        <Card>
          <Card.Header>
            <h4 className="card-header-title">Users</h4>
            <Button size="sm" onClick={() => setShowAddUserModal(true)}>
              <FiUserPlus />
              &nbsp; Invite user
            </Button>
          </Card.Header>
          <Card.Body>
            <ListGroup className="list-group-flush my-n3">
              <UserList
                isLoading={isLoading}
                users={users}
                onRemoveUser={removeUser}
                onOpenModifyUser={(user: User | null) => {
                  setModifySelectedUser(user);
                }}
              />
            </ListGroup>
          </Card.Body>
        </Card>
        <AddUserModal show={showAddUserModal} onAddUser={addUser} onHide={() => setShowAddUserModal(false)} />
        <ModifyUserModal
          user={modifySelectedUser}
          onHide={() => setModifySelectedUser(null)}
          onModifyUser={modifyUser}
        />
      </NarrowLayout>
    </>
  );
}
