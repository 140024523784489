import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { getCsvExport, getCsvExportDownloadUrls } from 'src/apis/csvExports/apis';
import { CsvExportItem } from 'src/apis/csvExports/types';
import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import PageLoader from 'src/components/PageLoader';
import { FiDownload } from 'react-icons/fi';
import LoadingButton from 'src/components/LoadingButton';
import { FaFileCsv } from 'react-icons/fa6';
import { useClient } from 'src/auth';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import NavButton from 'src/components/NavButton';

async function downloadExportPart(exportId: string, partName: string) {
  const exportInfo = await getCsvExportDownloadUrls(exportId);
  console.log('exportInfo', exportInfo);
  for (const part of exportInfo.partDownloads) {
    if (part.filename === partName) {
      window.open(part.downloadUrl);
    }
  }
}

export function ExportViewPageInner() {
  const params = useParams();

  const exportId = params.exportId as string;
  const [isLoading, setIsLoading] = useState(true);
  const [exportItem, setExportItem] = useState<CsvExportItem | null>(null);

  useEffect(() => {
    getCsvExport(exportId as string).then(response => {
      setIsLoading(false);
      setExportItem(response);
    });
  }, [exportId]);

  if (isLoading || !exportItem) {
    return <PageLoader />;
  }

  if (exportItem && exportItem?.isExpired) {
    return <>Download has expired. Please request again.</>;
  }

  return (
    <ListGroup className="list-group-flush my-n3">
      {exportItem.parts.map((part: any) => (
        <ListGroup.Item key={part.filename}>
          <Row className="align-items-center">
            <Col xs={9} className="">
              <div className="float-start">
                <FaFileCsv style={{ fontSize: 36, color: '#ccc', marginRight: 16, marginTop: -8 }} />
              </div>
              <h3>{part.filename}</h3>
            </Col>
            <Col xs="auto" className="ms-auto me-n3">
              <LoadingButton size="sm" onClick={() => downloadExportPart(exportItem.id, part.filename)}>
                <FiDownload /> Download file
              </LoadingButton>
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default function ExportViewPage({ title }: any) {
  const { clientName } = useClient();

  usePageTitle(title);

  return (
    <NarrowLayout
      title="Download files"
      preheader=""
      titleControls={
        <NavButton size="sm" variant="white" to={`/app/${clientName}/exports`}>
          View all exports
        </NavButton>
      }
    >
      <Card>
        <Card.Body>
          <ExportViewPageInner />
        </Card.Body>
      </Card>
    </NarrowLayout>
  );
}
