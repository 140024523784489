import { Pagination } from 'react-bootstrap';

export interface SimplePaginatorProps {
  pageIndex: number;
  totalPages: number;
  setPage: (pageIndex: number) => void;
  showPageButtons: boolean;
  optionsSize: number;
}

export default function SimplePaginator({
  pageIndex,
  totalPages,
  setPage,
  showPageButtons,
  optionsSize
}: SimplePaginatorProps) {
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < totalPages - 1;

  const fromIndex = Math.max(pageIndex - optionsSize, 0);
  const toIndex = Math.min(pageIndex + optionsSize, totalPages - 1);

  const pageOptions: number[] = [];
  for (let i = fromIndex; i <= toIndex; i++) {
    pageOptions.push(i);
  }
  if (pageOptions.length === 0) return <></>;

  return (
    <>
      <Pagination>
        <Pagination.First disabled={!canPreviousPage} onClick={() => setPage(0)} />
        <Pagination.Prev disabled={!canPreviousPage} onClick={() => setPage(pageIndex - 1)} />
        {showPageButtons ? (
          pageOptions.map((option, index) => (
            <Pagination.Item key={index} active={option === pageIndex} onClick={() => setPage(option)}>
              {option + 1}
            </Pagination.Item>
          ))
        ) : (
          <Pagination.Item disabled>
            Page {pageIndex + 1} of {totalPages}
          </Pagination.Item>
        )}
        <Pagination.Next disabled={!canNextPage} onClick={() => setPage(pageIndex + 1)} />
        <Pagination.Last disabled={!canNextPage} onClick={() => setPage(totalPages - 1)} />
      </Pagination>
    </>
  );
}

SimplePaginator.defaultProps = {
  showPageButtons: false,
  optionsSize: 3
};
