import { Badge, Button, Col, Dropdown, ListGroup, Row } from 'react-bootstrap';
import { FiMoreVertical } from 'react-icons/fi';
import { TalkTrack, TalkTrackProcessingStatus } from '../types';
import { useClient } from 'src/auth';
import { formatDate } from 'src/utils/string.ts';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import TalkTrackModal from 'src/pages/talk-tracks/components/TalkTrackModal';
import { showConfirm } from 'src/utils/modals';
import { encodeState } from 'src/utils/urls';

export interface TalkTrackItemProps {
  talkTrack: TalkTrack;
  onDelete: () => void;
  onRename: (newName: string) => void;
}

function navigateToTalkTrack(clientName: string, talkTrack: TalkTrack) {
  const state = { filters: talkTrack.filters, currentTalkTrackId: talkTrack.id, talkTrackName: talkTrack.name };
  const stateEncoded = encodeState(state);
  return `/app/${clientName}/#state=${stateEncoded}`;
}

export default function TalkTrackItem({ talkTrack, onDelete, onRename }: TalkTrackItemProps) {
  const { clientName } = useClient();
  const [showUpdateTalkTrackModel, setShowUpdateTalkTrackModel] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <ListGroup.Item key={talkTrack.id}>
        <Row className="align-items-center">
          <Col xs={8} className="ms-n2">
            <h3>
              {talkTrack.name}{' '}
              {talkTrack.processingStatus === TalkTrackProcessingStatus.Waiting ||
              talkTrack.processingStatus === TalkTrackProcessingStatus.Running ? (
                <Badge style={{ marginLeft: 8, marginTop: -2 }} bg="secondary">
                  Updating...
                </Badge>
              ) : (
                ''
              )}
            </h3>
            <span className="text-muted">
              Companies: {talkTrack.companyCount ?? 'N/A'} &nbsp; Last modified: {formatDate(talkTrack.lastModifiedAt)}
            </span>
          </Col>
          <Col xs="auto" className="ms-auto me-n3">
            <Button
              size="sm"
              onClick={() => {
                navigate(navigateToTalkTrack(clientName, talkTrack));
              }}
            >
              View Companies / Edit
            </Button>
          </Col>
          <Col xs="auto">
            <Dropdown align="end">
              <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                <FiMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={async () => {
                    const isSure = await showConfirm(
                      'This will remove this tag from your dataset. Are you sure?',
                      'Yes, delete',
                      'No, cancel',
                      'Confirm deletion'
                    );
                    if (isSure) onDelete();
                  }}
                >
                  Delete tag
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setShowUpdateTalkTrackModel(true);
                  }}
                >
                  Rename tag
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </ListGroup.Item>
      <TalkTrackModal
        show={showUpdateTalkTrackModel}
        onHide={() => setShowUpdateTalkTrackModel(false)}
        talkTrackId={talkTrack.id}
        talkTrackName={talkTrack.name}
        filters={talkTrack.filters}
        afterSubmit={(update: { talkTrackId?: string; name: string; filters: any }) => {
          onRename(update.name);
        }}
      />
    </>
  );
}
