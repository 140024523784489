import { Form, InputGroup } from 'react-bootstrap';
import './inputs.scss';

export function BasicTextInput({
  title,
  value,
  disabled = false,
  onUpdate,
  adornment
}: {
  title: string;
  value: string;
  disabled?: boolean;
  onUpdate: (value: string) => void;
  adornment?: JSX.Element;
}) {
  return (
    <InputGroup className={'input-group'} size={'sm'}>
      <InputGroup.Text className="input-text-header">{title}</InputGroup.Text>
      <Form.Control
        className={'input-text'}
        placeholder=""
        disabled={disabled}
        value={value}
        onChange={e => onUpdate(e.target.value)}
      />
      {adornment}
    </InputGroup>
  );
}
