import { Pagination } from 'react-bootstrap';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

export interface CardPaginatorProps {
  pageIndex: number;
  totalPages: number;
  setPage: (pageIndex: number) => void;
  optionsSize?: number;
}

export default function CardPaginator({ pageIndex, totalPages, setPage, optionsSize }: CardPaginatorProps) {
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < totalPages - 1;

  const _optionsSize = optionsSize ?? 3;
  const fromIndex = Math.max(pageIndex - _optionsSize, 0);
  const toIndex = Math.min(pageIndex + _optionsSize, totalPages - 1);

  const pageOptions: number[] = [];
  for (let i = fromIndex; i <= toIndex; i++) {
    pageOptions.push(i);
  }
  const hasMorePageOptionsBefore = pageIndex > _optionsSize;
  const hasMorePageOptionsAfter = pageIndex < totalPages - _optionsSize - 1;

  return (
    <div className="d-flex justify-content-between" style={{ paddingTop: 16, paddingBottom: 16 }}>
      <Pagination className="card-pagination pagination-tabs">
        <Pagination.Item
          className="ps-0 pe-4 border-end"
          disabled={!canPreviousPage}
          onClick={() => setPage(pageIndex - 1)}
        >
          Prev <FiArrowLeft className="me-1" />
        </Pagination.Item>
      </Pagination>
      <Pagination className="card-pagination pagination-tabs">
        {hasMorePageOptionsBefore && <Pagination.Ellipsis disabled />}
        {pageOptions.map((option, index) => (
          <Pagination.Item key={index} active={option === pageIndex} onClick={() => setPage(option)}>
            {option + 1}
          </Pagination.Item>
        ))}
        {hasMorePageOptionsAfter && <Pagination.Ellipsis disabled />}
      </Pagination>
      <Pagination className="card-pagination pagination-tabs">
        <Pagination.Item
          className="ps-4 pe-0 border-start"
          disabled={!canNextPage}
          onClick={() => setPage(pageIndex + 1)}
        >
          Next <FiArrowRight className="ms-1" />
        </Pagination.Item>
      </Pagination>
    </div>
  );
}
