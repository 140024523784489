import { create } from 'zustand';
import { Integration } from 'src/apis/integrations';
import * as api from 'src/apis/integrations/apis.ts';
import isEqual from 'lodash/isEqual';

type IntegrationState = {
  integrations: Integration[];
  isLoading: boolean;
  fetchIntegrations: () => Promise<void>;
  addIntegration: (integration: Partial<Integration>) => Promise<boolean>;
  updateIntegration: (id: string, data: Partial<Integration>) => Promise<boolean>;
};

export const useIntegrationStore = create<IntegrationState>((set, get) => ({
  integrations: [],
  isLoading: false,
  fetchIntegrations: async () => {
    set({ isLoading: true });
    const integrations = await api.loadIntegrations();
    // Don't set the value if it hasn't changed
    const integrationsHaveChanged = !isEqual(integrations, get().integrations);

    set({ isLoading: false, ...(integrationsHaveChanged ? { integrations } : {}) });
  },
  addIntegration: async (integration: Partial<Integration>) => {
    set({ isLoading: true });
    try {
      const result = await api.createIntegration(integration);
      set(state => ({
        isLoading: false,
        integrations: state.integrations.map(c => (c.integrationType === integration.integrationType ? result : c))
      }));
      return true;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      set({ isLoading: false });
    }
  },
  updateIntegration: async (id: string, data: Partial<Integration>) => {
    set({ isLoading: true });
    try {
      const result = await api.updateIntegration(id, data);
      set(state => ({
        isLoading: false,
        integrations: state.integrations.map(c => (c.id === id ? result : c))
      }));
      return true;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      set({ isLoading: false });
    }
  }
}));
