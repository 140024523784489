import { ColumnDef } from '@tanstack/react-table';
import { SignInOutput } from 'aws-amplify/auth';
import { DataBlockDefinition, DataBlockFieldDefinition } from 'src/apis/clients/types.ts';
import { CompanyRecord } from 'src/pages/companies/types';

export enum UserPermission {
  ADMIN = 'ADMIN'
}

export enum StaffPermission {
  VIEW_ANY_ACCOUNT = 'VIEW_ANY_ACCOUNT',
  EDIT_ACCOUNTS = 'EDIT_ACCOUNTS',
  ADMIN_BETA_TESTER = 'ADMIN_BETA_TESTER',
  TEST = 'TEST'
}

export interface BasicField {
  fieldName: string;
  displayName: string;
  type: SchemaDataType;
  fieldSource: string;
  isHiddenFromUser?: boolean;
  description?: string;
}

export interface CompanySchemaField extends BasicField {
  ccmName?: string;
  configId?: string;
  isMappableInIntegration: boolean;
  dataBlockField?: any;
  dataBlock?: any;
  dynamicConfig?: any;
  allowInTalkTracks: boolean;
  isDefaultSortField?: boolean;
  customColumnDef?: ColumnDef<CompanyRecord>;
}

export type CompanySchema = CompanySchemaField[];

export interface ContactSchemaField extends BasicField {
  isMappableInIntegration: boolean;
}

export type ContactSchema = ContactSchemaField[];

export enum SchemaDataType {
  String = 'String',
  Picklist = 'Picklist',
  Number = 'Number',
  Percentage = 'Percentage',
  Currency = 'Currency',
  Date = 'Date',
  Boolean = 'Boolean',
  Multipicklist = 'Multipicklist',
  Array = 'Array'
}

export interface AppSettings {
  companiesTableFields: string[] | null;
}

export interface ClientContextType {
  clientName: string;
  userPermissions: UserPermission[];
  companiesSchema: CompanySchema;
  ccmSchema: any;
  ccmDataBlocksDefinitions: DataBlockDefinition[];
  ccmDynamicDataBlocksDefinitions: DataBlockDefinition[];
  ccmDataBlocksFields: DataBlockFieldDefinition[];
  contactsSchema: ContactSchema;
  sourcingCriteria: any;
  contactCriteria?: any[];
  isContactsEnabled: boolean;
  appSettings: AppSettings;
  featureFlags: Record<string, boolean>;
  creditBalances: Record<string, number | null>;
  usageCounts: { companies: number; contacts: number };
  limits: { companies?: number; contacts?: number };
  refresh: () => void;
  updateSettings: (update: Partial<AppSettings>, skipToast?: boolean) => void;
  hasFeatureFlag: (flag: string) => boolean;
}

export interface UserInfo {
  id: string;
  email: string;
  clients: { userId: string; clientName: string; userPermissions: UserPermission[] }[];
  staffPermissions: StaffPermission[];
}

export interface AuthContextType {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  user: UserInfo | null;
  login: (email: string, password: string) => Promise<SignInOutput>;
  logout: () => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  confirmResetPassword: (email: string, confirmationCode: string, newPassword: string) => Promise<void>;
  userHasStaffPermission: (permission: StaffPermission) => boolean;
}
