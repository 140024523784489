type Colour = {
  r: number;
  b: number;
  g: number;
};

function interpolateColours(a: Colour, b: Colour, c: Colour, valueRaw: number): Colour {
  let value = valueRaw;
  if (value < -1) value = -1;
  if (value > 1) value = 1;
  if (value === 0) return b;
  let c1 = b;
  let c2 = a;
  if (value > 0) {
    c1 = b;
    c2 = c;
  }
  value = Math.abs(value);
  return {
    r: Math.round(c1.r + (c2.r - c1.r) * value),
    b: Math.round(c1.b + (c2.b - c1.b) * value),
    g: Math.round(c1.g + (c2.g - c1.g) * value)
  };
}

function colourToCss(c: Colour): string {
  return `rgb(${c.r},${c.g},${c.b})`;
}

const COLOUR_NEGATIVE = { r: 255, g: 77, b: 79 };
const COLOUR_NEUTRAL = { r: 180, g: 180, b: 150 };
const COLOUR_POSITIVE = { r: 82, g: 220, b: 26 };

export function interpolateScoringColour(v: number) {
  return colourToCss(interpolateColours(COLOUR_NEGATIVE, COLOUR_NEUTRAL, COLOUR_POSITIVE, v));
}
