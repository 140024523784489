import { pluralize } from 'src/utils/string.ts';
import { Alert, Button, Modal, Row, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CoverageAnalysisDomains } from 'src/pages/staff/coverageAnalysis/types.ts';
import DomainsModal from 'src/pages/staff/coverageAnalysis/domains/DomainsModal.tsx';
import { clearDomains } from 'src/pages/staff/coverageAnalysis/apis.ts';
import toast from 'react-hot-toast';

function EditButton({
  showModal,
  setShowModal,
  showDomains,
  setShowDomains,
  onSave,
  domains,
  disabled = false
}: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  showDomains: string[] | undefined;
  setShowDomains: (value: string[] | undefined) => void;
  onSave: (domains: string[]) => void;
  domains: string[];
  disabled?: boolean;
}) {
  return (
    <Row xs={'auto'} className={'flex-row-reverse'}>
      <Button size={'sm'} onClick={() => setShowModal(true)} disabled={disabled}>
        Edit domains
      </Button>
      <DomainsModal show={showModal} onHide={() => setShowModal(false)} onSave={onSave} domains={domains} />
      <Modal show={!!showDomains} onHide={() => setShowDomains(undefined)}>
        <Modal.Header closeButton>{showDomains?.length} Domains</Modal.Header>
        <Modal.Body>
          <pre>{showDomains ? showDomains.join('\n') : ''}</pre>
        </Modal.Body>
      </Modal>
    </Row>
  );
}

export default function DomainsPanel({
  domains,
  setDomains,
  runAnalysis,
  isLoading,
  openDomainsOnStart
}: {
  domains: string[];
  setDomains: (domains: string[]) => void;
  runAnalysis: () => void;
  isLoading: boolean;
  openDomainsOnStart: boolean;
}) {
  const [checkedDomains, setCheckedDomains] = useState<CoverageAnalysisDomains | null>();
  const [showDomains, setShowDomains] = useState<string[] | undefined>(undefined);
  const [showModal, setShowModal] = useState(openDomainsOnStart);
  const [isRunning, setIsRunning] = useState(false);
  // to prevent to often domains rechecking
  const [lastCheckedDomains, setLastCheckedDomains] = useState<string[] | null>(null);

  const onModalSave = async (domains: string[]) => {
    setIsRunning(true);
    const toastId = toast.loading('Checking domains...');
    try {
      const cleanDomains = await clearDomains(domains || []);
      setCheckedDomains(cleanDomains);
      setDomains(domains);
      setLastCheckedDomains(domains);
      toast.dismiss(toastId);
      runAnalysis();
    } catch (err) {
      setCheckedDomains(null);
      toast.dismiss(toastId);
      toast.error('Domains check failed');
    } finally {
      setIsRunning(false);
    }
  };

  useEffect(() => {
    if (domains && domains.length > 0 && JSON.stringify(domains) !== JSON.stringify(lastCheckedDomains)) {
      setIsRunning(true);
      clearDomains(domains)
        .then(cleanDomains => {
          setCheckedDomains(cleanDomains);
          setLastCheckedDomains(domains);
        })
        .catch(() => {
          setCheckedDomains(null);
          setLastCheckedDomains(null);
        })
        .finally(() => {
          setIsRunning(false);
        });
    }
  }, [domains, lastCheckedDomains, setDomains]);

  if (isRunning) {
    return (
      <>
        <Row>
          <div
            className={'d-flex justify-content-center align-items-center'}
            style={{ height: '100%', minHeight: '70px' }}
          >
            <Spinner animation="border" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </Row>
        <EditButton
          showModal={showModal}
          setShowModal={setShowModal}
          showDomains={showDomains}
          setShowDomains={setShowDomains}
          onSave={onModalSave}
          disabled={true}
          domains={domains}
        />
      </>
    );
  }

  if (!checkedDomains) {
    return (
      <>
        <Row>
          <Alert variant="danger">Domains not provided</Alert>
        </Row>{' '}
        <EditButton
          showModal={showModal}
          setShowModal={setShowModal}
          showDomains={showDomains}
          setShowDomains={setShowDomains}
          onSave={onModalSave}
          domains={domains}
        />
      </>
    );
  }
  return (
    <>
      <Row>
        <Alert>
          We found{' '}
          <a
            href="#"
            onClick={ev => {
              ev.preventDefault();
              setShowDomains(checkedDomains.matchedDomains);
            }}
          >
            {checkedDomains.matchedDomains.length ?? 0} companies
          </a>{' '}
          in GoodFit universe matching the target domains you supplied.
          {checkedDomains.notMatchedDomains.length > 0 ? (
            <>
              {' '}
              <a
                href="#"
                onClick={ev => {
                  ev.preventDefault();
                  setShowDomains(checkedDomains.notMatchedDomains);
                }}
              >
                {checkedDomains.notMatchedDomains.length}{' '}
                {pluralize(checkedDomains.notMatchedDomains.length, 'domain', 'domains')}
              </a>
              {pluralize(checkedDomains.notMatchedDomains.length, ' was ', ' were ')}
              not found.
            </>
          ) : (
            ''
          )}
          {checkedDomains.invalidDomains.length > 0 ? (
            <>
              {' '}
              <a
                href="#"
                onClick={ev => {
                  ev.preventDefault();
                  setShowDomains(checkedDomains.invalidDomains);
                }}
              >
                {checkedDomains.invalidDomains.length}{' '}
                {pluralize(checkedDomains.invalidDomains.length, 'domain', 'domains')}
              </a>
              {pluralize(checkedDomains.invalidDomains.length, ' was ', ' were ')}
              invalid.
            </>
          ) : (
            ''
          )}
        </Alert>
      </Row>
      <EditButton
        showModal={showModal}
        setShowModal={setShowModal}
        showDomains={showDomains}
        setShowDomains={setShowDomains}
        onSave={onModalSave}
        domains={domains}
        disabled={isLoading}
      />
    </>
  );
}
