import API from 'src/utils/API.ts';
import { DataBlockDefinition } from 'src/apis/clients/types.ts';

export async function getAllDataBlocks() {
  return await API.get<{ dataBlocks: DataBlockDefinition[] }>(`/app/data-blocks`, {});
}

export async function fetchFieldOptions(field: any, dataBlockConfig: any | undefined, search: string | undefined) {
  const params: any = {
    field: JSON.stringify(field),
    search
  };
  if (dataBlockConfig) {
    params.dataBlockConfig = JSON.stringify(dataBlockConfig);
  }

  return await API.get('/app/data-blocks/field-options', params);
}
