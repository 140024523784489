import { Col, ListGroup, Placeholder, Row } from 'react-bootstrap';
import { CsvExportItem } from 'src/apis/csvExports/types';
import EmptyMessage from 'src/components/EmptyMessage';
import CsvExportListItem from './CsvExportListItem';

function CsvItemPlaceholder() {
  return (
    <ListGroup.Item>
      <Row className="align-items-center">
        <Col xs={9} className="ms-n2">
          <Placeholder as="div" animation="glow">
            <Placeholder xs={10} />
          </Placeholder>
          <Placeholder as="div" animation="glow">
            <Placeholder xs={3} />
          </Placeholder>
        </Col>
        <Col xs="auto">
          <Placeholder as="div" animation="glow">
            <Placeholder xs={3} />
          </Placeholder>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default function CsvExportList({ exportItems, isLoading }: any) {
  if (isLoading) {
    return (
      <ListGroup className="list-group-flush my-n3">
        <CsvItemPlaceholder />
        <CsvItemPlaceholder />
        <CsvItemPlaceholder />
      </ListGroup>
    );
  }

  if (exportItems.length === 0) {
    // Todo we want to add some other elements to the EmptyMessage, icon and action button?
    return <EmptyMessage style={{ height: 300 }} message="There are no CSV exports in your account" />;
  }

  return (
    <ListGroup className="list-group-flush my-n3">
      {exportItems.map((exportItem: CsvExportItem) => (
        <CsvExportListItem key={exportItem.id} exportItem={exportItem} />
      ))}
    </ListGroup>
  );
}
