import { useEffect, useMemo, useState } from 'react';
import { Form, Fade, Collapse } from 'react-bootstrap';
import Select from 'react-select';

import { loadSegments } from '../../apis';
import { DEFAULT_THEME } from 'src/utils/react-select';
import intersection from 'lodash/intersection';

export function CheckToRevealSegments({ name, label, initialValue, onChange, suppressSegmentIds }: any) {
  const [checked, setChecked] = useState(!!initialValue);
  const [changed, setChanged] = useState(false);
  const [selectedSegmentIds, setSelectedSegmentIds] = useState(initialValue ?? []);

  const [isLoading, setIsLoading] = useState(true);
  const [segments, setSegments] = useState<{ value: any; label: string }[]>([]);

  useEffect(() => {
    loadSegments(1000).then(result => {
      // Filter out any missing segment Ids so that they dont show as empty pills
      const allSegmentIds = result.items.map(s => s.id);
      setSelectedSegmentIds(intersection(allSegmentIds, selectedSegmentIds));
      setSegments(
        result.items.filter(s => !suppressSegmentIds.includes(s.id)).map(s => ({ value: s.id, label: s.name }))
      );
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (changed && onChange) {
      onChange(checked ? selectedSegmentIds : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, selectedSegmentIds, changed]);

  const selectedOptions = useMemo(() => {
    return segments.filter(o => selectedSegmentIds.includes(o.value));
  }, [segments, selectedSegmentIds]);

  return (
    <div>
      <Form.Check
        type="checkbox"
        checked={checked}
        onChange={ev => {
          setChecked(ev.target.checked);
          setChanged(true);
        }}
        name={name}
        id={name}
        label={label}
      />
      <Fade in={checked} timeout={300} unmountOnExit={false}>
        <Collapse in={checked}>
          <div className="ps-4 py-3">
            <Select
              isLoading={isLoading}
              value={selectedOptions}
              options={segments}
              className="react-select-container"
              classNamePrefix="react-select"
              isMulti
              theme={DEFAULT_THEME}
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
              onChange={values => {
                setSelectedSegmentIds(values.map(option => option.value).filter(id => !!id));
                setChanged(true);
              }}
            />
          </div>
        </Collapse>
      </Fade>
    </div>
  );
}
