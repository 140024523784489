import { ColumnDef, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';

import ErrorComponent from 'src/components/ErrorMessage';
import PageLoader from 'src/components/PageLoader';
import { AllContactsForCompany } from 'src/pages/segments/types';
import { PAGE_SIZE, useAllCompanyContacts } from './useCompanyContacts';
import TableGrid from 'src/components/Table/TableGrid';
import SimplePaginator from 'src/components/Table/SimplePaginator';
import { pluralize, titleCase } from 'src/utils/string';
import { formatNumber } from 'src/utils/number';
import { mapSeniority } from 'src/pages/segments/utils';
import { CellLink } from '../../view/personas/CellLink';

interface ModalBodyContentProps {
  segmentId: string;
  companyId: string;
}

function ErrorMessage({ msg }: { msg: string }) {
  return <ErrorComponent message={msg} style={{ height: 490 }} fullHeight={false} />;
}

const columns: ColumnDef<AllContactsForCompany['items'][number]>[] = [
  {
    accessorKey: 'raw_name',
    header: 'Name',
    cell: ({ row: { original } }) => <CellLink linkText={original.raw_name} linkUrl={original.person_url} />
  },
  { accessorKey: 'position', header: 'Position', cell: c => titleCase(c.row.original.position) },
  {
    accessorKey: 'seniority',
    header: 'Seniority',
    cell: c => mapSeniority(c.row.original.seniority)
  },
  { accessorKey: 'country', header: 'Country' }
];

export function ModalBody({ companyId, segmentId }: ModalBodyContentProps) {
  const { isLoading, isError, companyContacts, setPage, pagination } = useAllCompanyContacts({ segmentId, companyId });

  const pageCount = Math.ceil((companyContacts?.count ?? 1) / PAGE_SIZE);

  const table = useReactTable({
    columns,
    pageCount,
    data: companyContacts?.items ?? [],
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { pagination }
  });

  if (isLoading) return <PageLoader fullHeight={false} style={{ height: 490 }} message="Fetching contacts..." />;
  if (isError) return <ErrorMessage msg="Failed to fetch contacts, please try again" />;
  if (companyContacts?.count === 0) return <ErrorMessage msg="No contacts found for this company" />;

  return (
    <>
      <div style={{ padding: 5, background: 'var(--bs-gray-100)', textAlign: 'center' }}>
        Found {formatNumber(companyContacts?.count ?? 0)}{' '}
        {pluralize(companyContacts?.count ?? 0, 'contact', 'contacts')}
      </div>

      <TableGrid
        table={table}
        isLoading={false}
        dataColumns={columns.length}
        wrapperClassName="scrolling-wrapper"
        className="table-uniform-width-columns table-compact table-centered sticky-thead"
      />

      <div className="d-flex justify-content-end mt-3 me-3 pagination-wrapper">
        <SimplePaginator
          setPage={setPage}
          totalPages={pageCount}
          pageIndex={pagination.pageIndex / pagination.pageSize}
        />
      </div>
    </>
  );
}
