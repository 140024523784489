import { useEffect, useState } from 'react';

/** e.g. '(max-width: 600px)' */
type MediaQuery = `(${'max' | 'min'}-width: ${number}px)`;

/**
 * Helper Util: Identify the media format for the current device
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 *
 * **USAGE:**
 * ```tsx
 * const Component = () => {
 *   const isSmallScreen = useMediaQuery('(max-width: 768px)')
 *
 *   return <div>{isSmallScreen ? 'Little' : 'Big'} Div</div>
 * }
 * ```
 */
export const useMediaQuery = (query: MediaQuery): boolean => {
  const [mediaMatchesQuery, setMediaMatchesQuery] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const handleMediaChange = (): void => setMediaMatchesQuery(mediaQueryList.matches);

    mediaQueryList.addEventListener('change', handleMediaChange);

    return () => mediaQueryList.removeEventListener('change', handleMediaChange);
  }, [query]);

  return mediaMatchesQuery;
};
