import { DndProvider, useDrop } from 'react-dnd';
import { PersonaRule } from './PersonaCritieraRule';
import { showModal } from 'src/utils/modals';
import { PersonaCriteriaRuleEditModal } from './PersonaCriteriaRuleEditModal';
import { SegmentPersonaCriteria } from 'src/pages/segments/types';
import { HTML5Backend } from 'react-dnd-html5-backend';

type DraggablePersonaListProps = {
  segmentId: string;
  criteriaGroups: SegmentPersonaCriteria[];
  editCriteria: (idx: number, criteria: SegmentPersonaCriteria) => void;
  deleteCriteria: (idx: number) => void;
  moveCriteria: (dragIndex: number, hoverIndex: number) => void;
  duplicateCriteria: (idx: number) => void;
};

function DroppableSpace({
  idx,
  skipArea,
  moveCriteria
}: {
  idx: number;
  skipArea?: 'top' | 'bottom';
  moveCriteria: (dragIndex: number, hoverIndex: number) => void;
}) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'PERSONA_RULE',
      drop: (item: { idx: number }) => {
        const dragIndex = item.idx;
        const hoverIndex = idx;
        if (dragIndex !== hoverIndex) {
          moveCriteria(dragIndex, hoverIndex);
        }
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [idx, moveCriteria]
  );
  return (
    // this could be nicer, trying to limit the time spent on this
    <div
      style={{
        position: 'relative',
        height: isOver ? '20px' : '1px'
      }}
    >
      {/*this is to create a little space overlapping cards, where we can drop one*/}
      <div
        ref={drop}
        style={{
          position: 'absolute',
          top: !isOver && skipArea === 'top' ? '0px' : '-20px',
          bottom: skipArea === 'bottom' ? '0px' : '-20px',
          left: '0',
          right: '0',
          opacity: isOver ? 100 : 0
        }}
      >
        <hr
          style={{
            border: '1px dashed black',
            opacity: '40%',
            width: '100%',
            position: 'absolute',
            top: '15px',
            transform: 'translateY(-50%)'
          }}
        />
      </div>
    </div>
  );
}

export function DraggablePersonaCriteriaList({
  segmentId,
  criteriaGroups,
  editCriteria,
  deleteCriteria,
  moveCriteria,
  duplicateCriteria
}: DraggablePersonaListProps) {
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <DroppableSpace idx={-1} skipArea={'top'} moveCriteria={moveCriteria} />
        {criteriaGroups.map((g: SegmentPersonaCriteria, idx: number) => (
          <div key={idx}>
            <PersonaRule
              idx={idx}
              groupNumber={idx + 1}
              positionInclusionKeywords={g?.positionInclusionKeywords ?? []}
              positionExclusionKeywords={g?.positionExclusionKeywords ?? []}
              seniorities={g?.seniorities ?? []}
              countries={g?.countries ?? []}
              onDuplicate={() => duplicateCriteria(idx)}
              onEdit={async () => {
                const criteria = await showModal(PersonaCriteriaRuleEditModal, { criteria: g, segmentId });
                if (criteria) editCriteria(idx, criteria as SegmentPersonaCriteria);
              }}
              onDelete={() => deleteCriteria(idx)}
            />
            <DroppableSpace idx={idx} skipArea={'bottom'} moveCriteria={moveCriteria} />
          </div>
        ))}
      </div>
    </DndProvider>
  );
}
