export enum CreditType {
  ENRICHMENT = 'ENRICHMENT',
  CONTACT = 'CONTACT'
}

export enum Action {
  SET = 'SET',
  ADD = 'ADD'
}

export type BalanceUpdate = {
  amount: number | null;
  action: Action;
  creditType: CreditType;
};

export type CreditBalance = { creditBalance: Record<CreditType, number> };

export type LimitBalance = { limits: Limits; usage: Limits };

export type Limits = {
  companies?: number | null;
  contacts?: number | null;
};

export type LimitType = 'companies' | 'contacts';

export type LimitUpdate = {
  amount: number | null;
  limitType: LimitType;
};
