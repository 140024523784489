import { ClientConfig, FieldMappingDefinition } from 'src/apis/clients/types.ts';
import { ValidationFailed } from './types.ts';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import { DataBlockFieldType, FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';

export function validate(clientConfig: Partial<ClientConfig>, fieldMapping: FieldMapping[]): ValidationFailed {
  const numberedFields =
    clientConfig?.fieldDefinitions?.map((f, index) => ({
      ...f,
      index
    })) || [];
  const errors: ValidationFailed = {
    fieldExternalNameIsNull: validateNotNullExternalNames(numberedFields),
    fieldExternalNameNotUnique: validateNonUniqueExternalNames(numberedFields),
    dataBlocksMissing: validateNoMissingDataBlockConfigs(
      numberedFields,
      fieldMapping,
      clientConfig.dataBlockConfigs || []
    ),
    contactCriteriaMissingProperties: validateContactCriteria(clientConfig.contactCriteria as any[]),
    emptySourcingCriteria: clientConfig.sourcingCriteria?.rules?.length === 0
  };
  errors.validationFailed = some(
    errors,
    value => (Array.isArray(value) && value.length > 0) || (isBoolean(value) && value)
  );
  return errors;
}

function validateNotNullExternalNames(numberedFields: FieldMappingDefinition[]): any[] {
  return numberedFields.filter(f => !f.externalName || f.externalName === '') || [];
}

function validateNonUniqueExternalNames(numberedFields: FieldMappingDefinition[]): any[] {
  const fieldsCounts = groupBy(numberedFields, 'externalName');
  const nonUniqueExternalNames = Object.entries(fieldsCounts)
    .filter(([key, value]) => key != '' && value.length > 1)
    .map(([key, value]) => {
      key;
      return value;
    });

  return flatMap(nonUniqueExternalNames);
}

function validateNoMissingDataBlockConfigs(
  numberedFields: FieldMappingDefinition[],
  fieldMapping: FieldMapping[],
  dataBlockConfigs: any[]
): any[] {
  return (
    uniq(
      numberedFields
        .filter(f =>
          fieldMapping.find(
            m => m.dataBlockField === f.internalName && m.dataBlockFieldType === DataBlockFieldType.DynamicDataBlocks
          )
        )
        .map(f => f.configId)
    ).filter(configId => canDeleteConfig(configId as string, dataBlockConfigs)) || []
  );
}

export function canDeleteConfig(configId: string, dataBlockConfigs: any[]) {
  return !dataBlockConfigs.find(dbc => dbc.configId === configId);
}

function validateContactCriteria(contactCriteria: any[]) {
  if (!contactCriteria || contactCriteria.length === 0) {
    return [];
  }
  const failedRows = [];
  for (const [index, criteria] of contactCriteria.entries()) {
    const missingProperties = [];
    if (!criteria.persona || criteria.persona === '') {
      missingProperties.push('persona');
    }
    if (!criteria.inclusionKeywords || criteria.inclusionKeywords.length === 0) {
      missingProperties.push('inclusionKeywords');
    }
    if (
      !criteria.employeeCountBounds ||
      !isNumber(criteria.employeeCountBounds.min) ||
      !isNumber(criteria.employeeCountBounds.max)
    ) {
      missingProperties.push('employeeCountBounds');
    }
    if (missingProperties.length > 0) {
      failedRows.push({ index, missingProperties });
    }
  }
  return failedRows;
}
