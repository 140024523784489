export function formatNumber(amount: number | string, maxDecimals = 3): string {
  if (amount === undefined || amount === null) return '';
  if (typeof amount === 'number' && isNaN(amount)) return '';
  return new Intl.NumberFormat(navigator.language || 'en-GB', { maximumFractionDigits: maxDecimals }).format(
    parseFloat(`${amount}`)
  );
}

export function formatPercent(amount: number | string, maxDecimals = 3): string {
  if (amount === undefined || amount === null) return '';
  if (typeof amount === 'number' && isNaN(amount)) return '';
  return (
    new Intl.NumberFormat(navigator.language || 'en-GB', { maximumFractionDigits: maxDecimals }).format(
      parseFloat(`${amount}`) * 100
    ) + '%'
  );
}

export function formatCurrency(amount: number | string, currencyCode?: string): string {
  if (amount === undefined || amount === null) return '';
  if (typeof amount === 'number' && isNaN(amount)) return '';
  return new Intl.NumberFormat(navigator.language || 'en-GB', {
    style: 'currency',
    currency: currencyCode ?? 'USD'
  }).format(parseFloat(`${amount}`));
}
