import { ResourceLock } from 'src/apis/resourceLocks/types';

/**
 * Helper Util: Has the resource become stale?
 *
 * If no locks exist, we're safe to edit the resource and will secure a lock.
 *
 * If there is no new lock, but there was an existing lock, a lock has been
 * overridden and since released; the resource is stale.
 *
 * If the existing lock belongs to the current user, and next lock user does
 * not, the lock has been overridden by a new user; the resource is stale.
 */
export const isResourceStale = (
  newLock: ResourceLock | null,
  existingLock: ResourceLock | null,
  currentUser?: string
): boolean => {
  // No locks exist
  if (!existingLock && !newLock) return false;
  // A lock has been released
  if (!newLock && existingLock) return true;

  // Lock has changed user
  return existingLock?.lockedBy === currentUser && newLock?.lockedBy !== currentUser;
};
