import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Card, Dropdown } from 'react-bootstrap';
import { listPageElementConfigs, optionsForPageElementConfigs } from './api.ts';
import { ColumnHeader } from 'src/components/Table/listTable/ColumnHeader.tsx';
import { ListTable } from 'src/components/Table/listTable/ListTable.tsx';
import { FiMoreVertical } from 'react-icons/fi';
import { getReactTableCellRendererForType } from 'src/components/Table/cellRenderers.tsx';
import { SchemaDataType } from 'src/auth';
import NavButton from 'src/components/NavButton.tsx';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import LayoutTNG from 'src/layouts/LayoutTNG';
import './pageElementStyles.scss';
import { PreviewModal } from 'src/pages/staff/pageElements/components/PreviewModal.tsx';

export function PageElementConfigListPage() {
  usePageTitle('Page Element Configs');
  const [showPreview, setShowPreview] = useState<{ show: boolean; pageElementsConfig: any }>({
    show: false,
    pageElementsConfig: null
  });

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: (row: any) => row.name,
        enableSorting: true,
        enableColumnFilter: true,
        cell: getReactTableCellRendererForType(SchemaDataType.String),
        header: ({ column }) => (
          <ColumnHeader
            column={column}
            headerName={'Name'}
            allowSorting={true}
            allowFiltering={true}
            loadOptions={optionsForPageElementConfigs}
          />
        )
      },
      {
        id: 'type',
        accessorFn: (row: any) => row.type,
        enableSorting: true,
        enableColumnFilter: true,
        cell: getReactTableCellRendererForType(SchemaDataType.String),
        header: ({ column }) => (
          <ColumnHeader
            column={column}
            headerName={'Type'}
            allowSorting={true}
            allowFiltering={true}
            loadOptions={optionsForPageElementConfigs}
          />
        )
      },
      {
        id: 'tags',
        accessorFn: (row: any) => row.tags,
        enableSorting: true,
        enableColumnFilter: true,
        cell: getReactTableCellRendererForType(SchemaDataType.Multipicklist),
        header: ({ column }) => (
          <ColumnHeader
            column={column}
            headerName={'Tags'}
            allowSorting={true}
            allowFiltering={true}
            loadOptions={optionsForPageElementConfigs}
          />
        )
      },
      {
        id: 'Options',
        cell: c => (
          <div className={'d-flex justify-content-center'}>
            <NavButton size="sm" className={'mx-2'} to={`/admin/page-element-configs/${c.row.original.id}`}>
              Edit
            </NavButton>
            <Dropdown className="position-static">
              <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                <FiMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowPreview({ show: true, pageElementsConfig: c.row.original })}>
                  Preview
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        header: () => <>Options</>
      }
    ],
    []
  );

  return (
    <LayoutTNG preTitle={'Page elements'} title={'Page Element Configs'}>
      <Card>
        <Card.Body>
          <ListTable
            columns={columns}
            emptyMessage={'No configs found'}
            list={listPageElementConfigs}
            pollInterval={5000}
            addButton={
              <NavButton size="sm" to={`/admin/page-element-configs/new`}>
                Add new
              </NavButton>
            }
          />
        </Card.Body>
      </Card>
      {showPreview.show && (
        <PreviewModal
          show={showPreview.show}
          pageElementConfig={showPreview.pageElementsConfig}
          onHide={() => setShowPreview({ show: false, pageElementsConfig: null })}
        />
      )}
    </LayoutTNG>
  );
}
