import './styles.scss';

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { ButtonContent } from './components/ButtonContent';
import { TooltipContent } from './components/TooltipContent';
import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { useResourceLockStore } from '../../resourceLockStore';
import { secureLockForResource } from 'src/apis/resourceLocks/apis';
import { LockableResource } from 'src/apis/resourceLocks/types';

interface UnlockResourceButtonProps {
  resourceType: LockableResource;
  resourceId: string;
}

export const UnlockResourceButton: React.FC<UnlockResourceButtonProps> = ({ resourceType, resourceId }) => {
  const { isResourceStale } = useResourceLockStore(resourceType, resourceId, state => ({
    isResourceStale: state.isResourceStale
  }));

  const [isUnlocking, setIsUnlocking] = useState<boolean>(false);

  const unlockResource = async () => {
    setIsUnlocking(true);
    await secureLockForResource(resourceType, resourceId);
  };

  return (
    <TooltipWrapper
      placement="bottom"
      tooltipText={<TooltipContent isResourceStale={isResourceStale} />}
      customComponent={
        <Button className="unlock-button" size="sm" onClick={unlockResource} disabled={isResourceStale}>
          <ButtonContent isUnlocking={isUnlocking} />
        </Button>
      }
    />
  );
};
