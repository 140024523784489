import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js/min';

/**
 * Helper Util: Format a phone number
 *
 * This util uses the libphonenumber-js library to format telephone numbers in
 * their international format.
 *
 * e.g. 7863517200 --> +1 786 351 7200
 *
 * If for any reason we fail to parse the number, the original string is returned.
 *
 * @see https://www.npmjs.com/package/libphonenumber-js#country-code
 */
export const formatPhoneNumber = (phoneNumber?: string | null): string | undefined => {
  if (!phoneNumber) return;

  try {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);

    return parsedNumber?.formatInternational() ?? phoneNumber;
  } catch (e) {
    return phoneNumber;
  }
};

/**
 * Helper Util: Get the ISO 3166-1 alpha-2 Country Code for a given phone number
 *
 * This util uses the libphonenumber-js library to resolve the country code for
 * a given telephone number.
 *
 * e.g. +447710212874 --> GB
 *
 * @see https://www.npmjs.com/package/libphonenumber-js#country-code
 */
export const getCountryCodeFromPhoneNumber = (phoneNumber?: string | null): CountryCode | undefined => {
  if (!phoneNumber) return;

  try {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);

    return parsedNumber?.country;
  } catch (e) {
    return;
  }
};
