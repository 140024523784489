import { confirmable, createConfirmation } from 'react-confirm';
import { BasicConfirmationModal } from 'src/components/BasicConfirmationModal';

export function showConfirm(
  confirmation: string,
  okLabel: string = 'OK',
  cancelLabel: string = 'cancel',
  title: string = 'Confirm',
  options: any = {}
): Promise<boolean> {
  return createConfirmation(confirmable<any, boolean>(BasicConfirmationModal))({
    confirmation,
    okLabel,
    cancelLabel,
    title,
    ...options
  });
}

export function showModal<T>(
  modal: JSX.ElementType,
  options: any = {},
  onSubmit?: (response: any) => void
): Promise<T | null> {
  return createConfirmation(confirmable(modalWrap(modal, onSubmit)))({
    ...options
  }) as Promise<T | null>;
}

function modalWrap(component: any, onSubmit: any) {
  return (args: any) => {
    return component({
      ...args,
      onHide: args.proceed,
      onSubmit: (payload: any) => {
        if (onSubmit) onSubmit(payload);
        args.proceed(payload);
      }
    });
  };
}
