import { useCallback, useState } from 'react';
import { Card } from 'react-bootstrap';
import LoadingButton from 'src/components/LoadingButton';
import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import { usePageTitle } from 'src/utils/usePageTitle';
import { createScoringAnalysisReport } from './apis';
import { useNavigate } from 'react-router-dom';
import { appClientUrl } from 'src/utils/urls';
import ScoringAnalysisReportForm from './components/edit/ScoringAnalysisReportForm';
import { DEFAULT_STATE, ScoringAnalysisReportFormState, canSubmitForm } from './components/edit/utils';

export default function NewScoringAnalysisPage() {
  const navigate = useNavigate();
  const [state, setState] = useState<ScoringAnalysisReportFormState>(DEFAULT_STATE);

  usePageTitle('New scoring analysis');

  const canSubmit = canSubmitForm(state);

  const handleUpdate = useCallback((updates: any) => {
    setState(s => ({ ...s, ...updates }));
  }, []);
  const handleCreateAnalysisReport = useCallback(async () => {
    const response = await createScoringAnalysisReport(state.name, state);
    navigate(appClientUrl(`/scoring/analysis/results/${response.report.id}`));
  }, [state, navigate]);

  return (
    <NarrowLayout title="New scoring analysis" preheader="">
      <Card>
        <Card.Header>Analysis settings</Card.Header>
        <Card.Body>
          <ScoringAnalysisReportForm state={state} handleUpdate={handleUpdate} />
        </Card.Body>
        <Card.Footer>
          <LoadingButton size="sm" variant="primary" disabled={!canSubmit} onClick={handleCreateAnalysisReport}>
            Calculate scoring analysis report
          </LoadingButton>
        </Card.Footer>
      </Card>
    </NarrowLayout>
  );
}
