import { Integration } from 'src/apis/integrations';
import { Segment } from 'src/pages/segments/types';

/**
 * Helper Util: Identify whether auto sync is enabled for a given segment/integration
 */
export const isAutoSyncEnabledForSegment = (segment: Segment, integration: Integration): boolean => {
  return (
    Boolean(integration.enableAutoSync) &&
    integration.syncSegmentRecordsOnly === true &&
    integration.segmentIds.includes(segment.id)
  );
};
