import { ListGroup } from 'react-bootstrap';
import ConnectorPlaceholder from './ConnectorPlacehoder';
import ConnectorItem from './ConnectorItem';
import { Integration } from 'src/apis/integrations';

export interface ConnectorListProps {
  integrations: Integration[];
  isLoading: boolean;
  openSettingsFor: (integration: Integration) => void;
}

export default function ConnectorList({ integrations, isLoading, openSettingsFor }: ConnectorListProps) {
  if (isLoading) {
    return (
      <ListGroup className="list-group-flush my-n3">
        <ConnectorPlaceholder />
        <ConnectorPlaceholder />
        <ConnectorPlaceholder />
      </ListGroup>
    );
  }

  return (
    <ListGroup className="list-group-flush my-n3">
      {integrations.map((integration: Integration) => (
        <ConnectorItem
          key={integration.id ?? integration.integrationType}
          integration={integration}
          openSettingsFor={() => {
            openSettingsFor(integration);
          }}
        />
      ))}
    </ListGroup>
  );
}
