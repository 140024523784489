import { useEffect, useMemo, useState } from 'react';
import * as Auth from 'aws-amplify/auth';

import AuthContext from './AuthContext';
import PageLoader from 'src/components/PageLoader';
import { StaffPermission, UserInfo } from './types';
import { SignInOutput } from 'aws-amplify/auth';
import API from 'src/utils/API.ts';

function UserAuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<UserInfo | null>(null);

  async function doLogin(email: string, password: string): Promise<SignInOutput> {
    const ret = await Auth.signIn({ username: email.toLowerCase(), password });
    if (ret.isSignedIn) {
      // Need to update session so that isAuthenticated and user correct
      await initSession();
    }
    return ret;
  }

  async function doLogout() {
    await Auth.signOut();
  }

  async function doChangePassword(oldPassword: string, newPassword: string) {
    await Auth.updatePassword({ oldPassword, newPassword });
  }

  async function doResetPassword(email: string) {
    // We use a special custom route rather than the Auth cognito method directly so it accounts for new users
    await API.post<any>('/auth/reset-password', {}, { email: email.toLowerCase() });
  }

  async function doConfirmResetPassword(email: string, confirmationCode: string, newPassword: string) {
    await Auth.confirmResetPassword({ username: email.toLowerCase(), newPassword, confirmationCode });
  }

  function userHasStaffPermission(permission: StaffPermission) {
    return user?.staffPermissions.includes(permission) ?? false;
  }

  async function initSession() {
    try {
      const session = await Auth.fetchAuthSession();
      if (session?.userSub) {
        const user = await Auth.fetchUserAttributes();
        if (user.email) {
          setIsAuthenticated(true);
          const userExpanded = await API.get<any>('/auth/user', {});
          setUser(userExpanded.user);
        }
      }
    } catch (err) {
      // If there is an error here, its best to hard reset the SPA and go to login
      // (unless already on that page)
      console.error(err);
      if (window.location.pathname !== '/login') window.location.href = '/login';
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    initSession();
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticating,
      isAuthenticated,
      user,
      login: doLogin,
      logout: doLogout,
      changePassword: doChangePassword,
      resetPassword: doResetPassword,
      confirmResetPassword: doConfirmResetPassword,
      userHasStaffPermission: userHasStaffPermission
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticating, isAuthenticated, user]
  );

  if (isAuthenticating) {
    return <PageLoader />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default UserAuthProvider;
