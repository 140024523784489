import { IntegrationFieldMapping, IntegrationFieldUpdateStrategy } from 'src/apis/integrations';
import { SchemaDataType } from 'src/auth';

export type RecordType = 'company' | 'contact';

// A version of above but allows externalName to be unset and provides extra context for UI
export type IntegrationFieldMappingInput = Omit<IntegrationFieldMapping, 'externalName'> & {
  externalName: string | null;
  integrationType: string;
  integrationId: string;
  //isSaving: boolean;
};

export type DataDirectoryEntry = {
  fieldName: string; // Unique, the name in the dataset
  displayName: string;
  ccmName?: string; // CCM internal name
  type: SchemaDataType;
  isSystem?: boolean;
  dynamicConfig?: any;
  presentInCCM: boolean;
  mappings: IntegrationFieldMappingInput[];
  fieldSource: string;
  description?: string;
};

export const updateStrategyLabels: Record<IntegrationFieldUpdateStrategy, string> = {
  OVERWRITE_ALWAYS: 'Overwrite',
  OVERWRITE_ONLY_IF_EMPTY: 'Update if empty'
};
