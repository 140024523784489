import './styles.scss';

import { FaSync } from 'react-icons/fa';
import { ImSpinner8 } from 'react-icons/im';
import { FiAlertTriangle } from 'react-icons/fi';

import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { Integration } from 'src/apis/integrations';
import { useSyncStatus } from '../../../hooks/useSyncStatus';

interface SyncStatusIconProps {
  isAutoSync: boolean;
  integration: Integration;
}

export const SyncStatusIcon: React.FC<SyncStatusIconProps> = ({ integration, isAutoSync }) => {
  const { isSyncing, isConnectorFaulty } = useSyncStatus(integration);

  if (isConnectorFaulty) {
    return (
      <TooltipWrapper
        placement="bottom"
        tooltipText="There is a problem with this connector configuration"
        customComponent={<FiAlertTriangle color="red" />}
      />
    );
  }

  if (isSyncing) {
    return (
      <TooltipWrapper
        placement="bottom"
        tooltipText="A sync is currently in progress"
        customComponent={<ImSpinner8 color="lightgreen" className="spin-icon" />}
      />
    );
  }

  return (
    <TooltipWrapper
      placement="bottom"
      tooltipText={`Auto sync is ${isAutoSync ? 'enabled' : 'disabled'}`}
      customComponent={<FaSync color={isAutoSync ? 'lightgreen' : 'lightgrey'} />}
    />
  );
};
