import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useCallback, useEffect, useRef } from 'react';
import { useBeforeUnload } from 'react-router-dom';

import { getExistingLockForResource, releaseLockForResource, secureLockForResource } from 'src/apis/resourceLocks/apis';
import { LockableResource, ResourceLock } from 'src/apis/resourceLocks/types';
import { useResourceLockStore } from '../resourceLockStore';
import { isResourceStale } from './utils';
import { useAuth } from 'src/auth';

const POLL_FREQUENCY = 1_500;

interface IUseResourceLock {
  resourceType: LockableResource;
  resourceId: string;
  pollFrequency?: number;
}

/**
 * Custom Hook: Manage resource locking for a given resource
 *
 * NOTE: This hook designed to be used by the ResourceLockModal.
 * You can implement this hook elsewhere and utilise the store
 * {@link useResourceLockStore} to observe the lock state, though YMMV!
 *
 * Event Flow:
 * 1. On mount, start polling for the resource lock:
 *    - Fetch the latest lock
 *    - If a lock exists, check if it belongs to the current user or another user
 *    - If no lock exists, secure a lock for the current user
 *    - Mark the resource as locked or stale based on the lock status
 *    - If the lock is about to expire, refresh the lock
 *    - Schedule the next poll
 * 2. On unmount:
 *    - Reset the lock state
 *    - Clear the polling timeout
 *    - Release the lock if it belongs to the current user
 * 3. On page unload:
 *    - Reset the lock state
 *    - Clear the polling timeout
 *    - Release the lock if it belongs to the current user
 */
export const useResourceLock = ({ resourceType, resourceId, pollFrequency = POLL_FREQUENCY }: IUseResourceLock) => {
  const { user } = useAuth();

  const { setIsResourceStale, setIsResourceLocked, resetResourceLockState, setActiveResourceLock } =
    useResourceLockStore(resourceType, resourceId, state => ({
      setIsResourceStale: state.setIsResourceStale,
      setIsResourceLocked: state.setIsResourceLocked,
      resetResourceLockState: state.resetResourceLockState,
      setActiveResourceLock: state.setActiveResourceLock
    }));

  const isMountedRef = useRef(true);
  const timeoutRef = useRef<number | null>(null);
  /** We're using non-reactive state to store the lock locally */
  const resourceLockRef = useRef<ResourceLock | null>(null);

  const cleanupResourceLocks = useCallback(() => {
    isMountedRef.current = false;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    resetResourceLockState();
    if (resourceLockRef.current?.lockedBy === user?.email) {
      void releaseLockForResource(resourceType, resourceId);
    }
  }, [resetResourceLockState, resourceId, resourceType, user?.email]);

  useEffect(() => {
    isMountedRef.current = true;

    const handleResourceLock = async () => {
      // Abort if the component is no longer mounted
      if (!isMountedRef.current) return;

      const latestLock = await getExistingLockForResource(resourceType, resourceId);
      const lockIsStale = isResourceStale(latestLock, resourceLockRef.current, user?.email);

      if (lockIsStale) {
        setIsResourceLocked(true);
        setIsResourceStale(true);
        resourceLockRef.current = latestLock;
        setActiveResourceLock(latestLock);
        return;
      }

      // If no lock exists for the resource, secure one for the current user
      if (!latestLock) {
        const newLock = await secureLockForResource(resourceType, resourceId);

        resourceLockRef.current = newLock;
        setActiveResourceLock(newLock);
      } else if (!isEqual(latestLock, resourceLockRef.current)) {
        // Only update the resourceLock state if it has changed
        setIsResourceLocked(latestLock.lockedBy !== user?.email);
        setIsResourceStale(lockIsStale);
        resourceLockRef.current = latestLock;
        setActiveResourceLock(latestLock);
      }

      /**
       * Resource Locks have a lease of 30 minutes. If the current lease is
       * about to expire, and the session is still active, we should refresh the
       * lock to prevent it from expiring.
       */
      const shouldRefreshLease = ((): boolean => {
        if (!resourceLockRef.current) return false;

        const lockedAt = moment(resourceLockRef.current.lockedAt);
        const currentTime = moment(new Date());
        const diffInMinutes = currentTime.diff(lockedAt, 'minutes');

        return diffInMinutes > 25;
      })();

      if (shouldRefreshLease) await secureLockForResource(resourceType, resourceId);

      timeoutRef.current = window.setTimeout(handleResourceLock, pollFrequency);
    };

    handleResourceLock();

    // Cleanup on unmount (but not hard navigation)
    return cleanupResourceLocks;
  }, [
    user,
    resourceId,
    resourceType,
    pollFrequency,
    setActiveResourceLock,
    cleanupResourceLocks,
    setIsResourceLocked,
    setIsResourceStale
  ]);

  /**
   * Use the `onBeforeUnload` event to release the lock when the user forcefully
   * navigates away from this page (e.g. browser navigation -vs- SPA Routing).
   *
   * NOTE: While protection exists on the backend to release "stale locks" which
   * at the time of writing have a lease of 30 minutes (see `releaseExpiredLocks`
   * in gf-sourcers), this purely serves as a best attempt to release locks
   * which would otherwise persist.
   *
   * @see https://api.reactrouter.com/v7/functions/react_router.useBeforeUnload.html
   */
  useBeforeUnload(cleanupResourceLocks);
};
