export type DisabledPath = { path: number[]; disable: DisabledActions[] };
export type DisabledActions =
  | 'All'
  | 'combinatorSelector'
  | 'addGroupAction'
  | 'addRuleAction'
  | 'removeRuleAction'
  | 'removeGroupAction'
  | 'operatorSelector'
  | 'fieldSelector'
  | 'valueEditor'
  | 'ruleActionOrnament'
  | 'groupActionOrnament'
  | 'collapseGroupAction'
  | 'cloneGroupAction'
  | 'cloneRuleAction';

export const isDisabledPath = (f: any, action: DisabledActions): boolean => {
  return !!f.context?.disabledPaths.find(
    (disabledPath: { path: number[]; disable: DisabledActions[] }) =>
      JSON.stringify(disabledPath.path) === JSON.stringify(f.path) &&
      (disabledPath.disable.includes(action) || disabledPath.disable.includes('All'))
  );
};
