import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { titleCase } from 'src/utils/string.ts';
import {
  getCredits,
  getLimits,
  updateCredits,
  updateLimits
} from 'src/pages/staff/clientConfigs/tabs/creditsAndLimits/apis.ts';
import toast from 'react-hot-toast';
import {
  Action,
  CreditType,
  LimitBalance,
  LimitType
} from 'src/pages/staff/clientConfigs/tabs/creditsAndLimits/types.ts';
import isNumber from 'lodash/isNumber';

type CreditsAndLimitsTabProps = {
  clientName: string;
  isNew: boolean;
};

function CreditRow({
  creditType,
  balance,
  clientName
}: {
  creditType: CreditType;
  balance: number | null;
  clientName: string;
}) {
  const [action, setAction] = useState<Action>(Action.SET);
  const [currentBalance, setCurrentBalance] = useState<number>(balance || 0);
  const [newBalance, setNewBalance] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onUpdate = useCallback(async () => {
    return toast.promise(
      (async () => {
        setIsLoading(true);
        try {
          const result = await updateCredits(clientName, { amount: newBalance, action: action, creditType });
          setCurrentBalance(result.creditBalance[creditType]);
        } finally {
          setIsLoading(false);
        }
      })(),
      {
        loading: 'Updating balance',
        error: 'Error!',
        success: 'Status Updated'
      }
    );
  }, [newBalance, action, creditType, clientName]);

  return (
    <tr>
      <td>{titleCase(creditType)}</td>
      <td>{currentBalance}</td>
      <td></td>
      <td className={'d-flex'}>
        <Form.Select style={{ width: '80px' }} size="sm" onChange={ev => setAction(ev.target.value as any)}>
          {Object.entries(Action).map(([value, label]) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </Form.Select>
        <Form.Control
          type="number"
          style={{ width: 120 }}
          value={isNumber(newBalance) ? newBalance : ''}
          size="sm"
          onChange={e => setNewBalance(e.target.value ? parseInt(e.target.value) : null)}
          disabled={false}
        />

        <Button size="sm" onClick={onUpdate} disabled={newBalance === null || isLoading}>
          {action}
        </Button>
      </td>
    </tr>
  );
}

function LimitRow({
  limitType,
  balance,
  usage,
  clientName
}: {
  limitType: LimitType;
  balance: number | null;
  usage: number | null;
  clientName: string;
}) {
  const [currentLimit, setCurrentLimit] = useState<number | null>(balance);
  const [newLimit, setNewLimit] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onUpdate = useCallback(async () => {
    return toast.promise(
      (async () => {
        setIsLoading(true);
        try {
          const result = await updateLimits(clientName, { amount: newLimit, limitType });
          setCurrentLimit(isNumber(result?.limits[limitType]) ? Number(result?.limits[limitType]) : null);
        } finally {
          setIsLoading(false);
        }
      })(),
      {
        loading: 'Updating Limit',
        error: 'Error!',
        success: 'Limit Updated'
      }
    );
  }, [newLimit, limitType, clientName]);

  return (
    <tr>
      <td>{titleCase(limitType)}</td>
      <td>{currentLimit}</td>
      <td>{usage}</td>
      <td className={'d-flex'}>
        <Form.Control
          type="number"
          style={{ width: 120 }}
          value={isNumber(newLimit) ? newLimit : ''}
          size="sm"
          onChange={e => setNewLimit(e.target.value ? parseInt(e.target.value) : null)}
          disabled={false}
        />

        <Button size="sm" onClick={onUpdate} disabled={isLoading}>
          Set
        </Button>
      </td>
    </tr>
  );
}

export function CreditsAndLimitsTab({ clientName, isNew }: CreditsAndLimitsTabProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [credits, setCredits] = useState<{ [key: string]: number }>({});
  const [limits, setLimits] = useState<LimitBalance>();

  useEffect(() => {
    !isNew &&
      getCredits(clientName)
        .then(credits => setCredits(credits.creditBalance))
        .then(() => getLimits(clientName))
        .then(limits => setLimits(limits))
        .finally(() => setIsLoading(false));
  }, [isNew, clientName]);

  if (isNew) {
    return (
      <Card>
        <Card.Body>
          <div className="text-muted mt-2">Section available for existing clients. Save the config to continue</div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Body>
        <div className="d-grid gap-2">
          <div className={'form-label'}>Credits</div>
          {(isLoading && (
            <div className="table-loading-overlay">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )) || (
            <table style={{ maxWidth: '600px' }}>
              <thead>
                <tr>
                  <th className={'w-25'}>Credit Type</th>
                  <th>Balance</th>
                  <th></th>
                  <th className={'w-50'}>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(CreditType).map(creditType => (
                  <CreditRow
                    key={creditType}
                    creditType={creditType}
                    balance={credits[creditType]}
                    clientName={clientName}
                  />
                ))}
              </tbody>
            </table>
          )}
          <hr />
          <div className={'form-label'}>Limits</div>
          {!isLoading && (
            <table style={{ maxWidth: '600px' }}>
              <thead>
                <tr>
                  <th className={'w-25'}>Limit Type</th>
                  <th>Limit</th>
                  <th>Usage</th>
                  <th className={'w-50'}>Action</th>
                </tr>
              </thead>
              <tbody>
                <LimitRow
                  limitType={'companies'}
                  balance={limits?.limits?.['companies'] as number}
                  usage={limits?.usage?.['companies'] as number}
                  clientName={clientName}
                />
                <LimitRow
                  limitType={'contacts'}
                  balance={limits?.limits?.['contacts'] as number}
                  usage={limits?.usage?.['contacts'] as number}
                  clientName={clientName}
                />
              </tbody>
            </table>
          )}
        </div>
        <div className="text-muted mt-2">Setting a limit to an empty value means no limits at all.</div>
      </Card.Body>
    </Card>
  );
}
