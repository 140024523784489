import {
  ContactCriteriaBreakdown,
  ContactCriteriaBreakdownRequest,
  ContactCriteriaResponse,
  ContactCriteriaSampleRequest,
  ContactCriteriaTotalsRequest,
  ContactCriteriaTotalsStats
} from 'src/pages/staff/clientConfigs/contacts/types.ts';
import API from 'src/utils/API.ts';

export async function getContactsTotals(request: ContactCriteriaTotalsRequest): Promise<{ executionId: string }> {
  return await API.post<{ executionId: string }>(`/admin/contacts-preview/totals`, {}, request);
}

export async function getContactsTotalResults(executionId: string) {
  return await API.get<{
    status: 'NOT_RUN_YET' | 'SUCCEEDED' | 'FAILED' | 'IN_PROGRESS';
    output: ContactCriteriaTotalsStats;
  }>(`/admin/contacts-preview/totals/${executionId}`, {});
}

export async function getContactsSample(request: ContactCriteriaSampleRequest): Promise<{ executionId: string }> {
  return await API.post<{ executionId: string }>(`/admin/contacts-preview/sample`, {}, request);
}

export async function getContactsSampleResults(executionId: string) {
  return await API.get<{
    status: 'NOT_RUN_YET' | 'SUCCEEDED' | 'FAILED' | 'IN_PROGRESS';
    output: ContactCriteriaResponse;
  }>(`/admin/contacts-preview/sample/${executionId}`, { executionId });
}

export async function getContactsBreakdown(request: ContactCriteriaBreakdownRequest): Promise<{ executionId: string }> {
  return await API.post<{ executionId: string }>(`/admin/contacts-preview/breakdown`, {}, request);
}

export async function getContactsBreakdownResults(executionId: string) {
  return await API.get<{
    status: 'NOT_RUN_YET' | 'SUCCEEDED' | 'FAILED' | 'IN_PROGRESS';
    output: ContactCriteriaBreakdown;
  }>(`/admin/contacts-preview/breakdown/${executionId}`, { executionId });
}
