import { ActionWithRulesProps } from 'react-querybuilder';
import { Button } from 'react-bootstrap';
import { FiBarChart2, FiCopy } from 'react-icons/fi';
import './ornament.scss';

export function RuleOrnament(
  f: ActionWithRulesProps,
  disabled: boolean | undefined,
  excludedValue: (path: string) => string,
  onExcludedClick: (path: string) => void,
  includedValue: (path: string) => string,
  onIncludedClick: (path: string) => void,
  onValuesClick?: (path: string) => void
) {
  return (
    <div className={'ruleOrnament d-flex text-center align-items-center'}>
      <div>
        <Button size="sm" className={'mx-1'} variant="danger" onClick={() => onExcludedClick(f.path.join('.'))}>
          {excludedValue(f.path.join('.')) || '-'}
        </Button>
        <Button size="sm" className={'mx-1'} variant="success" onClick={() => onIncludedClick(f.path.join('.'))}>
          {includedValue(f.path.join('.')) || '-'}
        </Button>
        {onValuesClick && (
          <Button size="sm" className={'mx-1'} variant="info" onClick={() => onValuesClick(f.path.join('.'))}>
            <FiBarChart2 />
          </Button>
        )}
      </div>
      <div className={'d-flex justify-content-center'}>
        <Button size="sm" variant="white" onClick={f.handleOnClick} disabled={disabled}>
          <FiCopy />
        </Button>
      </div>
    </div>
  );
}
