import { Button, Card, Form, Row } from 'react-bootstrap';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronRight, FiDownload, FiList, FiSave, FiSend } from 'react-icons/fi';
import WideLayout from 'src/layouts/Wide';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { ClientConfig, CompanyCriteriaFilterOperator } from 'src/apis/clients/types.ts';
import { getClientConfig, upsertClientConfig } from 'src/apis/clients/apis.ts';
import { generateSql, getResults, triggerEstimation } from 'src/pages/staff/marketEstimations/apis.ts';
import { MarketEstimationsTable } from 'src/pages/staff/marketEstimations/components/MarketEstimationsTable.tsx';
import LoadingButton from 'src/components/LoadingButton';
import toast from 'react-hot-toast';
import PageLoader from 'src/components/PageLoader.tsx';
import { FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';
import { getFieldMap } from 'src/components/Filters/CCMFilters/services/service.ts';
import { SourcingComponent } from 'src/pages/staff/clientConfigs/sourcing/SourcingComponent.tsx';
import { validate } from 'src/pages/staff/clientConfigs/validation/validate.ts';
import { ClientConfigErrors } from 'src/pages/staff/clientConfigs/validation/types.ts';
import { ErrorCard } from 'src/pages/staff/clientConfigs/validation/ErrorCard.tsx';
import ClientConfigModal from 'src/pages/staff/clientConfigs/components/ClientConfigModal.tsx';
import { useAdminContext } from 'src/adminContext/hooks.ts';
import { decodeState, encodeState } from 'src/utils/urls';
import { fetchExportSingedDownloadUrl, triggerMarketEstimationExport } from './exports/apis';
import { showModal } from 'src/utils/modals';
import { ExportModal } from './exports/ExportModal';
import { useAuth } from 'src/auth';
import { ExportsList } from './exports/ExportsList';
import { SavedReportsList } from './savedReports/SavedReportsList';
import { saveReport } from './savedReports/apis';

const Collapsable = ({ title, icon, children }: { title: string; icon: any; children: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div onClick={() => setIsOpen(!isOpen)}>
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          {isOpen ? <FiChevronDown /> : <FiChevronRight />} {icon}
          <div> {title}</div>
        </h3>
      </div>
      {isOpen && <div style={{ paddingLeft: '2rem' }}>{children}</div>}
    </div>
  );
};

const emptyConfig = (id: string): ClientConfig => ({
  clientName: id,
  contactsEnabled: false,
  syncWithoutContacts: false,
  syncWithoutEmails: false,
  crmDataPullEnabled: false,
  autoSyncEnabled: false,
  sourcingCriteria: {
    rules: [
      {
        field: 'firmographics/employee_count',
        value: 3,
        operator: CompanyCriteriaFilterOperator.GTE
      }
    ]
  },
  fieldDefinitions: [
    {
      externalName: 'domain',
      internalName: 'firmographics/primary_domain'
    },
    {
      externalName: 'name',
      internalName: 'firmographics/company_name'
    }
  ],
  enrichmentDestinations: [],
  otherEnrichmentStages: [],
  contactCriteria: [],
  dataBlockConfigs: [],
  excludeCompanyIds: [],
  customDataFields: [],
  gfAppFeatureFlags: {},
  lifecycleStage: 'OTHER',
  scheduledForDeletionAt: undefined
});

function MarketEstimationsPage({ title }: any) {
  // the state is used to render the right components, Ref is used so components do not re-render to often
  // state is required only on the initial load. Ref for managing consecutive changes
  const [clientConfig, setClientConfig] = useState<ClientConfig>(emptyConfig(''));
  const clientConfigRef = useRef<Partial<ClientConfig>>(emptyConfig(''));
  const { schema, dataBlocksDefinitions } = useAdminContext();
  usePageTitle(title);
  const [isLoading, setIsLoading] = useState(true);
  const [hasInitialLoaded, setHasInitialLoaded] = useState(false);
  const [executionId, setExecutionId] = useState<string | null>(null);
  const [result, setResult] = useState<any>();
  const excludeCurrentCompaniesRef = useRef<HTMLInputElement>(null);
  const onlySimpleColumnsRef = useRef<HTMLInputElement>(null);
  const [fieldMapping, setFieldMapping] = useState<FieldMapping[]>();
  const [errors, setErrors] = useState<ClientConfigErrors | null>(null);
  const [errorsOpen, setErrorsOpen] = useState(false);
  const [createClientModalOpen, setCreateClientModalOpen] = useState(false);

  const { user } = useAuth();

  const clientNameParam = window.location.hash.startsWith('#client=')
    ? window.location.hash.replace('#client=', '')
    : null;

  const stateParam = window.location.hash.startsWith('#state=') ? window.location.hash.replace('#state=', '') : null;

  const handleChange = useCallback((update: any, path: string) => {
    clientConfigRef.current = { ...clientConfigRef.current, [path]: update };
  }, []);

  const handleChangeAndCommit = useCallback((update: any, path: string) => {
    clientConfigRef.current = { ...clientConfigRef.current, [path]: update };
    setClientConfig(prevState => ({ ...prevState, [path]: update }));
  }, []);

  const handleCommitChanges = useCallback(() => {
    setClientConfig(prevState => ({ ...prevState, ...clientConfigRef.current }));
  }, []);

  useEffect(() => {
    if (clientNameParam) {
      getClientConfig(clientNameParam)
        .then(cc => {
          setClientConfig(clientNameParam ? cc.config : emptyConfig(''));
          clientConfigRef.current = clientNameParam ? cc.config : emptyConfig('');
          setIsLoading(false);
          setHasInitialLoaded(true);
        })
        .catch(() => {
          setClientConfig(emptyConfig(''));
          setIsLoading(false);
          setHasInitialLoaded(true);
        });
    } else if (stateParam) {
      const decodedState = decodeState(stateParam);
      console.log('Loading state', decodeState);
      setClientConfig(decodedState);
      clientConfigRef.current = decodedState;
      setIsLoading(false);
      setHasInitialLoaded(true);
    } else {
      setClientConfig(emptyConfig(''));
      setIsLoading(false);
      setHasInitialLoaded(true);
    }
  }, [clientNameParam, stateParam]);

  useEffect(() => {
    clientConfig.dataBlockConfigs && setFieldMapping(getFieldMap(clientConfig.dataBlockConfigs, dataBlocksDefinitions));
  }, [clientConfig.dataBlockConfigs, dataBlocksDefinitions]);

  const validateBeforeRun = useCallback(() => {
    const toastId = toast.loading('Checking config...');
    handleCommitChanges();
    const fieldMapping = getFieldMap(clientConfig.dataBlockConfigs, dataBlocksDefinitions);
    const validationsFailed = validate(clientConfigRef.current, fieldMapping);
    if (validationsFailed.validationFailed) {
      setErrors({ validationsFailed });
      toast.dismiss(toastId);
      setErrorsOpen(true);
      toast.error(`The config is incorrect, please fix the changes and try again`);
      return false;
    }
    toast.dismiss(toastId);
    setErrors(null);
    setErrorsOpen(false);
    return true;
  }, [handleCommitChanges, clientConfig.dataBlockConfigs, dataBlocksDefinitions]);

  const triggerPreview = useCallback(async () => {
    const isConfigCorrect = validateBeforeRun();
    if (!isConfigCorrect) return;

    setIsLoading(true);
    setResult(undefined);
    const payload = { ...clientConfigRef.current } as any;
    if (clientConfig.clientName && excludeCurrentCompaniesRef.current?.checked) {
      payload.excludeClientDestinationsFor = clientConfig.clientName;
    }
    if (onlySimpleColumnsRef.current?.checked) {
      const defaultConfig = emptyConfig('');
      payload.fieldDefinitions = defaultConfig.fieldDefinitions;
    }
    const result = await triggerEstimation(payload);
    if (result.executionId) {
      setExecutionId(result.executionId);
    }
  }, [clientConfig, validateBeforeRun]);

  useEffect(() => {
    let interval: any;

    const pollResults = async () => {
      if (executionId) {
        const result = await getResults(executionId);
        if (['SUCCEEDED', 'FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status)) {
          clearInterval(interval);
          setExecutionId(null);
          setIsLoading(false);
          setResult(result.output);
          // Fails if the step function failed, or if it SUCCEEDED but output contains error key
          const didFail = ['FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status) || result?.output?.error;
          const errorMessage = result?.output?.error ?? result.status;
          if (didFail) alert(`Sorry, preview errored: ${errorMessage}`);
        }
      }
      interval = setTimeout(pollResults, 1000); // Re-Poll in 1 seconds
    };

    if (executionId) {
      interval = setTimeout(pollResults, 1000); // Poll in 1 seconds
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [executionId]);

  const triggerDownload = useCallback(async () => {
    const exportConfig = await showModal<{ exportName: string; limit: number }>(ExportModal);
    if (exportConfig) {
      await toast.promise(
        (async () => {
          const { exportId } = await triggerMarketEstimationExport(
            clientConfigRef.current,
            exportConfig.exportName,
            exportConfig.limit,
            user!.email
          );
          const signedUrl = await fetchExportSingedDownloadUrl(exportId);
          window.open(signedUrl);
        })(),
        {
          loading: 'Exporting data. You can close this page and come back to it later.',
          success: 'Data exported',
          error: 'Sorry there was an error.'
        }
      );
    }
  }, [user]);

  const generateAndCopySql = useCallback(() => {
    return toast.promise(
      generateSql(clientConfigRef.current).then(result => {
        navigator.clipboard.writeText(result?.formattedUnsafeSql?.toString());
      }),
      {
        loading: 'Generating SQL',
        success: 'Copied SQL to clipboard',
        error: 'Sorry there was an error. Please find your closest engineer and shout at them.'
      }
    );
  }, []);

  const createClientConfig = useCallback(
    async (name: string) => {
      const toastId = toast.loading('Saving...');
      clientConfigRef.current = { ...clientConfigRef.current, clientName: name };
      handleCommitChanges();
      const result = await upsertClientConfig(name, clientConfigRef.current);
      toast.dismiss(toastId);
      if (result.isValid) {
        toast.success(`Client config created with name ${name}`);
        setCreateClientModalOpen(false);
        //   open in new tab
        window.open(`/admin/clients/${name}`);
      } else {
        toast.error('Client config creation failed');
      }
    },
    [handleCommitChanges]
  );

  const doSaveReport = useCallback(async () => {
    const name = prompt('Report name');
    if (name) {
      await toast.promise(
        saveReport({
          id: name,
          config: clientConfigRef.current,
          requestedByUserEmail: user?.email,
          count: result?.companyCount
        }),
        {
          loading: 'Saving report',
          success: 'Report saved',
          error: 'Error saving report'
        }
      );
    }
  }, [result, user]);

  if (!hasInitialLoaded || !fieldMapping) {
    return <PageLoader fullHeight />;
  }

  return (
    <WideLayout
      title={'Market estimations'}
      titleControls={
        <>
          <Button
            size="sm"
            className={'mx-2'}
            variant="white"
            onClick={() => {
              const json = prompt('JSON (can paste multi line)');
              if (json) {
                try {
                  // We have to toggle hasInitialLoaded in order to update filter state
                  setHasInitialLoaded(false);
                  setTimeout(() => {
                    const decodedState = JSON.parse(json);
                    setClientConfig(decodedState);
                    clientConfigRef.current = decodedState;
                    setHasInitialLoaded(true);
                  }, 50);
                } catch (err) {
                  alert(err);
                }
              }
            }}
          >
            Paste from JSON
          </Button>
          <Button
            size="sm"
            className={'mx-2'}
            variant="white"
            onClick={() => {
              const url = `${window.location.protocol}//${window.location.host}/admin/market-estimations#state=${encodeState(clientConfigRef.current)}`;
              navigator.clipboard.writeText(url);
              toast.success('URL copied to clipboard');
            }}
          >
            Copy permalink
          </Button>
          <Button
            size="sm"
            className={'mx-2'}
            variant="white"
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(clientConfigRef.current, null, 2));
              toast.success('JSON copied to clipboard');
            }}
          >
            Copy as JSON
          </Button>
          <Button size="sm" className={'mx-2'} variant="white" onClick={() => doSaveReport()}>
            Save Report
          </Button>
        </>
      }
    >
      <Card id="market-estimations">
        <Card.Body>
          <ErrorCard errorsOpen={errorsOpen} errors={errors} closeErrorsCard={() => setErrorsOpen(false)} />
          <SourcingComponent
            schema={schema}
            clientConfig={clientConfig}
            isEditing={true}
            handleChange={handleChange}
            handleCommitChanges={handleCommitChanges}
            handleChangeAndCommit={handleChangeAndCommit}
            errors={errors}
          />
          <Row className={'m-1'}>
            <Collapsable title={'Options'} icon={<FiList className={'mx-1'} />}>
              {clientConfig?.clientName && clientConfig.clientName.length > 0 && (
                <>
                  <Form.Group className="mb-0 d-flex mb-0" controlId="exampleForm.ControlInput1">
                    <Form.Check disabled={!clientConfig.clientName} ref={excludeCurrentCompaniesRef} className="me-2" />
                    <Form.Label>
                      Exclude companies currently in client destinations for `{clientConfig.clientName}`
                    </Form.Label>
                  </Form.Group>
                  <Form.Text className="mb-4 mt-0">
                    Check if you want to see how many companies would be added
                  </Form.Text>
                </>
              )}
              <Form.Group className="mb-0 d-flex mb-0" controlId="exampleForm.ControlInput1">
                <Form.Check ref={onlySimpleColumnsRef} className="me-2" />
                <Form.Label>Use only simple columns in preview (may be faster)</Form.Label>
              </Form.Group>
              <Form.Text className="mb-4 mt-0">Show only domain and name in preview table, may be faster.</Form.Text>
            </Collapsable>
          </Row>
          <Row className={'m-1'}>
            <div className="container-fluid d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <h2 className="m-0">
                  {result ? (
                    <>Found {result?.companyCount ?? 'ERROR'} companies</>
                  ) : (
                    <>{isLoading ? <>Loading...</> : <>Click preview to run</>}</>
                  )}
                </h2>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  size={'sm'}
                  disabled={isLoading}
                  className={'mx-2'}
                  onClick={() => {
                    triggerPreview();
                  }}
                >
                  Preview
                  <FiSend className={'ms-1'} />
                </Button>
                <LoadingButton size={'sm'} className={'mx-2'} onClick={triggerDownload}>
                  Download
                  <FiDownload className={'ms-1'} />
                </LoadingButton>
                <LoadingButton className={'mx-2'} size={'sm'} onClick={generateAndCopySql}>
                  Copy SQL
                </LoadingButton>
                <Button
                  size={'sm'}
                  className={'mx-2'}
                  disabled={isLoading}
                  onClick={async () => {
                    const isConfigCorrect = await validateBeforeRun();
                    if (!isConfigCorrect) return;
                    setCreateClientModalOpen(true);
                  }}
                >
                  Create client
                  <FiSave className={'ms-1'} />
                </Button>
              </div>
            </div>
          </Row>
          <Row className={'m-2'}>
            <MarketEstimationsTable
              fieldDefinitions={clientConfig.fieldDefinitions}
              fieldMapping={fieldMapping}
              result={result}
              isLoading={isLoading}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Saved reports</Card.Header>
        <SavedReportsList
          onLoadReport={(savedReport: any) => {
            setHasInitialLoaded(false);
            setTimeout(() => {
              const finalConfig = { ...emptyConfig, ...savedReport.config };
              setClientConfig(finalConfig);
              clientConfigRef.current = finalConfig;
              setHasInitialLoaded(true);
            }, 50);
          }}
        />
      </Card>
      <Card>
        <Card.Header>Exports</Card.Header>
        <ExportsList />
      </Card>
      <ClientConfigModal
        show={createClientModalOpen}
        onHide={() => setCreateClientModalOpen(false)}
        onSubmit={createClientConfig}
      />
    </WideLayout>
  );
}

export default MarketEstimationsPage;
