import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import { useCallback, useEffect, useMemo } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

function parseNumber(str: string) {
  if (str === '') return null;
  const ret = parseFloat(str);
  if (isNaN(ret)) return null;
  return ret;
}

export function RelativeDateInput({ handleOnChange, value, disabled }: any) {
  const amount = value?.amount ?? '';
  const unit = value?.unit ?? 'DAY';

  const updateValue = useCallback(
    (update: any) => {
      const newValue = { ...(value || { unit: 'DAY' }), ...update };
      handleOnChange(newValue);
    },
    [handleOnChange, value]
  );

  return (
    <div>
      <Form.Control
        type="number"
        className="d-inline-block"
        style={{ width: 80 }}
        value={amount}
        size="sm"
        aria-label="Value"
        placeholder="Number"
        onChange={e => updateValue({ amount: parseInt(e.target.value) })}
        disabled={disabled}
      />{' '}
      <Form.Select
        className="w-auto form-select-sm d-inline-block"
        value={unit}
        disabled={disabled}
        onChange={e => updateValue({ unit: e.target.value })}
      >
        <option value="DAY">Days</option>
        <option value="WEEK">Weeks</option>
        <option value="MONTH">Months</option>
        <option value="YEAR">Years</option>
      </Form.Select>
      {' ago'}
    </div>
  );
}

export function BooleanInput({ handleOnChange, value, disabled }: any) {
  // a hack to set default value when a predicate is added
  useEffect(() => {
    if (!isBoolean(value)) handleOnChange(true);
  }, [value, handleOnChange]);
  return (
    <div>
      <Form.Select
        className="w-auto form-select-sm d-inline-block"
        value={value}
        disabled={disabled}
        onChange={e => handleOnChange(e.target.value === 'true')}
      >
        <option key={'true'} value={'true'}>
          True
        </option>
        <option key={'false'} value={'false'}>
          False
        </option>
      </Form.Select>
    </div>
  );
}

export function DateInput({ handleOnChange, value, disabled }: any) {
  const style: any = {};
  if (disabled) {
    style.backgroundColor = 'hsl(0, 0%, 95%)';
  }
  return (
    <Form.Control
      style={style}
      type="date"
      className="w-auto"
      value={value}
      size="sm"
      aria-label="Value"
      placeholder="Value (date)"
      onChange={e => handleOnChange(e.target.value)}
      disabled={disabled}
    />
  );
}

export function TextInput({ handleOnChange, value, disabled }: any) {
  const style: any = { width: 250 };
  if (disabled) {
    style.backgroundColor = 'hsl(0, 0%, 95%)';
  }
  return (
    <Form.Control
      type="text"
      style={style}
      size="sm"
      value={value}
      aria-label="Value"
      placeholder="Value (Text)"
      onChange={e => handleOnChange(e.target.value || '')}
      disabled={disabled}
    />
  );
}

export function NumberInput({ handleOnChange, value, disabled }: any) {
  const style: any = { width: 120 };
  if (disabled) {
    style.backgroundColor = 'hsl(0, 0%, 95%)';
  }
  return (
    <Form.Control
      type="number"
      style={style}
      value={value}
      size="sm"
      aria-label="Value"
      placeholder="Number"
      onChange={e => handleOnChange(parseNumber(e.target.value))}
      disabled={disabled}
    />
  );
}

export function CurrencyInput({ handleOnChange, value, disabled }: any) {
  const style: any = { width: 120 };
  if (disabled) {
    style.backgroundColor = 'hsl(0, 0%, 95%)';
  }
  const disabledStyle: any = {};
  if (disabled) {
    disabledStyle.backgroundColor = 'hsl(0, 0%, 95%)';
  }
  return (
    <InputGroup size="sm" style={style}>
      <InputGroup.Text style={disabledStyle}>$</InputGroup.Text>
      <Form.Control
        style={disabledStyle}
        type="number"
        aria-label="Amount (USD)"
        placeholder="Number"
        value={value}
        onChange={e => handleOnChange(parseNumber(e.target.value))}
        disabled={disabled}
      />
    </InputGroup>
  );
}

export function PercentageInput({ handleOnChange, value, disabled }: any) {
  const style: any = { width: 120 };
  const disabledStyle: any = {};
  if (disabled) {
    disabledStyle.backgroundColor = 'hsl(0, 0%, 95%)';
  }

  return (
    <InputGroup size="sm" style={style}>
      <InputGroup.Text style={disabledStyle}>%</InputGroup.Text>

      <Form.Control
        style={disabledStyle}
        type="number"
        value={value ? (value * 100).toFixed(2) : value}
        aria-label="Percentage"
        onChange={e => {
          const value = parseNumber(e.target.value);
          handleOnChange(value !== null ? value / 100 : null);
        }}
        placeholder="Number"
        disabled={disabled}
      />
    </InputGroup>
  );
}

export function InputPair({ handleOnChange, value, inputType, disabled }: any) {
  const InputType = inputType;

  const actualValue = useMemo(() => (isArray(value) ? value : [undefined, undefined]), [value]);

  const setArrayValue = useCallback(
    (v: any, index: number) => {
      const newValue = [...actualValue];
      newValue[index] = v;
      handleOnChange(newValue);
    },
    [handleOnChange, actualValue]
  );

  return (
    <>
      <div className="d-inline-block me-2">
        <InputType value={actualValue?.[0]} handleOnChange={(v: any) => setArrayValue(v, 0)} disabled={disabled} />
      </div>
      and
      <div className="d-inline-block ms-2">
        <InputType value={actualValue?.[1]} handleOnChange={(v: any) => setArrayValue(v, 1)} disabled={disabled} />
      </div>
    </>
  );
}
