import moment from 'moment';
import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import { useState, useEffect, useCallback } from 'react';
import { Badge, Button, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import SimplePaginator from 'src/components/Table/SimplePaginator';
import TableGrid from 'src/components/Table/TableGrid';
import { titleCase } from 'src/utils/string';
import { cancelIntegrationJob, loadIntegrationJobs, IntegrationJob, IntegrationStatus } from 'src/apis/integrations';
import toast from 'react-hot-toast';
import { usePeriodic } from 'src/utils/usePeriodic';

const PAGE_SIZE = 10;

function StatusBadge({ status, onCancel }: { status: IntegrationStatus; onCancel: () => void }) {
  const BGS = {
    COMPLETE: 'success',
    FAILED: 'danger',
    CANCELLED: 'danger',
    IN_PROGRESS: 'info'
  };
  const LABELS = {
    COMPLETE: 'Complete',
    FAILED: 'Failed',
    IN_PROGRESS: 'In Progress',
    CANCELLED: 'Cancelled'
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column justify-content-center">
        <Badge style={{ fontSize: 13 }} bg={BGS[status]}>
          {LABELS[status]}
        </Badge>
      </div>
      {status == 'IN_PROGRESS' && (
        <Button size="sm" variant="white" className="ms-2" onClick={onCancel}>
          Cancel
        </Button>
      )}
    </div>
  );
}

function JobProgress({ value, status }: { value: any; status: IntegrationStatus }) {
  const BGS = {
    COMPLETE: 'success',
    FAILED: 'danger',
    CANCELLED: 'danger',
    IN_PROGRESS: 'secondary'
  };
  const percent = Math.round(value.progress * 100);

  const isActive = status === 'IN_PROGRESS';

  return (
    <OverlayTrigger
      placement="top"
      overlay={props => (
        <Tooltip {...props} className={'jobs-progress-tooltip'}>
          <ul className={'jobs-progress-tooltip-ul'}>
            <li>
              Total Companies to process <b>{value?.numAccountsToProcess || 0}</b>
            </li>
            <li>
              Companies created <b>{value?.numAccountsCreated || 0}</b>
            </li>
            <li>
              Companies updated <b>{value?.numAccountsUpdated || 0}</b>
            </li>
            <li>
              Companies failed <b>{value?.numAccountsFailed || 0}</b>
            </li>
            <li>
              Companies skipped <b>{value?.numAccountsSkipped || 0}</b>
            </li>
            <li>
              Contacts created <b>{value?.numContactsCreated || 0}</b>
            </li>
            <li>
              Contacts updated <b>{value?.numContactsUpdated || 0}</b>
            </li>
            <li>
              Contacts failed <b>{value?.numContactsFailed || 0}</b>
            </li>
            <li>
              Contacts skipped <b>{value?.numContactsSkipped || 0}</b>
            </li>
          </ul>
        </Tooltip>
      )}
    >
      <ProgressBar animated={isActive} now={percent} variant={BGS[status]} label={`${percent}%`} />
    </OverlayTrigger>
  );
}

export default function JobsTable({ connectorType }: any) {
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(0);
  const [jobs, setJobs] = useState<IntegrationJob[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // todo run reload on periodic basic
  const doBackgroundReload = useCallback(() => {
    loadIntegrationJobs(connectorType, PAGE_SIZE, page * PAGE_SIZE).then(data => {
      setJobs(data.jobs as IntegrationJob[]);
      setNumPages(Math.ceil(data.jobsCnt / PAGE_SIZE));
      setIsLoading(false);
    });
  }, [connectorType, page]);

  const handleCancelJob = useCallback(
    (integrationJobId: string) => {
      toast
        .promise(cancelIntegrationJob(integrationJobId), {
          loading: 'Cancelling sync job...',
          success: 'Sync job was cancelled',
          error: 'Error cancelling job'
        })
        .then(() => {
          // Reload jobs so that it updates status
          doBackgroundReload();
        });
    },
    [doBackgroundReload]
  );

  useEffect(() => {
    setIsLoading(true);
    doBackgroundReload();
  }, [doBackgroundReload]);

  // reload every 5 sec
  usePeriodic(async () => {
    await doBackgroundReload();
  }, 5000);

  const table = useReactTable<IntegrationJob>({
    columns: [
      {
        header: 'Integration',
        id: 'integrationType',
        accessorFn: row => titleCase(row.integrationType),
        enableSorting: false
      },
      {
        header: 'Mode',
        id: 'mode',
        accessorFn: row => (row.autosync ? 'Auto sync' : 'Manual'),
        enableSorting: false
      },
      {
        header: 'Started At',
        accessorKey: 'started',
        // @todo we need to work out about timezones. This is UTC.
        cell: props => moment(props.getValue()).format('YYYY-MM-DD HH:mm:ss'),
        enableSorting: false
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        cell: props => (
          <StatusBadge
            status={props.getValue()}
            onCancel={() => {
              console.log(JSON.stringify(props.row.original));
              handleCancelJob(props.row.original.id);
            }}
          />
        )
      },
      {
        header: 'Progress',
        accessorKey: 'progress',
        enableSorting: false,
        cell: props => <JobProgress value={props.getValue()} status={props.row.original.status} />
      }
    ],
    data: jobs,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  return (
    <>
      <TableGrid
        table={table}
        isLoading={isLoading}
        emptyMessage="No jobs have been started"
        numLoadingRows={PAGE_SIZE}
        className="jobs-table table-centered table-compact table-nowrap"
      />
      <div className="d-flex justify-content-end px-5 pb-3">
        <SimplePaginator pageIndex={page} totalPages={numPages} setPage={setPage} />
      </div>
    </>
  );
}
