import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import 'rc-slider/assets/index.css';
import { ScoringRule } from 'src/apis/scoring/types';
import { BasicField, CompanySchema, useClient } from 'src/auth';
import { CustomValueEditorComponent } from 'src/components/Filters/ClientCompaniesFilters/CustomValueEditor';
import { getOperatorsForField } from 'src/components/Filters/ClientCompaniesFilters/operators';
import ScoringRuleSlider from './ScoringRuleSlider';
import { applyOptionGrouping, getGroupNameForFieldOption } from 'src/utils/selectOptions';
import { useMemo } from 'react';
import { MdOutlineBarChart } from 'react-icons/md';
import { DEFAULT_THEME } from 'src/utils/react-select';

function groupSchemaFieldOptions(companiesSchema: CompanySchema, fieldName?: string | null) {
  const fieldOptions = companiesSchema
    .filter(f => !f.isHiddenFromUser && !f.fieldSource.startsWith('crmSync/'))
    .map(f => {
      const optgroup = getGroupNameForFieldOption(f);
      return { value: f.fieldName, label: f.displayName, optgroup };
    });

  const selectedOption = fieldOptions.find(o => o.value === fieldName);

  const groupedFieldOptions = applyOptionGrouping(fieldOptions);
  return {
    groupedFieldOptions,
    fieldOptions,
    selectedOption
  };
}

export default function ScoringRuleEditor({
  rule,
  modifyRule,
  removeRule,
  showFieldInfo
}: {
  rule: ScoringRule;
  modifyRule: (u: any) => void;
  removeRule: () => void;
  showFieldInfo: (field: BasicField) => void;
}) {
  const fieldName = rule?.field;
  const { companiesSchema } = useClient();

  const schemaField = companiesSchema.find(f => f.fieldName === fieldName);
  const operatorOptions = fieldName ? getOperatorsForField(companiesSchema, fieldName) : [];
  const operatorSelectOptions = operatorOptions.map(o => ({
    value: o.name,
    label: o.label
  }));

  const { groupedFieldOptions, selectedOption } = useMemo(() => {
    // Cant use other scoring fields in scoring
    const filteredCompaniesSchema = companiesSchema.filter(f => !f.fieldSource.startsWith('scoring/'));
    return groupSchemaFieldOptions(filteredCompaniesSchema, rule.field);
  }, [companiesSchema, rule.field]);

  return (
    <div className="d-flex flex-row justify-content-between align-items-center my-2" style={{ gap: 5 }}>
      <div style={{ width: '15%' }}>
        <Select
          options={groupedFieldOptions}
          onChange={v => {
            modifyRule({ field: v?.value, operator: null, value: null });
          }}
          isSearchable={true}
          value={selectedOption ?? null}
          className="react-select-container"
          classNamePrefix="react-select"
          theme={DEFAULT_THEME}
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              minWidth: 160,
              width: '100%'
            })
          }}
        />
      </div>
      <div className="flex-shrink-1">
        <Button
          size="sm"
          variant="light"
          disabled={!schemaField}
          className="px-2"
          onClick={() => {
            if (schemaField) {
              showFieldInfo(schemaField);
            }
          }}
        >
          <MdOutlineBarChart />
        </Button>
      </div>
      <div style={{ width: '12%' }}>
        {schemaField && (
          <Select
            options={operatorSelectOptions}
            onChange={v => {
              modifyRule({ operator: v?.value, value: null });
            }}
            isSearchable={true}
            value={operatorSelectOptions.find(o => o.value === rule.operator) ?? null}
            className="react-select-container"
            classNamePrefix="react-select"
            theme={DEFAULT_THEME}
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                width: '100%',
                minWidth: 128
              })
            }}
          />
        )}
      </div>
      <div className="flex-grow-1" style={{ width: '25%' }}>
        {schemaField && rule.operator && rule.field && (
          <CustomValueEditorComponent
            fieldName={rule.field}
            operator={rule.operator}
            type={schemaField?.type}
            value={rule.value}
            handleOnChange={value => modifyRule({ value })}
          />
        )}
      </div>
      <div className="flex-grow-1 slider-area" style={{ padding: '8px 0px', width: '23%' }}>
        <ScoringRuleSlider rule={rule} modifyRule={modifyRule} />
      </div>
      <div>
        <Form.Control
          type="number"
          onChange={ev => modifyRule({ score: parseFloat(ev.target.value) })}
          htmlSize={2}
          value={rule.score}
          size="sm"
          style={{ width: 56 }}
        />
      </div>
      <div>
        <Button size="sm" variant="white" onClick={() => removeRule()}>
          ✕
        </Button>
      </div>
    </div>
  );
}
