import Tag from 'src/components/Tag';

const TAG_COLOURS: Record<string, string> = {
  string: 'blue',
  number: 'green',
  picklist: 'cyan',
  multipicklist: 'orange',
  percentage: 'pink',
  currency: 'magenta',
  array: 'skyblue',
  boolean: 'purple'
};

type TypeTagProps = {
  type: string;
};

export default function TypeTag({ type }: TypeTagProps) {
  return <Tag color={TAG_COLOURS[type.toLowerCase()]}>{type}</Tag>;
}
