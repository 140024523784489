import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import JobsTable from './components/JobsTable';
import ErrorsTable from './components/ErrorsTable';
import './connector-logs.scss';
import { Integration, loadIntegrations } from 'src/apis/integrations';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import startCase from 'lodash/startCase';

export default function ConnectorLogsPage({ title }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showIntegrationType, setShowIntegrationType] = useState<string>(() => {
    // Default to query string ?connector=X if provided
    return searchParams.get('connector') ?? '';
  });
  const [integrations, setIntegrations] = useState<Integration[]>([]);

  usePageTitle(title);

  useEffect(() => {
    loadIntegrations().then(setIntegrations);
  }, []);

  return (
    <NarrowLayout
      title="Connector Logs"
      preheader="Settings"
      maxWidthCols={12}
      titleControls={
        <>
          <Form.Select
            value={showIntegrationType}
            onChange={ev => {
              setSearchParams({ connector: ev.target.value });
              setShowIntegrationType(ev.target.value);
            }}
          >
            <option value="">All connectors</option>
            {integrations.map(integration => (
              <option key={integration.id} value={integration.integrationType}>
                {startCase(integration.integrationType.toLowerCase())}
              </option>
            ))}
          </Form.Select>
        </>
      }
    >
      <Card>
        <Card.Header>Sync jobs</Card.Header>
        <Card.Body className="p-0">
          <JobsTable connectorType={showIntegrationType} />
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <span>
            Current record sync errors&nbsp;
            <OverlayTrigger
              placement="top"
              overlay={props => (
                <Tooltip {...props}>
                  A list of reasons records are currently not able to sync to your CRM. This represents only records
                  that are currently stuck: once the error is resolved, entries will be removed from this table.
                </Tooltip>
              )}
            >
              <span>
                <IoIosInformationCircleOutline />
              </span>
            </OverlayTrigger>
          </span>
        </Card.Header>
        <Card.Body className="p-0">
          <ErrorsTable connectorType={showIntegrationType} />
        </Card.Body>
      </Card>
    </NarrowLayout>
  );
}
