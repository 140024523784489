import { useCallback, useState } from 'react';
import { Card } from 'react-bootstrap';
import noop from 'lodash/noop';
import orderBy from 'lodash/orderBy';

import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import { CollectionHelper } from 'src/utils/CollectionHelper';
import {
  deleteScoringConfig,
  loadScoringConfigs,
  updateScoringConfig,
  validateScoringConfigDeletion
} from 'src/apis/scoring/apis';
import { ScoringConfig } from 'src/apis/scoring/types';
import ScoringConfigList from './components/list/ScoringConfigList';
import toast from 'react-hot-toast';
import { useClient } from 'src/auth';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import NavButton from 'src/components/NavButton';
import { usePeriodic } from 'src/utils/usePeriodic';
import { configHasDependantResources } from './utils';
import { showModal } from 'src/utils/modals';
import { BreakingChangesModal } from '../../components/BreakingChangesModal';

const collectionHelper = new CollectionHelper<ScoringConfig>('id');

export default function ScoringConfigListPage({ title }: any) {
  const { clientName, refresh } = useClient();

  const [isLoading, setIsLoading] = useState(true);
  const [scoringConfigs, setScoringConfigs] = useState<ScoringConfig[]>([]);
  const [isDeleteValid, setIsDeleteValid] = useState<boolean>(false);

  usePageTitle(title);

  // Load now and every 5s
  usePeriodic(() => {
    loadScoringConfigs().then(response => {
      setIsLoading(false);
      setScoringConfigs(response.configs);
    });
  }, 5000);

  /**
   * It's important that we validate the deletion of a config before we attempt
   * to delete it as there may be resources that depend upon the config.
   */
  const validateBeforeDelete = async (scoringConfigId: string) => {
    const breakingChanges = await validateScoringConfigDeletion(scoringConfigId);
    const hasBreakingChanges = configHasDependantResources(breakingChanges);

    setIsDeleteValid(!hasBreakingChanges);

    const showBreakingChangesModal = () => {
      const modalProps = { breakingChanges, clientName, resourceName: 'Scoring Field' };
      showModal(BreakingChangesModal, modalProps, noop);
    };

    hasBreakingChanges ? showBreakingChangesModal() : handleDelete(scoringConfigId);
  };

  const handleDelete = useCallback(
    (scoringConfigId: string) => {
      toast
        .promise((() => deleteScoringConfig(scoringConfigId, isDeleteValid))(), {
          loading: 'Deleting...',
          success: 'Scoring field deleted',
          error: 'Error deleting scoring field'
        })
        .then(() => {
          refresh();
          setScoringConfigs(collectionHelper.deleteItem(scoringConfigs, scoringConfigId));
        });
    },
    [scoringConfigs, isDeleteValid, refresh]
  );

  const setDefaultConfig = useCallback(
    (scoringConfigId: string) => {
      toast
        .promise(
          (async () => {
            return updateScoringConfig(scoringConfigId, { isDefault: true });
          })(),
          {
            loading: 'Updating...',
            success: 'Scoring field updated',
            error: 'Error updating scoring field'
          }
        )
        .then(() => {
          refresh();
          const newConfigs = orderBy(
            scoringConfigs.map(sc => {
              if (sc.id === scoringConfigId) {
                return { ...sc, isDefault: true };
              }
              return { ...sc, isDefault: false };
            }),
            'isDefault',
            'desc'
          );
          setScoringConfigs(newConfigs);
        });
    },
    [scoringConfigs, refresh]
  );

  return (
    <NarrowLayout title="Scoring fields" preheader="">
      <Card>
        <Card.Header>
          <h4 className="card-header-title">Scoring fields</h4>
          <NavButton size="sm" to={`/app/${clientName}/scoring/new`}>
            + Add scoring field
          </NavButton>
        </Card.Header>
        <Card.Body>
          <ScoringConfigList
            scoringConfigs={scoringConfigs}
            isLoading={isLoading}
            handleDelete={validateBeforeDelete}
            handleChangeDefault={setDefaultConfig}
          />
        </Card.Body>
      </Card>
    </NarrowLayout>
  );
}
