import { ClientConfig, DataBlockDefinition } from 'src/apis/clients/types.ts';
import { useState } from 'react';
import { Toggle } from 'src/pages/staff/clientConfigs/components/Toggle.tsx';
import JsonEditor from 'src/components/JsonEditor/JsonEditor.tsx';
import { Button, Row } from 'react-bootstrap';
import { DataBlockConfigsTable } from 'src/pages/staff/clientConfigs/sourcing/dynamicDataBlocks/DataBlockConfigsTable.tsx';
import { DataBlockConfigsModal } from 'src/pages/staff/clientConfigs/sourcing/dynamicDataBlocks/DataBlockConfigsModal.tsx';

export function DataBlockConfigs({
  isEditing,
  schema,
  dynamicDataBlocksDefinitions,
  clientConfig,
  handleChange,
  handleCommitChanges,
  handleChangeAndCommit
}: {
  isEditing: boolean;
  schema: any;
  dynamicDataBlocksDefinitions: DataBlockDefinition[];
  clientConfig: ClientConfig;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
}) {
  const [editAsJSON, setEditAsJSON] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openBlock, setOpenBlock] = useState<(DataBlockDefinition & { index: number }) | null>();

  const handleRemoveField = (index: number) => {
    const newDataBlockConfigs = [...clientConfig.dataBlockConfigs];
    newDataBlockConfigs.splice(index, 1);
    handleChangeAndCommit(newDataBlockConfigs, 'dataBlockConfigs');
  };

  const addBlock = (newBlock: any) => {
    const newBlocks = [...clientConfig.dataBlockConfigs];
    if (newBlock.index !== undefined) {
      newBlocks[newBlock.index] = {
        blockName: newBlock.blockName,
        configId: newBlock.configId,
        config: newBlock.config
      };
    } else {
      newBlocks.push({ blockName: newBlock.blockName, configId: newBlock.configId, config: newBlock.config });
    }
    handleChangeAndCommit(newBlocks, 'dataBlockConfigs');
  };

  const handleOpenBlock = (block: DataBlockDefinition & { index: number }) => {
    setOpenBlock(block);
    setModalOpen(true);
  };

  return (
    <div className={'mb-2'}>
      <div className={'d-flex justify-content-between'}>
        <div>
          <Row>
            <h3>Data Block Configs</h3>
          </Row>
        </div>
        <div className="d-flex align-items-baseline" style={{ gap: 10 }}>
          {isEditing && (
            <>
              <Button
                size={'sm'}
                onClick={() => {
                  setOpenBlock(null);
                  setModalOpen(true);
                }}
              >
                Add Block
              </Button>
            </>
          )}
          <Toggle
            flag={editAsJSON}
            label={'As JSON'}
            isEditing={true}
            onChange={() => {
              handleCommitChanges && handleCommitChanges();
              setEditAsJSON(!editAsJSON);
            }}
          />
        </div>
      </div>
      {(editAsJSON && (
        <JsonEditor
          schema={{
            items: {
              $ref: '#/definitions/DynamicDataBlockConfig'
            },
            type: 'array'
          }}
          referenceSchema={schema}
          json={clientConfig.dataBlockConfigs}
          isEditing={isEditing}
          onChange={(update: any) => {
            handleChange(update, 'dataBlockConfigs');
          }}
        />
      )) || (
        <DataBlockConfigsTable
          clientConfig={clientConfig}
          isEditing={isEditing}
          removeBlock={handleRemoveField}
          handleOpenBlock={handleOpenBlock}
        />
      )}
      <DataBlockConfigsModal
        dynamicDataBlocksDefinitions={dynamicDataBlocksDefinitions}
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        dataBlockConfig={openBlock}
        addBlock={addBlock}
        isEditing={isEditing}
      />
    </div>
  );
}
