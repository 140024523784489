import { useCallback, useEffect, useState } from 'react';
import { Card, Nav, Spinner, Tab } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';

import WideLayout from 'src/layouts/Wide';
import { usePageTitle } from 'src/utils/usePageTitle';
import { Segment, SegmentPersona } from './types';
import PageLoader from 'src/components/PageLoader';
import { loadPersonasForSegment, loadSegment } from './apis';
import { formatNumber } from 'src/utils/number';
import { CompaniesTab } from './components/view/CompaniesTab';
import { ContactsTab } from './components/view/ContactsTab';
import { SegmentSyncActions } from './components/sync/components/SegmentSyncActions';
import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { PersonaErrorStatusIcon } from './components/PersonaErrorStatusIcon';

const SEARCH_REFRESH_PERIOD_MS = 5000;

function formatTab(label: string, number: number | null) {
  if (!number) return label;
  return `${label} (${formatNumber(number)})`;
}

const useDefaultTab = (): 'companies' | 'contacts' => {
  const { hash } = useLocation();

  return /contacts/.test(hash) ? 'contacts' : 'companies';
};

export default function SegmentViewPage() {
  const params = useParams();
  const segmentId = params.segmentId as string;

  const defaultTab = useDefaultTab();

  const [isLoading, setIsLoading] = useState(true);
  const [segment, setSegment] = useState<Segment | undefined>(undefined);
  const [personas, setPersonas] = useState<SegmentPersona[]>([]);

  usePageTitle(segment ? `Segment: ${segment.name}` : 'View segment');

  const handleLoadSegment = useCallback(() => {
    const handleLoadSegmentInner = () => {
      loadSegment(segmentId, true).then(result => {
        setSegment(result.item);
        if (result.item.updateStatus !== 'COMPLETE') {
          // Try again in 5s
          setTimeout(handleLoadSegmentInner, SEARCH_REFRESH_PERIOD_MS);
        } else {
          loadPersonasForSegment(segmentId).then(personasResults => {
            const personas = personasResults.items;
            if (personas.find(p => p.searchStatus === 'RUNNING')) {
              // Update in 5 seconds as still loading
              setTimeout(handleLoadSegmentInner, SEARCH_REFRESH_PERIOD_MS);
            }
            setPersonas(personas);
            setIsLoading(false);
          });
        }
      });
    };
    handleLoadSegmentInner();
  }, [segmentId]);

  useEffect(() => {
    handleLoadSegment();
  }, [handleLoadSegment]);

  if (isLoading || !segment) return <PageLoader message="Loading segment..." />;
  if (segment.updateStatus === 'RUNNING') return <PageLoader message="Segment is updating..." />;
  if (segment.updateStatus === 'ERROR') return <PageLoader message="Segment generation failed..." />;

  return (
    <WideLayout
      preheader=""
      titleControls={<SegmentSyncActions isLoading={isLoading} segment={segment} />}
      title={
        <div className="d-flex align-content-center">
          {segment.name}
          {segment.refreshStatus === 'RUNNING' && (
            <TooltipWrapper
              placement="bottom"
              tooltipText="Segment is refreshing"
              customComponent={
                <Spinner size="sm" className="ms-3" variant="light" style={{ position: 'relative', top: -2 }} />
              }
            />
          )}
          <PersonaErrorStatusIcon segment={segment} />
        </div>
      }
    >
      <Tab.Container id="list-tabs" defaultActiveKey={defaultTab} unmountOnExit mountOnEnter>
        <Card>
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey="#first">
              <Nav.Item>
                <Nav.Link href="#companies" eventKey="companies">
                  {formatTab('Companies', segment.sizeCompanies)}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#contacts" eventKey="contacts">
                  {formatTab('Contacts', segment.sizeContacts)}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="companies">
              <CompaniesTab segment={segment} />
            </Tab.Pane>
            <Tab.Pane eventKey="contacts">
              <ContactsTab reloadSegment={handleLoadSegment} segment={segment} personas={personas} />
            </Tab.Pane>
          </Tab.Content>
        </Card>
      </Tab.Container>
    </WideLayout>
  );
}
