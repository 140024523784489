/**
 * Small helper that allows type-safe modifications of 'arrays of objects'
 * Eg the use case where we have a page with a list of things loaded via API and we want to mutate them in common (RESTlike) ways
 */
export class CollectionHelper<T> {
  constructor(protected idField: keyof T) {}

  getItem(items: T[], id: any): T | undefined {
    return items.find(item => item[this.idField] === id);
  }

  replaceItem(items: T[], id: any, newItem: T) {
    return items.map((item: T) => {
      if (item[this.idField] === id) {
        return newItem;
      }
      return item;
    });
  }

  updateItem(items: T[], id: any, update: Partial<T>) {
    return items.map((item: T) => {
      if (item[this.idField] === id) {
        return { ...item, ...update };
      }
      return item;
    });
  }

  deleteItem(items: T[], id: any) {
    return items.filter(item => item[this.idField] !== id);
  }

  addItem(items: T[], value: T) {
    return [...items, value];
  }
}
