import { useRef, useCallback, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import LoadingButton from 'src/components/LoadingButton';
import API from 'src/utils/API';

export default function LookupIndentityResolution() {
  const [result, setResult] = useState<any>(null);
  const domainRef = useRef<HTMLInputElement>(null);
  const linkedInUrlRef = useRef<HTMLInputElement>(null);

  const testIdResolution = useCallback(async () => {
    setResult(null);
    return toast.promise(
      API.post<any>(
        `/admin/toolbox/test-id-resolution`,
        {},
        {
          domain: domainRef?.current?.value,
          linkedInUrl: linkedInUrlRef?.current?.value
        }
      ).then(result => setResult(result)),
      {
        loading: 'Checking...',
        error: 'Error resolving!',
        success: 'Done'
      }
    );
  }, []);

  return (
    <Card>
      <Card.Header>Identity Resolution Check</Card.Header>
      <Card.Body>
        <p className="text-muted">
          Use this tool to trigger and check ID resolution for a given domain and / or linkedin
        </p>
        <Form.Group className="mb-3" controlId="id_res_domain">
          <Form.Label>Domain</Form.Label>
          <Form.Control ref={domainRef} name="domain" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="id_res_li">
          <Form.Label>LinkedIn URL</Form.Label>
          <Form.Control ref={linkedInUrlRef} name="linkedInUrl" />
        </Form.Group>
        {result ? (
          <>
            <pre style={{ border: '1px solid #ccc', background: '#eee', overflowY: 'scroll', maxHeight: 300 }}>
              {JSON.stringify(result, null, 2)}
            </pre>
          </>
        ) : (
          <></>
        )}
      </Card.Body>
      <Card.Footer>
        <LoadingButton size="sm" onClick={testIdResolution}>
          Trigger and check
        </LoadingButton>
      </Card.Footer>
    </Card>
  );
}
