import { useDrag } from 'react-dnd';
import { Button, Card } from 'react-bootstrap';
import { FiX, FiCopy } from 'react-icons/fi';
import { RiDraggable } from 'react-icons/ri';
import Tag from 'src/components/Tag';

export function PersonaRule({
  idx,
  groupNumber,
  positionInclusionKeywords,
  positionExclusionKeywords,
  seniorities,
  countries,
  onEdit,
  onDelete,
  onDuplicate
}: any) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'PERSONA_RULE',
    item: { idx },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  }));

  return (
    <Card
      ref={drag}
      style={{
        marginTop: 8,
        marginBottom: 8,
        border: '1px solid #ddd',
        opacity: isDragging ? 0.5 : 1
      }}
    >
      <Card.Body key={`persona-rule-${idx}`}>
        <div className="d-flex justify-content-between" style={{ gap: 8 }}>
          <h4>
            <RiDraggable style={{ margin: '-3px 8px 0px -3px', cursor: 'move' }} />
            {`Priority ${groupNumber}`}
            &nbsp;&nbsp;&nbsp;
          </h4>
          <div>
            <Button size="sm" variant="white" onClick={onEdit}>
              Edit
            </Button>
            &nbsp;
            <Button size="sm" variant="white" onClick={onDuplicate} title="Clone criteria">
              <FiCopy />
            </Button>
            &nbsp;
            <Button size="sm" variant="white" onClick={onDelete} title="Delete criteria">
              <FiX />
            </Button>
          </div>
        </div>
        {positionInclusionKeywords && positionInclusionKeywords.length > 0 && (
          <div className="d-flex justify-content-between align-items-start" style={{ gap: 8 }}>
            <div style={{ maxWidth: 200, width: '20%', textAlign: 'left', paddingTop: 2 }}>Position inclusions:</div>
            <div style={{ width: '80%' }}>
              {positionInclusionKeywords.map((t: string) => (
                <Tag color="green" key={`${t}-${idx}`}>
                  {t}
                </Tag>
              ))}
            </div>
          </div>
        )}
        {positionExclusionKeywords && positionExclusionKeywords.length > 0 && (
          <div className="d-flex justify-content-between align-items-start" style={{ gap: 8 }}>
            <div style={{ maxWidth: 200, width: '20%', textAlign: 'left', paddingTop: 2 }}>Position exclusions:</div>
            <div style={{ width: '80%' }}>
              {positionExclusionKeywords.map((t: string) => (
                <Tag color="red" key={`${t}-${idx}`}>
                  {t}
                </Tag>
              ))}
            </div>
          </div>
        )}
        {seniorities && seniorities.length > 0 && (
          <div className="d-flex justify-content-between align-items-start" style={{ gap: 8 }}>
            <div style={{ maxWidth: 200, width: '20%', textAlign: 'left', paddingTop: 2 }}>Seniority:</div>
            <div style={{ width: '80%' }}>
              {seniorities.map((t: string) => (
                <Tag color="orange" key={`${t}-${idx}`}>
                  {t}
                </Tag>
              ))}
            </div>
          </div>
        )}
        {countries && countries.length > 0 && (
          <div className="d-flex justify-content-between align-items-start" style={{ gap: 8 }}>
            <div style={{ maxWidth: 200, width: '20%', textAlign: 'left', paddingTop: 2 }}>Countries:</div>
            <div style={{ width: '80%' }}>
              {countries.map((t: string) => (
                <Tag color="blue" key={`${t}-${idx}`}>
                  {t}
                </Tag>
              ))}
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
