import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PageLoader from 'src/components/PageLoader';
import API from 'src/utils/API';

async function loadCompaniesExample() {
  return (
    await API.post<any>(
      '/app/companies/search',
      {},
      {
        limit: 1,
        filters: undefined,
        offset: 0,
        search: '',
        sortby: '__quality_score',
        sortdirection: 'desc'
      }
    )
  ).companies;
}

async function loadContactsExamples() {
  return (
    await API.get<any>(
      '/app/contacts',
      {
        limit: 5,
        filters: undefined,
        offset: 0
      },
      {}
    )
  ).contacts;
}

function wrapWebhook(body: any, type: string, isBatch: boolean) {
  return JSON.stringify({ body: isBatch ? [body] : body, source: 'goodfit', type }, null, 2);
}

function quickMapSchema(body: any, schema: any[]) {
  const ret: Record<string, any> = {};
  for (const field of schema) {
    if (field.fieldName.startsWith('crm_sync')) continue;
    if (field.fieldName.startsWith('__')) continue;
    if (field.isHiddenFromUser) continue;
    if (!field.isMappableInIntegration) continue;

    const fieldName = field.fieldName as string;
    let value = body[fieldName];
    if (field.type === 'Number' || field.type === 'Currency' || field.type === 'Percentage') {
      value = parseFloat(value);
    }
    ret[fieldName] = value;
  }

  return ret;
}

function mapContact(rawContact: any) {
  return {
    first_name: rawContact.first_name,
    last_name: rawContact.last_name,
    linkedin: rawContact.person_url,
    title: rawContact.position,
    tenure: rawContact.tenure,
    email: rawContact.email,
    email_status: rawContact.email_verification_status,
    is_former_employment: rawContact.is_former_employment,
    country: rawContact.country,
    location: rawContact.location,
    source: 'goodfit',
    is_goodfit: true
  };
}

function mapExampleContact(
  exampleCompanies: any[],
  exampleContacts: any[],
  isContactsEnabled: boolean,
  companiesSchema: any[],
  contactsSchema: any[]
) {
  if (!exampleCompanies) return null;
  if (!exampleContacts) return null;
  if (!isContactsEnabled) return null;
  const body = exampleContacts[0];
  if (!body) return {};
  const ret = quickMapSchema(mapContact(body), contactsSchema);
  ret.company = mapExampleCompany(exampleCompanies, exampleContacts, false, companiesSchema, contactsSchema);
  return ret;
}

function mapExampleCompany(
  exampleCompanies: any[],
  exampleContacts: any[],
  isContactsEnabled: boolean,
  companiesSchema: any[],
  contactsSchema: any[]
) {
  if (!exampleCompanies) return null;
  const exampleCompany = exampleCompanies[0];
  if (!exampleCompany) return {};
  const body = { ...exampleCompany };
  const ret = quickMapSchema(body, companiesSchema);
  ret.goodfit_company_id = exampleCompany.__company_id;
  if (isContactsEnabled) {
    ret.contacts = exampleContacts.slice(0, 2).map(c => quickMapSchema(mapContact(c), contactsSchema));
  }
  return ret;
}

export function WebhookExamplePayloadModal({
  show,
  recordType,
  onHide,
  isContactsEnabled,
  companiesSchema,
  contactsSchema,
  isBatch
}: any) {
  const [exampleCompanies, setExampleCompanies] = useState<any>(null);
  const [exampleContacts, setExampleContacts] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!show) return;
    setIsLoading(true);
    const loaders = [loadCompaniesExample(), loadContactsExamples()];
    Promise.all(loaders).then(results => {
      setExampleCompanies(results[0]);
      setExampleContacts(results[1]);
      setIsLoading(false);
    });
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Example {recordType} payload</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-0">
        {isLoading ? (
          <PageLoader fullHeight={false} style={{ height: 500 }} />
        ) : (
          <pre style={{ backgroundColor: '#eee', height: 500, overflowY: 'scroll', padding: 8 }}>
            {recordType !== 'contact'
              ? wrapWebhook(
                  mapExampleCompany(
                    exampleCompanies,
                    exampleContacts,
                    isContactsEnabled,
                    companiesSchema,
                    contactsSchema
                  ),
                  'account', // Not 'company' for historic reasons,
                  isBatch
                )
              : wrapWebhook(
                  mapExampleContact(
                    exampleCompanies,
                    exampleContacts,
                    isContactsEnabled,
                    companiesSchema,
                    contactsSchema
                  ),
                  'contact',
                  isBatch
                )}
          </pre>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
