import { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import LoadingButton from 'src/components/LoadingButton';

export function AddUserModal({ show, onHide, onAddUser }: any) {
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const canSubmit = !!email;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Invite User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ev => ev.preventDefault()}>
          <div className="form-group">
            <Form.Label>User Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter user's email address"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <Form.Check
              checked={isAdmin}
              onChange={e => setIsAdmin(e.target.checked)}
              type="checkbox"
              id="allow-admin"
              label="Allow user to Administer this account"
            />
          </div>
        </Form>
        <p className="text-small text-muted">
          If the user already has a GoodFit user account, they will be added right away. If they do not they will
          receive an email invitation to create an account.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Cancel
        </Button>
        <LoadingButton
          disabled={!canSubmit}
          variant="primary"
          onClick={async () => {
            const userPermissions: string[] = ['USER'];
            if (isAdmin) {
              userPermissions.push('ADMIN');
            }
            await onAddUser(email, userPermissions);
            onHide();
          }}
        >
          Add User
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
}
