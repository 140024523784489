import { useCallback, useMemo, useState } from 'react';
import { Button, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { CompanySchema, UserPermission } from 'src/auth/types';
import { RxDragHandleDots2 } from 'react-icons/rx';
import SortableList from 'src/components/SortableList';
import { useClient } from 'src/auth';
import { MdOutlineKeyboardDoubleArrowDown, MdOutlineKeyboardDoubleArrowUp } from 'react-icons/md';
import pull from 'lodash/pull';

// These columns are not movable in this UI and are hidden
const fixedColumns = ['name', 'domain'];
function removeFixedColumns(fieldNames: string[]) {
  return fieldNames.filter(fieldName => !fixedColumns.includes(fieldName));
}

function ColumnItem({ item, onMoveToTop, onMoveToBottom }: any) {
  return (
    <div className="column-draggable-item">
      <div className="float-end">
        <MdOutlineKeyboardDoubleArrowUp onClick={() => onMoveToTop()} style={{ cursor: 'pointer' }} />
        <MdOutlineKeyboardDoubleArrowDown onClick={() => onMoveToBottom()} style={{ cursor: 'pointer' }} />
      </div>
      <RxDragHandleDots2 style={{ marginRight: 3, marginTop: -2 }} />
      {item.displayName}
    </div>
  );
}

export interface CompaniesTableSettingsModalProps {
  show?: boolean;
  schema: CompanySchema;
  selectedColumns: string[];
  showUpdateGlobalSettings: boolean;
  setSelectedColumns: (selectedColumns: string[]) => void;
  onHide: () => void;
}

export default function CompaniesTableSettingsModal({
  show,
  schema,
  selectedColumns,
  setSelectedColumns,
  showUpdateGlobalSettings,
  onHide
}: CompaniesTableSettingsModalProps) {
  const [modalSelectedColumns, setModalSelectedColumns] = useState<string[]>(removeFixedColumns(selectedColumns));
  const { updateSettings, userPermissions } = useClient();
  const isAdminUser = userPermissions.includes(UserPermission.ADMIN);

  const toggleColumn = useCallback(
    (fieldName: string, isShown: boolean) => {
      if (isShown) {
        setModalSelectedColumns([...modalSelectedColumns, fieldName]);
      } else {
        setModalSelectedColumns([...pull(modalSelectedColumns, fieldName)]);
      }
    },
    [modalSelectedColumns, setModalSelectedColumns]
  );

  const modalSelectedColumnsAsFields = useMemo(
    () => modalSelectedColumns.map(fieldName => ({ ...schema.find(f => f.fieldName === fieldName) })),
    [modalSelectedColumns, schema]
  );

  function setModalSelectedColumnsAsFields(fields: CompanySchema) {
    setModalSelectedColumns(fields.map(f => f.fieldName));
  }

  return (
    <Modal size="lg" show={show}>
      <Modal.Header closeButton onHide={onHide}>
        Configure table
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            Available columns
            <div className="border" style={{ overflowY: 'auto', height: 400 }}>
              <ListGroup variant="flush">
                <ListGroup.Item style={{ padding: 5 }}>
                  <Form.Check
                    style={{ marginRight: 5 }}
                    label="Company"
                    id={`table_column_company`}
                    inline
                    disabled={true}
                    checked={true}
                  />
                </ListGroup.Item>
                {schema.map(f => {
                  if (fixedColumns.includes(f.fieldName)) return null;
                  if (f.isHiddenFromUser) return null;
                  return (
                    <ListGroup.Item style={{ padding: 5 }} key={f.fieldName}>
                      <Form.Check
                        style={{ marginRight: 5 }}
                        label={f.displayName}
                        id={`table_column_${f.fieldName}`}
                        inline
                        onChange={ev => toggleColumn(f.fieldName, ev.target.checked)}
                        checked={modalSelectedColumns.includes(f.fieldName)}
                      />
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
            <div className="mt-3">
              <Button variant="light" size="sm" onClick={() => setModalSelectedColumns([])}>
                Deselect all
              </Button>
              &nbsp;
              <Button
                variant="light"
                size="sm"
                onClick={() =>
                  setModalSelectedColumns(
                    schema
                      .filter(f => !f.isHiddenFromUser)
                      .map(f => f.fieldName)
                      .filter(fieldName => !fixedColumns.includes(fieldName))
                  )
                }
              >
                Select all
              </Button>
            </div>
          </Col>
          <Col>
            Column order
            <SortableList
              className="border"
              style={{ height: 400, overflowY: 'auto' }}
              items={modalSelectedColumnsAsFields}
              component={ColumnItem}
              setItems={setModalSelectedColumnsAsFields}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {isAdminUser && showUpdateGlobalSettings && (
          <Button
            size="sm"
            variant="secondary"
            onClick={async () => {
              await updateSettings({ companiesTableFields: modalSelectedColumns });
              setSelectedColumns(modalSelectedColumns);
              onHide();
            }}
          >
            Save default column configuration
          </Button>
        )}
        <Button
          size="sm"
          variant="primary"
          onClick={async () => {
            setSelectedColumns(modalSelectedColumns);
            onHide();
          }}
        >
          Use column configuration
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CompaniesTableSettingsModal.defaultProps = {
  show: false,
  showUpdateGlobalSettings: true
};
