import API from 'src/utils/API.ts';
import { PageElementConfig } from './types.ts';

export async function createPageElementConfig(create: Partial<PageElementConfig>) {
  return await API.post<{ item: PageElementConfig }>(`/admin/page-elements/configs/items`, {}, create);
}

export async function listPageElementConfigs(
  filtering: { id: string; value: string }[],
  sorting: string,
  offset: number,
  limit: number
): Promise<{
  items: PageElementConfig[];
  count: number;
}> {
  const requestParams: any = {
    sorting,
    limit,
    offset
  };

  filtering.forEach(f => (requestParams[f.id] = f.value));

  return await API.get<{
    items: PageElementConfig[];
    count: number;
  }>('/admin/page-elements/configs/items', requestParams, {});
}

export async function getPageElementConfig(id: string): Promise<{ item: PageElementConfig }> {
  return await API.get<{ item: PageElementConfig }>(`/admin/page-elements/configs/items/${id}`, {});
}

export async function updatePageElementConfig(id: string, update: Partial<PageElementConfig>) {
  return await API.put<{ item: PageElementConfig }>(`/admin/page-elements/configs/items/${id}`, {}, update);
}

export async function optionsForPageElementConfigs(
  field: string,
  prefix: string
): Promise<{
  options: string[];
}> {
  return await API.get<{
    options: string[];
  }>(
    '/admin/page-elements/configs/options',
    {
      field,
      prefix
    },
    {}
  );
}

export async function triggerEstimation(
  companyCriteria: any,
  dataBlockConfigs: any,
  clientDestination?: string
): Promise<{ executionId: string }> {
  return await API.post<{ executionId: string }>(
    `/admin/page-elements/batch-estimate`,
    {},
    {
      companyCriteria,
      dataBlockConfigs,
      clientDestination
    }
  );
}

export const estimationResultsEndpoint = '/admin/page-elements/batch-estimate/:executionId';

export async function triggerBatch(
  companyCriteria: any,
  dataBlockConfigs: any,
  clientDestination?: string
): Promise<{ executionId: string }> {
  return await API.post<{ executionId: string }>(
    `/admin/page-elements/batch-trigger`,
    {},
    {
      companyCriteria,
      dataBlockConfigs,
      clientDestination
    }
  );
}

export async function triggerPreview(
  pageElementsConfig: PageElementConfig,
  mode: 'domains' | 'random',
  domains: string[]
): Promise<{ executionId: string }> {
  return await API.post<{ executionId: string }>(
    `/admin/page-elements/preview-trigger`,
    {},
    {
      pageElementsConfig,
      mode,
      domains
    }
  );
}

export const previewResultsEndpoint = '/admin/page-elements/preview-trigger/:executionId';

export async function getDownloadUrl(domain: string, url: string): Promise<{ downloadUrl: string }> {
  return await API.get<{ downloadUrl: string }>(
    `/admin/page-elements/get-file`,
    {
      domain,
      url
    },
    {}
  );
}
