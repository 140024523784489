import { useState, useEffect, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import { usePageTitle } from 'src/utils/usePageTitle';
import { deleteScoringAnalysisReport, loadScoringAnalysisReports } from './apis';
import { appClientUrl } from 'src/utils/urls';
import ScoringAnalysisReportList from './components/list/ScoringAnalysisReportList';
import toast from 'react-hot-toast';
import { CollectionHelper } from 'src/utils/CollectionHelper';
import { ScoringAnalysisReport } from './types';
import NavButton from 'src/components/NavButton';

const collectionHelper = new CollectionHelper<ScoringAnalysisReport>('id');

export default function ScoringAnalysisInputDomainsPage() {
  const [scoringAnalysisReports, setScoringAnalysisReports] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  usePageTitle('Scoring analysis');

  useEffect(() => {
    loadScoringAnalysisReports().then(response => {
      setIsLoading(false);
      setScoringAnalysisReports(response.reports);
    });
  }, []);

  const handleDelete = useCallback(
    (scoringAnalysisReportId: string) => {
      toast
        .promise(
          (async () => {
            return deleteScoringAnalysisReport(scoringAnalysisReportId);
          })(),
          {
            loading: 'Deleting...',
            success: 'Scoring report deleted',
            error: 'Error deleting scoring report'
          }
        )
        .then(() => {
          setScoringAnalysisReports(collectionHelper.deleteItem(scoringAnalysisReports, scoringAnalysisReportId));
        });
    },
    [scoringAnalysisReports]
  );

  return (
    <NarrowLayout title={<>Scoring analysis reports</>} preheader="">
      <Card>
        <Card.Header>
          <h4 className="card-header-title">Saved scoring analysis reports</h4>
          <NavButton size="sm" to={appClientUrl('/scoring/analysis/new')}>
            + New analysis report
          </NavButton>
        </Card.Header>
        <Card.Body>
          <ScoringAnalysisReportList
            scoringAnalysisReports={scoringAnalysisReports}
            isLoading={isLoading}
            handleDelete={handleDelete}
          />
        </Card.Body>
      </Card>
    </NarrowLayout>
  );
}
