type KeyArray<T> = Array<keyof T>;
type StringKeyedObject = Record<string, any>;

/**
 * Helper Util: Strongly typed Object.keys
 *
 * This util is helpful where you want to dynamically address an object using
 * it's keys, but typescript complains that `string` can't be used to index your
 * object.
 *
 * **USAGE**
 * ```ts
 * type SomeObj = { someKey: string; anotherKey: boolean }
 * const myObj: SomeObj = { someKey: 'locked', anotherKey: false }
 * const myObjKeys = objectKeys(myObj)
 * // typeof key = 'someKey' | 'anotherKey'
 * myObjKeys.forEach( key => console.log(myObj[key]) )
 * ```
 *
 * 2025 and Object.keys (and even _.keys) don't accept a generic 🥴
 */
export const objectKeys = <T extends StringKeyedObject>(obj: T): KeyArray<T> => Object.keys(obj) as KeyArray<T>;
