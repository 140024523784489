import SourceCriteriaEditor from 'src/pages/staff/clientConfigs/components/SourcingCriteriaEditor.tsx';
import { FieldDefinitions } from './fieldDefinitions/FieldDefinitions.tsx';
import { FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';
import { useEffect, useState } from 'react';
import { getFieldMap } from 'src/components/Filters/CCMFilters/services/service.ts';
import PageLoader from 'src/components/PageLoader.tsx';
import { ClientConfigErrors } from 'src/pages/staff/clientConfigs/validation/types.ts';
import { DataBlockConfigs } from './dynamicDataBlocks/DataBlockConfigs.tsx';
import { useAdminContext } from 'src/adminContext/hooks.ts';

type SourcingComponentProps = {
  schema: any;
  clientConfig: any;
  isEditing: boolean;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
  errors: ClientConfigErrors | null;
};

export function SourcingComponent({
  schema,
  clientConfig,
  isEditing,
  handleChange,
  handleCommitChanges,
  handleChangeAndCommit,
  errors
}: SourcingComponentProps) {
  const { dataBlocksDefinitions, dynamicDataBlocksDefinitions } = useAdminContext();
  const [fieldMapping, setFieldMapping] = useState<FieldMapping[]>();

  useEffect(() => {
    clientConfig.dataBlockConfigs && setFieldMapping(getFieldMap(clientConfig.dataBlockConfigs, dataBlocksDefinitions));
  }, [clientConfig.dataBlockConfigs, dataBlocksDefinitions]);

  if (!fieldMapping) return <PageLoader />;

  return (
    <div>
      <SourceCriteriaEditor
        schema={schema}
        clientConfig={clientConfig}
        fieldMapping={fieldMapping}
        isEditing={isEditing}
        handleChange={handleChange}
        handleCommitChanges={handleCommitChanges}
      />
      <FieldDefinitions
        schema={schema}
        clientConfig={clientConfig}
        fieldMapping={fieldMapping}
        isEditing={isEditing}
        handleChange={handleChange}
        handleCommitChanges={handleCommitChanges}
        handleChangeAndCommit={handleChangeAndCommit}
        errors={errors}
      />
      <DataBlockConfigs
        schema={schema}
        dynamicDataBlocksDefinitions={dynamicDataBlocksDefinitions}
        clientConfig={clientConfig}
        isEditing={isEditing}
        handleChange={handleChange}
        handleCommitChanges={handleCommitChanges}
        handleChangeAndCommit={handleChangeAndCommit}
      />
    </div>
  );
}
