import Avatar from 'src/components/Avatar';

export interface IdCardCellProps {
  name: string;
  imageSrc?: string;
  hideAvatar?: boolean;
  onNameClick?: () => void;
  children?: any;
}

export interface IdCardIconProps {
  href?: string;
  imageSrc: string;
  alt?: string;
  style?: any;
  onClick?: () => void;
}

// Shows Avatar logo with name (clickable) and optional child icons
export function IdCardCell({ name, imageSrc, onNameClick, children, hideAvatar }: IdCardCellProps) {
  const actualOnClick = onNameClick ?? (() => {});
  const isClickable = !!onNameClick;

  return (
    <div className="text-start">
      {!hideAvatar && (
        <Avatar size="xs" className="me-2 float-start" style={{ minWidth: 32, width: 32, height: 32 }}>
          <Avatar.Image
            src={imageSrc}
            alt={name}
            className="rounded-circle border-light border"
            onClick={() => actualOnClick()}
            style={isClickable ? { cursor: 'pointer' } : {}}
          />
        </Avatar>
      )}
      <div>
        <div style={{ margin: 0, paddingTop: children ? '0' : '6px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <span className={isClickable ? 'id-card-cell-name-clickable' : ''} onClick={() => actualOnClick()}>
            {name}
          </span>
        </div>
        {children && <div style={{ marginTop: 0, lineHeight: '13px' }}>{children}</div>}
      </div>
    </div>
  );
}

export function IdCardIcon({ imageSrc, href, alt, style, onClick }: IdCardIconProps) {
  if (!href) {
    return (
      <span onClick={onClick} title={alt} style={{ cursor: 'pointer' }}>
        <img
          src={imageSrc}
          style={{
            opacity: 0.3,
            maxWidth: 10,
            maxHeight: 10,
            marginRight: 6,
            filter: 'grayscale(1)',
            ...(style || {})
          }}
          alt={alt || ''}
        />
      </span>
    );
  }
  return (
    <a href={href || '#'} target="_blank" onClick={onClick} title={alt}>
      <img
        src={imageSrc}
        style={{ opacity: 0.3, maxWidth: 10, maxHeight: 10, marginRight: 6, filter: 'grayscale(1)', ...(style || {}) }}
        alt={alt || ''}
      />
    </a>
  );
}
