import { ContactCriteria } from 'src/apis/clients/types.ts';
import { Button, Col, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import FieldValueSelect from 'src/components/Filters/Inputs/FieldValueSelect.tsx';
import { getContactsSample, getContactsSampleResults } from 'src/pages/staff/clientConfigs/contacts/api.ts';
import { usePeriodic } from 'src/utils/usePeriodic.ts';
import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { ContactsSample } from 'src/pages/staff/clientConfigs/contacts/types.ts';
import TableGrid from 'src/components/Table/TableGrid.tsx';
import { IdCardIcon } from 'src/components/Table/IdCardCell.tsx';
import toast from 'react-hot-toast';

export function SampleContactsTab({
  clientName,
  contactCriteria,
  included
}: {
  clientName: string | undefined;
  contactCriteria: ContactCriteria[] | undefined;
  included: boolean;
}) {
  const [domains, setDomains] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [contacts, setContacts] = useState<any[]>([]);
  const [contactsExecutionId, setContactsExecutionId] = useState<string>();

  const getSample = useCallback(() => {
    if (clientName === undefined || contactCriteria === undefined) {
      return;
    }
    setIsLoading(true);
    getContactsSample({
      clientName,
      contactCriteria,
      domains,
      included
    })
      .then(executionId => {
        setContactsExecutionId(executionId.executionId);
      })
      .catch(() => {
        toast.error('There has been a problem with your request, please contact eng');
        setIsLoading(false);
      });
  }, [clientName, contactCriteria, domains, included]);

  usePeriodic(
    () => {
      if (contactsExecutionId) {
        getContactsSampleResults(contactsExecutionId).then(response => {
          if (response.status === 'SUCCEEDED') {
            setContacts(response.output.contacts);
            setIsLoading(false);
            setContactsExecutionId(undefined);
          } else if (response.status === 'FAILED') {
            console.error('Failed to fetch totals');
            setIsLoading(false);
            setContactsExecutionId(undefined);
          }
        });
      }
    },
    5000,
    [contactsExecutionId]
  );

  useEffect(() => {
    if (isFirstLoad && clientName && contactCriteria) {
      setIsFirstLoad(false);
      getSample();
      return;
    }
  }, [clientName, contactCriteria, getSample, isFirstLoad]);

  const table = useReactTable<ContactsSample>({
    columns: [
      {
        header: 'Company',
        id: 'name',
        cell: props => (
          <div className={'d-flex gap-2 justify-content-start'}>
            <div className={'text-start'}>{props.row.original.companyName}</div>
            <div>
              <IdCardIcon
                imageSrc="/img/linkedin.svg"
                href={`https://www.linkedin.com/company/${props.row.original.companyLinkedInId}`}
                alt="Linkedin"
              />
            </div>
          </div>
        ),
        enableSorting: false
      },
      {
        header: 'Name',
        id: 'name',
        cell: props => (
          <div className={'d-flex gap-2'}>
            <div className={'text-start'}>{props.row.original.rawName}</div>
            <div>
              <IdCardIcon imageSrc="/img/linkedin.svg" href={props.row.original.personLinkedInUrl} alt="Linkedin" />
            </div>
          </div>
        ),
        enableSorting: false
      },
      {
        header: 'Title',
        accessorKey: 'title',
        cell: props => <div className={'text-start'}>{props.row.original.position}</div>,
        enableSorting: false
      },
      {
        header: 'Seniority',
        accessorKey: 'seniority',
        cell: props => <div className={'text-start'}>{props.row.original.seniority}</div>,
        enableSorting: false
      },
      {
        header: 'Country',
        accessorKey: 'country',
        cell: props => <div className={'text-start'}>{props.row.original.country}</div>,
        enableSorting: false
      }
    ],
    data: contacts,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  const copyToClipboard = useCallback(() => {
    const lines = [];
    const header = [
      'Company Name',
      'Company Linked In URL',
      'Name',
      'Person Linked In URL',
      'Position',
      'Seniority',
      'Country'
    ];

    lines.push(header.join('\t'));
    contacts.forEach(contact => {
      lines.push(
        [
          contact.companyName,
          `https://www.linkedin.com/company/${contact.companyLinkedInId}`,
          contact.rawName,
          contact.personLinkedInUrl,
          contact.position,
          contact.seniority,
          contact.country
        ].join('\t')
      );
    });

    const textTsv = lines.join('\n');
    navigator.clipboard.writeText(textTsv);

    toast.success(`Copied ${contacts.length} rows to clipboard!`);
  }, [contacts]);

  return (
    <Col>
      <Row xs={'auto'} className={'d-flex justify-content-between'}>
        <div className={'d-flex align-center gap-2'}>
          <div style={{ maxWidth: '300px' }}>
            <FieldValueSelect
              onChange={value => {
                setDomains(value);
              }}
              value={domains}
              fieldName={'Domains'}
              allowNew={true}
              placeholder={'Domains to preview'}
              disabled={false}
              loadOptions={async () => []}
              isMulti={true}
            />
          </div>
          <div className={'align-content-end'}>
            <Button size={'sm'} onClick={getSample} disabled={!(domains?.length > 0)}>
              Preview
            </Button>
          </div>
        </div>
        <div className={'align-content-end'}>
          <Button variant="white" size="sm" className="me-2" onClick={copyToClipboard}>
            Copy to clipboard
          </Button>
        </div>
      </Row>
      <Row className={'my-4'}>
        <TableGrid
          table={table}
          isLoading={isLoading}
          emptyMessage="No contacts to show"
          numLoadingRows={5}
          className="contacts-sample-table table-centered table-compact table-wrap"
        />
      </Row>
    </Col>
  );
}
