import { Card } from 'react-bootstrap';
import './guides-bar.scss';

export function GuidesBar() {
  const guides = [
    {
      emoji: '👋',
      header: 'Intro',
      link: 'https://docs.goodfit.io/goodfit-docs/guides/segmentation/segments-introduction'
    },
    {
      emoji: '🤖',
      header: 'Programmatic Outreach',
      link: 'https://docs.goodfit.io/goodfit-docs/guides/segmentation/programmatic-outreach'
    },
    {
      emoji: '🔄',

      header: 'Distribution',
      link: 'https://docs.goodfit.io/goodfit-docs/guides/segmentation/distribution'
    },
    {
      emoji: '🔢',
      header: 'Tiering',
      link: 'https://docs.goodfit.io/goodfit-docs/guides/segmentation/tiering'
    },
    {
      emoji: '📊',

      header: 'LinkedIn Ads',
      link: 'https://docs.goodfit.io/goodfit-docs/guides/segmentation/linkedin-ads'
    },
    {
      emoji: '🙋',
      header: 'Personas',
      link: 'https://docs.goodfit.io/goodfit-docs/guides/segmentation/personas-introduction'
    },
    {
      emoji: '🔍',
      header: 'Contact Coverage',
      link: 'https://docs.goodfit.io/goodfit-docs/guides/segmentation/maximising-contact-coverage'
    }
  ];

  return (
    <div>
      <h3>Guides</h3>
      <div className="guides-bar d-flex flex-row gap-3 my-3">
        {guides.map((g, index) => {
          return (
            <Card
              key={index}
              className="d-flex flex-column shadow-sm mb-1 text-center p-3 justify-content-center"
              onClick={() => window.open(g.link, '_blank')}
            >
              <a href={g.link} target="_blank" className="d-block text-black">
                <span className="me-2">{g.emoji}</span>
                {g.header}
              </a>
            </Card>
          );
        })}
      </div>
    </div>
  );
}
