import { useCallback, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { usePeriodic } from 'src/utils/usePeriodic';
import { fetchExports, fetchExportSingedDownloadUrl } from './apis';
import PageLoader from 'src/components/PageLoader';

export function ExportsList() {
  const [exports, setExports] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const downloadExport = useCallback(async (exportId: string) => {
    const signedUrl = await fetchExportSingedDownloadUrl(exportId);
    window.open(signedUrl);
  }, []);

  usePeriodic(
    async () => {
      const results = await fetchExports();
      setExports(results.items.slice(0, 10));
      setIsLoading(false);
    },
    5000,
    [],
    true
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>Export Name</th>
          <th>Status</th>
          <th>Requested By</th>
          <th style={{ width: '10%' }}></th>
        </tr>
      </thead>
      <tbody>
        {exports.map(e => (
          <tr key={e.id}>
            <td>{e.exportName}</td>
            <td>{e.status}</td>
            <td>{e.requestedByUserEmail}</td>
            <th>
              <Button size="sm" variant="white" disabled={e.status !== 'COMPLETE'} onClick={() => downloadExport(e.id)}>
                Download
              </Button>
            </th>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
