import { Action, Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { MdLightbulb } from 'react-icons/md';
import { IoHelpOutline } from 'react-icons/io5';

const DEFAULT_HELP_URL = 'https://docs.goodfit.io/goodfit-docs';
const STAFF_ADMIN_HELP_URL = 'https://goodfit.slab.com/posts/gf-app-admin-panel-er1aaeqh';

export function FloatingAdminHelpButton() {
  return (
    <>
      <Fab
        icon={<MdLightbulb />}
        alwaysShowTitle={true}
        mainButtonStyles={{
          background: '#8c6cf5',
          height: '45px',
          width: '45px'
        }}
      >
        <Action
          text="GoodFit docs"
          onClick={() => window.open(DEFAULT_HELP_URL, '_blank')}
          style={{ height: '40px', width: '40px' }}
        >
          <IoHelpOutline />
        </Action>
        <Action
          text="Staff Admin docs"
          onClick={() => window.open(STAFF_ADMIN_HELP_URL, '_blank')}
          style={{ height: '40px', width: '40px' }}
        >
          <IoHelpOutline />
        </Action>
      </Fab>
    </>
  );
}
