import { Dropdown, ListGroup, Row, Col, Badge } from 'react-bootstrap';
import gravatarUrl from 'gravatar-url';

import Avatar from 'src/components/Avatar';
import { UserPlaceholder } from './UserPlaceholder';
import { User } from '../types';
import { UserPermission, useAuth } from 'src/auth';
import { FiMoreVertical } from 'react-icons/fi';

interface UserListProps {
  isLoading: boolean;
  users: User[];
  onRemoveUser: (userId: string) => void;
  onOpenModifyUser: (user: User) => void;
}

export function UserList({ isLoading, users, onRemoveUser, onOpenModifyUser }: UserListProps) {
  const { user } = useAuth();

  if (isLoading) {
    return (
      <>
        <UserPlaceholder />
        <UserPlaceholder />
        <UserPlaceholder />
      </>
    );
  }

  if (users.length === 0) {
    return 'There are no users in your account';
  }

  return users.map((item, index) => (
    <ListGroup.Item key={index}>
      <Row className="align-items-center">
        <Col xs="auto">
          <Avatar>
            <Avatar.Image
              src={gravatarUrl(item.email, { size: 200, default: 'retro' })}
              alt={item.email}
              className="rounded-circle"
            />
          </Avatar>
        </Col>
        <Col xs={5} className="ms-n2">
          <h4 className="mb-1">{item.email}</h4>

          {item.userPermissions?.includes(UserPermission.ADMIN) && <Badge style={{ marginRight: 5 }}>Admin</Badge>}
          {item.isPendingAcceptance && (
            <Badge style={{ marginRight: 5 }} bg="light">
              Pending Acceptance
            </Badge>
          )}
        </Col>
        <Col xs="auto" className="ms-auto me-n3"></Col>
        <Col xs="auto">
          {user?.id !== item.userId && (
            <Dropdown align="end">
              <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                <FiMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onRemoveUser(item.userId)}>Remove User</Dropdown.Item>
                <Dropdown.Item onClick={() => onOpenModifyUser(item)}>Modify Permissions</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  ));
}
