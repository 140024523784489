import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import seniorityOptions from './seniorityOptions.json';
import countryOptions from './geo/countryOptions.json';
import regionOptions from './geo/regionOptions.json';
import { PersonaContactsPreviewTable } from './preview/PersonaContactsPreviewTable';
import LoadingButton from 'src/components/LoadingButton';
import { StringListInput } from './StringListInput';
import { SegmentPersonaCriteria } from 'src/pages/segments/types';

const combinedLocationOptions = [
  { label: 'Regions', options: regionOptions },
  { label: 'Countries', options: countryOptions }
];

export function PersonaCriteriaRuleEditModal({ segmentId, proceed, show, criteria }: any) {
  const [positionInclusionKeywords, setPositionInclusionKeywords] = useState<string[]>([]);
  const [positionExclusionKeywords, setPositionExclusionKeywords] = useState<string[]>([]);
  const [seniorities, setSeniorities] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [criteriaGroups, setCriteriaGroups] = useState<SegmentPersonaCriteria[]>([]);

  useEffect(() => {
    // Set defaults if existing criteria passed in
    if (criteria) {
      setPositionInclusionKeywords(criteria.positionInclusionKeywords);
      setPositionExclusionKeywords(criteria.positionExclusionKeywords);
      setSeniorities(criteria.seniorities);
      setCountries(criteria.countries);
    }
  }, [criteria]);

  const updatePreview = useCallback(async () => {
    setCriteriaGroups([{ positionInclusionKeywords, positionExclusionKeywords, seniorities, countries }]);
  }, [countries, positionExclusionKeywords, positionInclusionKeywords, seniorities]);

  const isAnyFieldSet =
    positionInclusionKeywords?.length > 0 ||
    positionExclusionKeywords?.length > 0 ||
    seniorities?.length > 0 ||
    countries?.length > 0;

  return (
    <Modal animation={true} show={show} onHide={() => proceed(null)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Define Persona Criteria</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={4}>
            <Form onSubmit={ev => ev.preventDefault()}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Position inclusion keywords</Form.Label>
                <StringListInput
                  value={positionInclusionKeywords}
                  onChange={(newValue: string[]) => setPositionInclusionKeywords(newValue)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Position exclusion keywords</Form.Label>
                <StringListInput
                  value={positionExclusionKeywords}
                  onChange={(newValue: string[]) => setPositionExclusionKeywords(newValue)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Seniority levels</Form.Label>
                <StringListInput
                  value={seniorities}
                  options={seniorityOptions}
                  onChange={(newValue: string[]) => setSeniorities(newValue)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Countries</Form.Label>
                <StringListInput
                  value={countries}
                  options={combinedLocationOptions}
                  onChange={(newValue: string[]) => setCountries(newValue)}
                />
              </Form.Group>
            </Form>
            <div>
              <LoadingButton size="sm" variant="light" onClick={updatePreview}>
                Preview
              </LoadingButton>
            </div>
          </Col>
          <Col sm={8}>
            <div style={{ height: 550, border: '1px solid #eee' }}>
              <PersonaContactsPreviewTable segmentId={segmentId} criteriaGroups={criteriaGroups} />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="light" onClick={() => proceed(null)}>
          Cancel
        </Button>
        <Button
          className="button-l"
          variant="primary"
          size="sm"
          disabled={!isAnyFieldSet}
          onClick={() => {
            proceed({
              positionInclusionKeywords,
              positionExclusionKeywords: positionExclusionKeywords?.length > 0 ? positionExclusionKeywords : null,
              seniorities: seniorities?.length > 0 ? seniorities : null,
              countries: countries?.length > 0 ? countries : null
            });
          }}
        >
          Save criteria
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
