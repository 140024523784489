import { CompanySchemaField } from 'src/auth';
import { titleCase } from './string';

export type SelectValue = string | null;
export type SelectOption = {
  value: SelectValue;
  label: string;
  optgroup?: string;
};

export function applyOptionGrouping(opts: SelectOption[]) {
  const ret: any[] = [];
  const groups: any = {};

  for (const opt of opts) {
    if (opt.optgroup) {
      const optgroup = opt.optgroup;
      if (!groups[optgroup]) {
        const grp = { label: optgroup, options: [] };
        ret.push(grp);
        groups[optgroup] = grp;
      }
      groups[optgroup].options.push(opt);
    } else {
      ret.push(opt);
    }
  }

  return ret;
}

function formatGroupTitle(str: string) {
  if (!str) return str;
  return titleCase(str.replace(/_/g, ' '));
}

export function getGroupNameForFieldOption(f: CompanySchemaField) {
  let optgroup = f?.dataBlock?.blockName ? formatGroupTitle(f?.dataBlock?.blockName) : null;
  if (!optgroup) {
    const parts = f.fieldSource?.split('/');
    if (parts[0] === 'crmSync') optgroup = 'Sync status';
    if (parts[0] === 'scoring') optgroup = 'Calculated fields';
    if (parts[0] === 'calculatedFields') optgroup = 'Calculated fields';
    if (parts[0] === 'calculated_fields') optgroup = 'Calculated fields';
  }
  if (!optgroup) optgroup = 'Other';
  return optgroup;
}
