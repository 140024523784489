import { FiExternalLink } from 'react-icons/fi';

import { SegmentBreakingChanges } from 'src/apis/types';
import { pluralize } from 'src/utils/string';

interface ConflictingSegmentsProps {
  resourceName: string;
  breakingSegments: SegmentBreakingChanges[];
  clientName: string;
}

export const BreakingSegments: React.FC<ConflictingSegmentsProps> = ({
  clientName,
  breakingSegments,
  resourceName
}) => {
  if (breakingSegments.length === 0) return null;

  return (
    <>
      <h4>
        The following {pluralize(breakingSegments.length, 'segment is', 'segments are')} reliant upon this{' '}
        {resourceName}:
      </h4>

      <ul>
        {breakingSegments.map(({ segmentId, segmentName }) => {
          const segmentUrl = `/app/${clientName}/segments/edit/${segmentId}`;

          return (
            <li key={segmentId}>
              <a className="d-inline-flex align-items-center" href={segmentUrl} target="_blank">
                {segmentName} <FiExternalLink className="ms-1" />
              </a>{' '}
            </li>
          );
        })}
      </ul>

      <div>
        To delete this {resourceName}, you must remove the field from the segment{' '}
        {pluralize(breakingSegments.length, 'definition', 'definitions')}.
      </div>
    </>
  );
};
