import React from 'react';
import { Form, Accordion } from 'react-bootstrap';

import { useClient } from 'src/auth';
import ClientCompaniesFilters from 'src/components/Filters/ClientCompaniesFilters/ClientCompaniesFilters';
import { Segment } from '../../../types';
import { CheckToRevealDomainsInput } from '../CheckToRevealDomains';
import { CheckToRevealLimitOptions } from '../CheckToRevealLimitOptions';
import { CheckToRevealSegments } from '../CheckToRevealSegments';
import { DynamicSegmentToggle } from './DynamicSegmentToggle';
import { SegmentNameInputField } from './SegmentNameInputField';

export interface SegmentSettingsFormProps {
  segment: Partial<Segment>;
  updateSegment: (key: string, value: any) => void;
  setIsSegmentNameValid: React.Dispatch<React.SetStateAction<boolean>>;
  isSegmentNameValid: boolean;
}

export function SegmentSettingsForm({
  segment,
  updateSegment,
  isSegmentNameValid,
  setIsSegmentNameValid
}: SegmentSettingsFormProps) {
  const { companiesSchema } = useClient();

  return (
    <>
      <SegmentNameInputField
        segment={segment}
        updateSegment={updateSegment}
        isSegmentNameValid={isSegmentNameValid}
        setIsSegmentNameValid={setIsSegmentNameValid}
      />
      <div>
        <Form onSubmit={ev => ev.preventDefault()}>
          <DynamicSegmentToggle segment={segment} updateSegment={updateSegment} />

          <Accordion style={{ border: 'none' }} defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Company filters</Accordion.Header>
              <Accordion.Body>
                <ClientCompaniesFilters
                  schema={companiesSchema}
                  defaultQuery={segment?.filters ?? null}
                  setQuery={q => updateSegment('filters', q)}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Inclusions and Exclusions</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3" id="pants112">
                  <CheckToRevealDomainsInput
                    name="exclude_domains"
                    label="Exclude specific company domains"
                    initialValue={segment?.exclusions?.domains}
                    maxDomains={2000}
                    onChange={(domains: string[]) =>
                      updateSegment('exclusions', { ...(segment?.exclusions ?? {}), domains })
                    }
                  />
                  <CheckToRevealDomainsInput
                    name="include_domains"
                    label="Include specific company domains"
                    initialValue={segment?.inclusions?.domains}
                    maxDomains={5000}
                    onChange={(domains: string[]) =>
                      updateSegment('inclusions', { ...(segment?.inclusions ?? {}), domains })
                    }
                  />
                  <CheckToRevealSegments
                    initialValue={segment?.exclusions?.segmentIds}
                    suppressSegmentIds={[segment?.id]}
                    label="Exclude companies from other segments"
                    name="exclude_segments"
                    onChange={(segmentIds: string[]) =>
                      updateSegment('exclusions', { ...(segment?.exclusions ?? {}), segmentIds })
                    }
                  />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Limit and sorting</Accordion.Header>
              <Accordion.Body>
                <CheckToRevealLimitOptions
                  label="Limit number of companies in segment"
                  name="limit"
                  initialValue={segment.limitOptions}
                  onChange={(value: any) => updateSegment('limitOptions', value)}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Form>
      </div>
    </>
  );
}
