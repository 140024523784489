import './preview-styles.scss';

import { useEffect, useState } from 'react';
import { ColumnDef, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';

import { PersonaPreviewResult, PreviewContact, Segment, SegmentPersonaCriteria } from 'src/pages/segments/types';
import TableGrid from 'src/components/Table/TableGrid';
import { PreviewFailedError } from './StatusComponents/PreviewFailedError';
import { PreviewLoading } from './StatusComponents/PreviewLoading';
import { NoMatchesFound } from './StatusComponents/NoMatchesFound';
import { generatePersonaPreview, loadSegment } from 'src/pages/segments/apis';
import { formatNumber, formatPercent } from 'src/utils/number';
import { pluralize } from 'src/utils/string';
import EmptyMessage from 'src/components/EmptyMessage';
import { HEIGHT } from './StatusComponents/constants';
import SimplePaginator from 'src/components/Table/SimplePaginator';
import { mapSeniority } from 'src/pages/segments/utils';
import { CellLink } from '../CellLink';
import { TooltipWrapper } from 'src/components/TooltipWrapper';

const columns: ColumnDef<PreviewContact>[] = [
  {
    accessorKey: 'raw_name',
    header: 'Name',
    cell: ({ row: { original } }) => <CellLink linkText={original.raw_name} linkUrl={original.person_url} />
  },
  {
    accessorKey: 'company_name',
    header: 'Company',
    cell: ({ row: { original } }) => (
      <CellLink
        linkText={original.company_name}
        linkUrl={`https://www.linkedin.com/company/${original.company_linked_in_id}`}
      />
    )
  },
  { accessorKey: 'position', header: 'Position' },
  { accessorKey: 'country', header: 'Country' },
  {
    accessorKey: 'seniority',
    header: 'Seniority',
    cell: c => mapSeniority(c.row.original.seniority)
  }
];

const defaultPagination = { pageSize: 100, pageIndex: 0 };

type PersonaContactsPreviewTableProps = {
  segmentId: string;
  criteriaGroups: SegmentPersonaCriteria[];
};

export function PersonaContactsPreviewTable({ segmentId, criteriaGroups }: PersonaContactsPreviewTableProps) {
  const [cachedPages, setCachedPages] = useState<Record<string, PersonaPreviewResult>>({});
  const [pagination, setPagination] = useState<typeof defaultPagination>(defaultPagination);
  const [previewResponse, setPreviewResponse] = useState<PersonaPreviewResult | null>(null);
  const [previewIsLoading, setPreviewIsLoading] = useState<boolean>(false);
  const [previewIsError, setPreviewIsError] = useState<boolean>(false);
  const [segment, setSegment] = useState<Segment | null>();

  const pageCount = Math.ceil((previewResponse?.totalContacts ?? 1) / pagination.pageSize);

  const setPage = (page: number): void => setPagination(state => ({ ...state, pageIndex: page * state.pageSize }));

  const table = useReactTable({
    columns,
    data: previewResponse?.items ?? [],
    manualPagination: true,
    manualSorting: true,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  useEffect(() => {
    if (criteriaGroups.length === 0) return;

    const cacheKey = `${segmentId}-${JSON.stringify(criteriaGroups)}-CONTACTS-${JSON.stringify(pagination)}`;

    // Cache the result for re-use when navigating back to the same page again
    if (cachedPages[cacheKey]) setPreviewResponse(cachedPages[cacheKey]);
    else {
      setPreviewIsLoading(true);

      Promise.all([generatePersonaPreview(segmentId, criteriaGroups, 'CONTACTS', pagination), loadSegment(segmentId)])
        .then(([personaPreviewResponse, segmentResponse]) => {
          setPreviewIsError(false);
          setPreviewResponse(personaPreviewResponse as PersonaPreviewResult);
          setCachedPages(state => ({ ...state, [cacheKey]: personaPreviewResponse as PersonaPreviewResult }));
          setSegment(segmentResponse.item);
        })
        .catch(err => {
          console.error('Preview error', err);
          setPreviewIsError(true);
        })
        .finally(() => setPreviewIsLoading(false));
    }
  }, [cachedPages, criteriaGroups, pagination, segmentId]);

  const isNotLoadedYet = criteriaGroups.length === 0 && previewResponse === null;
  const noMatchesFound = !previewResponse || previewResponse.items.length === 0;

  if (previewIsError) return <PreviewFailedError />;
  if (previewIsLoading) return <PreviewLoading />;
  // This condition is designed purely for the PersonaCriteriaRuleEditModal usage
  if (isNotLoadedYet) {
    return <EmptyMessage style={{ height: HEIGHT }} message="Please click preview to show preview contacts" />;
  }
  if (noMatchesFound) return <NoMatchesFound dataType="contacts" />;

  return (
    <>
      <div style={{ padding: 5, background: 'var(--bs-gray-100)', textAlign: 'center' }}>
        {/* e.g. "Found 2 contacts across 2 companies (20% coverage) in selected persona" */}
        Found {formatNumber(previewResponse.totalContacts)}{' '}
        {pluralize(previewResponse.totalContacts, 'contact', 'contacts')} across{' '}
        {formatNumber(previewResponse?.totalCompanies ?? 0)}{' '}
        {pluralize(previewResponse?.totalCompanies ?? 0, 'company', 'companies')} (
        {formatPercent((previewResponse?.totalCompanies ?? 0) / (segment?.sizeCompanies || 0), 1)} coverage) in selected
        persona{' '}
        <TooltipWrapper tooltipText="Actual number of found contacts can vary depending on email requirements and number per company" />
      </div>

      <TableGrid
        table={table}
        isLoading={false}
        dataColumns={columns.length}
        wrapperClassName="scrolling-wrapper"
        className="table-uniform-width-columns table-compact table-centered sticky-thead"
      />

      <div className="d-flex justify-content-end mt-3 me-3 pagination-wrapper">
        <SimplePaginator
          setPage={setPage}
          totalPages={pageCount}
          pageIndex={pagination.pageIndex / pagination.pageSize}
        />
      </div>
    </>
  );
}
