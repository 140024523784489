import JsonEditor from 'src/components/JsonEditor/JsonEditor.tsx';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Toggle } from 'src/pages/staff/clientConfigs/components/Toggle.tsx';
import { ContactCriteriaRow } from './ContactCriteriaRow.tsx';
import { CompanyCriteriaGroup, ContactCriteria } from 'src/apis/clients/types.ts';
import { ContactCriteriaPreviewModal } from 'src/pages/staff/clientConfigs/contacts/ContactCriteriaPreviewModal.tsx';
import { FiBarChart2 } from 'react-icons/fi';

type ContactCriteriaProps = {
  schema: any;
  clientConfig: any;
  isEditing: boolean;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
};

export function ContactCriteriaTab({
  schema,
  clientConfig,
  isEditing,
  handleChange,
  handleChangeAndCommit,
  handleCommitChanges
}: ContactCriteriaProps) {
  const [editAsJSON, setEditAsJSON] = useState(false);
  const [openPreviewFor, setOpenPreviewFor] = useState<{
    open: boolean;
    clientName: string;
    sourcingCriteria: CompanyCriteriaGroup;
    contactCriteria: ContactCriteria[];
  }>();

  const handlePropertyChange = (index: number, key: string, value: string) => {
    const newContactCriteria = [...clientConfig.contactCriteria];
    newContactCriteria[index][key] = value;
    handleChangeAndCommit(newContactCriteria, 'contactCriteria');
  };

  const removeRow = (index: number) => {
    const newContactCriteria = [...clientConfig.contactCriteria];
    newContactCriteria.splice(index, 1);
    handleChangeAndCommit(newContactCriteria, 'contactCriteria');
  };

  const addCriteria = () => {
    const newContactCriteria = [
      ...clientConfig.contactCriteria,
      {
        persona: '',
        seniority: [],
        inclusionKeywords: [],
        exclusionKeywords: [],
        employeeCountBounds: { min: 0, max: 0 }
      }
    ];
    handleChangeAndCommit(newContactCriteria, 'contactCriteria');
  };

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div>
          <Row>
            <h3>Criteria</h3>
          </Row>
        </div>
        <div className="d-flex align-items-baseline" style={{ gap: 10 }}>
          <div>
            <Button
              size={'sm'}
              variant={'primary'}
              className={''}
              onClick={() => {
                setOpenPreviewFor({
                  open: true,
                  clientName: clientConfig.clientName,
                  sourcingCriteria: clientConfig.sourcingCriteria,
                  contactCriteria: clientConfig.contactCriteria
                });
              }}
            >
              <FiBarChart2 size={'1rem'} className={'m-1'} />
            </Button>
          </div>
          {isEditing && (
            <div>
              <Button size={'sm'} onClick={addCriteria}>
                Add Criteria
              </Button>
            </div>
          )}
          <Toggle
            flag={editAsJSON}
            label={'As JSON'}
            isEditing={true}
            onChange={() => {
              handleCommitChanges && handleCommitChanges();
              setEditAsJSON(!editAsJSON);
            }}
          />
        </div>
      </div>
      <Col xs="auto">
        {(editAsJSON && (
          <JsonEditor
            schema={{
              items: {},
              type: 'array'
            }}
            referenceSchema={schema}
            json={clientConfig.contactCriteria}
            isEditing={isEditing}
            onChange={(update: any) => {
              handleChange(update, 'contactCriteria');
            }}
          />
        )) || (
          <>
            {clientConfig.contactCriteria.length === 0 && (
              <p>No contact criteria defined, this means legacy contacts disabled.</p>
            )}
            {clientConfig.contactCriteria.map((criteria: any, index: number) => (
              <ContactCriteriaRow
                key={index}
                criteria={criteria}
                index={index}
                handleChange={handlePropertyChange}
                removeRow={removeRow}
                isEditing={isEditing}
                openPreview={() => {
                  setOpenPreviewFor({
                    open: true,
                    clientName: clientConfig.clientName,
                    sourcingCriteria: clientConfig.sourcingCriteria,
                    contactCriteria: [criteria]
                  });
                }}
              />
            ))}
          </>
        )}
      </Col>
      {!!openPreviewFor?.open && (
        <ContactCriteriaPreviewModal
          show={!!openPreviewFor?.open}
          onHide={() => {
            setOpenPreviewFor({
              open: false,
              clientName: '',
              sourcingCriteria: { rules: [] },
              contactCriteria: []
            });
          }}
          clientName={openPreviewFor.clientName}
          contactCriteria={openPreviewFor.contactCriteria}
        />
      )}
    </>
  );
}
