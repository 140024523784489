import classNames from 'classnames';
import { ImageProps } from 'react-bootstrap';
import { BsPrefixProps } from 'react-bootstrap/esm/helpers';

interface ElementProps extends BsPrefixProps, React.HTMLAttributes<HTMLElement> {
  className?: string;
}

interface AvatarProps extends ElementProps {
  ratio?: string;
  size?: string;
  status?: string;
}

function Avatar({ className, ratio, size, status, ...props }: AvatarProps) {
  const classes = classNames(
    'avatar',
    ratio && `avatar-${ratio}`,
    size && `avatar-${size}`,
    status && `avatar-${status}`,
    className
  );
  return <div className={classes} {...props}></div>;
}

// We use a DIV with backgroundImage to avoid layout issues if it fails to load
function AvatarImage({ className, src, style, ...props }: ImageProps) {
  const classes = classNames('avatar-img', className);
  return (
    <div
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundColor: 'white',
        ...(style ?? {})
      }}
      className={classes}
      {...props}
    />
  );
}

Avatar.Image = AvatarImage;

export default Avatar;
