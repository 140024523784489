import { useState } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import Button from 'src/components/LoadingButton';
import { useAuth } from '../../auth';
import goodFitLogo from '/img/goodfit-logo-text.svg';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useNavigate } from 'react-router-dom';

interface ResetPasswordStartProps {
  error?: string;
  isSubmitting?: boolean;
  onSubmit: (email: string) => void;
}

function ResetPasswordStartForm({ onSubmit, error, isSubmitting }: ResetPasswordStartProps) {
  const [email, setEmail] = useState('');

  return (
    <div style={{ marginTop: -80 }}>
      <h1 className="display-4 text-center mb-3">
        <img className="navbar-brand-img" src={goodFitLogo} alt="GoodFit" style={{ height: 25 }} />
      </h1>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(email);
        }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="form-group">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" tabIndex={1} onChange={e => setEmail(e.target.value)} />
        </div>
        <Button size="lg" className="w-100 mb-3" type="submit" tabIndex={3} loading={isSubmitting}>
          Reset password
        </Button>
      </form>
    </div>
  );
}

export default function ResetPasswordPage({ title }: any) {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  usePageTitle(title);

  const handlePasswordReset = async (email: string) => {
    setIsSubmitting(true);
    setError('');
    try {
      await auth.resetPassword(email);
      navigate('/confirm-reset-password');
    } catch (err: unknown) {
      console.error(err);
      setError('Error while changing password: ' + (err as Error).message);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="d-flex align-items-center min-vh-100 bg-auth">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={5} xl={4} className="">
            <ResetPasswordStartForm onSubmit={handlePasswordReset} error={error} isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
