import TableGrid from 'src/components/Table/TableGrid.tsx';
import SimplePaginator from 'src/components/Table/SimplePaginator.tsx';
import { ColumnDef, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FiClipboard } from 'react-icons/fi';

type InMemoryTableWithPaginationProps = {
  columns: ColumnDef<any>[];
  data: any[];
  isLoading?: boolean;
  emptyMessage?: string;
  pageSize?: number;
  stayOnPageEvenIfDataChanges?: boolean;
  allowCopyToClipboard?: boolean;
};

export function InMemoryTableWithPagination({
  columns,
  data,
  pageSize = 10,
  emptyMessage = 'No data',
  isLoading = false,
  allowCopyToClipboard = true,
  stayOnPageEvenIfDataChanges = false
}: InMemoryTableWithPaginationProps) {
  const [page, setPage] = useState(0);

  const copyToClipboard = useCallback(() => {
    const columnsToCopy = columns.map(c => ({
      name: c.header,
      id: c.id
    }));

    const rows = data;
    const lines = [];
    const headers = columnsToCopy.map(c => c.name);

    lines.push(headers.join('\t'));
    rows.forEach(example => {
      lines.push(columnsToCopy.map(c => example[c.id as any]).join('\t'));
    });

    const textTsv = lines.join('\n');
    navigator.clipboard.writeText(textTsv);

    toast.success(`Copied ${rows.length} rows to clipboard!`);
  }, [columns, data]);

  const table = useReactTable({
    columns,
    data: data.slice(page * pageSize, (page + 1) * pageSize),
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  useEffect(() => {
    if (!stayOnPageEvenIfDataChanges) {
      setPage(0);
    }
  }, [data, stayOnPageEvenIfDataChanges]);

  return (
    <>
      <TableGrid
        table={table}
        isLoading={isLoading}
        emptyMessage={emptyMessage}
        numLoadingRows={pageSize}
        showEmptyTable={true}
        className="table-centered table-compact table-nowrap table"
      />
      <div className="d-flex justify-content-end gap-2">
        <SimplePaginator pageIndex={page} totalPages={Math.ceil(data.length / pageSize)} setPage={setPage} />
        {allowCopyToClipboard && data.length > 0 && (
          <div>
            <Button
              size={'sm'}
              disabled={isLoading}
              variant={'outline-secondary'}
              style={{ fontSize: '1rem' }}
              onClick={copyToClipboard}
            >
              <FiClipboard />
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
