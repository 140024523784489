import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { TableLoading } from 'react-bootstrap-table-loading';
import { CompanySchema } from 'src/auth';
import { CompanyRecord } from 'src/pages/companies/types';
import { buildColumns, renderTableHeaderCell } from './columns';

export type SortDefinition = { orderBy?: string; orderDirection: 'ASC' | 'DESC' };

type CompaniesTableProps = {
  companies: CompanyRecord[];
  onCompanySelect?: (row: CompanyRecord) => void;
  isLoading: boolean;
  schema: CompanySchema;
  selectedColumns: string[];
  enableSorting: boolean;
  onSortChange?: (sort: SortDefinition) => void;
  emptyComponent?: JSX.Element;
};

export default function CompaniesTable({
  companies,
  onCompanySelect,
  isLoading,
  schema,
  selectedColumns,
  emptyComponent,
  enableSorting,
  onSortChange
}: CompaniesTableProps) {
  const [sort, setSort] = useState<SortDefinition>({ orderBy: undefined, orderDirection: 'ASC' });

  const columns = useMemo(
    () => buildColumns(schema, selectedColumns, enableSorting, onCompanySelect),
    [schema, onCompanySelect, selectedColumns, enableSorting]
  );

  const table = useReactTable({
    columns,
    data: companies ?? [],
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting: [{ id: sort.orderBy ?? '__company_id', desc: sort.orderDirection === 'DESC' }]
    }
  });

  if (!isLoading && table.getRowModel().rows?.length === 0 && emptyComponent) {
    return emptyComponent;
  }

  return (
    <div className="table-loading">
      <Table
        size="sm"
        className="card-table table-nowrap companies-table table-centered table-fixed-first-column table-sticky-header"
        style={{ position: 'relative' }}
        hover
        responsive
      >
        <thead style={{ position: 'sticky', top: '0' }}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header =>
                renderTableHeaderCell(header, schema, (columnId: string) => {
                  let newSort: SortDefinition | undefined;
                  if (sort?.orderBy !== columnId) {
                    newSort = {
                      orderBy: columnId,
                      orderDirection: 'ASC'
                    };
                  } else if (sort.orderDirection == 'ASC') {
                    newSort = {
                      orderBy: columnId,
                      orderDirection: 'DESC'
                    };
                  } else {
                    // 3rd click to unset sorting
                    newSort = {
                      orderDirection: 'ASC'
                    };
                  }
                  setSort(newSort);
                  if (onSortChange) onSortChange(newSort);
                })
              )}
            </tr>
          ))}
        </thead>
        {isLoading && (companies === null || companies?.length === 0) ? (
          <TableLoading columns={columns.length} lines={15} />
        ) : (
          <tbody className="fs-base">
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
      {isLoading && table.getRowModel().rows?.length > 0 && (
        <div className="table-loading-overlay">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}

CompaniesTable.defaultProps = {
  enableSorting: true
};
