import { getCurrentClientName } from 'src/auth/ClientContext';

export function appClientUrl(path: string, clientName?: string) {
  const actualClientName = clientName ?? getCurrentClientName();
  return `/app/${actualClientName}${path}`;
}

export function encodeState(state: Record<string, any>) {
  return btoa(encodeURIComponent(JSON.stringify(state)));
}

export function decodeState(stateStr: string) {
  return JSON.parse(decodeURIComponent(atob(stateStr)));
}
