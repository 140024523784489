import { Badge } from 'react-bootstrap';
import { SchemaDataType } from 'src/auth';
import { CellRenderer, EMPTY_ELEMENT, CELL_RENDERERS } from 'src/components/Table/cellRenderers';
import { titleCase } from 'src/utils/string';

export const CRM_CELL_RENDERERS: Record<string, CellRenderer> = {
  ['last_sync_status']: value =>
    value ? (
      <Badge style={{ fontSize: '1em' }} bg={value === 'success' ? 'success' : 'danger'}>
        {titleCase(value)}
      </Badge>
    ) : (
      EMPTY_ELEMENT
    ),
  /*['last_sync_error']: value => {
    if (!value || _.isError(_.attempt(JSON.parse, value))) return EMPTY_ELEMENT;
    const parsedError = JSON.parse(value);
    return CELL_RENDERERS[SchemaDataType.String](parsedError.shortDescription);
  },*/
  ['first_synced_at']: CELL_RENDERERS[SchemaDataType.Date],
  ['last_synced_at']: CELL_RENDERERS[SchemaDataType.Date],
  ['external_id']: CELL_RENDERERS[SchemaDataType.String]
};
