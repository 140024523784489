import { Alert, Collapse } from 'react-bootstrap';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { ScoringAnalysisReport } from '../../types';
import LoadingButton from 'src/components/LoadingButton';
import { useCallback, useState } from 'react';
import { loadScoringAnalysisReportAISummary } from '../../apis';
import Markdown from 'react-markdown';

export function AISummaryBar({ scoringAnalysisReport }: { scoringAnalysisReport: ScoringAnalysisReport }) {
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState('');

  const loadSummary = useCallback(() => {
    setIsLoading(true);
    loadScoringAnalysisReportAISummary(scoringAnalysisReport.id).then(response => {
      setSummary(response.summary);
      setIsLoading(false);
    });
  }, [scoringAnalysisReport.id]);

  return (
    <Alert variant="info">
      <div className="d-flex flex-row">
        <div className="flex-grow-1">
          <b>New</b> generate an AI summary of this report.
        </div>
        <LoadingButton loading={isLoading} size="sm" variant="white" onClick={loadSummary}>
          <FaWandMagicSparkles /> Summarize with AI
        </LoadingButton>
      </div>
      {summary && (
        <Collapse in={!!summary}>
          <div className="mt-2">
            <Markdown>{summary}</Markdown>
          </div>
        </Collapse>
      )}
    </Alert>
  );
}
