import { Button } from 'react-bootstrap';

export type CoverageAnalysisToolbox = {
  onCreateNewAnalysis: () => void;
  showUpdate: boolean;
  onUpdate: () => void;
  onCreateNewClient: () => void;
};

export function CoverageAnalysisToolbox({
  onCreateNewAnalysis,
  showUpdate,
  onUpdate,
  onCreateNewClient
}: CoverageAnalysisToolbox) {
  return (
    <div className={'d-flex gap-2'}>
      <Button size={'sm'} onClick={onCreateNewAnalysis}>
        Save as new Analysis
      </Button>
      {showUpdate && (
        <Button size={'sm'} onClick={onUpdate}>
          Update Analysis
        </Button>
      )}
      <Button size={'sm'} onClick={onCreateNewClient}>
        Create new Client
      </Button>
    </div>
  );
}
