import { CellContext, ColumnDef } from '@tanstack/react-table';

import { CompanySchema, SchemaDataType } from 'src/auth';
import { IdCardCell, IdCardIcon } from '../../../components/Table/IdCardCell.tsx';
import { CompanyRecord } from '../types.ts';
import { getReactTableCellRendererForType } from '../../../components/Table/cellRenderers.tsx';
import { getLogoForCompany } from 'src/utils/logos';
import { CRM_CELL_RENDERERS } from '../renderers.tsx';
import { titleCase } from 'src/utils/string';
import { navigateToCRMRecord } from '../utils.ts';
import { Button } from 'react-bootstrap';
import { MdOutlineMenuOpen } from 'react-icons/md';

export function buildColumns(schema: CompanySchema, selectedColumns: string[], onCompanySelect: (row: any) => any) {
  // The 'company id panel' is always present
  const columns: ColumnDef<CompanyRecord>[] = [
    {
      header: 'Company',
      accessorKey: 'name',
      enableSorting: true,
      cell: props => {
        const company = props.row.original;
        const companyName = company.name;
        const domain = company.__domain;
        const logoUrl = getLogoForCompany(company);
        const linkedInId = company.__linked_in_id;

        return (
          <div className="fixed-cell">
            <Button
              size="sm"
              variant="white"
              style={{ margin: '8px 0 0 2px', padding: '2px 4px', lineHeight: 1.2, float: 'right' }}
              onClick={() => onCompanySelect(company)}
            >
              <MdOutlineMenuOpen style={{ fontSize: 12 }} />
            </Button>
            <IdCardCell name={companyName} imageSrc={logoUrl} onNameClick={() => onCompanySelect(company)}>
              <IdCardIcon imageSrc="/img/chain.svg" href={`https://${domain}/`} alt="Website" />
              <IdCardIcon
                imageSrc="/img/linkedin.svg"
                href={`https://www.linkedin.com/company/${linkedInId}`}
                alt="Linkedin"
              />
              {schema
                .filter(
                  field =>
                    (field.fieldName === 'crm_sync_hubspot/external_id' ||
                      field.fieldName === 'crm_sync_salesforce/external_id') &&
                    company[field.fieldName]
                )
                .map(field => {
                  const parts = field.fieldSource.split('/');
                  const type = parts[1];
                  const integrationId = parts[2];
                  const externalId = company[field.fieldName];
                  return (
                    <IdCardIcon
                      key={integrationId}
                      imageSrc={`/img/logos/${type}_logo.svg`}
                      style={type === 'salesforce' ? { opacity: 0.5, maxWidth: 12, marginTop: 1 } : { opacity: 0.5 }}
                      alt={`${titleCase(type)} record`}
                      onClick={() => navigateToCRMRecord(integrationId, externalId)}
                    />
                  );
                })}
            </IdCardCell>
          </div>
        );
      }
    }
  ];
  // Map other columns from schema
  for (const fieldName of selectedColumns) {
    const column = schema.find(f => f.fieldName === fieldName);
    if (column) {
      if (column.fieldName === 'domain' || column.fieldName === 'name') continue;
      if (fieldName.includes('crm_sync_') && CRM_CELL_RENDERERS[fieldName.split('/')[1]]) {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: (props: CellContext<any, unknown>) => CRM_CELL_RENDERERS[fieldName.split('/')[1]](props.getValue()),
          enableSorting: true
        });
      } else if (fieldName.includes('year')) {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: getReactTableCellRendererForType(SchemaDataType.String), // Year is not formatted like a number
          enableSorting: true
        });
      } else {
        columns.push({
          header: column.displayName,
          accessorKey: column.fieldName,
          cell: getReactTableCellRendererForType(column.type),
          enableSorting: true
        });
      }
    }
  }
  return columns;
}
