import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Card, Form } from 'react-bootstrap';
import { CSVBoxButton } from '@csvbox/react';
import API from 'src/utils/API';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useAuth } from 'src/auth';
import ClientSelect from 'src/components/staff/ClientSelect';
import WideLayout from 'src/layouts/Wide/WideLayout';
import BatchTaskList from 'src/components/staff/BatchTasksList';

async function triggerEnrichmentJob(
  filePath: string,
  clientName: string,
  uploadName: string,
  skipCreateClientDestinations: boolean,
  forceOverrideExternalIds: boolean,
  filterBlacklistedDomains: boolean
) {
  return await API.post<any>(
    `/admin/enrichment/tasks`,
    { client: clientName },
    {
      filePath,
      clientName,
      uploadName,
      skipCreateClientDestinations,
      forceOverrideExternalIds,
      filterBlacklistedDomains
    }
  );
}

export default function EnrichmentUploadPage() {
  usePageTitle('Enrichment Upload');
  const formRef = useRef(null);
  const auth = useAuth();

  const [clientName, setClientName] = useState('');
  const [uploadName, setUploadName] = useState('');

  const canSubmit = clientName && uploadName;

  return (
    <WideLayout title={'Enrichment'} preheader="Staff admin">
      <Card>
        <Card.Header>Previous enrichment upload tasks</Card.Header>
        <Card.Body>
          <BatchTaskList taskType="ENRICHMENT_UPLOAD" />
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Start enrichment task</Card.Header>
        <Card.Body>
          <p>
            You can track progress of batches in{' '}
            <a href="https://redash.goodfit.io/queries/18" target="_blank">
              Redash
            </a>
            .
          </p>
          <Form ref={formRef}>
            <Form.Group className="mb-3" controlId="clientName">
              <Form.Label>Client name</Form.Label>
              <ClientSelect name="clientName" clientName={clientName} setClientName={setClientName} />
              <Form.Text className="text-muted">Which client?</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="uploadName">
              <Form.Label>Upload name</Form.Label>
              <Form.Control
                required
                name="uploadName"
                type="text"
                placeholder="Upload name"
                onChange={ev => setUploadName(ev.target.value)}
              />
              <Form.Text className="text-muted">Just used for tracking progress of this batch</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="skipCreateClientDestinations">
              <Form.Check name="skipCreateClientDestinations" inline />
              <Form.Label>Don't add new companies to client destinations (or enrichment destinations)</Form.Label>
              <Form.Text className="text-muted">Check to disable adding any new domains to this client</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="forceOverrideExternalIds">
              <Form.Check name="forceOverrideExternalIds" inline />
              <Form.Label>Force reassociation of CRM IDs for existing domains</Form.Label>
              <Form.Text className="text-muted">
                Check to force reassociate CRM IDS for existing records (can cause orphans)
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="filterBlacklistedDomains">
              <Form.Check name="filterBlacklistedDomains" defaultChecked inline />
              <Form.Label>Filter blocklisted domains</Form.Label>
              <Form.Text className="text-muted">
                Skip common email and other problematic domains (recommended)
              </Form.Text>
            </Form.Group>

            <CSVBoxButton
              licenseKey="1ZnTZDqQuzpwuwxPdrd6bcs059CNG3"
              user={{
                user_id: auth.user?.id,
                clientName,
                uploadName
              }}
              onImport={(result, data) => {
                const formElements = (formRef?.current as any)?.elements;
                const clientName = formElements.clientName.value; // Cant use clientName state here for some reason, perhaps its memorised?
                const uploadName = formElements.uploadName.value;
                const skipCreateClientDestinations = formElements.skipCreateClientDestinations.checked;
                const forceOverrideExternalIds = formElements.forceOverrideExternalIds.checked;
                const filterBlacklistedDomains = formElements.filterBlacklistedDomains.checked;
                if (result) {
                  toast.promise(
                    triggerEnrichmentJob(
                      data.raw_file,
                      clientName,
                      uploadName,
                      skipCreateClientDestinations,
                      forceOverrideExternalIds,
                      filterBlacklistedDomains
                    ),
                    {
                      loading: 'Starting enrichment job',
                      success: 'Enrichment job started',
                      error: 'Error starting enrichment job'
                    }
                  );
                } else {
                  alert('Sorry, CSVbox could not upload your file. ');
                }
              }}
              render={(launch, isLoading) => {
                return (
                  <Button size="sm" variant="primary" disabled={isLoading || !canSubmit} onClick={launch}>
                    Select CSV file for upload
                  </Button>
                );
              }}
            >
              Import
            </CSVBoxButton>
          </Form>
        </Card.Body>
      </Card>
    </WideLayout>
  );
}
