import toast from 'react-hot-toast';
import { Integration, isAnyJobRunning, startJob } from 'src/apis/integrations';
import { UserPermission, useClient } from 'src/auth';
import { useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { FiRefreshCw } from 'react-icons/fi';

import Button from 'src/components/LoadingButton.tsx';
import { combineFiltersWithSearch } from '../utils';
import startCase from 'lodash/startCase';

async function syncToast(integrationId: string, filters: any) {
  const toastId = toast.loading('Starting new sync job...');
  const result = (await startJob(integrationId, filters))?.started;
  toast.dismiss(toastId);
  if (result) {
    toast.success('Job started');
  } else {
    toast.error(`Couldn't start a job, please contact GoodFit team`);
  }
}

export default function SyncButton({
  integrations,
  filters,
  search
}: {
  integrations: Integration[];
  filters: any;
  search?: string;
}) {
  const { clientName, userPermissions } = useClient();
  const [showModal, setShowModal] = useState(false);
  const [integrationForModal, setIntegrationForModal] = useState<Integration>();

  const combinedFilters = combineFiltersWithSearch(search, filters);

  const onClick = async (integration: Integration) => {
    if (integration.id) {
      const result = await isAnyJobRunning(integration.id);
      if (result.canStartJob) {
        await syncToast(integration.id, combinedFilters);
      } else {
        if (result.cantStartJobReason === 'INTEGRATION_JOB_ALREADY_RUNNING') {
          setIntegrationForModal(integration);
          setShowModal(true);
        } else {
          toast.error(`Couldn't start a job, There is a problem with your connector configuration`);
        }
      }
    }
  };

  const isEnabled = integrations?.length > 0;
  const isVisible = userPermissions.includes(UserPermission.ADMIN); // Dont show if not ADMIN as API call will fail

  if (!isVisible) {
    return <></>;
  }

  return (
    <>
      <Dropdown className="ms-2">
        <Dropdown.Toggle size="sm" variant="primary" id="dropdown-basic" disabled={!isEnabled}>
          <FiRefreshCw style={{ marginRight: 8 }} /> Sync
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {integrations?.map(integration => (
            <Dropdown.Item
              key={integration.id}
              onClick={async () => {
                await onClick(integration);
              }}
            >
              {startCase(integration.integrationType.toLowerCase())}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sync Job Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          There is a sync job running for {startCase(integrationForModal?.integrationType?.toLowerCase())}, if you start
          a new job, it will be stopped. Please confirm if you wish to stop the job and run a new one. You can preview
          running jobs in <a href={`/app/${clientName}/settings/connector-logs`}>Connector Logs</a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              await syncToast(integrationForModal?.id as string, combinedFilters);
              setShowModal(false);
            }}
          >
            Start Sync
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
