import API from 'src/utils/API';
import { ScoringConfig, ScoringRule } from './types';
import { BreakingChanges } from '../types';

export async function loadScoringPreview(
  rules: ScoringRule[],
  order: string,
  domains: string[],
  scoreRange: null | number[]
): Promise<any> {
  return await API.post<any>(
    '/app/scoring/preview',
    {},
    {
      rules,
      order,
      domains,
      scoreRange
    }
  );
}

export async function loadScoringAnalysis(rules: ScoringRule[]): Promise<any> {
  return await API.post<any>(
    '/app/scoring/analyze',
    {},
    {
      rules
    }
  );
}

export async function loadScoringConfigs(): Promise<{ configs: ScoringConfig[] }> {
  return await API.get<any>('/app/scoring/configs', {});
}

export async function loadScoringConfig(scoringConfigId: string): Promise<ScoringConfig> {
  return await API.get<any>(`/app/scoring/configs/${scoringConfigId}`, {});
}

export async function validateScoringConfigDeletion(scoringConfigId: string): Promise<BreakingChanges> {
  return await API.post(`/app/scoring/configs/${scoringConfigId}/validate-delete`, {});
}

export async function deleteScoringConfig(scoringConfigId: string, skipValidation: boolean = false) {
  return await API.del(`/app/scoring/configs/${scoringConfigId}?skip-validation=${skipValidation}`, {});
}

export async function updateScoringConfig(scoringConfigId: string, update: Partial<ScoringConfig>) {
  return await API.put(`/app/scoring/configs/${scoringConfigId}`, {}, { ...update });
}

export async function createScoringConfig(payload: Partial<ScoringConfig>) {
  return await API.post(`/app/scoring/configs`, {}, { ...payload });
}
