import { Button, Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';

type DomainsModalProps = {
  domains: string[];
  show: boolean;
  onHide: () => void;
  onSave: (domains: string[]) => void;
};
export default function DomainsModal({ domains, show, onHide, onSave }: DomainsModalProps) {
  const [domainsEdit, setDomainsEdit] = useState<string[]>([]);

  const closeModal = () => {
    onHide();
  };

  const save = () => {
    onSave(domainsEdit);
    closeModal();
  };

  useEffect(() => {
    setDomainsEdit(domains || []);
  }, [domains]);

  return (
    <Modal show={show} onHide={closeModal} size={'lg'}>
      <Modal.Header closeButton>Domains</Modal.Header>
      <Modal.Body>
        <Form.Label>Target domains</Form.Label>
        <Form.Text>
          Please supply a list of domains to check. One per line. These domains should exist in your GoodFit dataset to
          work. The top limit is 1000 domains.
        </Form.Text>
        <Form.Control
          as="textarea"
          rows={20}
          style={{ fontFamily: 'monospace', fontSize: 12 }}
          defaultValue={domainsEdit.join('\n')}
          isInvalid={!(domainsEdit.length > 0 && domainsEdit.length <= 1000)}
          onChange={ev => setDomainsEdit(ev.target.value.split('\n').filter(s => !!s) as string[])}
        />
        <Form.Control.Feedback type="invalid">Please provide between 1 and 1000 domains</Form.Control.Feedback>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" size="sm" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={save}
          disabled={domainsEdit.length === 0 || domainsEdit.length > 1000}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
