import {
  ConfusionExample,
  PredictiveLabelsModel,
  PredictiveLabelsModelMode,
  TrainingExample
} from 'src/pages/predictive-labels/types.ts';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { addTrainingExamples, createVersion, trainVersion } from 'src/pages/predictive-labels/apis.ts';
import { appClientUrl } from 'src/utils/urls.ts';
import { Button, Dropdown, Row, Table } from 'react-bootstrap';

function DomainActions({
  labels,
  example,
  index,
  updateConfusionExample
}: {
  labels: string[];
  example: {
    domain: string;
    predictedLabel: string;
    action: 'correct' | 'incorrect' | undefined;
    correctLabel?: string;
  };
  index: number;
  updateConfusionExample: (index: number, action: 'correct' | 'incorrect' | undefined, correctLabel?: string) => void;
}) {
  return (
    <Row>
      <Dropdown drop={'down-centered'} className="d-flex justify-content-center">
        <Dropdown.Toggle
          size={'sm'}
          variant={example.action ? 'outline-info' : 'white'}
          id="select-label"
          className="w-75 text-truncate"
        >
          {example.correctLabel || 'Select label'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item className="text-truncate">
            <div
              key={'correct-label'}
              onClick={() => {
                updateConfusionExample(index, 'correct', example.predictedLabel);
              }}
            >
              {example.predictedLabel}
            </div>
          </Dropdown.Item>
          {labels
            .filter(label => label !== example.predictedLabel)
            .map((label, labelIndex) => (
              <Dropdown.Item
                className="text-truncate"
                key={labelIndex}
                onClick={() => {
                  updateConfusionExample(index, 'incorrect', label);
                }}
              >
                {label}
              </Dropdown.Item>
            ))}
          {example.action && (
            <Dropdown.Item>
              <div
                key={'clear-label'}
                onClick={() => {
                  updateConfusionExample(index, undefined, undefined);
                }}
              >
                Clear label
              </div>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Row>
  );
}

export function KeepTraining({
  model,
  confusionExamples,
  setConfusionExamples
}: {
  model: PredictiveLabelsModel;
  confusionExamples: ConfusionExample[];
  setConfusionExamples: (examples: ConfusionExample[]) => void;
}) {
  const navigate = useNavigate();

  const anyExampleChanged = useCallback(() => {
    return confusionExamples.some(example => example.action !== undefined);
  }, [confusionExamples]);

  const allChangedExamplesCorrect = useCallback(() => {
    return confusionExamples
      .filter(example => example.action !== undefined)
      .map(
        example =>
          example.action === 'correct' ||
          model?.mode === PredictiveLabelsModelMode.BINARY ||
          example.correctLabel !== undefined
      )
      .every(e => e);
  }, [confusionExamples, model?.mode]);

  const updateConfusionExample = useCallback(
    (index: number, action: 'correct' | 'incorrect' | undefined, correctLabel?: string) => {
      const newExamples = [...confusionExamples];
      newExamples[index] = { ...newExamples[index], action, correctLabel };
      setConfusionExamples(newExamples);
    },
    [confusionExamples, setConfusionExamples]
  );

  const nextIteration = useCallback(async () => {
    const modelId = model.id;
    const examplesToAdd: TrainingExample[] = confusionExamples
      .filter(example => example.action !== undefined)
      .map(
        example =>
          ({
            modelId,
            domain: example.domain,
            targetLabel: example.action === 'correct' ? example.predictedLabel : (example.correctLabel as string)
          }) as TrainingExample
      );
    const toastId = toast.loading('Preparing examples...');
    try {
      await addTrainingExamples(modelId as string, {
        trainingExamples: examplesToAdd
      });
      const version = (await createVersion(modelId as string)).version;
      await trainVersion(modelId as string, version.version);
      navigate(appClientUrl(`/predictive-labels/${modelId}/version/${version.version}`));
      toast.success('Model training started!', { id: toastId });
    } catch (e) {
      toast.error('Error preparing examples, contact engineering', { id: toastId });
      console.log(e);
    }
  }, [confusionExamples, model.id, navigate]);

  const canKeepIterating = useCallback(() => {
    return anyExampleChanged() && allChangedExamplesCorrect();
  }, [allChangedExamplesCorrect, anyExampleChanged]);

  return (
    <>
      <Table bordered>
        <thead>
          <tr>
            <th style={{ width: '33%' }}>Domain</th>
            <th style={{ width: '33%' }}>Predicted Label</th>
            <th style={{ width: '33%' }}>Correct Label</th>
          </tr>
        </thead>
        <tbody>
          {confusionExamples.map((example, index) => (
            <tr key={`tr-${index}`}>
              <td>
                <a href={`https://${example.domain}`} target="_blank">
                  {example.domain}
                </a>
              </td>
              <td>{example.predictedLabel}</td>
              <td>
                <DomainActions
                  labels={model?.labels.map(l => l.label) || []}
                  example={example}
                  index={index}
                  updateConfusionExample={updateConfusionExample}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end gap-2">
        <div>
          <Button size={'sm'} disabled={!canKeepIterating()} variant="primary" onClick={nextIteration}>
            Next iteration
          </Button>
        </div>
      </div>
    </>
  );
}
