import { useCallback, useMemo, useState } from 'react';
import { BasicField } from 'src/auth';
import { RecordType } from 'src/pages/data-directory/types';
import FieldInfoOffcanvas from './FieldInfoOffcanvas';

export function useFieldInfoOffcanvas() {
  const [isShowing, setIsShowing] = useState(false);
  const [field, setField] = useState<BasicField | null>(null);
  const [recordType, setRecordType] = useState<RecordType | null>(null);

  const showFieldInfoOffcanvas = useCallback((field: BasicField, recordType: RecordType) => {
    setField(field);
    setRecordType(recordType);
    setIsShowing(true);
  }, []);

  const hideFieldInfoOffcanvas = useCallback(() => {
    setIsShowing(false);
  }, []);

  return useMemo(
    () => ({
      showFieldInfoOffcanvas,
      hideFieldInfoOffcanvas,
      modalElement: (
        <FieldInfoOffcanvas show={isShowing} field={field} recordType={recordType} onHide={() => setIsShowing(false)} />
      )
    }),
    [hideFieldInfoOffcanvas, showFieldInfoOffcanvas, isShowing, field, recordType]
  );
}
