import { Button, Card, Col, Modal, Nav, Row, Spinner } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { ContactCriteria } from 'src/apis/clients/types.ts';
import { getContactsTotalResults, getContactsTotals } from 'src/pages/staff/clientConfigs/contacts/api.ts';
import { usePeriodic } from 'src/utils/usePeriodic.ts';
import { SampleContactsTab } from 'src/pages/staff/clientConfigs/contacts/SampleContactsTab.tsx';
import toast from 'react-hot-toast';
import { BreakdownTab } from 'src/pages/staff/clientConfigs/contacts/BreakdownTab.tsx';

type ContactCriteriaPreviewModalProps = {
  show: boolean;
  onHide: () => void;
  clientName?: string;
  contactCriteria?: ContactCriteria[];
};

const LoadingValue = ({ isLoading, value }: { isLoading: boolean; value: string }) => {
  return (
    <>
      {(isLoading && (
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )) || <div className="fs-1 mb-4">{value}</div>}
    </>
  );
};

export function ContactCriteriaPreviewModal({
  show,
  onHide,
  clientName,
  contactCriteria
}: ContactCriteriaPreviewModalProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('includedSamples');
  const [totals, setTotals] = useState<any>();
  const [totalsExecutionId, setTotalsExecutionId] = useState<string>();

  const closeModal = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setActiveTab(eventKey);
    }
  };

  useEffect(() => {
    if (clientName === undefined || contactCriteria === undefined) {
      return;
    }
    setIsLoading(true);
    getContactsTotals({
      clientName,
      contactCriteria
    })
      .then(executionId => {
        setTotalsExecutionId(executionId.executionId);
      })
      .catch(() => {
        toast.error('There has been a problem with your request, please contact eng');
        setIsLoading(false);
      });
  }, [clientName, contactCriteria]);

  usePeriodic(
    () => {
      if (totalsExecutionId) {
        getContactsTotalResults(totalsExecutionId).then(response => {
          if (response.status === 'SUCCEEDED') {
            setTotals(response.output);
            setIsLoading(false);
            setTotalsExecutionId(undefined);
          } else if (response.status === 'FAILED') {
            console.error('Failed to fetch totals');
            setIsLoading(false);
            setTotalsExecutionId(undefined);
          }
        });
      }
    },
    5000,
    [totalsExecutionId]
  );

  return (
    <Modal show={show} onHide={closeModal} size={'xl'}>
      <Modal.Header closeButton>
        Preview Contacts for: {contactCriteria?.length === 1 ? contactCriteria[0].persona : 'All personas'}
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-between">
          <Col xs={'2'}>
            <div className="text-muted py-2">Matched Contacts</div>
          </Col>
          <Col xs={'2'}>
            <div className="text-muted py-2">Companies with Contacts</div>
          </Col>
          <Col xs={'2'}>
            <div className="text-muted py-2">% of Companies with Contacts</div>
          </Col>
          <Col xs={'2'}>
            <div className="text-muted py-2">New Contacts</div>
          </Col>
          <Col xs={'2'}>
            <div className="text-muted py-2">Average Contacts Per Company</div>
          </Col>
          <Col xs={'2'}>
            <div className="text-muted py-2">Companies with more than 20 Contacts</div>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs={'2'}>
            <LoadingValue value={totals?.totalContacts} isLoading={isLoading} />
          </Col>
          <Col xs={'2'}>
            <LoadingValue value={totals?.companiesWithContacts} isLoading={isLoading} />
          </Col>
          <Col xs={'2'}>
            <LoadingValue
              value={`${((totals?.companiesWithContacts * 100) / totals?.totalCompanies)?.toFixed(2)}%`}
              isLoading={isLoading}
            />
          </Col>
          <Col xs={'2'}>
            <LoadingValue value={totals?.netNewContacts} isLoading={isLoading} />
          </Col>
          <Col xs={'2'}>
            <LoadingValue value={totals?.avgContactsPerCompany} isLoading={isLoading} />
          </Col>
          <Col xs={'2'}>
            <LoadingValue value={totals?.companiesWithMoreThan20Contacts} isLoading={isLoading} />
          </Col>
        </Row>
        <hr className={'my-4'} />
        <Card.Header>
          <Nav variant="tabs" activeKey={activeTab} onSelect={handleSelect}>
            <Nav.Item>
              <Nav.Link eventKey="includedSamples">Included Sample</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="excludedSamples">Excluded Sample</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="breakdownInclusion">Inclusion Keywords - Breakdown</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="breakdownExclusion">Exclusion Keywords - Breakdown</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="breakdownSeniority">Seniority - Breakdown</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body className={'py-4'}>
          {activeTab === 'includedSamples' && (
            <SampleContactsTab included={true} clientName={clientName} contactCriteria={contactCriteria} />
          )}
          {activeTab === 'excludedSamples' && (
            <SampleContactsTab included={false} clientName={clientName} contactCriteria={contactCriteria} />
          )}
          {activeTab === 'breakdownInclusion' && (
            <BreakdownTab clientName={clientName} contactCriteria={contactCriteria} breakdownBy={'inclusionKeywords'} />
          )}
          {activeTab === 'breakdownExclusion' && (
            <BreakdownTab clientName={clientName} contactCriteria={contactCriteria} breakdownBy={'exclusionKeywords'} />
          )}
          {activeTab === 'breakdownSeniority' && (
            <BreakdownTab clientName={clientName} contactCriteria={contactCriteria} breakdownBy={'seniority'} />
          )}
        </Card.Body>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
