import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { Segment } from '../types';
import { pluralize } from 'src/utils/string';
import { segmentPersonasHaveErrors } from '../utils';

type PersonaName = Required<Segment>['segmentPersonas'][number]['name'];

const PersonaFailedTooltipText: React.FC<{ segment: Segment }> = ({ segment }) => {
  const outOfCreditPersonaNames: PersonaName[] = [];
  const unknownErrorPersonaNames: PersonaName[] = [];

  segment.segmentPersonas?.forEach(sp => {
    if (sp.searchStatus === 'ERROR') {
      const isOutOfCreditsError = sp.errorReason === 'OUT_OF_CREDITS';

      isOutOfCreditsError
        ? outOfCreditPersonaNames.push(`"${sp.name}"`)
        : unknownErrorPersonaNames.push(`"${sp.name}"`);
    }
  });

  const hasOutOfCreditErrors = outOfCreditPersonaNames.length > 0;
  const hasUnknownErrors = unknownErrorPersonaNames.length > 0;

  if (!hasOutOfCreditErrors && !hasUnknownErrors) return null;

  const outOfCreditErrorHeading = [
    `The following ${pluralize(outOfCreditPersonaNames.length, 'persona', 'personas')}`,
    'failed due to a lack of credits:'
  ].join(' ');

  const unknownErrorHeading = [
    `The following ${pluralize(unknownErrorPersonaNames.length, 'persona', 'personas')}`,
    'failed for an unknown reason:'
  ].join(' ');

  return (
    <div style={{ textAlign: 'left' }}>
      {hasOutOfCreditErrors && (
        <>
          {outOfCreditErrorHeading}
          <ul className="mt-2 ps-4">
            {outOfCreditPersonaNames.map(name => (
              <li key={name}>{name}</li>
            ))}
          </ul>
        </>
      )}
      {hasUnknownErrors && (
        <>
          {unknownErrorHeading}
          <ul className="mt-2 ps-4">
            {unknownErrorPersonaNames.map(name => (
              <li key={name}>{name}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export const PersonaErrorStatusIcon: React.FC<{
  segment: Segment;
}> = ({ segment }) => {
  const personasHaveErrors = segmentPersonasHaveErrors(segment);

  if (!personasHaveErrors) return null;

  return (
    <TooltipWrapper
      placement="right"
      tooltipText={<PersonaFailedTooltipText segment={segment} />}
      customComponent={<FiAlertTriangle color="red" className="ms-2" />}
    />
  );
};
