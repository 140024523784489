import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { HiOutlineBeaker } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from 'src/components/PageLoader';
import Tag from 'src/components/Tag';
import { usePeriodic } from 'src/utils/usePeriodic.ts';
import { deployVersion, getModel, getVersion, getVersionEvaluation } from 'src/pages/predictive-labels/apis.ts';
import {
  ConfusionExample,
  PredictiveLabelsModel,
  PredictiveLabelsModelVersion,
  PredictiveLabelsModelVersionStatus
} from 'src/pages/predictive-labels/types.ts';
import EmptyMessage from 'src/components/EmptyMessage.tsx';
import { FiAlertTriangle, FiCheck, FiRepeat } from 'react-icons/fi';
import { appClientUrl } from 'src/utils/urls.ts';
import toast from 'react-hot-toast';
import NarrowLayout from 'src/layouts/Narrow';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import MislabellingsModal from 'src/pages/predictive-labels/components/MislabellingsModal.tsx';
import { KeepTraining } from 'src/pages/predictive-labels/components/KeepTraining.tsx';
import NavButton from 'src/components/NavButton.tsx';
import LabelScoresTable from './components/LabelScoresTable';
import { showModal } from 'src/utils/modals';
import { SamplePreviewModal } from './components/SamplePreviewModal';

export function PredictiveLabelsTrainingIterationPage() {
  usePageTitle('Predictive label training');
  const { modelId, version } = useParams();
  const navigate = useNavigate();

  const [model, setModel] = useState<PredictiveLabelsModel>();
  const [modelVersion, setModelVersion] = useState<PredictiveLabelsModelVersion>();
  const [evaluation, setEvaluation] = useState<any>();
  const [confusionExamples, setConfusionExamples] = useState<ConfusionExample[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [continueTraining, setContinueTraining] = useState(false);
  const [trainingFailed, setTrainingFailed] = useState(false);
  const [isMislabellingsModalOpen, setIsMislabellingsModalOpen] = useState(false);

  useEffect(() => {
    getModel(modelId as string).then(res => {
      setModel(res.model);
    });
  }, [modelId]);

  usePeriodic(
    async () => {
      if (!model) {
        return;
      }
      if (trainingFailed) {
        return;
      } else if (modelVersion?.status === PredictiveLabelsModelVersionStatus.TRAINED) {
        return;
      } else if (!modelVersion || modelVersion.status === PredictiveLabelsModelVersionStatus.TRAINING) {
        const got = await getVersion(modelId as string, Number(version));
        switch (got.version.status) {
          case PredictiveLabelsModelVersionStatus.TRAINING:
            setIsLoading(true);
            break;
          case PredictiveLabelsModelVersionStatus.TRAINED:
            setModelVersion(got.version);
            // eslint-disable-next-line no-case-declarations
            const evaluation = (await getVersionEvaluation(modelId as string, Number(version))).trainingResults;
            setEvaluation(evaluation);
            setConfusionExamples(
              evaluation.confusionExamples.sort((a: ConfusionExample, b: ConfusionExample) =>
                a.predictedLabel.localeCompare(b.predictedLabel)
              )
            );
            setTrainingFailed(false);
            setIsLoading(false);
            break;
          case PredictiveLabelsModelVersionStatus.ERROR:
            setTrainingFailed(true);
            setIsLoading(false);
            break;
        }
      }
    },
    2000,
    [model, trainingFailed, version, modelVersion]
  );

  const completeTraining = useCallback(async () => {
    const toastId = toast.loading('Trigger the model...');
    try {
      await deployVersion(modelId as string, Number(version));
      toast.success('Model is being deployed!', { id: toastId });
      navigate(appClientUrl(`/predictive-labels`));
    } catch (e) {
      toast.error('Error deploying, contact engineering', { id: toastId });
      console.log(e);
    }
  }, [modelId, navigate, version]);

  console.log(evaluation);
  const handleShowSampleClick = useCallback(() => {
    showModal(SamplePreviewModal, {
      examples: evaluation?.randomExamples ?? []
    });
  }, [evaluation]);

  if (isLoading) {
    return <PageLoader message="Training and evaluating model..." />;
  }
  if (trainingFailed) {
    return (
      <NarrowLayout
        title={
          <>
            Predictive Labels: Training
            <Tag color="#aaa">
              <HiOutlineBeaker /> Labs
            </Tag>
          </>
        }
      >
        <Card>
          <EmptyMessage
            style={{ height: 300 }}
            icon={<FiAlertTriangle className={'text-danger'} />}
            message={'Training failed, please consult engineering'}
          />
        </Card>
      </NarrowLayout>
    );
  }

  return (
    <NarrowLayout preheader={''} title={<>Predictive Labels: Training</>}>
      {evaluation?.commonMislabellings?.length > 0 && (
        <Alert variant={'warning'}>
          The model predicted different labels for a number of domains. Here are{' '}
          <a
            href="#"
            onClick={ev => {
              ev.preventDefault();
              setIsMislabellingsModalOpen(true);
            }}
          >
            {evaluation?.commonMislabellings?.length} frequent mislabellings
          </a>
          . Please review them and change label if necessary, or continue on iterating on the model, until you reach
          satisfactory result
        </Alert>
      )}
      {evaluation?.isRunningLowOnUniverseSet && (
        <Alert variant={'warning'}>
          This model may be a 'rare label', and we are running low on examples for one or more labels in the universe
          set. To help with this, upon next iteration, the universe set will be regenerated, which could take a couple
          of minutes extra. If you continue to see this message, you should consider if the model company criteria may
          be excluding one or more labels positive examples (including False/Other).
        </Alert>
      )}
      <Card>
        <Card.Header>
          <Col xs={'auto'}>
            <div>
              <div>
                <strong>Iteration {version}</strong>
              </div>
              <div className={'d-flex'}>
                Cross validation accuracy{' '}
                <strong className={'mx-2'}>
                  {evaluation?.scores?.avgWeightedScores?.['f1-score']
                    ? evaluation?.scores?.avgWeightedScores?.['f1-score']?.toFixed(2)
                    : // if it's older payload refer to it's old key
                      evaluation?.scores?.avgWeighedF1Score?.toFixed(2)}
                </strong>
                (Max:
                <strong>
                  {evaluation?.scores?.overfittedWeightedF1Score
                    ? evaluation?.scores?.overfittedWeightedF1Score?.toFixed(2)
                    : // if it's older payload refer to it's old key
                      evaluation?.scores?.overfittedWeighteedF1Score?.toFixed(2)}
                </strong>
                )
              </div>
            </div>
          </Col>
          <Col xs={'auto'}>
            <Button variant="white" size="sm" className="mx-2" onClick={handleShowSampleClick}>
              View sample
            </Button>
            <NavButton variant="secondary" size="sm" to={appClientUrl(`/predictive-labels/${modelId}`)}>
              Back to model
            </NavButton>
          </Col>
        </Card.Header>
        <Card.Body>
          {continueTraining ? (
            <KeepTraining
              confusionExamples={confusionExamples}
              setConfusionExamples={setConfusionExamples}
              model={model as PredictiveLabelsModel}
            />
          ) : (
            <>
              <Row>
                <LabelScoresTable evaluation={evaluation} />
              </Row>
              <Row>
                <a>
                  If you are satisfied with these scores, you can complete training and deploy the model. Otherwise
                  please keep training
                </a>
              </Row>

              <div className={'d-flex justify-content-evenly m-4'}>
                <div>
                  <Button size={'sm'} variant="primary" onClick={completeTraining}>
                    Complete training <FiCheck className={'ms-1'} />
                  </Button>
                </div>
                <div>
                  <Button size={'sm'} variant="primary" onClick={() => setContinueTraining(true)}>
                    Continue training <FiRepeat className={'ms-1'} />
                  </Button>
                </div>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
      <MislabellingsModal
        modelId={modelId as string}
        labels={model?.labels.map(l => l.label) || []}
        mislabellings={evaluation?.commonMislabellings || []}
        show={isMislabellingsModalOpen}
        onHide={() => setIsMislabellingsModalOpen(false)}
      />
    </NarrowLayout>
  );
}
