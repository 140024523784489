import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { Card, Dropdown, Form } from 'react-bootstrap';
import { PredictiveLabelsModel, PredictiveLabelsModelStatus } from 'src/pages/predictive-labels/types.ts';
import { useCallback, useEffect, useState } from 'react';
import NavButton from 'src/components/NavButton.tsx';
import { deleteModel, getModels } from 'src/pages/predictive-labels/apis.ts';
import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { titleCase } from 'src/utils/string.ts';
import TableGrid from 'src/components/Table/TableGrid.tsx';
import SimplePaginator from 'src/components/Table/SimplePaginator.tsx';
import { FiMoreVertical } from 'react-icons/fi';
import { showConfirm } from 'src/utils/modals.ts';
import { useClient } from 'src/auth';
import NarrowLayout from 'src/layouts/Narrow';
import VersionListModal from 'src/pages/predictive-labels/components/VersionListModal.tsx';
import { usePeriodic } from 'src/utils/usePeriodic.ts';
import Tag from 'src/components/Tag.tsx';
import toast from 'react-hot-toast';

const PAGE_SIZE = 10;

function copyModelConfigToClipboard(modelId: string) {
  const config = { model: modelId };
  const data = JSON.stringify(config, null, 2);
  navigator.clipboard.writeText(data);
  toast.success('Model config copied to clipboard');
}

export function PredictiveLabelsListPage() {
  usePageTitle('Predictive labels');
  const { clientName } = useClient();
  const [models, setModels] = useState<PredictiveLabelsModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showGlobal, setShowGlobal] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(0);
  const [openVersionsListFor, setOpenVersionsListFor] = useState<string | null>(null);

  const archiveModel = useCallback(
    (id: string) => {
      setIsLoading(true);
      deleteModel(id)
        .then(() => {
          setModels(models.filter(model => model.id !== id));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [models]
  );

  const reloadModels = useCallback(
    (showRefresh = false) => {
      showRefresh && setIsLoading(showRefresh);
      getModels(showGlobal ? 'global' : 'clientOnly', PAGE_SIZE, page * PAGE_SIZE)
        .then(response => {
          setModels(response.models);
          setNumPages(Math.ceil(response.modelsCnt / PAGE_SIZE));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [page, showGlobal]
  );

  usePeriodic(reloadModels, 5000, [page, showGlobal]);

  useEffect(() => {
    reloadModels(true);
  }, [page, reloadModels]);

  const table = useReactTable<PredictiveLabelsModel>({
    columns: [
      {
        header: 'Model',
        id: 'name',
        accessorFn: row => titleCase(row.name),
        enableSorting: false
      },
      {
        header: 'Model type',
        id: 'mode',
        accessorFn: row => (row.mode === 'BINARY' ? 'Binary' : 'Multi-class'),
        enableSorting: false
      },
      {
        header: 'Status',
        enableSorting: false,
        cell: c => (
          <Tag
            color={c.row.original.status === PredictiveLabelsModelStatus.DEPLOYED ? 'success' : 'info'}
            style={{ maxWidth: '100%' }}
          >
            {`${titleCase(c.row.original.status || '')} ${
              [PredictiveLabelsModelStatus.DEPLOYED, PredictiveLabelsModelStatus.DEPLOYING].includes(
                c.row.original.status as PredictiveLabelsModelStatus
              )
                ? `(version ${c.row.original.activeVersion})`
                : ''
            }`}
          </Tag>
        )
      },
      {
        header: 'Options',
        id: 'options',
        cell: c => (
          <div className={'d-flex justify-content-center'}>
            <NavButton size="sm" className={'mx-2'} to={`/app/${clientName}/predictive-labels/${c.row.original.id}`}>
              Edit
            </NavButton>
            <Dropdown className="position-static">
              <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                <FiMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setOpenVersionsListFor(c.row.original.id);
                  }}
                >
                  View versions
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={async () => {
                    const isSure = await showConfirm(
                      'This will archive the model. Are you sure?',
                      'Yes, delete',
                      'No, cancel',
                      'Confirm deletion'
                    );
                    if (isSure) archiveModel(c.row.original.id);
                  }}
                >
                  Archive
                </Dropdown.Item>
                <Dropdown.Item onClick={() => copyModelConfigToClipboard(c.row.original.id)}>
                  Copy data block config
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )
      }
    ],
    data: models,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });
  return (
    <NarrowLayout title="Predictive Labels" preheader="">
      <Card>
        <Card.Header>
          <h4 className="card-header-title">Models</h4>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Show global models"
            className="mx-2"
            onClick={() => {
              setPage(0);
              setShowGlobal(!showGlobal);
            }}
          />
          <NavButton size="sm" to={`/app/${clientName}/predictive-labels/new`}>
            New model
          </NavButton>
        </Card.Header>
        <Card.Body>
          <TableGrid
            table={table}
            isLoading={isLoading}
            emptyMessage="No models set"
            numLoadingRows={PAGE_SIZE}
            className="table-centered table-compact table-nowrap"
          />
          <div className="d-flex justify-content-end px-5 pb-3">
            <SimplePaginator pageIndex={page} totalPages={numPages} setPage={setPage} />
          </div>
        </Card.Body>
      </Card>
      {openVersionsListFor && (
        <VersionListModal
          modelId={openVersionsListFor}
          show={!!openVersionsListFor}
          onHide={() => {
            setOpenVersionsListFor(null);
            reloadModels(true);
          }}
        />
      )}
    </NarrowLayout>
  );
}
