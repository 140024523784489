import { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import LoadingButton from 'src/components/LoadingButton';

export function ModifyUserModal({ user, onHide, onModifyUser }: any) {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const currentIsAdmin = user?.userPermissions?.includes('ADMIN');
    setIsAdmin(currentIsAdmin ?? false);
  }, [user]);

  return (
    <Modal show={!!user} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Modify User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="form-group">
            <Form.Check
              checked={isAdmin}
              onChange={e => setIsAdmin(e.target.checked)}
              type="checkbox"
              id="allow-admin"
              label="Allow user to Administer this account"
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Cancel
        </Button>
        <LoadingButton
          variant="primary"
          onClick={async () => {
            const userPermissions: string[] = [];
            if (isAdmin) {
              userPermissions.push('ADMIN');
            }
            await onModifyUser(user.userId, userPermissions);
            onHide();
          }}
        >
          Modify User
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
}
