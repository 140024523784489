import NarrowLayout from 'src/layouts/Narrow';
import { Card } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { createIntegration, IntegrationType } from 'src/apis/integrations';
import { useSearchParams } from 'react-router-dom';

import { useClient } from 'src/auth';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import isEmpty from 'lodash/isEmpty';

export default function SalesforceCodeCapturePage({ title }: any) {
  const [searchParams] = useSearchParams();
  const { clientName } = useClient();
  const [code, setCode] = useState<string | null>(null);
  const [isSandbox, setIsSandbox] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  const sendAuthCode = useCallback(async (code: string, isSandbox: boolean) => {
    try {
      await createIntegration({
        connectorName: 'SALESFORCE',
        integrationType: IntegrationType.SALESFORCE,
        code,
        isSalesforceSandbox: isSandbox
      });
      setIsSuccess(true);
    } catch (e) {
      console.log(e);
      setIsSuccess(false);
    }
  }, []);

  usePageTitle(title);

  useEffect(() => {
    if (code != null && !isEmpty(code)) {
      sendAuthCode(code, isSandbox);
    }
  }, [code, isSandbox, sendAuthCode]);

  useEffect(() => {
    // we have to do it this way, otherwise for some wired reason, it sends the request twice
    const code = searchParams.get('code');
    setCode(code);
    setIsSandbox(searchParams.get('sandbox') === 'true');
  }, [searchParams]);

  return (
    <>
      <NarrowLayout title="Connectors" preheader="Settings">
        <Card>
          <Card.Body>
            {(isSuccess === null && 'Connecting your salesforce account...') ||
              (!isSuccess && (
                <>
                  Failed to connect your salesforce account. Please contact GoodFit team, or try again later.
                  <a href={`/app/${clientName}/settings/connectors`}>Go back to settings</a>
                </>
              )) ||
              (isSuccess && <p>Thank you for connecting your salesforce account!</p>)}
            {isSuccess && (
              <>
                <p>As a next step you can now map the fields, or go back to the settings and enable sync</p>
                <ul>
                  <li>
                    <a href={`/app/${clientName}/data-directory`}>Map fields</a>
                  </li>
                  <li>
                    <a href={`/app/${clientName}/settings/connectors`}>Go back to settings</a>
                  </li>
                </ul>
              </>
            )}
          </Card.Body>
        </Card>
      </NarrowLayout>
    </>
  );
}
