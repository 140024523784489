import { Button, Card, Col, Row } from 'react-bootstrap';
import { InputPair, NumberInput, TextInput } from 'src/components/Filters/Inputs/inputs.tsx';
import FieldValueSelect from 'src/components/Filters/Inputs/FieldValueSelect.tsx';
import { FiBarChart2, FiTrash2 } from 'react-icons/fi';

export function ContactCriteriaRow({
  criteria,
  index,
  removeRow,
  handleChange,
  isEditing,
  openPreview
}: {
  criteria: any;
  index: number;
  removeRow: (index: number) => void;
  handleChange: (index: number, key: string, value: any) => void;
  isEditing: boolean;
  openPreview: () => void;
}) {
  return (
    <Card className={'p-3 mb-2'}>
      <Row>
        <Col>
          <Row className={'m-2'}>
            <Col xs={2}>Persona</Col>
            <Col xs={10}>
              <div>
                <TextInput
                  handleOnChange={(value: any) => {
                    handleChange(index, 'persona', value);
                  }}
                  value={criteria.persona}
                  disabled={!isEditing}
                />
              </div>
            </Col>
          </Row>
          <Row className={'m-2'}>
            <Col xs={2}>Seniority</Col>
            <Col xs={10}>
              <FieldValueSelect
                onChange={value => {
                  handleChange(index, 'seniority', value);
                }}
                value={criteria.seniority}
                fieldName={'Seniority'}
                placeholder={'Seniority'}
                disabled={!isEditing}
                loadOptions={async () => [
                  {
                    value: 'individualContributor',
                    label: 'Individual contributor'
                  },
                  {
                    value: 'founder',
                    label: 'Founder'
                  },
                  {
                    value: 'owner',
                    label: 'Owner'
                  },
                  {
                    value: 'cSuite',
                    label: 'C-Suite'
                  },
                  {
                    value: 'vp',
                    label: 'VP'
                  },
                  {
                    value: 'director',
                    label: 'Director'
                  },
                  {
                    value: 'head',
                    label: 'Head'
                  },
                  {
                    value: 'manager',
                    label: 'Manager'
                  },
                  {
                    value: 'assistant',
                    label: 'Assistant'
                  },
                  {
                    value: 'lead',
                    label: 'Lead'
                  }
                ]}
                isMulti={true}
              />
            </Col>
          </Row>
          <Row className={'m-2'}>
            <Col xs={2}>Include</Col>
            <Col xs={10}>
              <FieldValueSelect
                onChange={value => {
                  handleChange(index, 'inclusionKeywords', value);
                }}
                value={criteria.inclusionKeywords}
                fieldName={'Inclusion keywords'}
                allowNew={true}
                placeholder={'Inclusion keywords'}
                disabled={!isEditing}
                loadOptions={async () => []}
                isMulti={true}
              />
            </Col>
          </Row>
          <Row className={'m-2'}>
            <Col xs={2}>Exclude</Col>
            <Col xs={10}>
              <FieldValueSelect
                onChange={value => {
                  handleChange(index, 'exclusionKeywords', value);
                }}
                value={criteria.exclusionKeywords}
                fieldName={'Exclusion keywords'}
                allowNew={true}
                placeholder={'Exclusion keywords'}
                disabled={!isEditing}
                loadOptions={async () => []}
                isMulti={true}
              />
            </Col>
          </Row>
          <Row className={'m-2'}>
            <Col xs={2}>Employee range</Col>
            <Col xs={10}>
              <InputPair
                handleOnChange={(value: any) => {
                  handleChange(index, 'employeeCountBounds', { min: value[0], max: value[1] });
                }}
                value={[criteria.employeeCountBounds.min, criteria.employeeCountBounds.max]}
                inputType={NumberInput}
                disabled={!isEditing}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={'auto'} className={'d-flex justify-content-center align-items-center'}>
          <div>
            <Button size={'sm'} variant={'primary'} className={'py-0 mx-1'} onClick={openPreview}>
              <FiBarChart2 size={'1rem'} className={'m-1'} />
            </Button>
          </div>
          <div>
            <Button
              size={'sm'}
              variant={'outline-danger'}
              className={'py-0 mx-1'}
              disabled={!isEditing}
              onClick={() => removeRow(index)}
            >
              <FiTrash2 color={'danger'} size={'1rem'} className={'m-1'} />
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
