import { RecordType } from 'src/pages/data-directory/types';
import API from 'src/utils/API';

export async function fetchFieldStats(
  recordType: RecordType,
  fieldName: string,
  valuesPageSize = 50,
  valuesPageIndex = 0
) {
  return await API.post(
    '/app/data/describe-field',
    {},
    {
      fieldName,
      recordType,
      valuesPageLimit: valuesPageSize,
      valuesOffset: valuesPageIndex * valuesPageSize
    }
  );
}

export async function fetchFieldOptions(fieldName: string, search: string | undefined) {
  return await API.get('/app/data/field-options', {
    fieldName: fieldName,
    search
  });
}
