import { Card } from 'react-bootstrap';
import { Toggle } from 'src/pages/staff/clientConfigs/components/Toggle.tsx';
import { SourcingComponent } from 'src/pages/staff/clientConfigs/sourcing/SourcingComponent.tsx';
import { ClientConfigErrors } from 'src/pages/staff/clientConfigs/validation/types.ts';

type SourcingTabProps = {
  schema: any;
  clientConfig: any;
  isEditing: boolean;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
  errors: ClientConfigErrors | null;
};

export function SourcingTab({
  schema,
  clientConfig,
  isEditing,
  handleChange,
  handleCommitChanges,
  handleChangeAndCommit,
  errors
}: SourcingTabProps) {
  return (
    <Card>
      <Card.Body>
        <div className="d-grid gap-2">
          <Toggle
            flag={clientConfig.autoSyncEnabled}
            label="Automatically rebuild dataset every 24 hours"
            isEditing={isEditing}
            onChange={(update: any) => {
              handleChange(update, 'autoSyncEnabled');
            }}
          />
          <SourcingComponent
            schema={schema}
            clientConfig={clientConfig}
            isEditing={isEditing}
            handleChange={handleChange}
            handleCommitChanges={handleCommitChanges}
            handleChangeAndCommit={handleChangeAndCommit}
            errors={errors}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
