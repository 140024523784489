import { Form } from 'react-bootstrap';
import { SegmentSettingsFormProps } from '.';
import { TooltipWrapper } from 'src/components/TooltipWrapper';

type DynamicSegmentToggleProps = Omit<SegmentSettingsFormProps, 'setIsSegmentNameValid' | 'isSegmentNameValid'>;

export const DynamicSegmentToggle: React.FC<DynamicSegmentToggleProps> = ({ updateSegment, segment }) => {
  const handleToggle: React.ChangeEventHandler<HTMLInputElement> = ({ target: { checked } }) => {
    updateSegment('isDynamic', checked);
  };

  return (
    <Form.Check
      type="switch"
      className="mb-4"
      onChange={handleToggle}
      id="toggle-dynamic-segment"
      defaultChecked={segment.isDynamic}
      label={
        <div>
          <span className="me-2">Dynamic Segment</span>
          <TooltipWrapper
            placement="right"
            tooltipText="Dynamic segments will automatically add or remove records as your dataset changes."
          />
        </div>
      }
    />
  );
};
