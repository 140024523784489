import { TalkTrack } from './types';
import API from 'src/utils/API.ts';

export async function createTalkTrack(create: Partial<TalkTrack>) {
  return await API.post<TalkTrack>(`/app/talk-tracks`, {}, create);
}

export async function loadTalkTracks(): Promise<TalkTrack[]> {
  return (await API.get<TalkTrack[]>('/app/talk-tracks', {}, {})).map(tt => ({
    ...tt,
    lastModifiedAt: new Date(tt.lastModifiedAt)
  }));
}

export async function updateTalkTrack(id: string, update: Partial<TalkTrack>) {
  return await API.put<TalkTrack>(`/app/talk-tracks/${id}`, {}, update);
}

export async function deleteTalkTrack(id: string) {
  return await API.del(`/app/talk-tracks/${id}`, {});
}
