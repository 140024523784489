import ErrorMessage from 'src/components/ErrorMessage';
import { useClient } from './hooks';
import { UserPermission } from './types';

interface RequirePermissionsProps {
  permissions: UserPermission[];
  children?: React.ReactNode;
}

export default function RequirePermissions({ permissions, children }: RequirePermissionsProps) {
  const { userPermissions } = useClient();

  if (!permissions.every(permission => userPermissions.includes(permission))) {
    return (
      <ErrorMessage message="You don't have permissions to view this page. Please contact your account administrator." />
    );
  }

  return children;
}
