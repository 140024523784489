import { useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { DEFAULT_THEME } from 'src/utils/react-select';
import { components } from 'react-select';
import trim from 'lodash/trim';

export type FieldValueSelectProps = {
  onChange: (value: any[]) => void;
  value: any[] | null;
  fieldName: string;
  allowNew?: boolean;
  style?: any;
  placeholder?: string;
  disabled?: boolean;
  loadOptions: (fieldName: string, search: string) => Promise<{ value: string; label: string }[]>;
  isMulti?: boolean;
};

export default function FieldValueSelect({
  onChange,
  value,
  fieldName,
  allowNew,
  placeholder,
  style,
  disabled,
  loadOptions,
  isMulti = true
}: FieldValueSelectProps) {
  const [isVisible, setIsVisible] = useState(true);

  let ComponentType = AsyncSelect;
  let extraProps = {};
  if (allowNew) {
    ComponentType = AsyncCreatableSelect;
    extraProps = {
      createOptionPosition: 'first',
      allowCreateWhileLoading: true,
      formatCreateLabel: (str: string) => `Use text "${str}"`
    };
  }

  // This is a bug fix.
  // If fieldName changes we need to unmount and remount the select component so it loads new options
  useEffect(() => {
    setIsVisible(false);
  }, [fieldName]);

  useEffect(() => {
    if (!isVisible) setIsVisible(true);
  }, [isVisible]);
  if (!isVisible) return <></>;

  return (
    <ComponentType
      isDisabled={disabled}
      isMulti={isMulti}
      cacheOptions
      placeholder={placeholder ?? 'Select...'}
      name={fieldName}
      id={`select_${fieldName}`}
      defaultOptions={true}
      value={isMulti ? (value ? value.map((v: any) => ({ value: v, label: v })) : []) : { value: value, label: value }}
      loadOptions={async search => {
        return await loadOptions(fieldName, search);
      }}
      onChange={(vs: any) => {
        onChange(isMulti ? vs?.map((v: any) => v.value) : vs.value);
      }}
      className="react-select-container"
      classNamePrefix="react-select"
      theme={DEFAULT_THEME}
      components={{
        Input: (props: any) => (
          <components.Input
            {...props}
            // We cant use oncopy as it only fires if they select text
            onKeyDown={event => {
              if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
                event.preventDefault();
                const text = isMulti ? value?.join('\n') : `${value}`;
                if (text) navigator.clipboard.writeText(text);
              }
            }}
            onPaste={event => {
              event.preventDefault();
              const clipboardData = event.nativeEvent.clipboardData?.getData('text/plain');
              console.log(clipboardData);
              if (clipboardData)
                onChange(
                  isMulti
                    ? clipboardData
                        ?.split('\n')
                        .map(trim)
                        .filter(v => !!v)
                        .slice(0, 100) // Limit 100 items to prevent madness
                    : [clipboardData]
                );
            }}
          />
        )
      }}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          minWidth: 300,
          width: '100%',
          ...(style ?? {})
        })
      }}
      {...extraProps}
    />
  );
}
