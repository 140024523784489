export type EmptyMessageProps = {
  message?: React.ReactNode;
  style?: any;
  subMessage?: React.ReactNode;
  actions?: React.ReactNode;
  icon?: React.ReactNode;
};

export default function EmptyMessage({ message, style, subMessage, actions, icon }: EmptyMessageProps) {
  return (
    <div
      style={{ ...{ minHeight: 100 }, ...style }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      {icon && (
        <div className="mb-3 text-muted" style={{ fontSize: 40 }}>
          {icon}
        </div>
      )}
      <div>{message ?? 'No results found'}</div>
      {subMessage && <div className="text-muted mt-3">{subMessage}</div>}
      {actions && <div className="my-3">{actions}</div>}
    </div>
  );
}
