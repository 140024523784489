import { useState } from 'react';
import { Alert, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import Button from 'src/components/LoadingButton';
import { useAuth } from '../../auth';
import goodFitLogo from '/img/goodfit-logo-text.svg';
import { FiLock } from 'react-icons/fi';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useNavigate } from 'react-router-dom';
import { validateNewPassword } from 'src/pages/auth/validatePassword.ts';

interface ResetPasswordStartProps {
  error?: string;
  isSubmitting?: boolean;
  onSubmit: (email: string, code: string, newPassword: string) => void;
}

function ResetPasswordStartForm({ onSubmit, error, isSubmitting }: ResetPasswordStartProps) {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState<string | undefined>(undefined);

  return (
    <div style={{ marginTop: -80 }}>
      <h1 className="display-4 text-center mb-3">
        <img className="navbar-brand-img" src={goodFitLogo} alt="GoodFit" style={{ height: 25 }} />
      </h1>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(email, code, password as string);
        }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="form-group">
          <Form.Label>Email</Form.Label>
          <Form.Control tabIndex={1} name="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="form-group">
          <Form.Label>Code</Form.Label>
          <Form.Control
            tabIndex={2}
            type={'text'}
            autoComplete="off"
            placeholder="Enter code from email"
            onChange={e => setCode(e.target.value)}
          />
        </div>
        <div className="form-group">
          <Form.Label>New password</Form.Label>
          <InputGroup className="input-group-merge">
            <Form.Control
              type="password"
              tabIndex={2}
              placeholder="Enter new password"
              onChange={e => setPassword(e.target.value)}
              isInvalid={password !== undefined && validateNewPassword(password)?.details?.length > 0}
            />
            <InputGroup.Text>
              <FiLock />
            </InputGroup.Text>
          </InputGroup>
          <div className={'invalid-feedback d-block'}>
            {password !== undefined && validateNewPassword(password)?.details[0]?.message}
          </div>
        </div>
        <Button size="lg" className="w-100 mb-3" type="submit" tabIndex={3} loading={isSubmitting}>
          Change password
        </Button>
      </form>
    </div>
  );
}

export default function ResetPasswordPage({ title }: any) {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  usePageTitle(title);

  const handlePasswordReset = async (email: string, code: string, newPassword: string) => {
    setIsSubmitting(true);
    setError('');
    try {
      await auth.logout();
      await auth.confirmResetPassword(email, code, newPassword);
      await auth.login(email, newPassword);
      navigate('/app');
    } catch (err: unknown) {
      console.error(err);
      setError('Error while changing password: ' + (err as Error).message);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="d-flex align-items-center min-vh-100 bg-auth">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={5} xl={4} className="">
            <ResetPasswordStartForm onSubmit={handlePasswordReset} error={error} isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
