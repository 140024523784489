import { Modal, Button } from 'react-bootstrap';
import { Segment, SegmentPersona } from 'src/pages/segments/types';

export function StartPersonaSearchModal({
  persona,
  segment,
  show,
  proceed,
  creditBalances
}: {
  persona: SegmentPersona;
  segment: Segment;
  show: boolean;
  creditBalances: Record<string, any>;
  proceed: (startSearch: boolean) => void;
}) {
  const maxCreditsCouldUse =
    (segment?.sizeCompanies ?? 0) * (persona.searchOptions?.numContactsRequiredPerCompany ?? 1);

  const contactCreditBalance = creditBalances?.CONTACT ?? null;
  const isOutOfCredits = contactCreditBalance !== null && contactCreditBalance <= 0;

  return (
    <Modal animation={true} show={show} onHide={() => proceed(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Start persona search</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isOutOfCredits ? (
          <>
            You don't have any contact credits remaining. Please{' '}
            <a href="mailto:cs@goodfit.io">contact customer support</a> to add more.
          </>
        ) : (
          <>
            {contactCreditBalance !== null ? (
              <>
                Starting a persona search will use up to <b>{maxCreditsCouldUse} contact credits</b>{' '}
                {persona.searchOptions.checkPhoneNumber && (
                  <>
                    and <b>{maxCreditsCouldUse} phone number credits</b>
                  </>
                )}{' '}
                depending on the people found during the search.
                <br />
                <br />
                Your current credit balance is <b>{contactCreditBalance}</b> credits.
                <br />
                <br />
                Do you want to proceed?
              </>
            ) : (
              <>Do you want to start a persona search?</>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="light" onClick={() => proceed(false)}>
          Cancel
        </Button>
        <Button size="sm" variant="primary" onClick={() => proceed(true)} disabled={isOutOfCredits}>
          Start persona search
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
