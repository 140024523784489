import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import { TalkTrack } from './types';
import { Button, Card } from 'react-bootstrap';
import TalkTrackList from './components/TalkTrackList';
import { useCallback, useState } from 'react';
import { deleteTalkTrack, loadTalkTracks } from './apis';
import { CollectionHelper } from 'src/utils/CollectionHelper';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { appClientUrl } from 'src/utils/urls.ts';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { showConfirm } from 'src/utils/modals.ts';
import { usePeriodic } from 'src/utils/usePeriodic';

const collectionHelper = new CollectionHelper<TalkTrack>('id');

export default function TalkTrackListPage({ title }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [talkTracks, setTalkTracks] = useState<TalkTrack[]>([]);
  const navigate = useNavigate();

  const handleRename = useCallback(
    (talkTrackId: string, newName: string) => {
      const update = { name: newName };
      setTalkTracks(collectionHelper.updateItem(talkTracks, talkTrackId, update));
    },
    [talkTracks]
  );

  const handleDelete = useCallback(
    (talkTrackId: string) => {
      toast
        .promise(
          (async () => {
            return deleteTalkTrack(talkTrackId);
          })(),
          {
            loading: 'Deleting...',
            success: 'Tag deleted',
            error: 'Error deleting tag'
          }
        )
        .then(() => {
          setTalkTracks(collectionHelper.deleteItem(talkTracks, talkTrackId));
        });
    },
    [talkTracks]
  );

  usePageTitle(title);

  // Load now and every 5s
  usePeriodic(async () => {
    await loadTalkTracks().then(talkTracks => {
      setIsLoading(false);
      setTalkTracks(talkTracks);
    });
  }, 5000);

  return (
    <NarrowLayout title="Tags" preheader="">
      <Card>
        <Card.Header>
          <h4 className="card-header-title">Tags</h4>
          <Button
            size="sm"
            onClick={async () => {
              const isSure = await showConfirm(
                'You can create a new tag by setting your filters and pressing the "Create new Tag" button on the companies page',
                'Go to companies page',
                'No, stay on this page',
                'Create tag'
              );
              isSure && navigate(appClientUrl(`#show-filters`));
            }}
          >
            + Add Tag
          </Button>
        </Card.Header>
        <Card.Body>
          <TalkTrackList
            talkTracks={talkTracks}
            isLoading={isLoading}
            handleDelete={handleDelete}
            handleRename={handleRename}
          />
        </Card.Body>
      </Card>
    </NarrowLayout>
  );
}
