import { formatPercent } from 'src/utils/number';

export default function ScoreBar({ title, percent, color }: any) {
  return (
    <>
      <div className="scorebar">
        <div className="scorebar-count" style={{ width: '40%' }}>
          {title}
        </div>
        <div className="scorebar-bar" style={{ width: '40%' }}>
          <div className="scorebar-bar-inner" style={{ backgroundColor: color, width: `${percent * 100}%` }}></div>
        </div>
        <div className="scorebar-count" style={{ width: '20%' }}>
          {formatPercent(percent, 1)}
        </div>
      </div>
    </>
  );
}
