import { createStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { defaultResourceLockState } from './defaultResourceLockState';
import { ResourceLock } from 'src/apis/resourceLocks/types';

export interface ResourceLockState {
  isResourceStale: boolean;
  isResourceLocked: boolean;
  activeResourceLock: ResourceLock | null;
}

interface ResourceLockActions {
  setIsResourceStale(isResourceStale: ResourceLockState['isResourceStale']): void;
  setIsResourceLocked(isResourceLocked: ResourceLockState['isResourceLocked']): void;
  setActiveResourceLock(activeResourceLock: ResourceLockState['activeResourceLock']): void;
  resetResourceLockState(): void;
}

type ResourceLockSlice = ResourceLockState & ResourceLockActions;

const createResourceLockStore = () =>
  createStore<ResourceLockSlice>(set => ({
    /** STATE */
    ...defaultResourceLockState,

    /** ACTIONS */
    setIsResourceStale: isResourceStale => set({ isResourceStale }),
    setIsResourceLocked: isResourceLocked => set({ isResourceLocked }),
    setActiveResourceLock: activeResourceLock => set({ activeResourceLock }),
    resetResourceLockState: () => set(defaultResourceLockState)
  }));

/** A map of slices allows us to create multiple addressable stores */
const resourceLockSlices = new Map<string, ReturnType<typeof createResourceLockStore>>();

/**
 * Resource Lock Store
 *
 * This store is designed to be used in combination with the `useResourceLock`
 * hook and the `ResourceLockModal`. You can choose to implement your own UI,
 * though your mileage may vary.
 *
 * **BASIC USAGE:**
 * ```tsx
 * const ViewResource = ({resourceId}: { resourceId: string }) => {
 *   useResourceLock({
 *     resourceType: LockableResource.ResourceType,
 *     resourceId
 *   });
 *
 *   const { isResourceLocked, isResourceStale } =
 *     useResourceLockStore(LockableResource.ResourceType, id, state => ({
 *       isResourceLocked: state.isResourceLocked,
 *       isResourceStale: state.isResourceStale
 *     }));
 *
 *   return (
 *       <ResourceEditView
 *         canEdit={!isResourceLocked && !isResourceStale}
 *       >
 *         This is my lockable resource
 *       </ResourceEditView>
 *     </>
 *   )
 * }
 * ```
 */
export const useResourceLockStore = <T>(
  resourceType: string,
  resourceId: string | null | undefined,
  selector: (state: ResourceLockSlice) => T
) => {
  const storeKey = `${resourceType}:${resourceId}`;

  if (!resourceLockSlices.has(storeKey)) resourceLockSlices.set(storeKey, createResourceLockStore());

  const slice = resourceLockSlices.get(storeKey)!;

  const store = useStoreWithEqualityFn(slice, selector, shallow);

  /**
   * Escape Hatch: This allows us to use the store in places where the
   * `resourceId` is conditional by simply returning static state
   */
  if (!resourceId) return selector(defaultResourceLockState as ResourceLockSlice);

  return store;
};
