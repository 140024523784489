import { OperatorSelectorProps } from 'react-querybuilder';
import { useMemo } from 'react';
import Select from 'react-select';
import { DEFAULT_THEME } from 'src/utils/react-select.ts';
import { isDisabledPath } from 'src/components/Filters/disabledPaths.ts';

export function OperatorSelector(f: OperatorSelectorProps) {
  const [options] = useMemo(() => {
    const options = f.options.map((o: any) => ({
      value: o.name,
      label: o.label
    }));
    return [options];
  }, [f]);
  // Dont show selector if no field selected
  if (!f.field) return <></>;
  return (
    <Select
      options={options}
      onChange={v => f.handleOnChange(v ? v.value : null)}
      isSearchable={true}
      value={options.find(o => o.value === f.value)}
      className="react-select-container"
      classNamePrefix="react-select"
      theme={DEFAULT_THEME}
      // isDisabled={f.disabled || disabled || isDisabledPath(f, 'operatorSelector')}
      isDisabled={f.disabled || isDisabledPath(f, 'operatorSelector')}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          minWidth: 200
        })
      }}
    />
  );
}
