import { ReactNode } from 'react';
import Header from 'src/components/Header';

interface LayoutProps {
  title: string | ReactNode;
  preTitle?: string;
  titleControls?: JSX.Element;
  children: React.ReactNode;
}

// Just add default header & controls, no additional grid changes from WideLayout or NarrowLayout
export default function LayoutTNG({ title, preTitle, children, titleControls }: LayoutProps) {
  return (
    <div className="layout">
      <Header className="mt-md-5">
        {titleControls && <div className="float-end">{titleControls}</div>}
        {preTitle && <Header.Pretitle>{preTitle}</Header.Pretitle>}
        <Header.Title className="text-truncate">{title}</Header.Title>
      </Header>
      {children}
    </div>
  );
}
