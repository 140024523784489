import API from 'src/utils/API';
import {
  AllContactsForCompany,
  PersonaCompaniesPreviewResult,
  PersonaPreviewResult,
  PersonaPreviewType,
  Segment,
  SegmentPersona,
  SegmentPersonaCriteria,
  SegmentPersonaSearchOptions
} from './types';
import { CompanyRecord, ContactRecord } from '../companies/types';
import { SortDefinition } from 'src/components/companiesList/CompaniesTable';

export async function setSegmentAutoSync(
  autoSyncStatus: boolean,
  integrationId: string,
  segmentId: string
): Promise<void> {
  return await API.post(`/app/segments/items/${segmentId}/autosync`, {}, { autoSyncStatus, integrationId });
}

export async function loadSegments(
  limit = 100,
  offset = 0,
  includeSegmentPersonas = false
): Promise<{ count: number; items: Segment[] }> {
  return await API.get(`/app/segments/items${includeSegmentPersonas ? '?include-personas=true' : ''}`, {
    limit,
    offset
  });
}

export async function loadSegment(segmentId: string, includeSegmentPersonas = false): Promise<{ item: Segment }> {
  return await API.get(`/app/segments/items/${segmentId}${includeSegmentPersonas ? '?include-personas=true' : ''}`);
}

export async function deleteSegment(segmentId: string) {
  return await API.del(`/app/segments/items/${segmentId}`);
}

export async function saveSegment(segmentId: string, updates: Partial<Segment>): Promise<{ item: Segment }> {
  return await API.put(`/app/segments/items/${segmentId}`, {}, updates);
}

export async function createSegment(body: Partial<Segment>): Promise<{ item: Segment }> {
  return await API.post(`/app/segments/items`, {}, body);
}

export async function validateSegmentName(body: {
  segmentId?: string;
  segmentName: string;
}): Promise<{ isValid: boolean }> {
  return await API.post(`/app/segments/validate-name`, {}, body);
}

export async function triggerSegmentUpdateProcess(segmentId: string) {
  return await API.post(`/app/segments/items/${segmentId}/start-update`);
}

export async function triggerSegmentRefresh(segmentId: string) {
  return await API.post(`/app/segments/items/${segmentId}/refresh`);
}

export async function fetchCompaniesPreview(segment: Partial<Segment>, limit: number) {
  return await API.post<{ items: CompanyRecord[]; count: number }>('/app/segments/preview', {}, { ...segment, limit });
}

type FetchAllCompanyContactsArgs = { segmentId: string; companyId: string; pageSize?: number; pageIndex?: number };
export async function fetchAllContactsForCompany({
  segmentId,
  companyId,
  pageSize = 100,
  pageIndex = 0
}: FetchAllCompanyContactsArgs): Promise<AllContactsForCompany> {
  const searchParams = new URLSearchParams({
    company_id: companyId,
    offset: pageIndex.toString(),
    limit: pageSize.toString()
  });

  return await API.get<AllContactsForCompany>(`/app/segments/items/${segmentId}/all-contacts?${searchParams}`);
}

export async function loadSegmentContacts(
  segment: Partial<Segment>,
  limit?: number,
  offset?: number,
  personaId?: string,
  sort?: SortDefinition,
  otherParams?: Record<string, any>
) {
  return await API.get<{ items: ContactRecord[]; count: number; countCompanies: number }>(
    `/app/segments/items/${segment.id}/contacts`,
    {
      limit: limit ?? 50,
      offset: offset ?? 0,
      persona: personaId ?? '',
      sortby: sort?.orderBy ?? '',
      sortdirection: sort?.orderDirection?.toLowerCase() ?? '',
      ...(otherParams ?? {})
    }
  );
}

export async function loadPersonasForSegment(segmentId: string): Promise<{ count: number; items: SegmentPersona[] }> {
  return await API.get(`/app/segments/items/${segmentId}/personas/items/`, { limit: 10000, offset: 0 });
}

export async function loadPersona(segmentId: string, personaId: string): Promise<{ item: SegmentPersona }> {
  return await API.get(`/app/segments/items/${segmentId}/personas/items/${personaId}`, {});
}

export async function createPersona(
  segmentId: string,
  body: Partial<SegmentPersona>
): Promise<{ item: SegmentPersona }> {
  return await API.post(`/app/segments/items/${segmentId}/personas/items`, {}, body);
}

export async function updatePersona(
  segmentId: string,
  personaId: string,
  body: Partial<SegmentPersona>
): Promise<{ item: SegmentPersona }> {
  return await API.put(`/app/segments/items/${segmentId}/personas/items/${personaId}`, {}, body);
}

export async function deletePersona(segmentId: string, personaId: string) {
  await API.del(`/app/segments/items/${segmentId}/personas/items/${personaId}`);
}
export async function triggerPersonaSearchProcess(
  segmentId: string,
  personaId: string,
  searchOptions: SegmentPersonaSearchOptions
) {
  return await API.post(`/app/segments/items/${segmentId}/personas/items/${personaId}/start-search`, {}, searchOptions);
}

export async function cancelPersonaSearchProcess(segmentId: string, personaId: string) {
  return await API.post(`/app/segments/items/${segmentId}/personas/items/${personaId}/stop-search`);
}

const DEFAULT_PERSONA_PREVIEW_PAGE_SIZE = 50;

export async function triggerPersonaPreview(
  segmentId: string,
  criteriaGroups: SegmentPersonaCriteria[],
  previewType: PersonaPreviewType = 'CONTACTS',
  pagination: { pageIndex: number; pageSize: number } = { pageIndex: 0, pageSize: DEFAULT_PERSONA_PREVIEW_PAGE_SIZE }
): Promise<{ token: string }> {
  const { pageSize: limit, pageIndex: offset } = pagination;

  return await API.post(
    `/app/segments/items/${segmentId}/personas/preview`,
    {},
    { criteriaGroups, previewType, offset, limit }
  );
}

export async function checkPersonaPreview(segmentId: string, token: string): Promise<{ status: string; output: any }> {
  return await API.get(`/app/segments/items/${segmentId}/personas/preview/${token}`, {});
}

export async function generatePersonaPreview(
  segmentId: string,
  criteriaGroups: SegmentPersonaCriteria[],
  previewType: PersonaPreviewType = 'CONTACTS',
  pagination: { pageIndex: number; pageSize: number } = { pageIndex: 0, pageSize: DEFAULT_PERSONA_PREVIEW_PAGE_SIZE }
): Promise<PersonaPreviewResult | PersonaCompaniesPreviewResult> {
  const { token } = await triggerPersonaPreview(segmentId, criteriaGroups, previewType, pagination);

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const pollResult = await checkPersonaPreview(segmentId, token);
    if (pollResult.status === 'SUCCEEDED') {
      return pollResult.output as PersonaPreviewResult | PersonaCompaniesPreviewResult;
    }
    if (pollResult.status === 'RUNNING') {
      await new Promise(resolve => setTimeout(resolve, 2000));
    } else {
      throw new Error('Preview errored');
    }
  }
}
