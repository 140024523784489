import { Button, Form, Modal } from 'react-bootstrap';
import React, { useCallback, useState } from 'react';
import { getClientConfig } from 'src/apis/clients/apis.ts';

export type ClientConfigModalProps = {
  show: boolean;
  onHide: () => void;
  onSubmit: (name: string) => Promise<void>;
};

export default function ClientConfigModal({ show, onHide, onSubmit }: ClientConfigModalProps) {
  const [name, setName] = useState<string>('');
  const [isValid, setIsValid] = useState(true);
  const [clientExists, setClientExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      getClientConfig(name)
        .then(() => {
          setClientExists(true);
          setIsLoading(false);
        })
        .catch(() => {
          setClientExists(false);
          onSubmit(name)
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
        });
    },
    [name, onSubmit]
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>New Client config</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="newClientConfig">
            <Form.Label>Name</Form.Label>
            <Form.Control
              onChange={event => {
                const value = event.target.value;
                const isValid = /^[a-z0-9_-]+$/.test(value);
                setIsValid(isValid);
                setClientExists(false);
                setName(value);
              }}
              required
              placeholder="Name"
              defaultValue={''}
              autoFocus={true}
              type="text"
              isInvalid={!isValid || clientExists}
            />
            {!isValid && name?.length > 0 && (
              <Form.Control.Feedback type="invalid">
                Name can only contain alphanumeric characters.
              </Form.Control.Feedback>
            )}
            {clientExists && (
              <Form.Control.Feedback type="invalid">
                Client with this name already exist, provide a new name, or edit existing config
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div style={{ textAlign: 'right' }} className={'mt-3'}>
            <Button size={'sm'} type="submit" disabled={!isValid || isLoading}>
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
