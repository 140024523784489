import API from 'src/utils/API.ts';
import {
  BalanceUpdate,
  CreditBalance,
  LimitBalance,
  LimitUpdate
} from 'src/pages/staff/clientConfigs/tabs/creditsAndLimits/types.ts';

export async function getCredits(clientName: string) {
  return await API.get<CreditBalance>(`/admin/credits/${clientName}`, {});
}

export async function updateCredits(clientName: string, update: BalanceUpdate) {
  return await API.post<CreditBalance>(`/admin/credits/${clientName}`, {}, update);
}

export async function getLimits(clientName: string) {
  return await API.get<LimitBalance>(`/admin/limits/${clientName}`, {});
}

export async function updateLimits(clientName: string, update: LimitUpdate) {
  return await API.post<LimitBalance>(`/admin/limits/${clientName}`, {}, update);
}
