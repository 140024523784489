import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FiLock } from 'react-icons/fi';

export const ButtonContent: React.FC<{ isUnlocking: boolean }> = ({ isUnlocking }) => {
  if (isUnlocking) return <Spinner size="sm" variant="light" className="unlock-spinner" />;

  return (
    <div className="d-flex justify-content-center align-items-center gap-2">
      <FiLock /> Unlock Resource
    </div>
  );
};
