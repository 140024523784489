import { ClientConfig } from 'src/apis/clients/types';
import API from 'src/utils/API';

export type SavedMarketEstimation = {
  id: string;
  config: Partial<ClientConfig>;
  requestedByUserEmail: string;
  count: number | null;
  createdAt: string;
  updatedAt: string;
};

export async function fetchSavedReports(
  filtering: { id: string; value: string }[],
  sorting: string,
  offset: number,
  limit: number
): Promise<{ items: SavedMarketEstimation[]; count: number }> {
  const requestParams: any = {
    sorting,
    limit,
    offset
  };

  filtering.forEach(f => (requestParams[f.id] = f.value));
  const result = await API.get<{ items: SavedMarketEstimation[]; count: number }>(
    `/admin/market-estimation/saved-reports/items`,
    requestParams
  );
  return result;
}

export async function saveReport(item: Partial<SavedMarketEstimation>): Promise<{ item: SavedMarketEstimation }> {
  const result = await API.post<{ item: SavedMarketEstimation }>(
    `/admin/market-estimation/saved-reports/items`,
    {},
    item
  );
  return result;
}

export async function deleteSavedReport(id: string): Promise<any> {
  const result = await API.del(`/admin/market-estimation/saved-reports/items/${encodeURIComponent(id)}`);
  return result;
}
