export enum FilterOperator {
  exists = 'exists',
  notExists = 'notExists',
  stringEqualOneOf = 'stringEqualOneOf',
  stringNotEqualOneOf = 'stringNotEqualOneOf',
  stringContainsOneOf = 'stringContainsOneOf',
  stringNotContainsOneOf = 'stringNotContainsOneOf',
  stringContains = 'stringContains',
  stringNotContains = 'stringNotContains',
  equalTo = 'equalTo',
  notEqualTo = 'notEqualTo',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  between = 'between',
  booleanTrue = 'booleanTrue',
  booleanFalse = 'booleanFalse',
  arrayContainsAnyOf = 'arrayContainsAnyOf',
  arrayNotContainsAnyOf = 'arrayNotContainsAnyOf',
  arrayLengthGt = 'arrayLengthGt',
  arrayLengthGte = 'arrayLengthGte',
  arrayLengthLt = 'arrayLengthLt',
  arrayLengthLte = 'arrayLengthLte',
  dateEqual = 'dateEqual',
  dateNotEqual = 'dateNotEqual',
  dateSince = 'dateSince',
  dateBefore = 'dateBefore',
  dateBetween = 'dateBetween',
  dateSinceRelative = 'dateSinceRelative',
  dateBeforeRelative = 'dateBeforeRelative'
}
