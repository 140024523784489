import { useState, useEffect, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { listCsvExports } from 'src/apis/csvExports/apis';
import { CsvExportItem } from 'src/apis/csvExports/types';
import NarrowLayout from 'src/layouts/Narrow/NarrowLayout';
import CsvExportList from './components/CsvExportList';
import { usePageTitle } from 'src/utils/usePageTitle.ts';

let reloadTimeout = 0;

export default function ExportListPage({ title }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [exportItems, setExportItems] = useState<CsvExportItem[]>([]);

  const reloadExportItems = useCallback(() => {
    listCsvExports().then(response => {
      setIsLoading(false);
      setExportItems(response.exports);
      if (reloadTimeout) clearTimeout(reloadTimeout);
      reloadTimeout = setTimeout(reloadExportItems, 5000);
    });
  }, []);

  usePageTitle(title);

  useEffect(() => {
    reloadExportItems();
    return () => {
      if (reloadTimeout) clearTimeout(reloadTimeout);
    };
  }, [reloadExportItems]);

  return (
    <NarrowLayout title="Exports" preheader="">
      <Card>
        <Card.Body>
          <CsvExportList exportItems={exportItems} isLoading={isLoading} />
        </Card.Body>
      </Card>
    </NarrowLayout>
  );
}
