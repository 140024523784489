import API from 'src/utils/API.ts';
import {
  PredictiveLabelsModel,
  PredictiveLabelsModelVersion,
  PredictiveLabelsModelVersionResponse,
  TrainingExample
} from 'src/pages/predictive-labels/types.ts';

export async function getModels(
  type: 'global' | 'clientOnly',
  limit: number,
  offset: number
): Promise<{ models: PredictiveLabelsModel[]; modelsCnt: number }> {
  return await API.get<{ models: PredictiveLabelsModel[]; modelsCnt: number }>(`/app/predictive-labels`, {
    type,
    limit,
    offset
  });
}

export async function createModel(request: PredictiveLabelsModel) {
  return await API.post<{ model: PredictiveLabelsModel }>(`/app/predictive-labels`, {}, request);
}

export async function updateModel(request: any) {
  return await API.put<PredictiveLabelsModel>(`/app/predictive-labels/${request.id}`, {}, request);
}

export async function regenerateUniverseSet(id: string) {
  return await API.post<PredictiveLabelsModel>(`/app/predictive-labels/${id}/regenerate-universe-set`, {}, {});
}

export async function getModel(id: string): Promise<{ model: PredictiveLabelsModel }> {
  return await API.get<{ model: PredictiveLabelsModel }>(`/app/predictive-labels/${id}`, {});
}

export async function deleteModel(id: string) {
  return await API.del(`/app/predictive-labels/${id}`, {});
}

export async function addTrainingExamples(
  modelId: string,
  request: {
    trainingExamples: TrainingExample[];
  }
): Promise<{
  trainingExamples: TrainingExample[];
}> {
  return await API.post<{
    trainingExamples: TrainingExample[];
  }>(`/app/predictive-labels/${modelId}/training`, {}, request);
}

export async function getTrainingExamples(modelId: string): Promise<{ trainingExamples: TrainingExample[] }> {
  return await API.get<{ trainingExamples: TrainingExample[] }>(`/app/predictive-labels/${modelId}/training`, {});
}

export async function checkTrainingExamples(request: { trainingExamples: TrainingExample[] }) {
  return await API.post<{
    trainingExamples: TrainingExample[];
  }>(`/app/predictive-labels/xxx/training-check`, {}, request);
}

// overrides
export async function upsertTrainingExamples(
  modelId: string,
  request: {
    trainingExamples: TrainingExample[];
  }
): Promise<{
  trainingExamples: TrainingExample[];
}> {
  return await API.put<{
    trainingExamples: TrainingExample[];
  }>(`/app/predictive-labels/${modelId}/training`, {}, request);
}

export async function overrideTrainingExampleLabel(
  modelId: string,
  domain: string,
  targetLabel?: string,
  doubleVerified?: boolean
): Promise<{
  trainingExamples: TrainingExample[];
}> {
  return await API.patch<{
    trainingExamples: TrainingExample[];
  }>(`/app/predictive-labels/${modelId}/training`, {}, { domain, targetLabel, doubleVerified });
}

export async function getVersions(modelId: string): Promise<PredictiveLabelsModelVersionResponse> {
  return await API.get<PredictiveLabelsModelVersionResponse>(`/app/predictive-labels/${modelId}/versions`, {});
}

export async function getVersion(
  modelId: string,
  version: number
): Promise<{
  version: PredictiveLabelsModelVersion;
}> {
  return await API.get<{
    version: PredictiveLabelsModelVersion;
  }>(`/app/predictive-labels/${modelId}/versions/${version}`, {});
}

export async function createVersion(modelId: string): Promise<{
  version: PredictiveLabelsModelVersion;
}> {
  return await API.post<{
    version: PredictiveLabelsModelVersion;
  }>(`/app/predictive-labels/${modelId}/versions`, {});
}

export async function deployVersion(
  modelId: string,
  version: number
): Promise<{
  version: PredictiveLabelsModelVersion;
}> {
  return await API.post<{
    version: PredictiveLabelsModelVersion;
  }>(`/app/predictive-labels/${modelId}/versions/${version}/deploy`, {});
}

export async function trainVersion(
  modelId: string,
  version: number
): Promise<{
  version: PredictiveLabelsModelVersion;
}> {
  return await API.post<{
    version: PredictiveLabelsModelVersion;
  }>(`/app/predictive-labels/${modelId}/versions/${version}/train`, {});
}

export async function getVersionEvaluation(
  modelId: string,
  version: number
): Promise<{
  trainingResults: any;
}> {
  return await API.get<{
    trainingResults: any;
  }>(`/app/predictive-labels/${modelId}/versions/${version}/evaluation`, {});
}
