import { Navigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { usePageTitle } from 'src/utils/usePageTitle.ts';

export default function NavigateBackToConnectorSettings({ title }: any) {
  const [searchParams] = useSearchParams();
  const [state] = useState<any>(() => {
    const stateFromUrl = searchParams.get('state');
    return stateFromUrl ? JSON.parse(stateFromUrl) : null;
  });

  usePageTitle(title);

  return (
    (state?.client && (
      <Navigate
        to={{
          pathname: '/app/' + state.client + `/settings/connectors/${state.connectorType}`,
          search: `?code=${searchParams.get('code')}${state.sandbox === 'true' ? `&sandbox=true` : ''}`
        }}
        replace
      />
    )) || <></>
  );
}
