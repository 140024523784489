import Tag from 'src/components/Tag';
import { Col, Row } from 'react-bootstrap';

type ContactCriteriaProps = {
  contactCriteria?: any[];
};

export default function ContactCriteria({ contactCriteria }: ContactCriteriaProps) {
  return (
    <>
      {contactCriteria?.map((criteria, index) => {
        return (
          <Row xs={'auto'} className={'mb-4'} key={`contact-criteria-${index}`}>
            <Col>
              <div className={'mb-1'}>
                Persona: <strong>{criteria.persona}</strong> for{' '}
                {(criteria.employeeCountBounds.min === 0 && criteria.employeeCountBounds.max > 10000 && (
                  <strong> all companies</strong>
                )) || (
                  <>
                    companies between <strong>{criteria.employeeCountBounds.min}</strong> and{' '}
                    <strong>{criteria.employeeCountBounds.max}</strong> employees
                  </>
                )}
              </div>
              <Row xs={'auto'}>
                <div className={'mb-1'}>
                  Seniority:{' '}
                  {criteria.seniority.map((seniority: string) => (
                    <Tag key={seniority} color="info" style={{ margin: '0px 5px 0px 5px' }}>
                      {seniority}
                    </Tag>
                  ))}
                </div>
              </Row>
              <Row xs={'auto'}>
                <div className={'mb-1'}>
                  Included Keywords:{' '}
                  {criteria.inclusionKeywords.map((keyword: string) => (
                    <Tag key={keyword} color="success" style={{ margin: '0px 5px 0px 5px' }}>
                      {keyword}
                    </Tag>
                  ))}
                </div>
              </Row>
              <Row xs={'auto'}>
                <div className={'mb-1'}>
                  Excluded Keywords:{' '}
                  {criteria.exclusionKeywords.map((keyword: string) => (
                    <Tag key={keyword} color="danger" style={{ margin: '0px 5px 0px 5px' }}>
                      {keyword}
                    </Tag>
                  ))}
                </div>
              </Row>
            </Col>
          </Row>
        );
      })}
    </>
  );
}
