import React from 'react';
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap';
import { FiInfo } from 'react-icons/fi';

interface IconTooltipProps {
  /** The text to display in the tooltip */
  tooltipText: React.ReactNode;
  /** Use this to override the default wrapped component (icon: ⓘ) */
  customComponent?: React.ReactNode;
  /** Helpful where you want your label text to also trigger the tooltip */
  iconLabelText?: React.ReactNode;
}

/**
 * DRY Component: `<FiInfo />` icon wrapped with a Tooltip.
 *
 * **USAGE:**
 *
 * **Basic**
 * ```tsx
 * <TooltipWrapper tooltipText="This text will appear in the tooltip body" />
 * ```
 *
 * **With label**
 * ```tsx
 * <TooltipWrapper
 *   tooltipText="Helpful information"
 *   iconLabelText="This will adorn the icon"
 * />
 * ```
 *
 * **Custom Icon**
 * ```tsx
 * import { FiAlertTriangle } from 'react-icons/fi';
 *
 * <TooltipWrapper
 *   tooltipText="Caution!"
 *   customComponent={<FiAlertTriangle className="ms-2" color="yellow" />}
 * />
 * ```
 */
export const TooltipWrapper: React.FC<IconTooltipProps & Omit<OverlayTriggerProps, 'overlay' | 'children'>> = ({
  iconLabelText,
  tooltipText,
  customComponent,
  ...overlayTriggerProps
}) => (
  <OverlayTrigger
    {...{
      placement: 'top',
      overlay: props => <Tooltip {...props}>{tooltipText}</Tooltip>,
      ...overlayTriggerProps
    }}
  >
    <span style={{ lineHeight: 0 }}>
      {iconLabelText}
      {customComponent ?? <FiInfo className={iconLabelText ? 'ms-2' : ''} />}
    </span>
  </OverlayTrigger>
);
