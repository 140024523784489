import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useResourceLockStore } from '../../resourceLockStore';
import { StaleResourceModalContent } from './components/StaleResourceModalContent';
import { LockedResourceModalContent } from './components/LockedResourceModalContent';
import { LockableResource } from 'src/apis/resourceLocks/types';
import { useResourceLock } from 'src/components/ResourceLock/hooks/useResourceLock';

interface ResourceLockModalProps {
  resourceType: LockableResource;
  resourceId: string;
}

/** @see [ResourceLockModal readme](../../readme.md) */
export const ResourceLockModal: React.FC<ResourceLockModalProps> = ({ resourceType, resourceId }) => {
  useResourceLock({ resourceType, resourceId });

  const { activeResourceLock, isResourceLocked, isResourceStale } = useResourceLockStore(
    resourceType,
    resourceId,
    state => ({
      activeResourceLock: state.activeResourceLock,
      isResourceStale: state.isResourceStale,
      isResourceLocked: state.isResourceLocked
    })
  );

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(Boolean(activeResourceLock) && (isResourceLocked || isResourceStale));
  }, [isResourceLocked, isResourceStale, activeResourceLock]);

  if (!activeResourceLock) return null;

  const handleClose = (): void => setIsVisible(false);

  return (
    <Modal animation show={isVisible}>
      <StaleResourceModalContent resourceLock={activeResourceLock} handleClose={handleClose} />
      <LockedResourceModalContent resourceLock={activeResourceLock} handleClose={handleClose} />
    </Modal>
  );
};
