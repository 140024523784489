import { Button, Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';

type LabelModalProps = {
  label?: string;
  description?: string;
  show: boolean;
  onHide: () => void;
  onSave: (oldLabel: string, newLabel: string, description: string) => void;
};
export default function LabelModal({ label, description, show, onHide, onSave }: LabelModalProps) {
  const [labelEdit, setLabelEdit] = useState<string>();
  const [descriptionEdit, setDescriptionEdit] = useState(description);

  const closeModal = () => {
    onHide();
  };

  const save = () => {
    // @ts-expect-error just skip it
    onSave(label, labelEdit, descriptionEdit);
    closeModal();
  };

  useEffect(() => {
    setLabelEdit(label);
    setDescriptionEdit(description);
  }, [label, description]);

  return (
    <Modal show={show} onHide={closeModal} size={'lg'}>
      <Modal.Header closeButton>Label</Modal.Header>
      <Modal.Body>
        <Form.Label>Name</Form.Label>
        <Form.Control
          defaultValue={label}
          disabled={['Other', 'True', 'False'].includes(label as string)}
          onChange={ev => setLabelEdit(ev.target.value)}
        />
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          style={{ fontFamily: 'monospace', fontSize: 12 }}
          defaultValue={description}
          onChange={ev => setDescriptionEdit(ev.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={save}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
