import { ClientConfig } from 'src/apis/clients/types.ts';
import { useState } from 'react';
import { Toggle } from 'src/pages/staff/clientConfigs/components/Toggle.tsx';
import JsonEditor from 'src/components/JsonEditor/JsonEditor.tsx';
import { FieldDefinitionsTable } from './FieldDefinitionsTable.tsx';
import { FieldDefinitionsModal } from './FieldDefinitionsModal.tsx';
import { Alert, Button, Row } from 'react-bootstrap';
import { FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';
import FieldDefinitionsPasteFromSheet from 'src/pages/staff/clientConfigs/components/FieldDefinitionsPasteModal.tsx';
import { ClientConfigErrors } from 'src/pages/staff/clientConfigs/validation/types.ts';

export function FieldDefinitions({
  isEditing,
  schema,
  clientConfig,
  fieldMapping,
  handleChange,
  handleCommitChanges,
  handleChangeAndCommit,
  errors
}: {
  isEditing: boolean;
  schema: any;
  clientConfig: ClientConfig;
  fieldMapping: FieldMapping[];
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
  errors: ClientConfigErrors | null;
}) {
  const [editAsJSON, setEditAsJSON] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPasteFieldDefsModal, setShowPasteFieldDefsModal] = useState(false);

  const addFields = (newFields: any[]) => {
    const newFieldDefinitions = [...clientConfig.fieldDefinitions, ...newFields];
    handleChangeAndCommit(newFieldDefinitions, 'fieldDefinitions');
  };

  const handleExternalNameChange = (index: number, value: string) => {
    const newFieldDefinitions = [...clientConfig.fieldDefinitions];
    newFieldDefinitions[index].externalName = value;
    handleChangeAndCommit(newFieldDefinitions, 'fieldDefinitions');
  };

  const handleRemoveField = (index: number) => {
    const newFieldDefinitions = [...clientConfig.fieldDefinitions];
    newFieldDefinitions.splice(index, 1);
    handleChangeAndCommit(newFieldDefinitions, 'fieldDefinitions');
  };

  const handleSetFieldsFromSheet = (newFieldDefinitions: any[]) => {
    handleChangeAndCommit(newFieldDefinitions, 'fieldDefinitions');
  };

  return (
    <div className={'mb-3'}>
      <div className={'d-flex justify-content-between'}>
        <div>
          <Row>
            <h3>Field Definitions</h3>
          </Row>
        </div>
        <div className="d-flex align-items-baseline" style={{ gap: 10 }}>
          {isEditing && (
            <>
              <Button size={'sm'} onClick={() => setModalOpen(true)}>
                Add fields
              </Button>
              <Button
                size="sm"
                variant="white"
                style={{ marginBottom: 4 }}
                onClick={() => setShowPasteFieldDefsModal(true)}
              >
                Paste from sheet
              </Button>
            </>
          )}
          <Toggle
            flag={editAsJSON}
            label={'As JSON'}
            isEditing={true}
            onChange={() => {
              handleCommitChanges && handleCommitChanges();
              setEditAsJSON(!editAsJSON);
            }}
          />
        </div>
      </div>
      <Alert id="field-definitions-warning">
        Please be aware that if you remove or rename a field, you could break integrations, talk tracks, scoring or
        segments.
        <br />
        Please check and unmap the field first.
      </Alert>
      {(editAsJSON && (
        <JsonEditor
          schema={{
            items: {
              $ref: '#/definitions/FieldMappingDefinition'
            },
            type: 'array'
          }}
          referenceSchema={schema}
          json={clientConfig.fieldDefinitions}
          isEditing={isEditing}
          onChange={(update: any) => {
            handleChange(update, 'fieldDefinitions');
          }}
        />
      )) || (
        <FieldDefinitionsTable
          clientConfig={clientConfig}
          isEditing={isEditing}
          handleExternalNameChange={handleExternalNameChange}
          removeField={handleRemoveField}
          errors={errors}
        />
      )}
      <FieldDefinitionsModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        fieldDefinitions={clientConfig.fieldDefinitions}
        fieldMapping={fieldMapping}
        addFields={addFields}
      />
      <FieldDefinitionsPasteFromSheet
        show={showPasteFieldDefsModal}
        onHide={() => setShowPasteFieldDefsModal(false)}
        fieldDefinitions={clientConfig.fieldDefinitions}
        updateFieldDefinitions={handleSetFieldsFromSheet}
      />
    </div>
  );
}
