export type ScoringAnalysisReportFormState = {
  name: string;
  goodDomains: string[];
  otherDomains: string[];
  mode: string;
};

export const DEFAULT_STATE: ScoringAnalysisReportFormState = {
  name: '',
  goodDomains: [],
  otherDomains: [],
  mode: 'vsAll'
};

export function canSubmitForm(state: ScoringAnalysisReportFormState) {
  let canSubmit = !!state.name && state.goodDomains.length > 0;
  if (state.mode === 'vsOther' && state.otherDomains.length === 0) canSubmit = false;
  return canSubmit;
}
