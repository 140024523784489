import sortBy from 'lodash/sortBy';
import ReactSelect from 'react-select';
import { DEFAULT_THEME } from 'src/utils/react-select';
import { SelectOption, SelectValue, applyOptionGrouping } from 'src/utils/selectOptions';

export type SelectProps = {
  value: SelectValue | undefined;
  options: SelectOption[];
  onChange: (value: SelectValue) => void;
  styles: Record<string, any>;
  components: Record<string, any>;
  menuWidth?: number;
  sortOptions?: boolean;
  disabled: boolean;
};

/**
 * An implementation of a Select box that has some of the capabilities on the AntD one
 * Will expand this as we need more features
 */
export default function Select({
  value,
  options,
  onChange,
  menuWidth,
  sortOptions,
  styles: extraStyles,
  components,
  disabled
}: SelectProps) {
  // If the options have not loaded yet, and we have a value set, temporarily set an option...
  // so its state is not lost
  let actualOptions = [...options];
  let selectedOption = actualOptions.find(o => o.value === value);
  if (value && !selectedOption) {
    selectedOption = {
      value,
      label: value
    };
    actualOptions.push(selectedOption);
  }

  if (sortOptions) {
    actualOptions = sortBy(actualOptions, 'label');
  }

  const groupedOptions = applyOptionGrouping(actualOptions);

  return (
    <ReactSelect
      onChange={newValue => onChange(newValue ? newValue.value : null)}
      styles={{
        menu: styles => ({ ...styles, ...(menuWidth ? { minWidth: menuWidth } : {}) }),
        ...extraStyles
      }}
      isDisabled={disabled}
      theme={DEFAULT_THEME}
      components={components}
      value={selectedOption}
      options={groupedOptions}
    />
  );
}

Select.defaultProps = {
  styles: {},
  components: {},
  disabled: false
};
