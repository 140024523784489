import { FaCheckCircle } from 'react-icons/fa';
import { LuClipboardCopy } from 'react-icons/lu';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';

export const BadgeContent: React.FC<{ hoverActive: boolean; copySuccess: boolean }> = ({
  hoverActive,
  copySuccess
}) => {
  if (!hoverActive) {
    return (
      <>
        <RiMoneyDollarCircleLine className="me-2" fontSize={23} color="#6438f5" /> Credits
      </>
    );
  }

  if (copySuccess) {
    return (
      <>
        <FaCheckCircle className="me-2" fontSize={15} color="#6438f5" /> Summary Copied
      </>
    );
  }

  return <LuClipboardCopy fontSize={23} color="#6438f5" />;
};
