import { Badge, Col, Dropdown, ListGroup, Row } from 'react-bootstrap';
import { FiMoreVertical } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import NavButton from 'src/components/NavButton';
import { showConfirm } from 'src/utils/modals';
import { appClientUrl } from 'src/utils/urls';

export interface ScoringAnalysisReportItemProps {
  scoringAnalysisReport: any;
  handleDelete: (id: string) => void;
}

export default function ScoringAnalysisReportItem({
  scoringAnalysisReport,
  handleDelete
}: ScoringAnalysisReportItemProps) {
  const navigate = useNavigate();

  const isError = scoringAnalysisReport.status === 'Error';
  const isUpdating = scoringAnalysisReport.status !== 'Processed' && !isError;

  return (
    <>
      <ListGroup.Item key={scoringAnalysisReport.id}>
        <Row className="align-items-center">
          <Col xs={7} className="ms-n2">
            <h4>
              {scoringAnalysisReport.name}
              {isUpdating && (
                <Badge className="ms-2" bg="secondary">
                  Calculating...
                </Badge>
              )}
              {isError && (
                <Badge className="ms-2" bg="danger">
                  Error
                </Badge>
              )}
            </h4>
          </Col>
          <Col xs="auto" className="ms-auto me-n3">
            <NavButton
              size="sm"
              to={appClientUrl(`/scoring/analysis/results/${scoringAnalysisReport.id}`)}
              disabled={scoringAnalysisReport.status !== 'Processed'}
            >
              View results
            </NavButton>
          </Col>
          <Col xs="auto">
            <Dropdown align="end">
              <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                <FiMoreVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={async () => {
                    const isSure = await showConfirm(
                      'This will remove this scoring analysis report. Are you sure?',
                      'Yes, delete',
                      'No, cancel',
                      'Confirm deletion'
                    );
                    if (isSure) handleDelete(scoringAnalysisReport.id);
                  }}
                >
                  Delete report
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate(appClientUrl(`/scoring/analysis/edit/${scoringAnalysisReport.id}`));
                  }}
                >
                  Edit report
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </ListGroup.Item>
    </>
  );
}
