import { useAuth } from '../../auth';
import PageLoader from 'src/components/PageLoader';
import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useNavigate } from 'react-router-dom';

export default function LoginPage({ title }: any) {
  const auth = useAuth();
  const navigate = useNavigate();

  usePageTitle(title);

  if (auth) {
    auth.logout().then(() => {
      // We dont use React constructs here on purpose, to force a reload (and clear internal JS state)
      navigate('/login');
    });
  }

  return (
    <>
      <PageLoader />
    </>
  );
}
