import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, Row, Spinner } from 'react-bootstrap';
import { FiEdit, FiFilter } from 'react-icons/fi';
import { GrGroup } from 'react-icons/gr';
import toast from 'react-hot-toast';

import { useClient } from 'src/auth';
import CardPaginator from 'src/components/Table/CardPaginator';
import { ContactRecord } from 'src/pages/companies/types';
import { Segment, SegmentPersona, SegmentPersonaSearchOptions } from '../../types';
import {
  createPersona,
  loadPersona,
  loadSegmentContacts,
  triggerPersonaSearchProcess,
  updatePersona,
  deletePersona
} from '../../apis';
import ContactsTable from './ContactsTable';
import { formatNumber, formatPercent } from 'src/utils/number';
import EmptyMessage from 'src/components/EmptyMessage';
import { SortDefinition } from 'src/components/companiesList/CompaniesTable';
import { PersonaEditOffcanvas } from './personas/PersonaEditOffcanvas';
import CompanyOffcanvas from 'src/components/companiesList/CompanyOffcanvas';
import { pluralize } from 'src/utils/string';
import { showModal } from 'src/utils/modals';
import { CopySegmentPersonaModal } from './personas/CopySegmentPersonaModal';
import ExportButton, { ExportButtonProps } from './ExportButton';
import pick from 'lodash/pick';

const PAGE_SIZE = 50;

export function ContactsTab({
  segment,
  personas,
  reloadSegment
}: {
  segment: Segment;
  personas: SegmentPersona[];
  reloadSegment: () => void;
}) {
  const { contactsSchema } = useClient();

  const [selectedPersonaId, setSelectedPersonaId] = useState<string | null>(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [isOffcanvasShowing, setIsOffcanvasShowing] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const [totalCompanies, setTotalCompanies] = useState(0);

  const [contacts, setContacts] = useState<ContactRecord[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState<SortDefinition>({ orderDirection: 'ASC' });
  const [showEditPersona, setShowEditPersona] = useState(false);
  const [editingPersona, setEditingPersona] = useState<SegmentPersona | null>(null);

  const [includeActive, setIncludeActive] = useState(true);
  const [includeRemoved, setIncludeRemoved] = useState(false);
  const [includeChecked, setIncludeChecked] = useState(false);

  useEffect(() => {
    // Only want to set the loading spinner if any of these change
    setIsLoading(true);
  }, [selectedPersonaId, pageIndex, sort, includeActive, includeChecked, includeRemoved]);

  const loadContacts = useCallback(async () => {
    const offset = pageIndex * PAGE_SIZE;
    const data = await loadSegmentContacts(segment, PAGE_SIZE, offset, selectedPersonaId ?? undefined, sort, {
      include_active: includeActive,
      include_removed: includeRemoved,
      include_checked: includeChecked
    });
    setContacts(data.items);
    setTotalContacts(data?.count);
    setTotalCompanies(data?.countCompanies);
    setIsLoading(false);
  }, [pageIndex, segment, selectedPersonaId, sort, includeActive, includeChecked, includeRemoved]);

  useEffect(() => {
    loadContacts();
  }, [loadContacts]);

  const selectedPersona = useMemo(() => {
    if (!selectedPersonaId) return undefined;
    return personas.find(p => p.id === selectedPersonaId);
  }, [selectedPersonaId, personas]);

  const exportConfig: ExportButtonProps['exportConfig'] = {
    personaId: selectedPersonaId ?? undefined,
    include_active: includeActive,
    include_removed: includeRemoved,
    include_checked: includeChecked
  };

  const handleCreatePersona = useCallback(
    async (persona: Partial<SegmentPersona>) => {
      const result = await createPersona(segment.id, { ...persona });
      setSelectedPersonaId(result.item.id);
      reloadSegment();
      setEditingPersona(result.item);
      return result.item.id as string;
    },
    [reloadSegment, segment.id]
  );
  const handleUpdatePersona = useCallback(
    async (persona: Partial<SegmentPersona>) => {
      if (selectedPersonaId) {
        await updatePersona(segment.id, selectedPersonaId, persona);
        reloadSegment();
      }
      return selectedPersonaId as string;
    },
    [reloadSegment, segment.id, selectedPersonaId]
  );

  const handleDeletePersona = useCallback(async () => {
    if (selectedPersonaId) {
      await toast.promise(deletePersona(segment.id, selectedPersonaId), {
        loading: 'Deleting persona',
        success: 'Persona deleted successfully',
        error: 'Error deleting persona'
      });
      setSelectedPersonaId(null);
      reloadSegment();
    }
  }, [reloadSegment, segment.id, selectedPersonaId]);

  const handleTriggerPersonaSearchProcess = useCallback(
    async (personaId: string, searchOptions: SegmentPersonaSearchOptions) => {
      await triggerPersonaSearchProcess(segment.id, personaId, searchOptions);
      reloadSegment();
    },
    [reloadSegment, segment.id]
  );

  const handleImportPersona = useCallback(
    async (sourceSegmentId: string, sourcePersonaId: string) => {
      const existingPersonaResponse = await loadPersona(sourceSegmentId, sourcePersonaId);
      const createPersonaResponse = await createPersona(
        segment.id,
        pick(existingPersonaResponse.item, ['name', 'criteriaGroups', 'searchOptions'])
      );
      await reloadSegment();
      setSelectedPersonaId(createPersonaResponse.item.id);
      setEditingPersona(createPersonaResponse.item);
      setShowEditPersona(true);
    },
    [reloadSegment, segment.id]
  );

  const isSearchOngoingCurrentPersona = selectedPersona && selectedPersona.searchStatus == 'RUNNING';
  const isSearchOngoingAnyPersona = !!personas.find(p => p.searchStatus === 'RUNNING');

  // Should we show the spinner status? Only if selected persona is searching or 'all contacts' and any searching
  const isSearchOngoingCurrentPersonaSelection =
    (!selectedPersona && isSearchOngoingAnyPersona) || isSearchOngoingCurrentPersona;

  const dropDown = (
    <Dropdown>
      <Dropdown.Toggle size="sm" variant="primary">
        + Add persona
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            setEditingPersona(null);
            setShowEditPersona(true);
          }}
        >
          Define new persona
        </Dropdown.Item>
        <Dropdown.Item
          onClick={async () => {
            const existingSelection = (await showModal(CopySegmentPersonaModal, {
              selectedSegmentId: segment.id
            })) as any;
            if (existingSelection && existingSelection.personaId) {
              await toast.promise(handleImportPersona(existingSelection.segmentId, existingSelection.personaId), {
                loading: 'Importing persona',
                success: 'Persona imported successfully',
                error: 'Error importing persona'
              });
            }
          }}
        >
          Import persona from another segment
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  // Some null handling and also clamp it so no larger than 1
  const progress = selectedPersona
    ? Math.min(selectedPersona.searchProgressCompanies / (selectedPersona.sizeCompanies || 0) || 0, 1)
    : 1;

  return (
    <>
      {personas.length === 0 && (
        <EmptyMessage
          style={{ paddingTop: '10em', paddingBottom: '10em' }}
          message="Define a persona to find contacts in your segment"
          actions={dropDown}
        />
      )}
      {personas.length > 0 && (
        <>
          <Card.Body className="px-3 py-0">
            <Row className="p-3">
              <Col className="d-flex justify-content-start" style={{ gap: 8 }}>
                <div
                  style={{
                    marginTop: 2,
                    marginRight: -8,
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle'
                  }}
                >
                  <GrGroup style={{ marginTop: 0, marginRight: 8 }} />
                </div>

                <Form.Select
                  size="sm"
                  style={{ maxWidth: 300 }}
                  value={selectedPersonaId ?? ''}
                  onChange={ev => setSelectedPersonaId(ev.target.value)}
                >
                  <option value="">All contacts</option>
                  {personas.map(p => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Form.Select>

                <Button
                  size="sm"
                  disabled={!selectedPersona}
                  variant="white"
                  onClick={() => {
                    if (selectedPersona) {
                      setEditingPersona(selectedPersona);
                      setShowEditPersona(true);
                    }
                  }}
                >
                  <FiEdit /> Edit persona
                </Button>

                {dropDown}
              </Col>
            </Row>

            <Row className="p-3" style={{ borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>
              <Col>
                {formatNumber(totalContacts)} {pluralize(totalContacts, 'contact', 'contacts')} across{' '}
                {formatNumber(totalCompanies)} {pluralize(totalCompanies, 'company', 'companies')} (
                {formatPercent(totalCompanies / (segment.sizeCompanies || 0), 1)} coverage){' '}
                {selectedPersona ? 'in selected persona' : 'in all personas'}
              </Col>

              <Col>
                <div className="d-flex justify-content-end align-items-center" style={{ gap: 5 }}>
                  {isSearchOngoingCurrentPersona && (
                    <>
                      <Spinner animation="border" role="status" size="sm" variant="secondary" style={{ opacity: 0.5 }}>
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      &nbsp; Persona search in progress ({formatPercent(progress, 0)})
                    </>
                  )}

                  <ExportButton
                    segment={segment}
                    isLoading={isLoading}
                    exportConfig={exportConfig}
                    personaName={selectedPersona?.name}
                  />

                  <Dropdown className="ms-2" autoClose={'outside'}>
                    <Dropdown.Toggle size="sm" variant="white">
                      <FiFilter /> View
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.ItemText as="label" className="text-nowrap">
                        <input
                          type="checkbox"
                          defaultChecked={includeActive}
                          className="me-2"
                          style={{ top: 2, position: 'relative' }}
                          onChange={ev => setIncludeActive(ev.target.checked)}
                        />
                        Show active members
                      </Dropdown.ItemText>

                      <Dropdown.ItemText as="label" className="text-nowrap">
                        <input
                          type="checkbox"
                          defaultChecked={includeRemoved}
                          className="me-2"
                          style={{ top: 2, position: 'relative' }}
                          onChange={ev => setIncludeRemoved(ev.target.checked)}
                        />
                        Show historic members
                      </Dropdown.ItemText>

                      <Dropdown.ItemText as="label" className="text-nowrap">
                        <input
                          type="checkbox"
                          defaultChecked={includeChecked}
                          className="me-2"
                          style={{ top: 2, position: 'relative' }}
                          onChange={ev => setIncludeChecked(ev.target.checked)}
                        />
                        Show skipped candidates
                      </Dropdown.ItemText>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Card.Body>

          <ContactsTable
            contacts={contacts}
            schema={contactsSchema}
            isLoading={isLoading}
            isSearchOngoing={isSearchOngoingCurrentPersonaSelection}
            onCompanySelect={(c: any) => {
              setSelectedCompanyId(c.company_id);
              setIsOffcanvasShowing(true);
            }}
            onSortChange={(sort: SortDefinition) => setSort(sort)}
          />

          <Card.Footer>
            <CardPaginator
              pageIndex={pageIndex}
              totalPages={Math.ceil(totalContacts / PAGE_SIZE)}
              setPage={idx => setPageIndex(idx)}
            />
          </Card.Footer>
        </>
      )}
      <PersonaEditOffcanvas
        segmentId={segment.id}
        segment={segment}
        show={showEditPersona}
        editingPersona={editingPersona}
        handleClose={() => setShowEditPersona(false)}
        handleDeletePersona={handleDeletePersona}
        handleUpdatePersona={handleUpdatePersona}
        handleCreatePersona={handleCreatePersona}
        handleTriggerPersonaSearchProcess={handleTriggerPersonaSearchProcess}
      />

      <CompanyOffcanvas
        company={{ companyId: selectedCompanyId as string }}
        show={isOffcanvasShowing}
        onHide={() => {
          setIsOffcanvasShowing(false);
          setSelectedCompanyId(undefined);
        }}
        showSyncTab={false}
        showContactsTab={false}
      />
    </>
  );
}
