import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import PageLoader from 'src/components/PageLoader';
import Select from 'src/components/Select';
import { loadPersonasForSegment, loadSegments } from 'src/pages/segments/apis';
import { Segment, SegmentPersona } from 'src/pages/segments/types';

export function CopySegmentPersonaModal({ proceed, show, selectedSegmentId }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [isPersonasLoading, setIsPersonasLoading] = useState(false);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [personas, setPersonas] = useState<SegmentPersona[]>([]);
  const [personaId, setPersonaId] = useState<string | null>(null);
  const [segmentId, setSegmentId] = useState<string | null>(null);

  useEffect(() => {
    loadSegments().then(result => setSegments(result.items.filter(s => s.id !== selectedSegmentId)));
    setIsLoading(false);
  }, [selectedSegmentId]);

  useEffect(() => {
    if (segmentId) {
      setIsPersonasLoading(true);
      setPersonaId(null);
      loadPersonasForSegment(segmentId).then(result => {
        setPersonas(result.items);
        setIsPersonasLoading(false);
      });
    } else {
      setPersonas([]);
    }
  }, [segmentId]);

  const segmentOptions = useMemo(() => segments.map(s => ({ value: s.id, label: s.name })), [segments]);
  const personaOptions = useMemo(() => {
    if (isPersonasLoading) {
      return [
        {
          value: null,
          label: 'Loading...'
        }
      ];
    }
    if (personas.length === 0) {
      return [
        {
          value: null,
          label: 'No personas found'
        }
      ];
    }
    return personas.map(s => ({ value: s.id, label: s.name }));
  }, [personas, isPersonasLoading]);

  return (
    <Modal animation={true} show={show} onHide={() => proceed(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Select persona to import</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <PageLoader fullHeight={false} style={{ height: 200 }} />}
        {!isLoading && (
          <Form>
            <Form.Group className="mb-3" controlId="copyPersona.segmentId">
              <Form.Label>Segment</Form.Label>
              <Select onChange={value => setSegmentId(value)} value={segmentId} options={segmentOptions}></Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="copyPersona.personaId">
              <Form.Label>Persona</Form.Label>
              <Select
                disabled={!segmentId || isPersonasLoading}
                onChange={value => setPersonaId(value)}
                value={personaId}
                options={personaOptions}
              ></Select>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="light" onClick={() => proceed(null)}>
          Cancel
        </Button>
        <Button size="sm" variant="primary" onClick={() => proceed({ segmentId, personaId })}>
          Import persona
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
