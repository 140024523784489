import { useState } from 'react';
import './sortable-list.scss';

function arrayMoveItem(array: any[], fromIdx: number, toIdx: number) {
  array.splice(toIdx, 0, array.splice(fromIdx, 1)[0]);
  return array;
}

function classNamesForItem(idx: number, hoverIdx: number | null, draggingIdx: number | null) {
  const ret: string[] = ['drag-item'];

  if (idx === hoverIdx) ret.push('drag-over');
  if (idx === draggingIdx) ret.push('drag-dragging');

  return ret.join(' ');
}

export default function SortableList({ items, component, setItems, style, className }: any) {
  const Component = component;

  const [hoverIdx, setHoverIdx] = useState<number | null>(null);
  const [draggingIdx, setDraggingIdx] = useState<number | null>(null);

  return (
    <div
      className={className ?? ''}
      style={style ?? {}}
      onDrop={ev => {
        if (draggingIdx !== null && hoverIdx !== null) {
          const newItems = arrayMoveItem(items, draggingIdx, hoverIdx);
          setItems(newItems);
        }
        ev.preventDefault();
      }}
      onDragOver={ev => {
        ev.preventDefault();
      }}
    >
      {items.map((item: any, idx: number) => (
        <div
          key={JSON.stringify(item)}
          className={classNamesForItem(idx, hoverIdx, draggingIdx)}
          draggable
          onDragStart={() => {
            setDraggingIdx(idx);
          }}
          onDragEnd={() => {
            setDraggingIdx(null);
            setHoverIdx(null);
          }}
          onDragOver={ev => {
            setHoverIdx(idx);
            ev.preventDefault();
          }}
        >
          <Component
            item={item}
            onMoveToTop={() => {
              setItems(arrayMoveItem(items, idx, 0));
            }}
            onMoveToBottom={() => {
              setItems(arrayMoveItem(items, idx, items.length - 1));
            }}
          />
        </div>
      ))}
    </div>
  );
}
