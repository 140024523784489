import { useEffect, useRef, useState } from 'react';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import { Button } from 'react-bootstrap';

// this is a simple wrapper around the jsoneditor component
// I would recommend using the jsoneditor-react package instead, but it doesn't support react 18 yet, nor it will
// as it is abandoned project

export default function JsonEditor({ json, onChange, referenceSchema, schema, isEditing }: any) {
  const editorRef = useRef<JSONEditor | null>(null); // To store the editor instance
  const containerRef = useRef(null); // Reference to the container DOM element
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      const schemaRefs = {};
      for (const key in referenceSchema?.definitions) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        schemaRefs[`#/definitions/${key}`] = referenceSchema?.definitions[key];
      }
      const options = {
        modes: [isEditing ? 'code' : 'view'],
        schema,
        schemaRefs,
        showErrorTable: ['code'],
        onChange: () => {
          try {
            const updatedJson = editorRef?.current?.get();
            onChange(updatedJson);
          } catch (error) {
            console.error('Error reading JSON data', error);
          }
        }
      };

      // Initialize JSONEditor
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      editorRef.current = new JSONEditor(containerRef.current, options);

      // Set initial JSON data
      editorRef.current?.set(json);

      // Cleanup on component unmount
      return () => {
        if (editorRef.current) {
          editorRef.current?.destroy();
        }
      };
    }
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this effect runs only once

  // Update editor when json or schema prop changes
  useEffect(() => {
    const schemaRefs = {};
    for (const key in referenceSchema?.definitions || []) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      schemaRefs[`#/definitions/${key}`] = referenceSchema?.definitions[key];
    }
    if (editorRef.current) {
      editorRef.current?.update(json);
      editorRef.current?.setSchema(schema, schemaRefs);
    }
    // eslint-disable-next-line
  }, [schema, referenceSchema]);

  // Add this useEffect to handle isEditing prop changes
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setMode(isEditing ? 'code' : 'view');
    }
  }, [isEditing]);

  return (
    <div
      style={
        isExpanded
          ? {
              position: 'fixed',
              top: 30,
              left: 30,
              right: 30,
              bottom: 30,
              zIndex: 99999,
              backgroundColor: 'white',
              border: '5px solid #444',
              padding: 3,
              paddingTop: 35
            }
          : { marginBottom: '1rem' }
      }
    >
      <div style={{ float: 'right', position: 'relative', top: -32 }}>
        <Button
          size="sm"
          variant="white"
          className="m-0"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? 'Close' : 'Expand'}
        </Button>
      </div>
      <div
        ref={containerRef}
        style={isExpanded ? { height: 'calc(100vh - 110px)', marginBottom: 5 } : { height: '300px', marginBottom: 2 }}
      />
    </div>
  );
}
