import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MdContentCopy } from 'react-icons/md';
import SimplePaginator from 'src/components/Table/SimplePaginator';
import TableGrid from 'src/components/Table/TableGrid';

function copyValuesToClipboard(fieldStats: any) {
  const termCounts = fieldStats?.values || [];
  const terms = termCounts.map((tc: any) => tc.value);
  const data = terms.join('\n');
  navigator.clipboard.writeText(data);

  if (terms.length === 1000) {
    toast.success('First 1000 values copied to clipboard');
  } else {
    toast.success('Values copied to clipboard');
  }
}

export default function FieldStatsOffcanvasValuesTable({ fieldStats, isLoading }: any) {
  const pageSize = 50;
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    // Reset if field changes
    setPageIndex(0);
  }, [fieldStats]);

  const totalValues = fieldStats?.values?.length ?? 0;
  const pageCount = Math.ceil(totalValues / pageSize);
  const totalRows = fieldStats?.totalRows ?? 0;

  const pageData = useMemo(() => {
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return (fieldStats?.values ?? []).slice(start, end);
  }, [fieldStats, pageIndex, pageSize]);

  const uniqueValuesTable = useReactTable({
    columns: [
      {
        header: 'Value',
        accessorKey: 'value'
      },
      {
        header: 'Count',
        accessorKey: 'count'
      },
      {
        header: 'Percent',
        id: 'percent',
        cell: props => {
          const value = props.row.original.count;
          const percent = Math.round((100 * value) / totalRows);
          return (
            <div className="d-flex">
              <ProgressBar now={percent} style={{ width: '70%', marginRight: 8 }} /> {`${percent}%`}
            </div>
          );
        }
      }
    ],
    data: pageData,
    manualPagination: true,
    manualSorting: true,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  // Dont show if fieldStats is loaded and does not contain key
  const shouldShowValuesTable = isLoading || fieldStats?.values;

  if (!shouldShowValuesTable) {
    return <></>;
  }

  return (
    <>
      <hr />
      <div className="float-end" style={{ marginTop: -8 }}>
        <Button size="sm" variant="white" onClick={() => copyValuesToClipboard(fieldStats)}>
          <MdContentCopy />
          &nbsp; Copy Values to Clipboard
        </Button>
      </div>
      <h3>Unique values</h3>
      <TableGrid
        emptyMessage="No values"
        className="table-bordered"
        table={uniqueValuesTable}
        isLoading={isLoading}
        numLoadingRows={10}
      />
      <div className="d-flex justify-content-end">
        <SimplePaginator pageIndex={pageIndex} totalPages={pageCount} setPage={setPageIndex} />
      </div>
    </>
  );
}
