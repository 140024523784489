import orderBy from 'lodash/orderBy';
import { Badge, Offcanvas, Tab, Table, Tabs } from 'react-bootstrap';
import Avatar from 'src/components/Avatar';
import { SchemaDataType, useClient } from 'src/auth';
import { renderCell } from 'src/components/Table/cellRenderers.tsx';
import ContactsTable from './ContactsTable.tsx';
import { FiExternalLink, FiInfo } from 'react-icons/fi';
import CRMSyncStatusTab from './CRMSyncStatusTab.tsx';
import { CompanyRecord } from 'src/pages/companies/types.ts';
import { getLogoForCompany } from 'src/utils/logos.ts';
import EmptyMessage from 'src/components/EmptyMessage.tsx';
import { CRM_CELL_RENDERERS } from 'src/pages/companies/renderers.tsx';
import { useEffect, useMemo, useState } from 'react';
import API from 'src/utils/API.ts';
import PageLoader from 'src/components/PageLoader.tsx';
import { TooltipWrapper } from 'src/components/TooltipWrapper.tsx';

export interface CompanyOffcanvasProps {
  // provide the actual record if it's available, or use companyId to load it from DB
  company: { record: CompanyRecord | null } | { companyId: string | null };
  show: boolean;
  onHide: () => void;
  showContactsTab: boolean;
  showSyncTab: boolean;
}

function renderCustomerCell(fieldName: string, fieldType: SchemaDataType, value: any) {
  if (fieldName.includes('crm_sync_') && CRM_CELL_RENDERERS[fieldName.split('/')[1]]) {
    return CRM_CELL_RENDERERS[fieldName.split('/')[1]](value);
  } else if (fieldName.includes('year')) {
    return renderCell(SchemaDataType.String, value);
  } else {
    return renderCell(fieldType, value);
  }
}

export default function CompanyOffcanvas({
  company,
  show,
  onHide,
  showSyncTab,
  showContactsTab
}: CompanyOffcanvasProps): JSX.Element {
  const { isContactsEnabled, companiesSchema } = useClient();
  const [actualCompany, setActualCompany] = useState<CompanyRecord>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Sort fields by datablock or source so similar fields are together
  const sortedCompanySchema = useMemo(
    () =>
      orderBy(
        companiesSchema,
        [f => f?.dataBlock?.blockName === 'firmographics', 'dataBlock.blockName', 'fieldSource'],
        ['desc', 'asc', 'asc']
      ),
    [companiesSchema]
  );

  useEffect(() => {
    if (!company) return;

    if ('companyId' in company && company.companyId) {
      setIsLoading(true);
      API.get<{ item: any }>(`/app/companies/items/${company.companyId}`, {})
        .then(data => {
          setActualCompany(data.item);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if ('record' in company && company.record) {
      setActualCompany(company.record);
    } else {
      setActualCompany(undefined);
    }
  }, [company]);

  return (
    <Offcanvas show={show} placement="end" onHide={onHide} style={{ width: 800 }}>
      {isLoading && <PageLoader />}
      {!isLoading && actualCompany && (
        <>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title as="div" className="d-flex flex-row">
              <Avatar size="s" className="me-2">
                <Avatar.Image
                  src={getLogoForCompany(actualCompany)}
                  alt={actualCompany.__domain}
                  className="rounded-circle border-light border"
                />
              </Avatar>
              <div>
                <h3 className="my-2 mb-0">{actualCompany.name}</h3>
                <div className="text-small text-muted">
                  <a href={`https://${actualCompany.__domain}`} target="_blank">
                    <FiExternalLink style={{ marginTop: -3 }} /> {actualCompany.__domain}
                  </a>
                  &nbsp;|&nbsp;
                  <a href={`https://www.linkedin.com/company/${actualCompany.__linked_in_id}`} target="_blank">
                    <FiExternalLink style={{ marginTop: -3 }} /> LinkedIn
                  </a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example" className="mb-3" mountOnEnter unmountOnExit>
              <Tab eventKey="overview" title="Overview">
                <Table size="sm" bordered hover responsive>
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedCompanySchema
                      .filter(field => !field.isHiddenFromUser)
                      .filter(field => !field.fieldSource.startsWith('crmSync')) // Separate tab
                      .map(field => {
                        const fieldName = field.fieldName;
                        const displayName = field.displayName;
                        const fieldType = field.type;
                        const value = actualCompany[fieldName];
                        return (
                          <tr key={fieldName}>
                            <td>
                              {displayName}&nbsp;
                              <TooltipWrapper
                                tooltipText={field.description ?? 'No description available'}
                                customComponent={
                                  <span>{field?.description && <FiInfo style={{ color: '#aaa' }} />}</span>
                                }
                              />
                            </td>
                            <td>{renderCustomerCell(fieldName, fieldType, value)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Tab>
              {showContactsTab && (
                <Tab
                  eventKey="contacts"
                  title={
                    <>
                      Contacts&nbsp;
                      {isContactsEnabled && (
                        <Badge bg="primary-soft" className="rounded-pill">
                          {actualCompany?.contacts_count || 0}
                        </Badge>
                      )}
                    </>
                  }
                >
                  {isContactsEnabled ? (
                    <>
                      {actualCompany.contacts_count > 0 ? (
                        <ContactsTable company={actualCompany} totalItems={actualCompany.contacts_count} />
                      ) : (
                        <EmptyMessage message="No contacts available" />
                      )}
                    </>
                  ) : (
                    <p>
                      Contacts are not enabled on your account. Please get in touch to discuss enriching your accounts
                      with people and contact details.
                    </p>
                  )}
                </Tab>
              )}

              {showSyncTab && (
                <Tab eventKey="sync" title="Sync Status">
                  <Offcanvas.Body>
                    <CRMSyncStatusTab company={actualCompany} />
                  </Offcanvas.Body>
                </Tab>
              )}
            </Tabs>
          </Offcanvas.Body>
        </>
      )}
    </Offcanvas>
  );
}

CompanyOffcanvas.defaultProps = {
  showSyncTab: true,
  showContactsTab: true
};
