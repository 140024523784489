import { components } from 'react-select';
import { Button, Dropdown } from 'react-bootstrap';
import { IntegrationFieldMappingInput, RecordType, updateStrategyLabels } from '../types';
import { useDataDirectoryStore } from '../state';
import { titleCase } from 'src/utils/string';
import Tag from 'src/components/Tag';
import Select from 'src/components/Select';
import { useState } from 'react';
import FieldCreationModal from './FieldCreationModal';
import { IntegrationType } from 'src/apis/integrations';

export interface FieldMappingEditorProps {
  fieldName: string;
  mappings: IntegrationFieldMappingInput[];
  recordType: RecordType;
}

export default function FieldMappingEditor({ fieldName, mappings, recordType }: FieldMappingEditorProps) {
  const state = useDataDirectoryStore();

  // If no integrations we hide the add mapping button
  if (state.integrations.length === 0) {
    return <>-</>;
  }

  function addMapping(integrationId: string) {
    state.addFieldMapping(integrationId, recordType, fieldName);
  }

  if (mappings.length === 0) {
    if (state.integrations.length === 1) {
      return (
        <Button variant="white" size="sm" onClick={() => addMapping(state.integrations[0].id)}>
          + Add mapping
        </Button>
      );
    }

    return (
      <Dropdown>
        <Dropdown.Toggle variant="white" size="sm">
          + Add mapping
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ zIndex: 9999 }}>
          {state.integrations.map(i => (
            <Dropdown.Item
              key={i.id}
              onClick={() => addMapping(i.id)}
            >{`Add ${titleCase(i.integrationType)} field mapping`}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <div className="d-flex" style={{ gap: 3 }}>
      <div className="flex-grow-1">
        {mappings.map(mapping => (
          <FieldMappingEditorRow key={mapping.id} mapping={mapping} recordType={recordType} />
        ))}
      </div>
      <div className="flex-grow-0">
        <Dropdown>
          <Dropdown.Toggle variant="white" size="sm"></Dropdown.Toggle>

          <Dropdown.Menu style={{ zIndex: 9999 }}>
            {state.integrations.map(i => (
              <Dropdown.Item
                key={i.id}
                onClick={() => addMapping(i.id)}
              >{`Add ${titleCase(i.integrationType)} field mapping`}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

const PropertySelectMenuList = (props: any) => {
  return (
    <components.MenuList {...props}>
      <div style={{ overflow: 'hidden' }}>
        {props.showCreateFieldOption && (
          <Button
            size="sm"
            variant="secondary"
            style={{ margin: '0px 4px 4px 4px', width: '97.8%' }}
            onClick={props.onClick}
          >
            + Create new CRM field
          </Button>
        )}
      </div>
      {props.children}
    </components.MenuList>
  );
};

type FieldMappingEditorRowProps = {
  mapping: IntegrationFieldMappingInput;
  recordType: RecordType;
};

function FieldMappingEditorRow({ mapping, recordType }: FieldMappingEditorRowProps) {
  const state = useDataDirectoryStore();
  const [isFieldCreationModalVisible, setIsFieldCreationModalVisible] = useState(false);

  const integration = state.integrations.find(i => i.id === mapping.integrationId);
  if (!integration) return <>Invalid</>;

  const integrationProperties =
    (recordType === 'contact'
      ? state.integrationContactFieldDescriptions[mapping.integrationId]
      : state.integrationCompanyFieldDescriptions[mapping.integrationId]) || [];

  const showCreateFieldOption =
    integration.integrationType === IntegrationType.SALESFORCE ||
    integration.integrationType === IntegrationType.HUBSPOT;

  return (
    <div className="d-flex align-items-center field-editor-row" style={{ gap: 3, marginBottom: 2 }}>
      <Tag color="grey" style={{ width: 100, margin: 0, maxWidth: '100%', opacity: 0.5, display: 'block' }}>
        {titleCase(integration.integrationType)}
      </Tag>
      <div style={{ width: '30%' }} className="flex-grow-1">
        <Select
          onChange={newValue => {
            state.modifyFieldMapping(integration.id, recordType, mapping.id, {
              updateStrategy: newValue
            });
          }}
          value={mapping.updateStrategy}
          options={[
            { value: 'OVERWRITE_ONLY_IF_EMPTY', label: updateStrategyLabels.OVERWRITE_ONLY_IF_EMPTY },
            { value: 'OVERWRITE_ALWAYS', label: updateStrategyLabels.OVERWRITE_ALWAYS }
          ]}
        />
      </div>
      <div style={{ width: '50%' }} className="flex-grow-1">
        <Select
          menuWidth={350}
          value={mapping.externalName ?? undefined}
          components={{
            MenuList: (props: any) => {
              const onClick = () => {
                setIsFieldCreationModalVisible(true);
              };
              return PropertySelectMenuList({ ...props, showCreateFieldOption, onClick });
            }
          }}
          onChange={newValue => {
            state.modifyFieldMapping(integration.id, recordType, mapping.id, {
              externalName: newValue
            });
          }}
          options={integrationProperties.map(prop => ({
            value: prop.id,
            label: `${prop.label} (${prop.type})`
          }))}
        />
      </div>
      <div className="flex-shrink-1">
        <Button
          size="sm"
          variant="white"
          onClick={() => state.removeFieldMapping(integration.id, recordType, mapping.id)}
        >
          ✕
        </Button>
      </div>
      <FieldCreationModal
        show={isFieldCreationModalVisible}
        onHide={() => setIsFieldCreationModalVisible(false)}
        mapping={mapping}
        recordType={recordType}
      />
    </div>
  );
}
