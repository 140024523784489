import { Badge, Col, ListGroup } from 'react-bootstrap';
import { CsvExportItem } from 'src/apis/csvExports/types';
import { formatDateString, titleCase } from 'src/utils/string';
import { useClient } from 'src/auth';
import NavButton from 'src/components/NavButton';

export interface CsvExportListItemProps {
  exportItem: CsvExportItem;
}

export default function CsvExportListItem({ exportItem }: CsvExportListItemProps) {
  const { clientName } = useClient();
  let badgeBdColor = 'secondary';
  if (exportItem.status === 'COMPLETE') badgeBdColor = 'success';
  if (exportItem.status === 'FAILED') badgeBdColor = 'danger';

  let label = '';

  switch (exportItem.type) {
    case 'DATASET':
      label = exportItem.exportName ? `Dataset: ${exportItem.exportName}` : 'Dataset';
      break;
    case 'SEGMENT_LINKEDIN_ADS':
    case 'DATASET_LINKEDIN_ADS':
      label = exportItem.exportName ? `LinkedIn: ${exportItem.exportName}` : 'LinkedIn';
      break;
    case 'SEGMENT':
      label = exportItem.exportName ? `Segment: ${exportItem.exportName}` : 'Segment';
      break;
    case 'CRM_ERRORS':
      label = 'CRM sync errors';
      break;
    case 'SCORING_ANALYSIS':
      label = 'Scoring analysis';
      break;
    default:
      // We don't want to derail the UI if the type isn't implemented, a warning should suffice for debugging
      console.warn(`exportItem.type not implemented: ${exportItem.type}`);
  }

  let partsLabel = '';
  if (exportItem.parts) {
    partsLabel = 'Files: ' + exportItem.parts.map(p => p.filename).join(', ');
  }

  return (
    <ListGroup.Item>
      <div className="d-flex flex-lg-row flex-column">
        <Col lg={9}>
          <h3>
            {label} &nbsp; <Badge bg={badgeBdColor}>{titleCase(exportItem.status)}</Badge>
          </h3>
          <div className="text-muted">
            Requested at: {formatDateString(exportItem?.createdAt)} by {exportItem.requestedByUserEmail}
          </div>
          <div className="text-muted">{partsLabel}</div>
        </Col>
        <Col xs="auto" className="d-flex align-items-lg-center ms-lg-auto mt-3 mt-lg-0">
          <NavButton
            size="sm"
            className="w-100 w-lg-auto"
            disabled={exportItem.status !== 'COMPLETE'}
            to={`/app/${clientName}/exports/${exportItem.id}`}
          >
            View download
          </NavButton>
        </Col>
      </div>
    </ListGroup.Item>
  );
}
