import { Button, Modal } from 'react-bootstrap';
import {
  PredictiveLabelsModelStatus,
  PredictiveLabelsModelVersionResponse
} from 'src/pages/predictive-labels/types.ts';
import { useCallback, useEffect, useState } from 'react';
import { deployVersion, getVersions } from 'src/pages/predictive-labels/apis.ts';
import Tag from 'src/components/Tag.tsx';
import toast from 'react-hot-toast';
import { usePeriodic } from 'src/utils/usePeriodic.ts';
import { InMemoryTableWithPagination } from 'src/components/Table/InMemoryTableWithPagination.tsx';

type VersionListModalProps = {
  modelId: string;
  show: boolean;
  onHide: () => void;
};

export default function VersionListModal({ modelId, show, onHide }: VersionListModalProps) {
  const [versions, setVersions] = useState<PredictiveLabelsModelVersionResponse>({
    versions: [],
    activeVersion: 0,
    status: PredictiveLabelsModelStatus.TRAINING
  });
  const [isLoading, setIsLoading] = useState(true);

  const closeModal = useCallback(() => {
    onHide();
  }, [onHide]);

  const deploy = useCallback(
    (version: number) => {
      const toastId = toast.loading('Working...');
      deployVersion(modelId, version)
        .then(() => {
          toast.success(`Deploying model version ${version}`, { id: toastId });
          closeModal();
        })
        .catch(() => {
          toast.error('Failed to deploy model', { id: toastId });
        });
    },
    [closeModal, modelId]
  );

  const columns = [
    {
      header: 'Version',
      id: 'version',
      accessorFn: (row: any) => row.version,
      enableSorting: false
    },
    {
      header: 'Score',
      id: 'score',
      accessorFn: (row: any) => {
        if (['TRAINING', 'ERROR'].includes(row.status)) {
          return 'N/A';
        } else {
          return row.avgWeighedF1Score?.toFixed(4);
        }
      },
      enableSorting: false
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: (c: any) => (
        <>
          {(c.row.original.status === 'TRAINING' && <Tag color={'info'}>Training</Tag>) ||
            (c.row.original.status === 'ERROR' && <Tag color={'danger'}>Error</Tag>) ||
            (versions.activeVersion === c.row.original.version && versions.status === 'DEPLOYING' && (
              <Tag color={'info'}>Deploying</Tag>
            )) ||
            (versions.activeVersion === c.row.original.version && <Tag color={'success'}>Deployed</Tag>) || (
              <Button
                variant="primary"
                size="sm"
                disabled={versions.status === 'DEPLOYING'}
                onClick={() => deploy(c.row.original.version)}
              >
                Deploy
              </Button>
            )}
        </>
      )
    }
  ];

  useEffect(() => {
    setIsLoading(true);
    getVersions(modelId)
      .then(response => {
        setVersions(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [modelId]);

  usePeriodic(
    () => {
      getVersions(modelId).then(response => {
        setVersions(response);
      });
    },
    5000,
    [modelId]
  );

  return (
    <Modal show={show} onHide={closeModal} size={'lg'}>
      <Modal.Header closeButton>Versions</Modal.Header>
      <Modal.Body>
        <InMemoryTableWithPagination
          columns={columns}
          data={versions?.versions}
          isLoading={isLoading}
          allowCopyToClipboard={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={() => closeModal()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
