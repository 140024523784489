import API from 'src/utils/API';
import { ScoringAnalysisReport, ScoringAnalysisReportInputs } from './types';

export async function loadScoringAnalysisReports(): Promise<{ reports: ScoringAnalysisReport[] }> {
  return await API.get('/app/scoring-analysis/reports');
}

export async function loadScoringAnalysisReport(id: string): Promise<{ report: ScoringAnalysisReport }> {
  return await API.get(`/app/scoring-analysis/reports/${id}`);
}

export async function deleteScoringAnalysisReport(id: string) {
  return await API.del(`/app/scoring-analysis/reports/${id}`);
}

export async function updateScoringAnalysisReport(
  id: string,
  name: string,
  inputs: ScoringAnalysisReportInputs
): Promise<ScoringAnalysisReport> {
  return await API.put(`/app/scoring-analysis/reports/${id}`, {}, { name, inputs });
}

export async function loadScoringAnalysisReportAISummary(id: string): Promise<{ summary: string }> {
  return await API.post(`/app/scoring-analysis/reports/${id}/describe`, {}, {});
}

export async function createScoringAnalysisReport(
  name: string,
  inputs: ScoringAnalysisReportInputs
): Promise<ScoringAnalysisReport> {
  return await API.post('/app/scoring-analysis/reports', {}, { name, inputs });
}
