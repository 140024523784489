import React from 'react';

export const TooltipContent: React.FC<{ isResourceStale: boolean }> = ({ isResourceStale }) => {
  if (isResourceStale) {
    return 'This resource cannot be unlocked. Please make a note of your changes and reload the page to continue editing.';
  }

  return (
    <>
      <strong>Caution!</strong> Forcing the removal of a lock may result changes being overwritten!
    </>
  );
};
