import { Badge, BadgeProps, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Color from 'color';

const BOOTSTRAP_COLORS: Record<string, string> = {
  primary: '#2c7be5',
  secondary: '#6e84a3',
  success: '#00d97e',
  info: '#39afd1',
  warning: '#f6c343',
  danger: '#e63757',
  light: '#edf2f9',
  lighter: '#f9fbfd',
  dark: '#000000'
};

type TagProps = BadgeProps & { overlayText?: string };

// A more nicely formatted Badge
export default function Tag({ color, children, style, overlayText, ...props }: TagProps) {
  const bgValue = color || 'primary';
  const colorString = bgValue && bgValue in BOOTSTRAP_COLORS ? BOOTSTRAP_COLORS[bgValue] : bgValue;
  const col = Color(colorString);
  const bgColor = col.lightness(90).mix(Color('white'), 0.3);
  const contrast = col.contrast(bgColor);
  let darken = 0.05;
  let fade = 0.8;
  if (contrast < 2) {
    darken = 0.25;
    fade = 0.6;
  }
  const textColor = col.darken(darken);
  const borderColor = col.fade(fade);

  const tag = (
    <Badge
      bg=""
      text=""
      style={{
        padding: '4px 6px',
        border: `1px solid ${borderColor.hexa()}`,
        background: `${bgColor.hexa()}`,
        color: `${textColor.hexa()}`,
        borderRadius: 3,
        margin: 2,
        maxWidth: '70%',
        textOverflow: 'ellipsis',
        overflow: 'clip',
        fontSize: '13px',
        ...(style ?? {})
      }}
      {...props}
    >
      {children}
    </Badge>
  );

  if (overlayText) {
    return <OverlayTrigger overlay={(props: any) => <Tooltip {...props}>{overlayText}</Tooltip>}>{tag}</OverlayTrigger>;
  }
  return tag;
}
