import { useEffect, useState } from 'react';
import PageLoader from 'src/components/PageLoader.tsx';
import AdminContext from './AdminContext';
import { getClientConfig } from 'src/apis/clients/apis.ts';
import { DataBlockDefinition } from 'src/apis/clients/types.ts';
import { getAllDataBlocks } from './apis.ts';

function AdminProvider({ children }: { children: React.ReactNode }) {
  const [isSchemaLoading, setIsSchemaLoading] = useState(true);
  const [schema, setSchema] = useState<any>(null);
  const [isLoadingDataBlocks, setIsLoadingDataBlocks] = useState(true);
  const [dataBlocksDefinitions, setDataBlocksDefinitions] = useState<DataBlockDefinition[]>([]);
  const [dynamicDataBlocksDefinitions, setDynamicDataBlocksDefinitions] = useState<DataBlockDefinition[]>([]);

  useEffect(() => {
    const fetchSchema = async () => {
      setIsSchemaLoading(true);
      const config = await getClientConfig('goodfit');
      setSchema(config.schema);
      setIsSchemaLoading(false);
    };
    fetchSchema();
  }, []);

  useEffect(() => {
    const fetchDataBlocks = async () => {
      setIsLoadingDataBlocks(true);
      const result = await getAllDataBlocks();
      setDataBlocksDefinitions(result.dataBlocks);
      setDynamicDataBlocksDefinitions(result.dataBlocks.filter(db => db.isParameterisedWithDynamicConfig));
      setIsLoadingDataBlocks(false);
    };
    fetchDataBlocks();
  }, []);

  if (isSchemaLoading || isLoadingDataBlocks) {
    return <PageLoader />;
  }

  return (
    <AdminContext.Provider value={{ schema, dataBlocksDefinitions, dynamicDataBlocksDefinitions }}>
      {children}
    </AdminContext.Provider>
  );
}

export default AdminProvider;
