import './all-contacts-modal-styles.scss';

import { Button, Modal } from 'react-bootstrap';

import { ModalBody } from './ModalBody';

interface CompanyContactsModalProps {
  companyName: string;
  companyId: string;
  segmentId: string;
  proceed(args: unknown): void;
  show: boolean;
}

export function CompanyContactsModal({ proceed, show, companyName, companyId, segmentId }: CompanyContactsModalProps) {
  return (
    <Modal dialogClassName="modal-70w" animation={true} show={show} onHide={() => proceed(null)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Preview Contacts: {companyName}</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ height: 550, padding: 0 }}>
        <ModalBody companyId={companyId} segmentId={segmentId} />
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="light" onClick={() => proceed(null)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
