import { Button, Col, Form, Modal, ProgressBar, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { TrainingExample } from 'src/pages/predictive-labels/types.ts';
import { useCallback, useMemo, useRef, useState } from 'react';
import { checkTrainingExamples } from 'src/pages/predictive-labels/apis.ts';
import toast from 'react-hot-toast';
import SimplePaginator from 'src/components/Table/SimplePaginator.tsx';
import { usePeriodic } from 'src/utils/usePeriodic.ts';

type TrainingExamplesModalProps = {
  modelId: string;
  label?: string;
  trainingExamples: TrainingExample[];
  show: boolean;
  onHide: () => void;
  onSave: (label: string, trainingExamples: TrainingExample[]) => void;
};

function ExamplesTable({ examples }: { examples: TrainingExample[] }) {
  const pageSize = 10;
  const [pageIndex, setPageIndex] = useState(0);

  return (
    <div>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>Domain</th>
          </tr>
        </thead>
        <tbody>
          {examples?.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize).map((te, index) => (
            <tr key={index}>
              <td className={'p-2'}>
                <a href={`https://${te.domain}`} target="_blank">
                  {te.domain}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-end">
        <SimplePaginator
          pageIndex={pageIndex}
          totalPages={Math.ceil(examples.length / pageSize)}
          setPage={setPageIndex}
        />
      </div>
    </div>
  );
}

export function TrainingExamplesModal({
  modelId,
  label,
  trainingExamples,
  show,
  onHide,
  onSave
}: TrainingExamplesModalProps) {
  const [tsv, setTsv] = useState<string>('');
  const [trainingExamplesEdit, setTrainingExamplesEdit] = useState<TrainingExample[]>(trainingExamples);
  const trainingExamplesRef = useRef<TrainingExample[]>(trainingExamples);
  const [editMode, setEditMode] = useState<boolean>(trainingExamplesEdit?.length === 0);

  const closeModal = () => {
    onSave(label as string, trainingExamplesRef.current);
    trainingExamplesRef.current = [];
    setTrainingExamplesEdit([]);
    onHide();
  };

  const toggleEditMode = (newEditMultiple: boolean) => {
    if (newEditMultiple) {
      setTsv(trainingExamplesRef.current.map(te => te.domain).join('\n'));
    } else {
      trainingExamplesRef.current = tsv
        .split('\n')
        .filter(domain => domain.length > 0)
        .map(domain => ({ domain, targetLabel: label as string, status: undefined, modelId }));
      setTrainingExamplesEdit(trainingExamplesRef.current);
    }
    setEditMode(newEditMultiple);
  };

  const checkDomains = useCallback(async () => {
    if (editMode) return;
    const examplesToCheck = trainingExamplesRef.current.filter(
      te => te.domain?.length > 0 && (te.status === 'RUNNING' || !te.status)
    );
    if (examplesToCheck?.length > 0) {
      trainingExamplesRef.current = (
        await checkTrainingExamples({ trainingExamples: trainingExamplesRef.current })
      ).trainingExamples.map((te: any) => ({
        ...te,
        modelId,
        targetLabel: label as string
      }));
      setTrainingExamplesEdit(trainingExamplesRef.current);
    }
  }, [editMode, label, modelId]);

  const copyToClipboard = useCallback(() => {
    const rows = trainingExamplesRef.current;

    const lines = [];
    const header = ['Domain', 'Status'];

    lines.push(header.join('\t'));
    rows.forEach(example => {
      lines.push([example.domain, example.status].join('\t'));
    });

    const textTsv = lines.join('\n');
    navigator.clipboard.writeText(textTsv);

    toast.success(`Copied ${rows.length} rows to clipboard!`);
  }, []);

  usePeriodic(
    async () => {
      await checkDomains();
    },
    5000,
    [checkDomains]
  );

  const isRunning = useMemo(() => {
    return !!trainingExamplesEdit?.find(te => !['OK', 'NOT_FOUND', 'ERROR'].includes(te.status as string));
  }, [trainingExamplesEdit]);

  return (
    <Modal show={show}>
      <Modal.Header>Training examples for label: {label}</Modal.Header>
      <Modal.Body className={'py-0'}>
        <div>
          {(editMode && (
            <Row key={'index'} className={'d-flex mt-5'}>
              <Col className={'mt-1'}>
                <Form.Control
                  id={'edit-multiple'}
                  style={{ fontSize: 11, fontFamily: 'monospace' }}
                  value={tsv}
                  as="textarea"
                  rows={10}
                  onChange={ev => setTsv(ev.target.value)}
                ></Form.Control>
              </Col>
            </Row>
          )) ||
            (isRunning && (
              <div
                className={'d-flex justify-content-center align-items-center flex-column'}
                style={{ minHeight: '140px' }}
              >
                <div className={'w-100'}>
                  <ProgressBar
                    animated
                    now={
                      (trainingExamplesEdit.filter(te => ['OK', 'NOT_FOUND', 'ERROR'].includes(te.status as string))
                        .length /
                        trainingExamplesEdit.length) *
                      100
                    }
                    label={`${((trainingExamplesEdit.filter(te => ['OK', 'NOT_FOUND', 'ERROR'].includes(te.status as string)).length / trainingExamplesEdit.length) * 100).toFixed(2)}%`}
                  />
                </div>
                <div>Verifying domains</div>
              </div>
            )) || (
              <Tabs defaultActiveKey="valid" id="training-examples-tabs">
                <Tab
                  eventKey="valid"
                  title={`Valid (${trainingExamplesEdit?.filter(te => te.status === 'OK').length})`}
                >
                  <ExamplesTable examples={trainingExamplesEdit?.filter(te => te.status === 'OK')} />
                </Tab>
                <Tab
                  eventKey="invalid"
                  title={`Not included domains (${trainingExamplesEdit?.filter(te => te.status === 'ERROR' || te.status === 'NOT_FOUND').length})`}
                >
                  <ExamplesTable
                    examples={trainingExamplesEdit?.filter(te => te.status === 'ERROR' || te.status === 'NOT_FOUND')}
                  />
                </Tab>
              </Tabs>
            )}
        </div>
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '1.5rem',
            zIndex: '1000'
          }}
        >
          <div className={'d-flex gap-2'}>
            {!editMode && !isRunning ? (
              <Button variant="white" size="sm" onClick={() => copyToClipboard()}>
                Copy to clipboard
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!editMode && (
          <Button variant="secondary" size="sm" onClick={closeModal} disabled={isRunning}>
            Save & Close
          </Button>
        )}
        <Button
          variant={'primary'}
          size="sm"
          onClick={() => toggleEditMode(!editMode)}
          disabled={!editMode && isRunning}
        >
          {editMode ? 'Check' : 'Provide domains'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
