import { Button, ListGroup } from 'react-bootstrap';
import { ScoringConfig } from 'src/apis/scoring/types';
import { useClient } from 'src/auth';
import EmptyMessage from 'src/components/EmptyMessage';
import ScoringConfigPlaceholder from './ScoringConfigPlaceholder';
import ScoringConfigItem from './ScoringConfigItem';
import { FiSliders } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function ScoringConfigList({ scoringConfigs, isLoading, handleDelete, handleChangeDefault }: any) {
  const navigate = useNavigate();
  const { clientName } = useClient();
  if (isLoading) {
    return (
      <ListGroup className="list-group-flush my-n3">
        <ScoringConfigPlaceholder />
        <ScoringConfigPlaceholder />
        <ScoringConfigPlaceholder />
      </ListGroup>
    );
  }

  if (scoringConfigs.length === 0) {
    return (
      <EmptyMessage
        message="You don't have any scoring fields"
        subMessage={
          <>
            Find out about how scoring works:{' '}
            <a
              href="https://docs.goodfit.io/product-docs/guides/prioritisation/how-to-build-your-first-score"
              target="_blank"
            >
              Scoring documentation
            </a>
          </>
        }
        icon={<FiSliders />}
        actions={
          <Button size="sm" variant="primary" onClick={() => navigate(`/app/${clientName}/scoring/new`)}>
            Create your first scoring field
          </Button>
        }
      />
    );
  }

  return (
    <ListGroup className="list-group-flush my-n3">
      {scoringConfigs.map((config: ScoringConfig) => (
        <ScoringConfigItem
          key={config.id}
          scoringConfig={config}
          handleDelete={handleDelete}
          handleChangeDefault={handleChangeDefault}
        />
      ))}
    </ListGroup>
  );
}
