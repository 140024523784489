import './preview-styles.scss';

import { ColumnDef, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { GrGroup } from 'react-icons/gr';

import {
  PersonaCompaniesPreviewResult,
  PersonaPreviewType,
  PersonaCompanyPreview,
  SegmentPersonaCriteria
} from 'src/pages/segments/types';
import TableGrid from 'src/components/Table/TableGrid';
import { PreviewFailedError } from './StatusComponents/PreviewFailedError';
import { PreviewLoading } from './StatusComponents/PreviewLoading';
import { NoMatchesFound } from './StatusComponents/NoMatchesFound';
import { generatePersonaPreview } from 'src/pages/segments/apis';
import SimplePaginator from 'src/components/Table/SimplePaginator';
import { showModal } from 'src/utils/modals';
import { CompanyContactsModal } from '../../../modals/CompanyContactsModal';

type CompaniesPreview = Extract<PersonaPreviewType, 'COMPANIES_WITH_CONTACTS' | 'COMPANIES_WITHOUT_CONTACTS'>;

function TotalContactsCell({ company, segmentId }: { company: PersonaCompanyPreview; segmentId: string }) {
  const companyId = company.company_id;
  const companyName = company.company_name;

  const showCompanyContactsModal = () => showModal(CompanyContactsModal, { companyId, segmentId, companyName });

  return (
    <div className="d-flex align-items-center">
      <span className="flex-grow-1">{company.total_contacts}</span>
      <Button
        size="sm"
        variant="white"
        title="View all contacts for this company"
        style={{
          padding: '2px 4px',
          lineHeight: 1.2,
          float: 'right',
          alignSelf: 'end'
        }}
        onClick={showCompanyContactsModal}
      >
        <GrGroup style={{ fontSize: 12 }} />
      </Button>
    </div>
  );
}

const produceColumns = (segmentId: string): ColumnDef<PersonaCompanyPreview>[] => [
  { accessorKey: 'company_name', header: 'Company' },
  { accessorKey: 'num_matching_contacts', header: 'Contacts Matched' },
  {
    accessorKey: 'total_contacts',
    header: 'Contacts Total',
    cell: c => <TotalContactsCell segmentId={segmentId} company={c.row.original} />
  }
];

type PersonaCompaniesPreviewTableProps = {
  segmentId: string;
  criteriaGroups: SegmentPersonaCriteria[];
  companyMatchType: CompaniesPreview;
};

const defaultPagination = { pageSize: 100, pageIndex: 0 };

export function PersonaCompaniesPreviewTable({
  companyMatchType,
  criteriaGroups,
  segmentId
}: PersonaCompaniesPreviewTableProps) {
  const [previewResult, setPreviewResult] = useState<PersonaCompaniesPreviewResult | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [pagination, setPagination] = useState<typeof defaultPagination>(defaultPagination);
  const [cachedPages, setCachedPages] = useState<Record<string, PersonaCompaniesPreviewResult>>({});

  const pageCount = Math.ceil((previewResult?.totalCompanies ?? 1) / pagination.pageSize);

  const setPage = (page: number): void => setPagination(state => ({ ...state, pageIndex: page * state.pageSize }));

  const table = useReactTable({
    columns: produceColumns(segmentId),
    pageCount,
    data: previewResult?.items ?? [],
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { pagination }
  });

  useEffect(() => {
    const cacheKey = `${segmentId}-${JSON.stringify(criteriaGroups)}-${companyMatchType}-${JSON.stringify(pagination)}`;

    // Cache the result for re-use when navigating back to the same page again
    if (cachedPages[cacheKey]) setPreviewResult(cachedPages[cacheKey]);
    else {
      setIsLoading(true);

      generatePersonaPreview(segmentId, criteriaGroups, companyMatchType, pagination)
        .then(result => {
          setIsError(false);
          setPreviewResult(result as PersonaCompaniesPreviewResult);
          setCachedPages(state => ({ ...state, [cacheKey]: result as PersonaCompaniesPreviewResult }));
        })
        .catch(err => {
          console.error('Preview error', err);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [companyMatchType, criteriaGroups, segmentId, pagination, cachedPages]);

  if (isError) return <PreviewFailedError />;
  if (isLoading) return <PreviewLoading />;
  if (previewResult === null) return <NoMatchesFound dataType="companies" />;

  return (
    <>
      <div style={{ padding: 5, background: 'var(--bs-gray-100)', textAlign: 'center' }}>
        Found {previewResult.totalContacts} contacts across {previewResult.totalCompanies} companies
      </div>
      <TableGrid
        table={table}
        dataColumns={3}
        isLoading={false}
        wrapperClassName="scrolling-wrapper"
        className="table-uniform-width-columns table-compact table-centered sticky-thead"
      />
      <div className="d-flex justify-content-end mt-3 me-3 pagination-wrapper">
        <SimplePaginator
          setPage={setPage}
          totalPages={pageCount}
          pageIndex={pagination.pageIndex / pagination.pageSize}
        />
      </div>
    </>
  );
}
