import sortBy from 'lodash/sortBy';
import { DataBlockFieldType, FieldMapping } from './types.ts';
import {
  CompanyCriteriaFilter,
  CompanyCriteriaGroup,
  DataBlockDefinition,
  DataBlockFieldDefinition,
  DynamicDataBlockConfig
} from 'src/apis/clients/types.ts';

// override names when it's unfortunate
function displayName(field: DataBlockFieldDefinition, dynamicDataBlockConfig: DynamicDataBlockConfig) {
  if (field.publicName === 'technologies/technologies') return `technologies (${dynamicDataBlockConfig.configId})`;
  return `${field.publicName} (${dynamicDataBlockConfig.configId})`;
}

export function getFieldMap(
  dynamicDataBlockDefinitions: DynamicDataBlockConfig[],
  dataBlocks: DataBlockDefinition[]
): FieldMapping[] {
  const dynamicDataBlockFields = dynamicDataBlockDefinitions.flatMap(dynamicDataBlockConfig => {
    const dataBlockForConfig = dataBlocks.find(f => f.blockName === dynamicDataBlockConfig.blockName);
    if (dataBlockForConfig === undefined) return [];
    return dataBlockForConfig.fields.flatMap(field => {
      return {
        name: `${dynamicDataBlockConfig.configId}/${field.internalName.split('/').pop()}`,
        displayName: displayName(field, dynamicDataBlockConfig),
        type: field.type,
        group: `${dynamicDataBlockConfig.configId}`,
        dataBlockName: dataBlockForConfig.blockName,
        configId: dynamicDataBlockConfig.configId,
        dataBlockField: field.internalName,
        dataBlockFieldType: DataBlockFieldType.DynamicDataBlocks
      };
    });
  });

  const basicFields = dataBlocks
    .filter(db => !db.isParameterisedWithDynamicConfig && !db.isDepreciated)
    .flatMap((f: DataBlockDefinition) => {
      return f.fields?.map((field: DataBlockFieldDefinition) => {
        return {
          name: field.internalName,
          displayName: field.publicName,
          type: field.type,
          group: f.blockName,
          dataBlockName: f.blockName,
          dataBlockField: field.internalName,
          dataBlockFieldType: DataBlockFieldType.BasicDataBlocks
        };
      });
    });

  return sortBy([...dynamicDataBlockFields, ...basicFields], ['group', 'label']);
}

export function mapSourcingCriteria(
  sourcingCriteria: CompanyCriteriaGroup,
  fieldMap: FieldMapping[],
  direction: 'to' | 'from'
): CompanyCriteriaGroup {
  return mapGroup(sourcingCriteria, fieldMap, direction);
}

function mapGroup(rule: CompanyCriteriaGroup, fieldMap: FieldMapping[], direction: 'to' | 'from'): any {
  if ('rules' in rule) {
    const result = {
      rules: [],
      combinator: rule.combinator,
      not: rule.not,
      label: rule.label,
      id: rule.id
    } as CompanyCriteriaGroup;

    for (const node of rule.rules) {
      if ('rules' in node) {
        result.rules.push(mapGroup(node as CompanyCriteriaGroup, fieldMap, direction));
      } else {
        result.rules.push(mapRule(node as CompanyCriteriaFilter, fieldMap, direction));
      }
    }
    return result;
  } else {
    return mapRule(rule, fieldMap, direction);
  }
}

function mapRule(rule: CompanyCriteriaFilter, fieldMap: FieldMapping[], direction: 'to' | 'from'): any {
  if (direction === 'from') {
    const field = findMapping(rule, fieldMap, direction);
    return {
      field: field?.name,
      operator: rule.operator,
      value: rule.value,
      id: rule.id,
      configId: field?.configId
    };
  } else {
    const field = findMapping(rule, fieldMap, direction);
    return {
      field: field?.dataBlockField,
      operator: rule.operator,
      value: rule.value,
      id: rule.id,
      configId: field?.configId
    };
  }
}

export function findMapping(
  rule: {
    field: string;
    configId?: string;
  },
  fieldMap: FieldMapping[],
  direction: 'to' | 'from'
) {
  if (direction === 'from') {
    return fieldMap.find(f => f.dataBlockField === rule.field && (rule.configId ? f.configId === rule.configId : true));
  } else {
    return fieldMap.find(f => f.name === rule.field);
  }
}
