import isEmpty from 'lodash/isEmpty';

const DEFAULT_DESCRIPTION = 'Please contact the GoodFit team for support. \n{originalErrorMessage}';

const errorMaps = [
  {
    errorCode: 'SF_COMPANY_DUPLICATES_DETECTED',
    shortDescription: 'A duplicate has been detected',
    longDescription: 'A duplicate has been detected with following IDs'
  },
  {
    errorCode: 'SF_COMPANY_INVALID_CROSS_REFERENCE_KEY',
    shortDescription: 'This record no longer exists',
    longDescription: 'This record no longer exists in your CRM'
  },
  {
    errorCode: 'SF_COMPANY_STRING_TOO_LONG',
    shortDescription: 'Text too long for field: {fieldName}',
    longDescription:
      'Text too long for field: {fieldName}. Increase the allowed length to at least 255 in the field settings or change the field type to Text Area.'
  },
  {
    errorCode: 'SF_COMPANY_ENTITY_IS_DELETED',
    shortDescription: 'This record no longer exists',
    longDescription: 'This record no longer exists in your CRM.'
  },
  {
    errorCode: 'SF_COMPANY_CANNOT_EXECUTE_FLOW_TRIGGER',
    shortDescription: 'One of your flows has failed',
    longDescription: '{originalErrorMessage}'
  },
  {
    errorCode: 'SF_COMPANY_UNABLE_TO_LOCK_ROW',
    shortDescription: "There's another update happening at the same time",
    longDescription:
      "The update of the following records has failed because there's another update happening at the same time."
  },
  {
    errorCode: 'SF_COMPANY_REQUIRED_FIELD_MISSING',
    shortDescription: 'Required field ({fieldName}) is missing',
    longDescription: 'Required field ({fieldName}) is missing'
  },
  {
    errorCode: 'SF_COMPANY_FIELD_INTEGRITY_EXCEPTION',
    shortDescription: "There's been a problem with field: {fieldName}",
    longDescription: '{originalErrorMessage}'
  },
  {
    errorCode: 'SF_COMPANY_INVALID_OR_NULL_FOR_RESTRICTED_PICKLIST',
    shortDescription: 'Invalid value for picklist field: {fieldName}',
    longDescription:
      'Picklist field values don\'t exist for {fieldName}. Please ensure that they are added as one of the picklist options or uncheck "Restrict picklist to values defined in the value set" option in the field settings to avoid this error in the future.'
  },
  {
    errorCode: 'SF_COMPANY_INVALID_FIELD_FOR_INSERT_UPDATE',
    shortDescription: "Couldn't update field: {fieldName}",
    longDescription: 'The permissions to update {fieldName} are missing or the field is a formula.'
  },
  {
    errorCode: 'SF_COMPANY_CANNOT_INSERT_UPDATE_ACTIVATE_ENTITY',
    shortDescription: 'Update has failed',
    longDescription: '{originalErrorMessage}'
  },
  {
    errorCode: 'SF_COMPANY_TOO_MANY_ENUM_VALUE',
    shortDescription: 'Too many items selected for field: {fieldName}',
    longDescription: 'Too many items selected for field: {fieldName}'
  },
  {
    errorCode: 'SF_COMPANY_PICK_LIST_LIMIT_EXCEEDED',
    shortDescription: 'Too many picklist options created for field: {fieldName}',
    longDescription:
      'One of your picklist or multipicklist fields has too many options. To find the affected field please check your Data Docs (linked) for any field that is mapped to Picklist / Multipicklist field type & has more than 1000 unique values under Field Information.'
  },
  {
    errorCode: 'SF_COMPANY_STORAGE_LIMIT_EXCEEDED',
    shortDescription: 'Salesforce storage limit exceeded',
    longDescription: '{originalErrorMessage}'
  },
  {
    errorCode: 'SF_COMPANY_FIELD_FILTER_VALIDATION_EXCEPTION',
    shortDescription: "Value doesn't match filter criteria for field: {fieldName}",
    longDescription: "Value doesn't match filter criteria for field: {fieldName}"
  },
  {
    errorCode: 'SF_COMPANY_ERROR',
    shortDescription: 'Failed to create company',
    longDescription: 'Please contact goodfit team for support. {originalErrorMessage}'
  },
  {
    errorCode: 'SF_CONTACT_DUPLICATES_DETECTED',
    shortDescription: 'A duplicate has been detected',
    longDescription: 'A duplicate has been detected with the following IDs'
  },
  {
    errorCode: 'SF_CONTACT_TOO_MANY_ENUM_VALUE',
    shortDescription: 'Too many items selected for field: {fieldName}',
    longDescription: 'Too many items selected for field: {fieldName}'
  },
  {
    errorCode: 'SF_CONTACT_PICK_LIST_LIMIT_EXCEEDED',
    shortDescription: 'Too many picklist options created for field: {fieldName}',
    longDescription:
      'One of your picklist or multipicklist fields has too many options. To find the affected field please check your Data Docs (linked) for any field that is mapped to Picklist / Multipicklist field type & has more than 1000 unique values under Field Information.'
  },
  {
    errorCode: 'SF_CONTACT_FIELD_CUSTOM_VALIDATION_EXCEPTION',
    shortDescription: "Couldn't update because of custom validation exception",
    longDescription: '{originalErrorMessage}'
  },
  {
    errorCode: 'SF_CONTACT_REQUIRED_FIELD_MISSING',
    shortDescription: 'Required field ({fieldName}) is missing',
    longDescription: 'Required field ({fieldName}) is missing'
  },
  {
    errorCode: 'SF_CONTACT_INSUFFICIENT_ACCESS_ON_CROSS_REFERENCE_ENTITY',
    shortDescription: "The integration user doesn't have permission to update this record",
    longDescription: "The integration user doesn't have permission to update this record"
  },
  {
    errorCode: 'SF_CONTACT_INVALID_EMAIL_ADDRESS',
    shortDescription: 'Invalid email address',
    longDescription: 'Invalid email address'
  },
  {
    errorCode: 'SF_CONTACT_FIELD_INTEGRITY_EXCEPTION',
    shortDescription: "There's been a problem with field: {fieldName}",
    longDescription: '{originalErrorMessage}'
  },
  {
    errorCode: 'SF_CONTACT_CANNOT_INSERT_UPDATE_ACTIVATE_ENTITY',
    shortDescription: 'Update has failed',
    longDescription: 'Salesforce Error: {originalErrorMessage}'
  },
  {
    errorCode: 'SF_CONTACT_ENTITY_IS_DELETED',
    shortDescription: 'This record no longer exists',
    longDescription: 'This record no longer exists in your CRM.'
  },
  {
    errorCode: 'SF_CONTACT_ACCOUNT_NOT_CREATED',
    shortDescription: 'Contact not created',
    longDescription: 'Contact not created, because the account could not be created'
  },
  {
    errorCode: 'SF_CONTACT_INVALID_OR_NULL_FOR_RESTRICTED_PICKLIST',
    shortDescription: 'Invalid value for picklist field: {fieldName}',
    longDescription:
      'Picklist field values don\'t exist for {fieldName}. Please ensure that they are added as one of the picklist options or uncheck "Restrict picklist to values defined in the value set" option in the field settings to avoid this error in the future.'
  },
  {
    errorCode: 'SF_CONTACT_CONFLICT',
    shortDescription: 'Contact conflict',
    longDescription: 'A duplicate has been detected for the following emails'
  },
  {
    errorCode: 'SF_CONTACT_ERROR',
    shortDescription: 'Failed to create company',
    longDescription: 'Please contact goodfit team for support. {originalErrorMessage}'
  },

  {
    errorCode: 'HS_COMPANY_INVALID_OPTION',
    shortDescription: 'Invalid value for dropdown field: {fieldName}',
    longDescription:
      "Dropdown values don't exist for {fieldName}. Please ensure that it is added as one of the dropdown options."
  },
  {
    errorCode: 'HS_COMPANY_INVALID_DATE',
    shortDescription: 'Invalid date value for field: {fieldName}',
    longDescription: 'There was a problem syncing field {fieldName}. {originalErrorMessage}'
  },
  {
    errorCode: 'HS_COMPANY_VALIDATION_ERROR',
    shortDescription: 'Invalid value for field: {fieldName}',
    longDescription: 'There was a problem syncing field {fieldName}. {originalErrorMessage}'
  },
  {
    errorCode: 'HS_COMPANY_INVALID_INTEGER',
    shortDescription: 'Trying to push non integer value into integer field',
    longDescription: 'Following field {fieldName} might have an incorrect type'
  },
  {
    errorCode: 'HS_COMPANY_PROPERTY_DOESNT_EXIST',
    shortDescription: "Property doesn't exit ",
    longDescription: 'Following fields fields do not exist in your hubspot account'
  },
  {
    errorCode: 'HS_COMPANY_RESOURCE_NOT_FOUND',
    shortDescription: 'This record no longer exists',
    longDescription: 'This record no longer exists in your CRM.'
  },
  {
    errorCode: 'HS_COMPANY_ERROR',
    shortDescription: 'Failed to create company',
    longDescription: DEFAULT_DESCRIPTION
  },

  {
    errorCode: 'HS_CONTACT_ALREADY_EXISTS',
    shortDescription: 'A duplicate has been detected',
    longDescription: 'A duplicate has been detected for the following IDs'
  },
  {
    errorCode: 'HS_CONTACT_INVALID_DATE',
    shortDescription: 'Invalid date value for field: {fieldName}',
    longDescription: 'There was a problem syncing field {fieldName}. {originalErrorMessage}'
  },
  {
    errorCode: 'HS_CONTACT_VALIDATION_ERROR',
    shortDescription: 'Invalid value for field: {fieldName}',
    longDescription: 'There was a problem syncing field {fieldName}. {originalErrorMessage}'
  },
  {
    errorCode: 'HS_CONTACT_ERROR',
    shortDescription: 'Failed to create contact',
    longDescription: DEFAULT_DESCRIPTION
  },
  {
    errorCode: 'PD_COMPANY_ORG_NOT_FOUND',
    shortDescription: 'This record no longer exists',
    longDescription: 'This record no longer exists in your CRM'
  },
  {
    errorCode: 'PD_COMPANY_ERROR',
    shortDescription: 'Failed to create company',
    longDescription: DEFAULT_DESCRIPTION
  },
  {
    errorCode: 'GF_COMPANY_ERROR',
    shortDescription: 'Failed to create company',
    longDescription: DEFAULT_DESCRIPTION
  },
  {
    errorCode: 'GF_CONTACT_ERROR',
    shortDescription: 'Failed to create contact',
    longDescription: DEFAULT_DESCRIPTION
  }
];

export type ErrorDescriptions = {
  shortDescription: string;
  longDescription: string;
};

export function mapError(errorCode: string, originalMessage: string, fieldName: string | null): ErrorDescriptions {
  const errorMap = errorMaps.find(errorMap => errorMap.errorCode === errorCode);
  if (errorMap) {
    let longDescription = errorMap.longDescription;
    if (isEmpty(originalMessage)) {
      if (['PD_COMPANY_ERROR', 'GF_COMPANY_ERROR', 'GF_CONTACT_ERROR', 'HS_COMPANY_ERROR'].includes(errorCode)) {
        longDescription = errorMap.longDescription?.replace('{originalErrorMessage}', '');
      } else {
        longDescription = errorMap.longDescription?.replace(
          '{originalErrorMessage}',
          'Please contact goodfit team for support'
        );
      }
    } else {
      longDescription = longDescription.replace('{originalErrorMessage}', originalMessage);
    }
    longDescription.replace('{fieldName}', fieldName as string);

    return {
      shortDescription:
        errorMap.shortDescription.replace('{fieldName}', fieldName as string) || 'Failed to create entry',
      longDescription
    };
  }
  return {
    shortDescription: 'Failed to create entry',
    longDescription: DEFAULT_DESCRIPTION.replace('{originalErrorMessage}', originalMessage || '')
  };
}
