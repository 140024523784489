import { useState, useEffect } from 'react';

import { fetchAllContactsForCompany } from 'src/pages/segments/apis';
import { AllContactsForCompany } from 'src/pages/segments/types';

export const PAGE_SIZE = 100;
const defaultPagination = { pageSize: PAGE_SIZE, pageIndex: 0 };

interface UseCompanyContactsArgs {
  segmentId: string;
  companyId: string;
}

interface UseCompanyContacts {
  isLoading: boolean;
  isError: boolean;
  companyContacts?: AllContactsForCompany;
  setPage(page: number): void;
  pagination: typeof defaultPagination;
}

export const useAllCompanyContacts = ({ segmentId, companyId }: UseCompanyContactsArgs): UseCompanyContacts => {
  const [pagination, setPagination] = useState<typeof defaultPagination>(defaultPagination);
  const [companyContacts, setCompanyContacts] = useState<AllContactsForCompany>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setPage = (page: number): void => setPagination(state => ({ ...state, pageIndex: page * state.pageSize }));

  useEffect(() => {
    setIsLoading(true);

    fetchAllContactsForCompany({ segmentId, companyId, ...pagination })
      .then(result => {
        setCompanyContacts(result);
        setIsError(false);
      })
      .catch(err => {
        console.error('Preview error', err);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, [companyId, segmentId, pagination]);

  return { isLoading, isError, companyContacts, setPage, pagination };
};
