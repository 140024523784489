export interface TalkTrack {
  id: string;
  name: string;
  processingStatus: TalkTrackProcessingStatus;
  companyCount: number;
  filters: any;
  lastModifiedAt: Date;
}

export enum TalkTrackProcessingStatus {
  Waiting = 'Waiting',
  Running = 'Running',
  Fresh = 'Fresh'
}
