import startCase from 'lodash/startCase';
import { Dropdown } from 'react-bootstrap';

import { Integration } from 'src/apis/integrations';
import { showModal } from 'src/utils/modals';
import { SyncSettingsModal } from '../SyncSettingsModal';
import { SyncStatusIcon } from './SyncStatusIcon';
import { Segment } from 'src/pages/segments/types';
import { isAutoSyncEnabledForSegment } from '../../utils/isAutoSyncEnabledForSegment';

interface IntegrationItemProps {
  segment: Segment;
  isLoading: boolean;
  integration: Integration;
  includeDivider: boolean;
}

export const IntegrationMenuItem: React.FC<IntegrationItemProps> = ({
  isLoading,
  segment,
  integration,
  includeDivider
}) => {
  const showSyncModal = () => showModal(SyncSettingsModal, { integration, segment });

  const isAutoSync = isAutoSyncEnabledForSegment(segment, integration);
  const integrationName = startCase(integration.integrationType.toLowerCase());

  return (
    <>
      <Dropdown.Item disabled={isLoading} onClick={showSyncModal}>
        <span className="d-flex align-items-center gap-2">
          <SyncStatusIcon isAutoSync={isAutoSync} integration={integration} /> {integrationName}
        </span>
      </Dropdown.Item>
      {includeDivider && <Dropdown.Divider />}
    </>
  );
};
