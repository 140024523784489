import { useEffect, useState } from 'react';
import { Badge, Col, Nav, Row } from 'react-bootstrap';
import Header from 'src/components/Header';
import { useIntegrationStore } from 'src/pages/settings/connectors/integrationsStore.ts';
import SyncButton from 'src/pages/companies/components/SyncButton.tsx';
import ExportButton from './components/ExportButton';
import OpenWithButton from './components/OpenWithButton';
import { useClient } from 'src/auth';

export default function CRMCompaniesHeader({ onTabChange, totalItems, state, ...props }: any) {
  const [tab, setTab] = useState('all');
  const { hasFeatureFlag } = useClient();

  const fetchIntegrations = useIntegrationStore(state => state.fetchIntegrations);
  const integrations = useIntegrationStore(state => state.integrations);

  useEffect(() => {
    fetchIntegrations();
    // We only want this to run upon boot
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Header {...props}>
      <Header.Body>
        <Row className="align-items-center">
          <Col>
            <Header.Title className="text-truncate">Companies</Header.Title>
          </Col>
          <Col xs="auto" className="d-flex justify-content-end">
            {hasFeatureFlag('openInLinkedInSalesNav') && <OpenWithButton state={state} />}
            <ExportButton filters={state.filters} search={state.search} columns={state.columns} />
            <SyncButton integrations={integrations} filters={state.filters} search={state.search} />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Header.Tabs
              className="nav-overflow"
              onSelect={tab => {
                if (tab) {
                  setTab(tab);
                  onTabChange(tab);
                }
              }}
              activeKey={tab}
            >
              <Nav.Item>
                <Nav.Link eventKey="all" className="text-nowrap" role="button">
                  All companies{' '}
                  <Badge bg="secondary-soft" className="rounded-pill">
                    {totalItems}
                  </Badge>
                </Nav.Link>
              </Nav.Item>
            </Header.Tabs>
          </Col>
        </Row>
      </Header.Body>
    </Header>
  );
}
