import { Popover, OverlayTrigger } from 'react-bootstrap';
import { formatNumber, formatPercent } from 'src/utils/number';
import ScoreBar from './ScoreBar';

const scoreDescription = (v: any) => {
  if (v === 0) {
    return `Neutral`;
  }
  if (v <= -8) {
    return `Very negative impact`;
  }
  if (v <= -4) {
    return `Negative impact`;
  }
  if (v <= -1) {
    return `Somewhat negative impact`;
  }
  if (v >= 8) {
    return `Very positive impact`;
  }
  if (v >= 5) {
    return `Positive impact`;
  }
  if (v >= 1) {
    return `Somewhat positive impact`;
  }
};

export default function ScoreHover({ item }: any) {
  const renderTooltip = (props: any) => (
    <Popover id="score-tooltip" {...props}>
      <Popover.Header>Frequency analysis</Popover.Header>
      <Popover.Body>
        <p>
          This factor occurs in {item.goodCount} ({formatPercent(item.goodPercentage, 1)}) of target companies and{' '}
          {item.badCount} ({formatPercent(item.badPercentage, 1)}) of other companies. Therefore this factor has a{' '}
          <b style={{ color: '#666' }}>{scoreDescription(item.score)?.toLowerCase()}</b> with a suggested score of{' '}
          {formatNumber(item.score, 1)}.
        </p>
        <ScoreBar
          title="Target companies"
          percent={item.goodPercentage}
          count={item.goodCount}
          color="rgb(140, 108, 245)"
        />
        <ScoreBar title="Other companies" percent={item.badPercentage} count={item.badCount} color="orange" />
        Suggested score: {formatNumber(item.score, 1)}
        <br />
        Confidence: {formatPercent(item.confidence)}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger placement="left" overlay={renderTooltip}>
      <div className="score">{formatNumber(item.score, 1)}</div>
    </OverlayTrigger>
  );
}
