import { BasicField, SchemaDataType } from 'src/auth/types';

export type MetaDataField = BasicField & { description: string; isMappableInIntegration: boolean; isSystem: boolean };

const defaults = {
  isMappableInIntegration: true,
  fieldSource: 'meta',
  isSystem: true
};

export const METADATA_FIELDS: MetaDataField[] = [
  {
    fieldName: 'system:created_at',
    displayName: 'Created At (GoodFit)',
    description: 'Date this record was added to your GoodFit dataset',
    type: SchemaDataType.Date,
    ...defaults
  },
  {
    fieldName: 'system:updated_at',
    displayName: 'Updated At (GoodFit)',
    description: 'Date this record was last updated in your GoodFit dataset',
    type: SchemaDataType.Date,
    ...defaults
  },
  {
    fieldName: 'system:last_synced_at',
    displayName: 'Last Synced At (GoodFit)',
    description: 'Date this record was last updated in your CRM',
    type: SchemaDataType.Date,
    ...defaults
  },

  {
    fieldName: 'system:created_by_goodfit',
    displayName: 'Created By GoodFit?',
    description: 'Was this record originally created by GoodFit in your CRM?',
    type: SchemaDataType.Boolean,
    ...defaults
  },
  {
    fieldName: 'system:enriched_by_goodfit',
    displayName: 'Enriched By GoodFit?',
    description: 'Was this record enriched by GoodFit in your CRM?',
    type: SchemaDataType.Boolean,
    ...defaults
  },
  {
    fieldName: 'system:source',
    displayName: 'Source',
    description: 'Source of this record (set to GoodFit if this record was created by GoodFit)',
    type: SchemaDataType.String,
    ...defaults
  }
];
