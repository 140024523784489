import { FieldSelectorProps } from 'react-querybuilder';
import { useMemo } from 'react';
import Select from 'react-select';
import { DEFAULT_THEME } from 'src/utils/react-select.ts';
import { isDisabledPath } from 'src/components/Filters/disabledPaths.ts';

export function FieldSelector(f: FieldSelectorProps) {
  const [options, currentSelectedOption] = useMemo(() => {
    // Prepare the groups of options
    // Grouping by dataBlockName
    let currentSelectedOption: any = null;
    const groups: any = {};
    for (const o of f.options as any[]) {
      if (!groups[o.group]) {
        groups[o.group] = {
          label: o.group,
          options: []
        };
      }
      const option = {
        value: o.name,
        label: o.label
      };
      if (option.value === f.value) currentSelectedOption = option;
      groups[o.group].options.push(option);
    }
    const options = Object.values(groups);
    return [options, currentSelectedOption];
  }, [f]);
  return (
    <Select
      options={options}
      onChange={(v: any) => f.handleOnChange(v ? v.value : null)}
      isSearchable={true}
      value={currentSelectedOption}
      className="react-select-container"
      classNamePrefix="react-select"
      theme={DEFAULT_THEME}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          minWidth: 240
        })
      }}
      isDisabled={f.disabled || isDisabledPath(f, 'fieldSelector')}
    />
  );
}
