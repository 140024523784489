import { KeywordPattern } from './types';
import { Button, Form, InputGroup, Row } from 'react-bootstrap';
import { FiPlus, FiX } from 'react-icons/fi';
import { CheckboxInput } from 'src/components/inputs/CheckboxInput.tsx';
import './keywordPatterns.scss';

export function KeywordPatternForm({
  showKeyword = true,
  keywordPattern,
  update,
  deletePattern
}: {
  showKeyword?: boolean;
  keywordPattern: KeywordPattern;
  update: (update: KeywordPattern) => void;
  deletePattern: () => void;
}) {
  return (
    <>
      <div className="top-right-corner m-2">
        <Button size={'sm'} variant={'outline-danger'} onClick={deletePattern}>
          <FiX />
        </Button>
      </div>
      <Row className={'gap-2'}>
        <div className={'d-flex'}>
          <div>
            {showKeyword && (
              <InputGroup size="sm">
                <InputGroup.Text style={{ backgroundColor: '#f5f5f5', width: '8rem' }}>Keyword</InputGroup.Text>
                <Form.Control
                  placeholder="Keyword"
                  value={keywordPattern.keyword || ''}
                  onChange={e => update({ ...keywordPattern, keyword: e.target.value })}
                />
              </InputGroup>
            )}
          </div>
        </div>
        <div className={'d-flex flex-row gap-2'}>
          <div>
            <CheckboxInput
              title={'Case Sensitive'}
              value={keywordPattern.isCaseSensitive}
              onUpdate={(value: boolean) => update({ ...keywordPattern, isCaseSensitive: value })}
            />
          </div>
          <div>
            <CheckboxInput
              title={'Is Regex'}
              value={keywordPattern.isRegex || false}
              onUpdate={(value: boolean) => {
                const toUpdate = { ...keywordPattern, isRegex: value };
                if (!value) {
                  toUpdate.isWordBoundary = true;
                }
                update(toUpdate);
              }}
            />
          </div>
          <div>
            <CheckboxInput
              title={'Word Boundary'}
              disabled={!keywordPattern.isRegex}
              value={keywordPattern.isWordBoundary || false}
              onUpdate={(value: boolean) => update({ ...keywordPattern, isWordBoundary: value })}
            />
          </div>
        </div>
        <div className={''}>
          <div>
            <InputGroup size="sm" className="">
              <InputGroup.Text style={{ backgroundColor: '#f5f5f5', width: '8rem' }}>Patterns</InputGroup.Text>
              <div className={'keyword-patterns-container d-flex flex-row flex-wrap gap-2 ms-2'}>
                {keywordPattern.patterns.map((pattern, index) => {
                  return (
                    <div key={`keywords-pattern-${index}`} className={'me-2 d-flex  gap-2'}>
                      <InputGroup size={'sm'}>
                        <Form.Control
                          key={index}
                          placeholder="pattern"
                          value={pattern}
                          onChange={e => {
                            keywordPattern.patterns[index] = e.target.value;
                            update({ ...keywordPattern, patterns: keywordPattern.patterns });
                          }}
                        />
                        <Button
                          size={'sm'}
                          variant={'outline-danger'}
                          disabled={keywordPattern.patterns.length === 1}
                          onClick={() =>
                            update({
                              ...keywordPattern,
                              patterns: keywordPattern.patterns.filter((_, i) => index !== i)
                            })
                          }
                        >
                          <FiX />
                        </Button>
                      </InputGroup>
                    </div>
                  );
                })}
                <div>
                  <Button
                    size={'sm'}
                    variant={'outline-primary'}
                    onClick={() => update({ ...keywordPattern, patterns: [...keywordPattern.patterns, ''] })}
                  >
                    <FiPlus />
                  </Button>
                </div>
              </div>
            </InputGroup>

            {/*</div>*/}
          </div>
        </div>
      </Row>
    </>
  );
}
