import { useCallback, useRef } from 'react';
import { Card, Form } from 'react-bootstrap';
import LoadingButton from 'src/components/LoadingButton';
import API from 'src/utils/API';
import toast from 'react-hot-toast';

export default function DomainOverride() {
  const domainRef = useRef<HTMLInputElement>(null);
  const linkedInIdRef = useRef<HTMLInputElement>(null);

  const applyOverride = useCallback(async () => {
    return toast.promise(
      API.post<any>(
        `/admin/toolbox/add-domain-override`,
        {},
        {
          domain: domainRef?.current?.value,
          linkedInId: linkedInIdRef?.current?.value
        }
      ),
      {
        loading: 'Adding override',
        error: 'Error adding override',
        success: 'Domain override added'
      }
    );
  }, []);

  return (
    <Card>
      <Card.Header>Domain overrides</Card.Header>
      <Card.Body>
        <p className="text-muted">
          This tool will add an entry to domain_overrides and re-schedule the domains for rechecking. Within 15 minutes
          the domains should point to new company id PG, but may take 24 hours to appear in Redshift.
        </p>
        <Form.Group className="mb-3" controlId="domain_override_domain">
          <Form.Label>Domain</Form.Label>
          <Form.Control ref={domainRef} name="domain" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="domain_override_linkedinid">
          <Form.Label>LinkedIn ID</Form.Label>
          <Form.Control ref={linkedInIdRef} name="linkedInId" />
        </Form.Group>
      </Card.Body>
      <Card.Footer>
        <LoadingButton size="sm" onClick={applyOverride}>
          Apply override
        </LoadingButton>
      </Card.Footer>
    </Card>
  );
}
