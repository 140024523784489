import { Form } from 'react-bootstrap';
import { ScoringAnalysisReportFormState } from './utils';

export default function ScoringAnalysisReportForm({
  state,
  handleUpdate
}: {
  state: ScoringAnalysisReportFormState;
  handleUpdate: (update: any) => void;
}) {
  const showOtherDomains = state.mode === 'vsOther';

  return (
    <>
      <Form.Group className="mb-3" controlId="name">
        <Form.Label>Analysis name</Form.Label>
        <Form.Control
          placeholder="Example report"
          value={state.name}
          onChange={ev => handleUpdate({ name: ev.target.value })}
        />
      </Form.Group>

      <Form.Label>Target domains</Form.Label>
      <Form.Text>
        Please supply a list of 'good' domains. One per line. These domains should exist in your GoodFit dataset to
        work. Please supply as many as possible, ideally 50+.
      </Form.Text>
      <Form.Control
        as="textarea"
        rows={20}
        style={{ fontFamily: 'monospace', fontSize: 12 }}
        defaultValue={state.goodDomains.join('\n')}
        onChange={ev => handleUpdate({ goodDomains: ev.target.value.split('\n').filter(s => !!s) })}
      />
      <br />
      <div>
        <Form.Check
          inline
          label="Run an analysis of your target companies against all other companies in your GoodFit dataset (default)"
          name="type"
          value="vsAll"
          type="radio"
          defaultChecked={state.mode == 'vsAll'}
          id="mode-vsAll"
          onChange={ev => handleUpdate({ mode: ev.target.value })}
        />
      </div>
      <div>
        <Form.Check
          inline
          label="Run an analysis of your target companies against a specific set of companies (advanced)"
          name="type"
          value="vsOther"
          type="radio"
          defaultChecked={state.mode == 'vsOther'}
          id="mode-vsOther"
          onChange={ev => handleUpdate({ mode: ev.target.value })}
        />
      </div>
      {showOtherDomains && (
        <>
          <hr />
          <Form.Label>Other domains</Form.Label>
          <Form.Text>
            By default scoring analysis compares your target companies to all other companies in your GoodFit dataset,
            although here you can override this list. One per line. These domains should exist in your GoodFit dataset
            to work. Please supply as many as possible, ideally 50+.
          </Form.Text>
          <Form.Control
            as="textarea"
            rows={20}
            style={{ fontFamily: 'monospace', fontSize: 12 }}
            defaultValue={state.otherDomains.join('\n')}
            onChange={ev => handleUpdate({ otherDomains: ev.target.value.split('\n').filter(s => !!s) })}
          />
          <br />
        </>
      )}
    </>
  );
}
