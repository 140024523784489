import { Form } from 'react-bootstrap';

import { useClient } from 'src/auth';
import { ConnectorSettingsOffcanvasProps } from '..';
import { useIntegrationStore } from '../../../integrationsStore';
import { integrationToast } from '../utils';

export function SalesforceHubspotForm({ integration }: ConnectorSettingsOffcanvasProps) {
  const { isContactsEnabled } = useClient();
  const updateConnector = useIntegrationStore(state => state.updateIntegration);

  if (!integration) return <></>;

  return (
    <>
      <Form.Group className="mb-3" controlId="companiesSyncMode">
        <Form.Label>Companies sync mode</Form.Label>
        <Form.Select
          onChange={async ev => {
            await integrationToast(async () => {
              return await updateConnector(integration.id as string, {
                companiesSyncMode: ev.target.value as any
              });
            });
          }}
          value={integration?.companiesSyncMode}
        >
          <option value="CREATE_AND_UPDATE">Create and update</option>
          <option value="UPDATE_ONLY">Update existing only</option>
        </Form.Select>
        <Form.Text>
          If you want GoodFit to only update companies in your system, but not create new records select 'Update
          existing only', otherwise choose 'Create and update'
        </Form.Text>
      </Form.Group>

      {isContactsEnabled && (
        <Form.Group className="mb-3" controlId="contactsSyncMode">
          <Form.Label>Contacts sync mode</Form.Label>
          <Form.Select
            onChange={async ev => {
              await integrationToast(async () => {
                return await updateConnector(integration.id as string, {
                  contactsSyncMode: ev.target.value as any
                });
              });
            }}
            value={integration?.contactsSyncMode}
          >
            <option value="CREATE_AND_UPDATE">Create and update</option>
            <option value="UPDATE_ONLY">Update existing only</option>
            <option value="DISABLED">Disabled</option>
          </Form.Select>
          <Form.Text>
            If you want GoodFit to only update companies in your system, but not create new records select 'Update
            existing only', otherwise choose 'Create and update'
          </Form.Text>
        </Form.Group>
      )}
    </>
  );
}
