import { Card } from 'react-bootstrap';
import { Toggle } from 'src/pages/staff/clientConfigs/components/Toggle.tsx';
import { ContactCriteriaTab } from 'src/pages/staff/clientConfigs/contacts/ContactCriteriaTab.tsx';

type ContactsTabProps = {
  schema: any;
  clientConfig: any;
  isEditing: boolean;
  handleChange: (update: any, path: string) => void;
  handleCommitChanges: () => void;
  handleChangeAndCommit: (update: any, path: string) => void;
};

export function ContactsTab({
  schema,
  clientConfig,
  isEditing,
  handleChange,
  handleCommitChanges,
  handleChangeAndCommit
}: ContactsTabProps) {
  return (
    <Card>
      <Card.Body>
        <Toggle
          flag={clientConfig.contactsEnabled as boolean}
          label={'Contacts enabled'}
          isEditing={isEditing}
          onChange={(update: any) => {
            handleChangeAndCommit(update, 'contactsEnabled');
          }}
        />
        <div className="text-muted mb-2">
          Note: "Contacts Enabled" should be on to allow Segment CRM sync features to work correctly. If you just want
          to disable legacy contacts, but leave segment contacts active, empty contact criteria below but leave this on.
        </div>
        <Toggle
          flag={clientConfig.syncWithoutContacts as boolean}
          label={'Sync without contacts'}
          isEditing={isEditing}
          onChange={(update: any) => {
            handleChangeAndCommit(update, 'syncWithoutContacts');
          }}
        />
        <Toggle
          flag={clientConfig.syncWithoutEmails as boolean}
          label={'Sync without emails'}
          isEditing={isEditing}
          onChange={(update: any) => {
            handleChangeAndCommit(update, 'syncWithoutEmails');
          }}
        />
        <ContactCriteriaTab
          schema={schema}
          clientConfig={clientConfig}
          isEditing={isEditing}
          handleChange={handleChange}
          handleChangeAndCommit={handleChangeAndCommit}
          handleCommitChanges={handleCommitChanges}
        />
      </Card.Body>
    </Card>
  );
}
