import { useState } from 'react';
import { Col, Form } from 'react-bootstrap';

export function Toggle({
  flag,
  label,
  isEditing,
  onChange,
  id
}: {
  flag: boolean;
  label: string;
  isEditing: boolean;
  onChange: (flag: boolean) => void;
  id?: string;
}) {
  const [actualFlag, setFlag] = useState(flag);

  return (
    <Col xs="auto">
      <div className="d-flex mb-2">
        <Form.Check
          type="checkbox"
          checked={actualFlag}
          className=""
          onChange={v => {
            setFlag(v.target.checked);
            onChange(v.target.checked);
          }}
          label={label}
          disabled={!isEditing}
          id={id}
        />
      </div>
    </Col>
  );
}
