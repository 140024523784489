import { createContext } from 'react';
import { ClientContextType } from './types';

let currentClientName: string | undefined = undefined;

export function getCurrentClientName() {
  return currentClientName;
}

export function setCurrentClientName(clientName: string) {
  currentClientName = clientName;
}

const ClientContext = createContext<ClientContextType>(null!);

export default ClientContext;
