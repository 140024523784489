import { usePageTitle } from 'src/utils/usePageTitle.ts';
import { useEffect, useState } from 'react';
import { getClientConfig } from 'src/apis/clients/apis.ts';
import CoverageAnalysis from 'src/pages/staff/coverageAnalysis/components/CoverageAnalysis.tsx';
import PageLoader from 'src/components/PageLoader.tsx';
import { useParams } from 'react-router-dom';

import { getCoverageAnalysis } from 'src/pages/staff/coverageAnalysis/apis.ts';
import { CoverageAnalysisDTO } from 'src/pages/staff/coverageAnalysis/types.ts';
import { useAdminContext } from 'src/adminContext/hooks.ts';
import isEmpty from 'lodash/isEmpty';

export default function CoverageAnalysisPage({ title, readById }: { title: string; readById: boolean }) {
  usePageTitle(title);
  const { schema } = useAdminContext();

  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const clientNameParam = window.location.hash.startsWith('#client=')
    ? window.location.hash.replace('#client=', '')
    : null;

  const [baseConfig, setBaseConfig] = useState<CoverageAnalysisDTO>();

  useEffect(() => {
    if (readById && id && id !== 'new') {
      setIsLoading(true);
      getCoverageAnalysis(id).then(cc => {
        setBaseConfig(cc);
        setIsLoading(false);
      });
    } else if (clientNameParam && !isEmpty(clientNameParam)) {
      getClientConfig(clientNameParam)
        .then(cc => {
          setBaseConfig({ ...cc.config, domains: [], fieldRanges: [] } as unknown as CoverageAnalysisDTO);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setBaseConfig({
        domains: [],
        sourcingCriteria: { rules: [] },
        fieldDefinitions: [],
        dataBlockConfigs: [],
        fieldRanges: []
      } as unknown as CoverageAnalysisDTO);
      setIsLoading(false);
    }
  }, [clientNameParam, id, readById]);

  if (isLoading || !baseConfig) return <PageLoader fullHeight={true} />;

  return (
    // so the pop up doesn't block anything
    <div style={{ paddingBottom: '80px' }}>
      <CoverageAnalysis
        schema={schema}
        baseConfig={baseConfig}
        openDomainsOnStart={id === 'new' || (!!clientNameParam && !isEmpty(clientNameParam))}
      />
    </div>
  );
}
