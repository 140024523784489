import * as joi from 'joi';
import { joiPasswordExtendCore } from 'joi-password';

const joiPassword = joi.extend(joiPasswordExtendCore);

export const validateNewPassword = (password: any) => {
  const passwordComplexity = joiPassword.object({
    password: joiPassword
      .string()
      .min(8)
      .minOfNumeric(1)
      .minOfSpecialCharacters(1)
      .minOfUppercase(1)
      .minOfLowercase(1)
      .required()
      .messages({
        'string.min': 'Password must have at least 8 characters',
        'password.minOfNumeric': 'Password must have at least one numeric character',
        'password.minOfSpecialCharacters': 'Password must have at least one special character',
        'password.minOfUppercase': 'Password must have at least one uppercase letter',
        'password.minOfLowercase': 'Password must have at least one lowercase letter',
        'any.required': 'Password is required'
      })
  });
  const { error } = passwordComplexity.validate({ password });

  return error;
};
