import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import SimplePaginator from 'src/components/Table/SimplePaginator';
import TableGrid from 'src/components/Table/TableGrid';
import { titleCase } from 'src/utils/string';
import { ErrorSummaryRow, loadErrorsSummary } from 'src/apis/integrations';
import { Button } from 'react-bootstrap';
import { mapError } from 'src/pages/settings/connector-logs/components/errorMaps.ts';
import ErrorDetailsOffcanvas from 'src/pages/settings/connector-logs/components/ErrorDetailsOffcanvas.tsx';

const PAGE_SIZE = 10;

export default function ErrorsTable({ connectorType }: any) {
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<ErrorSummaryRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOffcanvasShowing, setIsOffcanvasShowing] = useState(false);
  const [selectedError, setSelectedError] = useState<ErrorSummaryRow | null>(null);

  useEffect(() => {
    setIsLoading(true);
    loadErrorsSummary(connectorType, PAGE_SIZE, page * PAGE_SIZE).then(data => {
      setRows(data.errors as ErrorSummaryRow[]);
      setNumPages(Math.ceil(data.errorsCnt / PAGE_SIZE));
      setIsLoading(false);
    });
  }, [connectorType, page]);

  const table = useReactTable<ErrorSummaryRow>({
    columns: [
      {
        header: 'Integration',
        id: 'integrationType',
        accessorFn: row => titleCase(row.integrationType),
        enableSorting: false
      },
      {
        header: 'Record Type',
        id: 'recordType',
        accessorFn: row => titleCase(row.recordType),
        enableSorting: false
      },
      {
        header: 'Field',
        accessorFn: row => row.field || '-',
        enableSorting: false
      },
      {
        header: 'Error',
        accessorFn: row => mapError(row.errorCode, 'original error', row.field).shortDescription,
        enableSorting: false
      },
      {
        header: '#Records',
        accessorKey: 'errorCount',
        enableSorting: false
      },
      {
        header: 'Actions',
        id: 'actions',
        cell: c => (
          <Button
            variant="white"
            size="sm"
            onClick={() => {
              setSelectedError(c.row.original);
              setIsOffcanvasShowing(true);
            }}
          >
            Show details
          </Button>
        )
      }
    ],
    data: rows,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });

  return (
    <>
      <TableGrid
        table={table}
        isLoading={isLoading}
        emptyMessage="No errors have occurred"
        numLoadingRows={PAGE_SIZE}
        className="table-centered table-compact table-nowrap table-fixed errors-summary-table"
      />
      <div className="d-flex justify-content-end px-5 pb-3">
        <SimplePaginator pageIndex={0} totalPages={numPages} setPage={setPage} />
      </div>
      {selectedError && (
        <ErrorDetailsOffcanvas
          error={selectedError as ErrorSummaryRow}
          show={isOffcanvasShowing}
          onHide={() => setIsOffcanvasShowing(false)}
        />
      )}
    </>
  );
}
