import { Button } from 'react-bootstrap';
import { useAuth, useClient } from '../../auth';
import { useState } from 'react';
import { usePageTitle } from 'src/utils/usePageTitle.ts';

export default function TestPage({ title }: any) {
  const [isError, setIsError] = useState(false);
  const auth = useAuth();
  const client = useClient();

  usePageTitle(title);

  if (isError) {
    throw Error('Bad shit');
  }

  return (
    <>
      <div>
        Hello
        <br />
        User: {auth.user?.email} {auth.user?.id}
        <br />
        Client: {client.clientName} with permissions {client.userPermissions}
        <br />
        Feature flags: {JSON.stringify(client.featureFlags)}
        <br />
        Has feature flag:
        <br />- test {JSON.stringify(client.hasFeatureFlag('test'))}
        <br />- pants {JSON.stringify(client.hasFeatureFlag('pants'))}
      </div>
      <br />
      <div>
        Test error boundary: <br />
        <Button onClick={() => setIsError(true)}>Throw an error</Button> <br />
        When clicked, this will show an error boundary only in the main content area, nav will still be visible.
      </div>
    </>
  );
}
