import API from 'src/utils/API';
import { LockableResource, ResourceLock } from './types';

/** Get any existing lock for the target resource */
export async function getExistingLockForResource(
  resourceType: LockableResource,
  resourceId: string
): Promise<ResourceLock | null> {
  return await API.get<ResourceLock | null>(`/app/resource-locks/${resourceType}/${resourceId}`, {});
}

/** Secure a lock for the target resource */
export async function secureLockForResource(
  resourceType: LockableResource,
  resourceId: string,
  lockedBy?: string
): Promise<ResourceLock> {
  return await API.post<ResourceLock>(
    `/app/resource-locks/${resourceType}/${resourceId}`,
    {},
    lockedBy ? { lockedBy } : {}
  );
}

/** Release a lock for the target resource */
export async function releaseLockForResource(resourceType: LockableResource, resourceId: string) {
  return await API.del(`/app/resource-locks/${resourceType}/${resourceId}`);
}
