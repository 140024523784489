import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export function BasicConfirmationModal({
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  title = 'Confirmation',
  confirmation,
  show,
  proceed,
  enableEscape = true
}: any) {
  return (
    <div className="static-modal">
      <Modal
        animation={true}
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : 'static'}
        keyboard={enableEscape}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmation}</Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="light" onClick={() => proceed(false)}>
            {cancelLabel}
          </Button>
          <Button className="button-l" variant="primary" size="sm" onClick={() => proceed(true)}>
            {okLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

BasicConfirmationModal.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
};
