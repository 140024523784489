import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { DataBlockFieldDataType } from 'src/apis/clients/types.ts';

import moment from 'moment';
import uniq from 'lodash/uniq';

type RangesSetupModalProps = {
  rule: string | undefined;
  onHide: () => void;
  getRanges: (rule: string) => (number | string)[];
  setRanges: (rule: string, range: (number | string)[]) => void;
  getRuleFieldType: (rule: string) => DataBlockFieldDataType;
};

export function RangesSetupModal({ onHide, rule, getRanges, setRanges, getRuleFieldType }: RangesSetupModalProps) {
  const [rangesEdit, setRangesEdit] = useState<(number | string)[]>([]);

  useEffect(() => {
    rule && setRangesEdit(getRanges(rule));
  }, [getRanges, rule]);

  const isValid = useCallback(() => {
    if (!rule) return false;
    const fieldType = getRuleFieldType(rule);
    if (['Number', 'Currency', 'Percentage'].includes(fieldType)) {
      return !rangesEdit.find(v => isNaN(v as number));
    } else if (fieldType === 'Date') {
      return rangesEdit.every(v => moment(v.toString(), 'YYYY-MM-DD', true).isValid());
    } else {
      return true;
    }
  }, [getRuleFieldType, rangesEdit, rule]);

  const saveRanges = useCallback(() => {
    rule &&
      setRanges(
        rule,
        uniq(rangesEdit).sort((a, b) => {
          if (isNaN(a as number) || isNaN(b as number)) {
            return a.toString().localeCompare(b.toString());
          }
          return (a as number) - (b as number);
        })
      );
    onHide();
  }, [onHide, rangesEdit, rule, setRanges]);

  return (
    <Modal show={!!rule}>
      <Modal.Header>
        <Modal.Title>Setup Ranges</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          as="textarea"
          rows={20}
          style={{ fontFamily: 'monospace', fontSize: 12 }}
          defaultValue={rangesEdit.join(',')}
          isInvalid={!(rangesEdit.length > 0 && rangesEdit.length <= 1000) || !isValid()}
          onChange={ev =>
            setRangesEdit(
              ev.target.value
                .split(',')
                .filter(s => !!s)
                .map(s => s.trim()) as (string | number)[]
            )
          }
        />
        <Form.Control.Feedback type="invalid">
          {!(rangesEdit.length > 0 && rangesEdit.length <= 1000)
            ? 'There have to be more than 0 and less than 1000 ranges.'
            : !isValid() && getRuleFieldType(rule as string) === 'Date'
              ? 'Please make sure all fields are correct dates in YYYY-MM-DD format'
              : 'Please make sure all fields are correct numbers'}
        </Form.Control.Feedback>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" size="sm" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" size="sm" onClick={saveRanges} disabled={!isValid()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
