import API from 'src/utils/API.ts';

export async function fetchFieldOptions(field: any, dataBlockConfig: any | undefined, search: string | undefined) {
  const params: any = {
    field: JSON.stringify(field),
    search
  };
  if (dataBlockConfig) {
    params.dataBlockConfig = JSON.stringify(dataBlockConfig);
  }

  return await API.get('/app/data-blocks/field-options', params);
}
