import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Form, Modal, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { CustomDataField } from 'src/apis/clients/types';
import { showModal } from 'src/utils/modals';
import { Toggle } from './Toggle';
import JsonEditor from 'src/components/JsonEditor/JsonEditor';

const DEFAULT_FIELD: CustomDataField = {
  name: '',
  description: '',
  displayName: '',
  type: 'String',
  fromField: ''
};

type CustomDataFieldsEditorProps = {
  isEditing: boolean;
  fields: CustomDataField[];
  onChange: (newFields: CustomDataField[]) => void;
};

export function CustomDataFieldsEditor({
  isEditing,
  fields,
  onChange,
  schema
}: CustomDataFieldsEditorProps & { schema: any }) {
  const [editAsJSON, setEditAsJSON] = useState(false);

  return (
    <>
      {isEditing && (
        <Alert variant="primary">
          Take care removing, renaming or changing type of fields if they are referenced from sourcing criteria, tags or
          scoring.
        </Alert>
      )}
      <div className="d-flex justify-content-end">
        <Toggle
          flag={editAsJSON}
          label={'As JSON'}
          isEditing={true}
          onChange={() => {
            setEditAsJSON(!editAsJSON);
          }}
        />
      </div>
      {editAsJSON ? (
        <JsonEditor
          schema={schema.definitions.CustomDataFields}
          referenceSchema={schema}
          json={fields}
          isEditing={isEditing}
          onChange={onChange}
        />
      ) : (
        <CustomDataFieldsEditorTable isEditing={isEditing} fields={fields} onChange={onChange} />
      )}
    </>
  );
}

function CustomDataFieldsEditorTable({ isEditing, fields, onChange }: CustomDataFieldsEditorProps) {
  const handleRemoveClicked = useCallback(
    (fieldName: string) => {
      const newFields = fields.filter(f => f.name !== fieldName);
      onChange(newFields);
    },
    [fields, onChange]
  );

  const handleAddField = useCallback(
    (newField: CustomDataField) => {
      const newFieldName = newField.name;
      if (fields.find(f => f.name === newFieldName)) {
        toast.error('Cant add field with name that already exists');
        return;
      }
      const newFields = [...fields, newField];
      onChange(newFields);
    },
    [fields, onChange]
  );
  const handleEditField = useCallback(
    (fieldName: string, updatedField: CustomDataField) => {
      const newFields = fields.map(field => {
        if (field.name === fieldName) {
          return updatedField;
        } else {
          return field;
        }
      });
      onChange(newFields);
    },
    [fields, onChange]
  );

  return (
    <div className="my-t mb-4">
      <Table size="sm" bordered className="mb-2">
        <thead>
          <tr>
            <th>Field</th>
            <th>Display Label</th>
            <th>Data Type</th>
            <th>Field Type</th>
            <th>Description</th>
            <th>From Field / Expression</th>
            {isEditing && <th></th>}
          </tr>
        </thead>
        <tbody>
          {fields.map((field: any) => (
            <tr key={field.name}>
              <td>{field.name}</td>
              <td>{field.displayName}</td>
              <td>{field.type}</td>
              <td>{field.expressionSql ? 'Expression' : 'Upload'}</td>
              <td>{field.description}</td>
              <td>{field.fromField ? field.fromField : <pre>{field.expressionSql}</pre>}</td>

              {isEditing && (
                <td>
                  <Button
                    size="sm"
                    variant="white"
                    className="me-2"
                    onClick={() =>
                      showModal(CustomDataFieldModal, { isNew: false, field }, (updatedField: CustomDataField) =>
                        handleEditField(field.name, updatedField)
                      )
                    }
                  >
                    Edit
                  </Button>
                  <Button size="sm" variant="white" onClick={() => handleRemoveClicked(field.name)}>
                    ✕
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <div>
        {isEditing && (
          <Button
            size="sm"
            variant="secondary"
            onClick={() => showModal(CustomDataFieldModal, { isNew: true }, handleAddField)}
          >
            Add custom field
          </Button>
        )}
      </div>
    </div>
  );
}

function CustomDataFieldModal({
  field,
  isNew,
  show,
  onSubmit,
  onHide
}: {
  field?: CustomDataField;
  show: boolean;
  isNew: boolean;
  onSubmit: any;
  onHide: () => void;
}) {
  const [fieldMode, setFieldMode] = useState<string | null>(null);
  const [fieldState, setFieldState] = useState<CustomDataField>(DEFAULT_FIELD);

  useEffect(() => {
    setFieldState(field ?? DEFAULT_FIELD);
    setFieldMode(field?.expressionSql ? 'expressionSql' : 'fromField');
  }, [field]);

  return (
    <Modal show={show} onHide={onHide}>
      <Form
        onSubmit={ev => {
          ev.preventDefault();
          const formData = new FormData(ev.currentTarget);

          if (fieldState) {
            const newState = {
              name: formData.get('name'),
              type: formData.get('type'),
              displayName: formData.get('displayName'),
              description: formData.get('description'),
              fromField: formData.get('fromField'),
              expressionSql: formData.get('expressionSql')
            } as CustomDataField;
            if (fieldMode === 'expressionSql') {
              newState.fromField = undefined;
            } else {
              newState.expressionSql = undefined;
              if (!newState.fromField) newState.fromField = newState.name; // Default to same as name
            }
            setFieldState(DEFAULT_FIELD);
            onSubmit(newState);
          }
        }}
      >
        <Modal.Header closeButton>{isNew ? 'New custom field' : 'Edit custom field'}</Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Field name</Form.Label>
            <Form.Control
              type="text"
              readOnly={!isNew}
              defaultValue={fieldState?.name}
              required
              name="name"
              placeholder="Name"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="type">
            <Form.Label>Field data type</Form.Label>
            <Form.Select required name="type" defaultValue={fieldState?.type}>
              <option value="String">String</option>
              <option value="Picklist">Picklist</option>
              <option value="Multipicklist">Multipicklist</option>
              <option value="Array">Array</option>
              <option value="Number">Number</option>
              <option value="Currency">Currency</option>
              <option value="Percentage">Percentage</option>
              <option value="Boolean">Boolean</option>
              <option value="Date">Date</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="displayName">
            <Form.Label>Field display label</Form.Label>
            <Form.Control type="text" name="displayName" defaultValue={fieldState?.displayName} placeholder="" />
            <Form.Text className="text-muted">
              Optional override its column name in app. This just changes what its called, not its references in tags,
              filters etc.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" name="description" defaultValue={fieldState?.description} placeholder="" />
            <Form.Text className="text-muted">Optional description for data dictionary</Form.Text>
          </Form.Group>

          <div className="mb-3">
            <Form.Label>Field mode</Form.Label>

            <Form.Check
              type="radio"
              label="From field in upload"
              id={'mode-fromField'}
              checked={fieldMode === 'fromField'}
              onChange={ev => {
                if (ev.target.value) {
                  setFieldMode('fromField');
                }
              }}
            />
            <Form.Check
              type="radio"
              label="From SQL expression (advanced)"
              id={'mode-expressionSql'}
              checked={fieldMode === 'expressionSql'}
              onChange={ev => {
                if (ev.target.value) {
                  setFieldMode('expressionSql');
                }
              }}
            />
          </div>

          {fieldMode === 'fromField' && !isNew && (
            <Form.Group className="mb-3" controlId="fromField">
              <Form.Label>From data upload field</Form.Label>
              <Form.Control type="text" name="fromField" defaultValue={fieldState?.fromField} />
              <Form.Text className="text-muted">
                Field name in custom data file. Typically is the same as field name above but can be overridden for
                historic cases.
              </Form.Text>
            </Form.Group>
          )}

          {fieldMode === 'expressionSql' && (
            <Form.Group className="mb-3" controlId="expressionSql">
              <Form.Label>Expression SQL</Form.Label>
              <Form.Control type="text" required name="expressionSql" defaultValue={fieldState?.expressionSql} />
              <Form.Text className="text-muted">
                Warning: We dont validate this expression here, if its invalid it will break the dataset build for this
                client.
              </Form.Text>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="white" onClick={onHide}>
            Cancel
          </Button>
          &nbsp;
          <Button type="submit" size="sm">
            Save custom field
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
