import { useEffect, useState } from 'react';
import API from 'src/utils/API.ts';

// Trigger must return exectionId, and endpoint must be a string with :executionId placeholder,
// like '/admin/page-elements/batch-estimate/:executionId'
export function useLongRunningTask(trigger: () => Promise<string>, endpoint: string, pollInterval = 5000) {
  const [executionId, setExecutionId] = useState<string | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState<any>();

  useEffect(() => {
    trigger()
      .then(data => {
        setExecutionId(data);
      })
      .catch(e => {
        setIsLoading(false);
        setError(e);
      });
  }, [trigger]);

  useEffect(() => {
    let interval: any;

    const pollResults = async () => {
      if (executionId) {
        const result = await API.get<{ status: string; output: any }>(
          endpoint.replace(':executionId', executionId),
          {}
        );
        if (['SUCCEEDED', 'FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status)) {
          clearInterval(interval);
          setIsLoading(false);
          setResult(result);
          setExecutionId(null);
          // Fails if the step function failed, or if it SUCCEEDED but output contains error key
          const didFail = ['FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status) || result?.output?.error;
          if (didFail) setError(result);
        }
      }
      interval = setTimeout(pollResults, pollInterval);
    };

    if (executionId) {
      interval = setTimeout(pollResults, pollInterval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [endpoint, executionId, pollInterval]);

  return { isLoading, result, error };
}
