import TableGrid from 'src/components/Table/TableGrid.tsx';
import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FieldMapping } from 'src/components/Filters/CCMFilters/services/types.ts';
import { CELL_RENDERERS } from 'src/components/Table/cellRenderers.tsx';
import { SchemaDataType } from 'src/auth';
import attempt from 'lodash/attempt';
import isArray from 'lodash/isArray';
import isError from 'lodash/isError';

export function MarketEstimationsTable({
  fieldDefinitions,
  fieldMapping,
  result,
  isLoading
}: {
  fieldDefinitions: any[];
  fieldMapping: FieldMapping[];
  result: {
    isValid: boolean;
    sample: any[];
    errors: any[];
  };
  isLoading: boolean;
}) {
  const columns = useMemo(
    () =>
      fieldDefinitions?.map((fd: any, index) => ({
        header: fd.externalName || fd.internalName,
        id: `${fd.externalName} ${fd.internalName} ${index}`,
        cell: (props: any) => {
          const type = fieldMapping.find(
            fm => fm.dataBlockField === fd.internalName && (!fd.config_id || fd.config === fm.configId)
          )?.type as SchemaDataType;
          if (['Multipicklist', 'Array'].includes(type)) {
            // A hack: we should do it properly on BE, but this is a quick hack to show the super data the correct way
            let value = props.row.original[fd.externalName];
            const parsed = attempt(JSON.parse, value);
            if (!isError(parsed) && isArray(parsed)) {
              value = parsed;
            } else {
              value = value?.split(';');
            }
            return CELL_RENDERERS[type](value || []);
          }
          return CELL_RENDERERS[type](props.row.original[fd.externalName]);
        },
        enableSorting: false
      })),
    [fieldDefinitions, fieldMapping]
  );

  const table = useReactTable<any>({
    columns: columns,
    data: result?.sample || [],
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {}
  });
  return (
    <div>
      {result?.isValid === false && (
        <div className="m-1 container-fluid">
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Invalid configuration</h4>
            <p>There are errors in the configuration</p>
            <hr />
            <p className="mb-0">
              <ul>
                {result.errors.map((error: any) => (
                  <li key={error.path}>
                    {error.message} for {error.instancePath}
                  </li>
                ))}
              </ul>
            </p>
          </div>
        </div>
      )}
      <TableGrid
        table={table}
        isLoading={isLoading}
        emptyMessage="No Records found"
        numLoadingRows={50}
        responsive={true}
        className="table-centered table-compact table-nowrap errors-summary-table"
      />
    </div>
  );
}
