import { ClientConfig } from 'src/apis/clients/types.ts';
import { Form, Table } from 'react-bootstrap';
import { FiTrash2 } from 'react-icons/fi';
import { mandatoryFields } from 'src/pages/staff/clientConfigs/sourcing/fieldDefinitions/mandatoryFields.ts';
import { ClientConfigErrors } from 'src/pages/staff/clientConfigs/validation/types.ts';

export function FieldDefinitionsTable({
  isEditing,
  clientConfig,
  handleExternalNameChange,
  removeField,
  errors
}: {
  isEditing: boolean;
  clientConfig: ClientConfig;
  handleExternalNameChange: (index: number, value: string) => void;
  removeField: (index: number) => void;
  errors: ClientConfigErrors | null;
}) {
  return (
    <Table bordered size="sm" style={{ backgroundColor: 'white', margin: 0 }}>
      <thead>
        <tr>
          <th>Internal Name</th>
          <th>External Name</th>
          <th>Config ID</th>
          {isEditing && <th></th>}
        </tr>
      </thead>
      <tbody>
        {clientConfig.fieldDefinitions.map((field: any, index: number) => (
          <tr key={`field_definitions_table_${index}`}>
            <td className={'p-2'}>{field.internalName}</td>
            <td
              className={'p-2'}
              style={{
                backgroundColor:
                  errors?.validationsFailed?.fieldExternalNameIsNull?.find(f => f.index === index) ||
                  errors?.validationsFailed?.fieldExternalNameNotUnique?.find(f => f.index === index)
                    ? '#e35d6a'
                    : 'inherit'
              }}
            >
              {(isEditing && // is not a mandatory field
                !mandatoryFields.find(
                  mandatory =>
                    field.internalName === mandatory.internalName && field.externalName === mandatory.externalName
                ) && (
                  <Form.Control
                    className={'py-0'}
                    type="text"
                    size="sm"
                    value={field.externalName}
                    aria-label="External name"
                    placeholder=""
                    onChange={e => handleExternalNameChange(index, e.target.value.trim())}
                    disabled={!isEditing}
                  />
                )) || <>{field.externalName}</>}
            </td>
            <td className={'p-2'}>
              <div>{field.configId}</div>
            </td>
            {isEditing &&
              // is not a mandatory field
              !mandatoryFields.find(
                mandatory =>
                  field.internalName === mandatory.internalName && field.externalName === mandatory.externalName
              ) && (
                <td className={'d-flex justify-content-center p-2'}>
                  <FiTrash2 color={'#e35d6a'} size={'1rem'} className={'m-1'} onClick={() => removeField(index)} />
                </td>
              )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
