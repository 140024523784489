import { ValueEditorProps } from 'react-querybuilder';
import {
  BooleanInput,
  CurrencyInput,
  DateInput,
  InputPair,
  NumberInput,
  PercentageInput,
  TextInput
} from 'src/components/Filters/Inputs/inputs.tsx';
import { CompanyCriteriaFilterOperator, DataBlockFieldDataType } from 'src/apis/clients/types.ts';
import CCMFieldValueMultiSelect from 'src/components/Filters/CCMFilters/CCMFieldValueMultiSelect.tsx';

export type CustomValueEditorComponentProps = {
  fieldName: string;
  type: DataBlockFieldDataType;
  operator: CompanyCriteriaFilterOperator;
  value: any;
  handleOnChange: (value: any) => void;
  disabled?: boolean;
  loadOptions: (fieldName: string, search: string) => Promise<{ value: string; label: string }[]>;
};

export const CustomValueEditorComponent = ({
  fieldName,
  type,
  operator,
  value,
  handleOnChange,
  disabled,
  loadOptions
}: CustomValueEditorComponentProps) => {
  if (fieldName === '') return <></>;

  if (operator === CompanyCriteriaFilterOperator.NULL || operator === CompanyCriteriaFilterOperator.NOT_NULL)
    return <></>;

  // Text input with typeahead selection
  if (
    (type === 'String' || type === 'Picklist') &&
    [
      CompanyCriteriaFilterOperator.IN,
      CompanyCriteriaFilterOperator.NOT_IN,
      CompanyCriteriaFilterOperator.STRING_MATCHES_ANY_TEXT,
      CompanyCriteriaFilterOperator.STRING_NOT_MATCHES_ANY_TEXT
    ].includes(operator)
  ) {
    const allowFreeTextEntry = [
      CompanyCriteriaFilterOperator.STRING_MATCHES_ANY_TEXT,
      CompanyCriteriaFilterOperator.STRING_NOT_MATCHES_ANY_TEXT
    ].includes(operator);
    return (
      <CCMFieldValueMultiSelect
        fieldName={fieldName}
        onChange={handleOnChange}
        value={value}
        allowNew={allowFreeTextEntry}
        disabled={disabled}
        loadOptions={loadOptions}
        isMulti={true}
      />
    );
  }
  if (type === 'Multipicklist' || type === 'Array') {
    if ([CompanyCriteriaFilterOperator.LIST_CONTAINS].includes(operator))
      return (
        <CCMFieldValueMultiSelect
          fieldName={fieldName}
          onChange={handleOnChange}
          value={value}
          allowNew={true}
          disabled={disabled}
          loadOptions={loadOptions}
          isMulti={false}
        />
      );
    if (
      [
        CompanyCriteriaFilterOperator.ARRAY_CONTAINS_ANY_OF,
        CompanyCriteriaFilterOperator.ARRAY_NOT_CONTAINS_ANY_OF,
        CompanyCriteriaFilterOperator.ARRAY_MATCHES_ANY_TEXT,
        CompanyCriteriaFilterOperator.ARRAY_NOT_MATCHES_ANY_TEXT
      ].includes(operator)
    ) {
      const allowNew = [
        CompanyCriteriaFilterOperator.ARRAY_MATCHES_ANY_TEXT,
        CompanyCriteriaFilterOperator.ARRAY_NOT_MATCHES_ANY_TEXT
      ].includes(operator);
      return (
        <CCMFieldValueMultiSelect
          fieldName={fieldName}
          onChange={handleOnChange}
          value={value}
          allowNew={allowNew}
          disabled={disabled}
          loadOptions={loadOptions}
          isMulti={true}
        />
      );
    }
  }

  // Standard free text input
  if (
    (type === 'String' || type === 'Picklist' || type === 'Multipicklist' || type === 'Array') &&
    [
      CompanyCriteriaFilterOperator.EQUALS,
      CompanyCriteriaFilterOperator.NOT_EQUALS,
      CompanyCriteriaFilterOperator.STRING_CONTAINS,
      CompanyCriteriaFilterOperator.LIST_CONTAINS,
      CompanyCriteriaFilterOperator.REGEX_CONTAINS,
      CompanyCriteriaFilterOperator.REGEX_NOT_CONTAINS,
      CompanyCriteriaFilterOperator.REGEX_CONTAINS_CASEINSENSITIVE,
      CompanyCriteriaFilterOperator.REGEX_NOT_CONTAINS_CASEINSENSITIVE,
      CompanyCriteriaFilterOperator.STRING_BEGINS_WITH,
      CompanyCriteriaFilterOperator.STRING_ENDS_WITH
    ].includes(operator)
  ) {
    return <TextInput handleOnChange={handleOnChange} value={value} disabled={disabled} />;
  }

  // Numeric types, number input
  if (type === 'Number' || type === 'Percentage' || type === 'Currency') {
    let InputType = NumberInput;
    if (type === 'Percentage') InputType = PercentageInput;
    if (type === 'Currency') InputType = CurrencyInput;

    if (operator === CompanyCriteriaFilterOperator.BETWEEN) {
      return <InputPair handleOnChange={handleOnChange} value={value} inputType={InputType} disabled={disabled} />;
    } else {
      return <InputType handleOnChange={handleOnChange} value={value} disabled={disabled} />;
    }
  }

  // Date, date input
  if (type === 'Date') {
    if (operator === CompanyCriteriaFilterOperator.BETWEEN) {
      return <InputPair handleOnChange={handleOnChange} value={value} inputType={DateInput} disabled={disabled} />;
    } else {
      return <DateInput handleOnChange={handleOnChange} value={value} disabled={disabled} />;
    }
  }
  if (
    type === 'Boolean' &&
    [CompanyCriteriaFilterOperator.EQUALS, CompanyCriteriaFilterOperator.NOT_EQUALS].includes(operator)
  ) {
    return <BooleanInput handleOnChange={handleOnChange} value={value} disabled={disabled} />;
  }
  return <></>;
};

export const CustomValueEditor = (
  props: ValueEditorProps & {
    disabled?: boolean;
    loadOptions: (fieldName: string, search: string) => Promise<{ value: string; label: string }[]>;
  }
) => {
  const type = props.fieldData.type as DataBlockFieldDataType;
  const operator = props.operator as CompanyCriteriaFilterOperator;
  const disabled = props.disabled as boolean;
  return (
    <CustomValueEditorComponent
      fieldName={props.field}
      type={type}
      operator={operator}
      value={props.value}
      handleOnChange={props.handleOnChange}
      disabled={disabled}
      loadOptions={props.loadOptions}
    />
  );
};
