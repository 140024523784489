import { useClient } from 'src/auth';
import { RollingProgressBar } from 'src/components/RollingProgressBar';
import { CreditSummaryTexts } from './produceSummaryTexts';

export const ContactCreditPanel: React.FC<{ summaryTexts: CreditSummaryTexts }> = ({ summaryTexts }) => {
  const { companiesSummary, contactCreditsSummary, enrichmentCreditsSummary } = summaryTexts;

  const { limits, usageCounts } = useClient();

  const companiesBalance = Object.freeze({
    limit: limits.companies ?? null,
    usage: usageCounts.companies
  });

  return (
    <div className="contact-credit-panel">
      <div className="d-flex align-items-center">
        <div className="me-2 companies-label">{companiesSummary}</div>
        {companiesBalance.limit !== null && (
          <RollingProgressBar
            visuallyHidden
            className="balance-indicator"
            value={Math.ceil((companiesBalance.usage / companiesBalance.limit) * 100)}
          />
        )}
      </div>

      {contactCreditsSummary !== null && <div>{contactCreditsSummary}</div>}
      {enrichmentCreditsSummary !== null && <div>{enrichmentCreditsSummary}</div>}

      <br />

      <div className="mt-auto text-center">Contact support at cs@goodfit.io to increase your credits.</div>
    </div>
  );
};
