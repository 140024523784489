import startCase from 'lodash/startCase';
import { Button, Form, Modal } from 'react-bootstrap';

import { Integration, isAnyJobRunning, startJob } from 'src/apis/integrations';
import { TooltipWrapper } from 'src/components/TooltipWrapper';
import { Segment } from 'src/pages/segments/types';
import { SyncNowButton } from './SyncNowButton';
import { useState } from 'react';
import { isAutoSyncEnabledForSegment } from '../../utils/isAutoSyncEnabledForSegment';
import { setSegmentAutoSync } from 'src/pages/segments/apis';
import toast from 'react-hot-toast';
import { pluralize } from 'src/utils/string';
import { useIntegrationStore } from 'src/pages/settings/connectors/integrationsStore';

interface ModalProps {
  show: boolean;
  onSubmit(): void;
  onHide: () => void;
  integration: Integration;
  segment: Segment;
}

const startSyncWithToast = async (integration: Integration, segment: Segment): Promise<void> => {
  const startingSyncToast = toast.loading('Sync starting...');
  const syncJob = await startJob(integration.id, [], [segment.id]);

  toast.dismiss(startingSyncToast);

  if (syncJob.started) toast.success('Sync started');
  else toast.error('Unable to start sync, please contact the GoodFit team');
};

export const SyncSettingsModal: React.FC<ModalProps> = ({ show, onSubmit, onHide, integration, segment }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { fetchIntegrations } = useIntegrationStore();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    try {
      setIsSaving(true);

      // We only need to handle autoSyncStatus if `syncSegmentRecordsOnly === true`
      if (integration.enableAutoSync && integration.syncSegmentRecordsOnly) {
        const formData = new FormData(e.currentTarget);
        const autoSyncStatusToggleValue = formData.get('auto-sync-status') === 'on';

        const isAutoSyncEnabled = isAutoSyncEnabledForSegment(segment, integration);
        const settingHasChanged = autoSyncStatusToggleValue !== isAutoSyncEnabled;

        if (settingHasChanged) await setSegmentAutoSync(autoSyncStatusToggleValue, integration.id, segment.id);

        // Integrations have changed, trigger a refresh without awaiting
        void fetchIntegrations();
      }

      const syncStatus = await isAnyJobRunning(integration.id);

      if (syncStatus.canStartJob) await startSyncWithToast(integration, segment);
      else toast.error('A sync is currently in progress');
    } catch (e) {
      console.warn(e);
      toast.error('Sorry, something went wrong!');
    } finally {
      setIsSaving(false);
      onSubmit();
    }
  };

  const integrationName = startCase(integration.integrationType.toLowerCase());

  const autoSyncStatus = isAutoSyncEnabledForSegment(segment, integration);

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>Start Sync</Modal.Header>

        <Modal.Body>
          <p>
            This will sync{' '}
            <strong>
              {segment.sizeCompanies} {pluralize(segment.sizeCompanies ?? 0, 'Company', 'Companies')}
            </strong>{' '}
            and{' '}
            <strong>
              {segment.sizeContacts} {pluralize(segment.sizeContacts ?? 0, 'Contact', 'Contacts')}
            </strong>{' '}
            to your {integrationName} integration.
          </p>
          <p>Do you want to continue?</p>

          {integration.enableAutoSync && integration.syncSegmentRecordsOnly && (
            <Form.Group className="mb-3 pt-3">
              <Form.Check
                type="switch"
                id="auto-sync-status"
                name="auto-sync-status"
                title="Enable auto sync"
                defaultChecked={autoSyncStatus}
                label={
                  <TooltipWrapper
                    placement="bottom"
                    tooltipText="When enabled any new company or contact added to the segment will be automatically synced."
                    iconLabelText="Enable auto sync"
                  />
                }
              />
            </Form.Group>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="white" className="me-2" onClick={onHide}>
            Cancel
          </Button>

          <SyncNowButton integration={integration} isSaving={isSaving} />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
