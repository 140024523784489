import { Button, Form, Modal, Row, Spinner } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { ClientConfig } from 'src/apis/clients/types.ts';
import { getResults, triggerEstimation } from 'src/pages/staff/marketEstimations/apis.ts';

type PreSaveModalProps = {
  show: boolean;
  onHide: () => void;
  save: () => void;
  clientName?: string;
  newClientConfig: ClientConfig;
  clientConfigBeforeChanges: ClientConfig | null;
};

// sadly requires a bit more elaborate comparison. To ignore undefined values and make sure nested properties work
function isEqual(obj1: any, obj2: any, path: string[] = []): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1).filter(key => obj1[key] !== undefined);
  const keys2 = Object.keys(obj2).filter(key => obj2[key] !== undefined);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }

    const newPath = path.concat(key);
    if (!isEqual(obj1[key], obj2[key], newPath)) {
      return false;
    }
  }

  return true;
}

export function PreSaveModal({
  show,
  onHide,
  clientName,
  save,
  newClientConfig,
  clientConfigBeforeChanges
}: PreSaveModalProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isNoChangeToSC, setIsNoChangeToSC] = useState(true);
  const [executionId, setExecutionId] = useState<string | null>(null);
  const [result, setResult] = useState<any>();
  const [confirmationValue, setConfirmationValue] = useState<string>('');

  const closeModal = useCallback(() => {
    setExecutionId(null);
    setResult(null);
    setConfirmationValue('');
    onHide();
  }, [onHide]);

  useEffect(() => {
    if (show) {
      if (isEqual(newClientConfig?.sourcingCriteria, clientConfigBeforeChanges?.sourcingCriteria)) {
        setIsLoading(false);
        setResult(undefined);
        setIsNoChangeToSC(true);
      } else {
        setIsNoChangeToSC(false);
        setIsLoading(true);
        setResult(undefined);
        const payload = { ...newClientConfig } as any;
        payload.excludeClientDestinationsFor = clientName;
        payload.fieldDefinitions = [
          {
            externalName: 'domain',
            internalName: 'firmographics/primary_domain'
          }
        ];
        triggerEstimation(payload).then(result => {
          if (result.executionId) {
            setExecutionId(result.executionId);
          }
        });
      }
    }
  }, [clientConfigBeforeChanges?.sourcingCriteria, clientName, newClientConfig, show]);

  useEffect(() => {
    let interval: any;

    const pollResults = async () => {
      if (executionId) {
        const result = await getResults(executionId);
        if (['SUCCEEDED', 'FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status)) {
          clearInterval(interval);
          setExecutionId(null);
          setIsLoading(false);
          setResult(result.output);
          const didFail = ['FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status) || result?.output?.error;
          const errorMessage = result?.output?.error ?? result.status;
          if (didFail) alert(`Sorry, preview errored: ${errorMessage}`);
        }
        interval = setTimeout(pollResults, 5000); // Re-poll every 5 seconds
      }
    };

    if (executionId) {
      interval = setTimeout(pollResults, 5000); // Poll every 5 seconds
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [executionId]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      save();
      closeModal();
    },
    [save, closeModal]
  );

  return (
    <Modal show={show} onHide={closeModal} size={'sm'}>
      <Modal.Header closeButton>Save config</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {isNoChangeToSC && (
            <>
              <Row className={'d-flex justify-content-center mb-2'}>
                <div className="text-start">Are you sure you want to save config?</div>
              </Row>
            </>
          )}
          {(isLoading && (
            <>
              <div className="text-center">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <div className="text-start">Calculating net new companies</div>
              </div>
            </>
          )) ||
            (!isNoChangeToSC && (
              <>
                <Row className={'d-flex justify-content-center mb-2'}>
                  <div className="text-start">
                    You are about to add <strong>{result?.companyCount}</strong> net new accounts
                  </div>
                </Row>
                <Form.Label>
                  <strong>Type the number of records to add below</strong>
                </Form.Label>
                <Form.Control
                  onChange={e => setConfirmationValue(e.target.value)}
                  required
                  defaultValue={''}
                  autoFocus={true}
                  type="text"
                />
              </>
            ))}
          <div
            style={{ textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', gap: '10px' }}
            className={'mt-3'}
          >
            <Button
              size={'sm'}
              type="submit"
              disabled={isLoading || (!isNoChangeToSC && confirmationValue !== result?.companyCount?.toString())}
            >
              Save
            </Button>
            <Button variant="white" size="sm" onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
