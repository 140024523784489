import { Button, Card, Row } from 'react-bootstrap';
import DomainsPanel from 'src/pages/staff/coverageAnalysis/domains/DomainsPanel.tsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CoverageAnalysisDTO, CoverageAnalysisResult, FieldRange } from 'src/pages/staff/coverageAnalysis/types.ts';
import { SourcingComponent } from 'src/pages/staff/coverageAnalysis/sourcing/SourcingComponent.tsx';
import {
  createCoverageAnalysis,
  getCoverageAnalysisResult,
  runCoverageAnalysis,
  updateCoverageAnalysis
} from 'src/pages/staff/coverageAnalysis/apis.ts';
import { CoverageBar } from 'src/pages/staff/coverageAnalysis/components/CoverageBar.tsx';
import WideLayout from 'src/layouts/Wide/WideLayout';
import { CoverageAnalysisToolbox } from 'src/pages/staff/coverageAnalysis/components/CoverageAnalysisToolbox.tsx';
import SaveAnalysisModal from 'src/pages/staff/coverageAnalysis/components/SaveAnalysisModal.tsx';
import toast from 'react-hot-toast';
import ClientConfigModal from 'src/pages/staff/clientConfigs/components/ClientConfigModal.tsx';
import { upsertClientConfig } from 'src/apis/clients/apis.ts';
import {
  CompanyCriteriaFilterOperator,
  CompanyCriteriaGroup,
  CompanyCriteriaGroupCombinationMode,
  DataBlockFieldDataType
} from 'src/apis/clients/types.ts';
import { getResults, triggerEstimation } from 'src/pages/staff/marketEstimations/apis.ts';
import { useAdminContext } from 'src/adminContext/hooks.ts';
import { ValueListModal } from 'src/pages/staff/coverageAnalysis/components/ValueListModal.tsx';
import { RangesSetupModal } from 'src/pages/staff/coverageAnalysis/components/RangesSetupModal.tsx';
import { NavigateWithoutSavingWarning } from 'src/components/NavigateWithoutSavingWarning.tsx';
import { useBlocker, useNavigate } from 'react-router-dom';
import { compareAnalysis, deepEqual } from 'src/pages/staff/coverageAnalysis/components/compareRunningAnalysis.ts';

type CoverageAnalysis = {
  schema: any;
  baseConfig: CoverageAnalysisDTO;
  openDomainsOnStart: boolean;
};

const mandatoryRules: CompanyCriteriaGroup = {
  id: 'mandatory/group-0',
  rules: [
    {
      id: 'mandatory/primary_domain',
      field: 'firmographics/primary_domain',
      operator: CompanyCriteriaFilterOperator.NOT_NULL,
      value: null
    },
    {
      id: 'mandatory/domain_is_active',
      field: 'firmographics/domain_is_active',
      operator: CompanyCriteriaFilterOperator.EQUALS,
      value: true
    },
    {
      id: 'mandatory/is_active',
      field: 'firmographics/is_active',
      operator: CompanyCriteriaFilterOperator.EQUALS,
      value: true
    },
    {
      id: 'mandatory/linked_in_unclaimed',
      field: 'firmographics/linked_in_unclaimed',
      operator: CompanyCriteriaFilterOperator.EQUALS,
      value: false
    }
  ],
  combinator: CompanyCriteriaGroupCombinationMode.AND
};

function ensureMandatoryRules(criteria: CompanyCriteriaGroup) {
  if (!criteria) {
    return mandatoryRules;
  }
  if (criteria.rules.find(rule => rule.id?.startsWith('mandatory'))) {
    return criteria;
  } else {
    return {
      rules: [{ rules: criteria.rules, combinator: criteria.combinator, id: criteria.id }, mandatoryRules]
    };
  }
}

function stripFromMandatoryRules(criteria: CompanyCriteriaGroup) {
  if (!criteria) {
    return criteria;
  }
  if (criteria.rules.find(rule => rule.id?.startsWith('mandatory'))) {
    const actualCriteria = criteria.rules[0] as CompanyCriteriaGroup;
    return { rules: actualCriteria.rules, combinator: actualCriteria.combinator, id: criteria.id };
  } else {
    return criteria;
  }
}

export default function CoverageAnalysis({ schema, baseConfig, openDomainsOnStart }: CoverageAnalysis) {
  const navigate = useNavigate();
  const { dataBlocksDefinitions } = useAdminContext();
  const [coverageAnalysisDTO, setCoverageAnalysisDTO] = useState<CoverageAnalysisDTO>({} as CoverageAnalysisDTO);
  const coverageAnalysisDTORef = useRef<CoverageAnalysisDTO>({} as CoverageAnalysisDTO);
  const [coverageAnalysisResult, setCoverageAnalysisResult] = useState<CoverageAnalysisResult>({
    flatRules: [],
    targetCompanies: []
  });
  const [savedAnalysis, setSavedAnalysis] = useState<CoverageAnalysisDTO>();
  const [runAnalysisExecutionId, setRunAnalysisExecutionId] = useState<string | null>(null);
  const [marketEstimateExecutionId, setMarketEstimateExecutionId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [marketEstimationsValue, setMarketEstimationsValue] = useState<number>();
  const [showSaveAnalysisModal, setShowSaveAnalysisModal] = useState(false);
  const [createClientModalOpen, setCreateClientModalOpen] = useState(false);
  const [runAnalysisToastId, setRunAnalysisToastId] = useState<string | null>(null);
  const [openValuesModalFor, setOpenValuesModalFor] = useState<string>();
  const [openRangesSetupModalFor, setOpenRangesSetupModalFor] = useState<string>();

  useEffect(() => {
    setCoverageAnalysisDTO({
      ...baseConfig,
      sourcingCriteria: ensureMandatoryRules(baseConfig.sourcingCriteria)
    });
    coverageAnalysisDTORef.current = {
      ...baseConfig,
      sourcingCriteria: ensureMandatoryRules(baseConfig.sourcingCriteria)
    };
    setSavedAnalysis({
      ...baseConfig,
      sourcingCriteria: ensureMandatoryRules(baseConfig.sourcingCriteria)
    });
  }, [baseConfig]);

  const handleChange = useCallback((update: any, path: string) => {
    // required to not clear the coverage analysis results, on collapse/expand
    const sourcingCriteriaIsEqual = deepEqual(coverageAnalysisDTORef?.current?.sourcingCriteria, update);
    coverageAnalysisDTORef.current = { ...coverageAnalysisDTORef.current, [path]: update };
    if (path === 'sourcingCriteria' && !sourcingCriteriaIsEqual)
      setCoverageAnalysisResult({
        flatRules: [],
        targetCompanies: []
      });
  }, []);

  const handleChangeAndCommit = useCallback((update: any, path: string) => {
    coverageAnalysisDTORef.current = { ...coverageAnalysisDTORef.current, [path]: update };
    setCoverageAnalysisDTO(prevState => ({ ...prevState, [path]: update }));
    if (path === 'sourcingCriteria') setCoverageAnalysisResult({ flatRules: [], targetCompanies: [] });
  }, []);

  const handleCommitChanges = useCallback(() => {
    // to make sure that if we go from JSON to UI, we don't lose the mandatory rules, and they are added back if not provided
    coverageAnalysisDTORef.current = {
      ...coverageAnalysisDTORef.current,
      sourcingCriteria: ensureMandatoryRules(coverageAnalysisDTORef.current.sourcingCriteria)
    };
    setCoverageAnalysisDTO(prevState => ({ ...prevState, ...coverageAnalysisDTORef.current }));
  }, []);

  const onCreateNewAnalysis = useCallback(() => {
    handleCommitChanges();
    setShowSaveAnalysisModal(true);
    return new Promise<void>(resolve => resolve());
  }, [handleCommitChanges]);

  const onCreateAnalysisSubmit = useCallback(
    async (name: string, clientName: string) => {
      setIsLoading(true);
      const toastId = toast.loading('Saving...');
      setSavedAnalysis({
        name,
        clientName: clientName ? clientName : undefined,
        domains: coverageAnalysisDTORef.current.domains,
        sourcingCriteria: coverageAnalysisDTORef.current.sourcingCriteria,
        dataBlockConfigs: coverageAnalysisDTORef.current.dataBlockConfigs,
        fieldRanges: coverageAnalysisDTORef.current.fieldRanges
      });
      createCoverageAnalysis({
        name,
        clientName: clientName ? clientName : undefined,
        domains: coverageAnalysisDTORef.current.domains,
        sourcingCriteria: stripFromMandatoryRules(coverageAnalysisDTORef.current.sourcingCriteria),
        dataBlockConfigs: coverageAnalysisDTORef.current.dataBlockConfigs,
        fieldRanges: coverageAnalysisDTORef.current.fieldRanges
      })
        .then(createdAnalysis => {
          console.log(`created analysis`, createdAnalysis);
          toast.dismiss(toastId);
          toast.success('Analysis saved');
          setShowSaveAnalysisModal(false);
          navigate(`/admin/coverage-analysis/${createdAnalysis.id}`);
        })
        .catch(() => {
          toast.dismiss(toastId);
          toast.error('Failed to save analysis');
          setShowSaveAnalysisModal(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [navigate]
  );

  const onUpdate = useCallback((): Promise<void> => {
    setIsLoading(true);
    const toastId = toast.loading('Saving...');
    handleCommitChanges();
    return updateCoverageAnalysis(coverageAnalysisDTORef.current.id as string, {
      name: coverageAnalysisDTORef.current.name,
      clientName: coverageAnalysisDTORef.current.clientName,
      domains: coverageAnalysisDTORef.current.domains,
      sourcingCriteria: stripFromMandatoryRules(coverageAnalysisDTORef.current.sourcingCriteria),
      dataBlockConfigs: coverageAnalysisDTORef.current.dataBlockConfigs,
      fieldRanges: coverageAnalysisDTORef.current.fieldRanges
    })
      .then(() => {
        toast.dismiss(toastId);
        toast.success('Analysis saved');
        setShowSaveAnalysisModal(false);
        setSavedAnalysis(coverageAnalysisDTORef.current);
      })
      .catch(() => {
        toast.dismiss(toastId);
        toast.error('Failed to save analysis');
        setShowSaveAnalysisModal(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handleCommitChanges]);

  function onCreateNewClient() {
    handleCommitChanges();
    setCreateClientModalOpen(true);
  }

  const createClientConfigSubmit = useCallback(
    async (name: string) => {
      const toastId = toast.loading('Saving...');
      handleCommitChanges();
      const result = await upsertClientConfig(name, {
        contactsEnabled: false,
        syncWithoutContacts: false,
        syncWithoutEmails: false,
        crmDataPullEnabled: false,
        autoSyncEnabled: false,
        enrichmentDestinations: [],
        otherEnrichmentStages: [],
        contactCriteria: [],
        excludeCompanyIds: [],
        customDataFields: [],
        gfAppFeatureFlags: {},
        clientName: name,
        sourcingCriteria: stripFromMandatoryRules(coverageAnalysisDTORef.current.sourcingCriteria),
        dataBlockConfigs: coverageAnalysisDTORef.current.dataBlockConfigs,
        fieldDefinitions: []
      });
      toast.dismiss(toastId);
      if (result.isValid) {
        toast.success(`Client config created with name ${name}`);
        setCreateClientModalOpen(false);
        //   open in new tab
        window.open(`/admin/clients/${name}`);
      } else {
        toast.error('Client config creation failed');
      }
    },
    [handleCommitChanges]
  );

  const runAnalysis = useCallback(() => {
    setIsLoading(true);
    const toastId = toast.loading('Running analysis...');
    setRunAnalysisToastId(toastId);
    handleCommitChanges();
    runCoverageAnalysis(coverageAnalysisDTORef.current)
      .then(result => {
        if (result.executionId) {
          setRunAnalysisExecutionId(result.executionId);
          setCoverageAnalysisResult({ flatRules: [], targetCompanies: [] });
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.dismiss(toastId);
        setRunAnalysisToastId(null);
      });

    triggerEstimation({
      sourcingCriteria: coverageAnalysisDTORef.current.sourcingCriteria,
      fieldDefinitions: [
        {
          externalName: 'domain',
          internalName: 'firmographics/primary_domain'
        }
      ],
      dataBlockConfigs: coverageAnalysisDTORef.current.dataBlockConfigs
    }).then(result => {
      if (result.executionId) {
        setMarketEstimateExecutionId(result.executionId);
        setMarketEstimationsValue(undefined);
      }
    });
  }, [handleCommitChanges]);

  useEffect(() => {
    let interval: any;
    const pollResults = async () => {
      if (runAnalysisExecutionId) {
        const result = await getCoverageAnalysisResult(runAnalysisExecutionId);
        if (['SUCCEEDED', 'FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status)) {
          clearInterval(interval);
          setIsLoading(false);
          runAnalysisToastId && toast.dismiss(runAnalysisToastId);
          setRunAnalysisToastId(null);
          setRunAnalysisExecutionId(null);

          const didFail = ['FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status);
          if (didFail) {
            toast.error(`Sorry, preview errored: ${result.status}`);
            setCoverageAnalysisResult({ flatRules: [], targetCompanies: [] });
          } else {
            setCoverageAnalysisResult(result.output);
          }
        }
      }
      interval = setTimeout(pollResults, 2000); // Re-poll in 2 seconds
    };

    if (runAnalysisExecutionId) {
      interval = setTimeout(pollResults, 2000); // Poll after 2 seconds
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [runAnalysisExecutionId, runAnalysisToastId]);

  useEffect(() => {
    let interval: any;
    const pollResults = async () => {
      if (marketEstimateExecutionId) {
        const result = await getResults(marketEstimateExecutionId);
        if (['SUCCEEDED', 'FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status)) {
          clearInterval(interval);
          setMarketEstimationsValue(result.output?.companyCount);
          setMarketEstimateExecutionId(null);
          const didFail = ['FAILED', 'TIMED_OUT', 'ABORTED'].includes(result.status);
          if (didFail) {
            toast.error(`Sorry, market estimate errored: ${result.status}`);
          }
        }
      }
      interval = setTimeout(pollResults, 2000); // Re-poll in 2 seconds
    };

    if (marketEstimateExecutionId) {
      interval = setTimeout(pollResults, 2000); // Poll in 2 seconds
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [marketEstimateExecutionId]);

  const getRuleFieldType = useCallback(
    (rule: string | undefined): DataBlockFieldDataType => {
      const flatRule = coverageAnalysisResult.flatRules.find(r => r.id === rule);
      if (!flatRule || !('fieldName' in flatRule)) return 'String';
      return (
        dataBlocksDefinitions.flatMap(d => d.fields).find(d => d.internalName === flatRule?.fieldName)?.type || 'String'
      );
    },
    [coverageAnalysisResult.flatRules, dataBlocksDefinitions]
  );

  const canSetRanges = useCallback(
    (rule: string): boolean => {
      return ['Number', 'Currency', 'Date', 'Percentage'].includes(getRuleFieldType(rule));
    },
    [getRuleFieldType]
  );

  const getRange = useCallback(
    (rule: string): (string | number)[] => {
      const flatRule = coverageAnalysisResult.flatRules.find(r => r.id === rule);
      if (!flatRule || !('fieldName' in flatRule)) return [];
      const customFieldRange = coverageAnalysisDTORef.current.fieldRanges.find(
        fr => fr.field === flatRule?.fieldName && (!flatRule?.configId || fr.configId === flatRule?.configId)
      );
      if (customFieldRange) return customFieldRange.ranges;
      const dataBlockRange = dataBlocksDefinitions
        .flatMap(d => d.fields)
        .find(d => d.internalName === flatRule?.fieldName)?.defaultRanges;
      return dataBlockRange || [];
    },
    [coverageAnalysisResult.flatRules, dataBlocksDefinitions]
  );

  const setRange = useCallback(
    (rule: string, range: (number | string)[]) => {
      const flatRule = coverageAnalysisResult.flatRules.find(r => r.id === rule);
      if (!flatRule || !('fieldName' in flatRule)) return;
      const newRange: FieldRange = { field: flatRule?.fieldName, ranges: range, configId: flatRule?.configId };
      coverageAnalysisDTORef.current.fieldRanges = [
        ...coverageAnalysisDTORef.current.fieldRanges.filter(
          fr => !(fr.field === flatRule?.fieldName && (!flatRule?.configId || fr.configId === flatRule?.configId))
        ),
        newRange
      ];
      setCoverageAnalysisResult({ flatRules: [], targetCompanies: [] });
      setOpenValuesModalFor(undefined);
      runAnalysis();
    },
    [coverageAnalysisResult.flatRules, runAnalysis]
  );

  const blocker = useBlocker(() => {
    return !compareAnalysis(coverageAnalysisDTORef.current, savedAnalysis as CoverageAnalysisDTO);
  });

  function isNewConfig() {
    return !coverageAnalysisDTO.id;
  }

  return (
    <WideLayout
      title={<>Coverage analysis </>}
      titleControls={
        <CoverageAnalysisToolbox
          onCreateNewAnalysis={onCreateNewAnalysis}
          onCreateNewClient={onCreateNewClient}
          showUpdate={!isNewConfig()}
          onUpdate={onUpdate}
        />
      }
      maxWidthCols={12}
    >
      <Card>
        <Card.Body>
          <DomainsPanel
            domains={coverageAnalysisDTO?.domains}
            setDomains={newDomains => {
              handleChangeAndCommit(newDomains, 'domains');
            }}
            runAnalysis={runAnalysis}
            isLoading={isLoading}
            openDomainsOnStart={openDomainsOnStart}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <SourcingComponent
            schema={schema}
            clientConfig={coverageAnalysisDTO}
            isEditing={!isLoading}
            handleChange={handleChange}
            handleCommitChanges={handleCommitChanges}
            handleChangeAndCommit={handleChangeAndCommit}
            coverageAnalysisResult={coverageAnalysisResult}
            openValuesModalFor={setOpenValuesModalFor}
          />
          <Row xs={'auto'} className={'flex-row-reverse mx-0'}>
            <Button
              size={'sm'}
              onClick={runAnalysis}
              disabled={isLoading || !(coverageAnalysisDTO.domains?.length > 0)}
            >
              Run Analysis
            </Button>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <CoverageBar
            isRunningCoverageAnalysis={!!runAnalysisExecutionId}
            isRunningMarketEstimations={!!marketEstimateExecutionId}
            coverageAnalysisResult={coverageAnalysisResult}
            marketEstimationsValue={marketEstimationsValue}
          />
        </Card.Body>
      </Card>
      <SaveAnalysisModal
        show={showSaveAnalysisModal}
        onHide={() => setShowSaveAnalysisModal(false)}
        onSubmit={onCreateAnalysisSubmit}
      />
      <ClientConfigModal
        show={createClientModalOpen}
        onHide={() => setCreateClientModalOpen(false)}
        onSubmit={createClientConfigSubmit}
      />

      {!!openValuesModalFor && (
        <ValueListModal
          targetCompanies={coverageAnalysisResult.targetCompanies}
          rule={openValuesModalFor}
          onHide={() => setOpenValuesModalFor(undefined)}
          canSetRanges={canSetRanges}
          openRangesSetupModal={setOpenRangesSetupModalFor}
        />
      )}
      <RangesSetupModal
        rule={openRangesSetupModalFor}
        onHide={() => setOpenRangesSetupModalFor(undefined)}
        getRuleFieldType={getRuleFieldType}
        setRanges={setRange}
        getRanges={getRange}
      />
      <NavigateWithoutSavingWarning
        blocker={blocker}
        canSave={!isNewConfig()}
        hide={() => {}}
        message={
          isNewConfig() ? 'Are you sure you want to discard changes? If not create a new analysis then save.' : null
        }
        handleSave={() => {
          return onUpdate();
        }}
      />
    </WideLayout>
  );
}
