import { CoverageAnalysisDTO } from 'src/pages/staff/coverageAnalysis/types.ts';

function deepCopyOfComparableParameters(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // Return the value if obj is not an object
  }

  let copy: any;

  if (Array.isArray(obj)) {
    copy = obj.map(item => deepCopyOfComparableParameters(item));
  } else {
    copy = {};
    for (const [key, value] of Object.entries(obj)) {
      if (!['clientName', 'name', 'id', 'fieldDefinitions', 'createdAt', 'updatedAt'].includes(key)) {
        // Skip 'id' properties
        copy[key] = deepCopyOfComparableParameters(value);
      }
    }
  }

  return copy;
}

export function deepEqual(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

function isObject(object: any): boolean {
  return object != null && typeof object === 'object';
}

export function compareAnalysis(a1: CoverageAnalysisDTO, a2: CoverageAnalysisDTO): boolean {
  const cleanedObj1 = deepCopyOfComparableParameters(a1);
  const cleanedObj2 = deepCopyOfComparableParameters(a2);
  return deepEqual(cleanedObj1, cleanedObj2);
}
