export function titleCase(str: string) {
  if (!str) return '';
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function snakeCaseToWords(str: string) {
  return titleCase(str.replace(/_/g, ' '));
}

export function formatDateString(input: string) {
  const dateStr = `${input}`;
  const date = new Date(dateStr);
  return date.toLocaleString();
}

export function formatDate(input: Date) {
  return input?.toLocaleString();
}

export function pluralize(value: number, singleText: string, pluralText: string) {
  return value === 1 ? singleText : pluralText;
}
